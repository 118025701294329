import React from 'react';
import $ from 'jquery';

function MessageModal(props) {
  const { message, handleClose } = props;

  return (
    <div className='modal__message' style={ { height: $('body').height() } }>
      <div className='modal__message__box'>
        <div className='modal__message__copy'>
          { message }
        </div>

        <div className='modal__message__close' onClick={ e => handleClose() }>
          Close
        </div>
      </div>
    </div>
  )
  
}

export default MessageModal;