import React, { useEffect } from 'react';

function ConcertCenter(props) {
  const { focusedSection } = props;

  useEffect(() => {
    const elementToFocus = document.getElementById(`section_${ focusedSection }`);
    const focusedRect = document.getElementsByClassName('focused');

    if (focusedRect.length) {
      focusedRect[0].classList.remove('focused');
    }

    elementToFocus && elementToFocus.classList.add('focused');
  }, [ focusedSection ]);

  return (
    <div id='concert-center'>
      <svg 
        easypz='{ "applyTransformTo":"svg > *", "modes": ["SIMPLE_PAN", "HOLD_ZOOM_IN", "CLICK_HOLD_ZOOM_OUT", "PINCH_ZOOM", "DBLCLICK_ZOOM_IN", "DBLRIGHTCLICK_ZOOM_OUT"], "options": { "minScale": 1, "maxScale": 2, "bounds": { "top": -50, "right": 50, "bottom": 50, "left": -50 }} }'
        version="1.1" 
        id="SeatingChartConcertCenterStage"
        xmlns="http://www.w3.org/2000/svg"
        x="0px" 
        y="0px" 
        viewBox="50 60 690 470">

        <g id="section_226">
          <rect x="216.8" y="469.5" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 229.2415 489.0931)">226</text>
        </g>

        <g id="section_227">
          <polygon points="168.3,453.4 144.3,477.4 172.4,505.5 211.2,505.8 211.2,470.8 185.5,470.6 	"/>
          <text transform="matrix(1 0 0 1 170.8502 489.3079)">227</text>
        </g>

        <g id="section_228">
          <rect x="116.9" y="415.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -273.9348 224.3723)" width="33.9" height="55.3"/>
          <text transform="matrix(1 0 0 1 124.0898 448.3763)">228</text>
        </g>

        <g id="section_229">
          <polygon points="120.5,405.1 96.3,429.3 72.5,405.4 72.5,359.9 106.3,359.9 107.1,391.3 	"/>
          <text id="_x32_29" transform="matrix(1 0 0 1 79.7547 397.3326)">229</text>
        </g>

        <g id="section_230">
          <rect x="72.5" y="308.3" width="33.9" height="45.2"/>
          <text transform="matrix(1 0 0 1 79.7547 334.042)">230</text>
        </g>

        <g id="section_231">
          <rect x="72.5" y="264.3" width="33.9" height="37.9"/>
          <text transform="matrix(1 0 0 1 79.7547 286.4771)">231</text>
        </g>

        <g id="section_232">
          <rect x="72.5" y="213.2" width="33.9" height="45.2"/>
          <text transform="matrix(1 0 0 1 79.7546 239.2999)">232</text>
        </g>

        <g id="section_201">
          <polygon points="120.9,160.4 97.2,136.7 73.4,160.6 73.4,206.1 107.1,206.1 107.1,173.9 	"/>
          <text transform="matrix(1 0 0 1 80.6297 177.1429)">201</text>
        </g>

        <g id="section_202">
          <rect x="107.1" y="106.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -47.8861 131.493)" width="55.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 124.4435 126.527)">202</text>
        </g>

        <g id="section_203">
          <polygon points="210.5,96.4 210.5,62.5 170.8,62.5 145.6,87.8 169.9,112.1 186.2,96.4 	"/>
          <text transform="matrix(1 0 0 1 168.3232 84.0774)">203</text>
        </g>

        <g id="section_225">
          <rect x="268.8" y="469" width="44.3" height="35"/>
          <text transform="matrix(1 0 0 1 282.5436 488.5793)">225</text>
        </g>

        <g id="section_224">
          <rect x="319.9" y="469.1" width="44.3" height="35.2"/>
          <text transform="matrix(1 0 0 1 332.7783 488.5785)">224</text>
        </g>

        <g id="section_222">
          <rect x="422.7" y="470.1" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 434.2541 489.6078)">222</text>
        </g>

        <g id="section_223">
          <rect x="370.9" y="469" width="44.3" height="27"/>
          <text transform="matrix(1 0 0 1 382.5233 486.9117)">223</text>
        </g>

        <g id="section_207">
          <rect x="370.9" y="68.9" width="44.3" height="29.6"/>
          <g>
            <text transform="matrix(1 0 0 1 382.3027 88.136)">207</text>
          </g>
        </g>

        <g id="section_221">
          <rect x="474.2" y="470.1" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 486.4589 489.6078)">221</text>
        </g>

        <g id="section_220">
          <rect x="525.2" y="470" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 536.4456 489.5094)">220</text>
        </g>

        <g id="section_218">
          <rect x="624" y="425.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -121.6857 590.6511)" width="56.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 642.4252 445.3914)">218</text>
        </g>

        <g id="section_216">
          <rect x="679.7" y="307.7" width="33.9" height="45.2"/>
          <text transform="matrix(1 0 0 1 686.8411 334.8959)">216</text>
        </g>

        <g id="section_215">
          <rect x="679.9" y="264.5" width="29" height="36.9"/>
          <text transform="matrix(1 0 0 1 684.9039 287.1768)">215</text>
        </g>

        <g id="section_214">
          <rect x="680" y="212.3" width="33.9" height="45.2"/>
          <text transform="matrix(1 0 0 1 687.0066 239.4274)">214</text>
        </g>

        <g id="section_219">
          <polygon points="576,470 576,504 615.7,504 640.9,478.7 616.6,454.3 600.3,470 	"/>
          <text transform="matrix(1 0 0 1 595.6715 487.0102)">219</text>
        </g>

        <g id="section_204">
          <rect x="217.5" y="62.5" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 229.935 82.0773)">204</text>
        </g>

        <g id="section_205">
          <rect x="268.8" y="62.5" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 280.6169 82.0771)">205</text>
        </g>

        <g id="section_206">
          <rect x="320.3" y="62.5" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 332.4337 82.0771)">206</text>
        </g>

        <g id="section_208">
          <rect x="421.6" y="62.5" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 433.4832 82.0771)">208</text>
        </g>

        <g id="section_209">
          <rect x="473.5" y="62.6" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 484.9198 82.0771)">209</text>
        </g>

        <g id="section_210">
          <rect x="525.4" y="63.2" width="44.3" height="33.9"/>
          <text transform="matrix(1 0 0 1 536.9342 82.6973)">210</text>
        </g>

        <g id="section_211">
          <polygon points="577.2,96.4 577.2,62.5 616.9,62.5 642.2,87.8 617.8,112.1 601.6,96.4 	"/>
          <text transform="matrix(1 0 0 1 598.2745 84.5871)">211</text>
        </g>

        <g id="section_212">
          <rect x="636.2" y="96.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 103.7004 498.1105)" width="33.9" height="54.8"/>
          <text transform="matrix(1 0 0 1 643.4253 127.1023)">212</text>
        </g>

        <g id="section_213">
          <polygon points="666.4,159.5 690.1,135.8 713.9,159.7 713.9,205.2 680.1,205.2 680.1,173 	"/>
          <text transform="matrix(1 0 0 1 687.1907 177.073)">213</text>
        </g>

        <g id="section_217">
          <polygon points="666.9,406.2 690.6,429.8 714.4,406 714.4,360.5 680.7,360.5 680.7,392.7 	"/>
          <text transform="matrix(1 0 0 1 687.0045 394.5986)">217</text>
        </g>

        <g id="section_125">
          <rect x="268.2" y="352.4" width="45.4" height="101.1"/>
          <text transform="matrix(1 0 0 1 281.2501 405.5796)">125</text>
        </g>

        <g id="section_124">
          <rect x="320.3" y="344.5" width="44.3" height="104.1"/>
          <text transform="matrix(1 0 0 1 332.7278 405.2451)">124</text>
        </g>

        <g id="section_123">
          <rect x="371.1" y="344.5" width="44.3" height="109"/>
          <text transform="matrix(1 0 0 1 383.3674 405.2451)">123</text>
        </g>

        <g id="section_122">
          <rect x="422.7" y="344.5" width="44.3" height="103.7"/>
          <text transform="matrix(1 0 0 1 434.1071 405.2451)">122</text>
        </g>

        <g id="section_121">
          <rect x="473.5" y="352.4" width="44.5" height="100.9"/>
          <text transform="matrix(1 0 0 1 485.9934 405.2451)">121</text>
        </g>

        <g id="section_109">
          <rect x="474.3" y="112.5" width="44.3" height="100.2"/>
          <line x1="474.3" y1="114.2" x2="518.6" y2="114.2"/>
          <text transform="matrix(1 0 0 1 486.9934 164.0594)">109</text>
        </g>

        <g id="section_108">
          <rect x="422.1" y="117.8" width="45.5" height="103.6"/>
          <text transform="matrix(1 0 0 1 435.216 164.0594)">108</text>
        </g>

        <g id="section_107">
          <rect x="371.5" y="112.9" width="44.3" height="108.5"/>
          <text transform="matrix(1 0 0 1 385.3671 164.0594)">107</text>
        </g>

        <g id="section_106">
          <rect x="320.3" y="117.3" width="44.3" height="104.1"/>
          <text transform="matrix(1 0 0 1 334.3165 164.0594)">106</text>
        </g>

        <g id="section_105">
          <rect x="268.8" y="112.1" width="44.3" height="99.6"/>
          <text transform="matrix(1 0 0 1 283.1567 164.0594)">105</text>
        </g>

        <g id="section_120">
          <polygon points="525.4,352.4 547.6,352.4 588.7,452.1 588.7,457.6 525.4,457.6 	"/>
          <text transform="matrix(1 0 0 1 533.934 405.2451)">120</text>
        </g>

        <g id="section_110">
          <polygon points="525.2,117.5 525.2,212.8 547.2,212.8 588.4,117.5 588.4,112.6 525.2,112.6 	"/>
          <text transform="matrix(1 0 0 1 535.2062 164.0594)">110</text>
        </g>

        <g id="section_116">
          <polygon points="663.7,377.8 663.7,311.3 562.8,311.3 562.8,336.8 	"/>
          <text transform="matrix(1 0 0 1 604.6299 337.9951)">116</text>
        </g>

        <g id="section_115">
          <rect x="562.8" y="261" width="95.2" height="44.3"/>
          <text transform="matrix(1 0 0 1 604.6299 287.1774)">115</text>
        </g>

        <g id="section_114">
          <polygon points="664,187.5 663.7,254.7 562.8,254.7 562.8,229.1 	"/>
          <text transform="matrix(1 0 0 1 611.0049 234.1773)">114</text>
        </g>

        <g id="section_104">
          <polygon points="262,114.2 262,211.6 239.9,211.7 198.8,114.2 198.8,109.2 262,109.2 	"/>
          <text transform="matrix(1 0 0 1 230.9559 164.0594)">104</text>
        </g>

        <g id="section_103">
          <polygon points="191.3,112.9 194.1,119.9 222.5,187.1 207.2,202.7 200.6,195.4 205.3,190.7 159.6,144.8 	"/>
          <text transform="matrix(1 0 0 1 179.3026 152.8094)">103</text>
        </g>

        <g id="section_101">
          <path d="M197.6,192.8c-2.4-1.2-39.6-41-39.6-41l-27.7,27.9l11,4.5l45.5,19.5L197.6,192.8z"/>
          <text transform="matrix(1 0 0 1 149.9144 177.7381)">101</text>
        </g>

        <g id="section_126">
          <polygon points="261.3,453.2 261.3,356.7 239.3,356.8 198.1,453.2 198.1,458.2 261.3,458.2 	"/>
          <text transform="matrix(1 0 0 1 230.9561 405.2451)">126</text>
        </g>

        <g id="section_14">
          <rect x="496.7" y="231.4" width="64.1" height="22.8"/>
          <text transform="matrix(1 0 0 1 520.3667 245.9274)">14</text>
        </g>

        <g id="section_15">
          <rect id="_x31_5_1_" x="496.7" y="261" width="64.1" height="44.3"/>
          <text transform="matrix(1 0 0 1 520.3667 287.6779)">15</text>
        </g>

        <g id="section_16">
          <rect x="496.7" y="311.3" width="64.1" height="22.8"/>
          <text transform="matrix(1 0 0 1 520.3667 325.9274)">16</text>
        </g>

        <g id="section_C">
          <rect x="434.2" y="244.4" width="53.8" height="35.4"/>
          <text transform="matrix(1 0 0 1 456.5225 265.1774)">C</text>
        </g>

        <g id="section_D">
          <rect x="434.2" y="286.3" width="53.8" height="35.4"/>
          <text transform="matrix(1 0 0 1 456.3043 306.9274)">D</text>
        </g>

        <g id="section_E">
          <rect x="396.8" y="324.6" width="35.2" height="11.3"/>
          <g>
            <text transform="matrix(1 0 0 1 411.5225 334.1899)">E</text>
          </g>
        </g>

        <g id="section_F">
          <rect x="355.2" y="324.6" width="35.2" height="11.3"/>
          <g>
            <text transform="matrix(1 0 0 1 369.7499 334.1899)">F</text>
          </g>
        </g>

        <g id="section_G">
          <rect x="299.3" y="286.3" width="52.8" height="35.4"/>
          <text transform="matrix(1 0 0 1 321.5988 307.9274)">G</text>
        </g>

        <g id="section_H">
          <rect x="299.3" y="244.4" width="52.8" height="35.4"/>
          <text transform="matrix(1 0 0 1 321.5671 265.1773)">H</text>
        </g>

        <g id="section_A">
          <rect x="355.2" y="230.1" width="35.2" height="11.3"/>
          <text transform="matrix(1 0 0 1 369.918 239.2896)">A</text>
        </g>

        <g id="section_B">
          <rect x="396.8" y="230.1" width="35.2" height="11.3"/>
          <text transform="matrix(1 0 0 1 411.0152 240.2896)">B</text>
        </g>

        <g id="section_30">
          <rect x="225.3" y="311.9" width="64.2" height="22.5"/>
          <text transform="matrix(1 0 0 1 249.4429 326.9277)">30</text>
        </g>

        <g id="section_31">
          <rect x="225.3" y="260.8" width="64.2" height="44.5"/>
          <text transform="matrix(1 0 0 1 249.443 286.3187)">31</text>
        </g>

        <g id="section_131">
          <rect x="164.4" y="260.8" width="58.9" height="44.5"/>
          <text transform="matrix(1 0 0 1 183.1529 286.0706)">131</text>
        </g>

        <g id="section_132">
          <polygon points="162.8,204.1 223.3,228.1 223.3,255.1 162.8,255.1 	"/>
          <text transform="matrix(1 0 0 1 183.1529 240.2894)">132</text>
        </g>

        <g id="section_130">
          <polygon points="162.8,362.9 223.3,338.9 223.3,311.9 162.8,311.9 	"/>
          <text transform="matrix(1 0 0 1 183.1529 333.1227)">130</text>
        </g>

        <g id="section_32">
          <rect x="225.3" y="231.7" width="64.2" height="22.5"/>
          <text transform="matrix(1 0 0 1 249.443 245.9277)">32</text>
        </g>

        <g id="section_113">
          <polygon points="628.6,152.3 659.1,182.5 591.1,211 584,203.9 608.6,178.6 605.4,175.5 	"/>
          <text transform="matrix(1 0 0 1 617.2899 183.5514)">113</text>
        </g>

        <g id="section_111">
          <polygon points="595.9,113.3 627,144.5 599.9,172.1 596.6,169.1 573,192.5 564.8,185 	"/>
          <text transform="matrix(1 0 0 1 590.5709 149.9432)">111</text>
        </g>

        <g id="section_119">
          <polygon points="578.5,371.7 580.6,369.4 558.9,347.7 554.5,352 595.2,450.5 625.7,420.2 	"/>
          <text transform="matrix(1 0 0 1 585.2625 415.8685)">119</text>
        </g>

        <g id="section_117">
          <polygon points="562.8,344.1 558.9,347.7 580.6,369.4 582.5,367.2 627.4,413 658,382.5 	"/>
          <text transform="matrix(1 0 0 1 611.3692 385.3606)">117</text>
        </g>

        <g id="section_127">
          <polygon points="210.5,365.8 203.2,372.7 205.3,374.6 159.6,422.3 191.1,451.9 222.1,378.3 	"/>
          <text transform="matrix(1.0525 0 0 1 175.7871 425.627)">127</text>
        </g>

        <g id="section_129">
          <polygon points="202,356.7 130.4,385.2 159.6,414.2 201.3,370.9 203.2,372.7 210.5,365.8 	"/>
          <text transform="matrix(1.0525 0 0 1 146.2871 394.1724)">129</text>
        </g>

        <g id="stage">
          <rect x="361.1" y="252.6" width="63.5" height="63.5"/>
          <text transform="matrix(1.098 0 0 1 370.6729 288.7124)">STAGE</text>
        </g>

        <g id="handicapped">
        <g>
        <g>
          <circle cx="344.4" cy="448.6" r="6"/>
          <path d="M344.4,455c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S347.9,455,344.4,455z M344.4,442.9
            c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S347.5,442.9,344.4,442.9z"/>
        </g>
        <g>
          <g>
            <path d="M344.6,447.6L344.6,447.6c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
              c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
              c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
              c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
              C344.6,447.2,344.6,447.4,344.6,447.6L344.6,447.6z"/>
          </g>
          <g>
            <path d="M343.5,447.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
              c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
              c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C342.6,447.9,343,447.7,343.5,447.6
              C343.4,447.6,343.4,447.6,343.5,447.6z"/>
          </g>
          <g>
            <path d="M345,445.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C344.6,444.6,345,444.9,345,445.4z"/>
          </g>
        </g>
      </g>
      <g>
      <g>
        <circle cx="344.4" cy="117.8" r="6"/>
        <path d="M344.4,124.1c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C350.7,121.3,347.9,124.1,344.4,124.1z
          M344.4,112.1c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S347.5,112.1,344.4,112.1z"/>
      </g>
      <g>
        <g>
          <path d="M344.6,116.8L344.6,116.8c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
            c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
            c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
            c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
            C344.6,116.4,344.6,116.6,344.6,116.8L344.6,116.8z"/>
        </g>
        <g>
          <path d="M343.5,116.7c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C342.6,117.1,343,116.9,343.5,116.7
            C343.4,116.7,343.4,116.7,343.5,116.7z"/>
        </g>
        <g>
          <path d="M345,114.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S345,114.1,345,114.6z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="230.4" cy="110.7" r="6"/>
        <path d="M230.4,117.1c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S233.9,117.1,230.4,117.1z M230.4,105
          c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S233.6,105,230.4,105z"/>
      </g>
      <g>
        <g>
          <path d="M230.6,109.8C230.7,109.8,230.7,109.8,230.6,109.8c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
            c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
            c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
            c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
            c0.2,0,0.4,0.1,0.5,0.4v0.1C230.6,109.3,230.6,109.5,230.6,109.8C230.6,109.7,230.6,109.7,230.6,109.8z"/>
        </g>
        <g>
          <path d="M229.5,109.7c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C228.6,110.1,229,109.8,229.5,109.7L229.5,109.7z"/>
        </g>
        <g>
          <path d="M231,107.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C230.6,106.7,231,107.1,231,107.5z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="145.6" cy="164.4" r="6"/>
        <path d="M145.6,170.7c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C151.9,167.9,149.1,170.7,145.6,170.7z
          M145.6,158.7c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S148.7,158.7,145.6,158.7z"/>
      </g>
      <g>
        <g>
          <path d="M145.8,163.4L145.8,163.4c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
            c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
            c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
            c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
            C145.8,163,145.8,163.1,145.8,163.4C145.8,163.3,145.8,163.4,145.8,163.4z"/>
        </g>
        <g>
          <path d="M144.7,163.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C143.8,163.7,144.2,163.5,144.7,163.3
            C144.6,163.3,144.6,163.3,144.7,163.3z"/>
        </g>
        <g>
          <path d="M146.2,161.2c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S146.2,160.7,146.2,161.2z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="72.3" cy="223.8" r="6"/>
        <path d="M72.3,230.1c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S75.8,230.1,72.3,230.1z M72.3,218.1
          c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C78.1,220.7,75.5,218.1,72.3,218.1z"/>
      </g>
      <g>
        <g>
          <path d="M72.6,222.8L72.6,222.8c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
            c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
            c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
            c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
            C72.6,222.4,72.6,222.6,72.6,222.8L72.6,222.8z"/>
        </g>
        <g>
          <path d="M71.4,222.8c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C70.6,223.1,71,222.9,71.4,222.8L71.4,222.8z"/>
        </g>
        <g>
          <path d="M73,220.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C72.6,219.7,73,220.1,73,220.6z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="72.3" cy="344.5" r="6"/>
        <path d="M72.3,350.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S75.8,350.9,72.3,350.9z M72.3,338.8
          c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C78.1,341.4,75.5,338.8,72.3,338.8z"/>
      </g>
      <g>
        <g>
          <path d="M72.6,343.5L72.6,343.5c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
            c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
            c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
            c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
            C72.6,343.1,72.6,343.3,72.6,343.5L72.6,343.5z"/>
        </g>
        <g>
          <path d="M71.4,343.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C70.6,343.8,71,343.6,71.4,343.5L71.4,343.5z"/>
        </g>
        <g>
          <path d="M73,341.3c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C72.6,340.5,73,340.9,73,341.3z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="446" cy="117.8" r="6"/>
        <path d="M446,124.1c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S449.5,124.1,446,124.1z M446,112.1
          c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S449.1,112.1,446,112.1z"/>
      </g>
      <g>
        <g>
          <path d="M446.2,116.8L446.2,116.8c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
            c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
            c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
            c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
            C446.2,116.4,446.2,116.6,446.2,116.8L446.2,116.8z"/>
        </g>
        <g>
          <path d="M445.1,116.7c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C444.2,117.1,444.6,116.9,445.1,116.7
            C445,116.7,445,116.7,445.1,116.7z"/>
        </g>
        <g>
          <path d="M446.6,114.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S446.6,114.1,446.6,114.6z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="555.1" cy="112.6" r="6"/>
        <path d="M555.1,118.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S558.6,118.9,555.1,118.9z
          M555.1,106.9c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S558.3,106.9,555.1,106.9z"/>
      </g>
      <g>
        <g>
          <path d="M555.4,111.6L555.4,111.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
            c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
            c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
            c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
            C555.4,111.2,555.4,111.3,555.4,111.6C555.4,111.5,555.4,111.6,555.4,111.6z"/>
        </g>
        <g>
          <path d="M554.2,111.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C553.4,111.9,553.7,111.7,554.2,111.5L554.2,111.5z"/>
        </g>
        <g>
          <path d="M555.8,109.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C555.4,108.5,555.8,108.9,555.8,109.4
            z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="394.4" cy="500.1" r="6"/>
        <path d="M394.4,506.5c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S397.9,506.5,394.4,506.5z
          M394.4,494.4c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,494.4,394.4,494.4z"/>
      </g>
      <g>
        <g>
          <path d="M394.6,499.2L394.6,499.2c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
            c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
            c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
            c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
            C394.6,498.7,394.6,498.9,394.6,499.2C394.6,499.1,394.6,499.1,394.6,499.2z"/>
        </g>
        <g>
          <path d="M393.5,499.1c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,499.5,393,499.2,393.5,499.1
            C393.4,499.1,393.4,499.1,393.5,499.1z"/>
        </g>
        <g>
          <path d="M395,497c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,496.5,395,497z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="674.4" cy="442.6" r="6"/>
        <path d="M674.4,448.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S677.9,448.9,674.4,448.9z
          M674.4,436.9c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C680.1,439.5,677.5,436.9,674.4,436.9z"/>
      </g>
      <g>
        <g>
          <path d="M674.6,441.6C674.6,441.6,674.7,441.6,674.6,441.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
            c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
            c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
            c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
            c0.2,0,0.4,0.1,0.5,0.4v0.1C674.6,441.2,674.6,441.4,674.6,441.6L674.6,441.6z"/>
        </g>
        <g>
          <path d="M673.5,441.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C672.6,441.9,673,441.7,673.5,441.6
            C673.4,441.6,673.5,441.6,673.5,441.6z"/>
        </g>
        <g>
          <path d="M675,439.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S675,438.9,675,439.4z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="711.3" cy="283" r="6"/>
        <path d="M711.3,289.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S714.8,289.3,711.3,289.3z
          M711.3,277.3c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C717,279.8,714.4,277.3,711.3,277.3z"/>
      </g>
      <g>
        <g>
          <path d="M711.5,282C711.5,282,711.6,282,711.5,282c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
            c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
            c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
            c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V284c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
            C711.5,281.6,711.5,281.7,711.5,282C711.5,281.9,711.5,282,711.5,282z"/>
        </g>
        <g>
          <path d="M710.4,281.9c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
            c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
            c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C709.5,282.3,709.9,282.1,710.4,281.9
            C710.3,281.9,710.4,281.9,710.4,281.9z"/>
        </g>
        <g>
          <path d="M711.9,279.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S711.9,279.3,711.9,279.8z"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <circle cx="676.8" cy="124.7" r="6"/>
        <path d="M676.8,131c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C683.2,128.2,680.3,131,676.8,131z
          M676.8,119c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S680,119,676.8,119z"/>
      </g>
      <g>
        <g>
          <path d="M677.1,123.7L677.1,123.7c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5H678c-0.3,0-0.5,0-0.8,0
            c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7
            c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0
            c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
            C677.1,123.3,677.1,123.5,677.1,123.7C677.1,123.6,677.1,123.7,677.1,123.7z"/>
              </g>
              <g>
                <path d="M675.9,123.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C675.1,124,675.4,123.8,675.9,123.6L675.9,123.6z"/>
              </g>
              <g>
                <path d="M677.5,121.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S677.5,121,677.5,121.5z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle cx="658" cy="283.6" r="6"/>
              <path d="M658,289.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S661.5,289.9,658,289.9z M658,277.8
                c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S661.2,277.8,658,277.8z"/>
            </g>
            <g>
              <g>
                <path d="M658.2,282.6C658.3,282.6,658.3,282.6,658.2,282.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                  c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                  c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                  c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                  c0.2,0,0.4,0.1,0.5,0.4v0.1C658.2,282.2,658.2,282.3,658.2,282.6C658.2,282.5,658.2,282.6,658.2,282.6z"/>
              </g>
              <g>
                <path d="M657.1,282.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C656.2,282.9,656.6,282.7,657.1,282.5L657.1,282.5z"/>
              </g>
              <g>
                <path d="M658.6,280.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C658.2,279.5,658.6,279.9,658.6,280.4
                  z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle cx="641.4" cy="399.2" r="6"/>
              <path d="M641.4,405.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S644.9,405.6,641.4,405.6z
                M641.4,393.5c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C647.1,396.1,644.5,393.5,641.4,393.5z"/>
            </g>
            <g>
              <g>
                <path d="M641.6,398.2C641.6,398.2,641.7,398.2,641.6,398.2c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                  c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                  c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                  c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                  c0.2,0,0.4,0.1,0.5,0.4v0.1C641.6,397.8,641.6,398,641.6,398.2L641.6,398.2z"/>
              </g>
              <g>
                <path d="M640.5,398.2c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C639.6,398.5,640,398.3,640.5,398.2L640.5,398.2z"/>
              </g>
              <g>
                <path d="M642,396c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C641.6,395.2,642,395.5,642,396z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle cx="554.5" cy="457.6" r="6"/>
              <path d="M554.5,463.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S558,463.9,554.5,463.9z M554.5,451.8
                c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S557.7,451.8,554.5,451.8z"/>
            </g>
            <g>
              <g>
                <path d="M554.7,456.6C554.8,456.6,554.8,456.6,554.7,456.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                  c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                  c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                  c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                  c0.2,0,0.4,0.1,0.5,0.4v0.1C554.7,456.2,554.7,456.3,554.7,456.6C554.7,456.5,554.7,456.6,554.7,456.6z"/>
              </g>
              <g>
                <path d="M553.6,456.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C552.7,456.9,553.1,456.7,553.6,456.5L553.6,456.5z"/>
              </g>
              <g>
                <path d="M555.1,454.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C554.7,453.5,555.1,453.9,555.1,454.4
                  z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle cx="394.4" cy="469" r="6"/>
              <path d="M394.4,475.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C400.7,472.5,397.9,475.3,394.4,475.3z
                M394.4,463.3c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C400.1,465.8,397.5,463.3,394.4,463.3z"/>
            </g>
            <g>
              <g>
                <path d="M394.6,468L394.6,468c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                  c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                  c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                  c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V470c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                  C394.6,467.6,394.6,467.8,394.6,468L394.6,468z"/>
              </g>
              <g>
                <path d="M393.5,468c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,468.3,393,468.1,393.5,468
                  C393.4,468,393.4,468,393.5,468z"/>
              </g>
              <g>
                <path d="M395,465.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,465.3,395,465.8z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle cx="392.3" cy="99.6" r="6"/>
              <path d="M392.3,105.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C398.6,103.1,395.8,105.9,392.3,105.9z
                M392.3,93.9c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S395.4,93.9,392.3,93.9z"/>
            </g>
            <g>
              <g>
                <path d="M392.5,98.6C392.5,98.6,392.6,98.6,392.5,98.6c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4
                  c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                  c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                  c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                  c0.2,0,0.4,0.1,0.5,0.4v0.1C392.5,98.2,392.5,98.4,392.5,98.6L392.5,98.6z"/>
              </g>
              <g>
                <path d="M391.4,98.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C390.5,98.9,390.9,98.7,391.4,98.5
                  C391.4,98.6,391.4,98.6,391.4,98.5z"/>
              </g>
              <g>
                <path d="M392.9,96.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S392.9,95.9,392.9,96.4z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle cx="392.3" cy="68.8" r="6"/>
              <path d="M392.3,75.2c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S395.8,75.2,392.3,75.2z M392.3,63.1
                c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S395.4,63.1,392.3,63.1z"/>
            </g>
            <g>
              <g>
                <path d="M392.5,67.9C392.5,67.9,392.6,67.9,392.5,67.9c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4
                  c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                  c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                  c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                  c0.2,0,0.4,0.1,0.5,0.4v0.1C392.5,67.4,392.5,67.6,392.5,67.9C392.5,67.8,392.5,67.8,392.5,67.9z"/>
              </g>
              <g>
                <path d="M391.4,67.8c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C390.5,68.2,390.9,67.9,391.4,67.8L391.4,67.8z"/>
              </g>
              <g>
                <path d="M392.9,65.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S392.9,65.2,392.9,65.7z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle cx="446" cy="448.6" r="6"/>
              <path d="M446,455c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S449.5,455,446,455z M446,442.9
                c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S449.1,442.9,446,442.9z"/>
            </g>
            <g>
              <g>
                <path d="M446.2,447.6L446.2,447.6c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                  c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                  c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                  c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                  C446.2,447.2,446.2,447.4,446.2,447.6L446.2,447.6z"/>
              </g>
              <g>
                <path d="M445.1,447.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C444.2,447.9,444.6,447.7,445.1,447.6
                  C445,447.6,445,447.6,445.1,447.6z"/>
              </g>
              <g>
                <path d="M446.6,445.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C446.2,444.6,446.6,444.9,446.6,445.4
                  z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle cx="650.2" cy="171.4" r="6"/>
              <path d="M650.2,177.7c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S653.6,177.7,650.2,177.7z
                M650.2,165.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S653.3,165.7,650.2,165.7z"/>
            </g>
            <g>
              <g>
                <path d="M650.4,170.4L650.4,170.4c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                  c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                  c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                  c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                  C650.4,170,650.4,170.2,650.4,170.4L650.4,170.4z"/>
              </g>
              <g>
                <path d="M649.2,170.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C648.4,170.7,648.8,170.5,649.2,170.4L649.2,170.4z"/>
              </g>
              <g>
                <path d="M650.8,168.2c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C650.4,167.3,650.8,167.7,650.8,168.2
                  z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle cx="154.4" cy="407.4" r="6"/>
              <path d="M154.4,413.8c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S157.9,413.8,154.4,413.8z
                M154.4,401.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C160.1,404.3,157.5,401.7,154.4,401.7z"/>
            </g>
            <g>
              <g>
                <path d="M154.6,406.4L154.6,406.4c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                  c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                  c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                  c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                  C154.6,406,154.6,406.2,154.6,406.4L154.6,406.4z"/>
              </g>
              <g>
                <path d="M153.5,406.4c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                  c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                  c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C152.6,406.8,153,406.5,153.5,406.4
                  C153.4,406.4,153.4,406.4,153.5,406.4z"/>
              </g>
              <g>
                <path d="M155,404.2c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C154.6,403.4,155,403.8,155,404.2z"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

// <img src='/img/CenterStageConcertSeatingChart.jpg' alt='center' />

export default ConcertCenter;
