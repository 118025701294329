import React, { useEffect } from 'react';
import $ from 'jquery';

function SuiteLevel(props) {
  const { focusedSection } = props;

  useEffect(() => {
    if (focusedSection) {
      let elementToFocus = document.getElementById(`section_${ focusedSection }`);
      $('.focused').removeClass('focused');

      const multiPoints = [ 'elevators', 'mens-restrooms', 'family-restrooms', 'womens-restrooms' ];

      if (multiPoints.includes(focusedSection)) {
        $(`.${ focusedSection }`).addClass('focused');
      }

      elementToFocus && elementToFocus.classList.add('focused');
    }
  }, [ focusedSection ]);

  return (
    <div className='suite-level'>
      <svg 
        easypz='{ "applyTransformTo":"svg > *", "modes": ["SIMPLE_PAN", "HOLD_ZOOM_IN", "CLICK_HOLD_ZOOM_OUT", "PINCH_ZOOM", "DBLCLICK_ZOOM_IN", "DBLRIGHTCLICK_ZOOM_OUT"], "options": { "minScale": 1, "maxScale": 2, "bounds": { "top": -50, "right": 50, "bottom": 50, "left": -50 }} }'
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg"
        x="0px" 
        y="0px"
        viewBox="150 150 2750 1950">
      <path id="bottom_fill" className="st0" d="M2775.6,925.4c-5.1-34.8-12-71.5-21.2-110l-24.1-40.3l-31.1-4.1V656.7h-118.9V373.8H2352
        c0,0-5.9-108.5-92.7-111.6h-175l-57.1-27.3h-254.3v-15.2c0,0,0,0-0.1,0v-10.8c0,0-95.6-58.4-239.1-54.2
        c-143.5-4.2-239.1,54.2-239.1,54.2v10.8c0,0,0,0-0.1,0v15.2h-254.3l-57.1,27.3h-175c-86.8,3.2-92.7,111.6-92.7,111.6H487v282.9
        H317.6l-30.4,45.9v105.7l13.1,7c-24.8,93.4-32.2,182.1-31,258.7c-1.1,76.6,6.2,165.3,31,258.7l-13.1,7v105.7l30.4,45.9H487v282.9
        h228.4c0,0,5.9,108.5,92.7,111.6h175l57.1,27.3h254.3v15.2c0,0,0,0,0.1,0v10.8c0,0,95.6,58.4,239.1,54.2
        c143.5,4.2,239.1-54.2,239.1-54.2v-10.8c0,0,0,0,0.1,0v-15.2h254.3l57.1-27.3h175c86.8-3.2,92.7-111.6,92.7-111.6h228.4v-282.9
        h118.9V1377l31.1-4.1l24.1-40.3c9.2-38.5,16.1-75.2,21.2-110c7-41.4,11.8-91.1,11.5-148.7C2787.4,1016.5,2782.5,966.8,2775.6,925.4z
        "/>
      <g>
        <path className="st1" d="M441.5,843.9l-8.2-58.7c-11.6,9.5-27.8,16-46.2,17.6v22.4L441.5,843.9z"/>
        <polygon className="st1" points="541.9,660.5 541.9,676.6 454.4,676.6 454.4,714 537.4,714 537.4,706.8 620.5,708 655.6,672.5 
          651.7,660.5 	"/>
        <ellipse className="st1" cx="378.5" cy="750.7" rx="68.8" ry="48.5"/>
        <path className="st1" d="M794.6,300.1h75.3v-37.9h-61.8c-86.8,3.2-92.7,111.6-92.7,111.6H487v282.9H317.6l-30.4,45.9v105.7l13.1,7
          c-1.1,4.2-2.2,8.3-3.2,12.5l47.7-17.1l38.2,13.1V803c-1.5,0.1-3.1,0.1-4.6,0.1c-40.2,0-72.8-23.6-72.8-52.5s32.7-52.5,72.8-52.5
          s72.8,23.6,72.8,52.5c0,12.3-5.9,23.5-15.7,32.5l1.3-0.2l8.8,62.4l78.7,27l86.3-154.6l5.8-5.9l-75.1-1.1v7.2h-91v-45.4h87.4v-16.1
          h116.8l4.1,12.9l27.4-27.6l190.4-185.6h-81.8V385L794.6,300.1L794.6,300.1z"/>
        <polygon className="st1" points="983.1,262.2 873.9,262.2 873.9,300.1 873.9,343.7 937,343.7 937,397.3 983.3,352.1 983.3,333.2 
          984.1,261.7 	"/>
        <polygon className="st1" points="869.9,344.7 869.9,304.1 798.6,304.1 798.6,385 891.7,385 920.5,413.4 933,401.2 933,347.7 
          869.9,347.7 	"/>
        <polygon className="st1" points="917.6,416.2 890.1,389 798.6,389 798.6,452.2 880.7,452.2 	"/>
      </g>
      <g>
        <polygon className="st1" points="601.8,1414.2 582.2,1394.1 578.7,1387.1 521.5,1428.9 493.4,1428.9 493.4,1428.5 456,1428.5 
          456,1463.3 488.2,1463.3 535.4,1461.6 	"/>
        <polygon className="st1" points="930.3,1771.9 946.8,1756.4 918.2,1728.1 889.2,1758.6 803.6,1758.6 803.9,1838 869.9,1838 
          869.9,1804.2 930.3,1804.2 	"/>
        <path className="st1" d="M442.9,1370.1c4.7,7,7.4,14.8,7.4,23.1c0,17.4-11.8,32.8-29.8,42.4v27.8H452v-38.9h42.2v0.4h26l56.8-41.4
          l-56.3-115.6l-97.4,39.7L442.9,1370.1z"/>
        <polygon className="st1" points="803.6,1754.6 887.5,1754.6 915.4,1725.3 879.6,1689.8 803.6,1689.8 	"/>
        <polygon className="st1" points="949.6,1759.2 934.3,1773.6 934.3,1808.2 873.8,1808.2 873.8,1841.9 873.1,1885.8 983.1,1885.8 
          988.8,1888.5 988.8,1839.4 982,1791.3 	"/>
        <path className="st1" d="M715.4,1774.2c0,0,5.9,108.5,92.7,111.6h61l0.7-43.9h-69.9l-0.2-83.4v-72.7h75.9l-256-253.6l-14.9-15.2
          l-67.9,48.5l-42.5,1.6v0.2H454h-2h-35.5v-29.8c-11.3,5.2-24.7,8.2-39,8.2c-40.2,0-72.8-23.6-72.8-52.5s32.7-52.5,72.8-52.5
          c2.9,0,5.7,0.1,8.5,0.4c-1.4-5-3.2-11.1-4.8-16.3l-94,38.3v82.3l30.4,45.9H487v282.9H715.4z"/>
        <path className="st1" d="M436.2,1362.1l-16.7-53l-34.5,14.1c2,6.7,4.3,14.5,5.3,18.2C409.1,1343.9,425.3,1351.5,436.2,1362.1z"/>
        <path className="st1" d="M308.7,1393.2c0,26.8,30.9,48.5,68.8,48.5s68.8-21.8,68.8-48.5c0-6.6-1.9-13-5.3-18.7l-0.8,0.3l-0.9-3
          c-11.2-16-34.7-27.1-61.7-27.1C339.5,1344.6,308.7,1366.4,308.7,1393.2z"/>
      </g>
      <g>
        <polygon className="st1" points="2259.1,1840.6 2259.1,1755 2161.2,1755 2139.5,1730.3 2119.3,1749.7 2119.3,1802.8 2187.5,1802 
          2187.5,1840.6 	"/>
        <polygon className="st1" points="2737.5,1361 2653.7,1362.3 2653.7,1373.7 2683.3,1403.3 2683.3,1446.7 2652.6,1477.5 2609.1,1477.5 
          2578.3,1446.7 2578.3,1403.3 2607.9,1373.7 2607.9,1363 2607.8,1363 2533,1320.7 2533,1402.3 2533.7,1441.1 2533,1441.1 
          2533,1489.5 2699.3,1489.5 2699.3,1377 2730.4,1372.9 	"/>
        <polygon className="st1" points="2582.3,1445.1 2610.7,1473.5 2650.9,1473.5 2679.3,1445.1 2679.3,1404.9 2649.7,1375.3 2649.7,1362.4 
          2611.9,1363 2611.9,1375.3 2582.3,1404.9 	"/>
        <polygon className="st1" points="2183.5,1806.1 2077.5,1807.2 2077.5,1839.2 2077.5,1873.3 2183.5,1873.3 	"/>
        <path className="st1" d="M2352,1774.2h0.4v-191.7h71.1l-63.1-64.6l-158.7,152.5l16.2,17.8h45.1v2v64.7v89.7h-75.6v32.7h-110v11.8
          l6.9-3.3h175C2346.1,1882.6,2352,1774.2,2352,1774.2z"/>
        <polygon className="st1" points="2580.4,1586.6 2356.4,1586.5 2356.4,1774.2 2580.4,1774.2 	"/>
        <polygon className="st1" points="2529,1441.1 2440.4,1441.1 2363.3,1515.2 2429,1582.5 2580.4,1582.5 2580.4,1493.5 2529,1493.5 	"/>
        <polygon className="st1" points="2198.9,1673.2 2142.4,1727.5 2163,1751 2259.1,1751 2259.1,1692.3 2216.2,1692.3 	"/>
        <polygon className="st1" points="2115.3,1802.8 2115.3,1753.5 2077.5,1789.9 2077.5,1803.2 	"/>
      </g>
      <g>
        <polygon className="st1" points="2482.8,622.2 2390.2,622.2 2390.2,656.4 2418.3,683.4 2480.2,683.4 2480.2,661.4 2527.5,661.4 
          2527.5,573.6 2482.8,573.6 	"/>
        <polygon className="st1" points="2118.5,339.7 2187,339.7 2187,262.2 2084.3,262.2 2074.4,257.5 2074.4,352.5 2118.5,394.8 	"/>
        <polygon className="st1" points="2191,262.2 2191,343.7 2122.5,343.7 2122.5,398.7 2138.1,413.8 2162,384.8 2257.8,384.8 2257.8,366.5 
          2257.8,262.2 	"/>
        <polygon className="st1" points="2204.5,477.6 2222,452 2257.8,452 2257.8,388.8 2163.9,388.8 2141,416.5 	"/>
        <polygon className="st1" points="2674.1,695.4 2645.7,667 2605.5,667 2577.1,695.4 2577.1,735.5 2606.7,765.1 2606.7,791.1 
          2644.5,791.9 2644.5,765.1 2674.1,735.5 	"/>
        <path className="st1" d="M2648.5,766.8V792l93.2,2.1l-11.4-19l-31.1-4.1V656.7h-118.9V373.8H2352c0,0-5.8-106.4-90.2-111.5v104.2v22.3
          V456h-37.7l-16.7,24.4l178.8,172.1v-34.3h92.6v-48.6h52.7v99.3h-0.7v17.5l29.3,33.2l-30.7,34.9l7.8,35.1l65.5,1.4v-24.2l-29.6-29.6
          v-43.5l30.8-30.7h43.4l30.8,30.7v43.5L2648.5,766.8z"/>
        <polygon id="section_coat-check" className="st1" points="2442.7,715.3 2419.4,688.4 2484.3,688.4 2484.3,666.6 2527.6,666.6 2527.6,688.4 
          2555.9,719.6 2527.2,751.5 2497.9,715.3 	"/>
      </g>
      <path id="top_border" className="st2" d="M2775.6,925.4c-5.1-34.8-12-71.5-21.2-110l-24.1-40.3l-31.1-4.1V656.7h-118.9V373.8H2352
        c0,0-5.9-108.5-92.7-111.6h-175l-57.1-27.3h-254.3v-15.2c0,0,0,0-0.1,0v-10.8c0,0-95.6-58.4-239.1-54.2
        c-143.5-4.2-239.1,54.2-239.1,54.2v10.8c0,0,0,0-0.1,0v15.2h-254.3l-57.1,27.3h-175c-86.8,3.2-92.7,111.6-92.7,111.6H487v282.9
        H317.6l-30.4,45.9v105.7l13.1,7c-24.8,93.4-32.2,182.1-31,258.7c-1.1,76.6,6.2,165.3,31,258.7l-13.1,7v105.7l30.4,45.9H487v282.9
        h228.4c0,0,5.9,108.5,92.7,111.6h175l57.1,27.3h254.3v15.2c0,0,0,0,0.1,0v10.8c0,0,95.6,58.4,239.1,54.2
        c143.5,4.2,239.1-54.2,239.1-54.2v-10.8c0,0,0,0,0.1,0v-15.2h254.3l57.1-27.3h175c86.8-3.2,92.7-111.6,92.7-111.6h228.4v-282.9
        h118.9V1377l31.1-4.1l24.1-40.3c9.2-38.5,16.1-75.2,21.2-110c7-41.4,11.8-91.1,11.5-148.7C2787.4,1016.5,2782.5,966.8,2775.6,925.4z
        "/>
      <g>
        <path className="st3" d="M1562.1,1058.7c-1.5-2.8-4.6-4.5-8.2-4.5h-26.5c-14.2-26.9-49.8-45.9-91.5-45.9s-77.3,19.1-91.5,45.9h-5.5
          c-4.7,0-10,2.9-12.5,6.9l-18.2,29c-1.9,3-2.1,6.5-0.6,9.3s4.6,4.5,8.2,4.5h27.6c13.4,27.9,49.8,47.9,92.5,47.9s79-20,92.5-47.9h2.4
          c4.7,0,10-2.9,12.5-6.9l18.2-29C1563.4,1064.9,1563.6,1061.5,1562.1,1058.7L1562.1,1058.7z"/>
        <path className="st4" d="M1558.8,1066.3l-18.2,29c-1.9,3.1-6.1,5.4-9.8,5.4h-4.5c-12.2,27.7-48.1,47.9-90.4,47.9
          c-30.3,0-59.1-10.6-76.9-28.5c-6-6-10.5-12.5-13.5-19.4h-29.7c-2.4,0-4.4-1-5.4-2.8s-0.8-4,0.5-6l18.2-29c1.9-3.1,6.2-5.4,9.8-5.4
          h7.4c3-6.2,7.2-12,12.6-17.4c17.8-17.8,46.6-28.5,76.9-28.5s59.1,10.6,76.9,28.5c5.4,5.4,9.6,11.3,12.6,17.4h28.5
          c2.4,0,4.4,1,5.3,2.8C1560.3,1062,1560.1,1064.2,1558.8,1066.3L1558.8,1066.3z M1562.1,1058.7c-1.5-2.8-4.6-4.5-8.2-4.5h-26.5
          c-14.2-26.9-49.8-45.9-91.5-45.9s-77.3,19.1-91.5,45.9h-5.5c-4.7,0-10,2.9-12.5,6.9l-18.2,29c-1.9,3-2.1,6.5-0.6,9.3
          s4.6,4.5,8.2,4.5h27.6c13.4,27.9,49.8,47.9,92.5,47.9s79-20,92.5-47.9h2.4c4.7,0,10-2.9,12.5-6.9l18.2-29
          C1563.4,1064.9,1563.6,1061.5,1562.1,1058.7L1562.1,1058.7z"/>
        <path className="st5" d="M1468.3,1123.3l6.9-2.5c1.4-0.6,1.5-1.7,0.2-2.4c0,0-2-1.1-5,0.1c-2.9,1.1-3.6,3.1-3.6,3.1
          C1466.1,1123.1,1466.9,1123.8,1468.3,1123.3L1468.3,1123.3z"/>
        <path className="st5" d="M1442,1126.7l6-0.4c1.3-0.1,1.6-1,0.7-1.9c0,0-1.3-1.3-4-1.1c-2.6,0.2-3.6,1.6-3.6,1.6
          C1440.3,1126,1440.7,1126.8,1442,1126.7L1442,1126.7z"/>
        <path className="st5" d="M1455.2,1126l6.6-1c1.4-0.3,1.6-1.2,0.6-2.1c0,0-1.6-1.3-4.5-0.8c-2.8,0.5-3.8,2.1-3.8,2.1
          C1453.3,1125.3,1453.8,1126.1,1455.2,1126L1455.2,1126z"/>
        <path className="st5" d="M1482.3,1117.4l6.7-4.4c1.4-1,1.2-2.2-0.4-2.6c0,0-2.4-0.7-5.4,1.3c-2.8,1.9-3.2,4.2-3.2,4.2
          C1479.8,1117.6,1480.8,1118.3,1482.3,1117.4L1482.3,1117.4z"/>
        <path className="st6" d="M1399.8,1100.7h10.5 M1349,1100.7c2.8,6,6.9,11.8,12.3,17.1c17.2,17.2,45.1,27.5,74.6,27.5
          c40.4,0,74.7-18.7,86.9-44.6"/>
        <path className="st6" d="M1521.9,1057.4c-2.8-5.3-6.6-10.4-11.3-15.2c-17.2-17.2-45.2-27.5-74.6-27.5c-29.5,0-57.4,10.3-74.6,27.5
          c-4.7,4.7-8.5,9.8-11.3,15.2"/>
        <path className="st4" d="M1553.9,1060.6c2.5,0,3.5,1.8,2.2,3.9l-18.2,29c-1.3,2.2-4.5,3.9-7.1,3.9h-215c-2.5,0-3.5-1.8-2.2-3.9l18.2-29
          c1.4-2.2,4.5-3.9,7.1-3.9H1553.9L1553.9,1060.6z"/>
        <path className="st3" d="M1354.3,1085.2h3.7c3.1,0,4.6-0.2,7.4-1.7c2.3-1.2,3.6-3.3,4.8-5.1c1.3-2.1,0.8-3.8,0.1-4.9
          c-0.5-0.8-1.6-1.5-3.6-1.8h-4.2L1354.3,1085.2L1354.3,1085.2z M1344,1085.4l8.4-14h-5.2l3.1-5.1h18.1c5.4,0,8.6,1,10.5,3.3
          s3,6.9,0.9,10.5c-1.9,3.2-3.8,6.4-8,8.7c-4.7,2.5-12,1.9-17.7,1.9h-18.4l3.1-5.1L1344,1085.4L1344,1085.4z"/>
        <polygon className="st3" points="1393.5,1085.5 1396.8,1085.5 1393.7,1090.6 1379.4,1090.6 1382.5,1085.5 1385.7,1085.5 1390.7,1077.2 
          1387.5,1077.2 1390.6,1072.1 1401.7,1072.1 	"/>
        <polygon className="st3" points="1470.5,1085.5 1473.8,1085.5 1470.7,1090.6 1456.4,1090.6 1459.5,1085.5 1462.7,1085.5 1467.7,1077.2 
          1464.5,1077.2 1467.6,1072.1 1478.7,1072.1 	"/>
        <polygon className="st3" points="1445.7,1076.2 1455.8,1071.4 1462.1,1071.5 1459.1,1076.4 1455.8,1076.5 1447.3,1080.5 1450.6,1084.9 
          1455.3,1084.9 1452.2,1090 1443.8,1090 1439.1,1083 1435.5,1090 1423.8,1090 1426.9,1084.9 1430.1,1084.9 1438.6,1070.8 
          1435.4,1070.9 1438.5,1065.7 1449.6,1065.7 1442.2,1077.9 	"/>
        <path className="st3" d="M1512.3,1085c-0.2,0.4-0.2,1.1,0.7,1.5c1,0.5,1.7,0.2,2.2,0.2c1.2,0,2.8-0.6,3.4-1.5c0.5-0.8,0.1-1-2-1.4
          l-0.4-0.1c-3.6-0.7-9-1.8-6.2-6.3c2.3-3.8,8.2-5.8,12.8-5.8c1.9,0,4.3,0.8,4.3,0.8l0.2-0.4h6.4l-3.7,6.1h-5.7c0,0,0.7-1.1-0.5-1.7
          c-0.8-0.4-1.6-0.3-2.2-0.3c-1.1,0-2.5,0.4-2.9,1.2c-0.4,0.7,0,1,2.2,1.4c3.5,0.6,9.3,1.6,6.5,6.4c-2.5,4.1-8.7,6-13.4,6
          c-3.1,0-4.8-1.1-4.8-1.1l-0.4,0.6h-6.5l3.9-6.5h6.6L1512.3,1085L1512.3,1085z"/>
        <path className="st3" d="M1488.4,1079c0,0,2-3.7,5.3-3.7c4.2,0,1.9,3.7,1.9,3.7H1488.4L1488.4,1079z M1501.7,1073.6
          c-1.5-2.1-5-2.5-8-2.5c-6.4,0-12.6,4.7-14.1,10.2c-0.8,2.6-0.2,5.1,1.5,7c1.7,1.8,4.4,2.8,7.5,2.8c9.3,0.1,11.1-6.6,11.1-6.6h-5.4
          l-0.3,0.3c-1.3,1.2-2.6,2-4.4,1.9c-4.2-0.2-2.1-4.5-2.1-4.5h14.3c0,0,0.8-1.9,0.8-2C1502.8,1078.4,1503.6,1076.3,1501.7,1073.6
          L1501.7,1073.6z"/>
        <path className="st3" d="M1397.4,1070.1c3,0,5.9-1.1,6.4-2.5s-1.6-2.5-4.7-2.5c-3,0-5.9,1.1-6.4,2.5
          C1392.3,1069,1394.4,1070.1,1397.4,1070.1L1397.4,1070.1z"/>
        <path className="st3" d="M1474.2,1070.1c3,0,5.9-1.1,6.4-2.5s-1.6-2.5-4.6-2.5s-5.9,1.1-6.4,2.5S1471.2,1070.1,1474.2,1070.1
          L1474.2,1070.1z"/>
        <path className="st3" d="M1420,1088.9l2.6-4.8h-4.4l-0.6,1c-1.3,1.2-2.1,1.8-4,1.8c-1.4,0-2.8,0.1-3.3-1.7c-0.3-0.9-0.2-2.2,0.3-3.4
          c1.3-2.6,3.5-4.3,6.1-5c1.6-0.4,2.9,0.7,3.2,1.9l0.1,0.4h4.4l3.6-7.2h-4.6c0,0,0,1.1-0.4,0.8c-1.1-0.9-2.6-1.3-4.5-1.3
          c-6,0-13.3,1.6-16.3,9.6c-1,2.8-0.6,5.1,0.9,7c1.8,2.4,3.9,3.2,6.5,3.4C1417.3,1092.2,1420,1088.9,1420,1088.9L1420,1088.9z"/>
        <path className="st7" d="M1392.2,1049.7l-3.6-12.3l-0.1-0.2h-0.3c-7.1,0.3-12.4-0.1-12.4-0.1h-0.5l0.1,0.4l3.8,13.3l0.1,0.2h0.2
          c3.8,0.1,7.7-0.2,12.3-0.8l0.4-0.1V1049.7L1392.2,1049.7z"/>
        <path className="st7" d="M1391.9,1107.8c-4.5-0.6-8.4-0.9-12.3-0.8h-0.2l-0.1,0.2l-3.8,13.3l-0.1,0.4h0.5c0.1,0,5.4-0.4,12.4-0.1h0.3
          l0.1-0.2l3.6-12.3l0.1-0.4L1391.9,1107.8L1391.9,1107.8z"/>
        <polygon className="st7" points="1410.6,1049.5 1410.6,1049.1 1410.2,1049.2 1399.7,1050.9 1399.5,1050.9 1399.5,1051.2 1399.5,1057.4 
          1399.8,1057.4 1410.3,1057.4 1410.6,1057.4 	"/>
        <path className="st7" d="M1480,1042.1c-0.2-1.7,0.8-2.3,2.2-1.5l6.7,4.4c1.4,1,1.2,2.2-0.4,2.6c0,0-2.4,0.7-5.4-1.3
          C1480.4,1044.4,1480,1042.1,1480,1042.1L1480,1042.1z M1462.3,1035.2c0,0-1.6,1.3-4.5,0.8c-2.8-0.5-3.8-2.1-3.8-2.1
          c-0.7-1.2-0.2-2,1.2-1.8l6.6,1C1463.1,1033.3,1463.4,1034.2,1462.3,1035.2L1462.3,1035.2z M1475.3,1039.6c0,0-2,1.1-5-0.1
          c-2.9-1.1-3.6-3.1-3.6-3.1c-0.5-1.4,0.2-2.2,1.7-1.7l6.9,2.5C1476.6,1037.8,1476.7,1038.8,1475.3,1039.6L1475.3,1039.6z
          M1442,1031.3l6,0.4c1.3,0.1,1.6,1,0.7,1.9c0,0-1.3,1.3-4,1.1c-2.6-0.2-3.6-1.6-3.6-1.6C1440.3,1032.1,1440.7,1031.3,1442,1031.3
          L1442,1031.3z M1507,1057.2c-15.2-18.5-35.1-28.4-59.3-29.2c-11.8-0.4-25.7,3-39.2,6.3l-2.4,0.6c-4,1-8.5,1.6-13.5,2h-0.4l0.1,0.4
          l3.4,11.9l0.1,0.3h0.3c2.2-0.4,4.4-0.8,6.7-1.2c9.6-1.8,20.5-3.9,35.9-4.1c26.3-0.4,40.8,11.7,42.8,13.4h25.7L1507,1057.2
          L1507,1057.2z"/>
        <polygon className="st7" points="1399.5,1100.6 1399.5,1100.7 1399.5,1107.1 1399.5,1107.4 1399.7,1107.4 1410.2,1109.1 1410.6,1109.2 
          1410.6,1108.8 1410.6,1100.7 1410.6,1100.6 	"/>
        <path className="st7" d="M1443.9,1114.2L1443.9,1114.2L1443.9,1114.2L1443.9,1114.2z M1483.2,1111.7c3-2,5.4-1.3,5.4-1.3
          c1.6,0.5,1.8,1.7,0.4,2.6l-6.7,4.4c-1.4,0.9-2.4,0.2-2.2-1.5C1480,1115.9,1480.4,1113.7,1483.2,1111.7L1483.2,1111.7z
          M1468.2,1126.6c-0.2,0.1-0.4,0.1-0.7,0.2C1467.8,1126.7,1468,1126.6,1468.2,1126.6L1468.2,1126.6z M1463.5,1127.8
          c-0.5,0.1-1,0.2-1.5,0.3C1462.5,1128,1463,1127.9,1463.5,1127.8L1463.5,1127.8z M1459.8,1128.5c-2.3,0.4-4.5,0.7-6.5,0.9
          C1455.3,1129.2,1457.5,1128.9,1459.8,1128.5L1459.8,1128.5z M1454,1124.1c0,0,1-1.6,3.8-2.1c2.9-0.5,4.5,0.8,4.5,0.8
          c1.1,0.9,0.8,1.9-0.6,2.1l-6.6,1C1453.8,1126.1,1453.3,1125.3,1454,1124.1L1454,1124.1z M1475.2,1120.8l-6.9,2.5
          c-1.5,0.5-2.2-0.3-1.7-1.7c0,0,0.7-1.9,3.6-3.1c3.1-1.2,5-0.1,5-0.1C1476.7,1119.2,1476.6,1120.3,1475.2,1120.8L1475.2,1120.8z
          M1448,1126.4l-6,0.4c-1.3,0-1.7-0.8-0.9-1.8c0,0,1-1.4,3.6-1.6c2.7-0.2,4,1.1,4,1.1C1449.6,1125.4,1449.3,1126.2,1448,1126.4
          L1448,1126.4z M1506.8,1100.6L1506.8,1100.6H1482l0,0h-0.5c-2,1.8-16.5,13.8-42.7,13.4c-15.4-0.2-26.3-2.3-35.9-4.1
          c-2.3-0.4-4.5-0.9-6.7-1.2h-0.3l-0.1,0.3l-3.4,11.9l-0.1,0.4h0.4c5,0.4,9.5,1.1,13.5,2l2.4,0.6c13.5,3.3,27.4,6.7,39.2,6.3
          c24.2-0.8,44.1-10.7,59.3-29.2l0.2-0.2h-0.5V1100.6z"/>
        <path className="st7" d="M1506.8,1100.6L1506.8,1100.6"/>
        <path className="st4" d="M1533.1,1110.5c0.7,0,1.2,0,1.2-0.8c0-0.6-0.6-0.7-1.1-0.7h-1v1.6L1533.1,1110.5L1533.1,1110.5z
          M1532.1,1113.3h-0.8v-5h1.9c1.2,0,1.8,0.4,1.8,1.4c0,0.9-0.6,1.3-1.3,1.4l1.4,2.2h-0.8l-1.3-2.2h-0.9L1532.1,1113.3L1532.1,1113.3
          z M1533.1,1114.5c2,0,3.5-1.5,3.5-3.6s-1.6-3.6-3.5-3.6c-2,0-3.6,1.6-3.6,3.6S1531.1,1114.5,1533.1,1114.5L1533.1,1114.5z
          M1528.6,1110.8c0-2.5,2-4.4,4.4-4.4s4.4,1.8,4.4,4.4c0,2.5-2,4.4-4.4,4.4C1530.7,1115.2,1528.6,1113.3,1528.6,1110.8
          L1528.6,1110.8z"/>
        <path className="st4" d="M1602,1080l8.7-9.1l-2.9,9.1H1602L1602,1080z M1606.5,1085l-0.2,0.3c-0.4,0.6-1.3,1-3,1.2l-2.6,4.1h17l1.9-3.7
          c-1.9-0.3-2.8-1.1-2.9-1.4c0-0.2,0-0.6,0.2-1.2l4.9-18.4H1607l-17.3,18l-2.3,1.9c-0.6,0.3-1.3,0.6-2.1,0.8l-2.6,4.1h13.2l2.6-4.2
          c-1.4-0.1-2-0.5-1.5-1.2l0.2-0.4l0.3-0.4l0.9-0.9h8.3l-0.3,1L1606.5,1085L1606.5,1085z"/>
        <path className="st4" d="M1643.5,1072.7c-2.2,0.1-4.2,0.8-4.9,1.3l0.8-1.5h-8.4l-1.8,3.6c0.2,0.1,0.5,0.2,0.7,0.3
          c0.4,0.3,0.4,0.8,0,1.5l-3.2,6.3c-0.4,0.9-0.7,1.4-0.9,1.6c-0.5,0.6-1.2,1.1-2.2,1.2l0,0c-0.2,0-0.4,0-0.6,0l-1.8,3.6h0.5h0.1h12.8
          l1.9-3.7c-0.5-0.1-3.5,0.2-3.5-0.6c0-0.3,0.1-0.9,0.6-1.8l1.6-3.2c0.6-1.3,1.4-2.2,2.2-2.7c1-0.6,2.2-1.3,3.5-1.3
          c0.5,0,1.1,0.1,1.5,0.2c1.2-1.8,2.9-3.4,4.9-4.5C1646.4,1072.8,1645.1,1072.6,1643.5,1072.7L1643.5,1072.7z"/>
        <path className="st4" d="M1686.1,1090.6h-8.1c0-0.2,0.1-0.5,0.2-1.1c0-0.2,0.1-0.5,0.2-0.9l1.6-3.5l2.9-5.7c0.7-1.4,0.9-2.2,0.6-2.6
          c-0.2-0.5-0.9-0.8-2.1-0.8c-1.1,0-2.2,0.3-3.4,0.9l-3.8,7.5c-0.5,0.9-0.7,1.5-0.6,1.8s0.4,0.5,0.9,0.6l-1.9,3.7h-10.2l1.9-3.7
          c0.7-0.2,1.3-0.4,1.7-0.8c0.3-0.3,0.7-1,1.2-2l3.2-6.3c0.3-0.6,0.4-0.9,0.4-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.3
          l1.8-3.6h8.7l-0.6,1.2c0.7-0.2,1.8-0.6,3.4-1c1.2-0.2,2.2-0.3,3-0.3c1.4,0,2.6,0.1,3.4,0.3c0.7,0.2,1.3,0.6,1.7,1.1
          c0.7,1,0.5,2.7-0.6,4.9l-2.9,5.7c-0.1,0.1-0.2,0.5-0.5,1.1c-0.2,0.4-0.2,0.7-0.2,0.9c0,0.2,0.4,0.4,1,0.7L1686.1,1090.6
          L1686.1,1090.6z"/>
        <path className="st4" d="M1705.8,1085.2l1.6-3.1c-2.4,0.6-3.9,1.1-4.7,1.3c-1.7,0.6-2.7,1.3-3.1,2.1c-0.5,1-0.1,1.5,1.2,1.5
          c0.7,0,1.4-0.2,2.3-0.5c0.2-0.1,0.7-0.2,1.3-0.5C1704.7,1085.8,1705.2,1085.6,1705.8,1085.2L1705.8,1085.2z M1711.9,1090.6h-8.5
          l1-1.9c-1.6,0.7-3.1,1.3-4.4,1.7c-1.5,0.4-2.9,0.6-4.2,0.6c-2,0-3.3-0.5-4-1.4c-0.7-1-0.7-2.2,0-3.7c0.8-1.6,2.3-2.9,4.5-3.9
          c2.1-0.9,6.4-2.1,13-3.4l0.5-1c0.7-1.4,0.2-2-1.6-2c-0.7,0-1.5,0.2-2.2,0.5c-0.7,0.5-1.1,1-1.4,1.6l-0.3,0.6h-7.1l2.1-4.2
          c4.2-1.3,7.9-1.9,11.1-1.9c3.3,0,5.4,0.5,6.4,1.4s0.9,2.5-0.2,4.7l-3.1,6.1c-0.4,0.8-0.7,1.3-0.7,1.5c-0.1,0.6,0.2,1,1,1.2
          L1711.9,1090.6L1711.9,1090.6z"/>
        <path className="st4" d="M1651.4,1079.8c0,0,1.8-3.5,4.9-3.5c3.9,0,1.8,3.5,1.8,3.5H1651.4L1651.4,1079.8z M1663.7,1074.7
          c-1.4-1.9-4.6-2.3-7.5-2.3c-5.9,0-11.7,4.4-13.1,9.4c-0.7,2.5-0.2,4.8,1.4,6.5s4.1,2.6,7,2.6c8.6,0,10.3-6.1,10.3-6.1h-5.1
          l-0.3,0.2c-1.2,1.1-2.4,1.8-4.1,1.7c-3.9-0.2-2-4.2-2-4.2h13.3c0,0,0.7-1.8,0.7-1.9C1664.8,1079.2,1665.5,1077.2,1663.7,1074.7
          L1663.7,1074.7z"/>
      </g>
      <g id="coat_check">
        <text transform="matrix(1 0 0 1 2453.5471 707.6513)" className="st8 st9 st10">Coat Check</text>
      </g>
      <g id="names">
        <text transform="matrix(1 0 0 1 1498.1104 216.3961)" className="st8 st9 st11">West </text>
        <text transform="matrix(1 0 0 1 1461.5104 252.3961)" className="st8 st9 st11">Concourse </text>
        <text transform="matrix(1 0 0 1 1483.7103 288.2961)" className="st8 st9 st11">Balcony</text>
        <text transform="matrix(1 0 0 1 1505.7056 1873.5414)" className="st8 st9 st11">East</text>
        <text transform="matrix(1 0 0 1 1459.8055 1909.5414)" className="st8 st9 st11">Concourse </text>
        <text transform="matrix(1 0 0 1 1482.0056 1945.4414)" className="st8 st9 st11">Balcony</text>
      </g>
      <g id="arena_top">
        <g id="suite_level_seating">
          <polygon className="st12" points="819.4,570.1 1000,384 1033.4,418.3 1996.4,418.3 2057.7,384 2239.7,570.1 2181.4,630.4 
            2117.5,560.6 941.8,560.6 874.6,630.4 		"/>
          <polyline className="st13" points="896.4,468.4 920.1,488.9 952.3,579.4 		"/>
          <line className="st13" x1="1060.5" y1="413.6" x2="1060.5" y2="579.4"/>
          <line className="st13" x1="1001.9" y1="576.9" x2="1001.9" y2="369.6"/>
          <line className="st13" x1="1118.4" y1="406.6" x2="1118.4" y2="579.4"/>
          <line className="st13" x1="1178.8" y1="409.8" x2="1178.8" y2="579.4"/>
          <line className="st13" x1="1237.2" y1="411.5" x2="1237.2" y2="573.9"/>
          <line className="st13" x1="1293.9" y1="406.6" x2="1293.9" y2="579.4"/>
          <line className="st13" x1="1351.4" y1="411.5" x2="1351.4" y2="579.4"/>
          <line className="st13" x1="1408.9" y1="413.6" x2="1408.9" y2="576.9"/>
          <line className="st13" x1="1470.7" y1="409.8" x2="1470.7" y2="579.4"/>
          <line className="st13" x1="1529.6" y1="411.5" x2="1529.6" y2="573.9"/>
          <line className="st13" x1="1587.2" y1="408.1" x2="1587.2" y2="570.9"/>
          <line className="st13" x1="1644.9" y1="409.8" x2="1644.9" y2="570.9"/>
          <line className="st13" x1="1702.9" y1="413.6" x2="1702.9" y2="582.4"/>
          <line className="st13" x1="1764.2" y1="404.7" x2="1764.2" y2="573.9"/>
          <line className="st13" x1="1822.1" y1="408.1" x2="1822.1" y2="578.1"/>
          <line className="st13" x1="1880.1" y1="413.6" x2="1880.1" y2="573.9"/>
          <line className="st13" x1="1935.9" y1="408.1" x2="1935.9" y2="587.4"/>
          <line className="st13" x1="1995.8" y1="398.5" x2="1995.8" y2="587.4"/>
          <line className="st13" x1="2058.1" y1="578.1" x2="2058.1" y2="357.4"/>
          <line className="st13" x1="2154.9" y1="457.8" x2="2111.8" y2="580.2"/>
          <polyline className="st13" points="2058.1,524 2127,524 2223,615.6 		"/>
          <line className="st13" x1="2128.7" y1="609.3" x2="2215" y2="524"/>
          <polygon className="st12" points="2322.8,1299.2 2322.8,1372.4 2254.3,1439.2 2313.5,1495.4 2398.5,1404.5 2398.5,1299.2 		"/>
          <polygon className="st12" points="2328.3,846.9 2328.3,773.8 2259.8,707 2319,650.7 2404,741.6 2404,846.9 		"/>
          <polygon className="st12" points="653.2,739.7 743.4,645.8 800.5,702.5 729.3,775.2 		"/>
          <polygon className="st12" points="650.9,1403.1 741.1,1497 798.2,1440.3 727,1367.6 		"/>
          <g>
            <polygon className="st12" points="816.2,1570.5 996.8,1756.6 1030.2,1722.3 1993.2,1722.3 2054.5,1756.6 2236.5,1570.5 
              2178.2,1510.2 2114.3,1580 938.6,1580 871.4,1510.2 			"/>
            <polyline className="st13" points="893.2,1672.2 916.9,1651.7 949.1,1561.2 			"/>
            <line className="st13" x1="1057.3" y1="1726.9" x2="1057.3" y2="1561.2"/>
            <line className="st13" x1="998.8" y1="1563.7" x2="998.8" y2="1770.9"/>
            <line className="st13" x1="1115.2" y1="1734" x2="1115.2" y2="1561.2"/>
            <line className="st13" x1="1175.6" y1="1730.8" x2="1175.6" y2="1561.2"/>
            <line className="st13" x1="1234" y1="1729.1" x2="1234" y2="1566.7"/>
            <line className="st13" x1="1290.7" y1="1734" x2="1290.7" y2="1561.2"/>
            <line className="st13" x1="1348.2" y1="1729.1" x2="1348.2" y2="1561.2"/>
            <line className="st13" x1="1405.7" y1="1726.9" x2="1405.7" y2="1563.7"/>
            <line className="st13" x1="1467.5" y1="1730.8" x2="1467.5" y2="1561.2"/>
            <line className="st13" x1="1526.4" y1="1729.1" x2="1526.4" y2="1566.7"/>
            <line className="st13" x1="1584" y1="1732.4" x2="1584" y2="1569.6"/>
            <line className="st13" x1="1641.7" y1="1730.8" x2="1641.7" y2="1569.6"/>
            <line className="st13" x1="1699.7" y1="1726.9" x2="1699.7" y2="1558.2"/>
            <line className="st13" x1="1761" y1="1735.8" x2="1761" y2="1566.7"/>
            <line className="st13" x1="1818.9" y1="1732.4" x2="1818.9" y2="1562.4"/>
            <line className="st13" x1="1876.9" y1="1726.9" x2="1876.9" y2="1566.7"/>
            <line className="st13" x1="1932.7" y1="1732.4" x2="1932.7" y2="1553.1"/>
            <line className="st13" x1="1992.6" y1="1742.1" x2="1992.6" y2="1553.1"/>
            <line className="st13" x1="2054.9" y1="1562.4" x2="2054.9" y2="1783.2"/>
            <line className="st13" x1="2151.7" y1="1682.8" x2="2108.6" y2="1560.3"/>
            <polyline className="st13" points="2054.9,1616.6 2123.8,1616.6 2219.8,1525 			"/>
            <line className="st13" x1="2125.5" y1="1531.3" x2="2211.8" y2="1616.6"/>
          </g>
          <line className="st13" x1="681.1" y1="1373.2" x2="798.2" y2="1486.1"/>
          <line className="st13" x1="697" y1="1466.2" x2="736" y2="1426.2"/>
          <polyline className="st13" points="2253.1,663 2362.2,758.8 2362.2,861.9 		"/>
          <line className="st13" x1="2310.5" y1="778.8" x2="2418.3" y2="735.3"/>
          <line className="st13" x1="2283.8" y1="748.8" x2="2370.4" y2="672.2"/>
          <line className="st13" x1="2305.2" y1="1363.7" x2="2444.6" y2="1423.8"/>
          <polyline className="st13" points="2258.4,1482.6 2359.9,1384 2359.9,1277.7 		"/>
          <line className="st13" x1="2286.7" y1="1390.3" x2="2368.7" y2="1472.3"/>
          <line className="st13" x1="733.9" y1="1426.2" x2="772.9" y2="1386.1"/>
        </g>
        <g id="suite__x23_s">
          <text transform="matrix(1 0 0 1 2085.6079 502.9451)" className="st14 st9 st15">2B</text>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 849.6767 563.1296)" className="st16 st9 st15">Party 1A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 922.8795 495.7908)" className="st16 st9 st15">Party 1B</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 2008.4866 495.7905)" className="st16 st9 st15">18A</text>
            </g>
          </g>
          <text transform="matrix(1 0 0 1 2077.165 551.0332)" className="st14 st9 st15">2A</text>
          <text transform="matrix(1 0 0 1 2153.7236 535.5133)" className="st14 st9 st15">3C</text>
          <text transform="matrix(1 0 0 1 2131.3311 571.5796)" className="st14 st9 st15">3B</text>
          <text transform="matrix(1 0 0 1 2164.4402 606.986)" className="st14 st9 st15">3A</text>
          <text transform="matrix(1 0 0 1 2193.5999 575.3853)" className="st14 st9 st15">3D</text>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 686.6106 732.1626)" className="st14 st9 st15">1C</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 709.0438 1397.6503)" className="st14 st9 st15">10A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 676.0381 1424.6997)" className="st14 st9 st15">10D</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 720.866 1466.958)" className="st14 st9 st15">10C</text>
            </g>
          </g>
          <line className="st13" x1="791.1" y1="666.1" x2="686.6" y2="771.9"/>
          <line className="st13" x1="691.3" y1="675.4" x2="772" y2="743.6"/>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 723.8263 754.0837)" className="st14 st9 st15">1B</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 726.8499 688.9359)" className="st14 st9 st15">1D</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 759.9851 715.62)" className="st14 st9 st15">1A</text>
            </g>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 842.7008 1580.7052)" className="st16 st9 st15">Party 2A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 920.2625 1655.2993)" className="st16 st9 st15">Party 2B</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1185.5886 1655.2991)" className="st16 st9 st15">33A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1127.1343 1655.2991)" className="st16 st9 st15">34A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1068.6804 1655.2997)" className="st16 st9 st15">35A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1010.2255 1655.2996)" className="st16 st9 st15">36A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1244.0426 1655.299)" className="st16 st9 st15">32A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1302.4972 1655.2993)" className="st16 st9 st15">31A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1360.9514 1655.2992)" className="st16 st9 st15">30A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1419.4056 1655.2993)" className="st16 st9 st15">29A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1477.8596 1655.2996)" className="st16 st9 st15">28A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1536.3145 1655.2991)" className="st16 st9 st15">27A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1594.7683 1655.2993)" className="st16 st9 st15">26A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1653.2227 1655.2991)" className="st16 st9 st15">25A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1711.677 1655.2992)" className="st16 st9 st15">24A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1770.1313 1655.2993)" className="st16 st9 st15">23A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1828.5851 1655.2992)" className="st16 st9 st15">22A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1887.0393 1655.2993)" className="st16 st9 st15">21A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 1945.4938 1655.2996)" className="st16 st9 st15">20A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 2003.9484 1655.299)" className="st16 st9 st15">19A</text>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 2077.5002 1657.8363)" className="st14 st9 st15">9B</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 2075.3 1604.9429)" className="st14 st9 st15">9A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 2128.0999 1583.2427)" className="st14 st9 st15">8A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 2163.6689 1547.5066)" className="st14 st9 st15">8B</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 2190.9067 1580.7053)" className="st14 st9 st15">8C</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 2148.8276 1620.8525)" className="st14 st9 st15">8D</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1949.4766 495.7905)" className="st16 st9 st15">17A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1891.1064 495.7905)" className="st16 st9 st15">16A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1832.7361 495.7905)" className="st16 st9 st15">15A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1774.3665 495.7905)" className="st16 st9 st15">14A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1715.6965 495.7904)" className="st16 st9 st15">13A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1657.426 495.7908)" className="st16 st9 st15">12A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1599.8562 495.7904)" className="st16 st9 st15">11A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1540.4861 495.7903)" className="st16 st9 st15">10A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1487.0861 495.7904)" className="st16 st9 st15">9A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1428.7158 495.7905)" className="st16 st9 st15">8A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1370.3457 495.7904)" className="st16 st9 st15">7A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1311.9758 495.7905)" className="st16 st9 st15">6A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1253.6058 495.7908)" className="st16 st9 st15">5A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1195.2357 495.7908)" className="st16 st9 st15">4A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1136.8656 495.7908)" className="st16 st9 st15">3A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1078.4955 495.7908)" className="st16 st9 st15">2A</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1020.1254 495.7905)" className="st16 st9 st15">1A</text>
            </g>
          </g>
          <text transform="matrix(1 0 0 1 2305.1533 690.7172)" className="st14 st9 st15">4C</text>
          <text transform="matrix(1 0 0 1 2273.6631 720.4259)" className="st14 st9 st15">4B</text>
          <text transform="matrix(1 0 0 1 2343.1096 730.5923)" className="st14 st9 st15">4D</text>
          <text transform="matrix(1 0 0 1 2309.1504 757.0442)" className="st14 st9 st15">4A</text>
          <text transform="matrix(1 0 0 1 2370.3994 810.0538)" className="st14 st9 st15">5B</text>
          <text transform="matrix(1 0 0 1 2331.8999 810.0538)" className="st14 st9 st15">5A</text>
          <g>
            <text transform="matrix(1 0 0 1 2328.4661 1346.1833)" className="st14 st9 st15">6A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 2368.9109 1346.1837)" className="st14 st9 st15">6B</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 2309.1504 1401.3501)" className="st14 st9 st15">7B</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 2273.6626 1435.7395)" className="st14 st9 st15">7A</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 2344.2888 1425.322)" className="st14 st9 st15">7C</text>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 2302.0747 1466.9575)" className="st14 st9 st15">7D</text>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 750.8439 1435.7397)" className="st14 st9 st15">10B</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 703.9872 1358.3329)" className="st14 st9 st10">Loge Box</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 2218.9004 1510.2)" className="st14 st9 st10">Loge Box</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 703.9872 796.7211)" className="st14 st9 st10">Loge Box</text>
            </g>
          </g>
          <g>
            <text transform="matrix(1 0 0 1 2218.8999 639.3999)" className="st14 st9 st10">Loge Box</text>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1509.3428 1749.5996)" className="st16 st9 st10">SUITES</text>
            </g>
          </g>
          <g>
            <g>
              <text transform="matrix(1 0 0 1 1509.343 401.3003)" className="st16 st9 st10">SUITES</text>
            </g>
          </g>
        </g>
        <g id="main_level_seating">
          <g>
            <polygon className="st12" points="985.2,610 1059.5,810.8 1161.8,810.8 1161.8,608.6 			"/>
            <polygon className="st12" points="1178.8,608.6 1178.8,888.8 1305.2,888.8 1305.2,608.8 			"/>
            <polygon className="st12" points="1049.8,1018 887.5,1018 887.5,1144.4 1049.7,1144.4 			"/>
            <polygon className="st12" points="1233.8,1021.2 1058.4,1021.2 1058.4,1147.6 1233.6,1147.6 			"/>
            <polygon className="st12" points="1233.8,939 1058.4,939 1058.4,1003.7 1233.6,1003.7 			"/>
            <polygon className="st12" points="1233.8,1163.9 1058.4,1163.9 1058.4,1228.5 1233.6,1228.5 			"/>
            <rect x="1321.1" y="625.2" className="st12" width="128.5" height="293.5"/>
            <rect x="1465.5" y="608.6" className="st12" width="121.7" height="312.6"/>
            <rect x="1605.4" y="625.2" className="st12" width="127.7" height="296"/>
            <rect x="1748.2" y="609.3" className="st12" width="128" height="279.4"/>
            <rect x="1812.2" y="935.5" className="st12" width="176.1" height="69.1"/>
            <rect x="1812.2" y="1164.1" className="st12" width="176.1" height="66"/>
            <rect x="1812.2" y="1019.4" className="st12" width="176.1" height="125"/>
            <polygon className="st12" points="1891,610 1891,887.6 1953.2,887.6 2074.4,610 			"/>
            <polygon className="st12" points="2042,719.2 2090.8,764.2 2102.2,775 2175.6,704.1 2087.1,615.6 			"/>
            <polygon className="st12" points="1995.8,935.5 2271.1,822.7 2271.1,1004.6 1995.8,1004.6 			"/>
            <rect x="1995.8" y="1019.4" className="st12" width="275.3" height="125"/>
            <polygon className="st12" points="1953.5,1280.2 2064.6,1558.9 1891,1558.9 1891,1280.2 			"/>
            <rect x="1748" y="1271.1" className="st12" width="124.6" height="287.8"/>
            <rect x="1605" y="1247.4" className="st12" width="124.6" height="295.7"/>
            <polygon className="st12" points="1588.6,1485.5 1588.6,1556.5 1464.1,1556.5 1464.1,1247.8 1588.6,1247.8 			"/>
            <rect x="1319.7" y="1246.1" className="st12" width="130.3" height="297"/>
            <rect x="1175.5" y="1271.1" className="st12" width="124.3" height="287.8"/>
            <polygon className="st12" points="1161.4,1290.5 1161.4,1558.9 984.5,1558.9 1091.3,1290.5 			"/>
            <polygon className="st12" points="2018.3,835.4 1996.4,818.2 2042,719.2 2090.8,764.2 			"/>
            <polygon className="st12" points="1989.7,1248.8 2276.2,1368 2188.9,1459.9 2055,1322.5 2040.7,1335.5 2175.6,1472 2089.9,1553.9 
              1969.2,1271.1 			"/>
            <polygon className="st12" points="968.3,614.9 885,703.8 954.7,770.3 942.3,782.6 871.9,717.4 800.5,796.5 892.5,832.1 892.5,832.1 
              990.8,872.4 1046.9,813.8 			"/>
            <polygon className="st12" points="2182.7,712.1 2266.9,802.7 2064.6,887.6 2049.8,870 2122,796.4 2111.2,783.7 			"/>
            <path className="st12" d="M959.8,1553.9c0,0,88.1-199.2,86.7-200.2c-1.4-0.9-66-59.9-66-59.9l-189,77.9l78.9,76.1l117-121.3
              l12.6,11.2l-126.9,132L959.8,1553.9z"/>
            <polygon className="st12" points="2276.2,1164.1 2276.2,1346.2 1995.8,1233.2 1995.8,1164.1 			"/>
            <path className="st12" d="M887.5,861.4c2.9,0.7,162.3,67,162.3,67v76.3H887.5V861.4z"/>
            <polygon className="st12" points="887.5,1163.9 887.5,1305.9 1049.8,1241.8 1049.8,1164.1 			"/>
          </g>
        </g>
        <g id="stairs">
          <polyline className="st17" points="349.8,1380.2 366.9,1380.2 366.9,1397.9 385.3,1397.9 385.3,1415.1 405,1415.1 		"/>
          <polyline className="st17" points="904.5,1826.1 921.6,1826.1 921.6,1843.9 940,1843.9 940,1861 959.7,1861 		"/>
          <polyline className="st17" points="353.5,735.4 370.6,735.4 370.6,753.1 389,753.1 389,770.3 408.7,770.3 		"/>
          <polyline className="st17" points="905.1,287.4 922.2,287.4 922.2,305.1 940.6,305.1 940.6,322.3 960.3,322.3 		"/>
          <polyline className="st17" points="2117.5,271.9 2134.6,271.9 2134.6,289.7 2153,289.7 2153,306.8 2172.7,306.8 		"/>
          <polyline className="st17" points="2600.8,702.5 2618,702.5 2618,720.2 2636.4,720.2 2636.4,737.4 2656,737.4 		"/>
          <polyline className="st17" points="2607.8,1406.2 2624.9,1406.2 2624.9,1423.9 2643.3,1423.9 2643.3,1441.1 2663,1441.1 		"/>
          <polyline className="st17" points="2100.5,1825.2 2117.6,1825.2 2117.6,1842.9 2136,1842.9 2136,1860.1 2155.7,1860.1 		"/>
        </g>
      </g>
      <polygon className="st18" points="944.7,1702.8 964.2,1683.8 997.2,1706.7 1015.7,1737.2 998.8,1758.4 "/>
      <polygon className="st18" points="2028.3,1741.9 2053.2,1702.4 2082.8,1674.4 2109.5,1700.3 2054.5,1756.6 "/>
      <polygon className="st18" points="947.9,437.7 967.4,456.8 1000.4,433.9 1018.9,403.4 1001.9,382.2 "/>
      <polygon className="st18" points="2031.5,398.7 2056.4,438.2 2086,466.2 2112.7,440.3 2057.7,384 "/>
      <g id="avion_club_bg_1_">
        <ellipse id="section_avion-club" className="st1" cx="2641.5" cy="1074.1" rx="110.9" ry="226.1"/>
      </g>
      <path id="section_tx-tavern" className="st1" d="M2355,950.7c0,0-24.1,122.2,0,242.3l62.6,16.5V933.8L2355,950.7z"/>
      <g id="reliant_club_bg">
        <path className="st1" d="M353.5,865.1l157.9,49.5c0,0-54.9,140.7,0,314l-157.9,54C353.5,1282.5,282.7,1023,353.5,865.1z"/>
        <polygon id='section_reliant-club' className="st1" points="664,780.6 664,783.9 664,1363.5 761.7,1307.7 761.7,833.4 	"/>
      </g>
      <g id="reliant_club_1_">
        <text transform="matrix(1 0 0 1 528.6233 1049.1036)" className="st8 st9 st11">Reliant</text>
        <text transform="matrix(1 0 0 1 543.7233 1085.1036)" className="st8 st9 st11">Club</text>
      </g>
      <g id="elevator_3">
        <g>
          <circle className="elevators st19" cx="2510.7" cy="615.6" r="24.8"/>
        </g>
        <g>
          <path className="st3" d="M2509,601.9h-8.5c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5V601.9L2509,601.9z M2503.9,613.9
            l2.6,3.4h-5.1L2503.9,613.9z"/>
          <path className="st3" d="M2521,601.9h-8.5v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7v-23.9C2522.7,602.7,2521.9,601.9,2521,601.9L2521,601.9z
            M2517.6,617.3l-2.6-3.4h5.1L2517.6,617.3z"/>
        </g>
      </g>
      <g id="elevator_2">
        <g>
          <circle className="elevators st19" cx="486.5" cy="1445.9" r="24.8"/>
        </g>
        <g>
          <path className="st3" d="M484.8,1432.3h-8.5c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5V1432.3L484.8,1432.3z
            M479.7,1444.2l2.6,3.4h-5.1L479.7,1444.2z"/>
          <path className="st3" d="M496.8,1432.3h-8.5v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7V1434C498.5,1433,497.7,1432.3,496.8,1432.3L496.8,1432.3z
            M493.4,1447.6l-2.6-3.4h5.1L493.4,1447.6z"/>
        </g>
      </g>
      <g id="elevator_1">
        <g>
          <circle className="elevators st19" cx="477.3" cy="692.9" r="24.8"/>
        </g>
        <g>
          <path className="st3" d="M475.6,679.3H467c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5L475.6,679.3L475.6,679.3z
            M470.4,691.2l2.6,3.4h-5.1L470.4,691.2z"/>
          <path className="st3" d="M487.5,679.3H479v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7V681C489.2,680.1,488.4,679.3,487.5,679.3L487.5,679.3z
            M484.1,694.6l-2.6-3.4h5.1L484.1,694.6z"/>
        </g>
      </g>
      <g id="rr_family_4">
        <g>
          <circle className="family-restrooms st19" cx="977.1" cy="403.9" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M992.1,398.4c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V406h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L992.1,398.4z"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st3" d="M982.6,403.6c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2c0,0-1.9-5.2-2.6-7.1
                c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7c-0.2,0.6,0.1,1.2,0.6,1.4
                c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.7
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7
                c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9c0.2,0.4,0.5,0.7,1,0.7
                c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
                c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3c0,0.5,0.4,0.9,0.9,0.9
                s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.6c0,0.4,0.3,0.8,0.8,0.8l0,0
                c0.4,0,0.8-0.3,0.8-0.8l0,0C982.6,408,982.6,404.6,982.6,403.6z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="986.7" cy="392.7" r="2.2"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="978.8" cy="399.2" r="2"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st3" d="M967.2,390.5c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S968.4,390.5,967.2,390.5z"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_family_3">
        <g>
          <circle className="family-restrooms st19" cx="2080.1" cy="404.1" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M2095,398.6c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.1h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L2095,398.6z"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st3" d="M2085.6,403.8c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2
                c0,0-1.9-5.2-2.6-7.1c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7
                c-0.2,0.6,0.1,1.2,0.6,1.4c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7
                c0.2,0,0.5,0,0.8,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3
                s1.3-0.6,1.3-1.3v-7.7c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9
                c0.2,0.4,0.5,0.7,1,0.7c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1
                c0.1,0,0.1,0.1,0.1,0.1c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3
                c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v5.6c0,0.4,0.3,0.8,0.8,0.8
                l0,0c0.4,0,0.8-0.3,0.8-0.8l0,0C2085.6,408.2,2085.6,404.8,2085.6,403.8z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="2089.7" cy="392.9" r="2.2"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="2081.7" cy="399.3" r="2"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st3" d="M2070.2,390.7c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S2071.4,390.7,2070.2,390.7z"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_family_2">
        <g>
          <circle className="family-restrooms st19" cx="2073.1" cy="1715.5" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M2088.1,1710c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1s0.1,0.1,0.1,0.2v17.6
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.1h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L2088.1,1710z"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st3" d="M2078.6,1715.2c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2
                c0,0-1.9-5.2-2.6-7.1c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7
                c-0.2,0.6,0.1,1.2,0.6,1.4c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7
                c0.2,0,0.5,0,0.8,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3
                s1.3-0.6,1.3-1.3v-7.7c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9
                c0.2,0.4,0.5,0.7,1,0.7c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1
                c0.1,0,0.1,0.1,0.1,0.1c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3
                c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.6
                c0,0.4,0.3,0.8,0.8,0.8l0,0c0.4,0,0.8-0.3,0.8-0.8l0,0C2078.6,1719.7,2078.6,1716.3,2078.6,1715.2z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="2082.7" cy="1704.4" r="2.2"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="2074.8" cy="1710.8" r="2"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st3" d="M2063.2,1702.1c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S2064.4,1702.1,2063.2,1702.1z"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_family_1">
        <g>
          <circle className="family-restrooms st19" cx="982.5" cy="1715.5" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M997.4,1710c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.2h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L997.4,1710z"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st3" d="M988,1715.2c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2c0,0-1.9-5.2-2.6-7.1
                c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7c-0.2,0.6,0.1,1.2,0.6,1.4
                c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.7
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7
                c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9c0.2,0.4,0.5,0.7,1,0.7
                c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
                c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3c0,0.5,0.4,0.9,0.9,0.9
                s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.6c0,0.4,0.3,0.8,0.8,0.8l0,0
                c0.4,0,0.8-0.3,0.8-0.8l0,0C988,1719.6,988,1716.3,988,1715.2z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="992.1" cy="1704.3" r="2.2"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="984.1" cy="1710.8" r="2"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st3" d="M972.6,1702.1c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S973.8,1702.1,972.6,1702.1z"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_men_4">
        <g>
          <circle className="mens-restrooms st19" cx="841" cy="420.8" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M844,412.2c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.6h0.6
                v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2l0,0v8.1
                c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C846.6,413.4,845.4,412.2,844,412.2z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="841" cy="409.2" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_men_3">
        <g>
          <circle className="mens-restrooms st19" cx="2195.8" cy="424" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M2198.8,415.5c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V426h0.6
                v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2l0,0v8.1
                c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C2201.4,416.6,2200.3,415.5,2198.8,415.5z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="2195.9" cy="412.4" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_men_2">
        <g>
            <circle className="mens-restrooms st19" cx="2201.8" cy="1720.6" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M2204.8,1712c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                v-10.6h0.6v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2l0,0v8.1
                c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C2207.4,1713.1,2206.3,1712,2204.8,1712z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="2201.9" cy="1709" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_men_1">
        <g>
          <circle className="mens-restrooms st19" cx="839.5" cy="1722.2" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M842.5,1713.6c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                v-10.6h0.6v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2l0,0
                v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C845.1,1714.8,844,1713.6,842.5,1713.6z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="839.6" cy="1710.6" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_women_4">
        <g>
          <circle className="womens-restrooms st19" cx="2224.8" cy="344.3" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M2231.6,344.7c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C2231.5,345.9,2231.8,345.2,2231.6,344.7z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="2224.8" cy="332.8" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_women_3">
        <g>
          <circle className="womens-restrooms st19" cx="831.8" cy="344.7" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M838.6,345.1c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C838.5,346.3,838.8,345.7,838.6,345.1z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="831.8" cy="333.2" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_women_2">
        <g>
          <circle className="womens-restrooms st19" cx="839.5" cy="1797.4" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M846.3,1797.8c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C846.2,1799,846.5,1798.4,846.3,1797.8z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="839.6" cy="1785.9" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_women_1">
        <g>
          <circle className="womens-restrooms st19" cx="2219.8" cy="1791.3" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M2226.6,1791.7c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C2226.5,1792.9,2226.8,1792.3,2226.6,1791.7z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st3" cx="2219.8" cy="1779.8" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="avion_club_1_">
        <text transform="matrix(1 0 0 1 2438.3972 1049.1035)" className="st8 st9 st11">Avion</text>
        <text transform="matrix(1 0 0 1 2444.6973 1085.1035)" className="st8 st9 st11">Club</text>
      </g>
      </svg>


    </div>
  )
}

export default SuiteLevel;