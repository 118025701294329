import React, { useEffect } from 'react';

import PageTitle from '../../components/PageTitle';
import Footer from '../../components/Footer';
import InfoExpander from '../../components/InfoExpander';

function ADAServices(props) {
  const { fields } = props;

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
  }, []);

  return (
    <div className='ada-services'>
      <PageTitle title='ADA Services' />
      
      <div
        className='ada-services__copy'
        dangerouslySetInnerHTML={ { __html: fields.ada_services_top_copy } }></div>

      <div className='ada-services__info'>
        <InfoExpander 
          title='Assisted Listening Devices'
          copy={ fields.ada_services_listening_copy } />

        <InfoExpander
          title='Companion Seating'
          copy={ fields.ada_services_companion_copy } />

        <InfoExpander
          title='Concessions'
          copy={ fields.ada_services_concessions_copy } />

        <InfoExpander
          title='Elevators'
          copy={ fields.ada_services_elevators_copy } />

        <InfoExpander
          title='Emergencies'
          copy={ fields.ada_services_emergencies_copy } />

        <InfoExpander
          title='Escalators'
          copy={ fields.ada_services_escalators_copy } />

        <InfoExpander
          title='Escorts'
          copy={ fields.ada_services_escorts_copy } />

        <InfoExpander
          title='Interpreting Services'
          copy={ fields.ada_services_interpreting_copy } />

        <InfoExpander
          title='Accessible Parking'
          copy={ fields.ada_services_parking_copy } />

        <InfoExpander
          title='Restrooms'
          copy={ fields.ada_services_restrooms_copy } />

        <InfoExpander
          title='Seating/Ticket Information'
          copy={ fields.ada_services_seating_copy } />

        <InfoExpander
          title='Service Animals'
          copy={ fields.ada_services_animals_copy } />

        <InfoExpander
          title='Mobility Device Storage'
          copy={ fields.ada_services_mobility_copy } />
      </div>


      <div 
        className='ada-services__copy__bottom'
        dangerouslySetInnerHTML={ { __html: fields.ada_services_bottom_copy } }></div>
      <Footer />
    </div>
  )
}

export default ADAServices;