import React from 'react';

function Defense(props) {
  const { showHome, stats } = props;
  console.log('showHome ', showHome);

  // let generatedRows = '';

  let generatedRows = `
    <tr>
      <td>Mr. Sports</td>
      <td class='numeric'>2</td>
      <td class='numeric'>6</td>
      <td class='numeric'>1</td>
    </tr>
  `;

  if (stats) {
    let defending = [];
    stats.forEach(summary => {
      if (summary.stats && summary.stats[ 0 ].$.hasOwnProperty('blk')) {
        let defendingObj = {
          name: summary.$.name,
          blocks: +summary.stats[ 0 ].$.blk,
          steals: +summary.stats[ 0 ].$.stl,
          rebounds: +summary.stats[ 0 ].$.treb
        }

        if (defendingObj.blocks || defendingObj.steals || defending.rebounds) {
          defending.push(defendingObj);
        }
      }
    });

    defending.forEach(defend => {
      generatedRows = generatedRows + `
          <tr>
            <td>${ defend.name || ' ' }</td>
            <td class='numeric'>${ defend.blocks || 0 }</td>
            <td class='numeric'>${ defend.steals || 0 }</td>
            <td class='numeric'>${ defend.rebounds || 0 }</td>
          </tr>
        `;
    })

    let totals = defending.reduce((total, current) => {
      total = {
        name: 'Team Totals',
        blocks: (total.blocks || 0) + current.blocks,
        steals: (total.steals || 0) + current.steals,
        rebounds: (total.rebounds || 0) + current.rebounds
      };

      return total;
    }, {});

    generatedRows = generatedRows + `
        <tr>
          <td>${ totals.name || ' ' }</td>
          <td class='numeric'>${ totals.blocks || 0 }</td>
          <td class='numeric'>${ totals.steals || 0 }</td>
          <td class='numeric'>${ totals.rebounds || 0 }</td>
        </tr>
      `;
  }

  return (
    <div className='sport__stats__table'>
      <table className='sport__stats__table__body'>
        <thead>
          <tr>
            <td>Name</td>
            <td className='numeric'>Blocks</td>
            <td className='numeric'>Steals</td>
            <td className='numeric'>Rebounds</td>
          </tr>
        </thead>

        { generatedRows && (
          <tbody dangerouslySetInnerHTML={ { __html: generatedRows } }></tbody>
        ) }
      </table>
    </div>
  )
}

export default Defense;
