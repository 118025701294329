import React, { useEffect } from 'react';

import Footer from '../components/Footer';

function Directions(props) {
  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
  }, []);
  
  return (
    <div className='directions'>
      <iframe
        title='map'
        src="https://embed.waze.com/iframe?zoom=13&lat=32.741191&lon=-97.368684&pin=1"
        width="100%" 
        height="520"></iframe>

      <Footer />
    </div>
  )
}

export default Directions;