import React from 'react';

function Points(props) {
  const { showHome, stats } = props;
  console.log('showHome ', showHome);

  // let generatedRows = '';

  let generatedRows = `
    <tr>
      <td>Mr. Sports</td>
      <td class='numeric'>2</td>
      <td class='numeric'>6</td>
      <td class='numeric'>1</td>
      <td class='numeric'>9</td>
    </tr>
    <tr>
      <td>Mr. Sports</td>
      <td class='numeric'>2</td>
      <td class='numeric'>6</td>
      <td class='numeric'>1</td>
      <td class='numeric'>9</td>
    </tr>
  `;

  if (stats) {
    let points = [];
    stats.forEach(summary => {
      if (summary.stats && summary.stats[ 0 ].$.hasOwnProperty('fgm')) {

        let pointsObj = {
          name: summary.$.name,
          fg: +summary.stats[ 0 ].$.fgm,
          threePt: +summary.stats[ 0 ].$.fgm3,
          freeThrows: +summary.stats[ 0 ].$.ftm,
          pts: +summary.stats[ 0 ].$.tp
        }

        if (pointsObj.fg || pointsObj.threePt || pointsObj.freeThrows) {
          points.push(pointsObj);
        }
      }
    });

    points.forEach(point => {
      generatedRows = generatedRows + `
          <tr>
            <td>${ point.name || ' ' }</td>
            <td class='numeric'>${ point.fg || 0 }</td>
            <td class='numeric'>${ point.threePt || 0 }</td>
            <td class='numeric'>${ point.freeThrows || 0 }</td>
            <td class='numeric'>${ point.pts || 0 }</td>
          </tr>
        `;
    })

    let totals = points.reduce((total, current) => {
      total = {
        name: 'Team Totals',
        fg: (total.fg || 0) + current.fg,
        threePt: (total.threePt || 0) + current.threePt,
        freeThrows: (total.freeThrows || 0) + current.freeThrows,
        pts: (total.pts || 0) + current.pts
      };

      return total;
    }, {});

    generatedRows = generatedRows + `
        <tr>
          <td>${ totals.name || ' ' }</td>
          <td class='numeric'>${ totals.fg || 0 }</td>
          <td class='numeric'>${ totals.threePt || 0 }</td>
          <td class='numeric'>${ totals.freeThrows || 0 }</td>
          <td class='numeric'>${ totals.pts || 0 }</td>
        </tr>
      `;
  }

  return (
    <div className='sport__stats__table'>
      <table className='sport__stats__table__body'>
        <thead>
          <tr>
            <td>Name</td>
            <td className='numeric'>FG</td>
            <td className='numeric'>3PT</td>
            <td className='numeric'>FT</td>
            <td className='numeric'>Pts</td>
          </tr>
        </thead>

        { generatedRows && (
          <tbody dangerouslySetInnerHTML={ { __html: generatedRows } }></tbody>
        ) }
      </table>
    </div>
  )
}

export default Points;
