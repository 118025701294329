import React, { useEffect } from 'react';
import $ from 'jquery';
import ReactFullpage from '@fullpage/react-fullpage';

function onLeave(origin, destination, direction) {
  if (origin.index === 0 && direction === "down") {
    $(".aboutPagination").addClass("visible")
    $(".aboutPagination a").fadeIn(200);

    $(".welcome .textBox").removeClass("fadeInUp")
    $(".swipeBox").removeClass("fadeInUp")
  }

  if (origin.index === 1 && direction === "up") {
    $(".aboutPagination").removeClass("visible")
    $(".aboutPagination a").fadeOut(200);
  }

  if (origin.index === 1 && direction === "down") {
    $(".about_bottom img").addClass("animateIt")

    $(".about_top .animated").removeClass("fadeInUp", function (e) {
      setTimeout(function () {
        $(".about_bottom .animated").removeClass("fadeIn")
      }, 800);
    })
  }

  if (origin.index === 2 && direction === "up") {
    $(".about_bottom img").removeClass("animateIt")
  }

  if (origin.index === 3) {
    $(".concertBg .textBox").removeClass("fadeInUp")
  }

  if (origin.index === 4) {
    $(".displayBg .textBox").removeClass("fadeInUp")
  }

  if (origin.index === 5) {
    $(".artdecoBg .textBox").removeClass("fadeInUp")
  }
}

function afterLoad(origin, destination, direction) {
  if (destination.index === 0) {
    $(".welcome .textBox").addClass("fadeInUp")    
    $(".swipeBox").addClass("fadeInUp")
    $("#slideNumber").html("1")
    $(".aboutProgressBar .progress").css("width", "10%")
  }

  if (destination.index === 1) {
    $(".about_top .animated").addClass("fadeInUp", function (e) {
      setTimeout(function () {
        $(".about_bottom .animated").addClass("fadeIn")
      }, 800);
    })
    $("#slideNumber").html("2")
    $(".aboutProgressBar .progress").css("width", "25%")
  }

  if (destination.index === 2) {
    $(".about_bottom .animated").addClass("fadeIn")
    $(".multi.animated").addClass("fadeIn")
    $(".about_bottom img").addClass("animateIt")
  }

  if (destination.index === 3) {
    $(".concertBg .textBox").addClass("fadeInUp")
    $("#slideNumber").html("3")
    $(".aboutProgressBar .progress").css("width", "40%")
  }

  if (destination.index === 4) {
    $(".displayBg .textBox").addClass("fadeInUp")
    $("#slideNumber").html("4")
    $(".aboutProgressBar .progress").css("width", "55%")
  }

  if (destination.index === 5) {
    $(".artdecoBg .textBox").addClass("fadeInUp")
    $("#slideNumber").html("5")
    $(".aboutProgressBar .progress").css("width", "70%")
  }

  if (destination.index === 6) {
    $("#slideNumber").html("6")
    $(".aboutProgressBar .progress").css("width", "85%")
  }

  if (destination.index === 8) {
    $("#slideNumber").html("7")
    $(".aboutProgressBar .progress").css("width", "100%")
  }
}

function changeSlider(slide){
  $(".star").removeClass("active")
  $(".star[data-nav="+ slide +"]").addClass("active")
  $(".slideContent").fadeOut(250).promise().done(
    function() {
      $(".slideContent[data-slide="+ slide +"]").fadeIn(250)
    });

  if(slide === 2){
    $(".slide_2_image").fadeIn(250)
  }
  else{
    $(".slide_2_image").fadeOut(250)
  }
}

function AboutFullPage(props) {
  const { fields } = props;

  useEffect(() => {
    $('.app').addClass('fixedHeader');
    // console.log('componennt did mount');
    return () => {
      // console.log('component did unmount')
      $('.app').removeClass('fixedHeader');
    }
  })

  return (
    
    <ReactFullpage
      //fullpage options
      licenseKey={ '98728404-946D40D9-ABC82A5F-1E3AD60A' }
      scrollingSpeed={ 1000 } /* Options here */
      onLeave={ onLeave.bind(this) }
      afterLoad={ afterLoad.bind(this) }

      render={ ({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section welcome active" style={ { backgroundImage: `url(${ fields.section_1.background.url })` } } >
              <div className="textBox animated">
                <div dangerouslySetInnerHTML={ { __html: fields.section_1.copy } }></div>
              </div>
              
              <div className="swipeBox animated">
                <a href="#slide3" data-anchor="slide1" className="arrowBox"><span></span></a>
                <p>Swipe up for your Dickies Arena guide</p>
              </div>
            </div>

            <div className="section about">
              <div className="about_top">
                <div className="animated">
                  <div dangerouslySetInnerHTML={ { __html: fields.section_2.copy } }></div>
                </div>
              </div>

              <div className="about_bottom">
                <div>
                  <img src={ fields.section_2.image.url } alt="" className="" />
                </div>
              </div>
            </div>

            <div className="section multiPurpose">
              <div className="slideContainer">                
                <img src={ fields.section_2.slide_2_image.url } alt="" className="placeholder" />
                <div className="imageContainer">
                  <img src={ fields.section_2.slide_2_image.url } alt="" className="slide_2_image" />
                </div>
                <div className="contentSlider">                  
                  <div className="sliderNav">
                    <span onClick={() => changeSlider(1)} className="star active" data-nav="1"></span>
                    <span onClick={() => changeSlider(2)} className="star" data-nav="2"></span>
                    <span onClick={() => changeSlider(3)} className="star" data-nav="3"></span>
                  </div>
                  <div className="slides">
                    <div data-slide="1" className="slideContent active" style={{ display: 'block'}} dangerouslySetInnerHTML={ { __html: fields.section_2.slide_1_copy } }></div>
                    <div data-slide="2" className="slideContent" dangerouslySetInnerHTML={ { __html: fields.section_2.slide_2_copy } }></div>  
                    <div data-slide="3" className="slideContent" dangerouslySetInnerHTML={ { __html: fields.section_2.slide_3_copy } }></div>  
                  </div>
                </div>
              </div>
            </div>

            <div className="section innerBg concertBg" style={ { backgroundImage: `url(${ fields.section_3.background_image.url })` } }>
              <div className="textBox fullHeight animated">
                <div dangerouslySetInnerHTML={ { __html: fields.section_3.copy } }></div>
              </div>
            </div>

            <div className="section innerBg displayBg" style={ { backgroundImage: `url(${ fields.section_4.background_image.url })` } }>
              <div className="textBox fullHeight animated">
                <div dangerouslySetInnerHTML={ { __html: fields.section_4.copy } }></div>
              </div>
            </div>

            <div className="section innerBg artdecoBg" style={ { backgroundImage: `url('/img/artdeco-bg.jpg')` } }>
              <div className="textBox fullHeight animated">
                <div dangerouslySetInnerHTML={ { __html: fields.section_5.copy } }></div>
              </div>
            </div>

            <div className="section innerBg houseBg" style={ { backgroundImage: `url(${ fields.section_6.overlay_image.url })` } }>
            </div>

            <div className="section innerBg houseBg" style={ { backgroundImage: `url(${ fields.section_6.background_image.url })` } }>
            </div>

            <div className="section innerBg conclusionBg" style={ { backgroundImage: `url(${ fields.section_7.background_image.url })` } }>
              <div className="textBox">
                <div dangerouslySetInnerHTML={ { __html: fields.section_7.copy } }></div>
                <a href='https://www.facebook.com/dickiesarena/' target='_blank' rel='noopener noreferrer'>
                  <img src='/img/icon-facebook.png' alt='Facebook' />
                </a>

                <a href='https://twitter.com/DickiesArena' target='_blank' rel='noopener noreferrer'>
                  <img src='/img/icon-twitter.png' alt='Twitter' />
                </a>

                <a href='https://www.instagram.com/dickiesarena/?hl=en' target='_blank' rel='noopener noreferrer'>
                  <img src='/img/icon-insta.png' alt='Instagram' />
                </a>
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      } }
    />
  )
}

export default AboutFullPage;