import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Landing from './Landing';
import About from './About';
import GuestServies from './GuestServices';
import ParkingMap from './guest-services/ParkingMap';
import ADAServices from './guest-services/ADAServices';
import Upcoming from './Upcoming';
import Directions from './Directions';
import SeatingChart from './SeatingChart';
import ConcourseMap from './ConcourseMap';
import EventDetail from './EventDetail';

function Routes(props) {
  const { pages } = props;
  // console.log('pages ' , pages);

  const fieldsOnlyRoutes = [
    // generic paths
    { path: '/', comp: Landing, fields: pages.landing },
    { path: '/about', comp: About, fields: pages.about },
    { path: '/guest-services', comp: GuestServies, fields: pages.guest },
    { path: '/guest-services/parking-map', comp: ParkingMap, fields: pages.guest },
    { path: '/guest-services/ada-services', comp: ADAServices, fields: pages.guest },
    { path: '/upcoming-events', comp: Upcoming, fields: pages.upcoming },
    { path: '/directions', comp: Directions, fields: pages.directions },
    { path: '/seating-chart', comp: SeatingChart, fields: pages.seating },
    { path: '/concourse-map', comp: ConcourseMap, fields: pages.concourse },
    { path: '/event/:id', comp: EventDetail, fields: {} }
  ];

  return (
    <Switch>
      { fieldsOnlyRoutes.map(option => {
        const Comp = option.comp;
        return (
          <Route
            exact
            key={ option.path }
            path={ `${ process.env.PUBLIC_URL }${ option.path }` }
            render={ (props) => <Comp { ...props } fields={ option.fields } /> } />
        )
      }) }
    </Switch>
  )
}

export default Routes;
