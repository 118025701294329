import React, { useEffect } from 'react';
import $ from 'jquery';

function MainLevel(props) {
  const { focusedSection } = props;

  useEffect(() => {
    if (focusedSection) {
      let elementToFocus = document.getElementById(`section_${ focusedSection }`);
      $('.focused').removeClass('focused');

      const multiPoints = [ 'elevators', 'mens-restrooms', 'family-restrooms', 'womens-restrooms', 'first-aid', 'guest-services' ];

      if (multiPoints.includes(focusedSection)) {
        $(`.${ focusedSection }`).addClass('focused');
      }

      elementToFocus && elementToFocus.classList.add('focused');
    }
  }, [ focusedSection ]);

  return (
    <div className='main-level'>
      <svg
        easypz='{ "applyTransformTo":"svg > *", "modes": ["SIMPLE_PAN", "HOLD_ZOOM_IN", "CLICK_HOLD_ZOOM_OUT", "PINCH_ZOOM", "DBLCLICK_ZOOM_IN", "DBLRIGHTCLICK_ZOOM_OUT"], "options": { "minScale": 1, "maxScale": 2, "bounds": { "top": -50, "right": 50, "bottom": 50, "left": -50 }} }' 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        x="0px" 
        y="0px"
        viewBox="150 150 2750 1950">

        <path id="bottom_fill" className="st0" d="M2775.6,925.4c-5.1-34.8-12-71.5-21.2-110l-24.1-40.3l-31.1-4.1V656.7h-118.9V373.8H2352
          c0,0-5.9-108.5-92.7-111.6h-175l-57.1-27.3h-254.3v-15.2c0,0,0,0-0.1,0v-10.8c0,0-95.6-58.4-239.1-54.2
          c-143.5-4.2-239.1,54.2-239.1,54.2v10.8c0,0,0,0-0.1,0v15.2h-254.3l-57.1,27.3h-175c-86.8,3.2-92.7,111.6-92.7,111.6H487v282.9
          H317.6l-30.4,45.9v105.7l13.1,7c-24.8,93.4-32.2,182.1-31,258.7c-1.1,76.6,6.2,165.3,31,258.7l-13.1,7v105.7l30.4,45.9H487v282.9
          h228.4c0,0,5.9,108.5,92.7,111.6h175l57.1,27.3h254.3v15.2c0,0,0,0,0.1,0v10.8c0,0,95.6,58.4,239.1,54.2
          c143.5,4.2,239.1-54.2,239.1-54.2v-10.8c0,0,0,0,0.1,0v-15.2h254.3l57.1-27.3h175c86.8-3.2,92.7-111.6,92.7-111.6h228.4v-282.9
          h118.9V1377l31.1-4.1l24.1-40.3c9.2-38.5,16.1-75.2,21.2-110c7-41.4,11.8-91.1,11.5-148.7C2787.4,1016.5,2782.5,966.8,2775.6,925.4z
          "/>
        <g>
          <rect x="868.9" y="1805.9" className="st1" width="108.2" height="79.9"/>
          <polygon className="st1" points="542.8,1485.6 542.4,1433.9 492.1,1433.9 453.9,1433.9 453.9,1468.7 483,1468.7 487,1468.7 487,1472.7 
            487,1578.3 562.8,1578.3 562.8,1485.6 	"/>
          <path className="st1" d="M381.4,1346.2l47.8-51l-67.7,19.8l-18.4,18l3.8,17.3c8.8-2.7,18.4-4.2,28.5-4.2
            C377.4,1346,379.4,1346.1,381.4,1346.2z"/>
          <path className="st1" d="M790.9,1609.2l-20.8,20.6h-42.3v195.8c11.9,28.5,35.2,58.6,80.3,60.2h56.8v-203l-17.2-14L790.9,1609.2z"/>
          <path className="st1" d="M487,1583v191.2h228.4c0,0,1,18.7,8.4,40.7v-185.5h-18.1V1583H487z"/>
          <path className="st1" d="M483,1472.7h-31.1h-2h-35.5v-29.8c-11.3,5.2-24.7,8.2-39,8.2c-40.2,0-72.8-23.6-72.8-52.5
            c0-20.6,16.5-38.5,40.5-47.1l-4.4-19.9l14.6-14.3l-53,15.5v-0.1l-13.1,7v105.7l30.4,45.9H483V1472.7z"/>
          <path className="st1" d="M547,1416.1l-66.2-136l-44,12.9l-50.3,53.6c34.9,3.9,61.7,25.7,61.7,51.9c0,17.4-11.8,32.8-29.8,42.4v27.8
            h31.5v-38.9h42.2h54.3l0.4,51.7h20v49.5h52.4l52.5-47l-47.6-50c-13-14.3-24.2-27.9-33.8-40.8L547,1416.1z"/>
          <path className="st1" d="M306.6,1398.5c0,26.8,30.9,48.5,68.8,48.5s68.8-21.8,68.8-48.5s-30.9-48.5-68.8-48.5
            S306.6,1371.8,306.6,1398.5z"/>
          <polygon className="st1" points="768.5,1625.8 788.2,1606.3 698.5,1512.2 631.6,1579 709.8,1579 709.8,1625.4 728.7,1625.4 
            728.7,1625.8 	"/>
          <polygon className="st1" points="695.7,1509.3 674.4,1487 620.7,1535 566.8,1535 566.8,1578.3 626.7,1578.3 	"/>
          <polygon className="st1" points="868.9,1801.9 977,1801.9 977,1774.4 868.9,1686.1 	"/>
          <path id='section_trinity-treats' className="st1" d="M548.8,1410.6l39-20.6c-55.5-75.7-56.4-124.7-56.4-124.7l-46.7,13.6L548.8,1410.6z"/>
        </g>
        <g>
          <path className="st1" d="M2569.1,1417.1v-60.7c-21.1-13.1-32.5-26.4-32.5-26.4l-88.5,87L2569.1,1417.1L2569.1,1417.1z"/>
          <path className="st1" d="M2197,1664v221.8h62.3c36.9-1.4,59.2-21.7,72.6-44.7v-217.9h-93.5L2197,1664z"/>
          <path className="st1" d="M2589.3,1443.2l26,26h36.8l26-26v-36.8l-24.8-24.8c-6-0.1-11.8-0.5-17.4-1.2h-20.6l-26,26V1443.2z"/>
          <polygon className="st1" points="2193,1804.5 2193,1667.9 2083.7,1775.4 2083.7,1804.5 	"/>
          <path className="st1" d="M2335.9,1583v36.2v2v212.5c14.4-29.1,16.1-59.5,16.1-59.5h228.4V1583H2335.9z"/>
          <path className="st1" d="M2703.1,1374.8c-15.6,4.4-30.4,6.5-44.1,6.8l23.2,23.2v40.1l-28.4,28.4h-40.1l-28.4-28.4v-40.1l28.4-28.4h1.5
            c-16.4-4.3-30.5-10.8-42-17.5v61.9h-1v0.3H2444l-201.5,198.1h89.4V1579h248.5v-87.7h118.9V1377l4.8-0.6L2703.1,1374.8z"/>
          <polygon className="st1" points="2083.7,1886.1 2084.3,1885.8 2193,1885.8 2193,1808.5 2083.7,1808.5 	"/>
        </g>
        <g>
          <rect x="462.4" y="679.1" className="st1" width="36.2" height="34.9"/>
          <path className="st1" d="M383.9,801.9c-10.1,0-19.7-1.5-28.5-4.2l-3.8,17.3l21.4,20.9l9.6,2.7l6-36.8
            C387.1,801.8,385.5,801.9,383.9,801.9z"/>
          <ellipse className="st1" cx="383.9" cy="749.3" rx="68.8" ry="48.5"/>
          <path className="st1" d="M347.3,816.2l4.4-19.9c-24-8.6-40.5-26.5-40.5-47.1c0-29,32.7-52.5,72.8-52.5c14.3,0,27.7,3,39,8.2v-29.8h35.5
            h2h38.2V675h19.9l8.6-14l-40.2-5.3v1H317.6l-30.4,45.9v103.3l13.2,9.4l64.7,18.2L347.3,816.2z"/>
          <polygon className="st1" points="487,373.8 487,651.6 651.9,673.6 671.6,655.3 671.6,373.8 	"/>
          <path className="st1" d="M726.1,326.7c-9.5,24.7-10.7,47.1-10.7,47.1h-39.9v277.9l50.6-47L726.1,326.7L726.1,326.7z"/>
          <path className="st1" d="M531.4,661.7l-10.7,17.4h-18.1V714h41.6l6.7,19.5l46.1,26.8c7.9-8.8,16.2-17.3,24.3-25.9
            c9.8-10.3,19.6-20.5,29.5-30.8c3.8-3.9,10.6-8.8,13.2-13.7c0,0-6.7-7.6-10.6-12.1L531.4,661.7z"/>
          <path className="st1" d="M500,831.3l47.1-96.6l-5.7-16.7h-40.8h-2h-40.2v-38.9h-31.5V707c18.1,9.6,29.8,25,29.8,42.4
            c0,26.8-28,49-64,52.1l-6.2,38.2l144.2,40.6c4.8-9.5,9.1-19.2,13.3-28.9L500,831.3z"/>
          <path id='section_seventh-street-staples-132' className="st1" d="M550.2,737.6l-44.7,91.7l40.2,18.3c7.1-16.6,14.2-33.2,23-49c7.2-12.8,16.1-24.4,25.7-35.4L550.2,737.6z"/>
          <path className="st1" d="M860.5,262.2h-52.4c-42.4,1.6-65.5,28.2-78,55v283.7l130.4-121.3V262.2z"/>
          <polygon className="st1" points="864.5,345.6 864.5,475.9 977.6,370.8 977.6,345.6 	"/>
          <rect x="864.5" y="262.2" className="st1" width="113.1" height="79.4"/>
        </g>
        <g>
          <polygon className="st1" points="2184.7,345.5 2184.4,345.5 2184.4,341.2 2083.7,341.2 2083.7,380.4 2184.7,478.6 	"/>
          <polygon className="st1" points="2431,575.1 2431,667.3 2405.5,693.3 2433.1,720.2 2496.2,660.3 2530.6,660.3 2530.7,609.8 
            2482.8,609.8 2482.8,575.1 	"/>
          <polygon className="st1" points="2332.7,311.2 2188.7,311.2 2188.7,482.5 2238.4,530.9 2332.7,530.9 	"/>
          <polygon className="st1" points="2184.4,262.2 2084.3,262.2 2083.6,261.9 2083.6,337.2 2184.4,337.2 	"/>
          <path className="st1" d="M2259.3,262.2h-70.9v44.9H2332C2318.6,284,2296.3,263.5,2259.3,262.2z"/>
          <path className="st1" d="M2652.1,767.7l27.6-30.5V698l-27.7-27.7h-41l-3.9-6h-27.9v-26.1h1.1v-28.5h-45.6l-0.1,51.9l84.4,108.6
            C2629.1,768.6,2640.1,767.7,2652.1,767.7z"/>
          <path className="st1" d="M2657.5,767.7c21.8,0.5,46.8,3.9,75.3,11.6l-2.5-4.2l-31.1-4.1V656.7h-116v3.6h26.1l3.9,6h40.5l30,30v42.4
            L2657.5,767.7z"/>
          <path className="st1" d="M2402.6,690.4l24.4-24.8V571h59.8v34.7h93.5V373.8H2352c0,0-1.6-29.4-15.4-58.1v219.1h-94.1L2402.6,690.4z"/>
          <path className="st1" d="M2554.5,693.8l-74.9,71.6l51.2,49.8c0,0,24.2-33.5,83.6-44.3L2554.5,693.8z"/>
          <polygon className="st1" points="2436,723 2476.8,762.7 2552.1,690.6 2531.6,664.3 2497.8,664.3 	"/>
        </g>
        <path id='section_seventh-street-staples-114' className="st1" d="M2323.5,1123.8h86.3c-0.4-22.3-0.1-48.8,1.2-80.4c0-0.8,0.1-1.5,0.1-2.3h-87.7L2323.5,1123.8L2323.5,1123.8z"/>
        <path className="st1" d="M2422.4,938.2c-1.1,10.7-5.1,54.4-7.3,105.4c-3.2,75.9-0.8,134.1,7.1,168.6l38.7-10
          c-7.4-36.6-11.7-79.3-11.7-124.8c0-46.4,4.4-89.8,12.1-126.9C2448.6,947.2,2435.5,943.2,2422.4,938.2z"/>
        <path id='section_tx-tavern' className="st1" d="M2349.5,891.9c20,17.4,54.9,39.7,112.5,54.7c4-18.3,8.7-34.9,14.1-49.6l-152.7-139.6v105.3
          C2328.5,870.4,2336.7,880.8,2349.5,891.9z"/>
        <path className="st1" d="M2346.7,894.7c-10.5-9.2-18-17.8-23.2-24.9v167.3h87.8c2.3-50.3,6.4-92.2,7.2-100.4
          C2393,926.7,2367.7,913.1,2346.7,894.7z"/>
        <path className="st1" d="M2419.1,1217.1l-0.5-2c-3.8-15.9-7.7-42.7-8.8-87.3h-86.4v100.5h114.9v43.9l31.9-31.9
          c-3.2-10.6-6.1-22.1-8.7-34.2L2419.1,1217.1z"/>
        <polygon className="st1" points="2323.5,1269.1 2399.7,1269.1 2399.7,1310.9 2434.4,1276.2 2434.4,1232.3 2323.5,1232.3 "/>
        <polygon className="st1" points="2395.7,1273.1 2323.5,1273.1 2323.5,1387.2 2395.7,1314.9 "/>
        <rect x="1232.5" y="1591.2" className="st1" width="243.5" height="75.8"/>
        <rect id='section_cultural-district-creations' x="1232.5" y="1671" className="st1" width="243.5" height="43.9"/>
        <polygon className="st1" points="2030.5,1591.2 2030.5,1624.9 2118,1624.9 2151.7,1591.2 "/>
        <rect x="1582.1" y="1591.2" className="st1" width="243.4" height="75.8"/>
        <polygon className="st1" points="2030.5,1628.9 2030.5,1712.4 2114,1628.9 "/>
        <path className="st1" d="M1186.6,1591.2h-147v89.4l42.7,41.6c12.1-4.8,25.3-7.4,39.2-7.4h65.1L1186.6,1591.2L1186.6,1591.2z"/>
        <rect id='section_tarrant-tex-mex' x="1582.1" y="1671" className="st1" width="243.4" height="43.9"/>
        <polygon className="st1" points="1035.6,1624.9 1035.6,1591.2 947.9,1591.2 982.5,1624.9 "/>
        <path className="st1" d="M2026.5,1591.2h-154.1v123.7h104.2c15.1,0,29.5,3.1,42.6,8.8l7.3-7.3V1591.2z"/>
        <polygon className="st1" points="1035.6,1676.7 1035.6,1628.9 986.6,1628.9 "/>
        <path className="st1" d="M710.9,1023.6v-50.2H596.8c-2.9,16.2-5.1,33-6.6,50.2H710.9z"/>
        <path className="st1" d="M710.9,1153v-125.4h-83v85.3v2v2h-38.3c0.9,12.3,2.1,24.3,3.7,36.1H710.9z"/>
        <path className="st1" d="M623.9,1027.6h-34c-1.2,15.2-1.8,30.7-1.8,46.5c0,13.1,0.4,26.1,1.3,38.8H624L623.9,1027.6L623.9,1027.6z"/>
        <path className="st1" d="M597.5,969.4h113.4v-69.3h-95.1C608.3,921.4,602.1,944.7,597.5,969.4z"/>
        <path className="st1" d="M710.9,896.1V773c-38.5,18-71.6,62.6-93.7,123.1H710.9z"/>
        <path className="st1" d="M710.9,1226.5V1157h-117c3.5,24.5,8.6,47.8,15,69.5H710.9z"/>
        <path className="st1" d="M643.4,1307.9c19.1,32,42.1,55.4,67.5,67.3v-67.3H643.4z"/>
        <path className="st1" d="M710.9,1230.5H610.1c8.4,27.4,18.8,52.2,31,73.4H711L710.9,1230.5L710.9,1230.5z"/>
        <path className="st1" d="M1868.7,558h149.6V449l-23.4-23.4c-13.1,5.7-27.5,8.8-42.6,8.8h-83.6V558z"/>
        <rect x="1228.7" y="478.7" className="st1" width="249.7" height="79.3"/>
        <rect id='section_camp-bowie-q' x="1228.7" y="434.3" className="st1" width="249.7" height="40.4"/>
        <polygon className="st1" points="2081.1,511.7 2022.4,511.7 2022.4,558 2127.5,558 "/>
        <polygon className="st1" points="2022.4,453 2022.4,507.7 2077.1,507.7 "/>
        <rect id='section_gendy-gourmet-sausages' x="1578.2" y="434.3" className="st1" width="247.8" height="40.4"/>
        <polygon className="st1" points="1035.6,507.7 1035.6,448.9 975.3,507.7 "/>
        <path className="st1" d="M1039.6,558h143.8V434.3h-86.2c-13.8,0-27-2.6-39.2-7.4l-18.5,18L1039.6,558L1039.6,558z"/>
        <rect x="1578.2" y="478.7" className="st1" width="247.8" height="79.3"/>
        <polygon className="st1" points="1035.6,511.7 971.2,511.7 923.7,558 1035.6,558 "/>
        <path id="top_border" className="st2" d="M2775.6,925.4c-5.1-34.8-12-71.5-21.2-110l-24.1-40.3l-31.1-4.1V656.7h-118.9V373.8H2352
          c0,0-5.9-108.5-92.7-111.6h-175l-57.1-27.3h-254.3v-15.2c0,0,0,0-0.1,0v-10.8c0,0-95.6-58.4-239.1-54.2
          c-143.5-4.2-239.1,54.2-239.1,54.2v10.8c0,0,0,0-0.1,0v15.2h-254.3l-57.1,27.3h-175c-86.8,3.2-92.7,111.6-92.7,111.6H487v282.9
          H317.6l-30.4,45.9v105.7l13.1,7c-24.8,93.4-32.2,182.1-31,258.7c-1.1,76.6,6.2,165.3,31,258.7l-13.1,7v105.7l30.4,45.9H487v282.9
          h228.4c0,0,5.9,108.5,92.7,111.6h175l57.1,27.3h254.3v15.2c0,0,0,0,0.1,0v10.8c0,0,95.6,58.4,239.1,54.2
          c143.5,4.2,239.1-54.2,239.1-54.2v-10.8c0,0,0,0,0.1,0v-15.2h254.3l57.1-27.3h175c86.8-3.2,92.7-111.6,92.7-111.6h228.4v-282.9
          h118.9V1377l31.1-4.1l24.1-40.3c9.2-38.5,16.1-75.2,21.2-110c7-41.4,11.8-91.1,11.5-148.7C2787.4,1016.5,2782.5,966.8,2775.6,925.4z
          "/>
        <text transform="matrix(1 0 0 1 1510.74 1898.9075)" className="st3 st4 st5">East</text>
        <text transform="matrix(1 0 0 1 1497.9399 1934.9075)" className="st3 st4 st5">Lobby</text>
        <text transform="matrix(1 0 0 1 554.3037 509.687)" className="st3 st4 st5">Box</text>
        <text transform="matrix(1 0 0 1 539.1037 545.687)" className="st3 st4 st5">Office</text>
        <g id="tcu_lobby">
          <text transform="matrix(1 0 0 1 1477.3063 320.2933)" className="st3 st4 st5">TCU Lobby</text>
          <g id="tcu_logo">
          </g>
        </g>
        <g id="dickies_lobby">
          <text transform="matrix(1 0 0 1 309.5602 1120.4357)" className="st3 st4 st5">Dickies Lobby</text>
          <g>
            <path className="st6" d="M469.7,1031.4c-0.9-1.6-2.6-2.5-4.5-2.5h-14.8c-7.9-14.9-27.7-25.6-50.9-25.6c-23.2,0-43,10.6-50.9,25.6h-3.1
              c-2.6,0-5.6,1.6-7,3.8l-10.1,16.1c-1.1,1.7-1.2,3.6-0.3,5.2c0.9,1.6,2.6,2.5,4.5,2.5H348c7.5,15.5,27.7,26.6,51.4,26.6
              c23.8,0,44-11.1,51.4-26.6h1.4c2.6,0,5.6-1.6,7-3.8l10.1-16.1C470.5,1034.9,470.6,1032.9,469.7,1031.4L469.7,1031.4z"/>
            <path className="st7" d="M467.9,1035.6l-10.1,16.1c-1.1,1.7-3.4,3-5.5,3h-2.5c-6.8,15.4-26.8,26.6-50.3,26.6
              c-16.9,0-32.9-5.9-42.8-15.8c-3.3-3.3-5.8-7-7.5-10.8h-16.5c-1.3,0-2.4-0.6-3-1.6c-0.5-1-0.5-2.2,0.3-3.4l10.1-16.1
              c1.1-1.7,3.4-3,5.5-3h4.1c1.7-3.4,4-6.7,7-9.7c9.9-9.9,25.9-15.8,42.8-15.8s32.9,5.9,42.8,15.8c3,3,5.4,6.3,7,9.7h15.8
              c1.3,0,2.4,0.6,3,1.6C468.7,1033.2,468.6,1034.5,467.9,1035.6L467.9,1035.6z M469.7,1031.4c-0.9-1.6-2.6-2.5-4.5-2.5h-14.8
              c-7.9-14.9-27.7-25.6-50.9-25.6c-23.2,0-43,10.6-50.9,25.6h-3.1c-2.6,0-5.6,1.6-7,3.8l-10.1,16.1c-1.1,1.7-1.2,3.6-0.3,5.2
              c0.9,1.6,2.6,2.5,4.5,2.5H348c7.5,15.5,27.7,26.6,51.4,26.6c23.8,0,44-11.1,51.4-26.6h1.4c2.6,0,5.6-1.6,7-3.8l10.1-16.1
              C470.5,1034.9,470.6,1032.9,469.7,1031.4L469.7,1031.4z"/>
            <path className="st8" d="M417.6,1067.4l3.8-1.4c0.8-0.3,0.8-0.9,0.1-1.3c0,0-1.1-0.6-2.8,0c-1.6,0.6-2,1.7-2,1.7
              C416.4,1067.2,416.8,1067.6,417.6,1067.4L417.6,1067.4z"/>
            <path className="st8" d="M402.9,1069.2l3.4-0.2c0.7-0.1,0.9-0.5,0.4-1c0,0-0.7-0.7-2.2-0.6c-1.4,0.1-2,0.9-2,0.9
              C402,1068.8,402.2,1069.3,402.9,1069.2L402.9,1069.2z"/>
            <path className="st8" d="M410.3,1068.8l3.7-0.5c0.8-0.1,0.9-0.7,0.3-1.2c0,0-0.9-0.7-2.5-0.5c-1.6,0.3-2.1,1.2-2.1,1.2
              C409.2,1068.5,409.5,1068.9,410.3,1068.8L410.3,1068.8z"/>
            <path className="st8" d="M425.3,1064.1l3.8-2.4c0.8-0.5,0.7-1.2-0.2-1.5c0,0-1.3-0.4-3,0.7c-1.6,1.1-1.8,2.3-1.8,2.3
              C424,1064.2,424.5,1064.5,425.3,1064.1L425.3,1064.1z"/>
            <path className="st9" d="M379.4,1054.8h5.8 M351.2,1054.7c1.6,3.4,3.9,6.6,6.8,9.5c9.6,9.6,25.1,15.3,41.5,15.3
              c22.5,0,41.6-10.4,48.3-24.8"/>
            <path className="st9" d="M447.4,1030.7c-1.6-3-3.7-5.8-6.3-8.4c-9.6-9.6-25.1-15.3-41.5-15.3s-31.9,5.7-41.5,15.3
              c-2.6,2.6-4.7,5.5-6.3,8.4"/>
            <path className="st7" d="M465.2,1032.5c1.4,0,2,1,1.2,2.2l-10.1,16.1c-0.8,1.2-2.5,2.2-3.9,2.2H332.7c-1.4,0-2-1-1.2-2.2l10.1-16.1
              c0.8-1.2,2.5-2.2,3.9-2.2H465.2L465.2,1032.5z"/>
            <path className="st6" d="M354.1,1046.1h2.1c1.7,0,2.6-0.1,4.1-0.9c1.3-0.7,2-1.8,2.6-2.9c0.7-1.2,0.4-2.1,0-2.7c-0.3-0.5-0.9-0.8-2-1
              h-2.3L354.1,1046.1L354.1,1046.1z M348.4,1046.3l4.7-7.8h-2.9l1.7-2.9H362c3,0,4.8,0.5,5.8,1.8c1.1,1.3,1.7,3.9,0.5,5.8
              c-1.1,1.8-2.1,3.6-4.5,4.8c-2.6,1.4-6.7,1-9.8,1h-10.3l1.7-2.9L348.4,1046.3L348.4,1046.3z"/>
            <polygon className="st6" points="375.9,1046.3 377.8,1046.3 376,1049.2 368.1,1049.2 369.8,1046.3 371.6,1046.3 374.4,1041.7 
              372.6,1041.7 374.3,1038.8 380.5,1038.8 		"/>
            <polygon className="st6" points="418.8,1046.3 420.6,1046.3 418.9,1049.2 410.9,1049.2 412.7,1046.3 414.5,1046.3 417.3,1041.7 
              415.5,1041.7 417.2,1038.8 423.3,1038.8 		"/>
            <polygon className="st6" points="405,1041.2 410.6,1038.5 414.1,1038.5 412.4,1041.3 410.6,1041.3 405.9,1043.5 407.7,1046 
              410.3,1046 408.6,1048.8 403.9,1048.8 401.3,1044.9 399.3,1048.8 392.8,1048.8 394.5,1046 396.3,1046 401.1,1038.1 399.2,1038.2 
              401,1035.3 407.1,1035.3 403.1,1042.1 		"/>
            <path className="st6" d="M442.1,1046c-0.1,0.2-0.1,0.6,0.4,0.8c0.6,0.3,0.9,0.1,1.3,0.1c0.7,0,1.6-0.3,1.9-0.8
              c0.3-0.4,0.1-0.5-1.1-0.8h-0.2c-2-0.4-5-1-3.5-3.5c1.3-2.1,4.5-3.2,7.1-3.2c1.1,0,2.4,0.4,2.4,0.4l0.1-0.2h3.5l-2.1,3.4h-3.2
              c0,0,0.4-0.6-0.3-0.9c-0.4-0.2-0.9-0.2-1.2-0.2c-0.6,0-1.4,0.2-1.6,0.7c-0.2,0.4,0,0.5,1.2,0.8c1.9,0.3,5.2,0.9,3.6,3.5
              c-1.4,2.3-4.8,3.3-7.4,3.3c-1.7,0-2.7-0.6-2.7-0.6l-0.2,0.3h-3.6l2.2-3.6h3.6L442.1,1046L442.1,1046z"/>
            <path className="st6" d="M428.7,1042.7c0,0,1.1-2.1,2.9-2.1c2.3,0,1.1,2.1,1.1,2.1H428.7L428.7,1042.7z M436.1,1039.7
              c-0.8-1.1-2.8-1.4-4.5-1.4c-3.6,0-7,2.6-7.9,5.6c-0.4,1.5-0.1,2.8,0.8,3.9c0.9,1,2.4,1.6,4.2,1.6c5.2,0,6.2-3.7,6.2-3.7h-3
              l-0.2,0.1c-0.7,0.6-1.4,1.1-2.5,1c-2.3-0.1-1.2-2.5-1.2-2.5h7.9l0.4-1.1C436.8,1042.4,437.2,1041.2,436.1,1039.7L436.1,1039.7z"/>
            <path className="st6" d="M378.1,1037.7c1.7,0,3.3-0.6,3.5-1.4c0.3-0.8-0.9-1.4-2.6-1.4s-3.3,0.6-3.5,1.4S376.4,1037.7,378.1,1037.7
              L378.1,1037.7z"/>
            <path className="st6" d="M420.9,1037.7c1.7,0,3.3-0.6,3.5-1.4c0.3-0.8-0.9-1.4-2.6-1.4s-3.3,0.6-3.5,1.4
              C418,1037.1,419.2,1037.7,420.9,1037.7L420.9,1037.7z"/>
            <path className="st6" d="M390.7,1048.2l1.4-2.6h-2.4l-0.4,0.5c-0.7,0.7-1.2,1-2.2,1c-0.8,0-1.5,0-1.8-1c-0.2-0.5-0.1-1.2,0.2-1.9
              c0.7-1.4,2-2.4,3.4-2.8c0.9-0.2,1.6,0.4,1.8,1.1l0.1,0.2h2.4l2-4h-2.5c0,0,0,0.6-0.2,0.5c-0.6-0.5-1.4-0.7-2.5-0.7
              c-3.3,0-7.4,0.9-9,5.4c-0.6,1.5-0.3,2.9,0.5,3.9c1,1.3,2.1,1.8,3.6,1.9C389.2,1050,390.7,1048.2,390.7,1048.2L390.7,1048.2z"/>
            <path className="st10" d="M375.2,1026.4l-2-6.9v-0.1h-0.1c-3.9,0.2-6.9-0.1-6.9-0.1h-0.3l0.1,0.2l2.1,7.4v0.1h0.1
              c2.1,0.1,4.3-0.1,6.8-0.4h0.2L375.2,1026.4L375.2,1026.4z"/>
            <path className="st10" d="M375.1,1058.7c-2.5-0.4-4.7-0.5-6.8-0.4h-0.1v0.1l-2.1,7.4l-0.1,0.2h0.3c0,0,3-0.2,6.9-0.1h0.1v-0.1l2-6.9
              l0.1-0.2H375.1L375.1,1058.7z"/>
            <polygon className="st10" points="385.4,1026.3 385.4,1026.1 385.2,1026.1 379.4,1027 379.3,1027.1 379.3,1027.2 379.3,1030.7 
              379.4,1030.7 385.3,1030.7 385.4,1030.7 		"/>
            <path className="st10" d="M424.1,1022.2c-0.1-0.9,0.4-1.3,1.2-0.8l3.8,2.4c0.8,0.5,0.7,1.2-0.2,1.5c0,0-1.3,0.4-3-0.7
              C424.3,1023.4,424.1,1022.2,424.1,1022.2L424.1,1022.2z M414.2,1018.3c0,0-0.9,0.7-2.5,0.5c-1.6-0.3-2.1-1.2-2.1-1.2
              c-0.4-0.7-0.1-1.1,0.6-1l3.7,0.5C414.7,1017.3,414.8,1017.8,414.2,1018.3L414.2,1018.3z M421.5,1020.8c0,0-1.1,0.6-2.8-0.1
              c-1.6-0.6-2-1.7-2-1.7c-0.3-0.8,0.1-1.2,0.9-1l3.8,1.4C422.2,1019.7,422.2,1020.3,421.5,1020.8L421.5,1020.8z M402.9,1016.2
              l3.4,0.2c0.7,0.1,0.9,0.5,0.4,1c0,0-0.7,0.7-2.2,0.6c-1.4-0.1-2-0.9-2-0.9C402,1016.6,402.2,1016.1,402.9,1016.2L402.9,1016.2z
              M439.1,1030.6c-8.4-10.3-19.5-15.8-33-16.3c-6.5-0.2-14.3,1.7-21.8,3.5l-1.3,0.3c-2.2,0.5-4.7,0.9-7.5,1.1h-0.2l0.1,0.2l1.9,6.6
              v0.2h0.2c1.2-0.2,2.4-0.4,3.7-0.7c5.3-1,11.4-2.2,20-2.3c14.6-0.2,22.7,6.5,23.8,7.5h14.3L439.1,1030.6L439.1,1030.6z"/>
            <polygon className="st10" points="379.3,1054.7 379.3,1054.8 379.3,1058.3 379.3,1058.5 379.4,1058.5 385.2,1059.5 385.4,1059.5 
              385.4,1059.3 385.4,1054.8 385.4,1054.7 		"/>
            <path className="st10" d="M404,1062.3L404,1062.3L404,1062.3L404,1062.3z M425.9,1060.9c1.7-1.1,3-0.7,3-0.7c0.9,0.3,1,0.9,0.2,1.5
              l-3.8,2.4c-0.8,0.5-1.4,0.1-1.2-0.8C424.1,1063.2,424.3,1062,425.9,1060.9L425.9,1060.9z M417.5,1069.2c-0.1,0-0.2,0.1-0.4,0.1
              C417.3,1069.2,417.4,1069.2,417.5,1069.2L417.5,1069.2z M414.9,1069.8c-0.3,0.1-0.6,0.1-0.9,0.2
              C414.3,1069.9,414.6,1069.9,414.9,1069.8L414.9,1069.8z M412.8,1070.2c-1.3,0.2-2.5,0.4-3.6,0.5
              C410.3,1070.6,411.5,1070.5,412.8,1070.2L412.8,1070.2z M409.6,1067.8c0,0,0.6-0.9,2.1-1.2c1.6-0.3,2.5,0.5,2.5,0.5
              c0.6,0.5,0.4,1-0.3,1.2l-3.7,0.5C409.5,1068.9,409.2,1068.5,409.6,1067.8L409.6,1067.8z M421.4,1066l-3.8,1.4
              c-0.8,0.3-1.2-0.2-0.9-1c0,0,0.4-1.1,2-1.7c1.7-0.7,2.8,0,2.8,0C422.2,1065.1,422.2,1065.7,421.4,1066L421.4,1066z M406.3,1069
              l-3.4,0.2c-0.7,0-0.9-0.4-0.5-1c0,0,0.6-0.8,2-0.9c1.5-0.1,2.2,0.6,2.2,0.6C407.2,1068.5,407,1069,406.3,1069L406.3,1069z
              M439,1054.7L439,1054.7h-13.8l0,0h-0.3c-1.1,1-9.2,7.7-23.8,7.4c-8.6-0.1-14.6-1.3-20-2.3c-1.3-0.2-2.5-0.5-3.7-0.7h-0.2v0.2
              l-1.9,6.6l-0.1,0.2h0.2c2.8,0.2,5.3,0.6,7.5,1.1l1.3,0.3c7.5,1.8,15.3,3.7,21.8,3.5C419.5,1070.5,430.6,1065.1,439,1054.7l0.1-0.1
              L439,1054.7L439,1054.7z"/>
            <line className="st11" x1="439" y1="1054.7" x2="439" y2="1054.7"/>
            <path className="st7" d="M453.6,1060.2c0.4,0,0.7,0,0.7-0.5c0-0.3-0.3-0.4-0.6-0.4h-0.6v0.9H453.6L453.6,1060.2z M453.1,1061.8h-0.4
              v-2.8h1.1c0.7,0,1,0.2,1,0.8c0,0.5-0.3,0.7-0.7,0.8l0.8,1.2h-0.5l-0.7-1.2h-0.5L453.1,1061.8L453.1,1061.8z M453.6,1062.4
              c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2C451.6,1061.6,452.5,1062.4,453.6,1062.4L453.6,1062.4z M451.1,1060.4
              c0-1.4,1.1-2.4,2.5-2.4c1.3,0,2.5,1,2.5,2.4s-1.1,2.4-2.5,2.4C452.3,1062.8,451.1,1061.8,451.1,1060.4L451.1,1060.4z"/>
          </g>
        </g>
        <g id="seating">
          <g id="court" className="st12">
            <g className="st13">
              <rect x="1313.4" y="972.7" className="st14" width="419" height="223.5"/>
              <line className="st14" x1="1522.9" y1="972.7" x2="1522.9" y2="1196.2"/>
              <circle className="st14" cx="1522.2" cy="1087.3" r="27.6"/>
              <circle className="st14" cx="1522.2" cy="1086.6" r="10"/>
              <circle className="st14" cx="1643.8" cy="1087.4" r="27.7"/>
              <rect x="1646" y="1059.6" className="st14" width="86.4" height="55.2"/>
              <circle className="st14" cx="1402" cy="1087.5" r="27.7"/>
              <rect x="1313.5" y="1060" className="st14" width="86.4" height="55.2"/>
            </g>
            <line className="st15" x1="1313.4" y1="996.9" x2="1386.8" y2="996.9"/>
            <line className="st15" x1="1313.4" y1="1172" x2="1386.1" y2="1172"/>
            <line className="st15" x1="1732.4" y1="1172" x2="1664.8" y2="1172"/>
            <line className="st15" x1="1732.1" y1="996.9" x2="1663.8" y2="996.9"/>
            <path className="st15" d="M1379.9,1172c47.1,0,85.2-42.8,85.2-89.9s-38.2-85.2-85.2-85.2"/>
            <path className="st15" d="M1671.5,996.9c-47.1,0-85.2,42.8-85.2,89.9s38.2,85.2,85.2,85.2"/>
            <rect x="1260" y="951.5" className="st15" width="526.4" height="265.5"/>
          </g>
          <g className="st12">
            <rect x="1280.2" y="929.8" className="st16" width="169.8" height="16.6"/>
            <rect x="1280.2" y="1223.2" className="st16" width="488.6" height="16.6"/>
            <rect x="1599.1" y="929.8" className="st16" width="169.8" height="16.6"/>
            <rect x="1791.3" y="951.5" className="st17" width="16.6" height="115.8"/>
            <rect x="1791.4" y="1103.6" className="st17" width="16.6" height="115.8"/>
            <rect x="1240.3" y="951.5" className="st17" width="16.6" height="115.8"/>
            <rect x="1240.4" y="1103.6" className="st17" width="16.6" height="115.8"/>
          </g>
          <g>
            <polygon className="st18" points="985.2,610 1059.5,810.8 1161.8,810.8 1161.8,608.6 		"/>
            <polygon className="st18" points="1178.8,608.6 1178.8,888.8 1305.2,888.8 1305.2,608.8 		"/>
            <polygon className="st18" points="1049.8,1018 887.5,1018 887.5,1144.4 1049.7,1144.4 		"/>
            <polygon className="st18" points="1233.8,1021.2 1058.4,1021.2 1058.4,1147.6 1233.6,1147.6 		"/>
            <polygon className="st18" points="1233.8,939 1058.4,939 1058.4,1003.7 1233.6,1003.7 		"/>
            <polygon className="st18" points="1233.8,1163.9 1058.4,1163.9 1058.4,1228.5 1233.6,1228.5 		"/>
            <rect x="1321.1" y="625.2" className="st18" width="128.5" height="293.5"/>
            <rect x="1465.5" y="608.6" className="st18" width="121.7" height="312.6"/>
            <rect x="1605.4" y="625.2" className="st18" width="127.7" height="296"/>
            <rect x="1748.2" y="609.3" className="st18" width="128" height="279.4"/>
            <rect x="1812.2" y="935.5" className="st18" width="176.1" height="69.1"/>
            <rect x="1812.2" y="1164.1" className="st18" width="176.1" height="66"/>
            <rect x="1812.2" y="1019.4" className="st18" width="176.1" height="125"/>
            <polygon className="st18" points="1891,610 1891,887.6 1953.2,887.6 2074.4,610 		"/>
            <polygon className="st18" points="2042,719.2 2090.8,764.2 2102.2,775 2175.6,704.1 2087.1,615.6 		"/>
            <polygon className="st18" points="1995.8,935.5 2271.1,822.7 2271.1,1004.6 1995.8,1004.6 		"/>
            <rect x="1995.8" y="1019.4" className="st18" width="275.3" height="125"/>
            <polygon className="st18" points="1953.5,1280.2 2064.6,1558.9 1891,1558.9 1891,1280.2 		"/>
            <rect x="1748" y="1271.1" className="st18" width="124.6" height="287.8"/>
            <rect x="1605" y="1247.4" className="st18" width="124.6" height="295.7"/>
            <polygon className="st18" points="1588.6,1485.5 1588.6,1556.5 1464.1,1556.5 1464.1,1247.8 1588.6,1247.8 		"/>
            <rect x="1319.7" y="1246.1" className="st18" width="130.3" height="297"/>
            <rect x="1175.5" y="1271.1" className="st18" width="124.3" height="287.8"/>
            <polygon className="st18" points="1161.4,1290.5 1161.4,1558.9 984.5,1558.9 1091.3,1290.5 		"/>
            <polygon className="st18" points="2018.3,835.4 1996.4,818.2 2042,719.2 2090.8,764.2 		"/>
            <polygon className="st18" points="1989.7,1248.8 2276.2,1368 2188.9,1459.9 2055,1322.5 2040.7,1335.5 2175.6,1472 2089.9,1553.9 
              1969.2,1271.1 		"/>
            <polygon className="st18" points="968.3,614.9 885,703.8 954.7,770.3 942.3,782.6 871.9,717.4 800.5,796.5 892.5,832.1 892.5,832.1 
              990.8,872.4 1046.9,813.8 		"/>
            <polygon className="st18" points="2182.7,712.1 2266.9,802.7 2064.6,887.6 2049.8,870 2122,796.4 2111.2,783.7 		"/>
            <path className="st18" d="M959.8,1553.9c0,0,88.1-199.2,86.7-200.2c-1.4-0.9-66-59.9-66-59.9l-189,77.9l78.9,76.1l117-121.3l12.6,11.2
              l-126.9,132L959.8,1553.9z"/>
            <polygon className="st18" points="2276.2,1164.1 2276.2,1346.2 1995.8,1233.2 1995.8,1164.1 		"/>
            <path className="st18" d="M887.5,861.4c2.9,0.7,162.3,67,162.3,67v76.3H887.5V861.4z"/>
            <polygon className="st18" points="887.5,1163.9 887.5,1305.9 1049.8,1241.8 1049.8,1164.1 		"/>
          </g>
          <g id="numbers">
            <text transform="matrix(1 0 0 1 1356.8657 755.4639)" className="st19 st4 st5">106</text>
            <text transform="matrix(1 0 0 1 1495.4385 755.4639)" className="st19 st4 st5">107</text>
            <text transform="matrix(1 0 0 1 1637.501 755.4639)" className="st19 st4 st5">108</text>
            <text transform="matrix(1 0 0 1 1780.1416 755.4639)" className="st19 st4 st5">109</text>
            <text transform="matrix(1 0 0 1 1931.8057 703.9629)" className="st19 st4 st5">110</text>
            <text transform="matrix(1 0 0 1 2074.1367 716.3193)" className="st19 st4 st5">111</text>
            <text transform="matrix(1 0 0 1 2153.7295 791.0557)" className="st19 st4 st5">113</text>
            <text transform="matrix(1 0 0 1 2142.5645 935.4946)" className="st19 st4 st5">114</text>
            <text transform="matrix(1 0 0 1 1879.5176 977.2812)" className="st19 st4 st5">14</text>
            <text transform="matrix(1 0 0 1 1879.5176 1093.4917)" className="st19 st4 st5">15</text>
            <text transform="matrix(1 0 0 1 1879.5176 1206.5605)" className="st19 st4 st5">16</text>
            <text transform="matrix(1 0 0 1 2142.5645 1091.2622)" className="st19 st4 st5">115</text>
            <text transform="matrix(1 0 0 1 2142.5645 1237.8301)" className="st19 st4 st5">116</text>
            <text transform="matrix(1 0 0 1 2145.4668 1380.3799)" className="st19 st4 st5">117</text>
            <text transform="matrix(1 0 0 1 2066.499 1461.3682)" className="st19 st4 st5">119</text>
            <text transform="matrix(1 0 0 1 1926.1855 1472.0166)" className="st19 st4 st5">120</text>
            <text transform="matrix(1 0 0 1 1780.1416 1424.793)" className="st19 st4 st5">121</text>
            <text transform="matrix(1 0 0 1 1637.5 1424.793)" className="st19 st4 st5">122</text>
            <text transform="matrix(1 0 0 1 1495.4395 1424.793)" className="st19 st4 st5">123</text>
            <text transform="matrix(1 0 0 1 1352.6465 1424.793)" className="st19 st4 st5">124</text>
            <text transform="matrix(1 0 0 1 1210.3374 1424.793)" className="st19 st4 st5">125</text>
            <text transform="matrix(1 0 0 1 1061.4512 1473.0664)" className="st19 st4 st5">126</text>
            <text transform="matrix(1 0 0 1 932.4004 1466.25)" className="st19 st4 st5">127</text>
            <text transform="matrix(1 0 0 1 860.2192 1380.3799)" className="st19 st4 st5">129</text>
            <text transform="matrix(1 0 0 1 941.3589 1225.7246)" className="st19 st4 st5">130</text>
            <text transform="matrix(1 0 0 1 941.3599 1091.2612)" className="st19 st4 st5">131</text>
            <text transform="matrix(1 0 0 1 941.3589 959.8926)" className="st19 st4 st5">132</text>
            <text transform="matrix(1 0 0 1 1124.2163 982.8984)" className="st19 st4 st5">32</text>
            <text transform="matrix(1 0 0 1 1124.2163 1091.2612)" className="st19 st4 st5">31</text>
            <text transform="matrix(1 0 0 1 1124.2163 1206.3096)" className="st19 st4 st5">30</text>
            <text transform="matrix(1 0 0 1 851.0605 793.8647)" className="st19 st4 st5">101</text>
            <text transform="matrix(1 0 0 1 926.7808 721.9653)" className="st19 st4 st5">103</text>
            <text transform="matrix(1 0 0 1 1061.4512 710.4258)" className="st19 st4 st5">104</text>
            <text transform="matrix(1 0 0 1 1210.3374 755.4639)" className="st19 st4 st5">105</text>
          </g>
          <g id="wheelchair">
            <g>
              <g>
                <circle className="st6" cx="1666.1" cy="1542.3" r="16.9"/>
                <path className="st20" d="M1666.1,1560.1c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8S1676,1560.1,1666.1,1560.1z
                  M1666.1,1526.3c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S1675,1526.3,1666.1,1526.3z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M1666.8,1539.6c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C1666.8,1539.4,1666.8,1539.5,1666.8,1539.6z"/>
                </g>
                <g>
                  <path className="st20" d="M1663.6,1539.4c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7
                    C1661.4,1540.4,1662.5,1539.8,1663.6,1539.4C1663.5,1539.4,1663.6,1539.4,1663.6,1539.4z"/>
                </g>
                <g>
                  <path className="st20" d="M1667.9,1533.4c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C1666.8,1530.9,1667.9,1532,1667.9,1533.4z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1388.6" cy="1542.3" r="16.9"/>
                <path className="st20" d="M1388.6,1560.1c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8S1398.4,1560.1,1388.6,1560.1z
                  M1388.6,1526.3c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S1397.5,1526.3,1388.6,1526.3z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M1389.2,1539.6c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C1389.2,1539.4,1389.2,1539.5,1389.2,1539.6z"/>
                </g>
                <g>
                  <path className="st20" d="M1386,1539.4c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7
                    C1383.8,1540.4,1384.9,1539.8,1386,1539.4C1385.9,1539.4,1386,1539.4,1386,1539.4z"/>
                </g>
                <g>
                  <path className="st20" d="M1390.4,1533.4c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C1389.3,1530.9,1390.4,1532,1390.4,1533.4z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1386.8" cy="626.1" r="16.9"/>
                <path className="st20" d="M1386.8,643.9c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8
                  C1404.6,635.9,1396.6,643.9,1386.8,643.9z M1386.8,610c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1
                  S1395.7,610,1386.8,610z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M1387.5,623.3c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C1387.5,623.2,1387.5,623.2,1387.5,623.3z"/>
                </g>
                <g>
                  <path className="st20" d="M1384.3,623.1c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7
                    C1382.1,624.1,1383.2,623.5,1384.3,623.1C1384.2,623.2,1384.2,623.2,1384.3,623.1z"/>
                </g>
                <g>
                  <path className="st20" d="M1388.6,617.1c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C1387.5,614.7,1388.6,615.8,1388.6,617.1z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1082.9" cy="610" r="16.9"/>
                <path className="st20" d="M1082.9,627.8c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8S1092.8,627.8,1082.9,627.8z
                  M1082.9,594c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S1091.8,594,1082.9,594z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M1083.6,607.2c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C1083.6,607.1,1083.6,607.2,1083.6,607.2z"/>
                </g>
                <g>
                  <path className="st20" d="M1080.4,607.1c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7
                    C1078.2,608.1,1079.3,607.5,1080.4,607.1C1080.3,607.1,1080.4,607.1,1080.4,607.1z"/>
                </g>
                <g>
                  <path className="st20" d="M1084.7,601c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C1083.6,598.6,1084.7,599.7,1084.7,601z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1967.4" cy="1559.5" r="16.9"/>
                <path className="st20" d="M1967.4,1577.3c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8S1977.2,1577.3,1967.4,1577.3z
                  M1967.4,1543.4c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S1976.2,1543.4,1967.4,1543.4z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M1968,1556.7c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C1968,1556.6,1968,1556.6,1968,1556.7z"/>
                </g>
                <g>
                  <path className="st20" d="M1964.8,1556.5c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7
                    C1962.6,1557.5,1963.7,1556.9,1964.8,1556.5C1964.7,1556.5,1964.8,1556.5,1964.8,1556.5z"/>
                </g>
                <g>
                  <path className="st20" d="M1969.2,1550.5c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C1968.1,1548,1969.2,1549.1,1969.2,1550.5z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="2227.9" cy="1420" r="16.9"/>
                <path className="st20" d="M2227.9,1437.8c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8S2237.8,1437.8,2227.9,1437.8z
                  M2227.9,1403.9c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1c8.9,0,16.1-7.2,16.1-16.1S2236.8,1403.9,2227.9,1403.9z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M2228.6,1417.2c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C2228.6,1417.1,2228.6,1417.1,2228.6,1417.2z"/>
                </g>
                <g>
                  <path className="st20" d="M2225.4,1417c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7c0.9-0.9,2-1.5,3.2-1.9
                    C2225.3,1417,2225.3,1417,2225.4,1417z"/>
                </g>
                <g>
                  <path className="st20" d="M2229.7,1411c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C2228.6,1408.5,2229.7,1409.6,2229.7,1411z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="2267.4" cy="1084.2" r="16.9"/>
                <path className="st20" d="M2267.4,1102c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8S2277.2,1102,2267.4,1102z
                  M2267.4,1068.1c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S2276.2,1068.1,2267.4,1068.1z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M2268,1081.4c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C2268,1081.3,2268,1081.4,2268,1081.4z"/>
                </g>
                <g>
                  <path className="st20" d="M2264.8,1081.3c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7c0.9-0.9,2-1.5,3.2-1.9
                    C2264.7,1081.3,2264.8,1081.3,2264.8,1081.3z"/>
                </g>
                <g>
                  <path className="st20" d="M2269.1,1075.2c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C2268,1072.8,2269.1,1073.9,2269.1,1075.2z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="2220.6" cy="753.2" r="16.9"/>
                <path className="st20" d="M2220.6,771c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8C2238.5,763,2230.5,771,2220.6,771
                  z M2220.6,737.1c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S2229.5,737.1,2220.6,737.1z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M2221.3,750.4c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C2221.3,750.3,2221.3,750.3,2221.3,750.4z"/>
                </g>
                <g>
                  <path className="st20" d="M2218.1,750.2c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7c0.9-0.9,2-1.5,3.2-1.9
                    C2218,750.3,2218,750.2,2218.1,750.2z"/>
                </g>
                <g>
                  <path className="st20" d="M2222.4,744.2c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C2221.3,741.7,2222.4,742.8,2222.4,744.2z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="858.8" cy="731.2" r="16.9"/>
                <path className="st20" d="M858.8,749c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8S868.6,749,858.8,749z M858.8,715.2
                  c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S867.6,715.2,858.8,715.2z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M859.4,728.4c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C859.4,728.3,859.4,728.4,859.4,728.4z"/>
                </g>
                <g>
                  <path className="st20" d="M856.2,728.3c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7
                    C854,729.3,855.1,728.7,856.2,728.3C856.1,728.3,856.2,728.3,856.2,728.3z"/>
                </g>
                <g>
                  <path className="st20" d="M860.5,722.2c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C859.5,719.8,860.5,720.9,860.5,722.2z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="850.8" cy="1424.8" r="16.9"/>
                <path className="st20" d="M850.8,1442.6c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8S860.6,1442.6,850.8,1442.6z
                  M850.8,1408.7c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S859.7,1408.7,850.8,1408.7z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M851.5,1422c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C851.5,1421.9,851.5,1421.9,851.5,1422z"/>
                </g>
                <g>
                  <path className="st20" d="M848.3,1421.8c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7
                    C846.1,1422.8,847.2,1422.2,848.3,1421.8C848.2,1421.9,848.2,1421.9,848.3,1421.8z"/>
                </g>
                <g>
                  <path className="st20" d="M852.6,1415.8c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C851.5,1413.4,852.6,1414.5,852.6,1415.8z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1669.3" cy="626.1" r="16.9"/>
                <path className="st20" d="M1669.3,643.9c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8
                  C1687.1,635.9,1679.1,643.9,1669.3,643.9z M1669.3,610c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1
                  S1678.1,610,1669.3,610z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M1669.9,623.3c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C1669.9,623.2,1669.9,623.2,1669.9,623.3z"/>
                </g>
                <g>
                  <path className="st20" d="M1666.7,623.1c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7
                    C1664.5,624.1,1665.6,623.5,1666.7,623.1C1666.6,623.2,1666.7,623.2,1666.7,623.1z"/>
                </g>
                <g>
                  <path className="st20" d="M1671,617.1c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4
                    C1669.9,614.7,1671,615.8,1671,617.1z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1977.8" cy="614.2" r="16.9"/>
                <path className="st20" d="M1977.8,632c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8s17.8,8,17.8,17.8S1987.6,632,1977.8,632z
                  M1977.8,598.1c-8.9,0-16.1,7.2-16.1,16.1s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S1986.6,598.1,1977.8,598.1z"/>
              </g>
              <g>
                <g>
                  <path className="st20" d="M1978.4,611.4c0.1,0,0.1,0,0.2,0c0.7,0,1.5,0,2.2,0s1.2,0.5,1.3,1.1c0.1,0.7-0.3,1.3-1,1.4
                    c-0.1,0-0.3,0-0.4,0c-0.7,0-1.4,0-2.2,0h-0.1c0,0.6,0,1.3,0,1.9c0.1,0,0.1,0,0.2,0c1.4,0,2.8,0,4.3,0c0.6,0,1,0.3,1.3,0.8
                    c0.8,1.6,1.5,3.2,2.3,4.8c0.4,0.9-0.2,1.9-1.2,1.9c-0.5,0-0.9-0.3-1.1-0.8c-0.6-1.3-1.3-2.7-1.9-4c-0.1-0.1-0.1-0.2-0.3-0.2
                    c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1-0.4-1.3-1c0-0.1,0-0.2,0-0.3c0-2.5,0-5,0-7.5c0-0.7,0.5-1.2,1.2-1.3c0.6-0.1,1.2,0.4,1.4,1
                    c0,0.1,0,0.2,0,0.3c0,0.5,0,1,0,1.5C1978.4,611.3,1978.4,611.3,1978.4,611.4z"/>
                </g>
                <g>
                  <path className="st20" d="M1975.2,611.2c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1-0.1,0.2c-1.8,0.7-3,2-3.4,3.9
                    c-0.6,2.5,0.6,5.2,3,6.3c2.9,1.4,6.3,0.2,7.6-2.8c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.1,0.1,0.1c0.3,0.6,0.5,1.1,0.8,1.7
                    c0,0.1,0,0.1,0,0.2c-1.2,1.7-2.8,2.8-4.9,3.1c-3.1,0.5-6-1-7.5-3.6c-1.6-2.8-1.1-6.4,1.2-8.7
                    C1973,612.2,1974.1,611.6,1975.2,611.2C1975.1,611.2,1975.2,611.2,1975.2,611.2z"/>
                </g>
                <g>
                  <path className="st20" d="M1979.5,605.2c0,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1.1-2.4-2.5s1.1-2.4,2.5-2.4S1979.5,603.8,1979.5,605.2z
                    "/>
                </g>
              </g>
            </g>
          </g>
          <g>
            <path className="st6" d="M1562.1,1058.7c-1.5-2.8-4.6-4.5-8.2-4.5h-26.5c-14.2-26.9-49.8-45.9-91.5-45.9s-77.3,19.1-91.5,45.9h-5.5
              c-4.7,0-10,2.9-12.5,6.9l-18.2,29c-1.9,3-2.1,6.5-0.6,9.3s4.6,4.5,8.2,4.5h27.6c13.4,27.9,49.8,47.9,92.5,47.9s79-20,92.5-47.9
              h2.4c4.7,0,10-2.9,12.5-6.9l18.2-29C1563.4,1064.9,1563.6,1061.5,1562.1,1058.7L1562.1,1058.7z"/>
            <path className="st7" d="M1558.8,1066.3l-18.2,29c-1.9,3.1-6.1,5.4-9.8,5.4h-4.5c-12.2,27.7-48.1,47.9-90.4,47.9
              c-30.3,0-59.1-10.6-76.9-28.5c-6-6-10.5-12.5-13.5-19.4h-29.7c-2.4,0-4.4-1-5.4-2.8s-0.8-4,0.5-6l18.2-29c1.9-3.1,6.2-5.4,9.8-5.4
              h7.4c3-6.2,7.2-12,12.6-17.4c17.8-17.8,46.6-28.5,76.9-28.5s59.1,10.6,76.9,28.5c5.4,5.4,9.6,11.3,12.6,17.4h28.5
              c2.4,0,4.4,1,5.3,2.8C1560.3,1062,1560.1,1064.2,1558.8,1066.3L1558.8,1066.3z M1562.1,1058.7c-1.5-2.8-4.6-4.5-8.2-4.5h-26.5
              c-14.2-26.9-49.8-45.9-91.5-45.9s-77.3,19.1-91.5,45.9h-5.5c-4.7,0-10,2.9-12.5,6.9l-18.2,29c-1.9,3-2.1,6.5-0.6,9.3
              s4.6,4.5,8.2,4.5h27.6c13.4,27.9,49.8,47.9,92.5,47.9s79-20,92.5-47.9h2.4c4.7,0,10-2.9,12.5-6.9l18.2-29
              C1563.4,1064.9,1563.6,1061.5,1562.1,1058.7L1562.1,1058.7z"/>
            <path className="st8" d="M1468.3,1123.3l6.9-2.5c1.4-0.6,1.5-1.7,0.2-2.4c0,0-2-1.1-5,0.1c-2.9,1.1-3.6,3.1-3.6,3.1
              C1466.1,1123.1,1466.9,1123.8,1468.3,1123.3L1468.3,1123.3z"/>
            <path className="st8" d="M1442,1126.7l6-0.4c1.3-0.1,1.6-1,0.7-1.9c0,0-1.3-1.3-4-1.1c-2.6,0.2-3.6,1.6-3.6,1.6
              C1440.3,1126,1440.7,1126.8,1442,1126.7L1442,1126.7z"/>
            <path className="st8" d="M1455.2,1126l6.6-1c1.4-0.3,1.6-1.2,0.6-2.1c0,0-1.6-1.3-4.5-0.8c-2.8,0.5-3.8,2.1-3.8,2.1
              C1453.3,1125.3,1453.8,1126.1,1455.2,1126L1455.2,1126z"/>
            <path className="st8" d="M1482.3,1117.4l6.7-4.4c1.4-1,1.2-2.2-0.4-2.6c0,0-2.4-0.7-5.4,1.3c-2.8,1.9-3.2,4.2-3.2,4.2
              C1479.8,1117.6,1480.8,1118.3,1482.3,1117.4L1482.3,1117.4z"/>
            <path className="st9" d="M1399.8,1100.7h10.5 M1349,1100.7c2.8,6,6.9,11.8,12.3,17.1c17.2,17.2,45.1,27.5,74.6,27.5
              c40.4,0,74.7-18.7,86.9-44.6"/>
            <path className="st9" d="M1521.9,1057.4c-2.8-5.3-6.6-10.4-11.3-15.2c-17.2-17.2-45.2-27.5-74.6-27.5c-29.5,0-57.4,10.3-74.6,27.5
              c-4.7,4.7-8.5,9.8-11.3,15.2"/>
            <path className="st7" d="M1553.9,1060.6c2.5,0,3.5,1.8,2.2,3.9l-18.2,29c-1.3,2.2-4.5,3.9-7.1,3.9h-215c-2.5,0-3.5-1.8-2.2-3.9
              l18.2-29c1.4-2.2,4.5-3.9,7.1-3.9H1553.9L1553.9,1060.6z"/>
            <path className="st6" d="M1354.3,1085.2h3.7c3.1,0,4.6-0.2,7.4-1.7c2.3-1.2,3.6-3.3,4.8-5.1c1.3-2.1,0.8-3.8,0.1-4.9
              c-0.5-0.8-1.6-1.5-3.6-1.8h-4.2L1354.3,1085.2L1354.3,1085.2z M1344,1085.4l8.4-14h-5.2l3.1-5.1h18.1c5.4,0,8.6,1,10.5,3.3
              s3,6.9,0.9,10.5c-1.9,3.2-3.8,6.4-8,8.7c-4.7,2.5-12,1.9-17.7,1.9h-18.4l3.1-5.1L1344,1085.4L1344,1085.4z"/>
            <polygon className="st6" points="1393.5,1085.5 1396.8,1085.5 1393.7,1090.6 1379.4,1090.6 1382.5,1085.5 1385.7,1085.5 
              1390.7,1077.2 1387.5,1077.2 1390.6,1072.1 1401.7,1072.1 		"/>
            <polygon className="st6" points="1470.5,1085.5 1473.8,1085.5 1470.7,1090.6 1456.4,1090.6 1459.5,1085.5 1462.7,1085.5 
              1467.7,1077.2 1464.5,1077.2 1467.6,1072.1 1478.7,1072.1 		"/>
            <polygon className="st6" points="1445.7,1076.2 1455.8,1071.4 1462.1,1071.5 1459.1,1076.4 1455.8,1076.5 1447.3,1080.5 
              1450.6,1084.9 1455.3,1084.9 1452.2,1090 1443.8,1090 1439.1,1083 1435.5,1090 1423.8,1090 1426.9,1084.9 1430.1,1084.9 
              1438.6,1070.8 1435.4,1070.9 1438.5,1065.7 1449.6,1065.7 1442.2,1077.9 		"/>
            <path className="st6" d="M1512.3,1085c-0.2,0.4-0.2,1.1,0.7,1.5c1,0.5,1.7,0.2,2.2,0.2c1.2,0,2.8-0.6,3.4-1.5c0.5-0.8,0.1-1-2-1.4
              l-0.4-0.1c-3.6-0.7-9-1.8-6.2-6.3c2.3-3.8,8.2-5.8,12.8-5.8c1.9,0,4.3,0.8,4.3,0.8l0.2-0.4h6.4l-3.7,6.1h-5.7c0,0,0.7-1.1-0.5-1.7
              c-0.8-0.4-1.6-0.3-2.2-0.3c-1.1,0-2.5,0.4-2.9,1.2c-0.4,0.7,0,1,2.2,1.4c3.5,0.6,9.3,1.6,6.5,6.4c-2.5,4.1-8.7,6-13.4,6
              c-3.1,0-4.8-1.1-4.8-1.1l-0.4,0.6h-6.5l3.9-6.5h6.6L1512.3,1085L1512.3,1085z"/>
            <path className="st6" d="M1488.4,1079c0,0,2-3.7,5.3-3.7c4.2,0,1.9,3.7,1.9,3.7H1488.4L1488.4,1079z M1501.7,1073.6
              c-1.5-2.1-5-2.5-8-2.5c-6.4,0-12.6,4.7-14.1,10.2c-0.8,2.6-0.2,5.1,1.5,7c1.7,1.8,4.4,2.8,7.5,2.8c9.3,0.1,11.1-6.6,11.1-6.6h-5.4
              l-0.3,0.3c-1.3,1.2-2.6,2-4.4,1.9c-4.2-0.2-2.1-4.5-2.1-4.5h14.3c0,0,0.8-1.9,0.8-2C1502.8,1078.4,1503.6,1076.3,1501.7,1073.6
              L1501.7,1073.6z"/>
            <path className="st6" d="M1397.4,1070.1c3,0,5.9-1.1,6.4-2.5s-1.6-2.5-4.7-2.5c-3,0-5.9,1.1-6.4,2.5
              C1392.3,1069,1394.4,1070.1,1397.4,1070.1L1397.4,1070.1z"/>
            <path className="st6" d="M1474.2,1070.1c3,0,5.9-1.1,6.4-2.5s-1.6-2.5-4.6-2.5s-5.9,1.1-6.4,2.5S1471.2,1070.1,1474.2,1070.1
              L1474.2,1070.1z"/>
            <path className="st6" d="M1420,1088.9l2.6-4.8h-4.4l-0.6,1c-1.3,1.2-2.1,1.8-4,1.8c-1.4,0-2.8,0.1-3.3-1.7c-0.3-0.9-0.2-2.2,0.3-3.4
              c1.3-2.6,3.5-4.3,6.1-5c1.6-0.4,2.9,0.7,3.2,1.9l0.1,0.4h4.4l3.6-7.2h-4.6c0,0,0,1.1-0.4,0.8c-1.1-0.9-2.6-1.3-4.5-1.3
              c-6,0-13.3,1.6-16.3,9.6c-1,2.8-0.6,5.1,0.9,7c1.8,2.4,3.9,3.2,6.5,3.4C1417.3,1092.2,1420,1088.9,1420,1088.9L1420,1088.9z"/>
            <path className="st10" d="M1392.2,1049.7l-3.6-12.3l-0.1-0.2h-0.3c-7.1,0.3-12.4-0.1-12.4-0.1h-0.5l0.1,0.4l3.8,13.3l0.1,0.2h0.2
              c3.8,0.1,7.7-0.2,12.3-0.8l0.4-0.1V1049.7L1392.2,1049.7z"/>
            <path className="st10" d="M1391.9,1107.8c-4.5-0.6-8.4-0.9-12.3-0.8h-0.2l-0.1,0.2l-3.8,13.3l-0.1,0.4h0.5c0.1,0,5.4-0.4,12.4-0.1h0.3
              l0.1-0.2l3.6-12.3l0.1-0.4L1391.9,1107.8L1391.9,1107.8z"/>
            <polygon className="st10" points="1410.6,1049.5 1410.6,1049.1 1410.2,1049.2 1399.7,1050.9 1399.5,1050.9 1399.5,1051.2 
              1399.5,1057.4 1399.8,1057.4 1410.3,1057.4 1410.6,1057.4 		"/>
            <path className="st10" d="M1480,1042.1c-0.2-1.7,0.8-2.3,2.2-1.5l6.7,4.4c1.4,1,1.2,2.2-0.4,2.6c0,0-2.4,0.7-5.4-1.3
              C1480.4,1044.4,1480,1042.1,1480,1042.1L1480,1042.1z M1462.3,1035.2c0,0-1.6,1.3-4.5,0.8c-2.8-0.5-3.8-2.1-3.8-2.1
              c-0.7-1.2-0.2-2,1.2-1.8l6.6,1C1463.1,1033.3,1463.4,1034.2,1462.3,1035.2L1462.3,1035.2z M1475.3,1039.6c0,0-2,1.1-5-0.1
              c-2.9-1.1-3.6-3.1-3.6-3.1c-0.5-1.4,0.2-2.2,1.7-1.7l6.9,2.5C1476.6,1037.8,1476.7,1038.8,1475.3,1039.6L1475.3,1039.6z
              M1442,1031.3l6,0.4c1.3,0.1,1.6,1,0.7,1.9c0,0-1.3,1.3-4,1.1c-2.6-0.2-3.6-1.6-3.6-1.6C1440.3,1032.1,1440.7,1031.3,1442,1031.3
              L1442,1031.3z M1507,1057.2c-15.2-18.5-35.1-28.4-59.3-29.2c-11.8-0.4-25.7,3-39.2,6.3l-2.4,0.6c-4,1-8.5,1.6-13.5,2h-0.4l0.1,0.4
              l3.4,11.9l0.1,0.3h0.3c2.2-0.4,4.4-0.8,6.7-1.2c9.6-1.8,20.5-3.9,35.9-4.1c26.3-0.4,40.8,11.7,42.8,13.4h25.7L1507,1057.2
              L1507,1057.2z"/>
            <polygon className="st10" points="1399.5,1100.6 1399.5,1100.7 1399.5,1107.1 1399.5,1107.4 1399.7,1107.4 1410.2,1109.1 
              1410.6,1109.2 1410.6,1108.8 1410.6,1100.7 1410.6,1100.6 		"/>
            <path className="st10" d="M1443.9,1114.2L1443.9,1114.2L1443.9,1114.2L1443.9,1114.2z M1483.2,1111.7c3-2,5.4-1.3,5.4-1.3
              c1.6,0.5,1.8,1.7,0.4,2.6l-6.7,4.4c-1.4,0.9-2.4,0.2-2.2-1.5C1480,1115.9,1480.4,1113.7,1483.2,1111.7L1483.2,1111.7z
              M1468.2,1126.6c-0.2,0.1-0.4,0.1-0.7,0.2C1467.8,1126.7,1468,1126.6,1468.2,1126.6L1468.2,1126.6z M1463.5,1127.8
              c-0.5,0.1-1,0.2-1.5,0.3C1462.5,1128,1463,1127.9,1463.5,1127.8L1463.5,1127.8z M1459.8,1128.5c-2.3,0.4-4.5,0.7-6.5,0.9
              C1455.3,1129.2,1457.5,1128.9,1459.8,1128.5L1459.8,1128.5z M1454,1124.1c0,0,1-1.6,3.8-2.1c2.9-0.5,4.5,0.8,4.5,0.8
              c1.1,0.9,0.8,1.9-0.6,2.1l-6.6,1C1453.8,1126.1,1453.3,1125.3,1454,1124.1L1454,1124.1z M1475.2,1120.8l-6.9,2.5
              c-1.5,0.5-2.2-0.3-1.7-1.7c0,0,0.7-1.9,3.6-3.1c3.1-1.2,5-0.1,5-0.1C1476.7,1119.2,1476.6,1120.3,1475.2,1120.8L1475.2,1120.8z
              M1448,1126.4l-6,0.4c-1.3,0-1.7-0.8-0.9-1.8c0,0,1-1.4,3.6-1.6c2.7-0.2,4,1.1,4,1.1C1449.6,1125.4,1449.3,1126.2,1448,1126.4
              L1448,1126.4z M1506.8,1100.6L1506.8,1100.6H1482l0,0h-0.5c-2,1.8-16.5,13.8-42.7,13.4c-15.4-0.2-26.3-2.3-35.9-4.1
              c-2.3-0.4-4.5-0.9-6.7-1.2h-0.3l-0.1,0.3l-3.4,11.9l-0.1,0.4h0.4c5,0.4,9.5,1.1,13.5,2l2.4,0.6c13.5,3.3,27.4,6.7,39.2,6.3
              c24.2-0.8,44.1-10.7,59.3-29.2l0.2-0.2h-0.5V1100.6z"/>
            <path className="st10" d="M1506.8,1100.6L1506.8,1100.6"/>
            <path className="st7" d="M1533.1,1110.5c0.7,0,1.2,0,1.2-0.8c0-0.6-0.6-0.7-1.1-0.7h-1v1.6L1533.1,1110.5L1533.1,1110.5z
              M1532.1,1113.3h-0.8v-5h1.9c1.2,0,1.8,0.4,1.8,1.4c0,0.9-0.6,1.3-1.3,1.4l1.4,2.2h-0.8l-1.3-2.2h-0.9L1532.1,1113.3
              L1532.1,1113.3z M1533.1,1114.5c2,0,3.5-1.5,3.5-3.6s-1.6-3.6-3.5-3.6c-2,0-3.6,1.6-3.6,3.6S1531.1,1114.5,1533.1,1114.5
              L1533.1,1114.5z M1528.6,1110.8c0-2.5,2-4.4,4.4-4.4s4.4,1.8,4.4,4.4c0,2.5-2,4.4-4.4,4.4
              C1530.7,1115.2,1528.6,1113.3,1528.6,1110.8L1528.6,1110.8z"/>
            <path className="st7" d="M1602,1080l8.7-9.1l-2.9,9.1H1602L1602,1080z M1606.5,1085l-0.2,0.3c-0.4,0.6-1.3,1-3,1.2l-2.6,4.1h17
              l1.9-3.7c-1.9-0.3-2.8-1.1-2.9-1.4c0-0.2,0-0.6,0.2-1.2l4.9-18.4H1607l-17.3,18l-2.3,1.9c-0.6,0.3-1.3,0.6-2.1,0.8l-2.6,4.1h13.2
              l2.6-4.2c-1.4-0.1-2-0.5-1.5-1.2l0.2-0.4l0.3-0.4l0.9-0.9h8.3l-0.3,1L1606.5,1085L1606.5,1085z"/>
            <path className="st7" d="M1643.5,1072.7c-2.2,0.1-4.2,0.8-4.9,1.3l0.8-1.5h-8.4l-1.8,3.6c0.2,0.1,0.5,0.2,0.7,0.3
              c0.4,0.3,0.4,0.8,0,1.5l-3.2,6.3c-0.4,0.9-0.7,1.4-0.9,1.6c-0.5,0.6-1.2,1.1-2.2,1.2l0,0c-0.2,0-0.4,0-0.6,0l-1.8,3.6h0.5h0.1
              h12.8l1.9-3.7c-0.5-0.1-3.5,0.2-3.5-0.6c0-0.3,0.1-0.9,0.6-1.8l1.6-3.2c0.6-1.3,1.4-2.2,2.2-2.7c1-0.6,2.2-1.3,3.5-1.3
              c0.5,0,1.1,0.1,1.5,0.2c1.2-1.8,2.9-3.4,4.9-4.5C1646.4,1072.8,1645.1,1072.6,1643.5,1072.7L1643.5,1072.7z"/>
            <path className="st7" d="M1686.1,1090.6h-8.1c0-0.2,0.1-0.5,0.2-1.1c0-0.2,0.1-0.5,0.2-0.9l1.6-3.5l2.9-5.7c0.7-1.4,0.9-2.2,0.6-2.6
              c-0.2-0.5-0.9-0.8-2.1-0.8c-1.1,0-2.2,0.3-3.4,0.9l-3.8,7.5c-0.5,0.9-0.7,1.5-0.6,1.8s0.4,0.5,0.9,0.6l-1.9,3.7h-10.2l1.9-3.7
              c0.7-0.2,1.3-0.4,1.7-0.8c0.3-0.3,0.7-1,1.2-2l3.2-6.3c0.3-0.6,0.4-0.9,0.4-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
              c-0.2-0.1-0.4-0.2-0.6-0.3l1.8-3.6h8.7l-0.6,1.2c0.7-0.2,1.8-0.6,3.4-1c1.2-0.2,2.2-0.3,3-0.3c1.4,0,2.6,0.1,3.4,0.3
              c0.7,0.2,1.3,0.6,1.7,1.1c0.7,1,0.5,2.7-0.6,4.9l-2.9,5.7c-0.1,0.1-0.2,0.5-0.5,1.1c-0.2,0.4-0.2,0.7-0.2,0.9c0,0.2,0.4,0.4,1,0.7
              L1686.1,1090.6L1686.1,1090.6z"/>
            <path className="st7" d="M1705.8,1085.2l1.6-3.1c-2.4,0.6-3.9,1.1-4.7,1.3c-1.7,0.6-2.7,1.3-3.1,2.1c-0.5,1-0.1,1.5,1.2,1.5
              c0.7,0,1.4-0.2,2.3-0.5c0.2-0.1,0.7-0.2,1.3-0.5C1704.7,1085.8,1705.2,1085.6,1705.8,1085.2L1705.8,1085.2z M1711.9,1090.6h-8.5
              l1-1.9c-1.6,0.7-3.1,1.3-4.4,1.7c-1.5,0.4-2.9,0.6-4.2,0.6c-2,0-3.3-0.5-4-1.4c-0.7-1-0.7-2.2,0-3.7c0.8-1.6,2.3-2.9,4.5-3.9
              c2.1-0.9,6.4-2.1,13-3.4l0.5-1c0.7-1.4,0.2-2-1.6-2c-0.7,0-1.5,0.2-2.2,0.5c-0.7,0.5-1.1,1-1.4,1.6l-0.3,0.6h-7.1l2.1-4.2
              c4.2-1.3,7.9-1.9,11.1-1.9c3.3,0,5.4,0.5,6.4,1.4s0.9,2.5-0.2,4.7l-3.1,6.1c-0.4,0.8-0.7,1.3-0.7,1.5c-0.1,0.6,0.2,1,1,1.2
              L1711.9,1090.6L1711.9,1090.6z"/>
            <path className="st7" d="M1651.4,1079.8c0,0,1.8-3.5,4.9-3.5c3.9,0,1.8,3.5,1.8,3.5H1651.4L1651.4,1079.8z M1663.7,1074.7
              c-1.4-1.9-4.6-2.3-7.5-2.3c-5.9,0-11.7,4.4-13.1,9.4c-0.7,2.5-0.2,4.8,1.4,6.5s4.1,2.6,7,2.6c8.6,0,10.3-6.1,10.3-6.1h-5.1
              l-0.3,0.2c-1.2,1.1-2.4,1.8-4.1,1.7c-3.9-0.2-2-4.2-2-4.2h13.3c0,0,0.7-1.8,0.7-1.9C1664.8,1079.2,1665.5,1077.2,1663.7,1074.7
              L1663.7,1074.7z"/>
          </g>
        </g>
        <g id="stairs">
          <polyline className="st21" points="896.1,285.7 913.2,285.7 913.2,303.5 931.6,303.5 931.6,320.6 951.3,320.6 	"/>
          <polyline className="st21" points="358.2,731.6 375.3,731.6 375.3,749.3 393.7,749.3 393.7,766.5 413.3,766.5 	"/>
          <polyline className="st21" points="346.6,1377.7 363.7,1377.7 363.7,1395.5 382.1,1395.5 382.1,1412.6 401.8,1412.6 	"/>
          <polyline className="st21" points="891.4,1824 908.6,1824 908.6,1841.8 926.9,1841.8 926.9,1858.9 946.6,1858.9 	"/>
          <polyline className="st21" points="2114.7,1829.9 2131.8,1829.9 2131.8,1847.7 2150.2,1847.7 2150.2,1864.8 2169.8,1864.8 	"/>
          <polyline className="st21" points="2105.8,285.4 2122.9,285.4 2122.9,303.2 2141.3,303.2 2141.3,320.3 2161,320.3 	"/>
          <polyline className="st21" points="2607.1,702.1 2624.2,702.1 2624.2,719.8 2642.6,719.8 2642.6,737 2662.3,737 	"/>
          <polyline className="st21" points="2606.2,1409.1 2623.3,1409.1 2623.3,1426.9 2641.7,1426.9 2641.7,1444 2661.4,1444 	"/>
        </g>
        <g id="guest_services">
          <g>
            <circle className="guest-services st22" cx="2358.9" cy="1305" r="24.8"/>
          </g>
          <g>
            <circle className="st6" cx="2367.1" cy="1308.8" r="1"/>
            <path className="st6" d="M2367.1,1310.9h-1c-0.6,0-1,0.5-1,1s0.5,1,1,1l0,0v4.1c0,0.6,0.5,1,1,1s1-0.5,1-1v-5.1
              C2368.2,1311.4,2367.7,1310.9,2367.1,1310.9z"/>
            <path className="st6" d="M2372.3,1306.5v-12.1c0-0.6-0.5-1-1-1h-27.8c-0.6,0-1,0.5-1,1v18.5c0,0.6,0.5,1,1,1h4.1v6.2
              c0,0.4,0.3,0.8,0.6,0.9c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.3l6.9-6.9h2.7c0.5,4.1,4,7.2,8.2,7.2c4.5,0,8.2-3.7,8.2-8.2
              C2375.4,1310.4,2374.2,1308.1,2372.3,1306.5z M2355.8,1311.9c-0.3,0-0.5,0.1-0.7,0.3l-5.4,5.4v-4.7c0-0.6-0.5-1-1-1h-4.1v-16.4
              h25.7v9.9c-1-0.4-2-0.6-3.1-0.6c-4.2,0-7.6,3.1-8.2,7.2h-3.2V1311.9z M2367.1,1319.1c-3.4,0-6.2-2.8-6.2-6.2
              c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2C2373.3,1316.4,2370.5,1319.1,2367.1,1319.1z"/>
          </g>
        </g>
        <g id="first_aid">
          <circle className="first-aid st22" cx="2531" cy="1380.4" r="24.8"/>
          <g>
            <path className="st6" d="M2534.6,1382.1L2534.6,1382.1h-2.7v-2.6l0,0c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9l0,0v2.7h-2.7
              c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9l0,0h2.6v2.7c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9l0,0v-2.6h2.6l0,0
              c0.5,0,0.9-0.4,0.9-0.9C2535.5,1382.5,2535.1,1382.1,2534.6,1382.1z"/>
            <path className="st6" d="M2542.5,1372.4h-23c-1.5,0-2.7,1.2-2.7,2.7v15.9c0,1.5,1.2,2.7,2.7,2.7h13.3h0.4h9.3c1.5,0,2.7-1.2,2.7-2.7
              v-15.9C2545.2,1373.6,2544,1372.4,2542.5,1372.4z M2543.4,1391c0,0.5-0.4,0.9-0.9,0.9h-9.3h-0.4h-13.3c-0.5,0-0.9-0.4-0.9-0.9
              v-15.9c0-0.5,0.4-0.9,0.9-0.9h23c0.5,0,0.9,0.4,0.9,0.9V1391z"/>
            <path className="st6" d="M2531,1368.9c2,0,3.5,1.6,3.5,3.5h1.8c0-2.9-2.4-5.3-5.3-5.3c-2.9,0-5.3,2.4-5.3,5.3h1.8
              C2527.5,1370.5,2529.1,1368.9,2531,1368.9z"/>
          </g>
        </g>
        <g id="elevator_5">
          <g>
            <circle className="elevators st22" cx="479.6" cy="694.9" r="24.8"/>
          </g>
          <g>
            <path className="st6" d="M477.9,681.3h-8.5c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5V681.3L477.9,681.3z M472.8,693.2
              l2.6,3.4h-5.1L472.8,693.2z"/>
            <path className="st6" d="M489.8,681.3h-8.5v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7V683C491.5,682.1,490.8,681.3,489.8,681.3L489.8,681.3z
              M486.4,696.6l-2.6-3.4h5.1L486.4,696.6z"/>
          </g>
        </g>
        <g id="elevator_4">
          <g>
            <circle className="elevators st22" cx="663" cy="922.9" r="24.8"/>
          </g>
          <g>
            <path className="st6" d="M661.3,909.3h-8.5c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5V909.3L661.3,909.3z M656.2,921.2
              l2.6,3.4h-5.1L656.2,921.2z"/>
            <path className="st6" d="M673.2,909.3h-8.5v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7V911C674.9,910,674.2,909.3,673.2,909.3L673.2,909.3z
              M669.8,924.6l-2.6-3.4h5.1L669.8,924.6z"/>
          </g>
        </g>
        <g id="elevator_3">
          <g>
            <ellipse transform="matrix(0.47 -0.8827 0.8827 0.47 -1022.0703 1200.7635)" className="elevators st22" cx="488.9" cy="1451.5" rx="24.8" ry="24.8"/>
          </g>
          <g>
            <path className="st6" d="M487.4,1437.7h-8.5c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5V1437.7L487.4,1437.7z
              M482.2,1449.7l2.6,3.4h-5.1L482.2,1449.7z"/>
            <path className="st6" d="M499.3,1437.7h-8.5v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7v-23.9C501,1438.5,500.2,1437.7,499.3,1437.7L499.3,1437.7
              z M495.9,1453.1l-2.6-3.4h5.1L495.9,1453.1z"/>
          </g>
        </g>
        <g id="elevator_2">
          <g>
            <circle className="elevators st22" cx="2413.2" cy="1253.1" r="24.8"/>
          </g>
          <g>
            <path className="st6" d="M2411.5,1239.4h-8.5c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5V1239.4L2411.5,1239.4z
              M2406.4,1251.4l2.6,3.4h-5.1L2406.4,1251.4z"/>
            <path className="st6" d="M2423.5,1239.4h-8.5v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7v-23.9C2425.2,1240.2,2424.4,1239.4,2423.5,1239.4
              L2423.5,1239.4z M2420,1254.8l-2.6-3.4h5.1L2420,1254.8z"/>
          </g>
        </g>
        <g id="elevator_1">
          <g>
            <circle className="elevators st22" cx="2518.2" cy="618.6" r="24.8"/>
          </g>
          <g>
            <path className="st6" d="M2516.5,604.9h-8.5c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5V604.9L2516.5,604.9z
              M2511.4,616.9l2.6,3.4h-5.1L2511.4,616.9z"/>
            <path className="st6" d="M2528.4,604.9h-8.5v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7v-23.9C2530.1,605.7,2529.3,604.9,2528.4,604.9
              L2528.4,604.9z M2525,620.3l-2.6-3.4h5.1L2525,620.3z"/>
          </g>
        </g>
        <g id="rr_family_4">
          <g>
            <circle className="family-restrooms st22" cx="2052.9" cy="476.7" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M2067.9,471.2c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                  c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                  v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.1h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                  c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L2067.9,471.2z"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st6" d="M2058.4,476.4c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2
                  c0,0-1.9-5.2-2.6-7.1c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7
                  c-0.2,0.6,0.1,1.2,0.6,1.4c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7
                  c0.2,0,0.5,0,0.8,0v7.7c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3
                  c0.7,0,1.3-0.6,1.3-1.3v-7.7c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9
                  c0.2,0.4,0.5,0.7,1,0.7c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1
                  c0.1,0,0.1,0.1,0.1,0.1c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3
                  c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v5.6c0,0.4,0.3,0.8,0.8,0.8
                  l0,0c0.4,0,0.8-0.3,0.8-0.8l0,0C2058.5,480.9,2058.4,477.5,2058.4,476.4z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="2062.6" cy="465.6" r="2.2"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="2054.6" cy="472" r="2"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st6" d="M2043,463.3c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S2044.2,463.3,2043,463.3z"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_family_3">
          <g>
            <circle className="family-restrooms st22" cx="1003.2" cy="476.7" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M1018.2,471.2c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                  c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                  v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.1h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                  c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L1018.2,471.2z"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st6" d="M1008.7,476.4c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2
                  c0,0-1.9-5.2-2.6-7.1c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7
                  c-0.2,0.6,0.1,1.2,0.6,1.4c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7
                  c0.2,0,0.5,0,0.8,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3
                  s1.3-0.6,1.3-1.3v-7.7c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9
                  c0.2,0.4,0.5,0.7,1,0.7c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1
                  c0.1,0,0.1,0.1,0.1,0.1c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3
                  c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.6
                  c0,0.4,0.3,0.8,0.8,0.8l0,0c0.4,0,0.8-0.3,0.8-0.8l0,0C1008.7,480.9,1008.7,477.5,1008.7,476.4z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1012.8" cy="465.6" r="2.2"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1004.9" cy="472" r="2"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st6" d="M993.3,463.3c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S994.5,463.3,993.3,463.3z"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_family_2">
          <g>
            <ellipse className="family-restrooms st22" cx="1005.5" cy="1659.2" rx="24.8" ry="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M1020.4,1653.7c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                  c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                  v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.1h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                  c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L1020.4,1653.7z"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st6" d="M1011,1658.9c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2c0,0-1.9-5.2-2.6-7.1
                  c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7c-0.2,0.6,0.1,1.2,0.6,1.4
                  c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.7
                  c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7
                  c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9c0.2,0.4,0.5,0.7,1,0.7
                  c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
                  c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3c0,0.5,0.4,0.9,0.9,0.9
                  s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.6c0,0.4,0.3,0.8,0.8,0.8l0,0
                  c0.4,0,0.8-0.3,0.8-0.8l0,0C1011,1663.3,1011,1659.9,1011,1658.9z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1015.1" cy="1648" r="2.2"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1007.1" cy="1654.4" r="2"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st6" d="M995.6,1645.8c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S996.8,1645.8,995.6,1645.8z"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_family_1">
          <g>
            <circle className="family-restrooms st22" cx="2061.2" cy="1659.2" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M2076.1,1653.7c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                  c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                  v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.1h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                  c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L2076.1,1653.7z"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st6" d="M2066.7,1658.9c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2
                  c0,0-1.9-5.2-2.6-7.1c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7
                  c-0.2,0.6,0.1,1.2,0.6,1.4c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7
                  c0.2,0,0.5,0,0.8,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3
                  s1.3-0.6,1.3-1.3v-7.7c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9
                  c0.2,0.4,0.5,0.7,1,0.7c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1
                  c0.1,0,0.1,0.1,0.1,0.1c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3
                  c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.6
                  c0,0.4,0.3,0.8,0.8,0.8l0,0c0.4,0,0.8-0.3,0.8-0.8l0,0C2066.7,1663.3,2066.7,1659.9,2066.7,1658.9z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="2070.8" cy="1648" r="2.2"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="2062.8" cy="1654.4" r="2"/>
              </g>
            </g>
            <g>
              <g>
                <path className="st6" d="M2051.2,1645.8c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S2052.5,1645.8,2051.2,1645.8z"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_men_5">
          <g>
            <circle className="mens-restrooms st22" cx="1941.6" cy="491.8" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M1944.6,483.2c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                  c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3
                  v-10.6h0.6v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2l0,0v8.1
                  c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C1947.2,484.4,1946,483.2,1944.6,483.2z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1941.6" cy="480.2" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_men_4">
          <g>
            <circle className="mens-restrooms st22" cx="1111.2" cy="491.8" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M1114.2,483.2c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                  c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3
                  v-10.6h0.6v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2l0,0v8.1
                  c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C1116.8,484.4,1115.7,483.2,1114.2,483.2z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1111.3" cy="480.2" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_men_3">
          <g>
            <circle className="mens-restrooms st22" cx="674.3" cy="1266" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M677.3,1257.4c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                  c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V1268
                  h0.6v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2l0,0v8.1
                  c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C679.9,1258.6,678.8,1257.4,677.3,1257.4z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="674.4" cy="1254.4" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_men_2">
          <g>
            <circle className="mens-restrooms st22" cx="1121.3" cy="1649.8" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M1124.2,1641.2c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                  c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3
                  v-10.6h0.6v10.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2l0,0
                  v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C1126.8,1642.3,1125.7,1641.2,1124.2,1641.2z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1121.3" cy="1638.1" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_men_1">
          <g>
            <circle className="mens-restrooms st22" cx="1959.1" cy="1649.8" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M1962.1,1641.2c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                  c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3
                  v-10.6h0.6v10.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2l0,0
                  v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C1964.7,1642.3,1963.5,1641.2,1962.1,1641.2z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="1959.1" cy="1638.1" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_women_5">
          <g>
            <circle className="womens-restrooms st22" cx="2264.8" cy="415.9" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M2271.6,416.3c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                  c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                  c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                  c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                  c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C2271.5,417.5,2271.8,416.9,2271.6,416.3z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="2264.8" cy="404.4" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_women_4">
          <g>
            <circle className="womens-restrooms st22" cx="802.6" cy="405.5" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M809.4,405.9c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                  c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                  c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V410c0.2,0,0.4,0,0.6,0v7.9
                  c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V410c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                  c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C809.3,407.1,809.6,406.5,809.4,405.9z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="802.6" cy="394" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_women_3">
          <g>
            <circle className="womens-restrooms st22" cx="638.1" cy="1189.8" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M644.9,1190.2c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                  c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                  c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                  c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                  c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C644.8,1191.4,645.1,1190.8,644.9,1190.2z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="638.2" cy="1178.3" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_women_2">
          <g>
            <circle className="womens-restrooms st22" cx="803" cy="1742.7" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M809.8,1743.1c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                  c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                  c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                  c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                  c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C809.7,1744.3,810,1743.7,809.8,1743.1z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="803" cy="1731.2" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="rr_women_1">
          <g>
            <circle className="womens-restrooms st22" cx="2264.2" cy="1743.3" r="24.8"/>
          </g>
          <g>
            <g>
              <g>
                <path className="st6" d="M2271,1743.7c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                  c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                  c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                  c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                  c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C2270.9,1744.9,2271.2,1744.3,2271,1743.7z"/>
              </g>
            </g>
            <g>
              <g>
                <circle className="st6" cx="2264.3" cy="1731.8" r="2.3"/>
              </g>
            </g>
          </g>
        </g>
        <g id="gendy_gourmet_sausages">
          
            <rect x="1588.9" y="422.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 158.6667 1266.7446)" className="st23" width="39.1" height="39.1"/>
          
            <rect x="1593.1" y="422.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 159.8943 1269.7147)" className="st24" width="39.1" height="39.1"/>
          <g>
            <path className="st25" d="M1671.4,444c-0.6,0.3-1.3,0.4-2.2,0.6c-0.9,0.3-1.8,0.4-2.8,0.5s-2.2,0.3-3.4,0.3c-2,0-4-0.4-5.7-1
              c-1.8-0.6-3.3-1.5-4.6-2.8c-1.3-1.1-2.3-2.7-3.1-4.3c-0.8-1.7-1.1-3.6-1.1-5.7c0-2,0.4-4,1.1-5.7c0.8-1.7,1.8-3.2,3.1-4.3
              c1.3-1.1,2.8-2.2,4.6-2.8c1.8-0.6,3.7-1,5.6-1c1.3,0,2.4,0.1,3.3,0.3c1,0.1,1.8,0.3,2.6,0.5c0.8,0.3,1.3,0.4,1.8,0.6
              s0.9,0.4,1.3,0.5l-1.7,3.8c-0.3-0.1-0.5-0.3-0.9-0.4s-0.9-0.4-1.5-0.5s-1.3-0.3-2-0.4c-0.8-0.1-1.7-0.1-2.6-0.1
              c-1.1,0-2.2,0.3-3.2,0.6c-1,0.4-1.9,1-2.8,1.9c-0.9,0.8-1.5,1.8-2,3.1c-0.5,1.1-0.8,2.6-0.8,4c0,1.5,0.3,2.8,0.8,4s1.1,2.2,2,3.1
              c0.9,0.8,1.8,1.4,2.9,1.9c1,0.4,2.2,0.6,3.3,0.6c0.5,0,1,0,1.4,0s0.8,0,1-0.1c0.3,0,0.6-0.1,0.8-0.1c0.3-0.1,0.5-0.1,0.8-0.3v-7.2
              h5.4v10.1C1672.5,443.7,1672.1,443.9,1671.4,444z"/>
            <path className="st25" d="M1699.7,444.9h-17.1v-26.7h16.6v4.2h-11v6.8h8v4.2h-8v7.5h11.5V444.9z"/>
            <path className="st25" d="M1732.8,418.2v26.7h-5.4l-13.8-17.8v17.8h-4.9v-26.7h5l14.2,18.1v-18.1H1732.8z"/>
            <path className="st25" d="M1743.6,418.2h8.7c1.9,0,3.8,0.3,5.7,0.9c1.8,0.5,3.4,1.4,4.9,2.6c1.4,1.1,2.6,2.6,3.3,4.2
              c0.9,1.7,1.3,3.6,1.3,5.7c0,2-0.4,3.8-1.3,5.5s-2,3.1-3.4,4.2s-3.1,2-4.9,2.7c-1.8,0.6-3.7,1-5.5,1h-8.7L1743.6,418.2
              L1743.6,418.2z M1752.3,440.7c1,0,2-0.3,3.2-0.6c1-0.5,2-1,2.9-1.9c0.9-0.8,1.7-1.8,2.2-2.9s0.9-2.4,0.9-3.8
              c0-1.7-0.3-3.1-0.8-4.1c-0.5-1.1-1.3-2-2.2-2.8c-0.9-0.8-1.9-1.3-2.9-1.7c-1.1-0.4-2.2-0.5-3.2-0.5h-3.1v18.4h3V440.7z"/>
            <path className="st25" d="M1795,418.2l-8.6,13.8v12.9h-5.6V432l-8.4-13.8h6.3l5.6,9.7l5.7-9.7H1795z"/>
            <path className="st25" d="M1654.3,459.4c-0.1,0.1-0.4,0.1-0.6,0.1s-0.5,0.1-0.9,0.1s-0.6,0.1-1,0.1c-0.6,0-1.1-0.1-1.8-0.3
              c-0.5-0.3-1-0.5-1.4-0.9s-0.8-0.8-0.9-1.3c-0.3-0.5-0.4-1.1-0.4-1.8c0-0.6,0.1-1.3,0.4-1.8s0.5-1,0.9-1.4s0.9-0.6,1.4-0.9
              s1.1-0.3,1.8-0.3c0.4,0,0.8,0,1,0s0.5,0.1,0.8,0.1s0.4,0.1,0.5,0.1c0.1,0.1,0.3,0.1,0.4,0.1l-0.5,1.1c-0.1,0-0.1,0-0.3-0.1
              c-0.1,0-0.3-0.1-0.5-0.1c-0.1,0-0.4-0.1-0.6-0.1s-0.5,0-0.8,0c-0.4,0-0.6,0.1-1,0.3s-0.6,0.4-0.9,0.6c-0.3,0.3-0.5,0.5-0.6,0.9
              s-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3c0.1,0.4,0.4,0.6,0.6,0.9c0.3,0.3,0.5,0.4,0.9,0.6c0.4,0.1,0.6,0.3,1,0.3c0.1,0,0.3,0,0.4,0
              s0.3,0,0.4,0s0.1,0,0.3,0c0.1,0,0.1,0,0.3-0.1V456h1.7v3.1C1654.7,459.2,1654.4,459.4,1654.3,459.4z"/>
            <path className="st25" d="M1664,451.3c0.6,0,1.3,0.1,1.8,0.3c0.5,0.3,1,0.5,1.4,0.9s0.6,0.8,0.9,1.4c0.3,0.5,0.3,1.1,0.3,1.7
              s-0.1,1.3-0.4,1.8s-0.5,1-0.9,1.3c-0.4,0.4-0.9,0.6-1.4,0.9s-1,0.3-1.7,0.3s-1.1-0.1-1.7-0.3c-0.5-0.3-1-0.5-1.4-0.9
              s-0.8-0.8-0.9-1.3c-0.3-0.5-0.4-1.1-0.4-1.8c0-0.6,0.1-1.1,0.4-1.7c0.3-0.5,0.5-1,0.9-1.4s0.9-0.6,1.4-0.9
              C1662.8,451.5,1663.4,451.3,1664,451.3z M1664,452.6c-0.4,0-0.8,0.1-1,0.3c-0.3,0.1-0.5,0.4-0.8,0.6s-0.4,0.6-0.5,0.9
              c-0.1,0.4-0.1,0.8-0.1,1.1c0,0.4,0,0.8,0.1,1.1c0.1,0.4,0.3,0.6,0.5,0.9c0.3,0.3,0.5,0.5,0.8,0.6s0.6,0.3,1,0.3s0.8-0.1,1-0.3
              c0.3-0.1,0.5-0.4,0.8-0.6c0.3-0.3,0.4-0.6,0.5-0.9c0.1-0.4,0.1-0.8,0.1-1.1c0-0.4,0-0.8-0.1-1.1c-0.1-0.4-0.3-0.6-0.5-0.9
              s-0.5-0.5-0.8-0.6C1664.7,452.7,1664.4,452.6,1664,452.6z"/>
            <path className="st25" d="M1680.5,456.8c0,0.4-0.1,0.8-0.3,1.1c-0.1,0.4-0.4,0.6-0.6,0.9c-0.3,0.3-0.6,0.5-1.1,0.6
              c-0.4,0.1-0.9,0.3-1.5,0.3c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.4-1.1-0.6c-0.3-0.3-0.5-0.5-0.6-0.9s-0.3-0.8-0.3-1.1v-5.4h1.8
              v5.1c0,0.6,0.1,1.1,0.4,1.4c0.3,0.4,0.8,0.5,1.4,0.5c0.4,0,0.6,0,0.8-0.1c0.3-0.1,0.4-0.3,0.5-0.4s0.3-0.4,0.4-0.6
              s0.1-0.5,0.1-0.6v-5.1h1.5L1680.5,456.8L1680.5,456.8z"/>
            <path className="st25" d="M1690.7,459.8l-2.2-3.7h-0.8v3.6h-1.8v-8.2h2.4c0.5,0,1,0,1.4,0.1s0.8,0.3,1,0.5c0.3,0.3,0.5,0.4,0.6,0.8
              c0.1,0.3,0.3,0.5,0.3,0.9c0,0.3,0,0.5-0.1,0.8s-0.3,0.4-0.4,0.6c-0.1,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.3,0.1-0.4,0.3l2.6,4
              L1690.7,459.8z M1688.4,455c0.5,0,0.9-0.1,1.1-0.4c0.3-0.3,0.4-0.5,0.4-0.8s-0.1-0.6-0.4-0.8c-0.3-0.3-0.6-0.4-1.1-0.4h-0.8v2.3
              L1688.4,455L1688.4,455z"/>
            <path className="st25" d="M1705.8,459.6h-1.7l-0.1-5l-2,5h-1.1l-2-5l-0.3,5h-1.4l0.4-8.2h1.7l2.3,6l2.6-6h1.5L1705.8,459.6z"/>
            <path className="st25" d="M1716.5,459.6h-5.2v-8.2h5.1v1.3h-3.4v2h2.4v1.3h-2.4v2.3h3.6L1716.5,459.6L1716.5,459.6z"/>
            <path className="st25" d="M1721,451.5h7v1.3h-2.7v6.9h-1.8v-6.9h-2.7L1721,451.5L1721,451.5z"/>
            <path className="st25" d="M1740.4,458.2c0.1,0,0.3,0.1,0.4,0.1s0.4,0.1,0.5,0.1c0.3,0,0.5,0,0.8,0c0.1,0,0.4,0,0.5,0s0.3-0.1,0.4-0.1
              c0.1-0.1,0.3-0.1,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4s0-0.3-0.1-0.5c-0.1-0.1-0.3-0.3-0.4-0.4s-0.4-0.3-0.5-0.4
              c-0.3-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-0.4-0.3-0.6-0.5c-0.3-0.3-0.4-0.4-0.5-0.6s-0.1-0.6-0.1-0.9
              c0-0.4,0.1-0.6,0.3-0.9s0.4-0.5,0.5-0.6s0.5-0.4,0.8-0.5s0.6-0.1,1-0.1s0.6,0,0.9,0s0.5,0.1,0.6,0.1s0.4,0.1,0.5,0.1
              s0.3,0.1,0.4,0.1l-0.5,1.1c-0.1-0.1-0.4-0.1-0.6-0.3c-0.3-0.1-0.5-0.1-0.9-0.1c-0.1,0-0.3,0-0.4,0s-0.3,0.1-0.4,0.1
              s-0.3,0.1-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.4,0.1,0.5s0.3,0.3,0.4,0.4s0.3,0.3,0.5,0.4c0.3,0.1,0.4,0.3,0.6,0.4
              c0.1,0.1,0.4,0.3,0.6,0.4c0.3,0.1,0.5,0.4,0.6,0.5c0.3,0.3,0.4,0.4,0.5,0.6s0.3,0.5,0.3,0.8c0,0.4-0.1,0.8-0.3,1
              c-0.1,0.3-0.4,0.5-0.6,0.8s-0.6,0.4-1,0.5s-0.8,0.1-1.1,0.1s-0.6,0-0.9,0s-0.5-0.1-0.8-0.1s-0.4-0.1-0.5-0.1
              c-0.1-0.1-0.3-0.1-0.4-0.1L1740.4,458.2C1740.2,458.1,1740.3,458.2,1740.4,458.2z"/>
            <path className="st25" d="M1751.1,459.6h-1.5l3.3-8.2h1.1l3.1,8.2h-1.8l-0.6-1.8h-2.8L1751.1,459.6z M1753.3,454l-1,2.7h2L1753.3,454z
              "/>
            <path className="st25" d="M1768.5,456.8c0,0.4-0.1,0.8-0.3,1.1c-0.1,0.4-0.4,0.6-0.6,0.9s-0.6,0.5-1.1,0.6c-0.4,0.1-0.9,0.3-1.5,0.3
              c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.4-1.1-0.6c-0.3-0.3-0.5-0.5-0.6-0.9s-0.3-0.8-0.3-1.1v-5.4h1.8v5.1c0,0.6,0.1,1.1,0.4,1.4
              c0.3,0.4,0.8,0.5,1.4,0.5c0.4,0,0.6,0,0.8-0.1c0.3-0.1,0.4-0.3,0.5-0.4s0.3-0.4,0.4-0.6c0.1-0.3,0.1-0.5,0.1-0.6v-5.1h1.5
              L1768.5,456.8L1768.5,456.8z"/>
            <path className="st25" d="M1774.5,458.2c0.1,0,0.3,0.1,0.4,0.1s0.4,0.1,0.5,0.1c0.3,0,0.5,0,0.8,0c0.1,0,0.4,0,0.5,0s0.3-0.1,0.4-0.1
              c0.1-0.1,0.3-0.1,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4s0-0.3-0.1-0.5c-0.1-0.1-0.3-0.3-0.4-0.4s-0.4-0.3-0.5-0.4
              c-0.3-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-0.4-0.3-0.6-0.5c-0.3-0.3-0.4-0.4-0.5-0.6s-0.1-0.6-0.1-0.9
              c0-0.4,0.1-0.6,0.3-0.9c0.1-0.3,0.4-0.5,0.5-0.6c0.3-0.3,0.5-0.4,0.8-0.5s0.6-0.1,1-0.1s0.6,0,0.9,0s0.5,0.1,0.6,0.1
              s0.4,0.1,0.5,0.1s0.3,0.1,0.4,0.1l-0.5,1.1c-0.1-0.1-0.4-0.1-0.6-0.3c-0.3-0.1-0.5-0.1-0.9-0.1c-0.1,0-0.3,0-0.4,0
              s-0.3,0.1-0.4,0.1s-0.3,0.1-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.4,0.1,0.5s0.3,0.3,0.4,0.4s0.3,0.3,0.5,0.4
              c0.3,0.1,0.4,0.3,0.6,0.4c0.1,0.1,0.4,0.3,0.6,0.4c0.3,0.1,0.5,0.4,0.6,0.5c0.3,0.3,0.4,0.4,0.5,0.6c0.1,0.3,0.3,0.5,0.3,0.8
              c0,0.4-0.1,0.8-0.3,1c-0.1,0.3-0.4,0.5-0.6,0.8c-0.3,0.3-0.6,0.4-1,0.5s-0.8,0.1-1.1,0.1c-0.4,0-0.6,0-0.9,0s-0.5-0.1-0.8-0.1
              s-0.4-0.1-0.5-0.1c-0.1-0.1-0.3-0.1-0.4-0.1L1774.5,458.2C1774.3,458.1,1774.4,458.2,1774.5,458.2z"/>
            <path className="st25" d="M1785.1,459.6h-1.5l3.3-8.2h1.1l3.1,8.2h-1.8l-0.6-1.8h-2.8L1785.1,459.6z M1787.3,454l-1,2.7h2L1787.3,454z
              "/>
            <path className="st25" d="M1802.5,459.4c-0.1,0.1-0.4,0.1-0.6,0.1c-0.3,0-0.5,0.1-0.9,0.1s-0.6,0.1-1,0.1c-0.6,0-1.1-0.1-1.8-0.3
              c-0.5-0.3-1-0.5-1.4-0.9s-0.8-0.8-0.9-1.3s-0.4-1.1-0.4-1.8c0-0.6,0.1-1.3,0.4-1.8s0.5-1,0.9-1.4s0.9-0.6,1.4-0.9s1.1-0.3,1.8-0.3
              c0.4,0,0.8,0,1,0c0.3,0,0.5,0.1,0.8,0.1s0.4,0.1,0.5,0.1c0.1,0.1,0.3,0.1,0.4,0.1l-0.5,1.1c-0.1,0-0.1,0-0.3-0.1
              c-0.1,0-0.3-0.1-0.5-0.1c-0.1,0-0.4-0.1-0.6-0.1c-0.3,0-0.5,0-0.8,0c-0.4,0-0.6,0.1-1,0.3s-0.6,0.4-0.9,0.6
              c-0.3,0.3-0.5,0.5-0.6,0.9s-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3c0.1,0.4,0.4,0.6,0.6,0.9c0.3,0.3,0.5,0.4,0.9,0.6
              c0.4,0.1,0.6,0.3,1,0.3c0.1,0,0.3,0,0.4,0s0.3,0,0.4,0s0.1,0,0.3,0s0.1,0,0.3-0.1V456h1.7v3.1
              C1802.8,459.2,1802.6,459.4,1802.5,459.4z"/>
            <path className="st25" d="M1813.4,459.6h-5.2v-8.2h5.1v1.3h-3.4v2h2.4v1.3h-2.4v2.3h3.6L1813.4,459.6L1813.4,459.6z"/>
            <path className="st25" d="M1818.8,458.2c0.1,0,0.3,0.1,0.4,0.1s0.4,0.1,0.5,0.1c0.3,0,0.5,0,0.8,0c0.1,0,0.4,0,0.5,0s0.3-0.1,0.4-0.1
              c0.1-0.1,0.3-0.1,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4s0-0.3-0.1-0.5c-0.1-0.1-0.3-0.3-0.4-0.4s-0.4-0.3-0.5-0.4
              c-0.3-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-0.4-0.3-0.6-0.5c-0.3-0.3-0.4-0.4-0.5-0.6s-0.1-0.6-0.1-0.9
              c0-0.4,0.1-0.6,0.3-0.9c0.1-0.3,0.4-0.5,0.5-0.6s0.5-0.4,0.8-0.5s0.6-0.1,1-0.1s0.6,0,0.9,0s0.5,0.1,0.6,0.1s0.4,0.1,0.5,0.1
              s0.3,0.1,0.4,0.1l-0.5,1.1c-0.1-0.1-0.4-0.1-0.6-0.3s-0.5-0.1-0.9-0.1c-0.1,0-0.3,0-0.4,0s-0.3,0.1-0.4,0.1s-0.3,0.1-0.3,0.3
              c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.4,0.1,0.5s0.3,0.3,0.4,0.4s0.3,0.3,0.5,0.4c0.3,0.1,0.4,0.3,0.6,0.4c0.1,0.1,0.4,0.3,0.6,0.4
              c0.3,0.1,0.5,0.4,0.6,0.5c0.3,0.3,0.4,0.4,0.5,0.6c0.1,0.3,0.3,0.5,0.3,0.8c0,0.4-0.1,0.8-0.3,1c-0.1,0.3-0.4,0.5-0.6,0.8
              c-0.3,0.3-0.6,0.4-1,0.5s-0.8,0.1-1.1,0.1c-0.4,0-0.6,0-0.9,0s-0.5-0.1-0.8-0.1s-0.4-0.1-0.5-0.1c-0.1-0.1-0.3-0.1-0.4-0.1
              L1818.8,458.2C1818.7,458.1,1818.8,458.2,1818.8,458.2z"/>
          </g>
          <g id="pmAjkW_2_">
            <g>
              <path d="M1594.5,444.9c0.4-1.7,1.5-2.6,3.1-3.1c0.5-0.1,0.6-0.5,0.8-1c0.3-2,1.5-3.3,3.3-4.1c3.2-1.4,6.4-2.8,9.5-4.2
                c1-0.4,2-0.9,3.1-1.1c1.3-0.3,2.6,0,3.7,0.9c0.4,0.3,0.6,0.3,1,0.1c0.8-0.3,1.5-0.6,2.4-0.8c1.8-0.1,3.2,1,3.6,2.7
                c0,0.1,0.1,0.3,0.1,0.3c0,0.4,0,0.8,0,1.1c-0.6,1.8-0.8,1.9-2,2.8c0.3,0.6,0.5,1.4,0.6,2c0.3,2.4-1,4.2-3.2,5.4
                c-2.4,1.3-5,2.3-7.5,3.4c-1.9,0.9-4,1.8-5.9,2.6c-1.3,0.5-2.4,0.6-3.8,0.4c-1.8-0.4-2.9-1.4-3.4-3.2c-0.6,0.1-1.3,0.3-1.9,0.1
                c-1.7-0.1-2.8-1.1-3.2-2.7c0-0.1-0.1-0.3-0.1-0.3C1594.5,445.6,1594.5,445.2,1594.5,444.9z M1604.6,450.8
                c0.6-0.1,1.3-0.3,1.9-0.5c4.3-1.9,8.7-3.8,13-5.9c1-0.5,1.8-1.3,2.3-2.3c0.8-1.7,0-3.4-1.8-4.1c-1.1-0.4-2.4-0.3-3.4,0.3
                c-4.2,1.9-8.4,3.7-12.6,5.6c-0.9,0.4-1.8,1-2.3,1.9c-0.3,0.6-0.5,1.4-0.5,2.2C1601.3,449.7,1602.7,450.7,1604.6,450.8z
                M1620.2,434.5c0.6-0.1,1.1-0.3,1.8-0.5c0.3-0.1,0.3-0.5,0.5-0.6c-0.3-0.1-0.5-0.3-0.8-0.3c-0.6,0-1.1,0.3-1.7,0.3
                c-1.4,0-2.6,0.5-3.4,1.5c-0.9,1-1.9,1.4-3.2,1.4c-1.4,0-2.7,0.5-3.6,1.5c-0.8,1-1.8,1.4-3.2,1.4c-1.5,0-2.7,0.5-3.7,1.7
                c-0.8,1-1.8,1.3-3.1,1.3c-0.6,0-1.1,0.1-1.8,0.3c-0.4,0.1-0.6,0.4-0.4,0.8c0.1,0.1,0.5,0.3,0.8,0.3c0.5,0,1.1-0.3,1.7-0.3
                c1.5,0,2.7-0.5,3.6-1.7c0.8-0.9,1.8-1.4,2.9-1.3c1.5,0,2.7-0.5,3.7-1.7c0.8-1,1.8-1.4,3.1-1.4c1.4,0,2.7-0.5,3.6-1.5
                C1617.8,434.8,1618.8,434.3,1620.2,434.5z M1616.8,433.2v-0.1c-0.5-0.1-0.9-0.4-1.4-0.4c-0.6,0-1.4,0.1-1.9,0.4
                c-3.8,1.7-7.5,3.3-11.4,5c-1.1,0.5-1.9,1.3-2.3,2.7C1605.5,438.2,1611.1,435.7,1616.8,433.2z"/>
            </g>
          </g>
        </g>
        <g id="trinity_treats">
          
            <rect x="508" y="1320.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -794.6267 769.4827)" className="st26" width="47.1" height="47.1"/>
          
            <rect x="513.1" y="1320.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -793.1306 773.1027)" className="st27" width="47.1" height="47.1"/>
          <g>
            <path className="st25" d="M578.2,1322.8H597v3.4h-7.1v18.7h-4.7v-18.7h-7.1L578.2,1322.8L578.2,1322.8z"/>
            <path className="st25" d="M617.2,1345.3l-6-9.8h-2.1v9.5h-4.7v-22.3h6.4c1.5,0,2.9,0.1,4,0.4s2,0.7,2.8,1.3c0.7,0.5,1.3,1.2,1.6,1.9
              c0.3,0.7,0.5,1.5,0.5,2.5c0,0.7-0.1,1.5-0.4,2.1c-0.3,0.6-0.6,1.2-1.1,1.6s-0.9,0.9-1.3,1.1c-0.4,0.3-0.9,0.5-1.1,0.6l6.7,10.7
              L617.2,1345.3z M611,1332.2c1.4,0,2.4-0.3,3-1c0.6-0.6,1-1.4,1-2.2c0-0.9-0.3-1.6-1-2.2c-0.6-0.6-1.7-1-3.1-1H609v6.3L611,1332.2
              L611,1332.2z"/>
            <path className="st25" d="M629.4,1345v-22.3h4.7v22.3H629.4z"/>
            <path className="st25" d="M663.1,1322.8v22.3h-4.5l-11.6-14.8v14.8h-4.1v-22.3h4.2l11.8,15.1v-15.1H663.1z"/>
            <path className="st25" d="M672,1345v-22.3h4.7v22.3H672z"/>
            <path className="st25" d="M684,1322.8h18.8v3.4h-7.1v18.7H691v-18.7h-7V1322.8z"/>
            <path className="st25" d="M727.2,1322.8l-7.2,11.5v10.8h-4.7v-10.8l-7.1-11.5h5.1l4.7,8.1l4.8-8.1H727.2z"/>
            <path className="st25" d="M577.9,1353.9h9.4v1.7h-3.5v9.4h-2.4v-9.4h-3.5V1353.9z"/>
            <path className="st25" d="M600.4,1365.1l-3-4.9h-1.1v4.8H594v-11.1h3.2c0.7,0,1.4,0.1,2,0.2c0.5,0.1,1,0.3,1.4,0.6
              c0.3,0.3,0.6,0.6,0.9,1c0.2,0.3,0.2,0.7,0.2,1.2s-0.1,0.7-0.2,1.1c-0.1,0.3-0.3,0.5-0.5,0.9c-0.2,0.2-0.4,0.4-0.6,0.5
              c-0.2,0.1-0.4,0.2-0.5,0.3l3.4,5.4H600.4z M597.4,1358.6c0.6,0,1.2-0.1,1.5-0.4s0.5-0.6,0.5-1.1s-0.2-0.7-0.5-1.1
              c-0.3-0.3-0.9-0.4-1.5-0.4h-1v3.2h1V1358.6z"/>
            <path className="st25" d="M616.7,1365h-7.2v-11.1h7v1.7h-4.6v2.8h3.3v1.7h-3.3v3.1h4.8V1365z"/>
            <path className="st25" d="M624.7,1365h-2.1l4.5-11.1h1.6l4.1,11.1h-2.5l-0.9-2.5h-3.9L624.7,1365z M627.6,1357.3l-1.4,3.6h2.7
              L627.6,1357.3z"/>
            <path className="st25" d="M637.1,1353.9h9.4v1.7H643v9.4h-2.4v-9.4h-3.5V1353.9z"/>
            <path className="st25" d="M654,1363.1c0.1,0.1,0.3,0.1,0.5,0.2s0.4,0.1,0.7,0.2c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4,0,0.6-0.1
              c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.5s-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.5
              s-0.4-0.3-0.7-0.5s-0.5-0.3-0.9-0.5c-0.2-0.2-0.5-0.3-0.9-0.5c-0.3-0.2-0.5-0.4-0.9-0.6c-0.2-0.2-0.4-0.5-0.6-1
              c-0.2-0.3-0.2-0.7-0.2-1.3c0-0.4,0.1-0.9,0.3-1.2c0.2-0.3,0.4-0.6,0.7-1c0.3-0.2,0.6-0.4,1.1-0.6c0.4-0.1,0.9-0.2,1.3-0.2
              c0.4,0,0.9,0,1.2,0.1c0.3,0,0.6,0.1,0.9,0.2s0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.1,0.4,0.2l-0.6,1.6c-0.2-0.1-0.4-0.2-0.9-0.3
              c-0.3-0.1-0.7-0.2-1.2-0.2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.4
              c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.3,0.7,0.5s0.5,0.3,0.9,0.5
              c0.3,0.2,0.6,0.4,0.9,0.7c0.3,0.3,0.5,0.5,0.7,1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.5-0.1,1.1-0.3,1.5s-0.5,0.7-1,1.1s-0.9,0.5-1.4,0.6
              s-1.1,0.2-1.6,0.2c-0.4,0-0.9,0-1.3-0.1c-0.3-0.1-0.6-0.1-1-0.2c-0.3-0.1-0.5-0.2-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.2l0.6-1.6
              C653.6,1362.8,653.7,1363,654,1363.1z"/>
          </g>
          <g id="iuIWnm_2_">
            <g>
              <path className="st28" d="M533.5,1363.5c-0.2-0.2-0.4-0.4-0.5-0.6c-2.4-5.9-4.7-11.7-7.1-17.6c-0.1-0.2-0.2-0.4-0.4-0.5
                c-2.5-1.2-3.3-4.3-1.9-6.6c0.1-0.2,0.1-0.4,0.1-0.6c-0.2-1.7,0.2-3.2,1.6-4.4c0.1-0.1,0.2-0.2,0.2-0.3c0.4-2.2,1.8-3.7,3.7-4.7
                c3.3-1.6,6.6-1.4,9.7,0.9c1.2,0.9,1.9,1.9,2.4,3.3c0,0.1,0.1,0.3,0.3,0.3c1.7,1,2.9,3.1,2.4,5.2c0,0.1,0,0.3,0.1,0.5
                c1.3,2.4,0.4,5.2-2,6.4c-0.2,0.1-0.4,0.2-0.5,0.5c-2.4,5.9-4.7,11.8-7.1,17.7c-0.1,0.2-0.3,0.4-0.5,0.6
                C533.7,1363.5,533.6,1363.5,533.5,1363.5z M533.4,1328.5c-1.4,0-2.6,0.2-3.6,0.9c-1.7,0.9-2.9,2-3.2,4.1c0,0.2-0.2,0.5-0.4,0.7
                c-1.2,0.9-1.6,2.1-1.2,3.5c0.1,0.4,0,0.7-0.2,1.1c-0.5,0.6-0.7,1.5-0.6,2.4c0.2,1.9,2.1,3.3,4.1,2.8c0.5-0.1,1-0.4,1.4-0.6
                c0.5-0.3,0.9-0.3,1.2,0.2l0.1,0.1c1.3,2.1,4.3,2.1,5.7,0c0.6-1,0.7-1,1.7-0.3c1.3,1,3.2,0.7,4.3-0.4c0.9-1.1,1-2.6,0.2-3.6
                c-0.3-0.4-0.3-0.9-0.2-1.4c0.5-1.5-0.2-3.3-1.7-4c-0.4-0.2-0.6-0.4-0.7-0.9c-0.1-0.9-0.5-1.6-1.1-2.1
                C537.4,1329.1,535.4,1328.6,533.4,1328.5z M536,1350.8c-0.9-0.9-1.6-1.7-2.5-2.6c-0.9,0.9-1.6,1.7-2.5,2.6
                c0.9,0.9,1.6,1.8,2.5,2.7C534.3,1352.6,535.1,1351.7,536,1350.8z M534.5,1347.2c0.9,0.9,1.6,1.7,2.5,2.6c0.7-0.9,1.5-1.7,2.4-2.6
                c-0.7-0.7-1.5-1.5-2.1-2.2c-0.4,0.3-0.7,0.6-1.1,0.9C535.3,1346.1,534.9,1346.7,534.5,1347.2z M532.6,1347.1
                c-1-0.7-1.8-1.4-2.7-2.1c-0.6,0.6-1.2,1.3-1.8,1.9c-0.1,0.1,0,0.2,0,0.3c0.4,1.1,1.4,1.6,2,2.6
                C530.8,1348.9,531.7,1348.1,532.6,1347.1z M533.5,1355.5c-0.4,0.5-1,1-1.3,1.5c-0.1,0.1,0,0.2,0,0.3c0.4,1.2,0.9,2.2,1.4,3.4
                c0,0.1,0.1,0.1,0.1,0.3c0.5-1.2,1-2.4,1.4-3.4c0-0.1,0-0.2,0-0.3C534.5,1356.6,534.1,1356.1,533.5,1355.5z M536.8,1351.8
                c-0.9,0.9-1.6,1.7-2.5,2.7c0.4,0.4,0.9,1,1.3,1.4c0.1-0.1,0.1-0.2,0.1-0.3c0.4-1,0.7-2,1.2-3
                C537.3,1352.1,537.3,1351.9,536.8,1351.8z M532.6,1354.5c-0.9-1-1.6-1.8-2.5-2.7l-0.1,0.1c0.4,1.3,1,2.5,1.5,3.9
                C531.8,1355.2,532.1,1354.9,532.6,1354.5z M539.9,1345.3c-0.2,0-0.4,0-0.6-0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2
                C539.9,1345.6,539.9,1345.5,539.9,1345.3C539.9,1345.4,539.9,1345.4,539.9,1345.3z"/>
              <path className="st29" d="M533.4,1328.5c2,0.1,3.9,0.6,5.4,2.2c0.5,0.6,1,1.4,1.1,2.1c0.1,0.4,0.3,0.7,0.7,0.9c1.5,0.6,2.2,2.5,1.7,4
                c-0.2,0.5-0.1,0.9,0.2,1.4c0.7,1.2,0.6,2.7-0.2,3.6c-1.1,1.3-3,1.5-4.3,0.4c-1-0.7-1.1-0.7-1.7,0.3c-1.3,2.1-4.3,2.1-5.7,0
                l-0.1-0.1c-0.3-0.5-0.6-0.5-1.2-0.2c-0.4,0.3-0.9,0.5-1.4,0.6c-1.9,0.5-3.9-0.9-4.1-2.8c-0.1-0.9,0.1-1.6,0.6-2.4
                c0.2-0.3,0.3-0.6,0.2-1.1c-0.4-1.4,0.1-2.7,1.2-3.5c0.2-0.2,0.4-0.4,0.4-0.7c0.3-1.9,1.5-3.2,3.2-4.1
                C531,1328.7,532.1,1328.5,533.4,1328.5z M532.9,1329.7c0,0-0.1,0-0.2,0c-2.2,0.6-3.9,1.8-4.5,4.2c-0.1,0.5,0.1,0.9,0.4,1
                c0.4,0.1,0.7-0.1,0.9-0.6c0.3-1.5,1.3-2.4,2.7-2.9c0.3-0.1,0.6-0.2,1.1-0.3c0.3-0.1,0.4-0.4,0.4-0.7
                C533.5,1329.9,533.3,1329.7,532.9,1329.7z M540.9,1338.3c0-1.3-0.9-2.5-2-3c-0.4-0.1-0.7,0-1,0.3c-0.1,0.3,0,0.7,0.3,0.9
                c0.9,0.4,1.3,1.1,1.3,2c0,0.4,0.5,0.7,0.9,0.5C540.7,1339.1,540.9,1338.8,540.9,1338.3z"/>
              <path className="st29" d="M536,1350.8c-0.9,0.9-1.6,1.8-2.5,2.7c-0.9-0.9-1.6-1.8-2.5-2.7c0.9-0.9,1.6-1.7,2.5-2.6
                C534.3,1349,535.1,1349.9,536,1350.8z"/>
              <path className="st29" d="M534.5,1347.2c0.5-0.5,1-1.1,1.5-1.4c0.4-0.2,0.7-0.5,1.1-0.9c0.6,0.7,1.4,1.5,2.1,2.2
                c-0.7,0.9-1.5,1.7-2.4,2.6C536.1,1348.9,535.2,1348.1,534.5,1347.2z"/>
              <path className="st29" d="M532.6,1347.1c-0.9,1-1.7,1.8-2.6,2.7c-0.6-0.9-1.6-1.5-2-2.6c0-0.1,0-0.3,0-0.3c0.6-0.6,1.2-1.3,1.8-1.9
                C530.7,1345.7,531.7,1346.4,532.6,1347.1z"/>
              <path className="st29" d="M533.5,1355.5c0.5,0.6,1.1,1.2,1.5,1.7c0.1,0.1,0.1,0.2,0,0.3c-0.4,1.2-1,2.2-1.4,3.4
                c-0.1-0.1-0.1-0.2-0.1-0.3c-0.4-1.2-0.9-2.2-1.4-3.4c0-0.1-0.1-0.3,0-0.3C532.6,1356.5,533,1356,533.5,1355.5z"/>
              <path className="st29" d="M536.8,1351.8c0.3,0.2,0.3,0.4,0.2,0.6c-0.4,1-0.9,2-1.2,3c0,0.1-0.1,0.2-0.1,0.3c-0.4-0.5-0.9-1-1.3-1.4
                C535.2,1353.5,536.1,1352.7,536.8,1351.8z"/>
              <path className="st29" d="M532.6,1354.5c-0.3,0.4-0.7,0.7-1.1,1.2c-0.5-1.3-1.1-2.6-1.5-3.9l0.1-0.1
                C530.8,1352.7,531.7,1353.5,532.6,1354.5z"/>
              <path className="st29" d="M539.9,1345.3C539.9,1345.4,539.9,1345.4,539.9,1345.3c0,0.2-0.1,0.3-0.1,0.4c-0.1-0.1-0.2-0.1-0.2-0.2
                c-0.1-0.1-0.1-0.1-0.3-0.3C539.5,1345.3,539.7,1345.3,539.9,1345.3z"/>
              <path className="st28" d="M532.9,1329.7c0.4,0,0.6,0.2,0.7,0.5c0.1,0.3-0.1,0.6-0.4,0.7c-0.3,0.1-0.7,0.2-1.1,0.3
                c-1.3,0.5-2.2,1.4-2.7,2.9c-0.1,0.5-0.5,0.7-0.9,0.6s-0.5-0.4-0.4-1c0.5-2.4,2.2-3.5,4.5-4.2
                C532.8,1329.7,532.9,1329.7,532.9,1329.7z"/>
              <path className="st28" d="M540.9,1338.3c0,0.4-0.1,0.7-0.4,0.9c-0.4,0.2-0.9-0.1-0.9-0.5c0-1-0.4-1.6-1.3-2c-0.4-0.2-0.5-0.5-0.3-0.9
                s0.5-0.5,1-0.3C539.9,1335.8,540.8,1337,540.9,1338.3z"/>
            </g>
          </g>
        </g>
        <g id="Reliant_Club_1_">
          <polygon className="st1" points="742.6,812.2 864.2,850.5 862.1,1318.3 743.1,1361.7 	"/>
          <text transform="matrix(1 0 0 1 756.0849 1063.104)" className="st3 st4 st5">Reliant</text>
          <text transform="matrix(1 0 0 1 771.1849 1099.104)" className="st3 st4 st5">Club</text>
        </g>
        <g id="camp_bowie_q_1_">
          
            <rect x="1246.8" y="416.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 62.5042 1023.8801)" className="st6" width="40.8" height="40.8"/>
          
            <rect x="1241.9" y="413.8" transform="matrix(0.7216 -0.6923 0.6923 0.7216 49.8887 996.9546)" className="st30" width="45.3" height="45.3"/>
          
            <rect x="1246.9" y="413.8" transform="matrix(0.7216 -0.6923 0.6923 0.7216 51.2785 1000.4109)" className="st30" width="45.3" height="45.3"/>
          <g>
            <g>
              <path className="st25" d="M1322.6,424.3c-0.4,0.2-0.9,0.4-1.4,0.5c-0.6,0.2-1.4,0.2-2.4,0.2c-0.8,0-1.5-0.1-2.2-0.4s-1.3-0.7-1.8-1.1
                c-0.5-0.5-0.9-1.1-1.2-1.7c-0.3-0.7-0.4-1.4-0.4-2.3c0-0.8,0.1-1.6,0.4-2.2c0.3-0.7,0.7-1.3,1.2-1.8s1.1-0.9,1.8-1.1
                c0.7-0.3,1.4-0.4,2.2-0.4c0.6,0,1,0,1.4,0.1s0.8,0.1,1.1,0.2c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.3,0.2,0.5,0.2l-0.6,1.5
                c-0.1,0-0.2-0.1-0.4-0.2c-0.2-0.1-0.3-0.1-0.6-0.2c-0.2-0.1-0.5-0.1-0.8-0.2c-0.3,0-0.7-0.1-1.1-0.1s-0.8,0.1-1.3,0.3
                c-0.4,0.2-0.8,0.4-1.1,0.8c-0.3,0.3-0.6,0.7-0.8,1.2c-0.2,0.5-0.3,1-0.3,1.6s0.1,1.1,0.3,1.5c0.2,0.5,0.5,0.9,0.8,1.2
                c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.8,0.3,1.3,0.3c0.8,0,1.4-0.1,1.8-0.2s0.8-0.3,1.1-0.4L1322.6,424.3z"/>
              <path className="st25" d="M1330.4,424.9h-2l4.4-10.7h1.6l3.9,10.7h-2.3l-0.9-2.4h-3.7L1330.4,424.9z M1333.2,417.5l-1.3,3.5h2.6
                L1333.2,417.5z"/>
              <path className="st25" d="M1355.6,424.9h-2.2l-0.1-6.5l-2.7,6.5h-1.4l-2.6-6.5l-0.3,6.5h-1.8l0.4-10.7h2.1l3.1,7.8l3.3-7.8h2
                L1355.6,424.9z"/>
              <path className="st25" d="M1362.7,424.9v-10.7h3.4c0.6,0,1.2,0.1,1.7,0.2c0.5,0.2,0.9,0.4,1.2,0.7c0.3,0.3,0.6,0.6,0.8,1
                c0.2,0.4,0.3,0.8,0.3,1.2c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.6s-1,0.2-1.5,0.2h-1.1v4.4L1362.7,424.9
                L1362.7,424.9z M1365.7,419c0.2,0,0.5,0,0.7-0.1s0.4-0.2,0.6-0.3c0.2-0.1,0.3-0.3,0.4-0.5s0.2-0.5,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7
                s-0.3-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.1-0.7-0.1h-0.8v3.2H1365.7z"/>
              <path className="st25" d="M1314.6,433.1h7.1c1.2,0,2.3,0.1,3.2,0.4c0.9,0.3,1.7,0.7,2.3,1.1c0.6,0.5,1,1,1.3,1.6s0.4,1.2,0.4,1.9
                c0,0.7-0.1,1.3-0.3,1.8c-0.2,0.5-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4,0.2-0.8,0.4-1.1,0.6c0.6,0,1.2,0.2,1.9,0.5
                c0.6,0.3,1.2,0.7,1.7,1.1c0.5,0.5,0.9,1,1.2,1.7c0.3,0.7,0.5,1.4,0.5,2.2c0,0.8-0.2,1.6-0.6,2.4s-0.9,1.5-1.6,2.1
                s-1.5,1.1-2.4,1.4c-0.9,0.4-1.9,0.5-3,0.5h-8.5v-21.3L1314.6,433.1L1314.6,433.1z M1320.8,442.1c0.5,0,1-0.1,1.4-0.2
                s0.8-0.3,1.1-0.5c0.3-0.2,0.6-0.6,0.8-0.9c0.2-0.4,0.3-0.9,0.3-1.4s-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.8-0.9
                c-0.3-0.2-0.7-0.4-1.1-0.5s-0.9-0.2-1.4-0.2h-1.7v6.1h1.7V442.1z M1324.9,450.7c0.7-0.5,1.1-1.3,1.1-2.4s-0.4-1.9-1.1-2.4
                s-1.7-0.8-2.9-0.8h-2.9v6.4h2.9C1323.2,451.5,1324.2,451.2,1324.9,450.7z"/>
              <path className="st25" d="M1348.5,432.7c1.7,0,3.2,0.3,4.6,0.8s2.6,1.3,3.6,2.3s1.8,2.2,2.3,3.5c0.5,1.4,0.8,2.9,0.8,4.5
                c0,1.7-0.3,3.2-0.9,4.6s-1.4,2.5-2.5,3.5c-1,1-2.2,1.7-3.6,2.2s-2.8,0.8-4.3,0.8c-1.6,0-3-0.3-4.4-0.8s-2.6-1.2-3.6-2.2
                s-1.8-2.1-2.4-3.5s-0.9-2.9-0.9-4.6c0-1.6,0.3-3,0.9-4.4s1.4-2.5,2.4-3.5s2.2-1.8,3.6-2.3C1345.5,433,1347,432.7,1348.5,432.7z
                M1348.5,436c-1,0-1.9,0.2-2.7,0.6c-0.8,0.4-1.5,0.9-2,1.6c-0.6,0.7-1,1.5-1.3,2.5c-0.3,0.9-0.4,2-0.4,3.1s0.1,2.1,0.4,3
                s0.7,1.8,1.3,2.5c0.6,0.7,1.2,1.3,2,1.7c0.8,0.4,1.7,0.6,2.7,0.6s1.9-0.2,2.7-0.6c0.8-0.4,1.5-1,2-1.7c0.6-0.7,1-1.5,1.3-2.5
                c0.3-0.9,0.4-1.9,0.4-3c0-1-0.1-2-0.4-3c-0.3-0.9-0.7-1.8-1.3-2.5c-0.6-0.7-1.2-1.3-2-1.7C1350.4,436.2,1349.5,436,1348.5,436z"
                />
              <path className="st25" d="M1374.6,454.6h-2.8l-7-21.6h4.5l4.6,14.6l3.1-8.5l-2-6.1h4.5l4.4,14.2l4.8-14.2h3.9l-7.9,21.6h-2.8
                l-3.5-10.7L1374.6,454.6z"/>
              <path className="st25" d="M1399.3,454.5v-21.4h4.5v21.4H1399.3z"/>
              <path className="st25" d="M1426.3,454.5h-13.7v-21.4h13.4v3.3h-8.8v5.4h6.5v3.3h-6.5v6h9.2L1426.3,454.5L1426.3,454.5z"/>
              <path className="st25" d="M1433.3,446.5v-3.8h9.5v3.8H1433.3z"/>
              <path className="st25" d="M1472.7,455.7l-1.4,3.5l-13.4-4.9c-2.4-1-4.2-2.3-5.5-4s-1.9-3.9-1.9-6.5c0-1.6,0.3-3,0.9-4.4
                s1.4-2.5,2.4-3.5s2.2-1.8,3.6-2.3c1.4-0.6,2.8-0.8,4.4-0.8c1.7,0,3.2,0.3,4.6,0.8s2.6,1.3,3.6,2.3s1.8,2.2,2.3,3.5
                c0.5,1.4,0.8,2.9,0.8,4.5c0,1.3-0.2,2.4-0.6,3.5s-0.9,2.1-1.5,3s-1.4,1.6-2.2,2.2c-0.8,0.6-1.7,1-2.6,1.3L1472.7,455.7z
                M1461.7,436c-1,0-1.9,0.2-2.7,0.6s-1.5,1-2,1.7c-0.6,0.7-1,1.5-1.3,2.5c-0.3,0.9-0.4,1.9-0.4,3s0.1,2.1,0.4,3s0.7,1.8,1.3,2.5
                c0.6,0.7,1.2,1.3,2.1,1.7c0.8,0.4,1.7,0.6,2.7,0.6s1.9-0.2,2.7-0.6c0.8-0.4,1.5-1,2-1.7c0.6-0.7,1-1.5,1.3-2.5
                c0.3-0.9,0.4-1.9,0.4-3c0-1-0.1-2-0.4-3c-0.3-0.9-0.7-1.8-1.3-2.5c-0.6-0.7-1.2-1.3-2-1.7C1463.7,436.2,1462.8,436,1461.7,436z"
                />
            </g>
          </g>
          <g id="CVsIrR_1_">
            <g>
              <path className="st31" d="M1268.6,438c-0.1,3.8-1.6,7.1-4.2,9.9c0.2-1.9,0.9-3.7,0.3-5.7c-1.2,1.8-2.2,3.7-2.4,5.8
                c-0.2,2.2,0.8,4.1,1.6,6c-0.5-0.3-1-0.7-1.5-1c-1.8-1.2-3.6-2.5-5-4.2c-2.2-2.6-2.9-5.6-2.4-8.9c0.3-2.3,1.2-4.3,2.1-6.4
                c0.8-2,1.4-4,1.3-6.1c0-0.5,0.1-0.9,0.1-1.4c0.9,1.9,2,3.7,2.2,5.9c1.2-2.1,2.2-4.3,2.8-6.6c0.7-2.3,1-4.6,1.2-7.1
                c0.6,0.3,1.1,0.4,1.6,0.7c2,1.2,3.5,2.9,4.7,4.9c0.9,1.4,1.5,3,2,4.6c0.1,0.2,0.1,0.4,0.2,0.6c0.4-2.3-0.7-4.1-1.3-6
                c0.5,0.4,1,0.9,1.5,1.3c2.3,1.9,3.7,4.5,4.9,7.1c1.1,2.4,1.7,4.9,1.8,7.6c0.1,3.9-1.3,7.3-3.9,10.2c-1.6,1.7-3.4,3.1-5.4,4.4
                c-0.2,0.1-0.4,0.3-0.8,0.5c0.3-0.6,0.5-1.1,0.7-1.6c0.7-1.5,1.2-3.1,1.4-4.8c0.3-3.1-0.5-5.9-2.4-8.3c-0.4-0.5-0.8-0.9-1.2-1.4
                C1268.7,438,1268.7,438,1268.6,438z M1266.1,420.5c-0.7,5.9-2.9,11.2-6.5,15.9c-0.1-1.5-0.2-2.9-0.3-4.4h-0.1
                c-0.7,1.9-1.5,3.8-2.2,5.8c-0.6,1.7-0.8,3.5-0.5,5.4c0.3,2.4,1.7,4.3,3.4,6c0.3,0.3,0.7,0.6,1.1,0.9c0-0.6-0.1-1-0.1-1.5
                c0-2.3,0.8-4.3,2-6.1c0.7-1.2,1.5-2.3,1.7-3.7c0,0,0-0.1,0.1-0.1c0.8,1.1,1.4,2.2,1.7,3.5c0.5-1.3,0.7-2.6,0.8-3.9
                c0.1-1.3-0.2-2.6-0.4-3.9c0.1,0.1,0.1,0.1,0.2,0.1c1.7,1.4,3.3,2.7,4.6,4.5c1.7,2.5,2.6,5.3,2.4,8.3c-0.1,0.9-0.2,1.8-0.4,2.7
                c0.4-0.3,0.7-0.7,1.1-1c3.4-3.3,4.7-7.3,4-12c-0.4-2.8-1.5-5.3-2.9-7.7c-0.2-0.4-0.5-0.7-0.7-1.1h-0.1c0,1.9-0.7,3.4-2.2,4.6
                c-0.2-1-0.4-1.9-0.7-2.9c-0.8-2.7-1.9-5.2-3.7-7.4C1267.7,421.8,1267,421.1,1266.1,420.5z"/>
            </g>
          </g>
        </g>
        <g id="cultural_district_creations">
          <polygon className="st6" points="1292.9,1719.5 1265.2,1749.2 1237.5,1719.5 1265.2,1689.7 	"/>
          <polygon className="st32" points="1293.9,1718.4 1262.8,1751.8 1231.7,1718.4 1262.8,1685.1 	"/>
          <polygon className="st32" points="1298.6,1718.4 1267.6,1751.8 1236.5,1718.4 1267.5,1685.1 	"/>
          <g>
            <path className="st25" d="M1325.5,1700.4c-0.7,0.4-1.7,0.8-2.8,1.1c-1.1,0.3-2.7,0.5-4.6,0.5c-1.5,0-3-0.3-4.3-0.8
              c-1.3-0.6-2.5-1.4-3.5-2.4s-1.8-2.2-2.3-3.6c-0.6-1.4-0.8-3-0.8-4.7s0.3-3.2,0.8-4.7c0.6-1.4,1.3-2.6,2.3-3.7c1-1,2.1-1.8,3.5-2.4
              c1.3-0.6,2.8-0.9,4.3-0.9c1.1,0,2,0.1,2.8,0.2s1.5,0.3,2.1,0.4c0.6,0.2,1.1,0.3,1.4,0.5c0.4,0.2,0.7,0.3,0.9,0.5l-1.2,3.2
              c-0.2-0.1-0.4-0.2-0.7-0.4c-0.3-0.1-0.7-0.3-1.1-0.4s-1-0.2-1.6-0.3c-0.6-0.1-1.3-0.1-2.1-0.1c-0.8,0-1.6,0.2-2.5,0.6
              c-0.8,0.4-1.5,0.9-2.2,1.6c-0.6,0.7-1.2,1.5-1.6,2.5s-0.6,2.1-0.6,3.3c0,1.2,0.2,2.2,0.6,3.2s0.9,1.8,1.6,2.5
              c0.6,0.7,1.4,1.3,2.2,1.7c0.8,0.4,1.6,0.6,2.5,0.6c1.5,0,2.7-0.1,3.5-0.4s1.5-0.6,2.1-0.8L1325.5,1700.4z"/>
            <path className="st25" d="M1350.3,1694c0,1.1-0.2,2.2-0.6,3.2s-0.9,1.8-1.7,2.6c-0.7,0.7-1.7,1.3-2.7,1.7c-1.1,0.4-2.3,0.6-3.7,0.6
              c-1.4,0-2.7-0.2-3.7-0.6c-1.1-0.4-2-0.9-2.8-1.6c-0.7-0.7-1.3-1.6-1.7-2.6c-0.4-1-0.6-2.1-0.6-3.3v-14.6h4.4v14
              c0,1.7,0.4,2.9,1.1,3.8s1.9,1.3,3.5,1.3c0.8,0,1.5-0.2,2.1-0.5s1-0.7,1.4-1.2s0.6-1,0.8-1.7c0.2-0.6,0.3-1.2,0.3-1.9v-14h4
              L1350.3,1694z"/>
            <path className="st25" d="M1362.9,1698.1h7.7v3.5h-12.1v-22.3h4.4V1698.1z"/>
            <path className="st25" d="M1371.2,1679.3h17.6v3.5h-6.6v18.8h-4.4v-18.8h-6.6V1679.3z"/>
            <path className="st25" d="M1413.1,1693.9c0,1.1-0.2,2.2-0.6,3.2s-0.9,1.8-1.7,2.6c-0.7,0.7-1.7,1.3-2.7,1.7c-1.1,0.4-2.3,0.6-3.7,0.6
              c-1.4,0-2.7-0.2-3.7-0.6c-1.1-0.4-2-0.9-2.8-1.6c-0.7-0.7-1.3-1.6-1.7-2.6c-0.4-1-0.6-2.1-0.6-3.3v-14.6h4.4v14
              c0,1.7,0.4,2.9,1.1,3.8s1.9,1.3,3.5,1.3c0.8,0,1.5-0.2,2.1-0.5s1-0.7,1.4-1.2s0.6-1,0.8-1.7c0.2-0.6,0.3-1.2,0.3-1.9v-14h4
              L1413.1,1693.9z"/>
            <path className="st25" d="M1433.3,1701.9l-5.6-9.9h-2v9.6h-4.4v-22.3h6c1.4,0,2.7,0.2,3.7,0.5s1.9,0.7,2.6,1.3
              c0.7,0.5,1.2,1.2,1.5,1.9c0.3,0.7,0.5,1.6,0.5,2.4s-0.1,1.5-0.4,2.1s-0.6,1.2-1,1.6c-0.4,0.5-0.8,0.8-1.2,1.1
              c-0.4,0.3-0.8,0.5-1,0.6l6.3,10.7L1433.3,1701.9z M1427.6,1688.8c1.3,0,2.2-0.3,2.8-1c0.6-0.6,0.9-1.4,0.9-2.2
              c0-0.9-0.3-1.6-0.9-2.2s-1.6-0.9-2.9-0.9h-1.8v6.3L1427.6,1688.8z"/>
            <path className="st25" d="M1446.2,1701.5h-3.9l8.4-22.3h3l7.7,22.3h-4.5l-1.7-5h-7.2L1446.2,1701.5z M1451.6,1686.1l-2.6,7.3h5.1
              L1451.6,1686.1z"/>
            <path className="st25" d="M1472.6,1698h7.7v3.5h-12.1v-22.3h4.4V1698z"/>
            <path className="st25" d="M1307.9,1710.2h6.7c1.5,0,3,0.2,4.4,0.7s2.7,1.2,3.8,2.1c1.1,0.9,2,2.1,2.6,3.5c0.7,1.4,1,3,1,4.8
              c0,1.7-0.3,3.2-1,4.6s-1.6,2.5-2.7,3.5s-2.4,1.8-3.8,2.3s-2.8,0.8-4.3,0.8h-6.7V1710.2z M1314.7,1728.9c0.8,0,1.6-0.2,2.4-0.6
              s1.6-0.9,2.3-1.6c0.7-0.7,1.2-1.5,1.7-2.4c0.4-0.9,0.7-2,0.7-3.1c0-1.4-0.2-2.5-0.6-3.5s-1-1.7-1.7-2.3c-0.7-0.6-1.5-1.1-2.3-1.3
              c-0.8-0.3-1.7-0.4-2.5-0.4h-2.4v15.3L1314.7,1728.9z"/>
            <path className="st25" d="M1334.2,1732.4v-22.3h4.4v22.3H1334.2z"/>
            <path className="st25" d="M1348.3,1728.5c0.3,0.1,0.6,0.3,1.1,0.4c0.4,0.1,0.9,0.2,1.4,0.3c0.5,0.1,1.2,0.1,1.9,0.1
              c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.3,1-0.5s0.5-0.5,0.7-0.8s0.3-0.6,0.3-1s-0.1-0.9-0.3-1.2c-0.2-0.4-0.5-0.7-0.9-1
              s-0.8-0.7-1.3-1s-1-0.7-1.6-1c-0.5-0.3-1-0.7-1.6-1.1s-1.1-0.8-1.6-1.4c-0.5-0.5-0.9-1.2-1.2-1.9c-0.3-0.7-0.5-1.6-0.5-2.6
              c0-0.9,0.2-1.7,0.5-2.4c0.4-0.7,0.8-1.4,1.4-1.9s1.3-1,2.1-1.3c0.8-0.3,1.6-0.5,2.5-0.5c0.8,0,1.6,0,2.2,0.1s1.2,0.2,1.7,0.3
              c0.5,0.1,0.9,0.3,1.2,0.4c0.3,0.2,0.6,0.3,0.9,0.4l-1.2,3.2c-0.4-0.2-0.9-0.4-1.5-0.7c-0.7-0.2-1.4-0.4-2.2-0.4
              c-0.4,0-0.7,0-1.1,0.1s-0.7,0.2-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.6c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.5,0.1,0.9,0.3,1.3
              c0.2,0.4,0.5,0.7,0.9,1s0.8,0.6,1.3,0.9s1,0.6,1.5,1c0.5,0.3,1,0.7,1.6,1.1s1.2,0.9,1.7,1.5s1,1.2,1.3,1.8
              c0.4,0.7,0.5,1.4,0.5,2.2c0,1.1-0.2,2.1-0.7,2.9c-0.4,0.8-1,1.6-1.8,2.1c-0.7,0.6-1.6,1-2.5,1.3c-1,0.3-1.9,0.5-3,0.5
              c-0.9,0-1.6-0.1-2.3-0.2c-0.7-0.1-1.3-0.2-1.8-0.4c-0.5-0.2-1-0.3-1.3-0.5c-0.4-0.2-0.6-0.3-0.8-0.5l1.2-3.1
              C1347.7,1728.2,1348,1728.3,1348.3,1728.5z"/>
            <path className="st25" d="M1366.1,1710.1h17.6v3.5h-6.6v18.8h-4.4v-18.8h-6.6V1710.1z"/>
            <path className="st25" d="M1402.5,1732.7l-5.6-9.9h-2v9.6h-4.4v-22.3h6c1.4,0,2.7,0.2,3.7,0.5s1.9,0.7,2.6,1.3
              c0.7,0.5,1.2,1.2,1.5,1.9c0.3,0.7,0.5,1.6,0.5,2.4s-0.1,1.5-0.4,2.1s-0.6,1.2-1,1.6c-0.4,0.5-0.8,0.8-1.2,1.1
              c-0.4,0.3-0.8,0.5-1,0.6l6.3,10.7L1402.5,1732.7z M1396.8,1719.6c1.3,0,2.2-0.3,2.8-1c0.6-0.6,0.9-1.4,0.9-2.2
              c0-0.9-0.3-1.6-0.9-2.2s-1.6-0.9-2.9-0.9h-1.8v6.3L1396.8,1719.6z"/>
            <path className="st25" d="M1413.9,1732.3V1710h4.4v22.3H1413.9z"/>
            <path className="st25" d="M1444.4,1731.1c-0.7,0.4-1.7,0.8-2.8,1.1c-1.1,0.3-2.7,0.5-4.6,0.5c-1.5,0-3-0.3-4.3-0.8
              c-1.3-0.6-2.5-1.4-3.5-2.4s-1.8-2.2-2.3-3.6c-0.6-1.4-0.8-3-0.8-4.7s0.3-3.2,0.8-4.7c0.6-1.4,1.3-2.6,2.3-3.7c1-1,2.1-1.8,3.5-2.4
              c1.3-0.6,2.8-0.9,4.3-0.9c1.1,0,2,0.1,2.8,0.2s1.5,0.3,2.1,0.4c0.6,0.2,1.1,0.3,1.4,0.5c0.4,0.2,0.7,0.3,0.9,0.5l-1.2,3.2
              c-0.2-0.1-0.4-0.2-0.7-0.4c-0.3-0.1-0.7-0.3-1.1-0.4s-1-0.2-1.6-0.3c-0.6-0.1-1.3-0.1-2.1-0.1c-0.8,0-1.6,0.2-2.5,0.6
              c-0.8,0.4-1.5,0.9-2.2,1.6c-0.6,0.7-1.2,1.5-1.6,2.5s-0.6,2.1-0.6,3.3c0,1.2,0.2,2.2,0.6,3.2s0.9,1.8,1.6,2.5
              c0.6,0.7,1.4,1.3,2.2,1.7c0.8,0.4,1.6,0.6,2.5,0.6c1.5,0,2.7-0.1,3.5-0.4s1.5-0.6,2.1-0.8L1444.4,1731.1z"/>
            <path className="st25" d="M1450.1,1710h17.6v3.5h-6.6v18.8h-4.4v-18.8h-6.6V1710z"/>
            <path className="st25" d="M1315.7,1751.8c-0.4,0.2-0.8,0.4-1.4,0.6s-1.3,0.3-2.3,0.3c-0.8,0-1.5-0.1-2.2-0.4s-1.2-0.7-1.7-1.2
              s-0.9-1.1-1.2-1.8c-0.3-0.7-0.4-1.5-0.4-2.3s0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.3,1.2-1.8s1.1-0.9,1.7-1.2c0.7-0.3,1.4-0.4,2.2-0.4
              c0.5,0,1,0,1.4,0.1s0.7,0.1,1,0.2s0.5,0.2,0.7,0.3c0.2,0.1,0.3,0.2,0.5,0.2l-0.6,1.6c-0.1,0-0.2-0.1-0.3-0.2
              c-0.2-0.1-0.3-0.1-0.6-0.2c-0.2-0.1-0.5-0.1-0.8-0.2c-0.3,0-0.7-0.1-1.1-0.1s-0.8,0.1-1.2,0.3c-0.4,0.2-0.8,0.5-1.1,0.8
              c-0.3,0.4-0.6,0.8-0.8,1.3c-0.2,0.5-0.3,1-0.3,1.6s0.1,1.1,0.3,1.6c0.2,0.5,0.5,0.9,0.8,1.3s0.7,0.6,1.1,0.8
              c0.4,0.2,0.8,0.3,1.2,0.3c0.8,0,1.3-0.1,1.8-0.2c0.4-0.1,0.8-0.3,1-0.4L1315.7,1751.8z"/>
            <path className="st25" d="M1328.1,1752.6l-2.8-5h-1v4.8h-2.2v-11.1h3c0.7,0,1.3,0.1,1.8,0.2c0.5,0.2,0.9,0.4,1.3,0.6
              c0.3,0.3,0.6,0.6,0.8,1c0.2,0.4,0.2,0.8,0.2,1.2c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.4,0.4-0.6,0.6
              c-0.2,0.1-0.4,0.2-0.5,0.3l3.2,5.3L1328.1,1752.6z M1325.3,1746.1c0.6,0,1.1-0.2,1.4-0.5s0.5-0.7,0.5-1.1s-0.2-0.8-0.5-1.1
              c-0.3-0.3-0.8-0.5-1.4-0.5h-0.9v3.2H1325.3z"/>
            <path className="st25" d="M1343.3,1752.4h-6.7v-11.1h6.5v1.7h-4.3v2.8h3.1v1.7h-3.1v3.1h4.5V1752.4z"/>
            <path className="st25" d="M1350.7,1752.4h-2l4.2-11.2h1.5l3.8,11.2h-2.3l-0.8-2.5h-3.6L1350.7,1752.4z M1353.5,1744.7l-1.3,3.7h2.5
              L1353.5,1744.7z"/>
            <path className="st25" d="M1362.3,1741.3h8.8v1.7h-3.3v9.4h-2.2v-9.4h-3.3V1741.3z"/>
            <path className="st25" d="M1377.4,1752.4v-11.1h2.2v11.1H1377.4z"/>
            <path className="st25" d="M1391.7,1741c0.8,0,1.5,0.1,2.2,0.4s1.3,0.7,1.7,1.2c0.5,0.5,0.9,1.1,1.1,1.8c0.3,0.7,0.4,1.5,0.4,2.3
              c0,0.9-0.1,1.7-0.4,2.4c-0.3,0.7-0.7,1.3-1.2,1.8s-1.1,0.9-1.7,1.2c-0.7,0.3-1.3,0.4-2.1,0.4c-0.8,0-1.5-0.1-2.1-0.4
              c-0.7-0.3-1.2-0.6-1.7-1.1s-0.9-1.1-1.2-1.8c-0.3-0.7-0.4-1.5-0.4-2.4c0-0.8,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.3,1.2-1.8
              s1.1-0.9,1.7-1.2C1390.2,1741.2,1390.9,1741,1391.7,1741z M1391.7,1742.8c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-1,0.9
              s-0.5,0.8-0.6,1.3s-0.2,1-0.2,1.6s0.1,1.1,0.2,1.6s0.4,0.9,0.6,1.3c0.3,0.4,0.6,0.7,1,0.9c0.4,0.2,0.8,0.3,1.3,0.3
              s0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,1-0.9s0.5-0.8,0.6-1.3s0.2-1,0.2-1.6c0-0.5-0.1-1.1-0.2-1.6s-0.4-0.9-0.6-1.3
              c-0.3-0.4-0.6-0.7-1-0.9S1392.2,1742.8,1391.7,1742.8z"/>
            <path className="st25" d="M1413.1,1741.2v11.1h-2.1l-5.4-7.4v7.4h-1.9v-11.1h2l5.5,7.6v-7.6H1413.1z"/>
            <path className="st25" d="M1420.8,1750.4c0.1,0.1,0.3,0.1,0.5,0.2s0.4,0.1,0.7,0.2c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.4,0,0.6-0.1
              s0.4-0.1,0.5-0.2s0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.4-0.2-0.6s-0.3-0.4-0.5-0.5c-0.2-0.2-0.4-0.3-0.7-0.5
              s-0.5-0.3-0.8-0.5c-0.2-0.2-0.5-0.3-0.8-0.5s-0.5-0.4-0.8-0.7s-0.4-0.6-0.6-0.9c-0.2-0.4-0.2-0.8-0.2-1.3c0-0.4,0.1-0.8,0.3-1.2
              c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.6-0.5,1-0.6c0.4-0.2,0.8-0.2,1.2-0.2c0.4,0,0.8,0,1.1,0.1c0.3,0,0.6,0.1,0.8,0.2
              c0.2,0.1,0.4,0.1,0.6,0.2s0.3,0.1,0.4,0.2l-0.6,1.6c-0.2-0.1-0.4-0.2-0.8-0.3c-0.3-0.1-0.7-0.2-1.1-0.2c-0.2,0-0.4,0-0.6,0.1
              c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.5s0.1,0.4,0.2,0.6s0.3,0.4,0.5,0.5s0.4,0.3,0.6,0.5
              s0.5,0.3,0.7,0.5s0.5,0.4,0.8,0.6s0.6,0.5,0.8,0.7c0.3,0.3,0.5,0.6,0.7,0.9s0.3,0.7,0.3,1.1c0,0.5-0.1,1-0.3,1.5
              c-0.2,0.4-0.5,0.8-0.9,1.1c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.1-1,0.2-1.5,0.2c-0.4,0-0.8,0-1.2-0.1c-0.3-0.1-0.6-0.1-0.9-0.2
              s-0.5-0.2-0.7-0.3c-0.2-0.1-0.3-0.2-0.4-0.2l0.6-1.6C1420.5,1750.3,1420.6,1750.3,1420.8,1750.4z"/>
          </g>
          <g id="VkHjnu_1_">
            <g>
              <path d="M1285.3,1720.2c0,0.6-0.1,1.2-0.5,1.7c-0.2,0.3-0.5,0.5-0.7,0.7c-0.1,0.1-0.2,0.1-0.2,0.2c-0.5,1.1-1.5,1.6-2.5,2
                c-1.8,0.7-3.7,1-5.6,1.2c-7.1,0.6-14.1,0.6-21.2-0.2c-1.5-0.2-3-0.5-4.5-0.9c-0.8-0.2-1.5-0.6-2.3-1c-0.5-0.3-0.9-0.8-1.1-1.4
                c0-0.1-0.1-0.2-0.2-0.2c-0.7-0.2-1-0.7-1.3-1.3c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.1-0.6-0.2-0.9c0.1-1.1,0.5-2,1.3-2.7
                c0.1-0.1,0.1-0.2,0.1-0.3c0-1.8,0.8-3.3,1.9-4.6c2.7-3.2,6.1-4.3,10-3.1c0.2,0.1,0.4,0,0.6-0.1c1.1-0.4,2.2-0.9,3.4-1.3
                c2.3-0.7,4.6-0.6,6.7,0.8c0.1,0.1,0.3,0.1,0.5,0.1c1.9-0.2,3.8-0.2,5.7,0.2c3.1,0.6,5.3,2.5,6.8,5.4c0.5,1.1,1,2.2,1.5,3.3
                c0.1,0.2,0.2,0.4,0.4,0.5c0.6,0.3,1,0.9,1.3,1.5C1285.2,1719.6,1285.2,1719.9,1285.3,1720.2z M1260.5,1710c0.7,0.5,1.3,1,2,1.5
                c0.1,0.1,0.3,0.3,0.3,0.4c0.2,0.4,0.1,0.8-0.2,1c-0.3,0.3-0.7,0.3-1,0c-0.7-0.5-1.3-1.1-2.1-1.5c-2.3-1.3-4.7-1.7-7.1-0.5
                c-2,0.9-3.5,2.4-4.3,4.5c-0.1,0.4-0.2,0.8-0.3,1.3c11.3,0.2,22.5,0.3,33.7,0.5c-0.9-2.3-1.9-4.3-3.9-5.7c-2-1.4-4.3-1.5-6.6-1.4
                c0.2,0.3,0.4,0.4,0.6,0.6c0.4,0.5,0.5,1,0.1,1.4s-0.8,0.3-1.3-0.1c-0.4-0.4-0.8-0.8-1.2-1.1c-2.3-2.1-4.8-2.5-7.6-1.4
                C1261.4,1709.7,1261,1709.8,1260.5,1710z M1282,1722.5c-1.5-0.5-2.9-0.6-4.4-0.2c-0.9,0.3-1.9,0.6-2.8,0.9
                c-1.1,0.4-2.1,0.3-3.1-0.3c-0.7-0.4-1.4-0.8-2.1-1.2c-0.8-0.5-1.7-0.5-2.6-0.1c-0.8,0.4-1.7,0.7-2.5,1.1
                c-1.4,0.6-2.6,0.5-3.9-0.4c-0.5-0.4-1-0.7-1.4-1c-0.6-0.4-1.2-0.4-1.8-0.1c-0.5,0.3-1,0.5-1.6,0.8c-0.9,0.5-1.8,0.5-2.7-0.2
                c-0.3-0.2-0.5-0.4-0.8-0.6c-0.2-0.1-0.5-0.3-0.8-0.2c-1.1,0.2-2.1,0.5-3.2,0.7v0.1c0.1,0.1,0.3,0.3,0.4,0.3
                c0.4,0.2,0.8,0.4,1.2,0.6c1.8,0.7,3.6,0.9,5.4,1.2c5.2,0.6,10.4,0.6,15.6,0.5c2.6-0.1,5.2-0.3,7.8-0.8
                C1279.8,1723.4,1280.9,1723.1,1282,1722.5z M1254.7,1720.5c0.6-0.3,1.1-0.6,1.6-0.8c1.3-0.7,2.6-0.7,3.9,0.3
                c0.4,0.3,0.9,0.6,1.3,0.9c0.7,0.5,1.4,0.5,2.2,0.2c0.7-0.3,1.5-0.6,2.2-1c1.7-0.9,3.3-0.8,4.9,0.2c0.6,0.4,1.2,0.7,1.8,1.1
                c0.5,0.3,0.9,0.4,1.5,0.2c0.9-0.3,1.7-0.6,2.6-0.8c1.6-0.5,3.2-0.7,4.9-0.3c0.5,0.1,1,0.2,1.5,0.3c0.3,0.1,0.6,0,0.7-0.4
                s0-0.7-0.3-0.8c-0.6-0.2-1.2-0.5-1.8-0.6c-3.2-0.1-6.4-0.1-9.7-0.2c-8.1-0.1-16.2-0.2-24.3-0.4c-0.6,0-1.1,0.6-1,1.2
                c0.1,0.5,0.3,0.6,0.7,0.5c0.3-0.1,0.5-0.2,0.8-0.2c1.1-0.3,2.1-0.6,3.2-0.8c0.7-0.2,1.4,0,2,0.5c0.3,0.2,0.6,0.5,0.9,0.7
                C1254.3,1720.5,1254.5,1720.5,1254.7,1720.5z"/>
            </g>
          </g>
        </g>
        <g id="tarrant_tex_mex">
          
            <rect x="1594.6" y="1694.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -739.6573 1644.6816)" className="st6" width="41.8" height="41.8"/>
          
            <rect x="1589.5" y="1691.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -740.3865 1642.928)" className="st33" width="47" height="47"/>
          
            <rect x="1594.6" y="1691.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -738.897 1646.5199)" className="st33" width="47" height="47"/>
          <g>
            <path className="st25" d="M1659.5,1694.1h18.8v3.5h-7.1v18.7h-4.7v-18.7h-7.1L1659.5,1694.1L1659.5,1694.1z"/>
            <path className="st25" d="M1683.4,1716.2h-4.2l9-22.2h3.2l8.2,22.2h-4.9l-1.8-5h-7.7L1683.4,1716.2z M1689.2,1700.8l-2.8,7.3h5.4
              L1689.2,1700.8z"/>
            <path className="st25" d="M1719.7,1716.5l-6-9.9h-2.1v9.5h-4.7v-22.2h6.4c1.5,0,2.8,0.2,4,0.5c1.1,0.3,2,0.7,2.7,1.3
              c0.7,0.5,1.3,1.2,1.6,1.9c0.4,0.7,0.5,1.5,0.5,2.4c0,0.8-0.2,1.5-0.5,2.1s-0.7,1.2-1.1,1.6c-0.4,0.5-0.9,0.8-1.3,1.1
              c-0.5,0.3-0.8,0.5-1.1,0.6l6.8,10.6L1719.7,1716.5z M1713.5,1703.5c1.4,0,2.4-0.3,3-1c0.7-0.6,1-1.4,1-2.2c0-0.9-0.3-1.6-1-2.2
              c-0.6-0.6-1.7-0.9-3.1-0.9h-1.9v6.3L1713.5,1703.5L1713.5,1703.5z"/>
            <path className="st25" d="M1744.6,1716.5l-6-9.9h-2.1v9.5h-4.7v-22.2h6.4c1.5,0,2.8,0.2,4,0.5c1.1,0.3,2,0.7,2.7,1.3
              c0.7,0.5,1.3,1.2,1.6,1.9c0.4,0.7,0.5,1.5,0.5,2.4c0,0.8-0.2,1.5-0.5,2.1s-0.7,1.2-1.1,1.6c-0.4,0.5-0.9,0.8-1.3,1.1
              c-0.5,0.3-0.8,0.5-1.1,0.6l6.8,10.6L1744.6,1716.5z M1738.5,1703.5c1.4,0,2.4-0.3,3-1c0.7-0.6,1-1.4,1-2.2c0-0.9-0.3-1.6-1-2.2
              c-0.6-0.6-1.7-0.9-3.1-0.9h-1.9v6.3L1738.5,1703.5L1738.5,1703.5z"/>
            <path className="st25" d="M1758.4,1716.2h-4.2l9-22.2h3.2l8.2,22.2h-4.9l-1.8-5h-7.7L1758.4,1716.2z M1764.2,1700.8l-2.8,7.3h5.4
              L1764.2,1700.8z"/>
            <path className="st25" d="M1801.8,1694.1v22.2h-4.5l-11.5-14.8v14.8h-4v-22.2h4.2l11.8,15.1v-15.1H1801.8z"/>
            <path className="st25" d="M1808.9,1694.1h18.8v3.5h-7.1v18.7h-4.7v-18.7h-7.1L1808.9,1694.1L1808.9,1694.1z"/>
            <path className="st25" d="M1659.3,1725.1h9.4v1.7h-3.5v9.4h-2.3v-9.4h-3.5L1659.3,1725.1L1659.3,1725.1z"/>
            <path className="st25" d="M1682.5,1736.1h-7.1V1725h6.9v1.7h-4.6v2.8h3.4v1.7h-3.4v3.1h4.8L1682.5,1736.1L1682.5,1736.1z"/>
            <path className="st25" d="M1690.6,1736.1h-2.2l3.8-5.6l-3.5-5.4h2.5l2.3,3.5l2.3-3.5h2.2l-3.4,5.1l3.9,6h-2.6l-2.6-4L1690.6,1736.1z"
              />
            <path className="st25" d="M1726,1736.1h-2.3l-0.1-6.7l-2.8,6.8h-1.5l-2.7-6.8l-0.3,6.7h-1.9l0.5-11.1h2.2l3.2,8.1l3.4-8.1h2
              L1726,1736.1z"/>
            <path className="st25" d="M1740.5,1736.1h-7.1V1725h6.9v1.7h-4.6v2.8h3.4v1.7h-3.4v3.1h4.8L1740.5,1736.1L1740.5,1736.1z"/>
            <path className="st25" d="M1748.6,1736.1h-2.2l3.8-5.6l-3.5-5.4h2.5l2.3,3.5l2.3-3.5h2.2l-3.4,5.1l3.9,6h-2.6l-2.6-4L1748.6,1736.1z"
              />
          </g>
          <g id="Xrr4fa_2_">
            <g>
              <path d="M1616,1724.3c-4,0-8,0-11.9,0c-3.1,0-4.9-1.9-4.9-5c0-2.1,0.1-4.3,0.8-6.3c0.1-0.2,0-0.5-0.1-0.7
                c-1.4-3.1-0.4-6.2,2.7-7.7c0.3-0.1,0.5-0.4,0.6-0.6c1.1-2.1,2.8-3.1,5.2-3.2c0.5,0,1-0.2,1.5-0.4c2-1.1,4.5-1.1,6.3,0.3
                c0.4,0.3,0.8,0.3,1.2,0.2c2.3-0.4,4.3,0.2,5.5,2.2c0.8,1.3,1.9,2,3.1,2.6c3.7,2.2,6.1,5.3,6.8,9.6c0.3,1.6,0.3,3.3,0.2,4.9
                c-0.2,2.5-2.1,4.1-4.6,4.1C1624.2,1724.3,1620.1,1724.3,1616,1724.3z M1624.3,1722.1c-0.1-1.2-0.2-2.3-0.3-3.4
                c-0.1-0.9,0-1.7-0.1-2.6c-0.7-5.3-4.7-9.2-10.2-9.8c-4.9-0.6-9.8,2.6-11.6,7.4c-0.7,1.9-0.7,3.9-0.7,5.9c0,1.5,1,2.5,2.5,2.5
                c2.9,0,5.7,0,8.6,0C1616.5,1722.1,1620.4,1722.1,1624.3,1722.1z M1626.2,1716.6c1.6-1.5,1.5-4-0.3-5.2c-0.6-0.4-0.9-0.9-0.8-1.6
                c0.1-1.7-0.9-2.9-2.4-3.5c-0.4-0.2-0.9-0.6-1-1c-0.8-2.1-2.6-2.9-4.8-2.1c-0.6,0.3-1.1,0.1-1.6-0.4c-1.5-1.6-3.3-1.6-5-0.2
                c-0.4,0.4-0.8,0.5-1.4,0.3c-1.7-0.4-3.3,0.4-3.9,2.1c-0.3,0.7-0.6,1.1-1.3,1.3c-1.5,0.5-2.4,1.9-2.2,3.3
                c3.8-4.9,8.7-6.9,14.7-5.3C1622.2,1706.2,1625.4,1710.4,1626.2,1716.6z M1629.3,1712.1c0,0.2,0,0.3,0,0.4
                c0.7,2.9-0.2,5.1-2.7,6.6c-0.2,0.1-0.4,0.4-0.4,0.7c0,1.2,1,2.2,2.2,2.3c1.2,0,2.2-0.9,2.3-2.1c0-1,0-2.1,0-3.1
                C1630.6,1715.2,1630.2,1713.7,1629.3,1712.1z"/>
              <path d="M1603.7,1720.9c-0.6,0-1.1-0.5-1.1-1.1s0.4-1,1.1-1.1c0.6,0,1.1,0.4,1.1,1.1C1604.8,1720.4,1604.3,1720.9,1603.7,1720.9z
                "/>
              <path d="M1621.7,1718.7c0.6,0,1.1,0.5,1.1,1.1s-0.6,1.1-1.2,1.1c-0.6-0.1-1-0.4-1-1.1C1620.6,1719.2,1621.1,1718.7,1621.7,1718.7
                z"/>
              <path d="M1608.2,1713c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1-1.1c0.7,0,1.1,0.4,1.1,1C1609.3,1712.5,1608.8,1713,1608.2,1713z"/>
              <path d="M1605.9,1715.3c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1s0.5-1,1.1-1C1605.4,1714.2,1605.9,1714.7,1605.9,1715.3z"/>
              <path d="M1609.3,1718.6c0,0.6-0.5,1.1-1.1,1.1s-1.2-0.5-1.1-1.1c0-0.6,0.4-1,1-1.1C1608.8,1717.6,1609.3,1718,1609.3,1718.6z"/>
              <path d="M1621.7,1715.4c-0.1,0.6-0.6,1.1-1.2,1s-1-0.5-0.9-1.2c0-0.6,0.5-1.1,1.2-1C1621.3,1714.3,1621.7,1714.8,1621.7,1715.4z"
                />
              <path d="M1611.5,1716.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.7,0.5-1.1,1.1-1c0.7,0.1,1,0.4,1,1.1
                C1612.7,1715.9,1612.2,1716.4,1611.5,1716.4z"/>
              <path d="M1613.8,1709.7c0,0.6-0.5,1.1-1.1,1.1c-0.7,0-1-0.4-1.1-1c0-0.6,0.4-1.1,1.1-1.1
                C1613.3,1708.5,1613.8,1709,1613.8,1709.7z"/>
              <path d="M1616,1719.8c-0.6,0-1.1-0.5-1-1.2c0-0.6,0.5-1.1,1.2-1s1,0.4,1,1.1C1617.2,1719.3,1616.6,1719.8,1616,1719.8z"/>
              <path d="M1617.2,1713c0,0.6-0.5,1.1-1.1,1.1s-1-0.5-1-1.1s0.5-1.1,1.1-1.1C1616.7,1712,1617.2,1712.4,1617.2,1713z"/>
            </g>
          </g>
        </g>
        <g id="tx_tavern">
          
            <rect x="2345.4" y="834.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 86.9835 1926.5811)" className="st34" width="47.4" height="47.4"/>
          
            <rect x="2350.5" y="834.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 88.4728 1930.1873)" className="st35" width="47.4" height="47.4"/>
          <g>
            <path className="st25" d="M2417.8,847.7h19v3.5h-7.1v18.9h-4.7v-18.9h-7.1L2417.8,847.7L2417.8,847.7z"/>
            <path className="st25" d="M2442,870.1h-4.3l9.1-22.4h3.3l8.3,22.4h-4.9l-1.8-5h-7.7L2442,870.1z M2447.9,854.5l-2.8,7.4h5.5
              L2447.9,854.5z"/>
            <path className="st25" d="M2472.7,870.3h-2.9l-7.3-22.6h4.8l4.6,14.8l5-14.8h4.1L2472.7,870.3z"/>
            <path className="st25" d="M2502.3,870.1h-14.4v-22.4h14v3.5h-9.3v5.6h6.8v3.5h-6.8v6.3h9.7V870.1z"/>
            <path className="st25" d="M2522.9,870.4l-6-10h-2.1v9.6h-4.7v-22.4h6.5c1.5,0,2.9,0.2,4,0.5s2,0.7,2.8,1.3c0.7,0.5,1.3,1.2,1.6,1.9
              c0.4,0.7,0.5,1.6,0.5,2.4c0,0.8-0.2,1.5-0.5,2.1s-0.7,1.2-1.1,1.6c-0.4,0.5-0.9,0.8-1.3,1.1c-0.5,0.3-0.8,0.5-1.1,0.6l6.8,10.7
              L2522.9,870.4z M2516.8,857.2c1.4,0,2.4-0.3,3.1-1c0.7-0.6,1-1.4,1-2.2c0-0.9-0.3-1.6-1-2.2c-0.7-0.6-1.7-0.9-3.1-0.9h-2v6.4
              L2516.8,857.2L2516.8,857.2z"/>
            <path className="st25" d="M2555.3,847.7v22.4h-4.5l-11.6-14.9v14.9h-4.1v-22.4h4.2l11.9,15.2v-15.2H2555.3z"/>
          </g>
          <g>
            <g>
              <polygon className="st25" points="2369.5,857.9 2371.5,860.3 2373.5,857.9 2373.5,844.4 2379.8,844.4 2379.8,841.7 2363.2,841.7 
                2363.2,844.4 2369.5,844.4 			"/>
              <path className="st25" d="M2362.3,840.9v4.4h6.3v13l2.9,3.5l2.8-3.5v-13h6.4v-4.4H2362.3z M2380.2,844.8h-6.4v13.3l-2.3,2.9l-2.4-2.9
                v-13.3h-6.4v-3.4h17.5L2380.2,844.8L2380.2,844.8z"/>
            </g>
            <g>
              <polygon className="st25" points="2373.5,866.4 2379.8,858.9 2376,858.9 2371.5,864.2 2367.3,858.9 2363.2,858.9 2369.7,866.5 
                2363,874.3 2366.9,874.3 2371.5,868.7 2376,874.3 2380,874.3 			"/>
              <path className="st25" d="M2374.6,866.4l7-8.3h-6l-4,4.9l-3.9-4.9h-6.3l7.2,8.4l-7.4,8.6h6.1l4.2-5.1l4.1,5.1h6.2L2374.6,866.4z
                M2371.5,869.3l-4.4,5.4h-5l7-8.2l-6.8-8h5.1l4.1,5.1l4.2-5.1h4.8l-6.6,7.9l6.9,8.3h-5.1L2371.5,869.3z"/>
            </g>
          </g>
        </g>
        <g id="_x37_th_street_staples">
          
            <rect x="2344" y="1062.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -73.282 1989.7717)" className="st6" width="42.5" height="42.5"/>
          
            <rect x="2338.5" y="1060.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -74.6892 1987.6621)" className="st36" width="47" height="47"/>
          
            <rect x="2343.6" y="1060.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -73.1945 1991.2684)" className="st36" width="47" height="47"/>
          <g>
            <path className="st25" d="M2411,1062.8h15.5v3.3l-12.6,19.8l-2.8-1.8l9.9-17.1h-10L2411,1062.8L2411,1062.8z"/>
            <path className="st25" d="M2429.8,1061h11v2h-4.1v10.9h-2.7V1063h-4.1L2429.8,1061L2429.8,1061z"/>
            <path className="st25" d="M2453.2,1068.3h-5.4v5.6h-2.7v-13h2.7v5.3h5.4v-5.3h2.7v13h-2.7V1068.3z"/>
            <path className="st25" d="M2471.3,1081.1c0.3,0.1,0.7,0.3,1.1,0.4c0.4,0.1,1,0.2,1.5,0.3c0.6,0.1,1.3,0.1,2,0.1c0.4,0,0.9-0.1,1.3-0.2
              c0.4-0.1,0.8-0.3,1.1-0.5s0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.6,0.3-1s-0.1-0.8-0.3-1.2c-0.2-0.4-0.6-0.7-1-1s-0.9-0.7-1.4-1
              s-1.1-0.7-1.7-1c-0.5-0.3-1.1-0.7-1.7-1.1c-0.6-0.4-1.2-0.8-1.7-1.4c-0.5-0.5-0.9-1.2-1.3-1.9c-0.3-0.7-0.5-1.6-0.5-2.6
              c0-0.9,0.2-1.7,0.6-2.4c0.4-0.7,0.9-1.4,1.5-1.9s1.4-0.9,2.2-1.2c0.8-0.3,1.7-0.4,2.7-0.4c0.9,0,1.7,0.1,2.4,0.1
              c0.7,0.1,1.3,0.2,1.8,0.3c0.5,0.1,0.9,0.3,1.3,0.4c0.4,0.2,0.7,0.3,0.9,0.4l-1.3,3.2c-0.4-0.2-0.9-0.4-1.6-0.7s-1.5-0.4-2.3-0.4
              c-0.4,0-0.8,0-1.2,0.1s-0.7,0.2-1,0.3c-0.3,0.2-0.6,0.4-0.8,0.6c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.5,0.1,0.9,0.4,1.2
              c0.2,0.4,0.6,0.7,1,1s0.9,0.6,1.4,0.9s1,0.6,1.6,1c0.5,0.3,1.1,0.7,1.7,1.1c0.6,0.4,1.2,0.9,1.8,1.5s1,1.2,1.4,1.8
              c0.4,0.7,0.6,1.4,0.6,2.2c0,1.1-0.2,2.1-0.7,2.9s-1.1,1.6-1.9,2.1c-0.8,0.6-1.7,1-2.7,1.3s-2.1,0.4-3.2,0.4
              c-0.9,0-1.8-0.1-2.5-0.2s-1.4-0.2-1.9-0.4c-0.6-0.2-1-0.3-1.4-0.5s-0.7-0.3-0.9-0.5l1.3-3.1
              C2470.7,1080.8,2470.9,1080.9,2471.3,1081.1z"/>
            <path className="st25" d="M2490.3,1062.8h18.9v3.5h-7.1v18.7h-4.7v-18.7h-7.1L2490.3,1062.8L2490.3,1062.8z"/>
            <path className="st25" d="M2529.3,1085.3l-6-9.9h-2.1v9.5h-4.7v-22.2h6.5c1.5,0,2.8,0.2,4,0.5c1.1,0.3,2,0.7,2.7,1.3
              c0.7,0.5,1.3,1.2,1.6,1.9c0.4,0.7,0.5,1.5,0.5,2.4c0,0.8-0.2,1.5-0.5,2.1s-0.7,1.2-1.1,1.6c-0.4,0.5-0.9,0.8-1.3,1.1
              c-0.5,0.3-0.8,0.5-1.1,0.6l6.8,10.6L2529.3,1085.3z M2523.2,1072.3c1.4,0,2.4-0.3,3-1c0.7-0.6,1-1.4,1-2.2c0-0.9-0.3-1.6-1-2.2
              c-0.6-0.6-1.7-0.9-3.1-0.9h-1.9v6.3L2523.2,1072.3L2523.2,1072.3z"/>
            <path className="st25" d="M2555.8,1085h-14.3v-22.2h13.9v3.5h-9.2v5.6h6.7v3.5h-6.7v6.2h9.6V1085z"/>
            <path className="st25" d="M2577.8,1085h-14.3v-22.2h13.9v3.5h-9.2v5.6h6.7v3.5h-6.7v6.2h9.6V1085z"/>
            <path className="st25" d="M2583.7,1062.8h18.9v3.5h-7.1v18.7h-4.7v-18.7h-7.1L2583.7,1062.8L2583.7,1062.8z"/>
            <path className="st25" d="M2409.8,1103c0.2,0.1,0.3,0.1,0.6,0.2c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4,0,0.6-0.1
              c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.3,0.1-0.5s-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.4-0.5-0.5
              c-0.2-0.2-0.4-0.3-0.7-0.5s-0.5-0.3-0.8-0.5s-0.5-0.3-0.8-0.5s-0.6-0.4-0.8-0.7c-0.3-0.3-0.5-0.6-0.6-0.9
              c-0.2-0.4-0.2-0.8-0.2-1.3c0-0.4,0.1-0.8,0.3-1.2c0.2-0.4,0.4-0.7,0.8-0.9c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.9-0.2,1.3-0.2
              c0.4,0,0.8,0,1.2,0.1c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.1,0.5,0.2l-0.7,1.6
              c-0.2-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.7-0.2-1.2-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.2-0.4,0.3
              c-0.1,0.1-0.1,0.3-0.1,0.5s0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.4,0.3,0.7,0.5s0.5,0.3,0.8,0.5s0.5,0.4,0.9,0.6
              c0.3,0.2,0.6,0.5,0.9,0.7c0.3,0.3,0.5,0.6,0.7,0.9s0.3,0.7,0.3,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.8-0.9,1.1
              c-0.4,0.3-0.8,0.5-1.4,0.7c-0.5,0.1-1,0.2-1.6,0.2c-0.5,0-0.9,0-1.2-0.1c-0.4-0.1-0.7-0.1-1-0.2s-0.5-0.2-0.7-0.3
              c-0.2-0.1-0.3-0.2-0.4-0.2l0.7-1.6C2409.5,1102.9,2409.7,1102.9,2409.8,1103z"/>
            <path className="st25" d="M2422.4,1093.9h9.4v1.7h-3.5v9.4h-2.3v-9.4h-3.5L2422.4,1093.9L2422.4,1093.9z"/>
            <path className="st25" d="M2437.3,1105h-2.1l4.5-11.1h1.6l4.1,11.1h-2.4l-0.9-2.5h-3.8L2437.3,1105z M2440.2,1097.3l-1.4,3.7h2.7
              L2440.2,1097.3z"/>
            <path className="st25" d="M2452.1,1105v-11.1h3.5c0.7,0,1.3,0.1,1.8,0.2c0.5,0.2,0.9,0.4,1.3,0.7c0.3,0.3,0.6,0.6,0.8,1
              s0.3,0.8,0.3,1.2c0,0.5-0.1,1-0.3,1.4s-0.5,0.8-0.9,1c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.2-1.6,0.2h-1.1v4.6h-2.5V1105z
              M2455.3,1098.8c0.2,0,0.5,0,0.7-0.1s0.5-0.2,0.7-0.3c0.2-0.1,0.3-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8s-0.1-0.5-0.2-0.7
              c-0.1-0.2-0.3-0.4-0.5-0.5s-0.4-0.2-0.7-0.3c-0.2-0.1-0.5-0.1-0.7-0.1h-0.8v3.4L2455.3,1098.8L2455.3,1098.8z"/>
            <path className="st25" d="M2469,1103.2h4.1v1.7h-6.5v-11.1h2.3L2469,1103.2L2469,1103.2z"/>
            <path className="st25" d="M2486.9,1105h-7.1v-11.1h6.9v1.7h-4.6v2.8h3.4v1.7h-3.4v3.1h4.8L2486.9,1105L2486.9,1105z"/>
            <path className="st25" d="M2494.4,1103c0.2,0.1,0.3,0.1,0.6,0.2c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4,0,0.6-0.1
              c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.3,0.1-0.5s-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.4-0.5-0.5
              c-0.2-0.2-0.4-0.3-0.7-0.5s-0.5-0.3-0.8-0.5s-0.5-0.3-0.8-0.5s-0.6-0.4-0.8-0.7c-0.3-0.3-0.5-0.6-0.6-0.9
              c-0.2-0.4-0.2-0.8-0.2-1.3c0-0.4,0.1-0.8,0.3-1.2c0.2-0.4,0.4-0.7,0.8-0.9c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.9-0.2,1.3-0.2
              c0.4,0,0.8,0,1.2,0.1c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.1,0.5,0.2l-0.7,1.6
              c-0.2-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.7-0.2-1.2-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.2-0.4,0.3
              c-0.1,0.1-0.1,0.3-0.1,0.5s0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.4,0.3,0.7,0.5s0.5,0.3,0.8,0.5s0.5,0.4,0.9,0.6
              c0.3,0.2,0.6,0.5,0.9,0.7c0.3,0.3,0.5,0.6,0.7,0.9s0.3,0.7,0.3,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.8-0.9,1.1
              c-0.4,0.3-0.8,0.5-1.4,0.7s-1,0.2-1.6,0.2c-0.5,0-0.9,0-1.2-0.1c-0.4-0.1-0.7-0.1-1-0.2s-0.5-0.2-0.7-0.3
              c-0.2-0.1-0.3-0.2-0.4-0.2l0.7-1.6C2494.1,1102.9,2494.2,1102.9,2494.4,1103z"/>
          </g>
          <g id="eI18kZ_2_">
            <g>
              <path className="st31" d="M2347.9,1086.6c0-0.1,0.1-0.2,0.1-0.2c0.9-3.1,4.4-4.4,7.2-2.7c0,0,0.1,0.1,0.2,0.1
                c1.1-1.3,2.5-1.9,4.2-1.7c1.4,0.1,2.4,0.8,3.3,1.9c1.6,2.1,1.2,5.7-1.9,7.2c0.8,2.2,0.4,4.1-1.5,5.6c-1.6,1.2-3.4,1.4-5.1,0.4
                c-1.9-1-2.6-2.6-2.5-4.8c-0.1,0-0.3-0.1-0.4-0.1c-1.8-0.5-2.9-1.6-3.5-3.4c0-0.1-0.1-0.1-0.1-0.2
                C2347.9,1087.9,2347.9,1087.2,2347.9,1086.6z M2354.4,1084.9c-0.7-0.5-1.6-0.8-2.5-0.6c-1.6,0.2-2.8,1.7-2.8,3.4
                c0,1.6,1.4,3,2.9,3.2c0.8,0.1,1.5,0,2.2-0.5c0.4-0.2,0.8-0.1,1,0.2c0.2,0.4,0.1,0.8-0.3,1c-0.5,0.2-1,0.4-1.4,0.6
                c-0.2,0.1-0.3,0.2-0.3,0.4c-0.1,1.4,0.6,2.7,1.8,3.2c1.3,0.6,2.5,0.5,3.6-0.4c1-0.8,1.4-2.2,1.1-3.5c-0.2-0.5-0.2-0.5-0.7-0.6
                c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.2-0.7,0.7-0.7c0.1,0,0.3,0,0.4,0c1.9-0.2,3.2-1.9,2.9-3.8c-0.3-1.8-2.2-3.1-4-2.7
                c-1.5,0.3-2.5,1.6-2.6,3.2c0,0.5-0.3,0.8-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.8C2354.3,1086,2354.4,1085.5,2354.4,1084.9z"/>
              <path className="st31" d="M2381.8,1088.9c-0.1,0.2-0.1,0.5-0.2,0.7c-0.5,1.7-1.7,2.9-3.4,3.3c-1.8,0.4-3.4-0.1-4.6-1.5
                c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0-0.1-0.1c-1.4,0.9-3,1.2-4.6,0.6c-1.3-0.5-2.1-1.4-2.5-2.7c-0.9-2.4,0.1-5.5,3.6-6.3
                c-0.1-1.8,0.4-3.4,2-4.4c1.1-0.7,2.3-1,3.5-0.7c3.1,0.7,4.5,3.8,3.5,6.3c0,0,0,0.1,0.1,0.1c1.6,0.7,2.5,1.9,2.8,3.6
                c0,0.1,0,0.1,0.1,0.1C2381.8,1088.2,2381.8,1088.6,2381.8,1088.9z M2374.4,1090.3c0,0,0.1,0.1,0.2,0.2c0.8,1,2.2,1.4,3.5,1
                s2.1-1.5,2.2-2.9c0.1-1.3-0.7-2.5-1.8-3.1c-0.3-0.2-0.6-0.2-0.8,0.1c-0.3,0.3-0.7,0.3-1,0.1c-0.3-0.3-0.3-0.7,0.1-1.1
                c0.9-1,1.2-2.2,0.6-3.5c-0.6-1.3-1.6-2-3-2s-2.5,0.7-3,2c-0.6,1.3-0.3,2.6,0.7,3.6c0.3,0.3,0.3,0.7,0,1s-0.7,0.3-1-0.1
                c-0.3-0.3-0.6-0.7-0.8-1.1c-0.1-0.2-0.2-0.3-0.5-0.2c-1.5,0.3-2.6,1.8-2.6,3.3c0.1,1.5,1.4,3,2.8,3.2c1.8,0.3,3.5-1.1,3.7-2.6
                c0.1-0.5,0.5-0.7,0.9-0.6s0.6,0.5,0.5,0.9C2374.9,1089.2,2374.6,1089.7,2374.4,1090.3z"/>
              <path className="st31" d="M2362.8,1067.8c1.5-1.6,3.2-2.2,5.3-1.5c1.8,0.7,2.9,2,3.1,4c0.2,2.2-0.9,3.8-2.7,4.8
                c0.8,2.2,0.4,4.2-1.5,5.7c-1.6,1.2-3.4,1.3-5.2,0.3s-2.6-2.6-2.4-4.7c-1.9-0.4-3.2-1.5-3.8-3.4c-0.4-1.2-0.2-2.4,0.4-3.6
                C2357.1,1067.2,2360.2,1066.2,2362.8,1067.8z M2356.8,1071.6c0,1.8,1.3,3.3,3,3.5c0.7,0.1,1.4,0,2.1-0.5c0.4-0.3,0.8-0.1,1,0.2
                c0.2,0.4,0.1,0.8-0.3,1c-0.3,0.2-0.7,0.3-1,0.4c-0.7,0.2-0.8,0.3-0.7,1c0,0.1,0,0.2,0,0.2c0.3,1.8,2.1,3.1,3.9,2.8
                c1.9-0.4,3.1-2.3,2.6-4.1c-0.2-0.6-0.2-0.6-0.8-0.7c-0.4,0-0.6-0.3-0.6-0.7c0-0.4,0.2-0.7,0.6-0.7c0.2,0,0.4,0,0.5,0
                c1.4-0.2,2.5-1.2,2.8-2.6c0.3-1.3-0.3-2.7-1.4-3.5c-1.1-0.7-2.7-0.7-3.8,0.1c-0.9,0.6-1.3,1.5-1.4,2.6c0,0.6-0.3,0.8-0.8,0.8
                c-0.4,0-0.7-0.3-0.7-0.8c0-0.4,0.1-0.9,0.2-1.3c0-0.2,0-0.3-0.1-0.3c-1.1-0.7-2.2-0.8-3.4-0.2
                C2357.4,1069.4,2356.8,1070.4,2356.8,1071.6z"/>
            </g>
          </g>
        </g>
        <g id="_x37_th_street_staples_1_">
          
            <rect x="543.9" y="776.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -398.8387 633.388)" className="st6" width="42.5" height="42.5"/>
          
            <rect x="538.4" y="775.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -400.2602 631.2842)" className="st36" width="47" height="47"/>
          
            <rect x="543.5" y="775.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -398.7655 634.8904)" className="st36" width="47" height="47"/>
          <g>
            <path className="st25" d="M610.9,777.6h15.5v3.3l-12.6,19.8l-2.8-1.8l9.9-17.1h-10L610.9,777.6L610.9,777.6z"/>
            <path className="st25" d="M629.7,775.8h11v2h-4.1v10.9h-2.7v-10.9h-4.1v-2H629.7z"/>
            <path className="st25" d="M653.1,783h-5.4v5.6H645v-13h2.7v5.3h5.4v-5.3h2.7v13h-2.7L653.1,783L653.1,783z"/>
            <path className="st25" d="M671.2,795.8c0.3,0.1,0.7,0.3,1.1,0.4c0.4,0.1,1,0.2,1.5,0.3c0.6,0.1,1.3,0.1,2,0.1c0.4,0,0.9-0.1,1.3-0.2
              c0.4-0.1,0.8-0.3,1.1-0.5s0.6-0.4,0.8-0.7s0.3-0.6,0.3-1s-0.1-0.8-0.3-1.2s-0.6-0.7-1-1s-0.9-0.7-1.4-1s-1.1-0.7-1.7-1
              c-0.5-0.3-1.1-0.7-1.7-1.1c-0.6-0.4-1.2-0.8-1.7-1.4c-0.5-0.5-0.9-1.2-1.3-1.9c-0.3-0.7-0.5-1.6-0.5-2.6c0-0.9,0.2-1.7,0.6-2.4
              c0.4-0.7,0.9-1.4,1.5-1.9s1.4-0.9,2.2-1.2s1.7-0.4,2.7-0.4c0.9,0,1.7,0.1,2.4,0.1c0.7,0.1,1.3,0.2,1.8,0.3s0.9,0.3,1.3,0.4
              c0.4,0.2,0.7,0.3,0.9,0.4l-1.3,3.2c-0.4-0.2-0.9-0.4-1.6-0.7c-0.7-0.2-1.5-0.4-2.3-0.4c-0.4,0-0.8,0-1.2,0.1s-0.7,0.2-1,0.3
              c-0.3,0.2-0.6,0.4-0.8,0.6c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.5,0.1,0.9,0.4,1.2c0.2,0.4,0.6,0.7,1,1s0.9,0.6,1.4,0.9
              c0.5,0.3,1,0.6,1.6,1c0.5,0.3,1.1,0.7,1.7,1.1c0.6,0.4,1.2,0.9,1.8,1.5s1,1.2,1.4,1.8c0.4,0.7,0.6,1.4,0.6,2.2
              c0,1.1-0.2,2.1-0.7,2.9c-0.5,0.8-1.1,1.6-1.9,2.1c-0.8,0.6-1.7,1-2.7,1.3s-2.1,0.4-3.2,0.4c-0.9,0-1.8-0.1-2.5-0.2
              c-0.7-0.1-1.4-0.2-1.9-0.4c-0.6-0.2-1-0.3-1.4-0.5s-0.7-0.3-0.9-0.5l1.3-3.1C670.6,795.6,670.9,795.7,671.2,795.8z"/>
            <path className="st25" d="M690.3,777.6h18.9v3.5h-7.1v18.7h-4.7V781h-7.1L690.3,777.6L690.3,777.6z"/>
            <path className="st25" d="M729.3,800.1l-6-9.9h-2.1v9.5h-4.7v-22.2h6.5c1.5,0,2.8,0.2,4,0.5c1.1,0.3,2,0.7,2.7,1.3
              c0.7,0.5,1.3,1.2,1.6,1.9c0.4,0.7,0.5,1.5,0.5,2.4c0,0.8-0.2,1.5-0.5,2.1s-0.7,1.2-1.1,1.6c-0.4,0.5-0.9,0.8-1.3,1.1
              c-0.5,0.3-0.8,0.5-1.1,0.6l6.8,10.6L729.3,800.1z M723.1,787.1c1.4,0,2.4-0.3,3-1c0.7-0.6,1-1.4,1-2.2c0-0.9-0.3-1.6-1-2.2
              c-0.6-0.6-1.7-0.9-3.1-0.9h-1.9v6.3L723.1,787.1L723.1,787.1z"/>
            <path className="st25" d="M755.7,799.8h-14.3v-22.2h13.9v3.5h-9.2v5.6h6.7v3.5h-6.7v6.2h9.6V799.8z"/>
            <path className="st25" d="M777.7,799.8h-14.3v-22.2h13.9v3.5h-9.2v5.6h6.7v3.5h-6.7v6.2h9.6V799.8z"/>
            <path className="st25" d="M783.6,777.6h18.9v3.5h-7.1v18.7h-4.7V781h-7.1L783.6,777.6L783.6,777.6z"/>
            <path className="st25" d="M609.8,817.8c0.2,0.1,0.3,0.1,0.6,0.2c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4,0,0.6-0.1
              c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.3,0.1-0.5s-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.4-0.5-0.5
              c-0.2-0.2-0.4-0.3-0.7-0.5s-0.5-0.3-0.8-0.5s-0.5-0.3-0.8-0.5s-0.6-0.4-0.8-0.7c-0.3-0.3-0.5-0.6-0.6-0.9
              c-0.2-0.4-0.2-0.8-0.2-1.3c0-0.4,0.1-0.8,0.3-1.2s0.4-0.7,0.8-0.9c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.9-0.2,1.3-0.2
              c0.4,0,0.8,0,1.2,0.1c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.1,0.5,0.2l-0.7,1.6
              c-0.2-0.1-0.5-0.2-0.8-0.3s-0.7-0.2-1.2-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.2-0.4,0.3
              c-0.1,0.1-0.1,0.3-0.1,0.5s0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.4,0.3,0.7,0.5s0.5,0.3,0.8,0.5s0.5,0.4,0.9,0.6
              c0.3,0.2,0.6,0.5,0.9,0.7c0.3,0.3,0.5,0.6,0.7,0.9c0.2,0.3,0.3,0.7,0.3,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.8-0.9,1.1
              s-0.8,0.5-1.4,0.7c-0.5,0.1-1,0.2-1.6,0.2c-0.5,0-0.9,0-1.2-0.1c-0.4-0.1-0.7-0.1-1-0.2c-0.3-0.1-0.5-0.2-0.7-0.3
              s-0.3-0.2-0.4-0.2l0.7-1.6C609.5,817.6,609.6,817.7,609.8,817.8z"/>
            <path className="st25" d="M622.3,808.6h9.4v1.7h-3.5v9.4h-2.3v-9.4h-3.5v-1.7H622.3z"/>
            <path className="st25" d="M637.3,819.7h-2.1l4.5-11.1h1.6l4.1,11.1H643l-0.9-2.5h-3.8L637.3,819.7z M640.2,812l-1.4,3.7h2.7L640.2,812
              z"/>
            <path className="st25" d="M652,819.7v-11.1h3.5c0.7,0,1.3,0.1,1.8,0.2c0.5,0.2,0.9,0.4,1.3,0.7c0.3,0.3,0.6,0.6,0.8,1s0.3,0.8,0.3,1.2
              c0,0.5-0.1,1-0.3,1.4s-0.5,0.8-0.9,1c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.2-1.6,0.2h-1.1v4.6L652,819.7L652,819.7z M655.2,813.5
              c0.2,0,0.5,0,0.7-0.1s0.5-0.2,0.7-0.3s0.3-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8s-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.5-0.5
              s-0.4-0.2-0.7-0.3c-0.2-0.1-0.5-0.1-0.7-0.1h-0.8v3.4h0.8V813.5z"/>
            <path className="st25" d="M669,818h4.1v1.7h-6.5v-11.1h2.3v9.4L669,818L669,818z"/>
            <path className="st25" d="M686.8,819.7h-7.1v-11.1h6.9v1.7H682v2.8h3.4v1.7H682v3.1h4.8V819.7z"/>
            <path className="st25" d="M694.3,817.8c0.2,0.1,0.3,0.1,0.6,0.2c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4,0,0.6-0.1
              c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.3,0.1-0.5s-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.4-0.5-0.5
              c-0.2-0.2-0.4-0.3-0.7-0.5s-0.5-0.3-0.8-0.5s-0.5-0.3-0.8-0.5s-0.6-0.4-0.8-0.7c-0.3-0.3-0.5-0.6-0.6-0.9
              c-0.2-0.4-0.2-0.8-0.2-1.3c0-0.4,0.1-0.8,0.3-1.2s0.4-0.7,0.8-0.9c0.3-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.9-0.2,1.3-0.2
              c0.4,0,0.8,0,1.2,0.1c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.1,0.5,0.2l-0.7,1.6
              c-0.2-0.1-0.5-0.2-0.8-0.3s-0.7-0.2-1.2-0.2c-0.2,0-0.4,0-0.6,0.1s-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.2-0.4,0.3
              c-0.1,0.1-0.1,0.3-0.1,0.5s0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.4,0.3,0.7,0.5s0.5,0.3,0.8,0.5s0.5,0.4,0.9,0.6
              c0.3,0.2,0.6,0.5,0.9,0.7c0.3,0.3,0.5,0.6,0.7,0.9c0.2,0.3,0.3,0.7,0.3,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.8-0.9,1.1
              s-0.8,0.5-1.4,0.7c-0.5,0.1-1,0.2-1.6,0.2c-0.5,0-0.9,0-1.2-0.1c-0.4-0.1-0.7-0.1-1-0.2c-0.3-0.1-0.5-0.2-0.7-0.3
              s-0.3-0.2-0.4-0.2l0.7-1.6C694.1,817.6,694.2,817.7,694.3,817.8z"/>
          </g>
          <g id="eI18kZ_1_">
            <g>
              <path className="st31" d="M547.8,801.3c0-0.1,0.1-0.2,0.1-0.2c0.9-3.1,4.4-4.4,7.2-2.7c0,0,0.1,0.1,0.2,0.1c1.1-1.3,2.5-1.9,4.2-1.7
                c1.4,0.1,2.4,0.8,3.3,1.9c1.6,2.1,1.2,5.7-1.9,7.2c0.8,2.2,0.4,4.1-1.5,5.6c-1.6,1.2-3.4,1.4-5.1,0.4c-1.9-1-2.6-2.6-2.5-4.8
                c-0.1,0-0.3-0.1-0.4-0.1c-1.8-0.5-2.9-1.6-3.5-3.4c0-0.1-0.1-0.1-0.1-0.2C547.8,802.6,547.8,802,547.8,801.3z M554.4,799.7
                c-0.7-0.5-1.6-0.8-2.5-0.6c-1.6,0.2-2.8,1.7-2.8,3.4c0,1.6,1.4,3,2.9,3.2c0.8,0.1,1.5,0,2.2-0.5c0.4-0.2,0.8-0.1,1,0.2
                c0.2,0.4,0.1,0.8-0.3,1c-0.5,0.2-1,0.4-1.4,0.6c-0.2,0.1-0.3,0.2-0.3,0.4c-0.1,1.4,0.6,2.7,1.8,3.2c1.3,0.6,2.5,0.5,3.6-0.4
                c1-0.8,1.4-2.2,1.1-3.5c-0.2-0.5-0.2-0.5-0.7-0.6c-0.4,0-0.7-0.3-0.7-0.7s0.2-0.7,0.7-0.7c0.1,0,0.3,0,0.4,0
                c1.9-0.2,3.2-1.9,2.9-3.8c-0.3-1.8-2.2-3.1-4-2.7c-1.5,0.3-2.5,1.6-2.6,3.2c0,0.5-0.3,0.8-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.8
                C554.2,800.8,554.3,800.3,554.4,799.7z"/>
              <path className="st31" d="M581.7,803.7c-0.1,0.2-0.1,0.5-0.2,0.7c-0.5,1.7-1.7,2.9-3.4,3.3c-1.8,0.4-3.4-0.1-4.6-1.5
                c0,0-0.1-0.1-0.1-0.2c0,0,0,0-0.1-0.1c-1.4,0.9-3,1.2-4.6,0.6c-1.3-0.5-2.1-1.4-2.5-2.7c-0.9-2.4,0.1-5.5,3.6-6.3
                c-0.1-1.8,0.4-3.4,2-4.4c1.1-0.7,2.3-1,3.5-0.7c3.1,0.7,4.5,3.8,3.5,6.3c0,0,0,0.1,0.1,0.1c1.6,0.7,2.5,1.9,2.8,3.6
                c0,0.1,0,0.1,0.1,0.1C581.7,803,581.7,803.3,581.7,803.7z M574.3,805.1c0,0,0.1,0.1,0.2,0.2c0.8,1,2.2,1.4,3.5,1s2.1-1.5,2.2-2.9
                c0.1-1.3-0.7-2.5-1.8-3.1c-0.3-0.2-0.6-0.2-0.8,0.1c-0.3,0.3-0.7,0.3-1,0.1c-0.3-0.3-0.3-0.7,0.1-1.1c0.9-1,1.2-2.2,0.6-3.5
                c-0.6-1.3-1.6-2-3-2s-2.5,0.7-3,2c-0.6,1.3-0.3,2.6,0.7,3.6c0.3,0.3,0.3,0.7,0,1s-0.7,0.3-1-0.1c-0.3-0.3-0.6-0.7-0.8-1.1
                c-0.1-0.2-0.2-0.3-0.5-0.2c-1.5,0.3-2.6,1.8-2.6,3.3c0.1,1.5,1.4,3,2.8,3.2c1.8,0.3,3.5-1.1,3.7-2.6c0.1-0.5,0.5-0.7,0.9-0.6
                c0.4,0.1,0.6,0.5,0.5,0.9C574.8,804,574.6,804.5,574.3,805.1z"/>
              <path className="st31" d="M562.7,782.6c1.5-1.6,3.2-2.2,5.3-1.5c1.8,0.7,2.9,2,3.1,4c0.2,2.2-0.9,3.8-2.7,4.8
                c0.8,2.2,0.4,4.2-1.5,5.7c-1.6,1.2-3.4,1.3-5.2,0.3s-2.6-2.6-2.4-4.7c-1.9-0.4-3.2-1.5-3.8-3.4c-0.4-1.2-0.2-2.4,0.4-3.6
                C557.1,781.9,560.1,780.9,562.7,782.6z M556.7,786.3c0,1.8,1.3,3.3,3,3.5c0.7,0.1,1.4,0,2.1-0.5c0.4-0.3,0.8-0.1,1,0.2
                c0.2,0.4,0.1,0.8-0.3,1c-0.3,0.2-0.7,0.3-1,0.4c-0.7,0.2-0.8,0.3-0.7,1c0,0.1,0,0.2,0,0.2c0.3,1.8,2.1,3.1,3.9,2.8
                c1.9-0.4,3.1-2.3,2.6-4.1c-0.2-0.6-0.2-0.6-0.8-0.7c-0.4,0-0.6-0.3-0.6-0.7s0.2-0.7,0.6-0.7c0.2,0,0.4,0,0.5,0
                c1.4-0.2,2.5-1.2,2.8-2.6c0.3-1.3-0.3-2.7-1.4-3.5c-1.1-0.7-2.7-0.7-3.8,0.1c-0.9,0.6-1.3,1.5-1.4,2.6c0,0.6-0.3,0.8-0.8,0.8
                c-0.4,0-0.7-0.3-0.7-0.8c0-0.4,0.1-0.9,0.2-1.3c0-0.2,0-0.3-0.1-0.3c-1.1-0.7-2.2-0.8-3.4-0.2
                C557.4,784.2,556.8,785.2,556.7,786.3z"/>
            </g>
          </g>
        </g>
        <g className="st12">
          <g className="st13">
            <text transform="matrix(1 0 0 1 2641.8943 1106.5386)" className="st3 st4 st5">Bud Light</text>
            <text transform="matrix(1 0 0 1 2657.0942 1142.5386)" className="st3 st4 st5">Gallery</text>
          </g>
          <g className="st13">
            <rect x="2661.2" y="982.9" className="st37" width="85.8" height="85.8"/>
            <g>
              <path className="st38" d="M2735.6,1007h-62.9c-1.1,0-1.9,0.8-1.9,1.9v33.6c0,1.1,0.7,1.9,1.9,1.9h62.9c1.1,0,1.9-0.7,1.9-1.9v-33.7
                C2737.5,1007.8,2736.7,1007,2735.6,1007 M2735.5,1008.2c0.5,0,0.7,0.3,0.7,0.7v33.6c0,0.5-0.3,0.7-0.7,0.7h-62.9
                c-0.5,0-0.7-0.3-0.7-0.7v-33.6c0-0.5,0.3-0.7,0.7-0.7H2735.5z"/>
              <path className="st6" d="M2732,1039.8c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8
                S2732.4,1039.8,2732,1039.8 M2732,1038.1c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1
                C2732.9,1038.5,2732.5,1038.1,2732,1038.1 M2731.8,1039v-0.3h0.3c0.1,0,0.2,0,0.2,0.1s-0.1,0.2-0.2,0.2L2731.8,1039L2731.8,1039z
                M2732.5,1038.9c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1h-0.5v1h0.2v-0.4h0.2l0.2,0.4h0.2l-0.3-0.4
                C2732.4,1039.1,2732.5,1039,2732.5,1038.9 M2690,1019.2c0.6,0,1.3,0.3,1.3,1.4c0,1.2-0.6,1.5-1.3,1.5h-1.6v-2.9H2690L2690,1019.2
                z M2689.9,1014.3c0.7,0,1.2,0.4,1.2,1.3c0,1.1-0.5,1.4-1.2,1.4h-1.4v-2.7H2689.9z M2691.6,1025c2.4,0,4.5-1.2,4.5-4
                c0-1.7-1.1-2.8-2.3-3.2c1.2-0.6,1.9-1.5,1.9-2.8c0-2.2-1.6-3.4-4.1-3.4h-8.2v13.4H2691.6z M2716.9,1014.5c1,0,2.6,0.1,2.6,3.6
                c0,3.6-1.5,3.8-2.6,3.8h-0.5v-7.5h0.5V1014.5z M2718,1025c3.5,0,6.8-2,6.8-6.8c0-4.7-3-6.6-6.6-6.6h-6.9v13.4H2718z M2684.8,1040
                v-3.3h-4.3v-10.1h-5.2v13.4H2684.8z M2685.9,1040h5.3v-13.4h-5.3V1040z M2700.1,1040.4c2.1,0,4-0.4,5.6-1.1v-7h-6.2v2.7h1.6v2.3
                c-0.2,0-0.5,0.1-0.8,0.1c-2,0-2.7-1.6-2.7-4c0-2.6,1.1-3.7,2.7-3.7c1.3,0,2.4,0.6,3.2,1.3l2.3-2.7c-1.5-1.3-3.3-2-5.8-2
                c-4.5,0-7.6,2.6-7.6,7.1C2692.5,1038.2,2695.3,1040.4,2700.1,1040.4 M2720.1,1040v-13.4h-5.1v4.9h-2.8v-4.9h-5.1v13.4h5.1v-5.2
                h2.8v5.2H2720.1z M2703.5,1025.2c4.2,0,6.2-1.5,6.2-5.1v-8.5h-5v9c0,1-0.6,1.4-1.3,1.4s-1.3-0.4-1.3-1.4v-9h-5.1v8.5
                C2697.1,1023.4,2699.1,1025.2,2703.5,1025.2 M2729.7,1029.7v10.2h-5.3v-10.2h-3.2v-3.2h11.7v3.2H2729.7z"/>
            </g>
          </g>
        </g>
        <g id="XMLID_1_">
          <path id="XMLID_7_" className="st39" d="M1489.4,285.2c0-5,0-10,0-15.1c2-0.9,3.9-1.7,5.8-2.6c0-9.6,0-19.2,0-28.9
            c-1.1,0.4-2.1,0.7-3.1,1.1c0,2.7,0,5.3,0,7.8c-4.6,2-9,4-13.5,5.9c0-7.7,0-15.4,0-23.2c14.6-6.1,29.8-10.2,45.5-12.3
            c0.4,1,0.5,2.1,0.5,3.1c0,2.1,0,4.3,0,6.4c0,0.1,0,0.1,0.1,0.2c0.2-0.2,0.4-0.3,0.5-0.5c3.3-3.3,6.6-6.7,9.9-10
            c0.2-0.2,0.5-0.3,0.8-0.3c4-0.1,7.9-0.1,11.9-0.1c1.8,0,3.6,0.1,5.4,0.2c0.3,0,0.7,0.2,0.9,0.5c3.2,3.2,6.5,6.5,9.7,9.7
            c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0,0.1,0,0.2,0c0-3.2,0-6.3,0-9.5c7.7,1,15.2,2.5,22.7,4.3c0,4.7,0,9.4,0,14.2c-1.5-0.4-3-0.7-4.6-1.1
            c0,0.8-0.1,1.6-0.1,2.4c0,6.9,0,13.8,0,20.6c0,0.4,0.1,0.8,0.3,1.2c0.8,1.5,1.7,2.9,2.6,4.4c0.1,0.2,0.3,0.4,0.5,0.5
            c2.1,0.8,4.2,1.5,6.3,2.3c1-0.9,2-1.8,3.1-2.7c0-8.3,0-16.5,0-24.9c-1.2-0.4-2.5-0.9-3.8-1.4c0-4.8,0-9.5,0-14.4
            c0.6,0.2,1.1,0.3,1.6,0.5c6.9,2.3,13.7,4.7,20.3,7.8c0.1,0,0.2,0,0.3,0.1c0,5.1,0,10.2,0,15.3c-1.4-0.7-2.7-1.3-4.1-2
            c-0.2-0.1-0.5-0.2-0.9-0.4c-0.1,8.6-0.1,17.1-0.2,25.6c-4.8,4.1-9.7,8.3-14.4,12.5c-2.7-1.2-5.2-2.4-7.8-3.4
            c-2.9-1.1-5.8-2.1-8.7-3.1c-0.5-0.2-0.8-0.4-1.1-0.9c-2.6-4.3-5.2-8.6-7.7-12.8c-0.1-0.2-0.2-0.6-0.2-0.8c0-3.6,0-7.2,0-10.9
            c0-5.9,0.1-11.8,0.1-17.7c0-0.2,0-0.5,0-0.8c-1.2-0.2-2.3-0.4-3.5-0.6c0,2.9,0,5.8,0,8.7c-4.4-0.4-8.8-0.9-13.3-1.3
            c0-1.6,0-3.3,0-5.3c-1.2-1.1-2.5-2.4-3.9-3.6c-0.2-0.2-0.6-0.3-0.9-0.3c-2,0-4,0-5.9,0c-1.3,1.3-2.6,2.5-3.9,3.8
            c-0.2,0.2-0.2,0.5-0.2,0.7c0,3.2-0.1,6.4-0.1,9.6c0,3.4,0,6.8,0,10.2c0.9,0.9,1.8,1.9,2.7,2.8c2.9,0,5.8,0,8.6,0
            c1.1-1.1,2.2-2.2,3.4-3.3c0-2,0-3.9,0-6c4.4,0.5,8.8,0.9,13.2,1.3c0,3.6,0,7.1,0,10.8c-3.7,3.7-7.5,7.4-11.4,11.2
            c-6.3-0.7-12.7-0.5-18.8-0.1c-2.3-2.2-4.5-4.3-6.6-6.3c-1.4-1.4-2.7-2.8-4.1-4.2c-0.4-0.4-0.6-0.9-0.6-1.4c0-5.4,0-10.8,0-16.2
            c0-1.3,0-2.5,0-4c-3.9,0.7-7.8,1.5-11.8,2.2c0-2.5,0-5,0-7.6c-1.1,0.3-2.1,0.5-3.2,0.8c0,9.3,0,18.6,0,27.9c2-0.5,4-1.1,6.1-1.7
            c0,4.8,0,9.5,0,14.2c0,0-0.2,0.1-0.3,0.1c-7.2,1.9-14.1,4.8-20.8,8c-1.2,0.6-2.4,1.2-3.6,1.8
            C1489.8,285.2,1489.6,285.2,1489.4,285.2z M1569.5,232.1c0,0.3,0,0.6,0,0.8c0,3.9-0.1,7.7-0.1,11.6c0,5.8-0.1,11.5-0.1,17.3
            c0,0.3,0.1,0.7,0.3,1c1.9,3.2,3.9,6.4,5.8,9.6c0.7,1.1,1.4,2.2,2,3.3c5.7,1.7,11.2,3.8,16.5,6.4c4.6-4,9.2-7.9,13.8-11.9
            c0.1-8.8,0.2-17.6,0.2-26.6c1.7,0.8,3.3,1.5,4.9,2.3c0-4.5,0-8.9,0-13.3c-2.9-1.7-18.9-7.7-20.6-7.7c0,4.1,0,8.3,0,12.4
            c1.3,0.5,2.6,0.9,3.8,1.4c0,8.7,0,17.3,0,25.9c-1.3,1.2-2.6,2.3-3.8,3.3c-2.4-0.9-4.7-1.7-7-2.6c-0.2-0.1-0.5-0.3-0.6-0.5
            c-1-1.6-2-3.3-3-5c-0.2-0.3-0.3-0.7-0.3-1c0-3.3,0-6.5,0-9.8c0-4.7,0.1-9.4,0.1-14c0-0.2,0-0.4,0.1-0.6c1.6,0.4,3,0.7,4.6,1.1
            c0-4.2,0-8.3,0-12.5c-6.9-1.6-13.9-3.1-21-4c0,0.6,0,1.1,0,1.6c0,3.1-0.1,6.2-0.1,9.3c0,0.4,0.1,0.9,0.2,1.3
            C1566.7,231.6,1568.1,231.8,1569.5,232.1z M1491.3,247c0-2.5,0-5.2,0-7.9c1.6-0.6,3.2-1.1,4.8-1.7c0,10.3,0,20.4,0,30.6
            c-2,0.9-3.9,1.7-5.8,2.6c0,4.5,0,8.9,0,13.4c7.6-3.9,15.4-7.2,23.6-9.5c-0.2-4.2,0.1-8.2-0.1-12.5c-2.1,0.6-4.1,1.2-6.1,1.7
            c0-9.9,0-19.7,0-29.7c1.6-0.4,3.2-0.8,4.9-1.2c0,2.6,0,5.1,0,7.6c3.7-0.7,7.3-1.3,10.9-2c0-0.2,0-0.4,0-0.6
            c0-5.8,0.1-11.7,0.1-17.5c0-0.5-0.1-1-0.2-1.5c-15.3,2.1-29.9,6-44,12c0,7.1,0,14.2,0,21.3C1483.4,250.5,1487.3,248.8,1491.3,247z
            M1540.7,229.6c2.5,0,5,0,7.2,0c1.7,1.6,3.2,3.1,4.8,4.6c0,1.6,0,3.1,0,4.6c3.9,0.4,7.7,0.8,11.5,1.1c0-3.7,0-7.2,0-10.8
            c-3.7-3.7-7.5-7.4-11.4-11.3c-5.6,0-11.4,0-17.2,0c-3.8,3.8-7.5,7.6-11.2,11.3c0,0.1,0,0.3,0,0.4c0,5.6-0.1,11.2-0.1,16.8
            c0,4.4,0,8.7,0,13.1c0,0.5,0.2,0.9,0.5,1.2c1.6,1.6,3.2,3.3,4.9,5c1.7,1.7,3.4,3.3,5.2,5.1c6-0.4,12.2-0.5,18.3,0.1
            c3.7-3.7,7.3-7.2,10.9-10.8c0-3.2,0-6.3,0-9.5c-3.8-0.4-7.6-1-11.5-1.2c0,1.8,0,3.6,0,5.4c-1.3,1.2-2.6,2.4-4,3.7
            c-2.9,0-6.1,0-9.2,0c-1.1-1.1-2.1-2.3-3.2-3.4c0.1-6.9,0.2-14,0.2-21.2C1537.7,232.5,1539.2,231,1540.7,229.6z"/>
          <path id="XMLID_10_" className="st6" d="M1569.5,232.1c-1.4-0.2-2.8-0.5-4.2-0.7c-0.1-0.5-0.2-0.9-0.2-1.3c0-3.1,0.1-6.2,0.1-9.3
            c0-0.5,0-1,0-1.6c7.2,0.9,14.1,2.3,21,4c0,4.1,0,8.3,0,12.5c-1.5-0.4-3-0.7-4.6-1.1c0,0.2-0.1,0.4-0.1,0.6c0,4.7-0.1,9.4-0.1,14
            c0,3.3,0,6.5,0,9.8c0,0.3,0.1,0.7,0.3,1c1,1.7,2,3.3,3,5c0.1,0.2,0.4,0.4,0.6,0.5c2.3,0.9,4.6,1.7,7,2.6c1.2-1,2.5-2.2,3.8-3.3
            c0-8.6,0-17.2,0-25.9c-1.2-0.4-2.5-0.9-3.8-1.4c0-4.2,0-8.3,0-12.4c1.6,0,17.6,6.1,20.6,7.7c0,4.4,0,8.8,0,13.3
            c-1.7-0.8-3.2-1.5-4.9-2.3c-0.1,8.9-0.2,17.7-0.2,26.6c-4.6,4-9.2,7.9-13.8,11.9c-5.3-2.7-10.8-4.7-16.5-6.4
            c-0.7-1.1-1.4-2.2-2-3.3c-1.9-3.2-3.9-6.4-5.8-9.6c-0.2-0.3-0.3-0.6-0.3-1c0-5.8,0-11.5,0.1-17.3c0-3.9,0.1-7.7,0.1-11.6
            C1569.5,232.6,1569.5,232.4,1569.5,232.1z M1597.3,265.3c-1.7,1.4-3.3,2.8-4.8,4.2c-2.8-1-5.5-2-8.1-3c-0.2-0.1-0.4-0.3-0.5-0.5
            c-1.1-1.9-2.3-3.7-3.4-5.6c-0.2-0.3-0.3-0.7-0.3-1.1c0-4.7,0-9.5,0-14.2c0-3.4,0.1-6.8,0.1-10.2c0-0.7,0-1.3,0-2.1
            c1.6,0.4,3.1,0.7,4.6,1.1c0-3.3,0-6.6,0-9.9c-6.1-1.4-12.2-2.7-18.5-3.6c0,3.2,0,6.4,0,9.6c1.4,0.3,2.9,0.5,4.4,0.8
            c0,0.4,0,0.6,0,0.8c0,2.5-0.1,5-0.1,7.5c0,7.4-0.1,14.7-0.1,22.1c0,0.3,0.1,0.6,0.2,0.9c1.6,2.6,3.2,5.2,4.7,7.9
            c0.9,1.5,1.8,3,2.7,4.5c5.3,1.6,10.5,3.6,15.5,6c4.3-3.7,8.5-7.3,12.8-11c0.1-9.3,0.2-18.5,0.2-27.8c1.7,0.7,3.2,1.4,4.9,2.2
            c0-3.5,0-6.9,0-10.4c-2-1.2-16.2-6.6-18-6.8c0,3.3,0,6.6,0,9.9c1.3,0.5,2.5,0.9,3.8,1.4C1597.3,247.1,1597.3,256.2,1597.3,265.3z"
            />
          <path id="XMLID_13_" className="st6" d="M1491.3,247c-4,1.8-7.9,3.4-11.8,5.2c0-7.1,0-14.2,0-21.3c14.1-5.9,28.8-9.9,44-12
            c0.1,0.5,0.2,1,0.2,1.5c0,5.8,0,11.7-0.1,17.5c0,0.2,0,0.4,0,0.6c-3.6,0.7-7.2,1.3-10.9,2c0-2.5,0-5,0-7.6
            c-1.7,0.4-3.3,0.8-4.9,1.2c0,9.9,0,19.8,0,29.7c2-0.6,4-1.1,6.1-1.7c0.2,4.2-0.1,8.3,0.1,12.5c-8.2,2.3-16,5.6-23.6,9.5
            c0-4.5,0-8.9,0-13.4c1.9-0.8,3.8-1.7,5.8-2.6c0-10.2,0-20.3,0-30.6c-1.6,0.6-3.2,1.1-4.8,1.7C1491.3,241.8,1491.3,244.5,1491.3,247
            z M1490,238.4c2.3-1.1,4.7-1.9,7.3-2.4c0,11.1,0,22,0,32.9c-1.9,0.8-3.8,1.7-5.7,2.5c0,3.5,0,7,0,10.6c6.8-3.4,13.8-6.3,21.1-8.4
            c-0.2-3.3,0.1-6.6,0-9.9c-2.2,0.6-4.2,1.2-6.2,1.8c0-10.8,0-21.5,0-32.3c2.3-0.6,4.6-1.1,6.8-1.7c0.5,0.3,0.6,0.7,0.6,1.2
            c0,1.9,0,3.7,0,5.6c0,0.2,0,0.5,0,0.8c2.9-0.5,5.6-1,8.5-1.5c0-5.7,0-11.4,0-17.1c-14.4,2.1-28.2,5.8-41.6,11.4
            c0,6.2,0,12.3,0,18.5c3.1-1.4,6.2-2.7,9.3-4C1490,243.7,1490,241.2,1490,238.4z"/>
          <path id="XMLID_16_" className="st6" d="M1540.7,229.6c-1.5,1.4-3,2.8-4.4,4.2c-0.1,7.2-0.2,14.2-0.2,21.2c1.1,1.2,2.2,2.3,3.2,3.4
            c3.2,0,6.3,0,9.2,0c1.4-1.3,2.7-2.5,4-3.7c0-1.8,0-3.6,0-5.4c3.9,0.2,7.7,0.7,11.5,1.2c0,3.2,0,6.3,0,9.5
            c-3.6,3.6-7.2,7.1-10.9,10.8c-6.1-0.6-12.2-0.5-18.3-0.1c-1.8-1.7-3.5-3.4-5.2-5.1c-1.6-1.6-3.2-3.3-4.9-5
            c-0.4-0.4-0.5-0.7-0.5-1.2c0-4.4,0-8.7,0-13.1c0-5.6,0.1-11.2,0.1-16.8c0-0.1,0-0.3,0-0.4c3.7-3.8,7.4-7.5,11.2-11.3
            c5.8,0,11.6,0,17.2,0c3.9,3.9,7.6,7.6,11.4,11.3c0,3.6,0,7.2,0,10.8c-3.9-0.4-7.7-0.8-11.5-1.1c0-1.5,0-3,0-4.6
            c-1.5-1.5-3.1-2.9-4.8-4.6C1545.7,229.6,1543.2,229.6,1540.7,229.6z M1552.7,269.5c3.4-3.4,6.8-6.7,10.1-10c0-2.6,0-5.2,0-7.9
            c-3-0.3-6-0.6-9.1-1c0,1.6,0,3.1,0,4.7c-1.5,1.5-3.1,3-4.6,4.4c-3.5,0-6.9,0-10.4,0c-1.3-1.3-2.6-2.7-3.9-4.2
            c0.1-7.2,0.1-14.5,0.2-22.2c1.7-1.5,3.5-3.2,5.3-4.9c2.5,0,5.2,0,7.8,0c0.2,0,0.6,0.1,0.7,0.3c1,0.9,1.9,1.9,2.8,2.9
            c0.7,0.7,1.4,1.5,2.2,2.3c0,1.3,0,2.6,0,3.8c3,0.3,6,0.6,9,0.9c0-3,0-6,0-9.1c-3.3-3.3-6.8-6.7-10.2-10.1c-0.2-0.2-0.4-0.2-0.7-0.3
            c-1.8-0.1-3.7-0.2-5.5-0.2c-3.4,0-6.9,0-9.9,0.1c-3.8,3.7-7.4,7.2-11,10.6c0,10,0,20,0,30c3.2,3.2,6.4,6.5,9.6,9.8
            C1541.1,269.1,1546.9,268.9,1552.7,269.5z"/>
          <path id="XMLID_17_" className="st39" d="M1597.3,265.3c0-9.1,0-18.2,0-27.4c-1.2-0.4-2.5-0.9-3.8-1.4c0-3.3,0-6.6,0-9.9
            c1.9,0.2,16,5.6,18,6.8c0,3.5,0,6.9,0,10.4c-1.7-0.7-3.2-1.4-4.9-2.2c-0.1,9.4-0.2,18.6-0.2,27.8c-4.2,3.7-8.5,7.3-12.8,11
            c-5-2.5-10.2-4.4-15.5-6c-0.9-1.5-1.8-3-2.7-4.5c-1.6-2.6-3.2-5.2-4.7-7.9c-0.2-0.3-0.2-0.6-0.2-0.9c0-7.4,0.1-14.7,0.1-22.1
            c0-2.5,0.1-5,0.1-7.5c0-0.2,0-0.5,0-0.8c-1.5-0.3-2.9-0.5-4.4-0.8c0-3.2,0-6.4,0-9.6c6.3,0.9,12.4,2.1,18.5,3.6c0,3.3,0,6.5,0,9.9
            c-1.5-0.4-3-0.7-4.6-1.1c0,0.7,0,1.4,0,2.1c0,3.4-0.1,6.8-0.1,10.2c0,4.7,0,9.5,0,14.2c0,0.4,0.1,0.8,0.3,1.1
            c1.1,1.9,2.2,3.8,3.4,5.6c0.1,0.2,0.3,0.4,0.5,0.5c2.7,1,5.3,2,8.1,3C1594,268.2,1595.7,266.8,1597.3,265.3z"/>
          <path id="XMLID_18_" className="st39" d="M1490,238.4c0,2.7,0,5.3,0,7.8c-3.1,1.4-6.2,2.7-9.3,4c0-6.2,0-12.3,0-18.5
            c13.4-5.5,27.3-9.3,41.6-11.4c0,5.7,0,11.4,0,17.1c-2.8,0.5-5.6,1-8.5,1.5c0-0.3,0-0.5,0-0.8c0-1.9,0-3.7,0-5.6
            c0-0.5-0.1-0.8-0.6-1.2c-2.2,0.5-4.5,1.1-6.8,1.7c0,10.8,0,21.5,0,32.3c2-0.6,4-1.2,6.2-1.8c0.1,3.3-0.2,6.6,0,9.9
            c-7.3,2.1-14.2,5-21.1,8.4c0-3.6,0-7.1,0-10.6c1.9-0.8,3.8-1.6,5.7-2.5c0-11,0-21.9,0-32.9C1494.7,236.5,1492.4,237.3,1490,238.4z"
            />
          <path id="XMLID_19_" className="st39" d="M1552.7,269.5c-5.8-0.5-11.6-0.4-17.5-0.1c-3.2-3.3-6.4-6.6-9.6-9.8c0-10,0-19.9,0-30
            c3.5-3.4,7.1-6.9,11-10.6c3,0,6.5-0.1,9.9-0.1c1.8,0,3.7,0.1,5.5,0.2c0.2,0,0.5,0.1,0.7,0.3c3.4,3.4,6.8,6.8,10.2,10.1
            c0,3.1,0,6.1,0,9.1c-3-0.3-6-0.6-9-0.9c0-1.3,0-2.5,0-3.8c-0.8-0.8-1.5-1.5-2.2-2.3c-0.9-1-1.9-1.9-2.8-2.9
            c-0.2-0.2-0.5-0.3-0.7-0.3c-2.7,0-5.3,0-7.8,0c-1.9,1.7-3.7,3.4-5.3,4.9c-0.1,7.6-0.1,15-0.2,22.2c1.4,1.5,2.7,2.9,3.9,4.2
            c3.5,0,7,0,10.4,0c1.5-1.4,3.1-2.9,4.6-4.4c0-1.6,0-3.1,0-4.7c3,0.3,6,0.7,9.1,1c0,2.7,0,5.2,0,7.9
            C1559.5,262.7,1556.2,266.1,1552.7,269.5z"/>
        </g>
        <text transform="matrix(1 0 0 1 2663.0999 1056.3899)" className="st3 st4 st5">North</text>
        <text transform="matrix(1 0 0 1 2663.0999 1092.3899)" className="st3 st4 st5">Lobby</text>
      </svg>

    </div>
  )
}

export default MainLevel;
