import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';

import Footer from '../components/Footer';
import OtherEventsSlider from '../components/OtherEventsSlider';
import Generic from './event-types/Generic';
import Basketball from './event-types/Basketball';

function EventDetail(props) {
  const { match } = props;
  const [ eventDetails, setEventDetails ] = useState();
  const [ eventComp, setEventComp ] = useState();

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
    
    axios.get(`http://35.153.188.133/wp-json/wp/v2/event/${ match.params.id }`)
      .then(res => {
        setEventDetails(res.data.acf);
      })
      .catch(err => console.log(err));
  }, [ match ]);

  useEffect(() => {
    if (eventDetails) {
      if (eventDetails.event_type === 'Basketball') {
        setEventComp(<Basketball eventDetails={ eventDetails } />);
      } else {
        setEventComp(<Generic eventDetails={ eventDetails } />);
      }
    }
  }, [ eventDetails ])

  useEffect(() => {
    $(".readMore").click(function(e){
      $(".eventContent").addClass("full")
    })
  })

  return (
    <div className='event-detail'>
      { eventComp && (
        <>
          { eventComp }

          <OtherEventsSlider events={ eventDetails.related_events } />
        </>
      )}
        
      <Footer />
    </div>
  )
}

export default EventDetail;
