import React from 'react';

import Player from './Player';

function Roster(props) {
  const { players } = props;

  return (
    <div>
      { players && players.map(player => <Player player={ player } key={ player.player_id } /> ) }
    </div>
  )
}

export default Roster;