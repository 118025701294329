import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

function Generic(props) {
  const { eventDetails } = props;
  // const [ readFull, setReadFull ] = useState(false);
  
  return (
    <div className='wrapper events'>
      <div className='masthead'>
        <img src={ eventDetails.event_image.url } alt={ eventDetails.title } />
        <div className='eventTitle'>
          <h1>{ eventDetails.event_title }</h1>
          <h2>{ moment(`${ eventDetails.event_date } ${ eventDetails.event_time }`, 'D/M/YYYY HH:mm:ss').format('dddd, MMM D - h:mm A') }</h2>
          <a href={ eventDetails.ticket_link } target='_blank' rel='noopener noreferrer' className='btn btn-primary ticketsBtnTablet'>Purchase Tickets</a>
        </div>
      </div>

      <div className='eventContainer'>
        <div className='eventContent'>
          <a href={ eventDetails.ticket_link } className='btn btn-primary btn-block ticketsBtnMobile'>Purchase Tickets</a>
          <div dangerouslySetInnerHTML={ { __html: eventDetails.event_description } }></div>
          <div className='readMore'>
            <span>Read More</span>
          </div>
        </div>
        <div className='seperator'></div>
        <div className='eventsNav'>
            <Link to='/seating-chart' className='nav-item'><img src='/img/icon-section.png' alt='section' />Find Your Section</Link>
            <Link to='/concourse-map' className='nav-item'><img src='/img/dining-room.png' alt='dining' />View Concessions</Link>
            <Link to='/directions' className='nav-item'><img src='/img/icons-waze.png' alt='waze' />Directions</Link>
          </div>
      </div>
    </div>
  )
}

export default Generic;