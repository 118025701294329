import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import axios from 'axios';
import history from '../utilities/history';
import '../styles/app.scss';

import Routes from './Routes';
import Header from '../components/Header';

function App() {
  const [ pages, setPages ] = useState();

  useEffect(() => {
    function getPageFields() {
      if (!pages) {
        axios.get(`http://35.153.188.133/wp-json/acf/v3/pages?per_page=25`)
          .then(res => {
            if (res.data) {
              let pages = {};

              res.data.forEach(page => {
                if (page.acf.hasOwnProperty('landing_seat_link')) {
                  pages.landing = page.acf;
                } else if (page.acf.hasOwnProperty('section_1')) {
                  pages.about = page.acf;
                } else if (page.acf.hasOwnProperty('concessions_header')) {
                  pages.concessions = page.acf;
                } else if (page.acf.hasOwnProperty('guest_services_header')) {
                  pages.guest = page.acf;
                } else if (page.acf.hasOwnProperty('upcoming_events_header')) {
                  pages.upcoming = page.acf;
                } else if (page.acf.hasOwnProperty('venue_map_header')) {
                  pages.venue = page.acf;
                } else if (page.acf.hasOwnProperty('directions_header')) {
                  pages.directions = page.acf;
                } else if (page.acf.hasOwnProperty('seating_header')) {
                  pages.seating = page.acf;
                } else if (page.acf.hasOwnProperty('concourse_header')) {
                  pages.concourse = page.acf;
                }
              });

              setPages(pages);
            }
          })
          .catch(err => {
            console.log(err);
            getPageFields();
          });
      }
    }

    getPageFields();
    ReactGA.initialize('UA-72166031-24');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [ pages ]);

  

  return (
    <Router basename={ process.env.PUBLIC_URL } history={ history } >
      <div className='app'>
        <Header
          history={ history } />

        <div id='header-gap'></div>

        {pages ? (
          <Routes pages={ pages } />
        ) : (
          <div className='loading'>
            <img className='animated infinite pulse' src='/img/a-rena-logo.png' alt='Dickies Arena' />
            <div>
              Loading...
            </div>
          </div>
        )}
        
      </div>
    </Router>
  );
}

export default App;
