import React, { useState } from 'react';

import StatsExpander from './stats/StatsExpander';
import Points from './stats/basketball/Points';
import Defense from './stats/basketball/Defense';
import Other from './stats/basketball/Other';

function Stats(props) {
  const { eventDetails } = props;
  const [ selectHome, setSelectHome ] = useState(true);

  return (
    <div className='sport__stats'>
      <div className='sport__stats__nav'>
        <div
          className={ selectHome ? 'active' : '' }
          onClick={ e => setSelectHome(true)}>
          { eventDetails.home_team_letters || 'HOME' }
        </div>

        <div
          className={ !selectHome ? 'active' : '' }
          onClick={ e => setSelectHome(false) }>
          { eventDetails.away_team_letters || 'AWAY' }
        </div>
      </div>

      <div className='sport__stats__body'>
        <StatsExpander title='POINTS'>
          <Points showHome={ selectHome } />
        </StatsExpander>

        <StatsExpander title='DEFENSE'>
          <Defense showHome={ selectHome } />
        </StatsExpander>

        <StatsExpander title='OTHER'>
          <Other showHome={ selectHome } />
        </StatsExpander>
      </div>
    </div>
  )
}

export default Stats;