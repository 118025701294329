import React from 'react';
import { Link } from 'react-router-dom';

function LandingCard(props) {
  const { card } = props;
  
  return (
    <Link to={ card.path }>
      { card.hasOwnProperty('image') ? (
        <div className='landing-card'>

          <div className='landing-card__left'>
            <h2>{ card.image.alt }</h2>
            <p>{ card.image.caption }</p>
          </div>

          <div className='landing-card__right'>
            <img src={ card.image.url } alt={ card.image.alt } />
          </div>
        </div>
      ) : (
        <div className='landing-card minimal'>
          <div className='landing-card__left'>
            <h2>{ card.title }</h2>
          </div>

          <div className='landing-card__right'>
            <img src='/img/arrow-right-deco.png' alt='arrow-right' />
          </div>
        </div>
      )}
      
    </Link>
  )
}

export default LandingCard;