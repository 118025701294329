import React from 'react';

function PageTitle(props) {
  const { title, withInfo, handleWithInfo } = props;

  return (
    <div className='page-title'>
      <h2>{ title }</h2>

      {withInfo && (
        <img src='/img/info-icon.png' onClick={ e => handleWithInfo() } alt='Info' />
      )}

    </div>
  )
}

export default PageTitle;