import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

function SectionSearch(props) {
  const { 
    sections, 
    focusedSection, 
    setFocusedSection, 
    handleShowInfo, 
    searchPlaceholder
  } = props;
  const [ filteredSections, setFilteredSections ] = useState(sections);
  const [ search, setSearch ] = useState('');
  const [ scrollContainer, setScrollContainer ] = useState();

  useEffect(() => {
    if (document.getElementById('sections-container')) {
      setScrollContainer(document.getElementById('sections-container'));
    }
  }, []);

  useEffect(() => {
    if (search) {
      setFilteredSections(() => {
        return sections.filter(section => {
          return section.includes(search.toString().toUpperCase());
        })
      });
    } else {
      setFilteredSections(sections);
    }
  }, [ search, sections ]);

  useEffect(() => {
    if (filteredSections.length) {
      setFocusedSection(filteredSections[ 0 ]);
    }
  }, [ filteredSections, setFocusedSection ]);

  function handleScroll(event) {
    let children = event.target.children;
    let visibleChildren = [];

    Array.from(children).forEach(child => {
      if (child.dataset.visible === 'true') {
        visibleChildren.push(child);
      }
    });

    if (visibleChildren[ 0 ].getAttribute('data-section') !== focusedSection) {
      setFocusedSection(visibleChildren[ 0 ].getAttribute('data-section'));
    }
    
    event.persist()
  }

  return (
    <div>
      <div className='seating-chart__search-box'>
        <input
          type='text'
          className='seating-chart__search'
          name='search'
          placeholder={ searchPlaceholder }
          value={ search }
          onChange={ e => setSearch(e.target.value) } />
        <span className='clearSearch' onClick={ e => setSearch('') }><img src='/img/x-icon.png' alt='close' /></span>
      </div>

      <div className='seating-chart__sections' id='sections-container' onScroll={ handleScroll }>
        { filteredSections.map(section => {
          if (focusedSection === section) {
            return (
              <VisibilitySensor key={ section } delayedCall={ true } containment={ scrollContainer }>
                { ({ isVisible }) => (
                  <div
                    key={ section }
                    data-visible={ isVisible }
                    data-section={ section }
                    className='seating-chart__section active'
                    onClick={ e => setFocusedSection(section) }>
                    Section { section }
                  </div>
                )}
              </VisibilitySensor>
            )
          } else {
            return (
              <VisibilitySensor key={ section } delayedCall={ true } containment={ scrollContainer }>
                { ({ isVisible }) => (
                  <div
                    key={ section }
                    data-visible={ isVisible }
                    data-section={ section }
                    className='seating-chart__section'
                    onClick={ e => handleShowInfo(section) }>
                    Section { section }
                  </div>
                )}
              </VisibilitySensor>
            )
          }
        })}
      </div>

    </div>
  )
}

export default SectionSearch;
