import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import Footer from '../components/Footer';
import PageTitle from '../components/PageTitle';
import EventCard from '../components/EventCard';

function Upcoming(props) {
  const [ events, setEvents ] = useState();

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
    
    axios.get('http://35.153.188.133/wp-json/wp/v2/event?per_page=100')
      .then(res => {
        let sortedEvents = res.data.sort((a, b) => moment(a.acf.event_date, 'D/M/YYYY').diff(moment(b.acf.event_date, 'D/M/YYYY')));

        setEvents(sortedEvents);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <div className='upcoming'>
      <PageTitle title='Upcoming Events' />
  
      <div className='upcoming__events'>
        {events && events.map(event => (
          <EventCard key={ event.id } event={ event } />
        ))}
      </div>

      <Footer />
    </div>
  )
}

export default Upcoming;