import React, { useEffect } from 'react';

function Basketball(props) {
  const { focusedSection } = props;

  useEffect(() => {
    const elementToFocus = document.getElementById(`section_${ focusedSection }`);
    const focusedRect = document.getElementsByClassName('focused');

    if (focusedRect.length) {
      focusedRect[ 0 ].classList.remove('focused');
    }

    elementToFocus && elementToFocus.classList.add('focused');
  }, [ focusedSection ]);

  return (
    <div>
      <svg 
        easypz='{ "applyTransformTo":"svg > *", "modes": ["SIMPLE_PAN", "HOLD_ZOOM_IN", "CLICK_HOLD_ZOOM_OUT", "PINCH_ZOOM", "DBLCLICK_ZOOM_IN", "DBLRIGHTCLICK_ZOOM_OUT"], "options": { "minScale": 1, "maxScale": 2, "bounds": { "top": -50, "right": 50, "bottom": 50, "left": -50 }} }'
        version="1.1" 
        id="SeatingChartBasketball" 
        xmlns="http://www.w3.org/2000/svg"
        x="0px" 
        y="0px" 
        viewBox="55 50 680 470" 
        style={{ enableBackground:'new 0 0 792 557' }}>
    
      <g id="section_226">
        <rect x="220.1" y="460.1" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 233.4529 479.6638)">226</text>
      </g>

      <g id="section_119">
        <polygon points="578.7,363.5 581.4,361.1 557.3,336.2 553.3,340.6 596.3,441.3 626.8,412.1 	"/>
        <text transform="matrix(1 0 0 1 587.9291 408.3367)">119</text>
      </g>

      <g id="section_117">
        <polygon points="560.6,332.7 557.3,336.2 581.4,361.1 583.8,358.9 631.5,407.8 662.6,375.1 	"/>
        <text transform="matrix(1 0 0 1 616.0397 379.5064)">117</text>
      </g>

      <g id="section_129">
        <path d="M201.3,348.7L134,376.4l28.1,27.1l41.7-43.2l2.1,1.9l4-5.7C205.3,352.3,201.3,348.7,201.3,348.7z"/>
        <text transform="matrix(1 0 0 1 158.522 379.5063)">129</text>
      </g>

      <g id="section_127">
        <path d="M224.8,370c-0.3-0.2-8.3-7.4-14.8-13.4l-4,5.7l2.4,2.1l-45.2,47l30.8,29.9C194,441.3,225.3,370.3,224.8,370z"
          />
        <text transform="matrix(1 0 0 1 184.2162 410.0741)">127</text>
      </g>

      <g id="section_101">
        <polygon points="190.4,164.1 187.8,166.7 162.7,143.5 137.3,171.7 170,184.3 205,198.7 214.5,188.7 	"/>
        <text transform="matrix(1 0 0 1 155.2617 170.7209)">101</text>
      </g>

      <g id="section_103">
        <polygon points="197,107 167.3,138.7 192.2,162.3 190.4,164.1 214.5,188.7 225,177.8 	"/>
        <text transform="matrix(1 0 0 1 182.2161 145.127)">103</text>
      </g>

      <g id="section_225">
        <rect x="271" y="460.1" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 284.7148 479.6647)">225</text>
      </g>
      
      <g id="section_224">
        <rect x="321.9" y="460.1" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 334.6611 479.6638)">224</text>
      </g>

      <g id="section_222">
        <rect x="423.7" y="460.1" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 435.2162 479.6638)">222</text>
      </g>

      <g id="section_223">
        <rect x="372.7" y="460.1" width="44.3" height="27"/>
        <text transform="matrix(1 0 0 1 384.3676 477.9971)">223</text>
      </g>

      <g id="section_207">
        <rect x="373" y="60" width="44.3" height="29.6"/>
        <text transform="matrix(1 0 0 1 384.3676 79.2213)">207</text>
      </g>
      <g id="section_221">
        <rect x="474.5" y="460.2" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 486.7305 479.6638)">221</text>
      </g>

      <g id="section_220">
        <rect x="525.4" y="460.2" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 536.6125 479.6638)">220</text>
      </g>

      <g id="section_218">
        <rect x="624.8" y="415.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -114.6306 588.4025)" width="56.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 643.2523 435.6496)">218</text>
      </g>

      <g id="section_216">
        <rect x="678.5" y="298.8" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 685.6465 325.9812)">216</text>
      </g>

      <g id="section_215">
        <rect x="678.6" y="255.6" width="29" height="36.9"/>
        <text transform="matrix(1 0 0 1 683.6465 278.2621)">215</text>
      </g>

      <g id="section_214">
        <rect x="678.5" y="204.2" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 685.6279 231.375)">214</text>
      </g>

      <g id="section_219">
        <polygon points="576,460.1 576,494 615.7,494 640.9,468.8 616.6,444.4 600.3,460.1 	"/>
        <text transform="matrix(1 0 0 1 595.6715 477.0658)">219</text>
      </g>

      <g id="section_204">
        <rect x="220.5" y="55.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 232.8839 75.2209)">204</text>
      </g>

      <g id="section_205">
        <rect x="271.4" y="55.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 283.1556 75.2209)">205</text>
      </g>

      <g id="section_206">
        <rect x="322.2" y="55.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 334.3164 75.2209)">206</text>
      </g>
      
      <g id="section_106">
        <rect x="322.6" y="110.7" width="45.7" height="104.5"/>
        <text transform="matrix(1 0 0 1 335.3164 157.0516)">106</text>
      </g>

      <g id="section_107">
        <rect x="374" y="104.8" width="43.3" height="111.3"/>
        <text transform="matrix(1 0 0 1 384.6448 157.0516)">107</text>
      </g>

      <g id="section_108">
        <rect x="423.8" y="110.7" width="45.5" height="105.4"/>
        <text transform="matrix(1 0 0 1 435.2161 157.0516)">108</text>
      </g>

      <g id="section_109">
        <rect x="474.6" y="105" width="45.6" height="99.5"/>
        <text transform="matrix(1 0 0 1 485.993 157.0516)">109</text>
      </g>

      <g id="section_110">
        <polygon points="525.4,105.3 525.4,204.1 547.6,204.1 590.8,105.3 	"/>
        <text transform="matrix(1 0 0 1 539.9816 138.7183)">110</text>
      </g>

      <g id="section_111">
        <polygon points="595.3,107.2 579.2,144.1 563,179.4 570.8,185.5 596.6,160.2 600.6,164 626.8,138.8 	"/>
        <text transform="matrix(1 0 0 1 590.6482 143.1171)">111</text>
      </g>

      <g id="section_113">
        <polygon points="629.3,141.6 659.3,173.9 587.2,204.1 582,197.8 607.7,171.6 603.8,167.1 	"/>
        <text transform="matrix(1 0 0 1 618.9816 169.721)">113</text>
      </g>

      <g id="section_114">
        <polygon points="562.8,221.1 660.8,181 660.8,245.8 562.8,245.8 	"/>
        <text transform="matrix(1 0 0 1 615.0066 221.138)">114</text>
      </g>

      <g id="section_14">
        <rect x="497.4" y="221.1" width="62.7" height="24.6"/>
        <text transform="matrix(1 0 0 1 521.3684 236.013)">14</text>
      </g>

      <g id="section_15">
        <rect x="497.4" y="251" width="62.7" height="44.5"/>
        <text transform="matrix(1 0 0 1 521.3684 277.381)">15</text>
      </g>

      <g id="section_16">
        <rect x="497.4" y="302.5" width="62.7" height="23.5"/>
        <text transform="matrix(1 0 0 1 521.3684 317.631)">16</text>
      </g>

      <g id="section_115">
        <rect x="562.8" y="251" width="98" height="44.5"/>
        <text transform="matrix(1 0 0 1 615.0066 276.5875)">115</text>
      </g>

      <g id="section_116">
        <polygon points="662.6,302.5 662.6,367.3 562.8,327.1 562.8,302.5 	"/>
        <text transform="matrix(1 0 0 1 615.0066 328.762)">116</text>
      </g>

      <g id="section_120">
        <polygon points="547.7,343.8 587.2,443.1 525.4,443.1 525.4,343.8 	"/>
        <text transform="matrix(1 0 0 1 537.9812 412.1266)">120</text>
      </g>

      <g id="section_121">
        <rect x="474.5" y="340.6" width="44.3" height="102.5"/>
        <text transform="matrix(1 0 0 1 485.9931 395.3162)">121</text>
      </g>

      <g id="section_122">
        <rect x="423.6" y="332.2" width="44.3" height="105.2"/>
        <text transform="matrix(1 0 0 1 435.2158 395.3162)">122</text>
      </g>

      <g id="section_123">
        <polygon points="417.8,416.9 417.8,442.2 373.5,442.2 373.5,332.3 417.8,332.3 	"/>
        <text transform="matrix(1 0 0 1 384.6452 395.3162)">123</text>
      </g>

      <g id="section_124">
        <rect x="322.1" y="331.7" width="46.4" height="105.7"/>
        <text transform="matrix(1 0 0 1 333.8145 395.3162)">124</text>
      </g>

      <g id="section_125">
        <rect x="270.7" y="340.6" width="44.2" height="102.5"/>
        <text transform="matrix(1 0 0 1 283.1557 395.3162)">125</text>
      </g>

      <g id="section_126">
        <polygon points="265.8,347.5 265.8,443.1 202.8,443.1 240.8,347.5 	"/>
        <text transform="matrix(1 0 0 1 230.1557 412.5004)">126</text>
      </g>

      <g id="section_130">
        <polygon points="168.2,302.4 168.2,353 226,330.2 226,302.5 	"/>
        <text transform="matrix(1 0 0 1 187.4057 324.4529)">130</text>
      </g>

      <g id="section_131">
        <polygon points="226,250.5 168.2,250.5 168.2,295.5 226,295.5 	"/>
        <text transform="matrix(1 0 0 1 187.4057 276.5873)">131</text>
      </g>

      <g id="section_132">
        <path d="M168.2,194.8c1,0.2,57.8,23.8,57.8,23.8v27.2h-57.8V194.8z"/>
        <text transform="matrix(1 0 0 1 187.4057 229.8231)">132</text>
      </g>

      <g id="section_32">
        <polygon points="291.5,222.4 229.1,222.4 229.1,245.4 291.5,245.4 	"/>
        <text transform="matrix(1 0 0 1 252.4985 238.0129)">32</text>
      </g>

      <g id="section_31">
        <polygon points="291.5,251.7 229.1,251.7 229.1,296.7 291.5,296.7 	"/>
        <text transform="matrix(1 0 0 1 252.4985 276.5872)">31</text>
      </g>

      <g id="section_30">
        <polygon points="291.5,302.4 229.1,302.4 229.1,325.4 291.5,325.4 	"/>
        <text transform="matrix(1 0 0 1 252.4985 316.5419)">30</text>
      </g>

      <g id="section_104">
        <polygon points="203,105.3 229.5,176.8 265.9,176.8 265.9,104.8 	"/>
        <text transform="matrix(1 0 0 1 230.1558 141.0189)">104</text>
      </g>

      <g id="section_105">
        <polygon points="271.9,104.8 271.9,204.5 316.9,204.5 316.9,104.8 	"/>
        <text transform="matrix(1 0 0 1 283.1558 157.0517)">105</text>
      </g>

      <g id="section_208">
        <rect x="423.8" y="55.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 435.63 75.2209)">208</text>
      </g>

      <g id="section_209">
        <rect x="474.7" y="55.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 485.9932 75.2209)">209</text>
      </g>

      <g id="section_210">
        <rect x="525.5" y="55.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 536.9342 75.2209)">210</text>
      </g>

      <g id="section_211">
        <polygon points="576.1,89.1 576.1,55.2 615.8,55.2 641,80.4 616.7,104.8 600.4,89.1 	"/>
        <text transform="matrix(1 0 0 1 597.1486 77.2213)">211</text>
      </g>

      <g id="section_212">
        <rect x="635.2" y="89.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 108.5538 495.2988)" width="33.9" height="54.8"/>
        <text transform="matrix(1 0 0 1 642.4253 119.7211)">212</text>
      </g>

      <g id="section_213">
        <polygon points="664.8,152.2 688.5,128.5 712.3,152.3 712.3,197.8 678.6,197.8 678.6,165.7 	"/>
        <text transform="matrix(1 0 0 1 685.6278 169.7211)">213</text>
      </g>

      <g id="section_217">
        <polygon points="664.8,396.8 688.5,420.5 712.3,396.7 712.3,351.2 678.6,351.2 678.6,383.3 	"/>
        <text transform="matrix(1 0 0 1 684.6273 388.8258)">217</text>
      </g>

      <g id="section_227">
        <polygon points="172.1,442.2 148.1,466.2 176.2,494.2 211.9,494.2 211.9,459.8 189.3,459.4 	"/>
        <text transform="matrix(1 0 0 1 173.6401 480.9968)">227</text>
      </g>

      <g id="section_228">
        <rect x="119.8" y="403.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -264.722 222.952)" width="33.9" height="55.3"/>
        <text transform="matrix(1 0 0 1 127.5483 438.6491)">228</text>
      </g>

      <g id="section_230">
        <rect x="76.4" y="299.4" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 83.6307 328.7623)">230</text>
      </g>

      <g id="section_231">
        <rect x="76.4" y="255.4" width="33.9" height="37.9"/>
        <text transform="matrix(1 0 0 1 83.6307 281.2623)">231</text>
      </g>

      <g id="section_232">
        <rect x="76.4" y="204.2" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 83.6307 234.3746)">232</text>
      </g>

      <g id="section_203">
        <polygon points="213.8,89.6 213.8,55.7 174.1,55.7 148.8,80.9 173.2,105.3 189.4,89.6 	"/>
        <text transform="matrix(1 0 0 1 173.64 80.2211)">203</text>
      </g>

      <g id="section_202">
        <rect x="109.6" y="99.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -42.1802 131.1523)" width="55.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 127.5484 122.7211)">202</text>
      </g>

      <g id="section_229">
        <polygon points="124.3,395.8 100.2,419.9 76.4,396.1 76.4,350.6 110.1,350.6 110.9,382 	"/>
        <text transform="matrix(1 0 0 1 83.6306 390.8255)">229</text>
      </g>

      <g id="section_201">
        <polygon points="123.9,151.5 100.2,127.8 76.4,151.7 76.4,197.2 110.1,197.2 110.1,165 	"/>
        <text transform="matrix(1 0 0 1 83.6309 167.9555)">201</text>
      </g>

      <g id="handicapped">
        <g>
          <g>
            <circle cx="394.4" cy="491.2" r="6"/>
            <path d="M394.4,497.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S397.9,497.6,394.4,497.6z
              M394.4,485.5c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,485.5,394.4,485.5z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,490.3L394.6,490.3c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,489.8,394.6,490,394.6,490.3C394.6,490.2,394.6,490.2,394.6,490.3z"/>
            </g>
            <g>
              <path d="M393.5,490.2c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,490.6,393,490.3,393.5,490.2
                C393.4,490.2,393.4,490.2,393.5,490.2z"/>
            </g>
            <g>
              <path d="M395,488.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,487.6,395,488.1z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="678.8" cy="430.4" r="6"/>
            <path d="M678.8,436.7c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S682.3,436.7,678.8,436.7z
              M678.8,424.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C684.5,427.2,681.9,424.7,678.8,424.7z"/>
          </g>
          <g>
            <g>
              <path d="M679,429.4C679,429.4,679.1,429.4,679,429.4c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C679,429,679,429.2,679,429.4L679,429.4z"/>
            </g>
            <g>
              <path d="M677.9,429.4c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C677,429.7,677.4,429.5,677.9,429.4
                C677.8,429.4,677.9,429.4,677.9,429.4z"/>
            </g>
            <g>
              <path d="M679.4,427.2c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C679,426.3,679.4,426.7,679.4,427.2z"
                />
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="711.3" cy="274.1" r="6"/>
            <path d="M711.3,280.4c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S714.8,280.4,711.3,280.4z
              M711.3,268.4c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C717,270.9,714.4,268.4,711.3,268.4z"/>
          </g>
          <g>
            <g>
              <path d="M711.5,273.1C711.5,273.1,711.6,273.1,711.5,273.1c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C711.5,272.7,711.5,272.8,711.5,273.1C711.5,273,711.5,273.1,711.5,273.1z"/>
            </g>
            <g>
              <path d="M710.4,273c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C709.5,273.4,709.9,273.2,710.4,273
                C710.3,273,710.4,273,710.4,273z"/>
            </g>
            <g>
              <path d="M711.9,270.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S711.9,270.4,711.9,270.9z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="676.5" cy="119.6" r="6"/>
            <path d="M676.5,125.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C682.9,123,680,125.9,676.5,125.9z
              M676.5,113.8c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S679.7,113.8,676.5,113.8z"/>
          </g>
          <g>
            <g>
              <path d="M676.7,118.6C676.8,118.6,676.8,118.6,676.7,118.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C676.8,118.1,676.7,118.3,676.7,118.6C676.7,118.5,676.7,118.5,676.7,118.6z"/>
            </g>
            <g>
              <path d="M675.6,118.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C674.8,118.9,675.1,118.6,675.6,118.5L675.6,118.5z"/>
            </g>
            <g>
              <path d="M677.1,116.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C676.8,115.5,677.1,115.9,677.1,116.4
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="460.1" r="6"/>
            <path d="M394.4,466.4c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C400.7,463.6,397.9,466.4,394.4,466.4z
              M394.4,454.4c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C400.1,456.9,397.5,454.4,394.4,454.4z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,459.1L394.6,459.1c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,458.7,394.6,458.9,394.6,459.1L394.6,459.1z"/>
            </g>
            <g>
              <path d="M393.5,459.1c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,459.4,393,459.2,393.5,459.1
                C393.4,459.1,393.4,459.1,393.5,459.1z"/>
            </g>
            <g>
              <path d="M395,456.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,456.4,395,456.9z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="445.4" cy="437.2" r="6"/>
            <path d="M445.4,443.5c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S448.9,443.5,445.4,443.5z
              M445.4,431.4c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S448.6,431.4,445.4,431.4z"/>
          </g>
          <g>
            <g>
              <path d="M445.6,436.2C445.7,436.2,445.7,436.2,445.6,436.2c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C445.6,435.8,445.6,435.9,445.6,436.2C445.6,436.1,445.6,436.1,445.6,436.2z"/>
            </g>
            <g>
              <path d="M444.5,436.1c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C443.6,436.5,444,436.3,444.5,436.1L444.5,436.1z"/>
            </g>
            <g>
              <path d="M446,434c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C445.7,433.1,446,433.5,446,434z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="346.6" cy="437.2" r="6"/>
            <path d="M346.6,443.5c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S350.1,443.5,346.6,443.5z
              M346.6,431.4c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C352.3,434,349.8,431.4,346.6,431.4z"/>
          </g>
          <g>
            <g>
              <path d="M346.8,436.2C346.9,436.2,346.9,436.2,346.8,436.2c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C346.8,435.8,346.8,435.9,346.8,436.2C346.8,436.1,346.8,436.1,346.8,436.2z"/>
            </g>
            <g>
              <path d="M345.7,436.1c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C344.8,436.5,345.2,436.3,345.7,436.1L345.7,436.1z"/>
            </g>
            <g>
              <path d="M347.2,434c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C346.9,433.1,347.2,433.5,347.2,434z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="90.7" r="6"/>
            <path d="M394.4,97c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C400.7,94.2,397.9,97,394.4,97z M394.4,85
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,85,394.4,85z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,89.7L394.6,89.7c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4V89
                C394.6,89.3,394.6,89.5,394.6,89.7L394.6,89.7z"/>
            </g>
            <g>
              <path d="M393.5,89.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,90,393,89.8,393.5,89.6
                C393.4,89.7,393.4,89.7,393.5,89.6z"/>
            </g>
            <g>
              <path d="M395,87.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,87,395,87.5z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="59.9" r="6"/>
            <path d="M394.4,66.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S397.9,66.3,394.4,66.3z M394.4,54.2
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,54.2,394.4,54.2z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,59L394.6,59c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V61c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,58.5,394.6,58.7,394.6,59C394.6,58.9,394.6,58.9,394.6,59z"/>
            </g>
            <g>
              <path d="M393.5,58.9c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,59.3,393,59,393.5,58.9
                C393.4,58.9,393.4,58.9,393.5,58.9z"/>
            </g>
            <g>
              <path d="M395,56.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,56.3,395,56.8z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="346" cy="111" r="6"/>
            <path d="M346,117.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S349.5,117.3,346,117.3z M346,105.3
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S349.1,105.3,346,105.3z"/>
          </g>
          <g>
            <g>
              <path d="M346.2,110C346.2,110,346.3,110,346.2,110c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5
                h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V112c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C346.2,109.6,346.2,109.8,346.2,110L346.2,110z"/>
            </g>
            <g>
              <path d="M345.1,109.9c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C344.2,110.3,344.6,110.1,345.1,109.9
                C345,110,345.1,110,345.1,109.9z"/>
            </g>
            <g>
              <path d="M346.6,107.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S346.6,107.3,346.6,107.8z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="237.8" cy="105.3" r="6"/>
            <path d="M237.8,111.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S241.3,111.6,237.8,111.6z M237.8,99.6
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C243.5,102.1,241,99.6,237.8,99.6z"/>
          </g>
          <g>
            <g>
              <path d="M238,104.3C238.1,104.3,238.1,104.3,238,104.3c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5
                h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C238,103.9,238,104.1,238,104.3C238,104.2,238,104.3,238,104.3z"/>
            </g>
            <g>
              <path d="M236.9,104.2c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C236,104.6,236.4,104.4,236.9,104.2L236.9,104.2z"/>
            </g>
            <g>
              <path d="M238.4,102.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C238.1,101.2,238.4,101.6,238.4,102.1
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="552.6" cy="443.3" r="6"/>
            <path d="M552.6,449.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S556.1,449.6,552.6,449.6z
              M552.6,437.5c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C558.4,440.1,555.8,437.5,552.6,437.5z"/>
          </g>
          <g>
            <g>
              <path d="M552.9,442.3L552.9,442.3c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C552.9,441.8,552.9,442,552.9,442.3C552.9,442.2,552.9,442.2,552.9,442.3z"/>
            </g>
            <g>
              <path d="M551.7,442.2c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C550.9,442.6,551.3,442.3,551.7,442.2L551.7,442.2z"/>
            </g>
            <g>
              <path d="M553.3,440.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C552.9,439.2,553.3,439.6,553.3,440.1
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="645.4" cy="393.6" r="6"/>
            <path d="M645.4,399.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S648.9,399.9,645.4,399.9z
              M645.4,387.9c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C651.1,390.4,648.6,387.9,645.4,387.9z"/>
          </g>
          <g>
            <g>
              <path d="M645.6,392.6C645.7,392.6,645.7,392.6,645.6,392.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C645.6,392.2,645.6,392.4,645.6,392.6L645.6,392.6z"/>
            </g>
            <g>
              <path d="M644.5,392.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C643.6,392.9,644,392.7,644.5,392.5
                C644.5,392.6,644.5,392.6,644.5,392.5z"/>
            </g>
            <g>
              <path d="M646,390.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S646,389.9,646,390.4z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="659.4" cy="274.1" r="6"/>
            <path d="M659.4,280.4c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S662.9,280.4,659.4,280.4z
              M659.4,268.4c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C665.2,270.9,662.6,268.4,659.4,268.4z"/>
          </g>
          <g>
            <g>
              <path d="M659.7,273.1L659.7,273.1c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C659.7,272.7,659.7,272.8,659.7,273.1C659.7,273,659.7,273.1,659.7,273.1z"/>
            </g>
            <g>
              <path d="M658.5,273c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C657.7,273.4,658,273.2,658.5,273L658.5,273z"/>
            </g>
            <g>
              <path d="M660.1,270.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C659.7,270,660.1,270.4,660.1,270.9z"
                />
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="642.8" cy="156.2" r="6"/>
            <path d="M642.8,162.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S646.3,162.6,642.8,162.6z
              M642.8,150.5c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S646,150.5,642.8,150.5z"/>
          </g>
          <g>
            <g>
              <path d="M643,155.2C643.1,155.2,643.1,155.2,643,155.2c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5
                h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C643,154.8,643,155,643,155.2L643,155.2z"/>
            </g>
            <g>
              <path d="M641.9,155.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C641,155.6,641.4,155.3,641.9,155.2L641.9,155.2z"/>
            </g>
            <g>
              <path d="M643.4,153c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C643,152.2,643.4,152.6,643.4,153z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="158" cy="148.4" r="6"/>
            <path d="M158,154.8c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S161.5,154.8,158,154.8z M158,142.7
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S161.2,142.7,158,142.7z"/>
          </g>
          <g>
            <g>
              <path d="M158.2,147.4C158.3,147.4,158.3,147.4,158.2,147.4c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C158.2,147,158.2,147.2,158.2,147.4L158.2,147.4z"/>
            </g>
            <g>
              <path d="M157.1,147.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C156.2,147.7,156.6,147.5,157.1,147.4L157.1,147.4z"/>
            </g>
            <g>
              <path d="M158.6,145.2c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C158.2,144.4,158.6,144.7,158.6,145.2
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="155.2" cy="395.3" r="6"/>
            <path d="M155.2,401.7c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S158.7,401.7,155.2,401.7z
              M155.2,389.6c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S158.3,389.6,155.2,389.6z"/>
          </g>
          <g>
            <g>
              <path d="M155.4,394.3L155.4,394.3c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C155.4,393.9,155.4,394.1,155.4,394.3L155.4,394.3z"/>
            </g>
            <g>
              <path d="M154.3,394.3c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C153.4,394.6,153.8,394.4,154.3,394.3
                C154.2,394.3,154.2,394.3,154.3,394.3z"/>
            </g>
            <g>
              <path d="M155.8,392.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C155.4,391.2,155.8,391.6,155.8,392.1
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="75.3" cy="334.3" r="6"/>
            <path d="M75.3,340.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S78.8,340.6,75.3,340.6z M75.3,328.5
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S78.5,328.5,75.3,328.5z"/>
          </g>
          <g>
            <g>
              <path d="M75.6,333.3L75.6,333.3c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C75.6,332.8,75.6,333,75.6,333.3C75.6,333.2,75.6,333.2,75.6,333.3z"/>
            </g>
            <g>
              <path d="M74.4,333.2c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C73.6,333.6,73.9,333.3,74.4,333.2L74.4,333.2z"/>
            </g>
            <g>
              <path d="M76,331.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S76,330.6,76,331.1z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="75.3" cy="214.5" r="6"/>
            <path d="M75.3,220.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C81.7,218,78.8,220.9,75.3,220.9z
              M75.3,208.8c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S78.5,208.8,75.3,208.8z"/>
          </g>
          <g>
            <g>
              <path d="M75.6,213.5L75.6,213.5c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C75.6,213.1,75.6,213.3,75.6,213.5L75.6,213.5z"/>
            </g>
            <g>
              <path d="M74.4,213.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C73.6,213.8,73.9,213.6,74.4,213.5L74.4,213.5z"/>
            </g>
            <g>
              <path d="M76,211.3c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C75.6,210.5,76,210.8,76,211.3z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="446.5" cy="111" r="6"/>
            <path d="M446.5,117.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S450,117.3,446.5,117.3z M446.5,105.3
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S449.7,105.3,446.5,105.3z"/>
          </g>
          <g>
            <g>
              <path d="M446.8,110L446.8,110c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V112c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C446.8,109.6,446.8,109.8,446.8,110L446.8,110z"/>
            </g>
            <g>
              <path d="M445.6,109.9c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C444.8,110.3,445.1,110.1,445.6,109.9
                C445.6,110,445.6,110,445.6,109.9z"/>
            </g>
            <g>
              <path d="M447.2,107.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C446.8,106.9,447.2,107.3,447.2,107.8
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="556.3" cy="106.8" r="6"/>
            <path d="M556.3,113.1c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S559.8,113.1,556.3,113.1z M556.3,101
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S559.5,101,556.3,101z"/>
          </g>
          <g>
            <g>
              <path d="M556.6,105.8L556.6,105.8c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C556.6,105.3,556.6,105.5,556.6,105.8C556.6,105.7,556.6,105.7,556.6,105.8z"/>
            </g>
            <g>
              <path d="M555.4,105.7c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C554.6,106.1,555,105.8,555.4,105.7L555.4,105.7z"/>
            </g>
            <g>
              <path d="M557,103.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S557,103.1,557,103.6z"/>
            </g>
          </g>
        </g>
      </g>
      <g id="court">
        <rect x="300.8" y="226.8" width="187.4" height="94.5" />
        <rect x="319.9" y="234.4" width="149.1" height="79.6"/>
        <line x1="394.4" y1="234.4" x2="394.4" y2="313.9"/>
        <circle cx="394.2" cy="275.2" r="9.8"/>
        <circle cx="394.2" cy="274.9" r="3.6"/>
        <circle cx="437.5" cy="275.2" r="9.9"/>
        <rect x="438.2" y="265.3" width="30.8" height="19.7"/>
        <circle cx="351.4" cy="275.2" r="9.9"/>
        <rect x="319.8" y="265.4" width="30.8" height="19.7"/>
        <line x1="319.9" y1="243" x2="346" y2="243" />
        <line x1="319.9" y1="305.3" x2="345.7" y2="305.3" />
        <line x1="469" y1="305.3" x2="444.9" y2="305.3" />
        <line x1="468" y1="243" x2="444.9" y2="243" />
        <path d="M343.5,305.3c16.8,0,30.3-15.2,30.3-32S360.3,243,343.5,243" />
        <path d="M447.3,243c-16.8,0-30.3,15.2-30.3,32s13.6,30.3,30.3,30.3" />
      </g>
      </svg>

    </div>
  )
}

export default Basketball;
