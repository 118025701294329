import React, { useState, useEffect } from 'react';
import $ from 'jquery';

function FilterModal(props) {
  const { handleClose, handleApply, existingFilters } = props;
  const [ filters, setFilters ] = useState(existingFilters || {
    concessions: true,
    restaurants: true,
    services: true,
    restrooms: true
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleReset() {
    setFilters({
      concessions: true,
      restaurants: true,
      services: true,
      restrooms: true
    });
  }

  return (
    <div className='modal__filter' style={ { height: $('body').height() } }>
      <img className='modal__info__close' src='/img/icon-close-dark.png' alt='Close' onClick={ e => handleClose() } />

      <h2>Filters</h2>

      <p style={{ color: '#8a8a8d', marginBottom: '30px' }}>Type</p>

      <div className='modal__filter__options'>
        <div className='modal__filter__option'>
          <label>
            Concessions
            <input
              className='modal__filter__check'
              name='concessions'
              type='checkbox'
              checked={ filters.concessions }
              onChange={ e => setFilters({ ...filters, concessions: !filters.concessions } ) } />
            
            <span className='modal__filter__check-box'></span>
          </label>
        </div>

        <div className='modal__filter__option'>
          <label>
            Restaurants & Bars
            <input
              className='modal__filter__check'
              name='restaurants'
              type='checkbox'
              checked={ filters.restaurants }
              onChange={ e => setFilters({ ...filters, restaurants: !filters.restaurants }) } />

            <span className='modal__filter__check-box'></span>
          </label>
        </div>

        <div className='modal__filter__option'>
          <label>
            Guest Services
            <input
              className='modal__filter__check'
              name='services'
              type='checkbox'
              checked={ filters.services }
              onChange={ e => setFilters({ ...filters, services: !filters.services }) } />

            <span className='modal__filter__check-box'></span>
          </label>
        </div>

        <div className='modal__filter__option'>
          <label>
            Restrooms
            <input
              className='modal__filter__check'
              name='restrooms'
              type='checkbox'
              checked={ filters.restrooms }
              onChange={ e => setFilters({ ...filters, restrooms: !filters.restrooms }) } />

            <span className='modal__filter__check-box'></span>
          </label>
        </div>
      </div>
    
      <div className='modal__filter__buttons'>
        <div className='modal__filter__button' onClick={ e => handleReset() }>
          Reset
        </div>

        <div className='modal__filter__button dark' onClick={ e => handleApply(filters) }>
          Apply
        </div>
      </div>
    
    </div>
  )
}

export default FilterModal;