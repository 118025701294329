import React, { useEffect } from 'react';

function Rodeo(props) {
  const { focusedSection } = props;

  useEffect(() => {
    const elementToFocus = document.getElementById(`section_${ focusedSection }`);
    const focusedRect = document.getElementsByClassName('focused');

    if (focusedRect.length) {
      focusedRect[ 0 ].classList.remove('focused');
    }

    elementToFocus && elementToFocus.classList.add('focused');
  }, [ focusedSection ]);

  return (
    <div>
      <svg
        easypz='{ "applyTransformTo":"svg > *", "modes": ["SIMPLE_PAN", "HOLD_ZOOM_IN", "CLICK_HOLD_ZOOM_OUT", "PINCH_ZOOM", "DBLCLICK_ZOOM_IN", "DBLRIGHTCLICK_ZOOM_OUT"], "options": { "minScale": 1, "maxScale": 2, "bounds": { "top": -50, "right": 50, "bottom": 50, "left": -50 }} }'
        version="1.1" 
        id="SeatingChartRodeo" 
        xmlns="http://www.w3.org/2000/svg" 
        x="0px"
        y="0px" 
        viewBox="50 50 690 470">
      
      <g id="section_70" className='small-section'>
        <rect x="249.1" y="173.6" width="8.9" height="7.8"/>
        <text transform="matrix(1 0 0 1 252.005 179.0105)">A</text>

        <rect x="249.1" y="164.6" width="8.9" height="7.8" />
        <text transform="matrix(1 0 0 1 249.6743 190.4118)" className='small-section__number'>70</text>
        <text transform="matrix(1 0 0 1 252.0743 170.4118)">B</text>

        <rect x="249.1" y="155.6" width="8.9" height="7.8" />
        <text transform="matrix(1 0 0 1 251.9068 160.3503)">C</text>
      </g>
      
      <g id="section_68" className='small-section'>
        <rect x="258" y="173.6" width="8.5" height="7.8"/>
        <text transform="matrix(1 0 0 1 260.568 179.0105)">A</text>

        <rect x="258" y="164.6" width="8.5" height="7.8" />
        <text transform="matrix(1 0 0 1 258.4927 190.4118)" className='small-section__number'>68</text>
        <text transform="matrix(1 0 0 1 260.8927 170.4118)">B</text>

        <rect x="258" y="155.6" width="8.5" height="7.8" />
        <text transform="matrix(1 0 0 1 260.568 160.3503)">C</text>
      </g>

      <g id="section_66" className='small-section'>
        <rect x="272.6" y="173.6" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 277.2158 179.0105)">A</text>

        <rect x="272.6" y="164.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 274.7852 190.4118)" className='small-section__number'>66</text>
        <text transform="matrix(1 0 0 1 277.2852 170.4118)">B</text>

        <rect x="272.6" y="155.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 277.1177 160.3503)">C</text>
      </g>

      <g id="section_64" className='small-section'>
        <rect x="284.4" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 287.3921 179.0105)">A</text>

        <rect x="284.4" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 284.9614 190.4118)" className='small-section__number'>64</text>
        <text transform="matrix(1 0 0 1 287.4614 170.4118)">B</text>

        <rect x="284.4" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 287.2939 160.3503)">C</text>
      </g>

      <g id="section_62" className='small-section'>
        <rect x="299.3" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 302.3409 179.0105)">A</text>

        <rect x="299.3" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 300.0103 190.4118)" className='small-section__number'>62</text>
        <text transform="matrix(1 0 0 1 302.4103 170.4118)">B</text>

        <rect x="299.3" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 302.2428 160.3503)">C</text>
      </g>

      <g id="section_60" className='small-section'>
        <rect x="308" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 311.0702 179.0105)">A</text>

        <rect x="308" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 308.7529 190.4118)" className='small-section__number'>60</text>
        <text transform="matrix(1 0 0 1 311.3529 170.4118)">B</text>

        <rect x="308" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 310.9477 160.3503)">C</text>
      </g>

      <g id="section_58" className='small-section'>
        <rect x="323.2" y="173.6" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 327.6236 179.0105)">A</text>

        <rect x="323.2" y="164.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 325.293 190.4118)" className='small-section__number'>58</text>
        <text transform="matrix(1 0 0 1 327.693 170.4118)">B</text>

        <rect x="323.2" y="155.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 327.5255 160.3503)">C</text>
      </g>

      <g id="section_56" className='small-section'>
        <rect x="335" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 337.7418 179.0105)">A</text>

        <rect x="335" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 335.4111 190.4118)" className='small-section__number'>56</text>
        <text transform="matrix(1 0 0 1 337.8111 170.4118)">B</text>

        <rect x="335" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 337.6437 160.3503)">C</text>
      </g>

      <g id="section_54" className='small-section'>
        <rect x="349.9" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 352.7911 179.0105)">A</text>

        <rect x="349.9" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 350.4604 190.4118)" className='small-section__number'>54</text>
        <text transform="matrix(1 0 0 1 352.8604 170.4118)">B</text>

        <rect x="349.9" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 352.693 160.3503)">C</text>
      </g>

      <g id="section_52" className='small-section'>
        <rect x="358.6" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 361.4771 179.0105)">A</text>

        <rect x="358.6" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 359.1465 190.4118)" className='small-section__number'>52</text>
        <text transform="matrix(1 0 0 1 361.5465 170.4118)">B</text>

        <rect x="358.6" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 361.379 160.3503)">C</text>
      </g>

      <g id="section_50" className='small-section'>
        <rect x="373.5" y="173.6" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 378.0581 179.0105)">A</text>

        <rect x="373.5" y="164.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 375.6274 190.4118)" className='small-section__number'>50</text>
        <text transform="matrix(1 0 0 1 378.1274 170.4118)">B</text>

        <rect x="373.5" y="155.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 377.96 160.3503)">C</text>
      </g>

      <g id="section_48" className='small-section'>
        <rect x="385.3" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 388.2925 179.0105)">A</text>

        <rect x="385.3" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 385.8618 190.4118)" className='small-section__number'>48</text>
        <text transform="matrix(1 0 0 1 388.3618 170.4118)">B</text>

        <rect x="385.3" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 388.1943 160.3503)">C</text>
      </g>

      <g id="section_46" className='small-section'>
        <rect x="400.4" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 403.5336 179.0105)">A</text>

        <rect x="400.4" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 401.0029 190.4118)" className='small-section__number'>46</text>
        <text transform="matrix(1 0 0 1 403.6029 170.4118)">B</text>

        <rect x="400.4" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 403.4354 160.3503)">C</text>
      </g>

      <g id="section_44" className='small-section'>
        <rect x="409.1" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 411.957 179.0105)">A</text>

        <rect x="409.1" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 409.5264 190.4118)" className='small-section__number'>44</text>
        <text transform="matrix(1 0 0 1 412.0264 170.4118)">B</text>

        <rect x="409.1" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 411.8584 160.3503)">C</text>
      </g>

      <g id="section_38" className='small-section'>
        <rect x="450.7" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 453.7707 179.0105)">A</text>

        <rect x="450.7" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 451.54 190.4118)" className='small-section__number'>38</text>
        <text transform="matrix(1 0 0 1 453.84 170.4118)">B</text>

        <rect x="450.7" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 453.6721 160.3503)">C</text>
      </g>

      <g id="section_36" className='small-section'>
        <rect x="459.3" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 462.2971 179.0105)">A</text>

        <rect x="459.3" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 460.0664 190.4118)" className='small-section__number'>36</text>
        <text transform="matrix(1 0 0 1 462.3664 170.4118)">B</text>

        <rect x="459.3" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 462.1984 160.3503)">C</text>
      </g>

      <g id="section_10" className='small-section'>
        <polygon points="589.2,220.6 589.2,213.5 597,210.6 597,220.6 	"/>
        <text transform="matrix(1 0 0 1 591.2865 217.8757)">A</text>

        <polygon points="598.2,220.6 598.2,210 606,207 606,220.6 	" />
        <text transform="matrix(1 0 0 1 578.2559 219.8757)" className='small-section__number'>10</text>
        <text transform="matrix(1 0 0 1 600.3559 217.8757)">B</text>

        <polygon points="607.3,220.6 607.3,206.4 615.1,203.5 615.1,220.6 	" />
        <text transform="matrix(1 0 0 1 609.3334 217.8757)">C</text>
      </g>

      <g id="section_8" className='small-section'>
        <rect x="589.2" y="220.5" width="7.8" height="11.8"/>
        <text transform="matrix(1 0 0 1 590.4316 228.6404)">A</text>

        <rect x="598.2" y="220.5" width="7.8" height="11.8" />
        <text transform="matrix(1 0 0 1 579.501 230.6404)" className='small-section__number'>8</text>
        <text transform="matrix(1 0 0 1 599.501 228.6404)">B</text>

        <rect x="607.3" y="220.6" width="7.8" height="11.8" />
        <text transform="matrix(1 0 0 1 608.4795 228.6404)">C</text>
      </g>

      <g id="section_6" className='small-section'>
        <rect x="589.2" y="238.2" width="7.8" height="11.8"/>
        <text transform="matrix(1 0 0 1 590.4316 245.4968)">A</text>

        <rect x="598.2" y="238.2" width="7.8" height="11.8" />
        <text transform="matrix(1 0 0 1 580.501 246.4968)" className='small-section__number'>6</text>
        <text transform="matrix(1 0 0 1 599.501 245.4968)">B</text>

        <rect x="607.3" y="238.3" width="7.8" height="11.8" />
        <text transform="matrix(1 0 0 1 608.4795 245.4968)">C</text>
      </g>

      <g id="section_42" className='small-section'>
        <rect x="424.2" y="173.6" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 428.523 179.0105)">A</text>

        <rect x="424.2" y="164.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 426.1924 190.4118)" className='small-section__number'>42</text>
        <text transform="matrix(1 0 0 1 428.5924 170.4118)">B</text>

        <rect x="424.2" y="155.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 428.4244 160.3503)">C</text>
      </g>

      <g id="section_40" className='small-section'>
        <rect x="436" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 439.0146 179.0105)">A</text>

        <rect x="436" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 436.584 190.4118)" className='small-section__number'>40</text>
        <text transform="matrix(1 0 0 1 439.084 170.4118)">B</text>

        <rect x="436" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 438.916 160.3503)">C</text>
      </g>

      <g id="section_34" className='small-section'>
        <rect x="474.5" y="173.6" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 478.4469 179.0105)">A</text>

        <rect x="474.5" y="164.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 476.1162 190.4118)" className='small-section__number'>34</text>
        <text transform="matrix(1 0 0 1 478.5162 170.4118)">B</text>

        <rect x="474.5" y="155.6" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 478.3482 160.3503)">C</text>
      </g>

      <g id="section_32" className='small-section'>
        <rect x="486.2" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 488.8375 179.0105)">A</text>

        <rect x="486.2" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 486.5068 190.4118)" className='small-section__number'>32</text>
        <text transform="matrix(1 0 0 1 488.9068 170.4118)">B</text>

        <rect x="486.2" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 488.7389 160.3503)">C</text>
      </g>

      <g id="section_76" className='small-section'>
        <polygon points="226.3,181.5 222.1,185.6 216.8,179.8 223.2,173.9 	"/>
        <text transform="matrix(1 0 0 1 220.4478 181.5661)">A</text>

        <polygon points="222.9,173.3 216.3,179.7 210.9,174 219.4,165.5 	" />
        <text transform="matrix(1 0 0 1 222.1338 195.0613)" className='small-section__number'>76</text>
        <text transform="matrix(1 0 0 1 215.3338 175.0613)">B</text>
      </g>

      <g id="section_78" className='small-section'>
        <polygon points="210.4,185.6 216.8,179.8 222.1,185.6 215.7,191.4 	"/>
        <text transform="matrix(1 0 0 1 214.3877 186.9031)">A</text>

        <polygon points="204.6,179.8 210.9,174 216.3,179.6 209.9,185.6 	" />
        <text transform="matrix(1 0 0 1 200.6396 193.4255)" className='small-section__number'>78</text>
        <text transform="matrix(1 0 0 1 208.9396 181.4255)">B</text>

        <polygon points="219.7,164.4 204.2,179.5 198.9,173.7 216,156.3 	" />
        <text transform="matrix(1 0 0 1 206.6452 171.2712)">C</text>
      </g>

      <g id="section_74" className='small-section'>
        <polygon points="233.5,181.5 244.5,181.5 244.5,173.6 229.9,173.6 	"/>
        <text transform="matrix(1 0 0 1 236.0467 179.4226)">A</text>
  
        <polygon points="229.2,172.4 236.4,172.4 236.4,164.6 225.9,164.6 	" />
        <text transform="matrix(1 0 0 1 235.3599 190.6511)" className='small-section__number'>74</text>
        <text transform="matrix(1 0 0 1 230.5599 169.6511)">B</text>
        
        <polygon points="234.1,155.6 232.1,155.6 222.6,155.6 225.4,163.4 232.1,163.4 234.1,163.4 244.5,163.4 244.5,155.6 	
          "/>
        <text transform="matrix(1 0 0 1 232.7796 160.8606)">C</text>
      </g>

      <g id="section_72B" className='small-section'>
        <rect x="236.4" y="164.6" width="8.2" height="7.8"/>
        <text transform="matrix(1 0 0 1 236.6641 190.6511)" className='small-section__number 72'></text>
        <text transform="matrix(1 0 0 1 239.0641 169.6511)">B</text>
      </g>

      <g id="section_30" className='small-section'>
        <rect x="501.5" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 504.4482 179.0105)">A</text>

        <rect x="501.5" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 502.0176 190.4118)" className='small-section__number'>30</text>
        <text transform="matrix(1 0 0 1 504.5176 169.4118)">B</text>

        <rect x="501.5" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 504.3496 160.3503)">C</text>
      </g>

      <g id="section_28" className='small-section'>
        <rect x="510.1" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 512.8727 179.0105)">A</text>

        <rect x="510.1" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 510.542 190.4118)" className='small-section__number'>28</text>
        <text transform="matrix(1 0 0 1 512.942 169.4118)">B</text>

        <rect x="510.1" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 512.774 160.3503)">C</text>
      </g>

      <g id="section_26" className='small-section'>
        <rect x="525.2" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 528.11 179.0105)">A</text>

        <rect x="525.2" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 525.7793 190.6511)" className='small-section__number'>26</text>
        <text transform="matrix(1 0 0 1 528.1793 169.6511)">B</text>

        <rect x="525.2" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 528.0114 160.3503)">C</text>
      </g>

      <g id="section_22" className='small-section'>
        <polygon points="558.1,181.5 548.8,181.5 548.8,173.6 561.7,173.6 	"/>
        <text transform="matrix(1 0 0 1 552.4557 178.8865)">A</text>

        <rect x="548.8" y="164.6" width="7.6" height="7.8" />
        <text transform="matrix(1 0 0 1 544.2646 190.6511)" className='small-section__number'>22</text>
        <text transform="matrix(1 0 0 1 551.3646 169.6511)">B</text>

        <rect x="548.8" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 551.6646 160.3503)">C</text>
      </g>

      <g id="section_20" className='small-section'>
        <polygon points="562.3,172.4 556.4,172.4 556.4,164.6 565.7,164.6 	"/>
        <text transform="matrix(1 0 0 1 558.9012 169.6511)">B</text>

        <rect x="557.5" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 552.7881 190.6511)" className='small-section__number'>20</text>
        <text transform="matrix(1 0 0 1 560.2881 160.3503)">C</text>
      </g>

      <g id="section_24" className='small-section'>
        <rect x="533.9" y="173.6" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 536.6344 179.0105)">A</text>

        <rect x="533.9" y="164.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 534.3037 190.6511)" className='small-section__number'>24</text>
        <text transform="matrix(1 0 0 1 536.7037 169.6511)">B</text>

        <rect x="533.9" y="155.6" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 536.5357 160.3503)">C</text>
      </g>

      <g id="section_12" className='small-section'>
        <polygon points="589.4,207.4 585.2,203.2 590.7,197.7 597.1,204 	" />
        <text transform="matrix(1 0 0 1 589.1387 204.1618)">A</text>

        <polygon points="598.3,203.5 591.6,196.8 597.1,191.3 606.4,200.5 	"/>
        <text transform="matrix(1 0 0 1 576.3438 212.6443)" className='small-section__number'>12</text>
        <text transform="matrix(1 0 0 1 596.3438 199.6443)">B</text>

        <polygon points="607.7,200.1 598.1,190.4 603.6,184.9 615.5,196.9 	" />
        <text transform="matrix(1 0 0 1 604.002 194.1921)">C</text>
      </g>

      <g id="section_14" className='small-section'>
        <rect x="581" y="193" transform="matrix(0.7071 -0.7071 0.7071 0.7071 31.7903 471.3899)" width="7.8" height="8.7"/>
        <text transform="matrix(1 0 0 1 583.2446 198.7576)">A</text>

        <rect x="587.4" y="186.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 38.1883 474.0417)" width="7.8" height="8.7" />
        <text transform="matrix(1 0 0 1 571.3867 206.5076)" className='small-section__number'>14</text>
        <text transform="matrix(1 0 0 1 589.3867 192.5076)">B</text>

        <rect x="593.9" y="180.2" transform="matrix(0.707 -0.7072 0.7072 0.707 44.6572 476.83)" width="7.8" height="8.7" />
        <text transform="matrix(1 0 0 1 595.7725 186.5388)">C</text>
      </g>
      
      <g id="section_16" className='small-section'>
        <polygon points="568.3,186.3 573.8,180.8 580,186.9 574.5,192.4 	" />
        <text transform="matrix(1 0 0 1 573.0615 187.9421)">A</text>

        <polygon points="574.8,179.9 580.3,174.4 586.3,180.5 580.8,186 	"/>
        <text transform="matrix(1 0 0 1 566.0615 200.6003)" className='small-section__number'>16</text>
        <text transform="matrix(1 0 0 1 578.1553 181.5661)">B</text>

        <polygon points="581,173.5 586.6,167.9 592.8,174.2 587.2,179.7 	" />
        <text transform="matrix(1 0 0 1 584.6191 175.4597)">C</text>
      </g>

      <g id="section_18" className='small-section'>
        <polygon points="568.3,186.3 564.2,182.2 567.7,174.6 573.8,180.8 	"/>
        <text transform="matrix(1 0 0 1 566.9056 182.5769)">A</text>

        <polygon points="574.8,179.9 568.2,173.4 571.1,165.2 580.3,174.4 	" />
        <text transform="matrix(1 0 0 1 561.0615 194.6003)" className='small-section__number'>18</text>
        <text transform="matrix(1 0 0 1 572.0615 174.6003)">B</text>

        <polygon points="581,173.5 571.4,163.8 574.8,156.2 586.6,167.9 	" />
        <text transform="matrix(1 0 0 1 576.8848 167.0417)">C</text>
      </g>

      <g id="section_9" className='small-section'>
        <polygon points="590.1,334.8 590.1,341.9 597.9,344.8 597.9,334.8 	"/>
        <text transform="matrix(1 0 0 1 591.0879 340.7468)">A</text>

        <polygon points="599.1,334.8 599.1,345.4 606.9,348.4 606.9,334.8 	" />
        <text transform="matrix(1 0 0 1 580.501 340.7468)" className='small-section__number'>9</text>
        <text transform="matrix(1 0 0 1 600.501 340.7468)">B</text>

        <polygon points="608.3,334.8 608.3,349 616.1,351.9 616.1,334.8 	" />
        <text transform="matrix(1 0 0 1 609.4795 340.7468)">C</text>
      </g>

      <g id="section_7" className='small-section'>
        <rect x="590.1" y="323.1" width="7.8" height="11.8"/>
        <text transform="matrix(1 0 0 1 591.0879 330.4265)">A</text>

        <rect x="599.1" y="323.1" width="7.8" height="11.8" />
        <text transform="matrix(1 0 0 1 580.501 330.4265)" className='small-section__number'>7</text>
        <text transform="matrix(1 0 0 1 600.501 330.4265)">B</text>

        <rect x="608.3" y="323" width="7.8" height="11.8" />
        <text transform="matrix(1 0 0 1 609.4795 330.4265)">C</text>
      </g>

      <g id="section_5" className='small-section'>
        <rect x="590.1" y="305.4" width="7.8" height="11.8"/>
        <text transform="matrix(1 0 0 1 591.0879 312.7976)">A</text>

        <rect x="599.1" y="305.4" width="7.8" height="11.8" />
        <text transform="matrix(1 0 0 1 580.501 312.7976)" className='small-section__number'>5</text>
        <text transform="matrix(1 0 0 1 600.501 312.7976)">B</text>

        <rect x="608.2" y="305.3" width="7.8" height="11.8" />
        <text transform="matrix(1 0 0 1 609.4795 312.7976)">C</text>
      </g>

      <g id="section_17" className='small-section'>
        <polygon points="569.3,369.1 565.2,373.2 568.7,380.8 574.8,374.6 	"/>
        <text transform="matrix(1 0 0 1 568.1953 375.9245)">A</text>

        <polygon points="575.8,375.5 569.2,382 572.1,390.2 581.3,381 	" />
        <text transform="matrix(1 0 0 1 557.0615 363.9509)" className='small-section__number'>17</text>
        <text transform="matrix(1 0 0 1 573.0615 383.9509)">B</text>

        <polygon points="588.2,375.7 572.4,391.6 575.8,399.2 587.6,387.5 593.7,381.2 	" />
        <text transform="matrix(1 0 0 1 581.4116 387.864)">C</text>
      </g>

      <g id="section_15" className='small-section'>
        <rect x="570.8" y="364.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -92.2443 514.6608)" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 573.2877 370.3991)">A</text>

        <rect x="577.3" y="371.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -94.8666 521.1313)" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 563.4746 360.8015)" className='small-section__number'>15</text>
        <text transform="matrix(1 0 0 1 580.3746 376.8015)">B</text>
      </g>

      <g id="section_13" className='small-section'>
        <rect x="581.8" y="354.1" transform="matrix(0.7072 -0.7071 0.7071 0.7072 -81.4604 519.2367)" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 584.4277 359.5642)">A</text>

        <rect x="588.2" y="360.5" transform="matrix(0.7071 -0.7072 0.7072 0.7071 -84.0922 525.7297)" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 569.9023 354.0515)" className='small-section__number'>13</text>
        <text transform="matrix(1 0 0 1 590.9023 366.0515)">B</text>

        <rect x="594.5" y="366.9" transform="matrix(0.7072 -0.7071 0.7071 0.7072 -86.7726 532.0327)" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 596.7725 372.5017)">C</text>
      </g>

      <g id="section_11" className='small-section'>
        <polygon points="590.4,348 586.3,352.1 591.8,357.6 598.1,351.4 	"/>
        <text transform="matrix(1 0 0 1 589.3158 353.5456)">A</text>

        <polygon points="599.3,351.9 592.7,358.5 598.2,364 607.4,354.9 	" />
        <text transform="matrix(1 0 0 1 575.2666 347.531)" className='small-section__number'>11</text>
        <text transform="matrix(1 0 0 1 596.8666 358.531)">B</text>

        <polygon points="608.7,355.3 599.1,364.9 604.6,370.4 616.5,358.5 	" />
        <text transform="matrix(1 0 0 1 604.9564 364.3523)">C</text>
      </g>

      <g id="section_19B" className='small-section'>
        <polygon points="562.3,382 555.2,382 555.2,389.8 565.7,389.8 	"/>
        <text transform="matrix(1 0 0 1 557.1538 387.3913)" className='small-section__number 19'></text>
        <text transform="matrix(1 0 0 1 558.9538 387.3913)">B</text>
      </g>

      <g id="section_41" className='small-section'>
        <rect x="424.2" y="372.9" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 428.323 378.447)">A</text>

        <rect x="424.2" y="382" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 426.1924 367.3894)" className='small-section__number'>41</text>
        <text transform="matrix(1 0 0 1 428.3924 387.3894)">B</text>

        <rect x="424.2" y="391" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 428.2244 396.7927)">C</text>
      </g>

      <g id="section_39" className='small-section'>
        <rect x="436" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 438.984 378.447)">A</text>

        <rect x="436" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 436.584 367.3894)" className='small-section__number'>39</text>
        <text transform="matrix(1 0 0 1 438.984 387.3894)">B</text>

        <rect x="436" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 438.984 396.7927)">C</text>
      </g>

      <g id="section_33" className='small-section'>
        <rect x="474.5" y="372.9" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 478.4469 378.447)">A</text>

        <rect x="474.5" y="382" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 476.1162 367.3894)" className='small-section__number'>33</text>
        <text transform="matrix(1 0 0 1 478.5162 387.3894)">B</text>

        <rect x="474.5" y="391" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 478.3482 396.7927)">C</text>
      </g>

      <g id="section_31" className='small-section'>
        <rect x="486.2" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 488.992 378.447)">A</text>

        <rect x="486.2" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 486.8613 367.3894)" className='small-section__number'>31</text>
        <text transform="matrix(1 0 0 1 489.0613 387.3894)">B</text>

        <rect x="486.2" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 488.8934 396.7927)">C</text>
      </g>

      <g id="section_37" className='small-section'>
        <rect x="450.7" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 453.635 378.447)">A</text>

        <rect x="450.7" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 451.4043 367.3894)" className='small-section__number'>37</text>
        <text transform="matrix(1 0 0 1 453.7043 387.3894)">B</text>

        <rect x="450.7" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 453.5363 396.7927)">C</text>
      </g>

      <g id="section_35" className='small-section'>
        <rect x="459.3" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 462.4338 378.447)">A</text>

        <rect x="459.3" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 460.2031 367.3894)" className='small-section__number'>35</text>
        <text transform="matrix(1 0 0 1 462.5031 387.3894)">B</text>

        <rect x="459.3" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 462.3352 396.7927)">C</text>
      </g>

      <g id="section_29" className='small-section'>
        <rect x="501.5" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 504.2105 378.447)">A</text>

        <rect x="501.5" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 501.8799 367.3894)" className='small-section__number'>29</text>
        <text transform="matrix(1 0 0 1 504.2799 387.3894)">B</text>

        <rect x="501.5" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 504.1119 396.7927)">C</text>
      </g>

      <g id="section_27" className='small-section'>
        <rect x="510.1" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 513.0104 378.447)">A</text>

        <rect x="510.1" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 510.6797 367.3894)" className='small-section__number'>27</text>
        <text transform="matrix(1 0 0 1 513.0797 387.3894)">B</text>

        <rect x="510.1" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 512.9117 396.7927)">C</text>
      </g>

      <g id="section_25" className='small-section'>
        <rect x="525.2" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 527.9713 378.447)">A</text>

        <rect x="525.2" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 525.6406 367.3894)" className='small-section__number'>25</text>
        <text transform="matrix(1 0 0 1 528.0406 387.3894)">B</text>

        <rect x="525.2" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 527.8727 396.7927)">C</text>
      </g>

      <g id="section_23" className='small-section'>
        <rect x="533.9" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 536.7721 378.447)">A</text>
          
        <rect x="533.9" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 534.4414 367.3894)" className='small-section__number'>23</text>
        <text transform="matrix(1 0 0 1 536.8414 387.3894)">B</text>

        <rect x="533.9" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 536.6734 396.7927)">C</text>
      </g>

      <g id="section_21" className='small-section'>
        <polygon points="558.1,372.9 548.8,372.9 548.8,380.8 561.7,380.8 	"/>
        <text transform="matrix(1 0 0 1 553.0404 378.447)">A</text>

        <rect x="548.8" y="382" width="6.4" height="7.8" />
        <text transform="matrix(1 0 0 1 549.2646 367.3913)" className='small-section__number'>21</text>
        <text transform="matrix(1 0 0 1 549.7646 387.3913)">B</text>

        <polygon points="566.2,391 557.5,391 548.8,391 548.8,398.8 557.5,398.8 568.8,398.8 	" />
        <text transform="matrix(1 0 0 1 554.3256 395.8191)">C</text>
      </g>

      <g id="section_69" className='small-section'>
        <rect x="249.1" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 252.1915 378.447)">A</text>

        <rect x="249.1" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 249.8608 367.3894)" className='small-section__number'>69</text>
        <text transform="matrix(1 0 0 1 252.2608 387.3894)">B</text>

        <rect x="249.1" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 252.0934 396.7927)">C</text>
      </g>

      <g id="section_67" className='small-section'>
        <rect x="257.8" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 260.9908 378.447)">A</text>

        <rect x="257.8" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 258.6602 367.3894)" className='small-section__number'>67</text>
        <text transform="matrix(1 0 0 1 261.0602 387.3894)">B</text>

        <rect x="257.8" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 260.8927 396.7927)">C</text>
      </g>

      <g id="section_61" className='small-section'>
        <rect x="299.3" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 301.969 378.447)">A</text>

        <rect x="299.3" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 299.8384 367.3894)" className='small-section__number'>61</text>
        <text transform="matrix(1 0 0 1 302.0384 387.3894)">B</text>

        <rect x="299.3" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 301.8709 396.7927)">C</text>
      </g>

      <g id="section_59" className='small-section'>
        <rect x="308" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 310.9684 378.447)">A</text>

        <rect x="308" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 308.6377 367.3894)" className='small-section__number'>59</text>
        <text transform="matrix(1 0 0 1 311.0377 387.3894)">B</text>

        <rect x="308" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 310.8702 396.7927)">C</text>
      </g>

      <g id="section_53" className='small-section'>
        <rect x="349.9" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 352.6325 378.447)">A</text>

        <rect x="349.9" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 350.4019 367.3894)" className='small-section__number'>53</text>
        <text transform="matrix(1 0 0 1 352.7019 387.3894)">B</text>

        <rect x="349.9" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 352.5344 396.7927)">C</text>
      </g>

      <g id="section_51" className='small-section'>
        <rect x="358.6" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 361.1338 378.447)">A</text>

        <rect x="358.6" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 359.2031 367.3894)" className='small-section__number'>51</text>
        <text transform="matrix(1 0 0 1 361.2031 387.3894)">B</text>

        <rect x="358.6" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 361.0356 396.7927)">C</text>
      </g>

      <g id="section_45" className='small-section'>
        <rect x="400.4" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 403.2623 378.447)">A</text>

        <rect x="400.4" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 400.9316 367.3894)" className='small-section__number'>45</text>
        <text transform="matrix(1 0 0 1 403.3316 387.3894)">B</text>

        <rect x="400.4" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 403.1642 396.7927)">C</text>
      </g>

      <g id="section_43" className='small-section'>
        <rect x="409.1" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 412.0631 378.447)">A</text>

        <rect x="409.1" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 409.7324 367.3894)" className='small-section__number'>43</text>
        <text transform="matrix(1 0 0 1 412.1324 387.3894)">B</text>

        <rect x="409.1" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 411.9645 396.7927)">C</text>
      </g>

      <g id="section_65" className='small-section'>
        <rect x="272.6" y="372.9" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 277.1158 378.447)">A</text>

        <rect x="272.6" y="382" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 274.7852 367.3894)" className='small-section__number'>65</text>
        <text transform="matrix(1 0 0 1 277.1852 387.3894)">B</text>

        <rect x="272.6" y="391" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 277.0177 396.7927)">C</text>
      </g>

      <g id="section_63" className='small-section'>
        <rect x="284.4" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 287.4426 378.447)">A</text>

        <rect x="284.4" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 285.2119 367.3894)" className='small-section__number'>63</text>
        <text transform="matrix(1 0 0 1 287.5119 387.3894)">B</text>

        <rect x="284.4" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 287.3444 396.7927)">C</text>
      </g>

      <g id="section_57" className='small-section'>
        <rect x="323.2" y="372.9" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 327.1799 378.447)">A</text>

        <rect x="323.2" y="382" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 324.9492 367.3894)" className='small-section__number'>57</text>
        <text transform="matrix(1 0 0 1 327.2492 387.3894)">B</text>

        <rect x="323.2" y="391" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 327.0817 396.7927)">C</text>
      </g>

      <g id="section_55" className='small-section'>
        <rect x="335" y="372.9" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 338.023 378.447)">A</text>

        <rect x="335" y="382" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 335.6924 367.3894)" className='small-section__number'>55</text>
        <text transform="matrix(1 0 0 1 338.0924 387.3894)">B</text>

        <rect x="335" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 337.9249 396.7927)">C</text>
      </g>

      <g id="section_49" className='small-section'>
        <rect x="373.5" y="372.9" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 377.8208 378.447)">A</text>

        <rect x="373.5" y="382" width="11.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 375.3901 367.3894)" className='small-section__number'>49</text>
        <text transform="matrix(1 0 0 1 377.8901 387.3894)">B</text>

        <rect x="373.5" y="391" width="11.8" height="7.8" />
        <text transform="matrix(1 0 0 1 377.7227 396.7927)">C</text>
      </g>

      <g id="section_47" className='small-section'>
        <rect x="385.3" y="372.9" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 388.1925 378.447)">A</text>

        <rect x="385.3" y="382" width="8.7" height="7.8"/>
        <text transform="matrix(1 0 0 1 385.8618 367.3894)" className='small-section__number'>47</text>
        <text transform="matrix(1 0 0 1 388.2618 387.3894)">B</text>

        <rect x="385.3" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 388.0943 396.7927)">C</text>
      </g>

      <g id="section_73" className='small-section'>
        <polygon points="233.5,372.9 242.8,372.9 242.8,380.8 229.9,380.8 	" />
        <text transform="matrix(1 0 0 1 236.2467 378.447)">A</text>

        <polygon points="229.1,382 236,382 236,389.8 225.9,389.8 	"/>
        <g>
          <text transform="matrix(1 0 0 1 228.9585 367.3913)" className='small-section__number'>73</text>
          <text transform="matrix(1 0 0 1 228.9585 387.3913)">B</text>
        </g>

        <polygon points="225.4,391 234.1,391 234.1,398.8 222.6,398.8 	" />
        <text transform="matrix(1 0 0 1 227.4957 396.7927)">C</text>
      </g>

      <g id="section_71" className='small-section'>
        <rect x="235.9" y="382" width="6.8" height="7.8"/>
        <text transform="matrix(1 0 0 1 236.3496 387.3894)">B</text>

        <rect x="234.1" y="391" width="8.7" height="7.8" />
        <text transform="matrix(1 0 0 1 237.8242 367.7927)" className='small-section__number'>71</text>
        <text transform="matrix(1 0 0 1 237.1242 396.7927)">C</text>
      </g>

      <g id="section_75" className='small-section'>
        <polygon points="226.3,372.9 222.1,368.8 216.8,374.4 223.2,380.5 	" />
        <text transform="matrix(1 0 0 1 220.0513 375.6472)">A</text>

        <polygon points="222.9,381.1 216.3,374.7 210.9,380.4 219.4,388.9 	"/>
        <text transform="matrix(1 0 0 1 218.6279 362.3747)" className='small-section__number'>75</text>
        <text transform="matrix(1 0 0 1 215.8279 382.3747)">B</text>

        <polygon points="219.7,390 210,381.4 204.3,386.9 216,398.1 	" />
        <text transform="matrix(1 0 0 1 210.2972 389.7077)">C</text>
      </g>

      <g id="section_77" className='small-section'>
        <polygon points="210.4,368.5 215.7,362.8 222.1,368.8 216.8,374.4 	" />
        <text transform="matrix(1 0 0 1 215.0792 370.1628)">A</text>

        <rect x="206.6" y="370.2" transform="matrix(0.6803 -0.7329 0.7329 0.6803 -207.2189 273.988)" width="7.8" height="8.7"/>
        <text transform="matrix(1 0 0 1 200.6396 367.9245)" className='small-section__number'>77</text>
        <text transform="matrix(1 0 0 1 209.0396 375.9245)">B</text>

        <polygon points="210,381.5 203.6,375.6 198.3,381.3 204.3,386.9 	" />
        <text transform="matrix(1 0 0 1 202.6031 382.8044)">C</text>
      </g>

      <g id="section_226">
        <rect x="220.1" y="464.3" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 232.8841 483.8205)">226</text>
      </g>

      <g id="section_225">
        <rect x="271" y="464.3" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 284.7148 483.8216)">225</text>
      </g>

      <g id="section_224">
        <rect x="321.9" y="464.3" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 334.6611 483.8208)">224</text>
      </g>

      <g id="section_222">
        <rect x="423.7" y="464.3" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 435.2162 483.8208)">222</text>
      </g>

      <g id="section_223">
        <rect x="372.7" y="464.3" width="44.3" height="27"/>
        <text transform="matrix(1 0 0 1 384.3676 482.154)">223</text>
      </g>

      <g id="section_207">
        <rect x="373" y="64.2" width="44.3" height="29.6"/>
        <text transform="matrix(1 0 0 1 384.3676 83.3783)">207</text>
      </g>

      <g id="section_107">
        <rect x="374" y="109" width="43.3" height="29"/>
        <text transform="matrix(1 0 0 1 385.3676 127.0111)">107</text>
      </g>

      <g id="section_106">
        <rect x="322.6" y="114.8" width="45.7" height="31.9"/>
        <text transform="matrix(1 0 0 1 334.7318 133.7611)">106</text>
      </g>

      <g id="section_105">
        <polygon points="271.9,109 271.9,146.7 316.9,146.7 316.9,109 	"/>
        <text transform="matrix(1 0 0 1 284.0423 133.7611)">105</text>
      </g>

      <g id="section_104">
        <polygon points="203,109.5 216.8,146.7 265.9,146.7 265.9,109 	"/>
        <text transform="matrix(1 0 0 1 228.4164 131.998)">104</text>
      </g>

      <g id="section_103">
        <path d="M211.5,147.9c-0.1-0.7-14-36-14-36l-30.7,30.7l22.9,25.4L211.5,147.9z"/>
        <text transform="matrix(0.9995 3.200000e-02 -3.200000e-02 0.9995 181.6337 146.0008)">103</text>
      </g>

      <g id="section_101">
        <polygon points="163,146.7 185.6,171.2 169.1,187.8 136.7,174.1 	"/>
        <text transform="matrix(0.9995 3.200000e-02 -3.200000e-02 0.9995 152.9977 173.2287)">101</text>
      </g>

      <g id="section_108">
        <rect x="423.8" y="114.8" width="45.5" height="31.9"/>
        <text transform="matrix(1 0 0 1 436.2538 133.7611)">108</text>
      </g>

      <g id="section_109">
        <rect x="474.6" y="109" width="45.6" height="37.7"/>
        <text transform="matrix(1 0 0 1 487.076 130.7046)">109</text>
      </g>

      <g id="section_110">
        <polygon points="525.4,109.5 525.4,146.7 574.5,146.7 590.8,109.5 	"/>
        <text transform="matrix(1 0 0 1 543.8263 130.7043)">110</text>
      </g>
      
      <g id="section_111">
        <polygon points="579.2,148.3 599.1,166.7 625.3,141.4 595.3,111.4 	"/>
        <text transform="matrix(1 0 0 1 589.8263 146.657)">111</text>
      </g>

      <g id="section_113">
        <polygon points="602.2,170.2 619.2,186.8 656.4,171.6 629,145.8 	"/>
        <text transform="matrix(1 0 0 1 615.553 171.157)">113</text>
      </g>

      <g id="section_114">
        <polygon points="622.7,191.9 660.8,178.7 660.8,250 624.6,250 	"/>
        <text transform="matrix(1 0 0 1 632.0225 220.657)">114</text>
      </g>

      <g id="section_115">
        <rect x="624.6" y="255.2" width="36.1" height="44.5"/>
        <text transform="matrix(1 0 0 1 632.0225 281.5381)">115</text>
      </g>

      <g id="section_116">
        <polygon points="624.6,305.4 660.8,305.4 660.8,369.2 624.6,355.4 	"/>
        <text transform="matrix(1 0 0 1 632.0225 337.2823)">116</text>
      </g>

      <g id="section_117">
        <polygon points="622.7,362 657.8,376.7 629,408 604.5,383 	"/>
        <text transform="matrix(1 0 0 1 619.5225 386.9141)">117</text>
      </g>

      <g id="section_119">
        <polygon points="580.2,408 596,442.8 625.8,412.4 601.5,385.7 	"/>
        <text transform="matrix(1 0 0 1 591.5225 414.2318)">119</text>
      </g>

      <g id="section_120">
        <polygon points="573.5,408 587.2,444 525.4,444 525.4,408 	"/>
        <text transform="matrix(1 0 0 1 540.7725 428.8372)">120</text>
      </g>

      <g id="section_121">
        <polygon points="518.9,408 518.9,444 474.5,444.1 474.5,408 	"/>
        <text transform="matrix(1 0 0 1 487.076 428.8372)">121</text>
      </g>

      <g id="section_122">
        <rect x="423.6" y="408" width="44.3" height="28.8"/>
        <text transform="matrix(1 0 0 1 435.2166 425.9066)">122</text>
      </g>

      <g id="section_123">
        <polygon points="417.8,419.2 417.8,445 373.4,445 373.4,417.2 417.8,417.2 	"/>
        <text transform="matrix(1 0 0 1 385.3678 434.558)">123</text>
      </g>

      <g id="section_124">
        <rect x="321.9" y="408" width="46.4" height="28.8"/>
        <text transform="matrix(1 0 0 1 335.3437 424.558)">124</text>
      </g>

      <g id="section_125">
        <rect x="272.2" y="408" width="44.2" height="37"/>
        <text transform="matrix(1 0 0 1 284.7148 428.8369)">125</text>
      </g>

      <g id="section_126">
        <polygon points="265.8,408 265.8,445 202.8,445 218,408 	"/>
        <text transform="matrix(1 0 0 1 226.2148 428.8375)">126</text>
      </g>

      <g id="section_127">
        <polygon points="212.8,407 196.8,442.8 166,411.7 193,386.7 	"/>
        <text transform="matrix(1 0 0 1 182.2148 414.2315)">127</text>
      </g>

      <g id="section_129">
        <polygon points="187.8,383 161.8,406.9 136.7,379 170.5,364.7 	"/>
        <text transform="matrix(1 0 0 1 156.2895 386.6573)">129</text>
      </g>

      <g id="section_221">
        <rect x="474.5" y="464.4" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 486.7305 483.8208)">221</text>
      </g>

      <g id="section_220">
        <rect x="525.4" y="464.4" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 536.6125 483.8208)">220</text>
      </g>

      <g id="section_218">
        <rect x="624.8" y="419.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -117.5881 589.6268)" width="56.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 643.2523 439.8065)">218</text>
      </g>

      <g id="section_216">
        <rect x="678.5" y="303" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 685.6465 330.1382)">216</text>
      </g>

      <g id="section_215">
        <rect x="678.6" y="259.8" width="29" height="36.9"/>
        <text transform="matrix(1 0 0 1 683.6465 282.419)">215</text>
      </g>

      <g id="section_214">
        <rect x="678.5" y="208.4" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 685.6279 235.532)">214</text>
      </g>

      <g id="section_219">
        <polygon points="576,464.3 576,498.2 615.7,498.2 640.9,473 616.6,448.6 600.3,464.3 	"/>
        <text transform="matrix(1 0 0 1 595.6715 481.2227)">219</text>
      </g>

      <g id="section_204">
        <rect x="220.5" y="59.9" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 232.8839 79.3778)">204</text>
      </g>

      <g id="section_205">
        <rect x="271.4" y="59.9" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 283.1556 79.3778)">205</text>
      </g>

      <g id="section_206">
        <rect x="322.2" y="59.9" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 334.3164 79.3778)">206</text>
      </g>

      <g id="section_208">
        <rect x="423.8" y="59.9" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 435.63 79.3778)">208</text>
      </g>

      <g id="section_209">
        <rect x="474.7" y="59.9" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 485.9932 79.3778)">209</text>
      </g>

      <g id="section_210">
        <rect x="525.5" y="59.9" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 536.9342 79.3778)">210</text>
      </g>

      <g id="section_211">
        <polygon points="576.1,93.3 576.1,59.4 615.8,59.4 641,84.6 616.7,109 600.4,93.3 	"/>
        <text transform="matrix(1 0 0 1 597.1486 81.3783)">211</text>
      </g>

      <g id="section_212">
        <rect x="635.2" y="93.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 105.5811 496.5265)" width="33.9" height="54.8"/>
        <text transform="matrix(1 0 0 1 642.4253 123.878)">212</text>
      </g>

      <g id="section_213">
        <polygon points="664.8,156.4 688.5,132.7 712.3,156.5 712.3,202 678.6,202 678.6,169.9 	"/>
        <text transform="matrix(1 0 0 1 685.6278 173.878)">213</text>
      </g>

      <g id="section_217">
        <polygon points="664.8,401 688.5,424.7 712.3,400.9 712.3,355.4 678.6,355.4 678.6,387.5 	"/>
        <text transform="matrix(1 0 0 1 684.6273 392.9828)">217</text>
      </g>

      <g id="section_227">
        <polygon points="172.1,446.4 148.1,470.4 176.2,498.4 211.9,498.4 211.9,464 189.3,463.6 	"/>
        <text transform="matrix(1 0 0 1 173.6401 485.1538)">227</text>
      </g>

      <g id="section_228">
        <rect x="119.8" y="407.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -267.6428 224.13)" width="33.9" height="55.3"/>
        <text transform="matrix(1 0 0 1 127.5483 442.8061)">228</text>
      </g>

      <g id="section_230">
        <rect x="76.4" y="303.6" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 83.6307 332.9193)">230</text>
      </g>

      <g id="section_231">
        <rect x="76.4" y="259.6" width="33.9" height="37.9"/>
        <text transform="matrix(1 0 0 1 83.6307 285.4193)">231</text>
      </g>

      <g id="section_232">
        <rect x="76.4" y="208.4" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 83.6307 238.5316)">232</text>
      </g>

      <g id="section_203">
        <polygon points="213.8,93.8 213.8,59.9 174.1,59.9 148.8,85.1 173.2,109.5 189.4,93.8 	"/>
        <text transform="matrix(1 0 0 1 173.64 84.378)">203</text>
      </g>

      <g id="section_202">
        <rect x="109.6" y="103.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -45.1023 132.3909)" width="55.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 127.5484 126.878)">202</text>
      </g>

      <g id="section_229">
        <polygon points="124.3,400 100.2,424.1 76.4,400.3 76.4,354.8 110.1,354.8 110.9,386.2 	"/>
        <text transform="matrix(1 0 0 1 83.6306 394.9825)">229</text>
      </g>

      <g id="section_201">
        <polygon points="123.9,155.7 100.2,132 76.4,155.9 76.4,201.4 110.1,201.4 110.1,169.2 	"/>
        <text transform="matrix(1 0 0 1 83.6309 172.1125)">201</text>
      </g>

      <g id="handicapped">
        <g>
          <g>
            <circle cx="394.4" cy="495.4" r="6"/>
            <path d="M394.4,501.8c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S397.9,501.8,394.4,501.8z
              M394.4,489.7c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,489.7,394.4,489.7z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,494.5L394.6,494.5c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,494,394.6,494.2,394.6,494.5C394.6,494.4,394.6,494.4,394.6,494.5z"/>
            </g>
            <g>
              <path d="M393.5,494.4c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,494.8,393,494.5,393.5,494.4
                C393.4,494.4,393.4,494.4,393.5,494.4z"/>
            </g>
            <g>
              <path d="M395,492.3c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,491.8,395,492.3z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="678.8" cy="434.6" r="6"/>
            <path d="M678.8,440.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S682.3,440.9,678.8,440.9z
              M678.8,428.9c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C684.5,431.4,681.9,428.9,678.8,428.9z"/>
          </g>
          <g>
            <g>
              <path d="M679,433.6C679,433.6,679.1,433.6,679,433.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C679,433.2,679,433.4,679,433.6L679,433.6z"/>
            </g>
            <g>
              <path d="M677.9,433.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C677,433.9,677.4,433.7,677.9,433.6
                C677.8,433.6,677.9,433.6,677.9,433.6z"/>
            </g>
            <g>
              <path d="M679.4,431.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S679.4,430.9,679.4,431.4z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="711.3" cy="278.3" r="6"/>
            <path d="M711.3,284.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S714.8,284.6,711.3,284.6z
              M711.3,272.6c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C717,275.1,714.4,272.6,711.3,272.6z"/>
          </g>
          <g>
            <g>
              <path d="M711.5,277.3C711.5,277.3,711.6,277.3,711.5,277.3c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C711.5,276.9,711.5,277,711.5,277.3C711.5,277.2,711.5,277.3,711.5,277.3z"/>
            </g>
            <g>
              <path d="M710.4,277.2c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C709.5,277.6,709.9,277.4,710.4,277.2
                C710.3,277.2,710.4,277.2,710.4,277.2z"/>
            </g>
            <g>
              <path d="M711.9,275.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S711.9,274.6,711.9,275.1z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="676.5" cy="123.8" r="6"/>
            <path d="M676.5,130.1c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C682.9,127.2,680,130.1,676.5,130.1z
              M676.5,118c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S679.7,118,676.5,118z"/>
          </g>
          <g>
            <g>
              <path d="M676.7,122.8C676.8,122.8,676.8,122.8,676.7,122.8c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C676.8,122.3,676.7,122.5,676.7,122.8C676.7,122.7,676.7,122.7,676.7,122.8z"/>
            </g>
            <g>
              <path d="M675.6,122.7c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C674.8,123.1,675.1,122.8,675.6,122.7L675.6,122.7z"/>
            </g>
            <g>
              <path d="M677.1,120.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C676.8,119.7,677.1,120.1,677.1,120.6
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="464.3" r="6"/>
            <path d="M394.4,470.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C400.7,467.8,397.9,470.6,394.4,470.6z
              M394.4,458.6c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C400.1,461.1,397.5,458.6,394.4,458.6z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,463.3L394.6,463.3c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,462.9,394.6,463.1,394.6,463.3L394.6,463.3z"/>
            </g>
            <g>
              <path d="M393.5,463.3c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,463.6,393,463.4,393.5,463.3
                C393.4,463.3,393.4,463.3,393.5,463.3z"/>
            </g>
            <g>
              <path d="M395,461.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,460.6,395,461.1z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="94.9" r="6"/>
            <path d="M394.4,101.2c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C400.7,98.4,397.9,101.2,394.4,101.2z
              M394.4,89.2c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,89.2,394.4,89.2z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,93.9L394.6,93.9c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,93.5,394.6,93.7,394.6,93.9L394.6,93.9z"/>
            </g>
            <g>
              <path d="M393.5,93.8c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,94.2,393,94,393.5,93.8
                C393.4,93.9,393.4,93.9,393.5,93.8z"/>
            </g>
            <g>
              <path d="M395,91.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,91.2,395,91.7z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="64.1" r="6"/>
            <path d="M394.4,70.5c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S397.9,70.5,394.4,70.5z M394.4,58.4
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,58.4,394.4,58.4z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,63.2L394.6,63.2c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,62.7,394.6,62.9,394.6,63.2C394.6,63.1,394.6,63.1,394.6,63.2z"/>
            </g>
            <g>
              <path d="M393.5,63.1c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,63.5,393,63.2,393.5,63.1
                C393.4,63.1,393.4,63.1,393.5,63.1z"/>
            </g>
            <g>
              <path d="M395,61c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,60.5,395,61z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="76.4" cy="217.9" r="6"/>
            <path d="M76.4,224.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S79.9,224.3,76.4,224.3z M76.4,212.2
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S79.6,212.2,76.4,212.2z"/>
          </g>
          <g>
            <g>
              <path d="M76.7,216.9L76.7,216.9c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C76.7,216.5,76.7,216.7,76.7,216.9L76.7,216.9z"/>
            </g>
            <g>
              <path d="M75.5,216.9c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C74.7,217.2,75.1,217,75.5,216.9L75.5,216.9z"/>
            </g>
            <g>
              <path d="M77.1,214.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S77.1,214.2,77.1,214.7z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="76.4" cy="336.7" r="6"/>
            <path d="M76.4,343c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S79.9,343,76.4,343z M76.4,331
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S79.6,331,76.4,331z"/>
          </g>
          <g>
            <g>
              <path d="M76.7,335.7L76.7,335.7c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C76.7,335.3,76.7,335.5,76.7,335.7L76.7,335.7z"/>
            </g>
            <g>
              <path d="M75.5,335.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C74.7,336,75.1,335.8,75.5,335.6L75.5,335.6z"/>
            </g>
            <g>
              <path d="M77.1,333.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S77.1,333,77.1,333.5z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="155.3" cy="397.5" r="6"/>
            <path d="M155.3,403.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S158.8,403.9,155.3,403.9z
              M155.3,391.8c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C161.1,394.4,158.5,391.8,155.3,391.8z"/>
          </g>
          <g>
            <g>
              <path d="M155.6,396.5L155.6,396.5c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C155.6,396.1,155.6,396.3,155.6,396.5L155.6,396.5z"/>
            </g>
            <g>
              <path d="M154.4,396.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C153.6,396.8,154,396.6,154.4,396.5L154.4,396.5z"/>
            </g>
            <g>
              <path d="M156,394.3c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S156,393.8,156,394.3z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="344.6" cy="437.9" r="6"/>
            <path d="M344.4,444.2c-3.5-0.1-6.3-3-6.1-6.5c0.1-3.5,3-6.3,6.5-6.1c3.5,0.1,6.3,3,6.1,6.5
              C350.8,441.6,347.9,444.3,344.4,444.2z M344.7,432.2c-3.2-0.1-5.8,2.4-5.9,5.5c-0.1,3.2,2.4,5.8,5.5,5.9s5.8-2.4,5.9-5.5
              C350.4,434.9,347.9,432.3,344.7,432.2z"/>
          </g>
          <g>
            <g>
              <path d="M344.8,436.9C344.8,436.9,344.9,436.9,344.8,436.9c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.4,0.4
                c0,0.2-0.1,0.4-0.4,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.3-0.1,0.7-0.5,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.4-1-0.6-1.4
                c0-0.1,0-0.1-0.1-0.1c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.1-1.8,0.1-2.7c0-0.2,0.2-0.4,0.4-0.4
                c0.2,0,0.4,0.1,0.5,0.4v0.1C344.8,436.5,344.8,436.7,344.8,436.9L344.8,436.9z"/>
            </g>
            <g>
              <path d="M343.7,436.8c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.2-1.1,0.7-1.3,1.4
                c-0.2,0.9,0.2,1.8,1,2.3c1,0.5,2.2,0.1,2.7-0.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.1-2.1-0.4-2.6-1.4s-0.3-2.3,0.5-3.1C342.8,437.2,343.2,437,343.7,436.8
                C343.6,436.8,343.7,436.8,343.7,436.8z"/>
            </g>
            <g>
              <path d="M345.3,434.7c0,0.5-0.4,0.9-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.9,0.9-0.8
                C344.9,433.8,345.3,434.3,345.3,434.7z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="444.7" cy="437.9" r="6"/>
            <path d="M444.5,444.2c-3.5-0.1-6.3-3-6.1-6.5c0.1-3.5,3-6.3,6.5-6.1c3.5,0.1,6.3,3,6.1,6.5S448,444.3,444.5,444.2z
              M444.9,432.2c-3.2-0.1-5.8,2.4-5.9,5.5c-0.1,3.2,2.4,5.8,5.5,5.9s5.8-2.4,5.9-5.5C450.5,434.9,448,432.3,444.9,432.2z"/>
          </g>
          <g>
            <g>
              <path d="M445,436.9L445,436.9c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.1,0.4-0.4,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.3-0.1,0.7-0.5,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.4-1-0.6-1.4c0-0.1,0-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.1-1.8,0.1-2.7c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.1,0.5,0.4v0.1
                C445,436.5,445,436.7,445,436.9L445,436.9z"/>
            </g>
            <g>
              <path d="M443.8,436.8c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.2-1.1,0.7-1.3,1.4
                c-0.2,0.9,0.2,1.8,1,2.3c1,0.5,2.2,0.1,2.7-0.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.1-2.1-0.4-2.6-1.4s-0.3-2.3,0.5-3.1C443,437.2,443.3,437,443.8,436.8L443.8,436.8z"/>
            </g>
            <g>
              <path d="M445.4,434.7c0,0.5-0.4,0.9-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.9,0.9-0.8
                C445.1,433.8,445.4,434.3,445.4,434.7z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="641.1" cy="394.6" r="6"/>
            <path d="M640.9,400.9c-3.5-0.1-6.3-3-6.1-6.5c0.1-3.5,3-6.3,6.5-6.1c3.5,0.1,6.3,3,6.1,6.5S644.4,401,640.9,400.9z
              M641.3,388.9c-3.2-0.1-5.8,2.4-5.9,5.5c-0.1,3.2,2.4,5.8,5.5,5.9c3.2,0.1,5.8-2.4,5.9-5.5S644.5,389,641.3,388.9z"/>
          </g>
          <g>
            <g>
              <path d="M641.4,393.6L641.4,393.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.4,0.4c0,0.2-0.1,0.4-0.4,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.3-0.1,0.7-0.5,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.4-1-0.6-1.4c0-0.1,0-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.1-1.8,0.1-2.7c0-0.2,0.2-0.4,0.4-0.4s0.4,0.1,0.5,0.4v0.1
                C641.4,393.2,641.4,393.4,641.4,393.6L641.4,393.6z"/>
            </g>
            <g>
              <path d="M640.3,393.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.2-1.1,0.7-1.3,1.4
                c-0.2,0.9,0.2,1.8,1,2.3c1,0.5,2.2,0.1,2.7-0.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.1-2.1-0.4-2.6-1.4s-0.3-2.3,0.5-3.1C639.4,393.9,639.8,393.7,640.3,393.5
                C640.2,393.5,640.2,393.5,640.3,393.5z"/>
            </g>
            <g>
              <path d="M641.9,391.4c0,0.5-0.4,0.9-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.9,0.9-0.8
                C641.5,390.5,641.9,390.9,641.9,391.4z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="641.8" cy="159.5" r="6"/>
            <path d="M641.6,165.8c-3.5-0.1-6.3-3-6.1-6.5s3-6.3,6.5-6.1c3.5,0.1,6.3,3,6.1,6.5
              C648,163.2,645.1,165.9,641.6,165.8z M641.9,153.8c-3.2-0.1-5.8,2.4-5.9,5.5c-0.1,3.2,2.4,5.8,5.5,5.9c3.2,0.1,5.8-2.4,5.9-5.5
              C647.6,156.5,645.1,153.9,641.9,153.8z"/>
          </g>
          <g>
            <g>
              <path d="M642,158.5C642,158.5,642.1,158.5,642,158.5c0.3,0,0.6,0,0.8,0s0.4,0.2,0.4,0.4s-0.1,0.4-0.4,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.3-0.1,0.7-0.5,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.4-1-0.6-1.4c0-0.1,0-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.1-1.8,0.1-2.7c0-0.2,0.2-0.4,0.4-0.4s0.4,0.1,0.5,0.4v0.1
                C642,158.1,642,158.3,642,158.5L642,158.5z"/>
            </g>
            <g>
              <path d="M640.9,158.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.2-1.1,0.7-1.3,1.4
                c-0.2,0.9,0.2,1.8,1,2.3c1,0.5,2.2,0.1,2.7-0.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.1-2.1-0.4-2.6-1.4s-0.3-2.3,0.5-3.1C640,158.8,640.4,158.5,640.9,158.4L640.9,158.4z"/>
            </g>
            <g>
              <path d="M642.5,156.3c0,0.5-0.4,0.9-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.9,0.9-0.8
                C642.1,155.4,642.5,155.8,642.5,156.3z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="555.1" cy="109.5" r="6"/>
            <path d="M554.9,115.8c-3.5-0.1-6.3-3-6.1-6.5s3-6.3,6.5-6.1c3.5,0.1,6.3,3,6.1,6.5
              C561.4,113.2,558.4,115.9,554.9,115.8z M555.3,103.8c-3.2-0.1-5.8,2.4-5.9,5.5c-0.1,3.2,2.4,5.8,5.5,5.9c3.2,0.1,5.8-2.4,5.9-5.5
              C560.9,106.5,558.4,103.9,555.3,103.8z"/>
          </g>
          <g>
            <g>
              <path d="M555.4,108.5L555.4,108.5c0.3,0,0.6,0,0.8,0s0.4,0.2,0.4,0.4s-0.1,0.4-0.4,0.5h-0.1c-0.3,0-0.5,0-0.8,0
                c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.2,0.8,1.7
                c0.1,0.3-0.1,0.7-0.5,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.4-1-0.6-1.4c0-0.1,0-0.1-0.1-0.1c-0.6,0-1.1,0-1.7-0.1
                c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.1-1.8,0.1-2.7c0-0.2,0.2-0.4,0.4-0.4s0.4,0.1,0.5,0.4v0.1
                C555.4,108.1,555.4,108.3,555.4,108.5L555.4,108.5z"/>
            </g>
            <g>
              <path d="M554.2,108.4c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.2-1.1,0.7-1.3,1.4
                c-0.2,0.9,0.2,1.8,1,2.3c1,0.5,2.2,0.1,2.7-0.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.1-2.1-0.4-2.6-1.4s-0.3-2.3,0.5-3.1C553.4,108.7,553.8,108.5,554.2,108.4L554.2,108.4z"/>
            </g>
            <g>
              <path d="M555.8,106.3c0,0.5-0.4,0.9-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.9,0.9-0.8
                C555.5,105.4,555.9,105.8,555.8,106.3z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="446.9" cy="114.8" r="6"/>
            <path d="M446.7,121.1c-3.5-0.1-6.3-3-6.1-6.5c0.1-3.5,3-6.3,6.5-6.1c3.5,0.1,6.3,3,6.1,6.5
              C453.1,118.5,450.2,121.2,446.7,121.1z M447,109.1c-3.2-0.1-5.8,2.4-5.9,5.5c-0.1,3.2,2.4,5.8,5.5,5.9c3.2,0.1,5.8-2.4,5.9-5.5
              C452.7,111.8,450.2,109.2,447,109.1z"/>
          </g>
          <g>
            <g>
              <path d="M447.1,113.8C447.1,113.8,447.2,113.8,447.1,113.8c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.4,0.4
                s-0.1,0.4-0.4,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.3-0.1,0.7-0.5,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.4-1-0.6-1.4
                c0-0.1,0-0.1-0.1-0.1c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.1-1.8,0.1-2.7c0-0.2,0.2-0.4,0.4-0.4
                c0.2,0,0.4,0.1,0.5,0.4v0.1C447.1,113.4,447.1,113.6,447.1,113.8L447.1,113.8z"/>
            </g>
            <g>
              <path d="M446,113.7c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.2-1.1,0.7-1.3,1.4c-0.2,0.9,0.2,1.8,1,2.3
                c1,0.5,2.2,0.1,2.7-0.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1c-0.4,0.6-1,1-1.8,1.1
                c-1.1,0.1-2.1-0.4-2.6-1.4s-0.3-2.3,0.5-3.1C445.1,114.1,445.5,113.8,446,113.7C445.9,113.7,446,113.7,446,113.7z"/>
            </g>
            <g>
              <path d="M447.6,111.6c0,0.5-0.4,0.9-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.9,0.9-0.8
                C447.2,110.7,447.6,111.1,447.6,111.6z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="347.6" cy="114.8" r="6"/>
            <path d="M347.4,121.1c-3.5-0.1-6.3-3-6.1-6.5s3-6.3,6.5-6.1c3.5,0.1,6.3,3,6.1,6.5
              C353.8,118.5,350.9,121.2,347.4,121.1z M347.8,109.1c-3.2-0.1-5.8,2.4-5.9,5.5c-0.1,3.2,2.4,5.8,5.5,5.9c3.2,0.1,5.8-2.4,5.9-5.5
              C353.4,111.8,350.9,109.2,347.8,109.1z"/>
          </g>
          <g>
            <g>
              <path d="M347.9,113.8L347.9,113.8c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.4,0.4s-0.1,0.4-0.4,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.3-0.1,0.7-0.5,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.4-1-0.6-1.4c0-0.1,0-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.1-1.8,0.1-2.7c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.1,0.5,0.4v0.1
                C347.9,113.4,347.9,113.6,347.9,113.8L347.9,113.8z"/>
            </g>
            <g>
              <path d="M346.7,113.7c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.2-1.1,0.7-1.3,1.4
                c-0.2,0.9,0.2,1.8,1,2.3c1,0.5,2.2,0.1,2.7-0.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.1-2.1-0.4-2.6-1.4s-0.3-2.3,0.5-3.1C345.9,114.1,346.3,113.8,346.7,113.7L346.7,113.7z"/>
            </g>
            <g>
              <path d="M348.3,111.6c0,0.5-0.4,0.9-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.9,0.9-0.8
                C348,110.7,348.4,111.1,348.3,111.6z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="157.3" cy="153.8" r="6"/>
            <path d="M157.1,160.1c-3.5-0.1-6.3-3-6.1-6.5c0.1-3.5,3-6.3,6.5-6.1c3.5,0.1,6.3,3,6.1,6.5S160.6,160.2,157.1,160.1z
              M157.5,148.1c-3.2-0.1-5.8,2.4-5.9,5.5c-0.1,3.2,2.4,5.8,5.5,5.9c3.2,0.1,5.8-2.4,5.9-5.5C163.1,150.8,160.6,148.2,157.5,148.1z
              "/>
          </g>
          <g>
            <g>
              <path d="M157.6,152.8L157.6,152.8c0.3,0,0.6,0,0.8,0s0.4,0.2,0.4,0.4s-0.1,0.4-0.4,0.5h-0.1c-0.3,0-0.5,0-0.8,0
                c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.2,0.8,1.7
                c0.1,0.3-0.1,0.7-0.5,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.4-1-0.6-1.4c0-0.1,0-0.1-0.1-0.1c-0.6,0-1.1,0-1.7-0.1
                c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.1-1.8,0.1-2.7c0-0.2,0.2-0.4,0.4-0.4s0.4,0.1,0.5,0.4v0.1
                C157.6,152.4,157.6,152.6,157.6,152.8L157.6,152.8z"/>
            </g>
            <g>
              <path d="M156.4,152.7c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.2-1.1,0.7-1.3,1.4
                c-0.2,0.9,0.2,1.8,1,2.3c1,0.5,2.2,0.1,2.7-0.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.1-2.1-0.4-2.6-1.4s-0.3-2.3,0.5-3.1C155.5,153,155.9,152.8,156.4,152.7L156.4,152.7z"/>
            </g>
            <g>
              <path d="M158,150.6c0,0.5-0.4,0.9-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.9,0.9-0.8C157.7,149.7,158,150.1,158,150.6
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="660.8" cy="279.8" r="6"/>
            <path d="M660.6,286.1c-3.5-0.1-6.3-3-6.1-6.5s3-6.3,6.5-6.1c3.5,0.1,6.3,3,6.1,6.5S664.1,286.2,660.6,286.1z
              M660.9,274.1c-3.2-0.1-5.8,2.4-5.9,5.5c-0.1,3.2,2.4,5.8,5.5,5.9c3.2,0.1,5.8-2.4,5.9-5.5C666.6,276.8,664.1,274.2,660.9,274.1z
              "/>
          </g>
          <g>
            <g>
              <path d="M661,278.8C661,278.8,661.1,278.8,661,278.8c0.3,0,0.6,0,0.8,0s0.4,0.2,0.4,0.4c0,0.2-0.1,0.4-0.4,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.3-0.1,0.7-0.5,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.4-1-0.6-1.4c0-0.1,0-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.4-0.2-0.4-0.4v-0.1c0-0.9,0.1-1.8,0.1-2.7c0-0.2,0.2-0.4,0.4-0.4s0.4,0.1,0.5,0.4v0.1
                C661,278.4,661,278.6,661,278.8L661,278.8z"/>
            </g>
            <g>
              <path d="M659.9,278.7c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.2-1.1,0.7-1.3,1.4
                c-0.2,0.9,0.2,1.8,1,2.3c1,0.5,2.2,0.1,2.7-0.9c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.1-2.1-0.4-2.6-1.4s-0.3-2.3,0.5-3.1C659,279.1,659.4,278.8,659.9,278.7
                C659.8,278.7,659.9,278.7,659.9,278.7z"/>
            </g>
            <g>
              <path d="M661.5,276.6c0,0.5-0.4,0.9-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.9,0.9-0.8
                C661.1,275.7,661.5,276.1,661.5,276.6z"/>
            </g>
          </g>
        </g>
      </g>
      
      <text transform="matrix(1.041 0 0 1 172.8765 261.8936)">ROUGH </text>
      <text transform="matrix(1.041 0 0 1 154.4443 282.5938)">STOCK END</text>
      <text transform="matrix(1.0071 0 0 1 529.0137 265.9023)">TIMED</text>
      <text transform="matrix(1.0071 0 0 1 508.6406 286.6025)">EVENT END</text>
      </svg>

    </div>
  )
}

export default Rodeo;
