import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
  return (
    <footer className='footer'>
      <div className="footerCol">
        <div className='footer__logo'>
          <Link to='/'>
            <img src='/img/a-rena-logo.png' alt='Dickies Arena' />
          </Link>
        </div>        
      </div>
      <div className="footerCol">
        <div className='footer__address'>
          3434 Trail Dr, Fort Worth, TX 76107
        </div>

        <div className='footer__phone'>
          (817) 402-9000
        </div>
      </div>
      <div className="footerCol">
        <div className='footer__social'>
          <a href='https://www.facebook.com/dickiesarena/' target='_blank' rel='noopener noreferrer'>
            <img src='/img/icon-facebook.png' alt='Facebook' />
          </a>

          <a href='https://twitter.com/DickiesArena' target='_blank' rel='noopener noreferrer'>
            <img src='/img/icon-twitter.png' alt='Twitter' />
          </a>

          <a href='https://www.instagram.com/dickiesarena/?hl=en' target='_blank' rel='noopener noreferrer'>
            <img src='/img/icon-insta.png' alt='Instagram' />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;