import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

function InfoModal(props) {
  const { section, selectedLayout, handleClose } = props;
  const [ sectionDetails, setSectionDetails ] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    
    switch (selectedLayout) {
      case 'end':
        if (+section > 0 && +section <= 16) {
          setSectionDetails({
            level: 'Floor',
            mens: '104, 110, 120, 126, 129',
            womens: '103, 111, 119, 127, 130',
            family: '104, 110, 120, 126',
            guest: 'Across from Section 116',
            aid: 'Northeast Concourse Entrance of North Lobby'
          });
        } else if (+section > 103 && +section <= 126) {
          setSectionDetails({
            level: '100',
            mens: '104, 110, 120, 126, 129',
            womens: '103, 111, 119, 127, 130',
            family: '104, 110, 120, 126',
            guest: 'Across from Section 116',
            aid: 'Northeast Concourse Entrance of North Lobby'
          });
        } else if (+section > 203 && +section <= 226) {
          setSectionDetails({
            level: '200',
            mens: '203, 211, 218, 227',
            womens: '202, 212, 219, 228',
            family: '202, 212, 218, 228',
            guest: 'Level 100 - across from Section 116',
            aid: 'Level 100 - Northeast Concourse Entrance of North Lobby'
          });
        }
        break;
      case 'center':
        if ((section >= 'A' && section <= 'H') || (+section > 13 && +section <= 132)) {
          setSectionDetails({
            level: '100',
            mens: '104, 110, 120, 126, 129',
            womens: '103, 111, 119, 127, 130',
            family: '104, 110, 120, 126',
            guest: 'Across from Section 116',
            aid: 'Northeast Concourse Entrance of North Lobby'
          });
        } else {
          setSectionDetails({
            level: '200',
            mens: '203, 211, 218, 227',
            womens: '202, 212, 219, 228',
            family: '202, 212, 218, 228',
            guest: 'Level 100 - across from Section 116',
            aid: 'Level 100 - Northeast Concourse Entrance of North Lobby'
          });
        }
        break;
      case 'basketball':
        if (+section > 13 && +section <= 132) {
          setSectionDetails({
            level: '100',
            mens: '104, 110, 120, 126, 129',
            womens: '103, 111, 119, 127, 130',
            family: '104, 110, 120, 126',
            guest: 'Across from Section 116',
            aid: 'Northeast Concourse Entrance of North Lobby'
          });
        } else {
          setSectionDetails({
            level: '200',
            mens: '203, 211, 218, 227',
            womens: '202, 212, 219, 228',
            family: '202, 212, 218, 228',
            guest: 'Level 100 - across from Section 116',
            aid: 'Level 100 - Northeast Concourse Entrance of North Lobby'
          });
        }
        break;
      case 'hockey':
        if (+section > 100 && +section <= 132) {
          setSectionDetails({
            level: '100',
            mens: '104, 110, 120, 126, 129',
            womens: '103, 111, 119, 127, 130',
            family: '104, 110, 120, 126',
            guest: 'Across from Section 116',
            aid: 'Northeast Concourse Entrance of North Lobby'
          });
        } else if (+section > 200 && +section <= 232) {
          setSectionDetails({
            level: '200',
            mens: '203, 211, 218, 227',
            womens: '202, 212, 219, 228',
            family: '202, 212, 218, 228',
            guest: 'Level 100 - across from Section 116',
            aid: 'Level 100 - Northeast Concourse Entrance of North Lobby'
          });
        }
        break;
      case 'rodeo':
        if ((+section > 0 && +section <= 100) || section === '72B') {
          setSectionDetails({
            level: 'Floor',
            mens: '104, 110, 120, 126, 129',
            womens: '103, 111, 119, 127, 130',
            family: '104, 110, 120, 126',
            guest: 'Across from Section 116',
            aid: 'Northeast Concourse Entrance of North Lobby'
          });
        } else if (+section > 100 && +section <= 129) {
          setSectionDetails({
            level: '100',
            mens: '104, 110, 120, 126, 129',
            womens: '103, 111, 119, 127, 130',
            family: '104, 110, 120, 126',
            guest: 'Across from Section 116',
            aid: 'Northeast Concourse Entrance of North Lobby'
          });
        } else if (+section > 200 && +section <= 232) {
          setSectionDetails({
            level: '200',
            mens: '203, 211, 218, 227',
            womens: '202, 212, 219, 228',
            family: '202, 212, 218, 228',
            guest: 'Level 100 - across from Section 116',
            aid: 'Level 100 - Northeast Concourse Entrance of North Lobby'
          });
        }
        break;
      default:
        break;
    }
  }, [ selectedLayout, section ]);

  return (
    <div className='modal__info' style={{ height: $('body').height() }}>
      <img className='modal__info__close' src='/img/icon-close.png' alt='Close' onClick={ e => handleClose() } />
      <h2>Section { section }</h2>

      <div className='modal__seating'>
        <div className='modal__seating__level'>
          <img src='/img/icon-marker.png' alt='point' />
          Level { sectionDetails && sectionDetails.level }, Section { section }
        </div>

        <div className='modal__seating__proximity'>
          <div className='modal__seating__proximity--section'>
            Men's Restrooms
            <span className='modal__seating__proximity--specific'>Section { sectionDetails && sectionDetails.mens }</span>
          </div>

          <div className='modal__seating__proximity--section'>
            Women's Restrooms
            <span className='modal__seating__proximity--specific'>Section { sectionDetails && sectionDetails.womens }</span>
          </div>

          <div className='modal__seating__proximity--section'>
            Family Restrooms
            <span className='modal__seating__proximity--specific'>Section { sectionDetails && sectionDetails.family }</span>
          </div>

          <div className='modal__seating__proximity--section'>
            Guest Services
            <span className='modal__seating__proximity--specific'>Section { sectionDetails && sectionDetails.guest }</span>
          </div>

          <div className='modal__seating__proximity--section'>
            First Aid
            <span className='modal__seating__proximity--specific'>Section { sectionDetails && sectionDetails.aid }</span>
          </div>
        </div>

        <Link to='/concourse-map' className='modal__seating__footer'>
          <div className='modal__seating__footer__copy'>
            <div>View Concourse Maps</div>
            <span>to find concessions and more</span>
          </div>
          
          <img src='/img/icon-stadium.png' alt='Concourse' />
        </Link>
      </div>
    </div>
  )
}

export default InfoModal;
