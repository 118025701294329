import React, { useState } from 'react';

function StatsExpander(props) {
  const { title, children } = props;
  const [ expand, setExpand ] = useState(false);

  return (
    <div className='info-expander'>
      <div className='info-expander__title' onClick={ e => setExpand(expand => !expand) }>
        { title }

        <span className={ expand ? 'info-expander__title__chevron down' : 'info-expander__title__chevron' }></span>
      </div>

      { expand && (
        <div
          className='info-expander__copy'>{ children }</div>
      ) }
    </div>
  )
}

export default StatsExpander;
