import React, { useEffect } from 'react';

import Footer from '../components/Footer';
import LandingCard from '../components/LandingCard';
import SlickCarousel from '../components/SlickCarousel';

function Landing(props) {
  const { fields } = props;
  // console.log('landing fiels ', fields);

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
  }, []);

  const landingCards = [
    { path: '/seating-chart', image: fields.landing_seat_link },
    { path: '/concourse-map', image: fields.landing_concession_link },
    { path: '/about', image: fields.landing_about_link },
    { path: '/directions', image: fields.landing_map_link },
    { path: '/guest-services', title: 'Guest Services' },
    { path: '/upcoming-events', title: 'Upcoming Events' }
  ]

  return (
    <div className='landing'>
      <div className='home_slider'>
        <SlickCarousel fields={ fields } />
      </div>

      <div className='landing__body'>
        <h2>Explore Dickies Arena Live</h2>

        <div className='landing__body__cards'>
          { landingCards.map((card, index) => (
            <LandingCard card={ card } key={ index } />
          ))}
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Landing;
