import React, { useEffect } from 'react';

function ConcertEnd(props) {
  const { focusedSection } = props;

  useEffect(() => {
    const elementToFocus = document.getElementById(`section_${ focusedSection }`);
    const focusedRect = document.getElementsByClassName('focused');

    if (focusedRect.length) {
      focusedRect[ 0 ].classList.remove('focused');
    }

    elementToFocus && elementToFocus.classList.add('focused');
  }, [ focusedSection ]);

  return (
    <div>
      <svg 
        easypz='{ "applyTransformTo":"svg > *", "modes": ["SIMPLE_PAN", "HOLD_ZOOM_IN", "CLICK_HOLD_ZOOM_OUT", "PINCH_ZOOM", "DBLCLICK_ZOOM_IN", "DBLRIGHTCLICK_ZOOM_OUT"], "options": { "minScale": 1, "maxScale": 2, "bounds": { "top": -50, "right": 50, "bottom": 50, "left": -50 }} }'
        version="1.1" 
        id="SeatingChartConcertEndStage"
        xmlns="http://www.w3.org/2000/svg"
        x="0px" 
        y="0px" 
        viewBox="60 60 680 470">
      <g id="section_226">
        <rect x="243.2" y="471.1" width="21.2" height="33.9"/>
        <text transform="matrix(1 0 0 1 243.8727 490.6143)">226</text>
      </g>

      <g id="section_225">
        <rect x="270.9" y="471" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 284.7148 490.6153)">225</text>
      </g>

      <g id="section_224">
        <rect x="321.8" y="471" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 334.6611 490.6143)">224</text>
      </g>

      <g id="section_222">
        <rect x="423.6" y="471" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 435.2162 490.6143)">222</text>
      </g>

      <g id="section_223">
        <rect x="372.8" y="471.1" width="44.3" height="27"/>
        <text transform="matrix(1 0 0 1 384.3676 488.9477)">223</text>
      </g>

      <g id="section_207">
        <g>
          <rect x="373" y="71" width="44.3" height="29.6"/>
        </g>
        <text transform="matrix(1 0 0 1 384.3676 90.1718)">207</text>
      </g>

      <g id="section_221">
        <rect x="474.5" y="471.1" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 486.7305 490.6143)">221</text>
      </g>

      <g id="section_220">
        <rect x="525.4" y="471.1" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 536.6125 490.6143)">220</text>
      </g>

      <g id="section_218">
        <rect x="624.9" y="426.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -122.2841 591.6638)" width="56.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 643.2523 446.6002)">218</text>
      </g>

      <g id="section_216">
        <rect x="678.5" y="309.7" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 685.6465 336.9318)">216</text>
      </g>

      <g id="section_215">
        <rect x="678.6" y="266.6" width="29" height="36.9"/>
        <text transform="matrix(1 0 0 1 683.6465 289.2126)">215</text>
      </g>

      <g id="section_214">
        <rect x="678.5" y="215.1" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 685.6279 242.3255)">214</text>
      </g>

      <g id="section_219">
        <polygon points="576,471.1 576,505 615.7,505 640.9,479.7 616.6,455.4 600.3,471.1 	"/>
        <text transform="matrix(1 0 0 1 595.6715 488.0164)">219</text>
      </g>

      <g id="section_204">
        <rect x="243.1" y="66.7" width="21.7" height="33.9"/>
        <text transform="matrix(1 0 0 1 244.8729 86.1715)">204</text>
      </g>

      <g id="section_205">
        <rect x="271.3" y="66.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 283.1556 86.1715)">205</text>
      </g>

      <g id="section_206">
        <rect x="322.1" y="66.6" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 334.3164 86.1715)">206</text>
      </g>

      <g id="section_208">
        <rect x="423.8" y="66.6" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 435.63 86.1715)">208</text>
      </g>

      <g id="section_209">
        <rect x="474.6" y="66.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 485.9932 86.1715)">209</text>
      </g>

      <g id="section_210">
        <rect x="525.4" y="66.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 536.9342 86.1715)">210</text>
      </g>

      <g id="section_211">
        <polygon points="576.1,100 576.1,66.1 615.8,66.1 641,91.4 616.7,115.7 600.4,100 	"/>
        <text transform="matrix(1 0 0 1 597.1486 88.1718)">211</text>
      </g>

      <g id="section_212">
        <rect x="635.2" y="100.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 100.8559 498.4828)" width="33.9" height="54.8"/>
        <text transform="matrix(1 0 0 1 642.4253 130.6717)">212</text>
      </g>
      
      <g id="section_213">
        <polygon points="664.8,163.1 688.5,139.5 712.3,163.3 712.3,208.8 678.6,208.8 678.6,176.6 	"/>
        <text transform="matrix(1 0 0 1 685.6278 180.6717)">213</text>
      </g>

      <g id="section_217">
        <polygon points="664.8,407.8 688.5,431.4 712.3,407.6 712.3,362.1 678.6,362.1 678.6,394.3 	"/>
        <text transform="matrix(1 0 0 1 684.6273 399.7764)">217</text>
      </g>

      <g id="section_125">
        <rect x="271.2" y="354.5" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 284.1556 407.281)">125</text>
      </g>

      <g id="section_124">
        <rect x="322.1" y="354.5" width="44.3" height="95.5"/>
        <text transform="matrix(1 0 0 1 333.7278 407.281)">124</text>
      </g>

      <g id="section_123">
        <rect x="372.8" y="354.5" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 385.3674 407.281)">123</text>
      </g>

      <g id="section_122">
        <rect x="423.6" y="354.5" width="44.3" height="95.5"/>
        <text transform="matrix(1 0 0 1 435.1071 407.281)">122</text>
      </g>

      <g id="section_121">
        <rect x="474.3" y="354.5" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 486.9934 407.281)">121</text>
      </g>

      <g id="section_109">
        <rect x="474.3" y="116.2" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 486.9934 166.0953)">109</text>
      </g>

      <g id="section_108">
        <rect x="423.6" y="120.8" width="44.3" height="95.5"/>
        <text transform="matrix(1 0 0 1 435.216 166.0953)">108</text>
      </g>

      <g id="section_107">
        <rect x="372.8" y="116.2" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 385.3671 166.0953)">107</text>
      </g>

      <g id="section_106">
        <rect x="322.1" y="120.8" width="44.3" height="95.5"/>
        <text transform="matrix(1 0 0 1 334.3165 166.0953)">106</text>
      </g>

      <g id="section_105">
        <rect x="271.2" y="116.2" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 283.1567 166.0953)">105</text>
      </g>

      <g id="section_120">
        <polygon points="525.4,354.5 547.6,354.5 588.7,454.2 588.7,459.6 525.4,459.6 	"/>
        <text transform="matrix(1 0 0 1 535.934 407.281)">120</text>
      </g>

      <g id="section_110">
        <polygon points="525.4,116.2 525.4,216 547.4,216.3 588.7,116.2 588.7,111.3 525.4,111.3 	"/>
        <text transform="matrix(1 0 0 1 535.2062 166.0953)">110</text>
      </g>

      <g id="section_119">
        <polygon points="578.5,374.2 580.6,371.9 558.9,350.2 554.5,354.5 595.2,453 625.7,422.7 	"/>
        <text transform="matrix(1 0 0 1 585.2625 418.3359)">119</text>
      </g>

      <g id="section_117">
        <polygon points="562.8,346.6 558.9,350.2 580.6,371.9 582.5,369.7 627.4,415.5 658,385 	"/>
        <text transform="matrix(1 0 0 1 611.3692 387.828)">117</text>
      </g>

      <g id="section_116">
        <polygon points="662.5,379.8 662.5,313.3 562.8,313.3 562.8,338.8 	"/>
        <text transform="matrix(1 0 0 1 604.6299 340.031)">116</text>
      </g>

      <g id="section_115">
        <rect x="562.8" y="263" width="95.2" height="44.3"/>
        <text transform="matrix(1 0 0 1 604.6299 289.2132)">115</text>
      </g>

      <g id="section_114">
        <polygon points="662.5,190.7 662.5,257.2 562.8,257.2 562.8,231.7 	"/>
        <text transform="matrix(1 0 0 1 604.6299 236.2132)">114</text>
      </g>

      <g id="section_113">
        <polygon points="626.2,148.8 595,182.2 602,189.2 582.7,208.5 589,214.8 661,184.7 	"/>
        <text transform="matrix(1 0 0 1 620.3003 185.0872)">113</text>
      </g>

      <g id="section_111">
        <polygon points="594.8,116.2 564,191.5 569.8,197.2 589.9,177.1 595,182.2 626.2,148.8 	"/>
        <text transform="matrix(1 0 0 1 589.4105 156.0955)">111</text>
      </g>

      <g id="section_104">
        <polygon points="243.1,111.3 243.1,213.7 265.2,213.6 265.2,116.2 265.2,111.3 	"/>
        <text transform="matrix(1 0 0 1 243.8729 166.0953)">104</text>
      </g>

      <g id="section_126">
        <polygon points="243.2,357.7 243.2,459.1 265.2,459.1 265.2,454.2 265.2,357.6 	"/>
        <text transform="matrix(1 0 0 1 243.8727 407.281)">126</text>
      </g>

      <g id="section_1">
        <rect x="255.6" y="224.2" width="57.7" height="36.2"/>
        <text transform="matrix(1 0 0 1 290.7032 245.6876)">1</text>
        <rect x="323.4" y="224.2" width="40.1" height="36.2"/>
        <text transform="matrix(1 0 0 1 340.1268 245.6876)">1</text>
      </g>

      <g id="section_4">
        <rect x="373.9" y="224.2" width="40.1" height="36.2"/>
        <text transform="matrix(1 0 0 1 389.6853 245.6876)">4</text>
        <rect x="423.8" y="224.2" width="67" height="36.2"/>
        <text transform="matrix(1 0 0 1 452.32 245.6876)">4</text>
      </g>

      <g id="section_2">
        <rect x="255.6" y="265.3" width="107.9" height="40.8"/>
        <text transform="matrix(1 0 0 1 291.5782 289.7271)">2</text>
      </g>

      <g id="section_5">
        <rect x="373.9" y="265.3" width="17.8" height="40.8"/>
        <text transform="matrix(1 0 0 1 379.5031 289.7271)">5</text>
      </g>

      <g id="section_3">
        <rect x="323.4" y="310.3" width="40.1" height="26.4"/>
        <rect x="255.6" y="310.3" width="57.7" height="36.2"/>
        <text transform="matrix(1 0 0 1 291.3361 331.4541)">3</text>
        <text transform="matrix(1 0 0 1 339.7921 326.5725)">3</text>
      </g>

      <g id="section_6">
        <rect x="373.9" y="310.3" width="40.1" height="36.2"/>
        <rect x="423.8" y="310.3" width="67" height="36.2"/>
        <text transform="matrix(1 0 0 1 390.4018 331.4537)">6</text>
        <text transform="matrix(1 0 0 1 452.3201 331.4537)">6</text>
      </g>

      <g id="section_14">
        <rect x="501.7" y="234.3" width="58.3" height="22.9"/>
        <text transform="matrix(1 0 0 1 523.359 250.0953)">14</text>
      </g>

      <g id="section_15">
        <rect x="515.7" y="263" width="44.3" height="44.3"/>
        <text transform="matrix(1 0 0 1 531.5234 288.6791)">15</text>
      </g>

      <g id="section_16">
        <rect x="501.7" y="313.3" width="58.3" height="22.9"/>
        <text transform="matrix(1 0 0 1 522.0099 328.3838)">16 </text>
      </g>

      <g id="STAGE">
        <rect x="176.8" y="238" width="63.5" height="94.5"/>
        <text transform="matrix(0 -1.098 1 0 212.9277 307.7734)">STAGE</text>
      </g>

      <g id="mix">
        <text transform="matrix(1 0 0 1 412.9392 288.7277)">MIX</text>
        <rect x="396" y="267" width="56.7" height="37.3"/>
      </g>

      <g id="UNAVAILABLE">
        <rect x="76.4" y="266.4" width="33.9" height="37.9"/>
        <rect x="76.4" y="215.1" width="33.9" height="45.2"/>
        <rect x="76.4" y="310.3" width="33.9" height="45.2"/>
        
        <rect x="119.7" y="414.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -272.4852 226.0521)" width="33.9" height="55.3"/>
        <rect x="220.1" y="471.1" width="23.1" height="33.9"/>
        <polygon points="172.1,453.2 148.1,477.1 176.2,505.2 211.9,505.2 211.9,470.8 189.3,470.4 	"/>
        <polygon points="213.8,100.5 213.8,66.6 174.1,66.6 148.8,91.9 173.2,116.2 189.4,100.5 	"/>
        <rect x="220.5" y="66.6" width="22.6" height="33.9"/>
        
        <rect x="109.6" y="110.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -49.9084 134.3765)" width="55.3" height="33.9"/>
        <polygon points="124.3,406.7 100.2,430.9 76.4,407 76.4,361.5 110.1,361.5 110.9,393 	"/>
        <polygon points="123.9,162.5 100.2,138.8 76.4,162.6 76.4,208.1 110.1,208.1 110.1,176 	"/>
        <polygon points="242.7,111.3 243.1,213.7 202,116.2 202,111.3 	"/>
        <polygon points="242.2,459.1 202,459.1 202,454.2 243.2,357.7 	"/>
        <polygon points="193.8,117.5 197,120.8 225.5,190.5 209.6,206.2 202,198.7 207,193.2 162.6,149 	"/>
        <polygon points="194.3,454.2 197.5,450.8 225.3,380.9 209.2,365.3 201.6,372.9 206.7,378.3 162.8,423 	"/>
        <path d="M186.5,180c-2.4-1.2-25-23.9-25-23.9L144.9,173l-10.2,10.4l6.6,2.7l27.1,11.3L186.5,180z"/>
        <polygon points="185.1,391.2 161.5,414.4 133.9,387.3 167.5,372.7 	"/>
      </g>
      
      <g id="handicapped">
        <g>
          <g>
            <circle cx="343.7" cy="341.3" r="6"/>
            <path d="M343.7,347.7c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S347.2,347.7,343.7,347.7z
              M343.7,335.6c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C349.4,338.2,346.9,335.6,343.7,335.6z"/>
          </g>
          <g>
            <g>
              <path d="M344,340.3L344,340.3c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C344,339.9,344,340.1,344,340.3L344,340.3z"/>
            </g>
            <g>
              <path d="M342.8,340.3c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C342,340.6,342.3,340.4,342.8,340.3L342.8,340.3z"/>
            </g>
            <g>
              <path d="M344.3,338.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C344,337.2,344.4,337.6,344.3,338.1z"
                />
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="344.4" cy="450.7" r="6"/>
            <path d="M344.4,457c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S347.9,457,344.4,457z M344.4,445
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C350.1,447.5,347.5,445,344.4,445z"/>
          </g>
          <g>
            <g>
              <path d="M344.6,449.7L344.6,449.7c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C344.6,449.3,344.6,449.4,344.6,449.7C344.6,449.6,344.6,449.7,344.6,449.7z"/>
            </g>
            <g>
              <path d="M343.5,449.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C342.6,450,343,449.8,343.5,449.6
                C343.4,449.6,343.4,449.6,343.5,449.6z"/>
            </g>
            <g>
              <path d="M345,447.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S345,447,345,447.5z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="344.4" cy="119.8" r="6"/>
            <path d="M344.4,126.2c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C350.7,123.3,347.9,126.2,344.4,126.2z
              M344.4,114.1c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S347.5,114.1,344.4,114.1z"/>
          </g>
          <g>
            <g>
              <path d="M344.6,118.8L344.6,118.8c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C344.6,118.4,344.6,118.6,344.6,118.8L344.6,118.8z"/>
            </g>
            <g>
              <path d="M343.5,118.8c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C342.6,119.2,343,118.9,343.5,118.8
                C343.4,118.8,343.4,118.8,343.5,118.8z"/>
            </g>
            <g>
              <path d="M345,116.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C344.6,115.8,345,116.2,345,116.6z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="446" cy="119.8" r="6"/>
            <path d="M446,126.2c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C452.3,123.3,449.5,126.2,446,126.2z
              M446,114.1c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S449.1,114.1,446,114.1z"/>
          </g>
          <g>
            <g>
              <path d="M446.2,118.8L446.2,118.8c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C446.2,118.4,446.2,118.6,446.2,118.8L446.2,118.8z"/>
            </g>
            <g>
              <path d="M445.1,118.8c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C444.2,119.2,444.6,118.9,445.1,118.8
                C445,118.8,445,118.8,445.1,118.8z"/>
            </g>
            <g>
              <path d="M446.6,116.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C446.2,115.8,446.6,116.2,446.6,116.6
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="557.1" cy="111.3" r="6"/>
            <path d="M557.1,117.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S560.5,117.6,557.1,117.6z
              M557.1,105.6c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S560.2,105.6,557.1,105.6z"/>
          </g>
          <g>
            <g>
              <path d="M557.3,110.3L557.3,110.3c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C557.3,109.9,557.3,110.1,557.3,110.3L557.3,110.3z"/>
            </g>
            <g>
              <path d="M556.1,110.2c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C555.3,110.6,555.7,110.4,556.1,110.2L556.1,110.2z"/>
            </g>
            <g>
              <path d="M557.7,108.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S557.7,107.6,557.7,108.1z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="678.8" cy="441.4" r="6"/>
            <path d="M678.8,447.7c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S682.3,447.7,678.8,447.7z
              M678.8,435.6c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S681.9,435.6,678.8,435.6z"/>
          </g>
          <g>
            <g>
              <path d="M679,440.4C679,440.4,679.1,440.4,679,440.4c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C679,439.9,679,440.1,679,440.4C679,440.3,679,440.3,679,440.4z"/>
            </g>
            <g>
              <path d="M677.9,440.3c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C677,440.7,677.4,440.4,677.9,440.3
                C677.8,440.3,677.9,440.3,677.9,440.3z"/>
            </g>
            <g>
              <path d="M679.4,438.2c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C679,437.3,679.4,437.7,679.4,438.2z"
                />
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="711.3" cy="285" r="6"/>
            <path d="M711.3,291.4c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C717.6,288.5,714.8,291.4,711.3,291.4z
              M711.3,279.3c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S714.4,279.3,711.3,279.3z"/>
          </g>
          <g>
            <g>
              <path d="M711.5,284C711.5,284,711.6,284,711.5,284c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V286c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C711.5,283.6,711.5,283.8,711.5,284L711.5,284z"/>
            </g>
            <g>
              <path d="M710.4,284c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C709.5,284.3,709.9,284.1,710.4,284
                C710.3,284,710.4,284,710.4,284z"/>
            </g>
            <g>
              <path d="M711.9,281.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C711.5,281,711.9,281.4,711.9,281.8z"
                />
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="676.5" cy="130.5" r="6"/>
            <path d="M676.5,136.8c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C682.9,134,680,136.8,676.5,136.8z
              M676.5,124.8c-3.2,0-5.7,2.6-5.7,5.7c0,3.1,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C682.2,127.4,679.7,124.8,676.5,124.8z"/>
          </g>
          <g>
            <g>
              <path d="M676.7,129.5C676.8,129.5,676.8,129.5,676.7,129.5c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C676.8,129.1,676.7,129.3,676.7,129.5L676.7,129.5z"/>
            </g>
            <g>
              <path d="M675.6,129.5c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C674.8,129.8,675.1,129.6,675.6,129.5L675.6,129.5z"/>
            </g>
            <g>
              <path d="M677.1,127.3c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C676.8,126.4,677.1,126.8,677.1,127.3
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="650.2" cy="173.5" r="6"/>
            <path d="M650.2,179.8c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S653.6,179.8,650.2,179.8z
              M650.2,167.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S653.3,167.7,650.2,167.7z"/>
          </g>
          <g>
            <g>
              <path d="M650.4,172.5L650.4,172.5c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C650.4,172.1,650.4,172.2,650.4,172.5C650.4,172.4,650.4,172.4,650.4,172.5z"/>
            </g>
            <g>
              <path d="M649.2,172.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C648.4,172.8,648.8,172.5,649.2,172.4L649.2,172.4z"/>
            </g>
            <g>
              <path d="M650.8,170.3c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C650.4,169.4,650.8,169.8,650.8,170.3
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="658" cy="285.6" r="6"/>
            <path d="M658,292c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C664.3,289.1,661.5,292,658,292z
              M658,279.9c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S661.2,279.9,658,279.9z"/>
          </g>
          <g>
            <g>
              <path d="M658.2,284.6C658.3,284.6,658.3,284.6,658.2,284.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C658.2,284.2,658.2,284.4,658.2,284.6L658.2,284.6z"/>
            </g>
            <g>
              <path d="M657.1,284.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C656.2,284.9,656.6,284.7,657.1,284.6L657.1,284.6z"/>
            </g>
            <g>
              <path d="M658.6,282.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C658.2,281.5,658.6,281.9,658.6,282.4
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="641.4" cy="401.3" r="6"/>
            <path d="M641.4,407.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S644.9,407.6,641.4,407.6z
              M641.4,395.6c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C647.1,398.1,644.5,395.6,641.4,395.6z"/>
          </g>
          <g>
            <g>
              <path d="M641.6,400.3C641.6,400.3,641.7,400.3,641.6,400.3c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C641.6,399.9,641.6,400,641.6,400.3C641.6,400.2,641.6,400.3,641.6,400.3z"/>
            </g>
            <g>
              <path d="M640.5,400.2c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C639.6,400.6,640,400.4,640.5,400.2L640.5,400.2z"/>
            </g>
            <g>
              <path d="M642,398.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S642,397.6,642,398.1z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="554.5" cy="459.6" r="6"/>
            <path d="M554.5,466c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C560.8,463.1,558,466,554.5,466z
              M554.5,453.9c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S557.7,453.9,554.5,453.9z"/>
          </g>
          <g>
            <g>
              <path d="M554.7,458.6C554.8,458.6,554.8,458.6,554.7,458.6c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C554.7,458.2,554.7,458.4,554.7,458.6L554.7,458.6z"/>
            </g>
            <g>
              <path d="M553.6,458.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C552.7,458.9,553.1,458.7,553.6,458.6L553.6,458.6z"/>
            </g>
            <g>
              <path d="M555.1,456.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C554.7,455.5,555.1,455.9,555.1,456.4
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="471.1" r="6"/>
            <path d="M394.4,477.4c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S397.9,477.4,394.4,477.4z
              M394.4,465.3c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,465.3,394.4,465.3z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,470.1L394.6,470.1c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,469.6,394.6,469.8,394.6,470.1C394.6,470,394.6,470,394.6,470.1z"/>
            </g>
            <g>
              <path d="M393.5,470c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,470.4,393,470.1,393.5,470
                C393.4,470,393.4,470,393.5,470z"/>
            </g>
            <g>
              <path d="M395,467.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,467.4,395,467.9z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="101.6" r="6"/>
            <path d="M394.4,108c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S397.9,108,394.4,108z M394.4,95.9
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,95.9,394.4,95.9z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,100.7L394.6,100.7c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,100.2,394.6,100.4,394.6,100.7C394.6,100.6,394.6,100.6,394.6,100.7z"/>
            </g>
            <g>
              <path d="M393.5,100.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,101,393,100.7,393.5,100.6
                C393.4,100.6,393.4,100.6,393.5,100.6z"/>
            </g>
            <g>
              <path d="M395,98.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,98,395,98.5z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="70.9" r="6"/>
            <path d="M394.4,77.2c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S397.9,77.2,394.4,77.2z M394.4,65.2
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C400.1,67.7,397.5,65.2,394.4,65.2z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,69.9L394.6,69.9c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,69.5,394.6,69.7,394.6,69.9L394.6,69.9z"/>
            </g>
            <g>
              <path d="M393.5,69.8c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,70.2,393,70,393.5,69.8
                C393.4,69.9,393.4,69.9,393.5,69.8z"/>
            </g>
            <g>
              <path d="M395,67.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,67.2,395,67.7z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="446" cy="450.7" r="6"/>
            <path d="M446,457c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S449.5,457,446,457z M446,445
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C451.7,447.5,449.1,445,446,445z"/>
          </g>
          <g>
            <g>
              <path d="M446.2,449.7L446.2,449.7c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C446.2,449.3,446.2,449.4,446.2,449.7C446.2,449.6,446.2,449.7,446.2,449.7z"/>
            </g>
            <g>
              <path d="M445.1,449.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C444.2,450,444.6,449.8,445.1,449.6
                C445,449.6,445,449.6,445.1,449.6z"/>
            </g>
            <g>
              <path d="M446.6,447.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S446.6,447,446.6,447.5z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="502.2" r="6"/>
            <path d="M394.4,508.5c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C400.7,505.7,397.9,508.5,394.4,508.5z
              M394.4,496.5c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C400.1,499,397.5,496.5,394.4,496.5z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,501.2L394.6,501.2c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,500.8,394.6,501,394.6,501.2L394.6,501.2z"/>
            </g>
            <g>
              <path d="M393.5,501.1c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,501.5,393,501.3,393.5,501.1
                C393.4,501.2,393.4,501.2,393.5,501.1z"/>
            </g>
            <g>
              <path d="M395,499c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,498.5,395,499z"/>
            </g>
          </g>
        </g>
      </g>
      </svg>

    </div>
  )
}

export default ConcertEnd;
