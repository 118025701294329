import React from 'react';

function Other(props) {
  const { showHome, stats } = props;
  console.log('showHome ', showHome);
  
  // let generatedRows = '';

  // placeholder - delete
  let generatedRows = `
    <tr>
      <td>Mr. Sports</td>
      <td class='numeric'>2</td>
      <td class='numeric'>6</td>
      <td class='numeric'>10</td>
    </tr>
  `;

  if (stats) {
    let others = [];
    stats.forEach(summary => {
      if (summary.stats && summary.stats[ 0 ].$.hasOwnProperty('fga')) {
        let othersObj = {
          name: summary.$.name,
          pf: +summary.stats[ 0 ].$.pf,
          to: +summary.stats[ 0 ].$.to,
          min: +summary.stats[ 0 ].$.min,
          blocks: +summary.stats[ 0 ].$.tp
        }

        if (othersObj.pf || othersObj.to || othersObj.min) {
          others.push(othersObj);
        }
      }
    });

    others.forEach(other => {
      generatedRows = generatedRows + `
          <tr>
            <td>${ other.name || ' ' }</td>
            <td class='numeric'>${ other.pf || 0 }</td>
            <td class='numeric'>${ other.to || 0 }</td>
            <td class='numeric'>${ other.min || 0 }</td>
          </tr>
        `;
    })

    let totals = others.reduce((total, current) => {
      total = {
        name: 'Team Totals',
        pf: (total.pf || 0) + current.pf,
        to: (total.to || 0) + current.to,
        min: (total.min || 0) + current.min
      };

      return total;
    }, {});

    generatedRows = generatedRows + `
        <tr>
          <td>${ totals.name || ' ' }</td>
          <td class='numeric'>${ totals.pf || 0 }</td>
          <td class='numeric'>${ totals.to || 0 }</td>
          <td class='numeric'>${ totals.min || 0 }</td>
        </tr>
      `;
  }

  return (
    <div className='sport__stats__table'>
      <table className='sport__stats__table__body'>
        <thead>
          <tr>
            <td>Name</td>
            <td className='numeric'>Personal Fouls</td>
            <td className='numeric'>Turn Overs</td>
            <td className='numeric'>Min</td>
          </tr>
        </thead>

        { generatedRows && (
          <tbody dangerouslySetInnerHTML={ { __html: generatedRows } }></tbody>
        ) }
      </table>
    </div>
  )
}

export default Other;
