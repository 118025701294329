import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function OtherEventsSlider(props) {
  const { events } = props;
  const [ eventsWithDetails, setEventsWithDetails ] = useState([]);

  useEffect(() => {
    async function getEventsDetails(events) {
      const eventDetailPromises = events.map(async event => {
        const res = await axios.get(`http://35.153.188.133/wp-json/wp/v2/event/${ event.ID }`);
        return res.data;
      });

      const eventDetailArray = await Promise.all(eventDetailPromises);
      setEventsWithDetails(eventDetailArray);
    }

    getEventsDetails(events);
  }, [ events ]);

  const settings = {
    dots: false,
    speed: 2000,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          centerMode: true,
          infinite: true,
        }
      },
    ]
  };

  return (
    <div className='ohterEventSlider'>
      <h3>OTHER EVENTS YOU MAY LIKE</h3>
      <div className='events-slider slider'>
        <Slider { ...settings }>
          {eventsWithDetails.map(event => (
            <div className='slide' key={ event.id }>
              <Link to={ `/event/${ event.id }` }>
                <img src={ event.acf.event_image.url } alt={ event.acf.event_title } />
                <h2>{ event.acf.event_title }</h2>
                <p>{ event.acf.event_date }</p>
              </Link>
            </div>
          )) }
        </Slider>
      </div>

      <Link to='/upcoming-events' className='btn btn-outline-primary'>All Upcoming Events</Link>
    </div>
  )
}

export default OtherEventsSlider;