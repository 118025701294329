import React, { useEffect } from 'react';
import $ from 'jquery';

function UpperLevel(props) {
  const { focusedSection } = props;

  useEffect(() => {
    if (focusedSection) {
      let elementToFocus = document.getElementById(`section_${ focusedSection }`);
      $('.focused').removeClass('focused');

      const multiPoints = [ 'elevators', 'mens-restrooms', 'family-restrooms', 'womens-restrooms' ];

      if (multiPoints.includes(focusedSection)) {
        $(`.${ focusedSection }`).addClass('focused');
      }

      elementToFocus && elementToFocus.classList.add('focused');
    }
  }, [ focusedSection ]);

  return (
    <div className='upper-level'>
      <svg 
        easypz='{ "applyTransformTo":"svg > *", "modes": ["SIMPLE_PAN", "HOLD_ZOOM_IN", "CLICK_HOLD_ZOOM_OUT", "PINCH_ZOOM", "DBLCLICK_ZOOM_IN", "DBLRIGHTCLICK_ZOOM_OUT"], "options": { "minScale": 1, "maxScale": 2, "bounds": { "top": -50, "right": 50, "bottom": 50, "left": -50 }} }'
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        x="0px" 
        y="0px"
        viewBox="150 150 2750 1950">
      
      <path id="bottom_fill" className="st0" d="M2775.6,925.4c-5.1-34.8-12-71.5-21.2-110l-24.1-40.3l-31.1-4.1V656.7h-118.9V373.8H2352
        c0,0-5.9-108.5-92.7-111.6h-175l-57.1-27.3h-254.3v-15.2c0,0,0,0-0.1,0v-10.8c0,0-95.6-58.4-239.1-54.2
        c-143.5-4.2-239.1,54.2-239.1,54.2v10.8c0,0,0,0-0.1,0v15.2h-254.3l-57.1,27.3h-175c-86.8,3.2-92.7,111.6-92.7,111.6H487v282.9
        H317.6l-30.4,45.9v105.7l13.1,7c-24.8,93.4-32.2,182.1-31,258.7c-1.1,76.6,6.2,165.3,31,258.7l-13.1,7v105.7l30.4,45.9H487v282.9
        h228.4c0,0,5.9,108.5,92.7,111.6h175l57.1,27.3h254.3v15.2c0,0,0,0,0.1,0v10.8c0,0,95.6,58.4,239.1,54.2
        c143.5,4.2,239.1-54.2,239.1-54.2v-10.8c0,0,0,0,0.1,0v-15.2h254.3l57.1-27.3h175c86.8-3.2,92.7-111.6,92.7-111.6h228.4v-282.9
        h118.9V1377l31.1-4.1l24.1-40.3c9.2-38.5,16.1-75.2,21.2-110c7-41.4,11.8-91.1,11.5-148.7C2787.4,1016.5,2782.5,966.8,2775.6,925.4z
        "/>
      <g>
        <ellipse className="st1" cx="377.5" cy="1393.2" rx="68.8" ry="48.5"/>
        <path className="st1" d="M414.7,1313.3l-26.1,27.9c34.9,3.9,61.7,25.7,61.7,51.9c0,17.4-11.8,32.8-29.8,42.4v27.8H452v-38.9h40.2h2
          h34.6L414.7,1313.3z"/>
        <polygon className="st1" points="583,1576.9 487,1576.9 487,1774.2 619.9,1774.2 619.9,1585.6 644.5,1558.4 622.8,1537.1 	"/>
        <path className="st1" d="M383.5,1340.8l28.4-30.3l-24.6-24l-42.1,41l3.8,17.3c8.8-2.7,18.4-4.2,28.5-4.2
          C379.5,1340.6,381.5,1340.7,383.5,1340.8z"/>
        <polygon className="st1" points="553.6,1469.6 553.6,1448.7 532.9,1428.5 504.2,1428.5 496.2,1428.5 456,1428.5 456,1463.3 
          496.2,1463.3 496.2,1463.4 514,1463.4 566.8,1516.2 583.9,1499.2 	"/>
        <path className="st1" d="M566.8,1521.8l-54.5-54.5h-20.2v-0.1H454h-2h-35.5v-29.8c-11.3,5.2-24.7,8.2-39,8.2
          c-40.2,0-72.8-23.6-72.8-52.5c0-20.6,16.5-38.5,40.5-47.1l-4.4-19.9l20.9-20.3l-68.9-3.5c2.3,10.1,4.8,20.2,7.5,30.4l-13.1,7v105.7
          l30.4,45.9H487v81.5h94.3v0.1l38.6-38.6l-33.2-32.4L566.8,1521.8z"/>
        <polygon className="st1" points="895.2,1803.2 878.1,1812.6 878.1,1885.8 983.1,1885.8 988.8,1888.5 988.8,1839.5 932.3,1839.5 	"/>
        <path className="st1" d="M733.1,1748.3l0.1,29.1l-17.5,0.7c1,9.4,4.6,34.3,16.9,57.8l141.1-1.5v-23.9l18.5-10.2l-53.2-52H733.1z"/>
        <path className="st1" d="M808.1,1885.8h66v-47.4l-139.4,1.4C748.1,1863.3,770.5,1884.5,808.1,1885.8z"/>
        <polygon className="st1" points="711.7,1624 647.3,1561.1 623.9,1587.1 623.9,1774.2 712.5,1774.2 729.2,1773.6 729,1744.3 
          729.2,1744.3 729.2,1716.5 711.7,1716.5 	"/>
        <polygon className="st1" points="715.7,1668.5 756.5,1707.4 778.3,1689 715.7,1627.9 	"/>
        <polygon className="st1" points="834.9,1744.3 781.1,1691.8 756.4,1712.7 715.7,1674 715.7,1712.5 733.2,1712.5 733.2,1744.3 	"/>
      </g>
      <g>
        <polygon className="st1" points="772,424.9 717,423.7 717,460.5 700.2,460.5 700.2,530.6 787.1,446.4 	"/>
        <polygon className="st1" points="621.3,373.8 621.3,568.7 622.5,568.7 645.9,583.3 696.3,534.4 696.3,456.4 713,456.4 713,373.8 	"/>
        <polygon className="st1" points="778.7,397.6 717,397.6 717,419.7 774.1,421 790,443.5 803.1,430.9 	"/>
        <path className="st1" d="M487,373.8v282.9H317.6l-30.4,45.9v105.7l13.1,7c-2,7.5-3.8,14.9-5.6,22.3l66.1,3.8l-19.9-19.2l2.7-25.4
          c-22.6-8.9-37.9-26.2-37.9-46.1c0-29,32.7-52.5,72.8-52.5c14.2,0,27.5,3,38.7,8v-35.3h88.2v-55.2h39.3v-46.9h72.7v-195L487,373.8
          L487,373.8z"/>
        <path className="st1" d="M717,360.7v33h63.8l25.2,34.4l29.2-28.3v-99.2h-95.7C725.3,321.5,719.4,346,717,360.7z"/>
        <path className="st1" d="M808.1,262.2c-31.3,1.2-52,15.9-65.8,34.4h96.9v99.3l29.6-28.7v-105H808.1z"/>
        <polygon className="st1" points="872.8,262.2 872.8,363.3 933,304.9 981.8,304.9 981.8,262.2 	"/>
        <path className="st1" d="M642.9,586.2l-21.6-13.5h-72.7v46.9h-39.3v51.2v2v47h-71.9c8.7,8.7,13.8,19.3,13.8,30.8
          c0,27.9-30.2,50.7-68.3,52.4l29.7,31.5l146.2-140.8l-4-22.2L642.9,586.2z"/>
        <path className="st1" d="M505.3,674.9h-84.2v33.3c4.3,2.3,8.3,4.9,11.9,7.8h72.3V674.9z"/>
        <ellipse className="st1" cx="378.5" cy="750.7" rx="68.8" ry="48.5"/>
        <path className="st1" d="M377.6,803.2c-10.8-0.1-21-1.9-30.1-5l-2.3,22.5l40.7,39.8l24-23.1L377.6,803.2z"/>
      </g>
      <g>
        <path className="st1" d="M2296,271.4c-10.3-5.3-22.4-8.7-36.7-9.2h-71.5v66.3l-17.6,20.7L2214,392v-87.1l50.5-2.8L2296,271.4z"/>
        <path className="st1" d="M2299.6,273.4l-33.4,32.6l-48.2,2.7v87.2l23.2,22.7l28.9-24.9h60.1l-1.3-91.8
          C2321.6,290.9,2312.1,280.7,2299.6,273.4z"/>
        <polygon className="st1" points="2084.3,262.2 2071.6,256.1 2071.6,305.6 2125.6,305.6 2167.3,346.4 2183.8,327 2183.8,262.2 	"/>
        <polygon className="st1" points="2532,725.1 2544.5,736.3 2578.3,736.3 2578.3,656.4 2580.3,648.8 2580.3,610.2 2493.4,610.2 
          2493.4,643.6 2532,643.8 	"/>
        <path className="st1" d="M2383,557.1h49.1V373.8H2352c0,0-1.9-34.5-19.1-65.2l1.4,89.1l0.7,34.1h20.4l0.2,52.2l-22.4,24.5L2383,557.1z"
          />
        <path className="st1" d="M2760,840c-1.8-8.1-3.6-16.3-5.6-24.6l-24.1-40.3l-31.1-4.1V656.7h-91.4l0.1,10.1h39.6l30,30v42.4l-30,30H2605
          l-25.6-28.9H2549l128.5,115.9L2760,840z"/>
        <polygon className="st1" points="2500.1,696.3 2528,721.5 2528,647.8 2489.4,647.6 2489.4,606.2 2553.3,606.2 2580.3,606.2 
          2580.3,373.8 2436.1,373.8 2436.1,561.1 2387.1,561.1 2500.1,671.6 	"/>
        <polygon className="st1" points="2645.8,765.2 2673.5,737.5 2673.5,698.4 2645.8,670.8 2603.9,670.8 2603.8,656.7 2582.4,656.7 
          2582.3,657 2582.3,737.6 2606.8,765.2 	"/>
        <polygon className="st1" points="2296.9,426.2 2275,450.7 2330.7,503.7 2351.3,481.4 2351.3,435.4 2340.3,435.7 2330.3,436.2 
          2330.3,426.2 	"/>
        <polygon className="st1" points="2295.1,422.2 2331.4,422.2 2330.9,398.5 2271.6,397.7 2244.1,421.4 2272.1,448 	"/>
        <line className="st2" x1="2332.3" y1="395.7" x2="2332.3" y2="424.2"/>
      </g>
      <g>
        <path className="st1" d="M2349.7,1791.1l-19.5-27.8l0.7-13.4h-64.5l-20-29.4l-20.1,19.5v94.3h109.3
          C2343.4,1818.7,2347.6,1802.7,2349.7,1791.1z"/>
        <polygon className="st1" points="2610.7,1372.5 2650.9,1372.5 2671.1,1352.4 2638.7,1318 2596.7,1359.2 	"/>
        <path className="st1" d="M2433.8,1590.1l-25.2-26.6l-52.5,50.8v100.9h-19.5l-2.3,46.9l16.4,23.3c1.1-6.9,1.3-11.2,1.3-11.2h81.8V1590.1
          z"/>
        <polygon className="st1" points="2352.1,1711.2 2352.1,1618.2 2278.7,1689.3 2301.6,1710.8 2334.6,1710.8 2334.6,1711.2 	"/>
        <path className="st1" d="M2333.5,1838.3h-111.2v-94.4l-52.9,51.2l18.1,16.1v66.1h-115.4v14.3l12.2-5.8h175
          C2297.7,1884.4,2320.2,1862.4,2333.5,1838.3z"/>
        <polygon className="st1" points="2183.5,1873.3 2183.5,1813 2166.6,1797.9 2130.8,1832.5 2072.1,1832.5 2072.1,1873.3 	"/>
        <polygon className="st1" points="2268.5,1745.9 2331.1,1745.9 2332.6,1714.8 2300,1714.8 2275.8,1692.1 2249.4,1717.7 	"/>
        <polygon className="st1" points="2582.3,1412 2563.7,1412 2554.5,1400.5 2525.4,1429 2539,1443.9 2581.1,1443.9 2610.7,1473.5 
          2650.9,1473.5 2679.3,1445.1 2679.3,1404.9 2650.9,1376.5 2610.7,1376.5 2582.3,1404.9 	"/>
        <polygon className="st1" points="2565.7,1408 2578.3,1408 2578.3,1403.3 2607.1,1374.5 2593.9,1361.9 2557.4,1397.7 	"/>
        <path className="st1" d="M2689.9,1307.2l-15.6-24.1l-32.7,32.1l35.1,37.3l-22.1,22l28.7,28.7v43.5l-30.8,30.8H2609l-29.6-29.6h-42.3
          l-14.7-16.1l-18.3,17.9v21.1l-92.8,89.9l26.3,27.8v185.7h142.6v-282.9h118.9V1377l31.1-4.1l24.1-40.3c2.3-9.7,4.5-19.2,6.5-28.7
          L2689.9,1307.2z"/>
      </g>
      <path className="st1" d="M391.1,1229.8l167.8,167.8V1103H404.4C404.4,1103,406.3,1172,391.1,1229.8z"/>
      <path className="st1" d="M558.9,989.9V740.4L391.1,908.3c7.3,27.6,10.6,57.7,12.2,81.7L558.9,989.9L558.9,989.9z"/>
      <path id='section_panther-city-prime' className="st1" d="M403.5,993.9c1.4,24.2,0.9,41.1,0.9,41.1h154.5v-41.1H403.5z"/>
      <g>
        <rect x="1323.1" y="409.7" className="st1" width="114.5" height="25.3"/>
        <rect id='section_mixology-on-montgomery' x="1323.1" y="360.8" className="st1" width="114.5" height="44.9"/>
      </g>
      <g>
        <rect x="1032" y="412.2" className="st1" width="112.7" height="22.7"/>
        <rect id='section_gendy-gourmet-sausages' x="1032" y="360.8" className="st1" width="112.7" height="47.5"/>
      </g>
      <polygon className="st1" points="998.2,360.8 950.1,360.8 889.2,411.5 899.3,435 998.2,435 "/>
      <g>
        <rect id='section_camp-bowie-q' x="1180.8" y="360.8" className="st1" width="106.7" height="47.5"/>
        <rect x="1180.8" y="412.2" className="st1" width="106.7" height="22.7"/>
      </g>f
      <g>
        <rect id='section_cultural-district-creations' x="1610.7" y="364.8" className="st1" width="130.3" height="44.9"/>
        <rect x="1610.7" y="413.7" className="st1" width="130.3" height="25.3"/>
      </g>
      <g>
        <rect id='section_seventh-street-staples-209' x="1763" y="364.8" className="st1" width="130.3" height="44.9"/>
        <rect x="1763" y="413.7" className="st1" width="130.3" height="25.3"/>
      </g>
      <g>
        <rect id='section_tarrant-tex-mex' x="1907.3" y="364.8" className="st1" width="117.9" height="44.9"/>
        <rect x="1907.3" y="413.7" className="st1" width="117.9" height="25.3"/>
      </g>
      <polygon className="st1" points="2054.7,367.5 2054.7,439 2160.9,439 2180.3,419.6 2108.8,351 2068.2,351 2068.2,367.5 "/>
      <g>
        <path className="st1" d="M2647.4,987.2c7.4-36.5,18.4-83.9,18.4-83.9l-165.8-166v249.8h147.4L2647.4,987.2L2647.4,987.2z"/>
        <path id='section_hamburgers-on-harley' className="st1" d="M2646.6,991.2H2500v36.5h140.8C2640.6,1023.5,2643.1,1008.9,2646.6,991.2z"/>
        <path id='section_coliseum-cocktails' className="st1" d="M2619.2,1229l40.5-11.9c-5.6-26.2-13.1-65.8-16.8-86l-40.6,4.9L2619.2,1229z"/>
        <path className="st1" d="M2616,1234.1l-18.5-101.5l44.6-5.4c-1-5.6-1.6-8.9-1.6-8.9h-137h-3.4v288l165.8-164.5
          c-1.1-2.7-3-10.4-5.3-20.7L2616,1234.1z"/>
      </g>
      <g>
        <polygon className="st1" points="1762.1,1702.8 1762.1,1736 1877.5,1736 1877.5,1702.6 	"/>
        <rect id='section_cowtown-fresh-fare' x="1762.1" y="1740" className="st1" width="115.5" height="33.4"/>
      </g>
      <polygon className="st1" points="2169,1702.1 2055.8,1702.3 2055.8,1773.3 2069.1,1773.3 2069.1,1794.4 2105.9,1794.4 2183.6,1716.7 "/>
      <g>
        <rect id='section_sundance-sunrise' x="1909.9" y="1740" className="st1" width="118.8" height="33.4"/>
        <polygon className="st1" points="1909.9,1736 2028.6,1736 2028.6,1702.4 1909.9,1702.6 	"/>
      </g>
      <g>
        <rect id='section_clearfork-cones' x="1614.9" y="1740" className="st1" width="118.6" height="33.4"/>
        <polygon className="st1" points="1733.5,1736 1733.5,1702.9 1614.9,1703.1 1614.9,1736 	"/>
      </g>
      <g>
        <rect id='section_botanic-beverages' x="1323.5" y="1741" className="st1" width="116.7" height="34.4"/>
        <rect x="1323.5" y="1702.1" className="st1" width="116.7" height="34.9"/>
      </g>
      <g>
        <rect id='section_texiyaki' x="1028.7" y="1741" className="st1" width="116.7" height="34.4"/>
        <rect x="1028.7" y="1702.1" className="st1" width="116.7" height="34.9"/>
      </g>
      <polygon className="st1" points="1001.6,1702.1 926.7,1702.1 926.7,1728.1 949.3,1752.1 929.7,1773.9 949.2,1794 990.3,1794 
        990.3,1775.4 1001.6,1775.4 "/>
      <g>
        <rect id='section_seventh-street-staples-225' x="1175.5" y="1741" className="st1" width="117.8" height="34.4"/>
        <rect x="1175.5" y="1702.1" className="st1" width="117.8" height="34.9"/>
      </g>
      <polygon className="st1" points="943.9,1752.2 922.7,1729.7 922.7,1702.1 898.1,1702.1 878.6,1721.3 926.9,1771 "/>
      <path id="top_border" className="st3" d="M2775.6,925.4c-5.1-34.8-12-71.5-21.2-110l-24.1-40.3l-31.1-4.1V656.7h-118.9V373.8H2352
        c0,0-5.9-108.5-92.7-111.6h-175l-57.1-27.3h-254.3v-15.2c0,0,0,0-0.1,0v-10.8c0,0-95.6-58.4-239.1-54.2
        c-143.5-4.2-239.1,54.2-239.1,54.2v10.8c0,0,0,0-0.1,0v15.2h-254.3l-57.1,27.3h-175c-86.8,3.2-92.7,111.6-92.7,111.6H487v282.9
        H317.6l-30.4,45.9v105.7l13.1,7c-24.8,93.4-32.2,182.1-31,258.7c-1.1,76.6,6.2,165.3,31,258.7l-13.1,7v105.7l30.4,45.9H487v282.9
        h228.4c0,0,5.9,108.5,92.7,111.6h175l57.1,27.3h254.3v15.2c0,0,0,0,0.1,0v10.8c0,0,95.6,58.4,239.1,54.2
        c143.5,4.2,239.1-54.2,239.1-54.2v-10.8c0,0,0,0,0.1,0v-15.2h254.3l57.1-27.3h175c86.8-3.2,92.7-111.6,92.7-111.6h228.4v-282.9
        h118.9V1377l31.1-4.1l24.1-40.3c9.2-38.5,16.1-75.2,21.2-110c7-41.4,11.8-91.1,11.5-148.7C2787.4,1016.5,2782.5,966.8,2775.6,925.4z
        "/>
      <g id="dickies_logo">
        <path className="st4" d="M1552.4,1070.7c-1.5-2.8-4.5-4.4-8.1-4.4h-26.1c-14-26.5-49-45.1-90-45.1s-76,18.8-90,45.1h-5.4
          c-4.6,0-9.8,2.9-12.3,6.8l-17.9,28.5c-1.9,3-2.1,6.4-0.6,9.1c1.5,2.8,4.5,4.4,8.1,4.4h27.1c13.2,27.4,49,47.1,91,47.1
          s77.7-19.7,91-47.1h2.4c4.6,0,9.8-2.9,12.3-6.8l17.9-28.5C1553.6,1076.8,1553.8,1073.4,1552.4,1070.7L1552.4,1070.7z"/>
        <path className="st5" d="M1549.1,1078.2l-17.9,28.5c-1.9,3-6,5.3-9.6,5.3h-4.4c-12,27.2-47.3,47.1-88.9,47.1c-29.8,0-58.1-10.4-75.6-28
          c-5.9-5.9-10.3-12.3-13.3-19.1h-29.2c-2.4,0-4.3-1-5.3-2.8s-0.8-3.9,0.5-5.9l17.9-28.5c1.9-3,6.1-5.3,9.6-5.3h7.3
          c3-6.1,7.1-11.8,12.4-17.1c17.5-17.5,45.8-28,75.6-28s58.1,10.4,75.6,28c5.3,5.3,9.4,11.1,12.4,17.1h28c2.4,0,4.3,1,5.2,2.8
          C1550.6,1073.9,1550.4,1076.1,1549.1,1078.2L1549.1,1078.2z M1552.4,1070.7c-1.5-2.8-4.5-4.4-8.1-4.4h-26.1
          c-14-26.5-49-45.1-90-45.1s-76,18.8-90,45.1h-5.4c-4.6,0-9.8,2.9-12.3,6.8l-17.9,28.5c-1.9,3-2.1,6.4-0.6,9.1
          c1.5,2.8,4.5,4.4,8.1,4.4h27.1c13.2,27.4,49,47.1,91,47.1s77.7-19.7,91-47.1h2.4c4.6,0,9.8-2.9,12.3-6.8l17.9-28.5
          C1553.6,1076.8,1553.8,1073.4,1552.4,1070.7L1552.4,1070.7z"/>
        <path className="st6" d="M1460.1,1134.2l6.8-2.5c1.4-0.6,1.5-1.7,0.2-2.4c0,0-2-1.1-4.9,0.1c-2.9,1.1-3.5,3-3.5,3
          C1457.9,1134,1458.7,1134.7,1460.1,1134.2L1460.1,1134.2z"/>
        <path className="st6" d="M1434.2,1137.6l5.9-0.4c1.3-0.1,1.6-1,0.7-1.9c0,0-1.3-1.3-3.9-1.1c-2.6,0.2-3.5,1.6-3.5,1.6
          C1432.5,1136.9,1432.9,1137.7,1434.2,1137.6L1434.2,1137.6z"/>
        <path className="st6" d="M1447.2,1136.9l6.5-1c1.4-0.3,1.6-1.2,0.6-2.1c0,0-1.6-1.3-4.4-0.8s-3.7,2.1-3.7,2.1
          C1445.3,1136.2,1445.8,1137,1447.2,1136.9L1447.2,1136.9z"/>
        <path className="st6" d="M1473.9,1128.4l6.6-4.3c1.4-1,1.2-2.2-0.4-2.6c0,0-2.4-0.7-5.3,1.3c-2.8,1.9-3.1,4.1-3.1,4.1
          C1471.4,1128.6,1472.4,1129.3,1473.9,1128.4L1473.9,1128.4z"/>
        <path className="st7" d="M1392.7,1112h10.3 M1342.7,1112c2.8,5.9,6.8,11.6,12.1,16.8c16.9,16.9,44.4,27,73.4,27
          c39.7,0,73.5-18.4,85.5-43.9"/>
        <path className="st7" d="M1512.8,1069.4c-2.8-5.2-6.5-10.2-11.1-15c-16.9-16.9-44.5-27-73.4-27c-29,0-56.5,10.1-73.4,27
          c-4.6,4.6-8.4,9.6-11.1,15"/>
        <path className="st5" d="M1544.3,1072.5c2.5,0,3.4,1.8,2.2,3.8l-17.9,28.5c-1.3,2.2-4.4,3.8-7,3.8h-211.5c-2.5,0-3.4-1.8-2.2-3.8
          l17.9-28.5c1.4-2.2,4.4-3.8,7-3.8H1544.3L1544.3,1072.5z"/>
        <path className="st4" d="M1348,1096.7h3.6c3,0,4.5-0.2,7.3-1.7c2.3-1.2,3.5-3.2,4.7-5c1.3-2.1,0.8-3.7,0.1-4.8
          c-0.5-0.8-1.6-1.5-3.5-1.8h-4.1L1348,1096.7L1348,1096.7z M1337.8,1096.9l8.3-13.8h-5.1l3-5h17.8c5.3,0,8.5,1,10.3,3.2
          c1.9,2.3,3,6.8,0.9,10.3c-1.9,3.1-3.7,6.3-7.9,8.6c-4.6,2.5-11.8,1.9-17.4,1.9h-18.1l3-5L1337.8,1096.9L1337.8,1096.9z"/>
        <polygon className="st4" points="1386.5,1097 1389.8,1097 1386.7,1102.1 1372.6,1102.1 1375.7,1097 1378.8,1097 1383.8,1088.9 
          1380.6,1088.9 1383.7,1083.9 1394.6,1083.9 	"/>
        <polygon className="st4" points="1462.3,1097 1465.5,1097 1462.4,1102.1 1448.4,1102.1 1451.4,1097 1454.6,1097 1459.5,1088.9 
          1456.4,1088.9 1459.4,1083.9 1470.3,1083.9 	"/>
        <polygon className="st4" points="1437.9,1087.9 1447.8,1083.2 1454,1083.3 1451,1088.1 1447.8,1088.2 1439.4,1092.1 1442.7,1096.5 
          1447.3,1096.5 1444.3,1101.5 1436,1101.5 1431.4,1094.6 1427.8,1101.5 1416.3,1101.5 1419.4,1096.5 1422.5,1096.5 1430.9,1082.6 
          1427.7,1082.7 1430.8,1077.6 1441.7,1077.6 1434.4,1089.6 	"/>
        <path className="st4" d="M1503.4,1096.5c-0.2,0.4-0.2,1.1,0.7,1.5c1,0.5,1.7,0.2,2.2,0.2c1.2,0,2.8-0.6,3.3-1.5c0.5-0.8,0.1-1-2-1.4
          l-0.4-0.1c-3.5-0.7-8.9-1.8-6.1-6.2c2.3-3.7,8.1-5.7,12.6-5.7c1.9,0,4.2,0.8,4.2,0.8l0.2-0.4h6.3l-3.6,6h-5.6c0,0,0.7-1.1-0.5-1.7
          c-0.8-0.4-1.6-0.3-2.2-0.3c-1.1,0-2.5,0.4-2.9,1.2c-0.4,0.7,0,1,2.2,1.4c3.4,0.6,9.1,1.6,6.4,6.3c-2.5,4-8.6,5.9-13.2,5.9
          c-3,0-4.7-1.1-4.7-1.1l-0.4,0.6h-6.4l3.8-6.4h6.5L1503.4,1096.5L1503.4,1096.5z"/>
        <path className="st4" d="M1479.9,1090.6c0,0,2-3.6,5.2-3.6c4.1,0,1.9,3.6,1.9,3.6H1479.9L1479.9,1090.6z M1492.9,1085.3
          c-1.5-2.1-4.9-2.5-7.9-2.5c-6.3,0-12.4,4.6-13.9,10c-0.8,2.6-0.2,5,1.5,6.9c1.7,1.8,4.3,2.8,7.4,2.8c9.1,0.1,10.9-6.5,10.9-6.5
          h-5.3l-0.3,0.3c-1.3,1.2-2.6,2-4.3,1.9c-4.1-0.2-2.1-4.4-2.1-4.4h14.1c0,0,0.8-1.9,0.8-2C1494,1090.1,1494.8,1088,1492.9,1085.3
          L1492.9,1085.3z"/>
        <path className="st4" d="M1390.3,1081.9c3,0,5.8-1.1,6.3-2.5s-1.6-2.5-4.6-2.5s-5.8,1.1-6.3,2.5
          C1385.3,1080.8,1387.4,1081.9,1390.3,1081.9L1390.3,1081.9z"/>
        <path className="st4" d="M1465.9,1081.9c3,0,5.8-1.1,6.3-2.5s-1.6-2.5-4.5-2.5c-3,0-5.8,1.1-6.3,2.5S1462.9,1081.9,1465.9,1081.9
          L1465.9,1081.9z"/>
        <path className="st4" d="M1412.6,1100.4l2.6-4.7h-4.3l-0.6,1c-1.3,1.2-2.1,1.8-3.9,1.8c-1.4,0-2.8,0.1-3.2-1.7
          c-0.3-0.9-0.2-2.2,0.3-3.3c1.3-2.6,3.4-4.2,6-4.9c1.6-0.4,2.9,0.7,3.1,1.9l0.1,0.4h4.3l3.5-7.1h-4.5c0,0,0,1.1-0.4,0.8
          c-1.1-0.9-2.6-1.3-4.4-1.3c-5.9,0-13.1,1.6-16,9.4c-1,2.8-0.6,5,0.9,6.9c1.8,2.4,3.8,3.1,6.4,3.3
          C1409.9,1103.6,1412.6,1100.4,1412.6,1100.4L1412.6,1100.4z"/>
        <path className="st8" d="M1385.2,1061.8l-3.5-12.1l-0.1-0.2h-0.3c-7,0.3-12.2-0.1-12.2-0.1h-0.5l0.1,0.4l3.7,13.1l0.1,0.2h0.2
          c3.7,0.1,7.6-0.2,12.1-0.8l0.4-0.1L1385.2,1061.8L1385.2,1061.8z"/>
        <path className="st8" d="M1384.9,1119c-4.4-0.6-8.3-0.9-12.1-0.8h-0.2l-0.1,0.2l-3.7,13.1l-0.1,0.4h0.5c0.1,0,5.3-0.4,12.2-0.1h0.3
          l0.1-0.2l3.5-12.1l0.1-0.4L1384.9,1119L1384.9,1119z"/>
        <polygon className="st8" points="1403.3,1061.6 1403.3,1061.2 1402.9,1061.3 1392.6,1063 1392.4,1063 1392.4,1063.3 1392.4,1069.4 
          1392.7,1069.4 1403,1069.4 1403.3,1069.4 	"/>
        <path className="st8" d="M1471.6,1054.4c-0.2-1.7,0.8-2.3,2.2-1.5l6.6,4.3c1.4,1,1.2,2.2-0.4,2.6c0,0-2.4,0.7-5.3-1.3
          C1472,1056.6,1471.6,1054.4,1471.6,1054.4L1471.6,1054.4z M1454.2,1047.6c0,0-1.6,1.3-4.4,0.8s-3.7-2.1-3.7-2.1
          c-0.7-1.2-0.2-2,1.2-1.8l6.5,1C1455,1045.7,1455.3,1046.6,1454.2,1047.6L1454.2,1047.6z M1467,1051.9c0,0-2,1.1-4.9-0.1
          c-2.9-1.1-3.5-3-3.5-3c-0.5-1.4,0.2-2.2,1.7-1.7l6.8,2.5C1468.3,1050.1,1468.4,1051.1,1467,1051.9L1467,1051.9z M1434.2,1043.7
          l5.9,0.4c1.3,0.1,1.6,1,0.7,1.9c0,0-1.3,1.3-3.9,1.1c-2.6-0.2-3.5-1.6-3.5-1.6C1432.5,1044.5,1432.9,1043.7,1434.2,1043.7
          L1434.2,1043.7z M1498.2,1069.2c-15-18.2-34.5-27.9-58.3-28.7c-11.6-0.4-25.3,3-38.6,6.2l-2.4,0.6c-3.9,1-8.4,1.6-13.3,2h-0.4
          l0.1,0.4l3.3,11.7l0.1,0.3h0.3c2.2-0.4,4.3-0.8,6.6-1.2c9.4-1.8,20.2-3.8,35.3-4c25.9-0.4,40.1,11.5,42.1,13.2h25.3L1498.2,1069.2
          L1498.2,1069.2z"/>
        <polygon className="st8" points="1392.4,1111.9 1392.4,1112 1392.4,1118.3 1392.4,1118.6 1392.6,1118.6 1402.9,1120.3 1403.3,1120.4 
          1403.3,1120 1403.3,1112 1403.3,1111.9 	"/>
        <path className="st8" d="M1436.1,1125.3L1436.1,1125.3L1436.1,1125.3L1436.1,1125.3z M1474.7,1122.8c3-2,5.3-1.3,5.3-1.3
          c1.6,0.5,1.8,1.7,0.4,2.6l-6.6,4.3c-1.4,0.9-2.4,0.2-2.2-1.5C1471.6,1126.9,1472,1124.8,1474.7,1122.8L1474.7,1122.8z M1460,1137.5
          c-0.2,0.1-0.4,0.1-0.7,0.2C1459.6,1137.6,1459.8,1137.5,1460,1137.5L1460,1137.5z M1455.4,1138.6c-0.5,0.1-1,0.2-1.5,0.3
          C1454.4,1138.8,1454.9,1138.7,1455.4,1138.6L1455.4,1138.6z M1451.7,1139.3c-2.3,0.4-4.4,0.7-6.4,0.9
          C1447.3,1140,1449.5,1139.7,1451.7,1139.3L1451.7,1139.3z M1446,1135c0,0,1-1.6,3.7-2.1c2.9-0.5,4.4,0.8,4.4,0.8
          c1.1,0.9,0.8,1.9-0.6,2.1l-6.5,1C1445.8,1137,1445.3,1136.2,1446,1135L1446,1135z M1466.9,1131.8l-6.8,2.5
          c-1.5,0.5-2.2-0.3-1.7-1.7c0,0,0.7-1.9,3.5-3c3-1.2,4.9-0.1,4.9-0.1C1468.4,1130.2,1468.3,1131.3,1466.9,1131.8L1466.9,1131.8z
          M1440.1,1137.3l-5.9,0.4c-1.3,0-1.7-0.8-0.9-1.8c0,0,1-1.4,3.5-1.6c2.7-0.2,3.9,1.1,3.9,1.1
          C1441.7,1136.3,1441.4,1137.1,1440.1,1137.3L1440.1,1137.3z M1498,1111.9L1498,1111.9h-24.4l0,0h-0.5c-2,1.8-16.2,13.6-42,13.2
          c-15.1-0.2-25.9-2.3-35.3-4c-2.3-0.4-4.4-0.9-6.6-1.2h-0.3l-0.1,0.3l-3.3,11.7l-0.1,0.4h0.4c4.9,0.4,9.3,1.1,13.3,2l2.4,0.6
          c13.3,3.2,27,6.6,38.6,6.2c23.8-0.8,43.4-10.5,58.3-28.7l0.2-0.2h-0.5L1498,1111.9L1498,1111.9z"/>
        <path className="st8" d="M1498,1111.9L1498,1111.9"/>
        <path className="st5" d="M1523.8,1121.6c0.7,0,1.2,0,1.2-0.8c0-0.6-0.6-0.7-1.1-0.7h-1v1.6L1523.8,1121.6L1523.8,1121.6z
          M1522.8,1124.4h-0.8v-4.9h1.9c1.2,0,1.8,0.4,1.8,1.4c0,0.9-0.6,1.3-1.3,1.4l1.4,2.2h-0.8l-1.3-2.2h-0.9L1522.8,1124.4
          L1522.8,1124.4z M1523.8,1125.6c2,0,3.4-1.5,3.4-3.5c0-2.1-1.6-3.5-3.4-3.5c-2,0-3.5,1.6-3.5,3.5S1521.9,1125.6,1523.8,1125.6
          L1523.8,1125.6z M1519.4,1121.9c0-2.5,2-4.3,4.3-4.3s4.3,1.8,4.3,4.3s-2,4.3-4.3,4.3C1521.5,1126.3,1519.4,1124.4,1519.4,1121.9
          L1519.4,1121.9z"/>
        <path className="st5" d="M1591.6,1091.6l8.6-9l-2.9,9H1591.6L1591.6,1091.6z M1596,1096.5l-0.2,0.3c-0.4,0.6-1.3,1-3,1.2l-2.6,4h16.7
          l1.9-3.6c-1.9-0.3-2.8-1.1-2.9-1.4c0-0.2,0-0.6,0.2-1.2l4.8-18.1h-14.6l-17,17.7l-2.3,1.9c-0.6,0.3-1.3,0.6-2.1,0.8l-2.6,4h13
          l2.6-4.1c-1.4-0.1-2-0.5-1.5-1.2l0.2-0.4l0.3-0.4l0.9-0.9h8.2l-0.3,1L1596,1096.5L1596,1096.5z"/>
        <path className="st5" d="M1632.4,1084.5c-2.2,0.1-4.1,0.8-4.8,1.3l0.8-1.5h-8.3l-1.8,3.5c0.2,0.1,0.5,0.2,0.7,0.3
          c0.4,0.3,0.4,0.8,0,1.5l-3.1,6.2c-0.4,0.9-0.7,1.4-0.9,1.6c-0.5,0.6-1.2,1.1-2.2,1.2l0,0c-0.2,0-0.4,0-0.6,0l-1.8,3.5h0.5h0.1h12.6
          l1.9-3.6c-0.5-0.1-3.4,0.2-3.4-0.6c0-0.3,0.1-0.9,0.6-1.8l1.6-3.1c0.6-1.3,1.4-2.2,2.2-2.7c1-0.6,2.2-1.3,3.4-1.3
          c0.5,0,1.1,0.1,1.5,0.2c1.2-1.8,2.9-3.3,4.8-4.4C1635.3,1084.5,1634,1084.4,1632.4,1084.5L1632.4,1084.5z"/>
        <path className="st5" d="M1674.3,1102.1h-8c0-0.2,0.1-0.5,0.2-1.1c0-0.2,0.1-0.5,0.2-0.9l1.6-3.4l2.9-5.6c0.7-1.4,0.9-2.2,0.6-2.6
          c-0.2-0.5-0.9-0.8-2.1-0.8c-1.1,0-2.2,0.3-3.3,0.9l-3.7,7.4c-0.5,0.9-0.7,1.5-0.6,1.8s0.4,0.5,0.9,0.6l-1.9,3.6h-10l1.9-3.6
          c0.7-0.2,1.3-0.4,1.7-0.8c0.3-0.3,0.7-1,1.2-2l3.1-6.2c0.3-0.6,0.4-0.9,0.4-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.3
          l1.8-3.5h8.6l-0.6,1.2c0.7-0.2,1.8-0.6,3.3-1c1.2-0.2,2.2-0.3,3-0.3c1.4,0,2.6,0.1,3.3,0.3c0.7,0.2,1.3,0.6,1.7,1.1
          c0.7,1,0.5,2.7-0.6,4.8l-2.9,5.6c-0.1,0.1-0.2,0.5-0.5,1.1c-0.2,0.4-0.2,0.7-0.2,0.9s0.4,0.4,1,0.7L1674.3,1102.1L1674.3,1102.1z"
          />
        <path className="st5" d="M1693.7,1096.7l1.6-3c-2.4,0.6-3.8,1.1-4.6,1.3c-1.7,0.6-2.7,1.3-3,2.1c-0.5,1-0.1,1.5,1.2,1.5
          c0.7,0,1.4-0.2,2.3-0.5c0.2-0.1,0.7-0.2,1.3-0.5C1692.6,1097.3,1693.1,1097.1,1693.7,1096.7L1693.7,1096.7z M1699.7,1102.1h-8.4
          l1-1.9c-1.6,0.7-3,1.3-4.3,1.7c-1.5,0.4-2.9,0.6-4.1,0.6c-2,0-3.2-0.5-3.9-1.4c-0.7-1-0.7-2.2,0-3.6c0.8-1.6,2.3-2.9,4.4-3.8
          s6.3-2.1,12.8-3.3l0.5-1c0.7-1.4,0.2-2-1.6-2c-0.7,0-1.5,0.2-2.2,0.5c-0.7,0.5-1.1,1-1.4,1.6l-0.3,0.6h-7l2.1-4.1
          c4.1-1.3,7.8-1.9,10.9-1.9c3.2,0,5.3,0.5,6.3,1.4s0.9,2.5-0.2,4.6l-3,6c-0.4,0.8-0.7,1.3-0.7,1.5c-0.1,0.6,0.2,1,1,1.2
          L1699.7,1102.1L1699.7,1102.1z"/>
        <path className="st5" d="M1640.2,1091.4c0,0,1.8-3.4,4.8-3.4c3.8,0,1.8,3.4,1.8,3.4H1640.2L1640.2,1091.4z M1652.3,1086.4
          c-1.4-1.9-4.5-2.3-7.4-2.3c-5.8,0-11.5,4.3-12.9,9.2c-0.7,2.5-0.2,4.7,1.4,6.4c1.6,1.7,4,2.6,6.9,2.6c8.5,0,10.1-6,10.1-6h-5
          l-0.3,0.2c-1.2,1.1-2.4,1.8-4,1.7c-3.8-0.2-2-4.1-2-4.1h13.1c0,0,0.7-1.8,0.7-1.9C1653.4,1090.8,1654.1,1088.9,1652.3,1086.4
          L1652.3,1086.4z"/>
      </g>
      <g id="stairs">
        <polyline className="st9" points="349.8,1380.2 366.9,1380.2 366.9,1397.9 385.3,1397.9 385.3,1415.1 405,1415.1 	"/>
        <polyline className="st9" points="891.4,1840 908.6,1840 908.6,1857.7 926.9,1857.7 926.9,1874.9 946.6,1874.9 	"/>
        <polyline className="st9" points="881.3,1719.8 898.4,1719.8 898.4,1737.5 916.8,1737.5 916.8,1754.7 936.5,1754.7 	"/>
        <polyline className="st9" points="353.5,735.4 370.6,735.4 370.6,753.1 389,753.1 389,770.3 408.7,770.3 	"/>
        <polyline className="st9" points="879.8,278.3 897,278.3 897,296.1 915.4,296.1 915.4,313.2 935,313.2 	"/>
        <polyline className="st9" points="2103.1,392.5 2120.4,392.5 2120.4,410.3 2138.8,410.3 2138.8,427.4 2158.4,427.4 	"/>
        <polyline className="st9" points="2117.5,271.9 2134.6,271.9 2134.6,289.7 2153,289.7 2153,306.8 2172.7,306.8 	"/>
        <polyline className="st9" points="2600.8,702.5 2618,702.5 2618,720.2 2636.4,720.2 2636.4,737.4 2656,737.4 	"/>
        <polyline className="st9" points="2607.8,1406.2 2624.9,1406.2 2624.9,1423.9 2643.3,1423.9 2643.3,1441.1 2663,1441.1 	"/>
        <polyline className="st9" points="2100.5,1825.2 2117.6,1825.2 2117.6,1842.9 2136,1842.9 2136,1860.1 2155.7,1860.1 	"/>
      </g>
      <g id="main_level_seating">
        <g id="main_level_seating_1_">
          <polygon className="st10" points="985.2,610 1059.5,810.8 1161.8,810.8 1161.8,608.6 		"/>
          <polygon className="st10" points="1178.8,608.6 1178.8,888.8 1305.2,888.8 1305.2,608.8 		"/>
          <polygon className="st10" points="1049.8,1018 887.5,1018 887.5,1144.4 1049.7,1144.4 		"/>
          <polygon className="st10" points="1233.8,1021.2 1058.4,1021.2 1058.4,1147.6 1233.6,1147.6 		"/>
          <polygon className="st10" points="1233.8,939 1058.4,939 1058.4,1003.7 1233.6,1003.7 		"/>
          <polygon className="st10" points="1233.8,1163.9 1058.4,1163.9 1058.4,1228.5 1233.6,1228.5 		"/>
          <rect x="1321.1" y="625.2" className="st10" width="128.5" height="293.5"/>
          <rect x="1465.5" y="608.6" className="st10" width="121.7" height="312.6"/>
          <rect x="1605.4" y="625.2" className="st10" width="127.7" height="296"/>
          <rect x="1748.2" y="609.3" className="st10" width="128" height="279.4"/>
          <rect x="1812.2" y="935.5" className="st10" width="176.1" height="69.1"/>
          <rect x="1812.2" y="1164.1" className="st10" width="176.1" height="66"/>
          <rect x="1812.2" y="1019.4" className="st10" width="176.1" height="125"/>
          <polygon className="st10" points="1891,610 1891,887.6 1953.2,887.6 2074.4,610 		"/>
          <polygon className="st10" points="2042,719.2 2090.8,764.2 2102.2,775 2175.6,704.1 2087.1,615.6 		"/>
          <polygon className="st10" points="1995.8,935.5 2271.1,822.7 2271.1,1004.6 1995.8,1004.6 		"/>
          <rect x="1995.8" y="1019.4" className="st10" width="275.3" height="125"/>
          <polygon className="st10" points="1953.5,1280.2 2064.6,1558.9 1891,1558.9 1891,1280.2 		"/>
          <rect x="1748" y="1271.1" className="st10" width="124.6" height="287.8"/>
          <rect x="1605" y="1247.4" className="st10" width="124.6" height="295.7"/>
          <polygon className="st10" points="1588.6,1485.5 1588.6,1556.5 1464.1,1556.5 1464.1,1247.8 1588.6,1247.8 		"/>
          <rect x="1319.7" y="1246.1" className="st10" width="130.3" height="297"/>
          <rect x="1175.5" y="1271.1" className="st10" width="124.3" height="287.8"/>
          <polygon className="st10" points="1161.4,1290.5 1161.4,1558.9 984.5,1558.9 1091.3,1290.5 		"/>
          <polygon className="st10" points="2018.3,835.4 1996.4,818.2 2042,719.2 2090.8,764.2 		"/>
          <polygon className="st10" points="1989.7,1248.8 2276.2,1368 2188.9,1459.9 2055,1322.5 2040.7,1335.5 2175.6,1472 2089.9,1553.9 
            1969.2,1271.1 		"/>
          <polygon className="st10" points="968.3,614.9 885,703.8 954.7,770.3 942.3,782.6 871.9,717.4 800.5,796.5 892.5,832.1 892.5,832.1 
            990.8,872.4 1046.9,813.8 		"/>
          <polygon className="st10" points="2182.7,712.1 2266.9,802.7 2064.6,887.6 2049.8,870 2122,796.4 2111.2,783.7 		"/>
          <path className="st10" d="M959.8,1553.9c0,0,88.1-199.2,86.7-200.2c-1.4-0.9-66-59.9-66-59.9l-189,77.9l78.9,76.1l117-121.3l12.6,11.2
            l-126.9,132L959.8,1553.9z"/>
          <polygon className="st10" points="2276.2,1164.1 2276.2,1346.2 1995.8,1233.2 1995.8,1164.1 		"/>
          <path className="st10" d="M887.5,861.4c2.9,0.7,162.3,67,162.3,67v76.3H887.5V861.4z"/>
          <polygon className="st10" points="887.5,1163.9 887.5,1305.9 1049.8,1241.8 1049.8,1164.1 		"/>
        </g>
      </g>
      <g id="upper_concourse_seating">
        <g>
          <rect x="1024.7" y="1594.3" className="st10" width="125" height="95.6"/>
          <rect x="1168.3" y="1594.3" className="st10" width="125" height="95.6"/>
          <rect x="1311.9" y="1594.3" className="st10" width="125" height="95.6"/>
          <rect x="1599" y="1594.3" className="st10" width="125" height="95.6"/>
          <rect x="1455.2" y="1594.3" className="st10" width="125" height="76.2"/>
          <rect x="1456" y="465.7" className="st10" width="125" height="83.5"/>
          <rect x="1742.3" y="1594.6" className="st10" width="125" height="95.6"/>
          <rect x="1885.9" y="1594.6" className="st10" width="125" height="95.6"/>
          
            <rect x="2166" y="1468.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -414.7519 2031.9072)" className="st10" width="158.8" height="95.6"/>
          <rect x="2317.8" y="1139.3" className="st10" width="95.6" height="127.5"/>
          <rect x="2318" y="1017.5" className="st10" width="81.8" height="104.1"/>
          <rect x="2317.8" y="872.5" className="st10" width="95.6" height="127.5"/>
          <polygon className="st10" points="2028.6,1594.3 2028.6,1690 2140.6,1690 2211.7,1618.9 2143.1,1550.1 2097.2,1594.3 		"/>
          <rect x="1025.8" y="453.6" className="st10" width="125" height="95.6"/>
          <rect x="1169.4" y="453.6" className="st10" width="125" height="95.6"/>
          <rect x="1312.7" y="453.6" className="st10" width="125" height="95.6"/>
          <rect x="1599.3" y="453.6" className="st10" width="125" height="95.6"/>
          <rect x="1742.9" y="453.6" className="st10" width="125" height="95.6"/>
          <rect x="1886.2" y="453.6" className="st10" width="125" height="95.6"/>
          <polygon className="st10" points="2028.9,547.8 2028.9,452.2 2140.9,452.2 2212,523.3 2143.4,592.1 2097.5,547.8 		"/>
          
            <rect x="2195.6" y="548.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 214.8479 1769.488)" className="st10" width="95.6" height="154.6"/>
          <polygon className="st10" points="2279.1,725.8 2346,659 2413.1,726.1 2413.1,854.4 2318,854.4 2318,763.9 		"/>
          <polygon className="st10" points="2279.1,1415.8 2346,1482.6 2413.1,1415.5 2413.1,1287.2 2318,1287.2 2318,1377.7 		"/>
          <polygon className="st10" points="889.3,1543.8 821.6,1611.5 900.9,1690.5 1001.6,1690.5 1001.6,1593.5 937.8,1592.4 		"/>
          
            <rect x="741.8" y="1434.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -838.1468 1001.3022)" className="st10" width="95.6" height="156"/>
          <rect x="619.3" y="1141" className="st10" width="95.6" height="127.5"/>
          <rect x="619.3" y="1016.9" className="st10" width="95.6" height="106.9"/>
          <rect x="619.3" y="872.5" className="st10" width="95.6" height="127.5"/>
          <polygon className="st10" points="1006.9,549.2 1006.9,453.6 894.9,453.6 823.6,524.7 892.4,593.5 938.1,549.2 		"/>
          
            <rect x="712.8" y="577.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -210.234 742.1993)" className="st10" width="156" height="95.6"/>
          <polygon className="st10" points="754.5,1413 686.5,1480.9 619.3,1413.8 619.3,1285.5 714.4,1285.5 716.7,1374 		"/>
          <polygon className="st10" points="753.3,723.8 686.5,657 619.3,724.4 619.3,852.7 714.4,852.7 714.4,761.9 		"/>
        </g>
        <text transform="matrix(1 0 0 1 1062.3628 1644.541)" className="st11 st12 st13">226</text>
        <text transform="matrix(1 0 0 1 1206.9629 1644.543)" className="st11 st12 st13">225</text>
        <text transform="matrix(1 0 0 1 1347.8516 1644.541)" className="st11 st12 st13">224</text>
        <text transform="matrix(1 0 0 1 1631.4971 1644.541)" className="st11 st12 st13">222</text>
        <text transform="matrix(1 0 0 1 1488.0645 1639.8389)" className="st11 st12 st13">223</text>
        <text transform="matrix(1 0 0 1 1488.0645 514.9688)" className="st11 st12 st13">207</text>
        <text transform="matrix(1 0 0 1 1776.8105 1644.541)" className="st11 st12 st13">221</text>
        <text transform="matrix(1 0 0 1 1917.5186 1644.541)" className="st11 st12 st13">220</text>
        <text transform="matrix(1 0 0 1 2218.3291 1520.3828)" className="st11 st12 st13">218</text>
        <text transform="matrix(1 0 0 1 2337.9131 1211.0322)" className="st11 st12 st13">216</text>
        <text transform="matrix(1 0 0 1 2332.2715 1076.4248)" className="st11 st12 st13">215</text>
        <text transform="matrix(1 0 0 1 2337.8623 944.165)" className="st11 st12 st13">214</text>
        <text transform="matrix(1 0 0 1 2084.1113 1637.2119)" className="st11 st12 st13">219</text>
        <text transform="matrix(1 0 0 1 1060.7573 503.6846)" className="st11 st12 st13">204</text>
        <text transform="matrix(1 0 0 1 1202.564 503.6846)" className="st11 st12 st13">205</text>
        <text transform="matrix(1 0 0 1 1346.8799 503.6846)" className="st11 st12 st13">206</text>
        <text transform="matrix(1 0 0 1 1632.665 503.6846)" className="st11 st12 st13">208</text>
        <text transform="matrix(1 0 0 1 1774.7295 503.6846)" className="st11 st12 st13">209</text>
        <text transform="matrix(1 0 0 1 1918.4258 503.6846)" className="st11 st12 st13">210</text>
        <text transform="matrix(1 0 0 1 2088.2783 509.3271)" className="st11 st12 st13">211</text>
        <text transform="matrix(1 0 0 1 2215.9951 629.2109)" className="st11 st12 st13">212</text>
        <text transform="matrix(1 0 0 1 2337.8623 770.251)" className="st11 st12 st13">213</text>
        <text transform="matrix(1 0 0 1 2335.0381 1388.3027)" className="st11 st12 st13">217</text>
        <text transform="matrix(1 0 0 1 893.6421 1648.3008)" className="st11 st12 st13">227</text>
        <text transform="matrix(1 0 0 1 763.627 1528.8457)" className="st11 st12 st13">228</text>
        <text transform="matrix(1 0 0 1 639.7432 1218.875)" className="st11 st12 st13">230</text>
        <text transform="matrix(1 0 0 1 639.7432 1084.8862)" className="st11 st12 st13">231</text>
        <text transform="matrix(1 0 0 1 639.7432 952.626)" className="st11 st12 st13">232</text>
        <text transform="matrix(1 0 0 1 893.6421 517.7896)" className="st11 st12 st13">203</text>
        <text transform="matrix(1 0 0 1 763.627 637.6738)" className="st11 st12 st13">202</text>
        <text transform="matrix(1 0 0 1 639.7432 1393.9443)" className="st11 st12 st13">229</text>
        <text transform="matrix(1 0 0 1 639.7441 765.2715)" className="st11 st12 st13">201</text>
        <g id="ada">
          <g>
            <g>
              <circle className="st4" cx="1516.4" cy="1677.1" r="16.9"/>
              <path className="st14" d="M1516.4,1695.1c-9.9,0-17.8-7.9-17.8-17.8s7.9-17.8,17.8-17.8s17.8,7.9,17.8,17.8
                S1526.2,1695.1,1516.4,1695.1z M1516.4,1661c-9,0-16.1,7.3-16.1,16.1s7.3,16.1,16.1,16.1s16.1-7.3,16.1-16.1
                S1525.1,1661,1516.4,1661z"/>
            </g>
            <g>
              <g>
                <path className="st14" d="M1516.9,1674.5L1516.9,1674.5c0.8,0,1.7,0,2.3,0c0.6,0,1.1,0.6,1.4,1.1c0,0.6-0.3,1.1-0.8,1.4h-0.3
                  c-0.8,0-1.4,0-2.3,0c0,0,0,0-0.3,0c0,0.6,0,1.4,0,2c0,0,0,0,0.3,0c1.4,0,2.8,0,4.2,0c0.6,0,1.1,0.3,1.4,0.8
                  c0.8,1.7,1.4,3.1,2.3,4.8c0.6,0.8-0.3,2-1.1,2c-0.6,0-0.8-0.3-1.1-0.8c-0.6-1.4-1.4-2.5-2-3.9c0-0.3-0.3-0.3-0.3-0.3
                  c-1.7,0-3.1,0-4.8,0c-0.6,0-1.1-0.3-1.4-1.1v-0.3c0-2.5,0-5.1,0-7.6c0-0.6,0.6-1.1,1.1-1.4c0.6,0,1.1,0.3,1.4,1.1v0.3
                  C1516.9,1673.1,1516.9,1673.7,1516.9,1674.5C1516.9,1674.3,1516.9,1674.3,1516.9,1674.5z"/>
              </g>
              <g>
                <path className="st14" d="M1513.8,1674.3c0,0.3,0,0.3,0,0.6s0,0.8,0,1.1c0,0,0,0,0,0.3c-1.7,0.8-3.1,2-3.4,3.9
                  c-0.6,2.5,0.6,5.1,3.1,6.2c2.8,1.4,6.2,0.3,7.6-2.8c0,0,0,0,0-0.3c0,0,0,0,0,0.3c0.3,0.6,0.6,1.1,0.8,1.7c0,0,0,0,0,0.3
                  c-1.1,1.7-2.8,2.8-5.1,3.1c-3.1,0.6-5.9-0.8-7.6-3.7s-1.1-6.5,1.1-8.7C1511.3,1675.4,1512.4,1674.5,1513.8,1674.3
                  C1513.5,1674.3,1513.5,1674.3,1513.8,1674.3z"/>
              </g>
              <g>
                <path className="st14" d="M1518.1,1668.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5S1518.1,1666.9,1518.1,1668.3z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2318.6" cy="1505.6" r="16.9"/>
              <path className="st14" d="M2318.6,1523.3c-9.9,0-17.8-7.9-17.8-17.8s7.9-17.8,17.8-17.8s17.8,7.9,17.8,17.8
                S2328.5,1523.3,2318.6,1523.3z M2318.6,1489.5c-9,0-16.1,7.3-16.1,16.1c0,9,7.3,16.1,16.1,16.1c9,0,16.1-7.3,16.1-16.1
                C2334.7,1496.6,2327.3,1489.5,2318.6,1489.5z"/>
            </g>
            <g>
              <g>
                <path className="st14" d="M2319.2,1502.8C2319.2,1502.8,2319.4,1502.8,2319.2,1502.8c0.8,0,1.7,0,2.3,0s1.1,0.6,1.4,1.1
                  c0,0.6-0.3,1.1-0.8,1.4h-0.3c-0.8,0-1.4,0-2.3,0c0,0,0,0-0.3,0c0,0.6,0,1.4,0,2c0,0,0,0,0.3,0c1.4,0,2.8,0,4.2,0
                  c0.6,0,1.1,0.3,1.4,0.8c0.8,1.7,1.4,3.1,2.3,4.8c0.6,0.8-0.3,2-1.1,2c-0.6,0-0.8-0.3-1.1-0.8c-0.6-1.4-1.4-2.5-2-3.9
                  c0-0.3-0.3-0.3-0.3-0.3c-1.7,0-3.1,0-4.8,0c-0.6,0-1.1-0.3-1.4-1.1v-0.3c0-2.5,0-5.1,0-7.6c0-0.6,0.6-1.1,1.1-1.4
                  c0.6,0,1.1,0.3,1.4,1.1v0.3C2319.2,1501.6,2319.2,1502.2,2319.2,1502.8L2319.2,1502.8z"/>
              </g>
              <g>
                <path className="st14" d="M2316.1,1502.8c0,0.3,0,0.3,0,0.6s0,0.8,0,1.1c0,0,0,0,0,0.3c-1.7,0.8-3.1,2-3.4,3.9
                  c-0.6,2.5,0.6,5.1,3.1,6.2c2.8,1.4,6.2,0.3,7.6-2.8c0,0,0,0,0-0.3c0,0,0,0,0,0.3c0.3,0.6,0.6,1.1,0.8,1.7c0,0,0,0,0,0.3
                  c-1.1,1.7-2.8,2.8-5.1,3.1c-3.1,0.6-5.9-0.8-7.6-3.7c-1.7-2.8-1.1-6.5,1.1-8.7C2313.5,1503.6,2314.7,1503,2316.1,1502.8
                  C2315.8,1502.8,2316.1,1502.8,2316.1,1502.8z"/>
              </g>
              <g>
                <path className="st14" d="M2320.3,1496.6c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5
                  C2319.2,1494,2320.3,1495.1,2320.3,1496.6z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2410.3" cy="1064.7" r="16.9"/>
              <path className="st14" d="M2410.3,1082.5c-9.9,0-17.8-7.9-17.8-17.8s7.9-17.8,17.8-17.8s17.8,7.9,17.8,17.8
                S2420.2,1082.5,2410.3,1082.5z M2410.3,1048.6c-9,0-16.1,7.3-16.1,16.1c0,9,7.3,16.1,16.1,16.1c9,0,16.1-7.3,16.1-16.1
                C2426.4,1055.7,2419,1048.6,2410.3,1048.6z"/>
            </g>
            <g>
              <g>
                <path className="st14" d="M2410.8,1061.9C2410.8,1061.9,2411.1,1061.9,2410.8,1061.9c0.8,0,1.7,0,2.3,0s1.1,0.6,1.4,1.1
                  c0,0.6-0.3,1.1-0.8,1.4h-0.3c-0.8,0-1.4,0-2.3,0c0,0,0,0-0.3,0c0,0.6,0,1.4,0,2c0,0,0,0,0.3,0c1.4,0,2.8,0,4.2,0
                  c0.6,0,1.1,0.3,1.4,0.8c0.8,1.7,1.4,3.1,2.3,4.8c0.6,0.8-0.3,2-1.1,2c-0.6,0-0.8-0.3-1.1-0.8c-0.6-1.4-1.4-2.5-2-3.9
                  c0-0.3-0.3-0.3-0.3-0.3c-1.7,0-3.1,0-4.8,0c-0.6,0-1.1-0.3-1.4-1.1v-0.3c0-2.5,0-5.1,0-7.6c0-0.6,0.6-1.1,1.1-1.4
                  c0.6,0,1.1,0.3,1.4,1.1v0.3C2410.8,1060.7,2410.8,1061,2410.8,1061.9C2410.8,1061.6,2410.8,1061.9,2410.8,1061.9z"/>
              </g>
              <g>
                <path className="st14" d="M2407.7,1061.6c0,0.3,0,0.3,0,0.6s0,0.8,0,1.1c0,0,0,0,0,0.3c-1.7,0.8-3.1,2-3.4,3.9
                  c-0.6,2.5,0.6,5.1,3.1,6.2c2.8,1.4,6.2,0.3,7.6-2.8c0,0,0,0,0-0.3c0,0,0,0,0,0.3c0.3,0.6,0.6,1.1,0.8,1.7c0,0,0,0,0,0.3
                  c-1.1,1.7-2.8,2.8-5.1,3.1c-3.1,0.6-5.9-0.8-7.6-3.7c-1.7-2.8-1.1-6.5,1.1-8.7C2405.2,1062.7,2406.3,1062.1,2407.7,1061.6
                  C2407.5,1061.6,2407.7,1061.6,2407.7,1061.6z"/>
              </g>
              <g>
                <path className="st14" d="M2412,1055.7c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5S2412,1054.2,2412,1055.7z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2312.1" cy="628.9" r="16.9"/>
              <path className="st14" d="M2312.1,646.6c-9.9,0-17.8-7.9-17.8-17.8s7.9-17.8,17.8-17.8s17.8,7.9,17.8,17.8
                C2330.2,638.5,2322,646.6,2312.1,646.6z M2312.1,612.5c-9,0-16.1,7.3-16.1,16.1c0,8.7,7.3,16.1,16.1,16.1s16.1-7.3,16.1-16.1
                C2328.2,619.8,2321.1,612.5,2312.1,612.5z"/>
            </g>
            <g>
              <g>
                <path className="st14" d="M2312.7,626.1C2313,626.1,2313,626.1,2312.7,626.1c0.8,0,1.7,0,2.3,0s1.1,0.6,1.4,1.1
                  c0,0.6-0.3,1.1-0.8,1.4h-0.3c-0.8,0-1.4,0-2.3,0c0,0,0,0-0.3,0c0,0.6,0,1.4,0,2c0,0,0,0,0.3,0c1.4,0,2.8,0,4.2,0
                  c0.6,0,1.1,0.3,1.4,0.8c0.8,1.7,1.4,3.1,2.3,4.8c0.6,0.8-0.3,2-1.1,2c-0.6,0-0.8-0.3-1.1-0.8c-0.6-1.4-1.4-2.5-2-3.9
                  c0-0.3-0.3-0.3-0.3-0.3c-1.7,0-3.1,0-4.8,0c-0.6,0-1.1-0.3-1.4-1.1v-0.3c0-2.5,0-5.1,0-7.6c0-0.6,0.6-1.1,1.1-1.4
                  c0.6,0,1.1,0.3,1.4,1.1v0.3C2313,624.6,2312.7,625.2,2312.7,626.1C2312.7,625.8,2312.7,625.8,2312.7,626.1z"/>
              </g>
              <g>
                <path className="st14" d="M2309.6,625.8c0,0.3,0,0.3,0,0.6s0,0.8,0,1.1c0,0,0,0,0,0.3c-1.7,0.8-3.1,2-3.4,3.9
                  c-0.6,2.5,0.6,5.1,3.1,6.2c2.8,1.4,6.2,0.3,7.6-2.8c0,0,0,0,0-0.3c0,0,0,0,0,0.3c0.3,0.6,0.6,1.1,0.8,1.7c0,0,0,0,0,0.3
                  c-1.1,1.7-2.8,2.8-5.1,3.1c-3.1,0.6-5.9-0.8-7.6-3.7c-1.7-2.8-1.1-6.5,1.1-8.7C2307.3,626.9,2308.2,626.1,2309.6,625.8
                  L2309.6,625.8z"/>
              </g>
              <g>
                <path className="st14" d="M2313.8,619.8c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5
                  C2313,617.3,2313.8,618.4,2313.8,619.8z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="1516.4" cy="1589.4" r="16.9"/>
              <path className="st14" d="M1516.4,1607.1c-9.9,0-17.8-7.9-17.8-17.8s7.9-17.8,17.8-17.8s17.8,7.9,17.8,17.8
                C1534.1,1599.2,1526.2,1607.1,1516.4,1607.1z M1516.4,1573.3c-9,0-16.1,7.3-16.1,16.1c0,9,7.3,16.1,16.1,16.1
                s16.1-7.3,16.1-16.1C1532.4,1580.3,1525.1,1573.3,1516.4,1573.3z"/>
            </g>
            <g>
              <g>
                <path className="st14" d="M1516.9,1586.5L1516.9,1586.5c0.8,0,1.7,0,2.3,0c0.6,0,1.1,0.6,1.4,1.1c0,0.6-0.3,1.1-0.8,1.4h-0.3
                  c-0.8,0-1.4,0-2.3,0c0,0,0,0-0.3,0c0,0.6,0,1.4,0,2c0,0,0,0,0.3,0c1.4,0,2.8,0,4.2,0c0.6,0,1.1,0.3,1.4,0.8
                  c0.8,1.7,1.4,3.1,2.3,4.8c0.6,0.8-0.3,2-1.1,2c-0.6,0-0.8-0.3-1.1-0.8c-0.6-1.4-1.4-2.5-2-3.9c0-0.3-0.3-0.3-0.3-0.3
                  c-1.7,0-3.1,0-4.8,0c-0.6,0-1.1-0.3-1.4-1.1v-0.3c0-2.5,0-5.1,0-7.6c0-0.6,0.6-1.1,1.1-1.4c0.6,0,1.1,0.3,1.4,1.1v0.3
                  C1516.9,1585.4,1516.9,1586,1516.9,1586.5L1516.9,1586.5z"/>
              </g>
              <g>
                <path className="st14" d="M1513.8,1586.5c0,0.3,0,0.3,0,0.6s0,0.8,0,1.1c0,0,0,0,0,0.3c-1.7,0.8-3.1,2-3.4,3.9
                  c-0.6,2.5,0.6,5.1,3.1,6.2c2.8,1.4,6.2,0.3,7.6-2.8c0,0,0,0,0-0.3c0,0,0,0,0,0.3c0.3,0.6,0.6,1.1,0.8,1.7c0,0,0,0,0,0.3
                  c-1.1,1.7-2.8,2.8-5.1,3.1c-3.1,0.6-5.9-0.8-7.6-3.7s-1.1-6.5,1.1-8.7C1511.3,1587.4,1512.4,1586.8,1513.8,1586.5
                  C1513.5,1586.5,1513.5,1586.5,1513.8,1586.5z"/>
              </g>
              <g>
                <path className="st14" d="M1518.1,1580.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5S1518.1,1578.9,1518.1,1580.3z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="1516.4" cy="547.3" r="16.9"/>
              <path className="st14" d="M1516.4,565.1c-9.9,0-17.8-7.9-17.8-17.8s7.9-17.8,17.8-17.8s17.8,7.9,17.8,17.8
                C1534.1,557.2,1526.2,565.1,1516.4,565.1z M1516.4,531.3c-9,0-16.1,7.3-16.1,16.1s7.3,16.1,16.1,16.1s16.1-7.3,16.1-16.1
                S1525.1,531.3,1516.4,531.3z"/>
            </g>
            <g>
              <g>
                <path className="st14" d="M1516.9,544.5L1516.9,544.5c0.8,0,1.7,0,2.3,0c0.6,0,1.1,0.6,1.4,1.1c0,0.6-0.3,1.1-0.8,1.4h-0.3
                  c-0.8,0-1.4,0-2.3,0c0,0,0,0-0.3,0c0,0.6,0,1.4,0,2c0,0,0,0,0.3,0c1.4,0,2.8,0,4.2,0c0.6,0,1.1,0.3,1.4,0.8
                  c0.8,1.7,1.4,3.1,2.3,4.8c0.6,0.8-0.3,2-1.1,2c-0.6,0-0.8-0.3-1.1-0.8c-0.6-1.4-1.4-2.5-2-3.9c0-0.3-0.3-0.3-0.3-0.3
                  c-1.7,0-3.1,0-4.8,0c-0.6,0-1.1-0.3-1.4-1.1v-0.3c0-2.5,0-5.1,0-7.6c0-0.6,0.6-1.1,1.1-1.4c0.6,0,1.1,0.3,1.4,1.1v0.3
                  C1516.9,543.4,1516.9,544,1516.9,544.5L1516.9,544.5z"/>
              </g>
              <g>
                <path className="st14" d="M1513.8,544.2c0,0.3,0,0.3,0,0.6s0,0.8,0,1.1c0,0,0,0,0,0.3c-1.7,0.8-3.1,2-3.4,3.9
                  c-0.6,2.5,0.6,5.1,3.1,6.2c2.8,1.4,6.2,0.3,7.6-2.8c0,0,0,0,0-0.3c0,0,0,0,0,0.3c0.3,0.6,0.6,1.1,0.8,1.7c0,0,0,0,0,0.3
                  c-1.1,1.7-2.8,2.8-5.1,3.1c-3.1,0.6-5.9-0.8-7.6-3.7s-1.1-6.5,1.1-8.7C1511.3,545.4,1512.4,544.8,1513.8,544.2
                  C1513.5,544.5,1513.5,544.5,1513.8,544.2z"/>
              </g>
              <g>
                <path className="st14" d="M1518.1,538.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5S1518.1,536.9,1518.1,538.3z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="1516.4" cy="460.5" r="16.9"/>
              <path className="st14" d="M1516.4,478.5c-9.9,0-17.8-7.9-17.8-17.8s7.9-17.8,17.8-17.8s17.8,7.9,17.8,17.8
                C1534.1,470.6,1526.2,478.5,1516.4,478.5z M1516.4,444.4c-9,0-16.1,7.3-16.1,16.1s7.3,16.1,16.1,16.1s16.1-7.3,16.1-16.1
                S1525.1,444.4,1516.4,444.4z"/>
            </g>
            <g>
              <g>
                <path className="st14" d="M1516.9,457.9L1516.9,457.9c0.8,0,1.7,0,2.3,0c0.6,0,1.1,0.6,1.4,1.1c0,0.6-0.3,1.1-0.8,1.4h-0.3
                  c-0.8,0-1.4,0-2.3,0c0,0,0,0-0.3,0c0,0.6,0,1.4,0,2c0,0,0,0,0.3,0c1.4,0,2.8,0,4.2,0c0.6,0,1.1,0.3,1.4,0.8
                  c0.8,1.7,1.4,3.1,2.3,4.8c0.6,0.8-0.3,2-1.1,2c-0.6,0-0.8-0.3-1.1-0.8c-0.6-1.4-1.4-2.5-2-3.9c0-0.3-0.3-0.3-0.3-0.3
                  c-1.7,0-3.1,0-4.8,0c-0.6,0-1.1-0.3-1.4-1.1v-0.3c0-2.5,0-5.1,0-7.6c0-0.6,0.6-1.1,1.1-1.4c0.6,0,1.1,0.3,1.4,1.1v0.3
                  C1516.9,456.5,1516.9,457.1,1516.9,457.9C1516.9,457.6,1516.9,457.6,1516.9,457.9z"/>
              </g>
              <g>
                <path className="st14" d="M1513.8,457.6c0,0.3,0,0.3,0,0.6s0,0.8,0,1.1c0,0,0,0,0,0.3c-1.7,0.8-3.1,2-3.4,3.9
                  c-0.6,2.5,0.6,5.1,3.1,6.2c2.8,1.4,6.2,0.3,7.6-2.8c0,0,0,0,0-0.3c0,0,0,0,0,0.3c0.3,0.6,0.6,1.1,0.8,1.7c0,0,0,0,0,0.3
                  c-1.1,1.7-2.8,2.8-5.1,3.1c-3.1,0.6-5.9-0.8-7.6-3.7s-1.1-6.5,1.1-8.7C1511.3,458.8,1512.4,457.9,1513.8,457.6
                  C1513.5,457.6,1513.5,457.6,1513.8,457.6z"/>
              </g>
              <g>
                <path className="st14" d="M1518.1,451.7c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5S1518.1,450.3,1518.1,451.7z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="616.2" cy="1234.5" r="16.9"/>
              <path className="st14" d="M616.2,1252.3c-9.9,0-17.8-7.9-17.8-17.8s7.9-17.8,17.8-17.8c9.9,0,17.8,7.9,17.8,17.8
                S626.1,1252.3,616.2,1252.3z M616.2,1218.1c-9,0-16.1,7.3-16.1,16.1c0,9,7.3,16.1,16.1,16.1s16.1-7.3,16.1-16.1
                S625.3,1218.1,616.2,1218.1z"/>
            </g>
            <g>
              <g>
                <path className="st14" d="M617.1,1231.7L617.1,1231.7c0.8,0,1.7,0,2.3,0s1.1,0.6,1.4,1.1c0,0.6-0.3,1.1-0.8,1.4h-0.3
                  c-0.8,0-1.4,0-2.3,0c0,0,0,0-0.3,0c0,0.6,0,1.4,0,2c0,0,0,0,0.3,0c1.4,0,2.8,0,4.2,0c0.6,0,1.1,0.3,1.4,0.8
                  c0.8,1.7,1.4,3.1,2.3,4.8c0.6,0.8-0.3,2-1.1,2c-0.6,0-0.8-0.3-1.1-0.8c-0.6-1.4-1.4-2.5-2-3.9c0-0.3-0.3-0.3-0.3-0.3
                  c-1.7,0-3.1,0-4.8,0c-0.6,0-1.1-0.3-1.4-1.1v-0.3c0-2.5,0-5.1,0-7.6c0-0.6,0.6-1.1,1.1-1.4c0.6,0,1.1,0.3,1.4,1.1v0.3
                  C617.1,1230.3,617.1,1230.8,617.1,1231.7C617.1,1231.4,617.1,1231.4,617.1,1231.7z"/>
              </g>
              <g>
                <path className="st14" d="M613.7,1231.4c0,0.3,0,0.3,0,0.6s0,0.8,0,1.1c0,0,0,0,0,0.3c-1.7,0.8-3.1,2-3.4,3.9
                  c-0.6,2.5,0.6,5.1,3.1,6.2c2.8,1.4,6.2,0.3,7.6-2.8c0,0,0,0,0-0.3c0,0,0,0,0,0.3c0.3,0.6,0.6,1.1,0.8,1.7c0,0,0,0,0,0.3
                  c-1.1,1.7-2.8,2.8-5.1,3.1c-3.1,0.6-5.9-0.8-7.6-3.7s-1.1-6.5,1.1-8.7C611.5,1232.5,612.3,1231.7,613.7,1231.4L613.7,1231.4z"
                  />
              </g>
              <g>
                <path className="st14" d="M618.2,1225.5c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5S618.2,1224.1,618.2,1225.5z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="616.2" cy="896.6" r="16.9"/>
              <path className="st14" d="M616.2,914.6c-9.9,0-17.8-7.9-17.8-17.8c0-9.9,7.9-17.8,17.8-17.8c9.9,0,17.8,7.9,17.8,17.8
                C634.3,906.4,626.1,914.6,616.2,914.6z M616.2,880.5c-9,0-16.1,7.3-16.1,16.1c0,9,7.3,16.1,16.1,16.1s16.1-7.3,16.1-16.1
                S625.3,880.5,616.2,880.5z"/>
            </g>
            <g>
              <g>
                <path className="st14" d="M617.1,893.7L617.1,893.7c0.8,0,1.7,0,2.3,0s1.1,0.6,1.4,1.1c0,0.6-0.3,1.1-0.8,1.4h-0.3
                  c-0.8,0-1.4,0-2.3,0c0,0,0,0-0.3,0c0,0.6,0,1.4,0,2c0,0,0,0,0.3,0c1.4,0,2.8,0,4.2,0c0.6,0,1.1,0.3,1.4,0.8
                  c0.8,1.7,1.4,3.1,2.3,4.8c0.6,0.8-0.3,2-1.1,2c-0.6,0-0.8-0.3-1.1-0.8c-0.6-1.4-1.4-2.5-2-3.9c0-0.3-0.3-0.3-0.3-0.3
                  c-1.7,0-3.1,0-4.8,0c-0.6,0-1.1-0.3-1.4-1.1v-0.3c0-2.5,0-5.1,0-7.6c0-0.6,0.6-1.1,1.1-1.4c0.6,0,1.1,0.3,1.4,1.1v0.3
                  C617.1,892.6,617.1,893.2,617.1,893.7L617.1,893.7z"/>
              </g>
              <g>
                <path className="st14" d="M613.7,893.7c0,0.3,0,0.3,0,0.6s0,0.8,0,1.1c0,0,0,0,0,0.3c-1.7,0.8-3.1,2-3.4,3.9
                  c-0.6,2.5,0.6,5.1,3.1,6.2c2.8,1.4,6.2,0.3,7.6-2.8c0,0,0,0,0-0.3c0,0,0,0,0,0.3c0.3,0.6,0.6,1.1,0.8,1.7c0,0,0,0,0,0.3
                  c-1.1,1.7-2.8,2.8-5.1,3.1c-3.1,0.6-5.9-0.8-7.6-3.7c-1.7-2.8-1.1-6.5,1.1-8.7C611.5,894.6,612.3,894,613.7,893.7L613.7,893.7z
                  "/>
              </g>
              <g>
                <path className="st14" d="M618.2,887.5c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5
                  C617.1,885.3,618.2,886.1,618.2,887.5z"/>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_women_4">
        <g>
          <circle className="womens-restrooms st15" cx="2390.8" cy="407" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M2397.6,407.4c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C2397.5,408.6,2397.8,408,2397.6,407.4z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2390.8" cy="395.5" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_women_3">
        <g>
          <circle className="womens-restrooms st15" cx="664.6" cy="442.5" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M671.4,442.9c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C671.3,444.1,671.6,443.5,671.4,442.9z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="664.6" cy="431" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_women_2">
        <g>
          <circle className="womens-restrooms st15" cx="669.8" cy="1673.1" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M676.6,1673.5c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C676.5,1674.7,676.8,1674.1,676.6,1673.5z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="669.8" cy="1661.6" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_women_1">
        <g>
          <circle className="womens-restrooms st15" cx="2395.1" cy="1652.5" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M2401.9,1652.9c0-0.1-2-5.8-2.3-6.7c-0.5-1.5-1.4-2.2-2.5-2.2c-1.6,0-2.2,0-3.8,0c-1.2,0-2,0.7-2.5,2.2
                c-0.3,0.9-2.3,6.6-2.3,6.7c-0.2,0.6,0.1,1.2,0.7,1.4c0.6,0.2,1.2-0.1,1.4-0.7c0.1-0.2,1.3-3.5,1.9-5.5c-0.1,0.6,0.1-0.6-1.6,8.1
                c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.2,0,0.4,0,0.6,0v7.9
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.7-8.7-1.5-7.7-1.6-8.1
                c0.7,1.9,1.9,5.3,1.9,5.5c0.2,0.6,0.8,0.9,1.4,0.7C2401.8,1654.1,2402.1,1653.5,2401.9,1652.9z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2395.1" cy="1641" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_men_4">
        <g>
          <circle className="mens-restrooms st15" cx="2266.9" cy="349.8" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M2269.8,341.2c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                v-10.6h0.6v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2l0,0
                v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C2272.4,342.4,2271.3,341.2,2269.8,341.2z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2266.9" cy="338.2" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_men_3">
        <g>
          <circle className="mens-restrooms st15" cx="780.4" cy="349.5" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M783.4,340.9c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2V362c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.6
                h0.6V362c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2l0,0v8.1
                c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C786,342,784.8,340.9,783.4,340.9z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="780.4" cy="337.9" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_men_2">
        <g>
          <circle className="mens-restrooms st15" cx="798.2" cy="1791.3" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M801.2,1782.7c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                v-10.6h0.6v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2l0,0
                v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C803.8,1783.9,802.7,1782.7,801.2,1782.7z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="798.3" cy="1779.7" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_men_1">
        <g>
          <circle className="mens-restrooms st15" cx="2276.2" cy="1787.2" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M2279.2,1778.6c-3.2,0-3.3,0-5.9,0c-1.4,0-2.6,1.2-2.6,2.6v8.1c0,0.6,0.5,1.1,1.1,1.1l0,0
                c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v18.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.6h0.6
                v10.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-17.5,0-7.5,0-18.5c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2l0,0v8.1
                c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1v-8.1l0,0C2281.8,1779.8,2280.6,1778.6,2279.2,1778.6z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2276.2" cy="1775.6" r="2.3"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_family_4">
        <g>
          <circle className="family-restrooms st15" cx="743.7" cy="479.6" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M758.7,474.1c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.1h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L758.7,474.1z"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st4" d="M749.2,479.3c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2c0,0-1.9-5.2-2.6-7.1
                c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7c-0.2,0.6,0.1,1.2,0.6,1.4
                c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.7
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V484c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V484
                c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9c0.2,0.4,0.5,0.7,1,0.7
                c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
                c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3c0,0.5,0.4,0.9,0.9,0.9
                s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v5.6c0,0.4,0.3,0.8,0.8,0.8l0,0
                c0.4,0,0.8-0.3,0.8-0.8l0,0C749.3,483.7,749.2,480.3,749.2,479.3z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="753.4" cy="468.4" r="2.2"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="745.4" cy="474.8" r="2"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st4" d="M733.8,466.2c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S735,466.2,733.8,466.2z"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_family_3">
        <g>
          <circle className="family-restrooms st15" cx="754.8" cy="1659.4" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M769.8,1653.8c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.1h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L769.8,1653.8z"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st4" d="M760.3,1659c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2c0,0-1.9-5.2-2.6-7.1
                c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7c-0.2,0.6,0.1,1.2,0.6,1.4
                c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.7
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7
                c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9c0.2,0.4,0.5,0.7,1,0.7
                c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
                c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3c0,0.5,0.4,0.9,0.9,0.9
                s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.6c0,0.4,0.3,0.8,0.8,0.8l0,0
                c0.4,0,0.8-0.3,0.8-0.8l0,0C760.3,1663.5,760.3,1660.1,760.3,1659z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="764.4" cy="1648.2" r="2.2"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="756.5" cy="1654.6" r="2"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st4" d="M744.9,1645.9c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2C747.1,1646.9,746.1,1645.9,744.9,1645.9z"
                />
            </g>
          </g>
        </g>
      </g>
      <g id="rr_family_2">
        <g>
          <circle className="family-restrooms st15" cx="2315.2" cy="1673.1" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M2330.2,1667.6c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1s0.1,0.1,0.1,0.2v17.6
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-10.1h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L2330.2,1667.6z"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st4" d="M2320.7,1672.8c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2
                c0,0-1.9-5.2-2.6-7.1c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7
                c-0.2,0.6,0.1,1.2,0.6,1.4c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7
                c0.2,0,0.5,0,0.8,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3
                s1.3-0.6,1.3-1.3v-7.7c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9
                c0.2,0.4,0.5,0.7,1,0.7c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1
                c0.1,0,0.1,0.1,0.1,0.1c0,2.1,0,11.9,0,12.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3
                c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.6
                c0,0.4,0.3,0.8,0.8,0.8l0,0c0.4,0,0.8-0.3,0.8-0.8l0,0C2320.7,1677.3,2320.7,1673.9,2320.7,1672.8z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2324.8" cy="1662" r="2.2"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2316.9" cy="1668.4" r="2"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st4" d="M2305.3,1659.7c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S2306.5,1659.7,2305.3,1659.7z"/>
            </g>
          </g>
        </g>
      </g>
      <g id="rr_family_1">
        <g>
          <circle className="family-restrooms st15" cx="2313.5" cy="469" r="24.8"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st4" d="M2328.5,463.4c0-1.4-1.1-2.5-2.5-2.5c-0.5,0-4.8,0-5.3,0c-1.1,0-2,0.6-2.5,1.5l-0.4,0.7
                c0.1,0.3,0.2,0.7,0.2,1.1c0,0.6-0.2,1.2-0.5,1.6c0.4,0,0.7,0.1,1.1,0.3l1.5-2.7c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
                v17.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V471h0.5v10.1c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-16.4,0-7.7,0-17.7
                c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v7.8c0,0.6,0.5,1.1,1.1,1.1l0,0c0.6,0,1.1-0.5,1.1-1.1L2328.5,463.4z"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st4" d="M2319,468.7c0-1-0.8-1.8-1.8-1.8h-4.1c-1,0-1.8,0.8-1.8,1.8c0,0.3,0,0,0,1.5l-0.8-0.2c0,0-1.9-5.2-2.6-7.1
                c-0.5-1.4-1.3-2.2-2.5-2.2c-1.6,0-2.1,0-3.7,0c-1.1,0-2,0.7-2.5,2.2c-0.8,2.2-2.5,7-2.5,7c-0.2,0.6,0.1,1.2,0.6,1.4
                c0.6,0.2,1.2-0.1,1.4-0.6c0,0,1.3-3.5,2.1-5.9c-0.1,0.5,0.1-0.5-1.5,7.9c-0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.5,0,0.8,0v7.7
                c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7c0.2,0,0.4,0,0.6,0v7.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-7.7
                c0.3,0,0.6,0,0.8,0c0.4,0,0.7-0.3,0.6-0.7c-1.6-8.5-1.5-7.5-1.5-7.9c0.9,2.4,2.1,5.8,2.1,5.9c0.2,0.4,0.5,0.7,1,0.7
                c0.9,0.2,1.5,0.3,2.5,0.5c0.5,0.1,0.9-0.3,0.9-0.7c0,0,0-2.1,0-2.4c0-0.1,0.1-0.1,0.1-0.1l0.1,0.1c0,2.1,0,11.9,0,12.8
                c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9c0-0.9,0-6.4,0-7.3h0.4c0,0.9,0,6.4,0,7.3c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
                c0-5.3,0-11.4,0-12.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.6c0,0.4,0.3,0.8,0.8,0.8l0,0c0.4,0,0.8-0.3,0.8-0.8l0,0
                C2319.1,473.1,2319,469.7,2319,468.7z"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2323.2" cy="457.8" r="2.2"/>
            </g>
          </g>
          <g>
            <g>
              <circle className="st4" cx="2315.2" cy="464.2" r="2"/>
            </g>
          </g>
          <g>
            <g>
              <path className="st4" d="M2303.6,455.6c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2C2305.8,456.5,2304.8,455.6,2303.6,455.6z"
                />
            </g>
          </g>
        </g>
      </g>
      <g id="elevator_3">
        <g>
          <circle className="elevators st15" cx="486.5" cy="1445.9" r="24.8"/>
        </g>
        <g>
          <path className="st4" d="M484.8,1432.3h-8.5c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5V1432.3L484.8,1432.3z
            M479.7,1444.2l2.6,3.4h-5.1L479.7,1444.2z"/>
          <path className="st4" d="M496.8,1432.3h-8.5v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7V1434C498.5,1433,497.7,1432.3,496.8,1432.3L496.8,1432.3z
            M493.4,1447.6l-2.6-3.4h5.1L493.4,1447.6z"/>
        </g>
      </g>
      <g id="elevator_2">
        <g>
          <circle className="elevators st15" cx="2517.6" cy="630.4" r="24.8"/>
        </g>
        <g>
          <path className="st4" d="M2515.9,616.8h-8.5c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5V616.8L2515.9,616.8z
            M2510.7,628.7l2.6,3.4h-5.1L2510.7,628.7z"/>
          <path className="st4" d="M2527.8,616.8h-8.5v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7v-23.9C2529.5,617.5,2528.7,616.8,2527.8,616.8
            L2527.8,616.8z M2524.4,632.1l-2.6-3.4h5.1L2524.4,632.1z"/>
        </g>
      </g>
      <g id="elevator_1">
        <g>
          <circle className="elevators st15" cx="477.3" cy="692.9" r="24.8"/>
        </g>
        <g>
          <path className="st4" d="M475.6,679.3H467c-0.9,0-1.7,0.8-1.7,1.7v23.9c0,0.9,0.8,1.7,1.7,1.7h8.5L475.6,679.3L475.6,679.3z
            M470.4,691.2l2.6,3.4h-5.1L470.4,691.2z"/>
          <path className="st4" d="M487.5,679.3H479v27.3h8.5c0.9,0,1.7-0.8,1.7-1.7V681C489.2,680.1,488.4,679.3,487.5,679.3L487.5,679.3z
            M484.1,694.6l-2.6-3.4h5.1L484.1,694.6z"/>
        </g>
      </g>
      <g id="hamburgers_on_harley">
        
          <rect x="2400.5" y="977.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 3.7842 2002.996)" className="st16" width="38.5" height="38.5"/>
        
          <rect x="2404.6" y="977.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 4.9902 2005.8909)" className="st17" width="38.5" height="38.5"/>
        <g>
          <path className="st18" d="M2470.6,989.8h-7.5v7.9h-3.8v-18.2h3.8v7.4h7.5v-7.4h3.8v18.2h-3.8V989.8z"/>
          <path className="st18" d="M2483.6,997.7h-3.5l7.4-18.2h2.6l6.7,18.2h-3.9l-1.5-4h-6.3L2483.6,997.7z M2488.4,985.1l-2.3,6h4.4
            L2488.4,985.1z"/>
          <path className="st18" d="M2521.5,997.7h-3.7l-0.2-11l-4.7,11.1h-2.4l-4.4-11.1l-0.5,11h-3.1l0.8-18.1h3.6l5.3,13.3l5.5-13.3h3.4
            L2521.5,997.7z"/>
          <path className="st18" d="M2528.7,979.6h6c1,0,2,0.1,2.7,0.4c0.8,0.3,1.4,0.6,1.9,0.9s0.8,0.8,1.1,1.4s0.4,1,0.4,1.6s-0.1,1.1-0.3,1.5
            s-0.5,0.8-0.8,1c-0.3,0.3-0.6,0.6-0.9,0.8c-0.4,0.2-0.7,0.4-0.9,0.5c0.6,0,1,0.2,1.6,0.4c0.6,0.3,1,0.6,1.4,0.9
            c0.5,0.4,0.8,0.8,1,1.4c0.3,0.6,0.4,1.1,0.4,1.9c0,0.7-0.2,1.4-0.5,2c-0.4,0.7-0.8,1.2-1.4,1.8c-0.6,0.5-1.3,0.9-2.1,1.2
            s-1.6,0.5-2.5,0.5h-7.2L2528.7,979.6L2528.7,979.6L2528.7,979.6z M2533.9,987.3c0.5,0,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.3,0.9-0.5
            c0.3-0.2,0.5-0.5,0.7-0.8s0.3-0.8,0.3-1.2c0-0.5-0.1-0.8-0.3-1.1s-0.4-0.6-0.7-0.8s-0.6-0.4-0.9-0.5c-0.4-0.1-0.8-0.1-1.2-0.1
            h-1.4v5.2L2533.9,987.3L2533.9,987.3z M2537.5,994.5c0.7-0.5,0.9-1.1,0.9-2.1c0-0.9-0.3-1.6-0.9-2.1c-0.7-0.5-1.4-0.7-2.4-0.7
            h-2.5v5.4h2.5C2536,995.2,2536.8,995,2537.5,994.5z"/>
          <path className="st18" d="M2563.5,991.5c0,0.9-0.2,1.8-0.5,2.6c-0.4,0.8-0.8,1.5-1.5,2.1s-1.5,1-2.4,1.4s-2.1,0.5-3.3,0.5
            s-2.3-0.2-3.3-0.5c-0.9-0.3-1.8-0.8-2.4-1.3s-1.1-1.3-1.5-2.1s-0.5-1.7-0.5-2.6v-11.9h3.8v11.4c0,1.3,0.3,2.4,0.9,3.1
            c0.7,0.8,1.7,1.1,3.1,1.1c0.8,0,1.3-0.1,1.8-0.4s0.9-0.6,1.2-0.9s0.6-0.8,0.8-1.3s0.2-1,0.2-1.5v-11.4h3.5L2563.5,991.5
            L2563.5,991.5L2563.5,991.5z"/>
          <path className="st18" d="M2581.2,998l-4.9-8.1h-1.7v7.8h-3.8v-18.1h5.3c1.2,0,2.3,0.1,3.3,0.4c0.9,0.3,1.7,0.6,2.3,1
            c0.6,0.5,1,0.9,1.3,1.6c0.3,0.6,0.5,1.2,0.5,2c0,0.7-0.1,1.2-0.4,1.8c-0.3,0.5-0.6,0.9-0.8,1.3c-0.4,0.4-0.8,0.7-1,0.9
            c-0.4,0.2-0.7,0.4-0.9,0.5l5.5,8.7L2581.2,998z M2576.1,987.3c1.1,0,2-0.3,2.5-0.8c0.6-0.6,0.8-1.1,0.8-1.8s-0.3-1.3-0.8-1.8
            c-0.6-0.5-1.4-0.8-2.5-0.8h-1.6v5.2L2576.1,987.3L2576.1,987.3z"/>
          <path className="st18" d="M2605,997.1c-0.4,0.2-0.8,0.3-1.5,0.4c-0.6,0.1-1.2,0.3-2,0.4c-0.8,0.1-1.5,0.2-2.3,0.2
            c-1.4,0-2.6-0.2-3.8-0.7s-2.3-1-3.1-1.9c-0.8-0.8-1.6-1.8-2.1-3c-0.5-1.1-0.8-2.4-0.8-3.8s0.3-2.7,0.8-3.9c0.6-1.1,1.2-2.2,2.1-3
            c0.8-0.8,2-1.4,3.1-1.9c1.2-0.5,2.4-0.7,3.8-0.7c0.8,0,1.6,0,2.3,0.1s1.2,0.2,1.7,0.4c0.5,0.1,0.9,0.3,1.2,0.4
            c0.4,0.2,0.7,0.3,0.8,0.4l-1.1,2.6c-0.2-0.1-0.4-0.1-0.7-0.3c-0.3-0.1-0.7-0.2-1-0.4c-0.4-0.1-0.8-0.2-1.4-0.3
            c-0.6-0.1-1.1-0.1-1.7-0.1c-0.8,0-1.5,0.2-2.2,0.5c-0.8,0.3-1.3,0.8-1.9,1.3c-0.6,0.6-1,1.2-1.4,2.1c-0.4,0.8-0.6,1.7-0.6,2.7
            s0.2,2,0.6,2.7c0.4,0.8,0.8,1.5,1.4,2.1c0.6,0.6,1.2,1,2,1.3s1.5,0.5,2.3,0.5c0.4,0,0.7,0,0.9,0s0.5,0,0.8-0.1
            c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.3-0.1,0.5-0.2v-4.9h3.7v6.8C2605.8,996.9,2605.4,997.1,2605,997.1z"/>
          <path className="st18" d="M2624.1,997.7h-11.6v-18.1h11.4v2.8h-7.5v4.6h5.5v2.8h-5.5v5.1h7.8L2624.1,997.7L2624.1,997.7z"/>
          <path className="st18" d="M2640.9,998l-4.9-8.1h-1.7v7.8h-3.8v-18.1h5.3c1.2,0,2.3,0.1,3.3,0.4c0.9,0.3,1.7,0.6,2.3,1
            c0.6,0.5,1,0.9,1.3,1.6c0.3,0.6,0.5,1.2,0.5,2c0,0.7-0.1,1.2-0.4,1.8c-0.3,0.5-0.6,0.9-0.8,1.3c-0.4,0.4-0.8,0.7-1,0.9
            c-0.4,0.2-0.7,0.4-0.9,0.5l5.5,8.7L2640.9,998z M2636,987.3c1.1,0,2-0.3,2.5-0.8c0.6-0.6,0.8-1.1,0.8-1.8s-0.3-1.3-0.8-1.8
            c-0.6-0.5-1.4-0.8-2.5-0.8h-1.6v5.2L2636,987.3L2636,987.3z"/>
          <path className="st18" d="M2651.3,994.5c0.3,0.1,0.6,0.2,0.9,0.4c0.4,0.1,0.8,0.2,1.2,0.3c0.5,0.1,1,0.1,1.7,0.1c0.4,0,0.8,0,1-0.1
            c0.4-0.1,0.7-0.2,0.9-0.4c0.3-0.2,0.5-0.4,0.7-0.7s0.2-0.6,0.2-0.8c0-0.4-0.1-0.7-0.3-1s-0.5-0.6-0.8-0.8
            c-0.4-0.3-0.8-0.6-1.1-0.8c-0.5-0.3-0.9-0.6-1.4-0.8c-0.5-0.3-0.9-0.6-1.4-0.8c-0.5-0.3-0.9-0.7-1.4-1.1c-0.5-0.5-0.8-0.9-1-1.5
            s-0.4-1.3-0.4-2.1s0.2-1.3,0.5-2c0.3-0.6,0.8-1.1,1.2-1.6c0.6-0.5,1.1-0.8,1.8-1c0.7-0.3,1.4-0.4,2.2-0.4s1.4,0,2,0.1
            s1,0.2,1.5,0.3c0.4,0.1,0.8,0.2,1,0.4c0.3,0.1,0.6,0.3,0.8,0.3l-1.1,2.6c-0.3-0.2-0.8-0.4-1.3-0.6c-0.6-0.2-1.2-0.3-1.9-0.3
            c-0.3,0-0.7,0-0.9,0.1c-0.3,0.1-0.6,0.2-0.8,0.3c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.8c0,0.4,0.1,0.8,0.3,1
            c0.2,0.3,0.5,0.6,0.8,0.8c0.3,0.3,0.7,0.5,1.1,0.8s0.8,0.6,1.3,0.8c0.4,0.3,0.8,0.6,1.4,0.9c0.6,0.4,1,0.8,1.5,1.2
            c0.5,0.5,0.8,0.9,1.1,1.5s0.5,1.1,0.5,1.8c0,0.8-0.2,1.7-0.6,2.3c-0.4,0.7-0.9,1.3-1.5,1.8c-0.7,0.5-1.4,0.8-2.3,1
            c-0.8,0.3-1.7,0.4-2.6,0.4c-0.8,0-1.4,0-2.1-0.1c-0.6-0.1-1.1-0.2-1.6-0.4c-0.5-0.1-0.8-0.3-1.1-0.4c-0.3-0.2-0.6-0.3-0.8-0.4
            l1.1-2.5C2650.9,994.3,2651.1,994.4,2651.3,994.5z"/>
          <path className="st18" d="M2462.7,1004.8c0.8,0,1.3,0.1,2,0.4c0.6,0.2,1.1,0.6,1.5,0.9c0.4,0.4,0.8,0.9,0.9,1.5
            c0.2,0.6,0.4,1.2,0.4,1.9c0,0.8-0.1,1.4-0.4,2c-0.3,0.6-0.7,1-1,1.5c-0.5,0.4-0.9,0.8-1.5,0.9c-0.6,0.2-1.2,0.4-1.8,0.4
            c-0.7,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.1-0.6-1.5-0.9c-0.5-0.4-0.8-0.9-1-1.5s-0.4-1.2-0.4-2c0-0.7,0.1-1.3,0.4-1.9
            c0.3-0.6,0.6-1,1-1.5c0.5-0.4,0.9-0.8,1.5-1C2461.4,1004.9,2462.1,1004.8,2462.7,1004.8z M2462.7,1006.2c-0.5,0-0.8,0.1-1.1,0.3
            s-0.7,0.4-0.8,0.7c-0.3,0.3-0.4,0.7-0.6,1c-0.1,0.4-0.2,0.8-0.2,1.3s0.1,0.8,0.2,1.3c0.1,0.4,0.3,0.8,0.6,1
            c0.3,0.3,0.6,0.6,0.8,0.8c0.4,0.2,0.8,0.3,1.1,0.3c0.5,0,0.8-0.1,1.1-0.3c0.4-0.2,0.7-0.4,0.8-0.8c0.3-0.3,0.5-0.7,0.6-1
            c0.1-0.4,0.2-0.8,0.2-1.3s-0.1-0.8-0.2-1.2c-0.1-0.4-0.3-0.8-0.6-1c-0.3-0.3-0.6-0.6-0.8-0.8S2463.2,1006.2,2462.7,1006.2z"/>
          <path className="st18" d="M2481.5,1004.9v9.1h-1.9l-4.7-6v6h-1.7v-9.1h1.7l4.8,6.2v-6.2H2481.5z"/>
          <path className="st18" d="M2501.1,1010.1h-3.8v3.9h-1.9v-9.1h1.9v3.8h3.8v-3.8h1.9v9.1h-1.9V1010.1z"/>
          <path className="st18" d="M2510.1,1014h-1.7l3.7-9.1h1.3l3.4,9.1h-2l-0.8-2.1h-3.1L2510.1,1014z M2512.5,1007.7l-1.1,3h2.3
            L2512.5,1007.7z"/>
          <path className="st18" d="M2527.3,1014.2l-2.4-4h-0.8v3.9h-1.9v-9.1h2.6c0.7,0,1.1,0.1,1.6,0.2c0.5,0.1,0.8,0.3,1.1,0.6
            c0.3,0.2,0.5,0.5,0.7,0.8s0.2,0.7,0.2,1s-0.1,0.7-0.2,0.8c-0.1,0.3-0.3,0.5-0.5,0.7s-0.4,0.4-0.6,0.5c-0.2,0.1-0.4,0.2-0.5,0.3
            l2.7,4.3H2527.3z M2524.9,1008.9c0.6,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.6,0.4-0.9s-0.1-0.7-0.4-0.9c-0.3-0.3-0.7-0.4-1.2-0.4h-0.8
            v2.6L2524.9,1008.9L2524.9,1008.9z"/>
          <path className="st18" d="M2536.7,1012.6h3.4v1.4h-5.3v-9.1h1.9L2536.7,1012.6L2536.7,1012.6z"/>
          <path className="st18" d="M2551.3,1014h-5.8v-9.1h5.6v1.4h-3.8v2.3h2.7v1.4h-2.7v2.5h3.9v1.5L2551.3,1014L2551.3,1014z"/>
          <path className="st18" d="M2563.8,1004.9l-2.9,4.7v4.4h-1.9v-4.4l-2.9-4.7h2.1l1.9,3.3l2-3.3H2563.8z"/>
        </g>
        <g id="L8LS9v_5_">
          <g>
            <path d="M2433.6,1001.7c0,0.3,0,0.7,0,0.9c0,0.1,0,0.1-0.1,0.2c-0.2,0.3-0.5,0.4-0.8,0.3c-0.1,0-0.2,0-0.3,0
              c-0.1,0.2-0.1,0.4-0.2,0.6c-0.6,2-1.9,3.2-3.8,3.8c-0.3,0.1-0.6,0.1-0.8,0.1c-1.4,0-2.9,0-4.3,0c-3.3,0-6.6,0-9.8,0
              c-0.4,0-0.8-0.1-1.2-0.2c-1.5-0.6-2.6-1.4-3.2-2.9c-0.2-0.4-0.3-0.8-0.4-1c-0.1-0.2-0.1-0.2-0.3-0.2c-0.3,0-0.5-0.1-0.7-0.3
              c-0.1-0.1-0.2-0.3-0.2-0.4c0-1.2,0-2.4,0-3.6c0-0.4,0.3-0.6,0.7-0.6c0.6,0,1.1,0,1.7,0c7.6,0,15.2,0,22.8,0
              c0.3,0,0.6,0.2,0.7,0.6c0,0.1,0,0.2,0.1,0.4C2433.6,1000,2433.6,1000.9,2433.6,1001.7z M2430.9,1003.2c-6.8-0.1-13.6,0-20.4,0
              c0,0.1,0,0.2,0.1,0.4c0.5,1.3,1.4,2.2,2.7,2.4c0.5,0.1,0.9,0.1,1.4,0.1c3.5,0,6.9,0,10.4,0c0.9,0,1.8,0,2.7-0.1
              c0.3,0,0.6-0.1,0.8-0.2c1.1-0.5,1.9-1.3,2.3-2.4C2430.9,1003.3,2430.9,1003.2,2430.9,1003.2z M2409.3,1001.6c7.6,0,15.2,0,22.8,0
              c0-0.7,0-1.2,0-1.9c-0.1,0-0.1,0-0.2,0c-6.8,0-13.5,0-20.3,0c-0.8,0-1.4,0-2.2,0c-0.1,0-0.2,0-0.2,0.1
              C2409.3,1000.5,2409.3,1001.1,2409.3,1001.6z"/>
            <path d="M2420.5,993.7c-2.5,0-5,0-7.5,0c-1.1,0-2.3,0-3.5,0c-0.6,0-0.8-0.4-0.8-0.8c0-0.7,0.1-1.3,0.3-2c0.5-1.4,1.3-2.6,2.3-3.6
              c1.2-1.1,2.7-1.9,4.3-2.4c0.9-0.3,2-0.6,3-0.7c0.7-0.1,1.4-0.1,2.1-0.1c2.2,0,4.3,0.5,6.3,1.4c1.6,0.8,3,1.9,4,3.3
              c0.8,1.1,1.3,2.4,1.3,3.8c0,0.3,0,0.5-0.2,0.7c0,0.1-0.1,0.2-0.2,0.2c-0.3,0-0.5,0.1-0.8,0.1c-1.2,0-2.4,0-3.7,0
              C2425.4,993.8,2422.9,993.8,2420.5,993.7C2420.5,993.8,2420.5,993.7,2420.5,993.7z M2430.9,992.2c0-0.1,0-0.1,0-0.2v-0.1
              c-0.2-0.9-0.6-1.7-1.2-2.4c-1.1-1.4-2.6-2.3-4.3-3c-2.2-0.8-4.3-0.9-6.6-0.7c-1.4,0.2-2.8,0.6-4,1.2c-1.1,0.6-2.2,1.3-3,2.3
              c-0.6,0.8-1,1.5-1.2,2.3c0,0.2-0.1,0.3-0.1,0.5C2417.3,992.2,2424.1,992.2,2430.9,992.2z"/>
            <path d="M2411.8,994.2c0.9,0,1.8,0.3,2.4,0.8c0.2,0.2,0.5,0.4,0.7,0.6c0.8,0.6,1.7,0.6,2.5,0.1c0.3-0.1,0.5-0.3,0.7-0.5
              c1.5-1.3,3.7-1.3,5.2,0c0.1,0.1,0.3,0.2,0.5,0.4c0.5,0.4,0.9,0.5,1.5,0.5c0.7,0,1.2-0.3,1.7-0.8s1-0.8,1.8-0.9
              c1.1-0.3,2.3-0.1,3.2,0.7c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.3,0.3,0.6,0.2,0.9c-0.2,0.3-0.5,0.5-0.8,0.4c-0.1,0-0.2-0.1-0.3-0.1
              c-0.1-0.1-0.3-0.2-0.4-0.3c-0.9-0.8-2.2-0.8-3,0.1c-0.4,0.4-0.8,0.7-1.4,0.8c-1.5,0.6-2.8,0.3-4-0.7c-0.3-0.2-0.6-0.5-0.8-0.7
              c-0.8-0.5-1.7-0.3-2.5,0.3c-0.2,0.2-0.4,0.4-0.7,0.6c-1.4,1.1-3.6,1-4.9-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.9-0.8-2.1-0.8-3,0
              c-0.2,0.2-0.4,0.3-0.6,0.4c-0.5,0.1-1-0.4-0.8-0.9c0-0.1,0.1-0.2,0.2-0.3C2410,994.7,2410.8,994.4,2411.8,994.2
              C2411.7,994.2,2411.7,994.2,2411.8,994.2z"/>
            <path d="M2425.3,988.9c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6c0,0.4-0.3,0.6-0.6,0.6S2425.3,989.3,2425.3,988.9z"/>
            <path d="M2415.1,988.9c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6c0,0.4-0.3,0.6-0.6,0.6
              C2415.3,989.5,2415.1,989.2,2415.1,988.9z"/>
            <path d="M2420.2,989.5c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6c0,0.4-0.3,0.6-0.6,0.6C2420.4,990,2420.2,989.8,2420.2,989.5
              z"/>
          </g>
        </g>
      </g>
      <g id="gendy_gourmet_sausages">
        
          <rect x="971" y="363.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 21.4222 807.8797)" className="st19" width="29.8" height="29.8"/>
        
          <rect x="974.2" y="363.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 22.3569 810.124)" className="st20" width="29.8" height="29.8"/>
        <g>
          <path className="st18" d="M1033.9,379.9c-0.5,0.2-1,0.3-1.7,0.5s-1.4,0.3-2.2,0.4c-0.8,0.1-1.7,0.2-2.7,0.2c-1.6,0-3-0.3-4.3-0.8
            c-1.4-0.5-2.6-1.2-3.5-2.2c-1-0.9-1.8-2.1-2.4-3.3c-0.6-1.3-0.9-2.8-0.9-4.3c0-1.6,0.3-3,0.9-4.3c0.6-1.3,1.4-2.5,2.4-3.3
            s2.2-1.6,3.5-2.2c1.4-0.5,2.8-0.8,4.3-0.8c1,0,1.9,0.1,2.6,0.2c0.8,0.1,1.4,0.2,2,0.4c0.6,0.2,1,0.3,1.4,0.5s0.7,0.3,0.9,0.4
            l-1.3,3c-0.2-0.1-0.4-0.2-0.7-0.3c-0.3-0.1-0.7-0.3-1.2-0.4s-1-0.2-1.6-0.3c-0.6-0.1-1.2-0.1-2-0.1s-1.7,0.2-2.5,0.5
            s-1.5,0.8-2.2,1.5c-0.7,0.6-1.2,1.4-1.6,2.3s-0.6,2-0.6,3c0,1.2,0.2,2.2,0.6,3c0.4,0.9,0.9,1.7,1.6,2.3s1.4,1.1,2.2,1.5
            c0.8,0.3,1.7,0.5,2.6,0.5c0.4,0,0.8,0,1.1,0s0.6,0,0.8-0.1c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.4-0.1,0.6-0.2v-5.5h4.1v7.7
            C1034.7,379.6,1034.3,379.7,1033.9,379.9z"/>
          <path className="st18" d="M1055.4,380.5h-13.1v-20.4h12.7v3.1h-8.4v5.1h6.2v3.1h-6.2v5.7h8.8V380.5z"/>
          <path className="st18" d="M1080.7,360.2v20.4h-4.1l-10.5-13.5v13.5h-3.6v-20.4h3.8l10.8,13.9v-13.9H1080.7z"/>
          <path className="st18" d="M1088.9,360.2h6.6c1.5,0,3,0.2,4.3,0.7c1.4,0.4,2.7,1.1,3.6,1.9c1.1,0.9,2,1.9,2.6,3.1c0.7,1.3,1,2.8,1,4.4
            c0,1.5-0.3,3-1,4.1c-0.7,1.3-1.6,2.4-2.7,3.2c-1.1,0.9-2.4,1.6-3.7,2.1s-2.8,0.7-4.2,0.7h-6.6L1088.9,360.2L1088.9,360.2
            L1088.9,360.2z M1095.5,377.3c0.8,0,1.6-0.2,2.4-0.5c0.8-0.4,1.6-0.8,2.3-1.5c0.7-0.6,1.2-1.4,1.7-2.3c0.4-0.9,0.7-1.8,0.7-2.9
            c0-1.3-0.2-2.3-0.6-3.1c-0.4-0.9-1-1.6-1.7-2.2s-1.4-1-2.3-1.3c-0.8-0.3-1.7-0.4-2.5-0.4h-2.4v14L1095.5,377.3L1095.5,377.3z"/>
          <path className="st18" d="M1128,360.2l-6.6,10.5v9.8h-4.3v-9.8l-6.4-10.5h4.7l4.3,7.5l4.4-7.5H1128z"/>
          <path className="st18" d="M1020.9,391.6c-0.1,0.1-0.3,0.1-0.5,0.1s-0.4,0.1-0.7,0.1s-0.5,0.1-0.8,0.1c-0.5,0-0.9-0.1-1.4-0.2
            c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.3-0.6-0.6-0.7-1c-0.2-0.4-0.3-0.9-0.3-1.4s0.1-1,0.3-1.4s0.4-0.8,0.7-1c0.3-0.3,0.7-0.5,1.1-0.7
            c0.4-0.2,0.9-0.2,1.4-0.2c0.3,0,0.6,0,0.8,0s0.4,0.1,0.6,0.1c0.2,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.1l-0.4,0.9
            c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.5-0.1s-0.4,0-0.6,0c-0.3,0-0.5,0.1-0.8,0.2
            c-0.2,0.1-0.5,0.3-0.7,0.5s-0.4,0.4-0.5,0.7c-0.1,0.3-0.2,0.6-0.2,1s0.1,0.7,0.2,1s0.3,0.5,0.5,0.7s0.4,0.3,0.7,0.4
            s0.5,0.2,0.8,0.2c0.1,0,0.2,0,0.3,0s0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1v-1.7h1.3v2.4
            C1021.2,391.5,1021.1,391.5,1020.9,391.6z"/>
          <path className="st18" d="M1028.2,385.4c0.5,0,0.9,0.1,1.4,0.2s0.8,0.4,1.1,0.7c0.3,0.3,0.5,0.6,0.7,1s0.2,0.9,0.2,1.3
            c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.4,0.8-0.7,1c-0.3,0.3-0.7,0.5-1.1,0.7s-0.8,0.2-1.3,0.2s-0.9-0.1-1.3-0.2
            c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.3-0.5-0.6-0.7-1s-0.3-0.9-0.3-1.4s0.1-0.9,0.3-1.3c0.2-0.4,0.4-0.8,0.7-1
            c0.3-0.3,0.7-0.5,1.1-0.7C1027.4,385.5,1027.8,385.4,1028.2,385.4z M1028.2,386.4c-0.3,0-0.6,0.1-0.8,0.2
            c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.5-0.4,0.7c-0.1,0.3-0.1,0.6-0.1,0.9s0,0.6,0.1,0.9s0.2,0.5,0.4,0.7s0.4,0.4,0.6,0.5
            s0.5,0.2,0.8,0.2s0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5s0.3-0.5,0.4-0.7c0.1-0.3,0.1-0.6,0.1-0.9s0-0.6-0.1-0.9
            s-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.4-0.6-0.5C1028.8,386.4,1028.5,386.4,1028.2,386.4z"/>
          <path className="st18" d="M1040.8,389.6c0,0.3-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.7s-0.5,0.4-0.8,0.5s-0.7,0.2-1.1,0.2s-0.8-0.1-1.1-0.2
            c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.3-0.2-0.6-0.2-0.9v-4.1h1.3v3.9c0,0.5,0.1,0.8,0.3,1.1
            c0.2,0.3,0.6,0.4,1.1,0.4c0.2,0,0.5,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.1-0.5v-3.9h1.2
            V389.6z"/>
          <path className="st18" d="M1048.6,391.8l-1.7-2.8h-0.6v2.7h-1.3v-6.3h1.8c0.4,0,0.8,0,1.1,0.1c0.3,0.1,0.6,0.2,0.8,0.4
            c0.2,0.2,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2,0,0.4-0.1,0.6s-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.2-0.4,0.3
            c-0.1,0.1-0.2,0.1-0.3,0.2l1.9,3L1048.6,391.8z M1046.8,388.1c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6
            c-0.2-0.2-0.5-0.3-0.9-0.3h-0.6v1.8H1046.8z"/>
          <path className="st18" d="M1060.1,391.8h-1.3l-0.1-3.7l-1.6,3.8h-0.9l-1.5-3.8l-0.2,3.7h-1.1l0.3-6.3h1.3l1.8,4.6l1.9-4.6h1.2
            L1060.1,391.8z"/>
          <path className="st18" d="M1068.3,391.8h-4v-6.3h3.9v1h-2.6v1.6h1.9v1h-1.9v1.8h2.7V391.8z"/>
          <path className="st18" d="M1071.6,385.5h5.3v1h-2v5.3h-1.3v-5.3h-2V385.5L1071.6,385.5z"/>
          <path className="st18" d="M1086.5,390.6c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.4,0
            c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
            c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.2-0.3-0.3-0.4-0.5
            s-0.1-0.5-0.1-0.7s0.1-0.5,0.2-0.7s0.3-0.4,0.4-0.5c0.2-0.2,0.4-0.3,0.6-0.4s0.5-0.1,0.8-0.1s0.5,0,0.7,0s0.4,0.1,0.5,0.1
            s0.3,0.1,0.4,0.1s0.2,0.1,0.3,0.1l-0.4,0.9c-0.1-0.1-0.3-0.1-0.5-0.2s-0.4-0.1-0.7-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1
            c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.3,0.1,0.4s0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3
            c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.3,0.5,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
            c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.3-0.8,0.4s-0.6,0.1-0.9,0.1s-0.5,0-0.7,0s-0.4-0.1-0.6-0.1
            s-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.1l0.4-0.9C1086.4,390.6,1086.4,390.6,1086.5,390.6z"/>
          <path className="st18" d="M1094.6,391.8h-1.2l2.6-6.3h0.9l2.3,6.3h-1.4l-0.5-1.4h-2.2L1094.6,391.8z M1096.3,387.4l-0.8,2.1h1.6
            L1096.3,387.4z"/>
          <path className="st18" d="M1107.9,389.6c0,0.3-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.7s-0.5,0.4-0.8,0.5s-0.7,0.2-1.1,0.2s-0.8-0.1-1.1-0.2
            c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.3-0.2-0.6-0.2-0.9v-4.1h1.3v3.9c0,0.5,0.1,0.8,0.3,1.1
            c0.2,0.3,0.6,0.4,1.1,0.4c0.2,0,0.5,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.1-0.5v-3.9h1.2
            V389.6z"/>
          <path className="st18" d="M1112.4,390.6c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.4,0
            c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
            c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.2-0.3-0.3-0.4-0.5
            s-0.1-0.5-0.1-0.7s0.1-0.5,0.2-0.7s0.3-0.4,0.4-0.5c0.2-0.2,0.4-0.3,0.6-0.4s0.5-0.1,0.8-0.1s0.5,0,0.7,0s0.4,0.1,0.5,0.1
            s0.3,0.1,0.4,0.1s0.2,0.1,0.3,0.1l-0.4,0.9c-0.1-0.1-0.3-0.1-0.5-0.2s-0.4-0.1-0.7-0.1c-0.1,0-0.2,0-0.3,0s-0.2,0.1-0.3,0.1
            c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.3,0.1,0.4s0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3
            c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.3,0.5,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
            c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.3-0.8,0.4s-0.6,0.1-0.9,0.1s-0.5,0-0.7,0s-0.4-0.1-0.6-0.1
            s-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.1l0.4-0.9C1112.2,390.6,1112.3,390.6,1112.4,390.6z"/>
          <path className="st18" d="M1120.6,391.8h-1.2l2.6-6.3h0.9l2.3,6.3h-1.4l-0.5-1.4h-2.2L1120.6,391.8z M1122.2,387.4l-0.8,2.1h1.6
            L1122.2,387.4z"/>
          <path className="st18" d="M1133.8,391.6c-0.1,0.1-0.3,0.1-0.5,0.1s-0.4,0.1-0.7,0.1s-0.5,0.1-0.8,0.1c-0.5,0-0.9-0.1-1.4-0.2
            c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.3-0.6-0.6-0.7-1c-0.2-0.4-0.3-0.9-0.3-1.4s0.1-1,0.3-1.4c0.2-0.4,0.4-0.8,0.7-1
            c0.3-0.3,0.7-0.5,1.1-0.7s0.9-0.2,1.4-0.2c0.3,0,0.6,0,0.8,0s0.4,0.1,0.6,0.1s0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.1l-0.4,0.9
            c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.5-0.1s-0.4,0-0.6,0c-0.3,0-0.5,0.1-0.8,0.2
            c-0.2,0.1-0.5,0.3-0.7,0.5s-0.4,0.4-0.5,0.7c-0.1,0.3-0.2,0.6-0.2,1s0.1,0.7,0.2,1s0.3,0.5,0.5,0.7s0.4,0.3,0.7,0.4
            c0.3,0.1,0.5,0.2,0.8,0.2c0.1,0,0.2,0,0.3,0s0.2,0,0.2,0c0.1,0,0.1,0,0.2,0s0.1,0,0.2-0.1v-1.7h1.3v2.4
            C1134,391.5,1133.9,391.5,1133.8,391.6z"/>
          <path className="st18" d="M1142,391.8h-4v-6.3h3.9v1h-2.6v1.6h1.9v1h-1.9v1.8h2.7V391.8z"/>
          <path className="st18" d="M1146.3,390.6c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.4,0
            c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
            c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.2-0.3-0.3-0.4-0.5
            s-0.1-0.5-0.1-0.7s0.1-0.5,0.2-0.7s0.3-0.4,0.4-0.5c0.2-0.2,0.4-0.3,0.6-0.4s0.5-0.1,0.8-0.1s0.5,0,0.7,0s0.4,0.1,0.5,0.1
            s0.3,0.1,0.4,0.1s0.2,0.1,0.3,0.1l-0.4,0.9c-0.1-0.1-0.3-0.1-0.5-0.2s-0.4-0.1-0.7-0.1c-0.1,0-0.2,0-0.3,0s-0.2,0.1-0.3,0.1
            c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.3,0.1,0.4s0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3
            c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.3,0.5,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
            c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.3-0.8,0.4s-0.6,0.1-0.9,0.1s-0.5,0-0.7,0s-0.4-0.1-0.6-0.1
            s-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.1l0.4-0.9C1146.2,390.6,1146.2,390.6,1146.3,390.6z"/>
        </g>
        <g id="pmAjkW_1_">
          <g>
            <path d="M975.3,380.5c0.3-1.3,1.2-2,2.4-2.4c0.4-0.1,0.5-0.4,0.6-0.8c0.2-1.6,1.2-2.6,2.6-3.1c2.4-1.1,4.8-2.2,7.3-3.2
              c0.8-0.3,1.6-0.7,2.4-0.9c1-0.2,2,0,2.9,0.7c0.3,0.2,0.5,0.2,0.8,0.1c0.6-0.2,1.2-0.5,1.9-0.6c1.3-0.1,2.4,0.8,2.8,2.1
              c0,0.1,0.1,0.2,0.1,0.2c0,0.3,0,0.6,0,0.8c-0.5,1.3-0.6,1.5-1.6,2.2c0.2,0.5,0.4,1.1,0.5,1.6c0.2,1.9-0.8,3.2-2.4,4
              c-1.9,1-3.8,1.8-5.8,2.7c-1.5,0.7-3,1.4-4.5,2c-0.9,0.4-1.9,0.5-2.9,0.3c-1.4-0.3-2.3-1.1-2.7-2.5c-0.5,0.1-1,0.2-1.5,0.1
              c-1.3-0.1-2.1-0.9-2.5-2.1c0-0.1-0.1-0.2-0.1-0.2C975.3,381.1,975.3,380.8,975.3,380.5z M983,385c0.5-0.1,1-0.2,1.5-0.4
              c3.3-1.5,6.6-3,9.9-4.4c0.8-0.4,1.4-0.9,1.8-1.8c0.6-1.3,0-2.7-1.4-3.1c-0.9-0.3-1.8-0.2-2.7,0.2c-3.2,1.5-6.4,2.9-9.6,4.3
              c-0.7,0.3-1.4,0.8-1.8,1.5c-0.2,0.5-0.4,1.1-0.4,1.6C980.4,384.1,981.5,384.9,983,385z M994.9,372.6c0.5-0.1,0.9-0.2,1.4-0.3
              c0.2-0.1,0.2-0.4,0.4-0.5c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.9,0.2-1.3,0.2c-1.1,0-2,0.4-2.7,1.2c-0.7,0.8-1.5,1.1-2.5,1.1
              c-1.1,0-2,0.4-2.7,1.2c-0.6,0.8-1.4,1.1-2.4,1.1c-1.1,0-2.1,0.4-2.9,1.3c-0.6,0.7-1.4,1-2.4,1c-0.5,0-0.9,0.1-1.4,0.2
              c-0.3,0.1-0.5,0.3-0.3,0.6c0.1,0.1,0.4,0.2,0.6,0.2c0.4,0,0.9-0.2,1.3-0.2c1.1,0,2.1-0.4,2.8-1.3c0.6-0.7,1.4-1.1,2.3-1
              c1.2,0,2.1-0.4,2.9-1.3c0.6-0.8,1.4-1.1,2.4-1.1c1.1,0,2-0.4,2.8-1.2C993,372.8,993.8,372.4,994.9,372.6z M992.2,371.5
              L992.2,371.5c-0.4-0.2-0.7-0.4-1.1-0.3c-0.5,0-1.1,0.1-1.5,0.3c-2.9,1.3-5.7,2.6-8.7,3.8c-0.9,0.4-1.5,1-1.8,2.1
              C983.8,375.4,988,373.5,992.2,371.5z"/>
          </g>
        </g>
      </g>
      <g id="mixology_on_montgomery">
        
          <rect x="1333" y="363.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 126.977 1064.491)" className="st21" width="30.9" height="30.9"/>
        
          <rect x="1336.3" y="363.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 127.9436 1066.8092)" className="st22" width="30.9" height="30.9"/>
        <g>
          <path className="st18" d="M1393.9,379.5h-3l-0.2-8.9l-3.7,8.9h-2l-3.5-8.9l-0.4,8.9h-2.5l0.6-14.6h2.9l4.2,10.7l4.4-10.7h2.7
            L1393.9,379.5z"/>
          <path className="st18" d="M1399.7,379.5v-14.6h3v14.6H1399.7z"/>
          <path className="st18" d="M1410.4,379.5h-2.9l5-7.4l-4.6-7.2h3.3l3,4.6l3-4.6h2.9l-4.5,6.7l5.1,7.9h-3.4l-3.3-5.3L1410.4,379.5z"/>
          <path className="st18" d="M1431.6,364.6c1.2,0,2.2,0.2,3.1,0.6s1.8,0.9,2.5,1.6s1.2,1.5,1.6,2.4s0.6,2,0.6,3c0,1.2-0.2,2.2-0.6,3.1
            s-1,1.8-1.7,2.4c-0.7,0.7-1.5,1.2-2.5,1.5c-0.9,0.4-1.9,0.5-3,0.5s-2.1-0.2-3-0.5s-1.8-0.9-2.5-1.5c-0.7-0.7-1.3-1.5-1.7-2.4
            s-0.6-2-0.6-3.1s0.2-2.1,0.6-3s1-1.8,1.7-2.4c0.7-0.7,1.5-1.2,2.5-1.6C1429.6,364.8,1430.6,364.6,1431.6,364.6z M1431.6,366.9
            c-0.7,0-1.3,0.1-1.9,0.4s-1,0.7-1.4,1.1c-0.4,0.5-0.7,1.1-0.9,1.7c-0.2,0.7-0.3,1.4-0.3,2.1s0.1,1.4,0.3,2.1
            c0.2,0.6,0.5,1.2,0.9,1.7s0.9,0.9,1.4,1.2c0.6,0.3,1.2,0.4,1.9,0.4c0.7,0,1.3-0.1,1.9-0.4s1-0.7,1.4-1.2s0.7-1.1,0.9-1.7
            c0.2-0.6,0.3-1.3,0.3-2.1c0-0.7-0.1-1.4-0.3-2.1c-0.2-0.7-0.5-1.2-0.9-1.7s-0.9-0.9-1.4-1.2
            C1432.9,367.1,1432.3,366.9,1431.6,366.9z"/>
          <path className="st18" d="M1447.9,377.2h5.4v2.3h-8.5v-14.6h3L1447.9,377.2L1447.9,377.2z"/>
          <path className="st18" d="M1464.2,364.6c1.2,0,2.2,0.2,3.1,0.6s1.8,0.9,2.5,1.6s1.2,1.5,1.6,2.4s0.6,2,0.6,3c0,1.2-0.2,2.2-0.6,3.1
            s-1,1.8-1.7,2.4s-1.5,1.2-2.5,1.5s-1.9,0.5-3,0.5s-2.1-0.2-3-0.5c-0.9-0.4-1.8-0.9-2.5-1.5s-1.3-1.5-1.7-2.4
            c-0.4-0.9-0.6-2-0.6-3.1s0.2-2.1,0.6-3s1-1.8,1.7-2.4c0.7-0.7,1.5-1.2,2.5-1.6C1462.1,364.8,1463.1,364.6,1464.2,364.6z
            M1464.2,366.9c-0.7,0-1.3,0.1-1.9,0.4s-1,0.7-1.4,1.1c-0.4,0.5-0.7,1.1-0.9,1.7c-0.2,0.7-0.3,1.4-0.3,2.1s0.1,1.4,0.3,2.1
            c0.2,0.6,0.5,1.2,0.9,1.7s0.9,0.9,1.4,1.2c0.6,0.3,1.2,0.4,1.9,0.4c0.7,0,1.3-0.1,1.9-0.4s1-0.7,1.4-1.2s0.7-1.1,0.9-1.7
            c0.2-0.6,0.3-1.3,0.3-2.1c0-0.7-0.1-1.4-0.3-2.1c-0.2-0.7-0.5-1.2-0.9-1.7s-0.9-0.9-1.4-1.2
            C1465.6,367.1,1464.9,366.9,1464.2,366.9z"/>
          <path className="st18" d="M1489.4,379.1c-0.3,0.1-0.7,0.2-1.2,0.3s-1,0.2-1.6,0.3c-0.6,0.1-1.2,0.1-1.9,0.1c-1.1,0-2.2-0.2-3.1-0.5
            c-1-0.4-1.8-0.9-2.6-1.5c-0.8-0.6-1.3-1.5-1.7-2.4c-0.4-0.9-0.6-2-0.6-3.1s0.2-2.2,0.6-3.1c0.4-1,1-1.8,1.7-2.4
            c0.7-0.7,1.6-1.2,2.6-1.5c1-0.4,2-0.5,3.1-0.5c0.7,0,1.3,0,1.9,0.1c0.5,0.1,1,0.2,1.4,0.3c0.4,0.1,0.7,0.2,1,0.3
            c0.3,0.1,0.5,0.2,0.7,0.3l-0.9,2.2c-0.1,0-0.3-0.1-0.5-0.2s-0.5-0.2-0.8-0.3c-0.3-0.1-0.7-0.2-1.1-0.2c-0.4-0.1-0.9-0.1-1.4-0.1
            c-0.6,0-1.2,0.1-1.8,0.4c-0.6,0.2-1.1,0.6-1.6,1.1s-0.8,1-1.1,1.7c-0.3,0.7-0.4,1.4-0.4,2.2c0,0.8,0.1,1.6,0.4,2.2
            c0.3,0.7,0.7,1.2,1.1,1.7c0.5,0.5,1,0.8,1.6,1s1.2,0.4,1.9,0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0,0.6-0.1c0.2,0,0.3-0.1,0.5-0.1
            c0.1,0,0.3-0.1,0.4-0.1v-3.9h3v5.5C1490,378.9,1489.8,379,1489.4,379.1z"/>
          <path className="st18" d="M1506,364.9l-4.7,7.5v7.1h-3v-7.1l-4.6-7.5h3.3l3,5.3l3.1-5.3H1506z"/>
          <path className="st18" d="M1381.8,385.2c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8c0.3,0.3,0.6,0.7,0.8,1.2c0.2,0.5,0.3,1,0.3,1.6
            s-0.1,1.1-0.3,1.6s-0.5,0.9-0.9,1.2c-0.4,0.3-0.8,0.6-1.2,0.8c-0.5,0.2-1,0.3-1.5,0.3s-1-0.1-1.5-0.3c-0.5-0.2-0.9-0.4-1.2-0.8
            c-0.4-0.3-0.6-0.7-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.6c0-0.5,0.1-1.1,0.3-1.5c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.3,0.8-0.6,1.2-0.8
            C1380.8,385.3,1381.3,385.2,1381.8,385.2z M1381.8,386.3c-0.3,0-0.7,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.7,0.6
            c-0.2,0.2-0.3,0.5-0.4,0.9c-0.1,0.3-0.2,0.7-0.2,1.1s0.1,0.7,0.2,1s0.3,0.6,0.4,0.9c0.2,0.2,0.4,0.4,0.7,0.6
            c0.3,0.1,0.6,0.2,0.9,0.2s0.7-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.9c0.1-0.3,0.2-0.7,0.2-1
            c0-0.4-0.1-0.7-0.2-1s-0.3-0.6-0.4-0.9c-0.2-0.2-0.4-0.4-0.7-0.6C1382.5,386.3,1382.2,386.3,1381.8,386.3z"/>
          <path className="st18" d="M1396.9,385.4v7.3h-1.5l-3.7-4.8v4.8h-1.3v-7.3h1.4l3.8,4.9v-4.9H1396.9z"/>
          <path className="st18" d="M1415.5,392.6h-1.5l-0.1-4.4l-1.9,4.4h-1l-1.8-4.4l-0.2,4.4h-1.3l0.3-7.3h1.5l2.1,5.3l2.3-5.3h1.4
            L1415.5,392.6z"/>
          <path className="st18" d="M1424,385.2c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8c0.3,0.3,0.6,0.7,0.8,1.2c0.2,0.5,0.3,1,0.3,1.6
            s-0.1,1.1-0.3,1.6c-0.2,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.8,0.6-1.2,0.8c-0.5,0.2-1,0.3-1.5,0.3s-1-0.1-1.5-0.3
            c-0.5-0.2-0.9-0.4-1.2-0.8c-0.4-0.3-0.6-0.7-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.6c0-0.5,0.1-1.1,0.3-1.5c0.2-0.5,0.5-0.9,0.8-1.2
            c0.4-0.3,0.8-0.6,1.2-0.8C1422.9,385.3,1423.4,385.2,1424,385.2z M1424,386.3c-0.3,0-0.7,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.7,0.6
            c-0.2,0.2-0.3,0.5-0.4,0.9c-0.1,0.3-0.2,0.7-0.2,1.1s0.1,0.7,0.2,1s0.3,0.6,0.4,0.9c0.2,0.2,0.4,0.4,0.7,0.6
            c0.3,0.1,0.6,0.2,0.9,0.2s0.7-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.9c0.1-0.3,0.2-0.7,0.2-1
            c0-0.4-0.1-0.7-0.2-1s-0.3-0.6-0.4-0.9c-0.2-0.2-0.4-0.4-0.7-0.6C1424.6,386.3,1424.3,386.3,1424,386.3z"/>
          <path className="st18" d="M1439,385.4v7.3h-1.5l-3.7-4.8v4.8h-1.3v-7.3h1.4l3.8,4.9v-4.9H1439z"/>
          <path className="st18" d="M1443.4,385.4h6.2v1.2h-2.4v6.2h-1.6v-6.2h-2.4L1443.4,385.4L1443.4,385.4z"/>
          <path className="st18" d="M1460,392.3c-0.2,0.1-0.4,0.1-0.6,0.2s-0.5,0.1-0.8,0.1s-0.6,0.1-1,0.1c-0.6,0-1.1-0.1-1.6-0.3
            s-0.9-0.4-1.3-0.8c-0.4-0.3-0.6-0.7-0.9-1.2c-0.2-0.5-0.3-1-0.3-1.6s0.1-1.1,0.3-1.6c0.2-0.5,0.5-0.9,0.9-1.2
            c0.4-0.3,0.8-0.6,1.3-0.8s1-0.3,1.6-0.3c0.4,0,0.7,0,0.9,0.1c0.3,0,0.5,0.1,0.7,0.1c0.2,0.1,0.4,0.1,0.5,0.2s0.2,0.1,0.3,0.2
            l-0.5,1.1c-0.1,0-0.1-0.1-0.3-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.4-0.1-0.6-0.1s-0.4,0-0.7,0s-0.6,0.1-0.9,0.2s-0.6,0.3-0.8,0.5
            c-0.2,0.2-0.4,0.5-0.6,0.8c-0.1,0.3-0.2,0.7-0.2,1.1s0.1,0.8,0.2,1.1c0.1,0.3,0.3,0.6,0.6,0.8c0.2,0.2,0.5,0.4,0.8,0.5
            s0.6,0.2,0.9,0.2c0.2,0,0.3,0,0.4,0s0.2,0,0.3,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2-0.1v-2h1.5L1460,392.3
            C1460.3,392.3,1460.1,392.3,1460,392.3z"/>
          <path className="st18" d="M1468.5,385.2c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.8c0.3,0.3,0.6,0.7,0.8,1.2c0.2,0.5,0.3,1,0.3,1.6
            s-0.1,1.1-0.3,1.6c-0.2,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.8,0.6-1.2,0.8c-0.5,0.2-1,0.3-1.5,0.3s-1-0.1-1.5-0.3
            c-0.5-0.2-0.9-0.4-1.2-0.8c-0.4-0.3-0.6-0.7-0.8-1.2s-0.3-1-0.3-1.6c0-0.5,0.1-1.1,0.3-1.5c0.2-0.5,0.5-0.9,0.8-1.2
            c0.3-0.3,0.8-0.6,1.2-0.8C1467.4,385.3,1467.9,385.2,1468.5,385.2z M1468.5,386.3c-0.3,0-0.7,0.1-0.9,0.2
            c-0.3,0.1-0.5,0.3-0.7,0.6c-0.2,0.2-0.3,0.5-0.4,0.9c-0.1,0.3-0.2,0.7-0.2,1.1s0.1,0.7,0.2,1s0.3,0.6,0.4,0.9
            c0.2,0.2,0.4,0.4,0.7,0.6c0.3,0.1,0.6,0.2,0.9,0.2s0.7-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.5-0.9
            c0.1-0.3,0.2-0.7,0.2-1c0-0.4-0.1-0.7-0.2-1s-0.3-0.6-0.5-0.9c-0.2-0.2-0.4-0.4-0.7-0.6C1469.1,386.3,1468.8,386.3,1468.5,386.3z"
            />
          <path className="st18" d="M1484.5,392.6h-1.5l-0.1-4.4l-1.9,4.4h-1l-1.8-4.4l-0.2,4.4h-1.3l0.3-7.3h1.5l2.1,5.3l2.3-5.3h1.4
            L1484.5,392.6z"/>
          <path className="st18" d="M1494,392.6h-4.7v-7.3h4.5v1.2h-3v1.9h2.2v1.2h-2.2v2.1h3.1L1494,392.6L1494,392.6L1494,392.6z"/>
          <path className="st18" d="M1502.8,392.7l-2-3.2h-0.7v3.1h-1.6v-7.3h2.2c0.5,0,1,0.1,1.3,0.2c0.4,0.1,0.7,0.2,0.9,0.4
            c0.2,0.2,0.4,0.4,0.5,0.6s0.2,0.5,0.2,0.8s-0.1,0.5-0.2,0.7s-0.2,0.4-0.4,0.5c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.4,0.2
            l2.3,3.5L1502.8,392.7z M1500.7,388.4c0.5,0,0.8-0.1,1-0.3c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.6-0.3-1-0.3
            h-0.6v2.1L1500.7,388.4L1500.7,388.4z"/>
          <path className="st18" d="M1513.5,385.4l-2.4,3.7v3.5h-1.6v-3.5l-2.3-3.7h1.7l1.6,2.7l1.6-2.7H1513.5z"/>
        </g>
        <g id="_x37_BAaYq_3_">
          <g>
            <path className="st23" d="M1361.6,376.2c0,0.1-0.1,0.3-0.1,0.4c-0.7,2.8-3.5,4.2-6.2,3.1c-0.1,0-0.2-0.1-0.3-0.1
              c-0.7,0.8-1.4,1.6-2,2.5c-0.7,0.8-1.4,1.7-2.1,2.5c-0.1,0.1-0.2,0.3-0.2,0.4c0,1.5,0,3,0,4.5c0.9,0,1.7,0,2.6,0
              c0,0.4,0,0.8,0,1.3c-2.1,0-4.2,0-6.3,0c0-0.4,0-0.8,0-1.3c0.8,0,1.7,0,2.6,0c0-0.2,0-0.3,0-0.5c0-1.3,0-2.6,0-3.8
              c0-0.3-0.1-0.5-0.3-0.7c-2.6-3-5.1-6.1-7.7-9.1c0-0.1-0.1-0.1-0.2-0.2c1.3,0,2.6,0,3.8,0c-0.4-1.2-0.9-2.3-1.3-3.3
              c0-0.1-0.2-0.2-0.3-0.2c-0.8,0-1.6,0-2.5,0c0-0.4,0-0.9,0-1.3c1.2,0,2.5,0,3.6,0c0,0.1,0,0.1,0,0.2c0.6,1.5,1.1,3,1.7,4.4
              c0.1,0.2,0.2,0.2,0.4,0.2c1.9,0,3.6,0,5.5,0c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.3,0.1-0.4c0.5-2.1,2.3-3.4,4.3-3.4
              c2.1,0,3.8,1.4,4.3,3.4c0,0.2,0.1,0.3,0.1,0.5C1361.6,375.5,1361.6,375.8,1361.6,376.2z M1344.1,376.3c2.1,2.5,4,4.8,6,7.2
              c2-2.5,3.9-4.8,6-7.2C1352.1,376.3,1348.1,376.3,1344.1,376.3z M1355.9,378.6c1.1,0.6,2.6,0.3,3.5-0.7c1-1,1.2-2.6,0.6-3.7
              c-0.7-1.3-2.1-1.9-3.4-1.7c-1.3,0.3-2.4,1.4-2.5,2.6c1.6,0,3.1,0,4.7,0C1357.8,376.3,1356.9,377.4,1355.9,378.6z"/>
          </g>
        </g>
      </g>
      <g id="coliseum_cocktails">
        
          <rect x="2444.7" y="1161.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -112.9848 2087.2451)" className="st24" width="36.6" height="36.6"/>
        
          <rect x="2448.7" y="1161.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -111.8737 2090.1106)" className="st25" width="36.6" height="36.6"/>
        <g>
          <path className="st18" d="M2513.9,1179.9c-0.6,0.3-1.3,0.6-2.3,0.9s-2.2,0.4-3.8,0.4c-1.2,0-2.5-0.2-3.6-0.7c-1.2-0.5-2.1-1.1-2.9-1.8
            c-0.9-0.8-1.4-1.7-1.9-2.8c-0.5-1.1-0.7-2.3-0.7-3.6c0-1.3,0.2-2.5,0.7-3.6s1.2-2,1.9-2.9c0.9-0.8,1.8-1.4,2.9-1.8
            c1.2-0.5,2.3-0.7,3.6-0.7c0.9,0,1.6,0,2.3,0.1s1.2,0.2,1.7,0.3c0.5,0.1,0.9,0.3,1.2,0.4c0.3,0.2,0.6,0.3,0.8,0.4l-1.1,2.5
            c-0.1-0.1-0.3-0.2-0.6-0.3c-0.3-0.1-0.6-0.2-1-0.3c-0.4-0.1-0.8-0.2-1.3-0.3c-0.5-0.1-1.1-0.1-1.7-0.1s-1.3,0.2-2,0.5
            s-1.2,0.8-1.8,1.2c-0.6,0.6-1,1.2-1.2,1.9c-0.3,0.8-0.5,1.6-0.5,2.5s0.2,1.7,0.5,2.5s0.8,1.4,1.2,1.9c0.6,0.6,1.2,1,1.8,1.2
            c0.7,0.3,1.3,0.5,2,0.5c1.2,0,2.2-0.1,3-0.4c0.7-0.2,1.2-0.4,1.7-0.6L2513.9,1179.9z"/>
          <path className="st18" d="M2528.6,1163.2c1.3,0,2.6,0.2,3.7,0.7c1.2,0.5,2.1,1.1,2.9,1.8c0.8,0.8,1.4,1.7,1.8,2.9
            c0.5,1.1,0.7,2.3,0.7,3.6c0,1.3-0.3,2.6-0.8,3.7s-1.2,2-2,2.8c-0.9,0.8-1.8,1.3-2.9,1.8c-1.1,0.4-2.2,0.7-3.5,0.7
            c-1.2,0-2.4-0.2-3.6-0.6c-1.2-0.4-2.1-1-2.9-1.8c-0.9-0.8-1.4-1.7-1.9-2.8s-0.7-2.3-0.7-3.7c0-1.2,0.2-2.5,0.7-3.6s1.2-2,1.9-2.9
            c0.9-0.8,1.8-1.4,2.9-1.9C2526.1,1163.5,2527.2,1163.2,2528.6,1163.2z M2528.6,1165.9c-0.8,0-1.5,0.2-2.2,0.5s-1.2,0.8-1.6,1.3
            c-0.5,0.6-0.8,1.2-1.1,2s-0.4,1.6-0.4,2.5s0.1,1.6,0.4,2.4c0.3,0.8,0.6,1.4,1.1,2s1.1,1.1,1.6,1.3c0.7,0.3,1.3,0.5,2.2,0.5
            c0.8,0,1.5-0.2,2.2-0.5s1.2-0.8,1.6-1.3c0.5-0.6,0.8-1.2,1.1-2s0.4-1.5,0.4-2.4s-0.1-1.6-0.4-2.4c-0.3-0.8-0.6-1.4-1.1-2
            s-1.1-1.1-1.6-1.3C2530.1,1166.1,2529.3,1165.9,2528.6,1165.9z"/>
          <path className="st18" d="M2547.8,1178.1h6.4v2.7h-10v-17.3h3.6V1178.1L2547.8,1178.1z"/>
          <path className="st18" d="M2559.7,1180.8v-17.3h3.6v17.3H2559.7z"/>
          <path className="st18" d="M2571.4,1177.8c0.3,0.1,0.6,0.2,0.9,0.3c0.4,0.1,0.8,0.2,1.2,0.3c0.5,0.1,1,0.1,1.5,0.1c0.4,0,0.7,0,1-0.1
            s0.6-0.2,0.9-0.4c0.3-0.2,0.5-0.4,0.6-0.6c0.2-0.2,0.2-0.5,0.2-0.8c0-0.4-0.1-0.7-0.3-1s-0.4-0.6-0.8-0.8
            c-0.3-0.3-0.7-0.5-1.1-0.8c-0.4-0.3-0.9-0.5-1.3-0.8c-0.4-0.3-0.9-0.6-1.3-0.9c-0.5-0.3-0.9-0.7-1.3-1.1c-0.4-0.4-0.8-0.9-1-1.4
            c-0.3-0.6-0.4-1.2-0.4-2c0-0.7,0.2-1.2,0.5-1.8c0.3-0.6,0.7-1.1,1.2-1.4c0.5-0.4,1.1-0.8,1.7-1c0.7-0.2,1.3-0.4,2.1-0.4
            c0.7,0,1.3,0,1.8,0.1c0.6,0.1,1,0.2,1.4,0.3c0.4,0.1,0.8,0.2,1.1,0.3c0.3,0.1,0.5,0.2,0.8,0.3l-1.1,2.5c-0.3-0.2-0.8-0.3-1.2-0.5
            c-0.6-0.2-1.2-0.3-1.8-0.3c-0.3,0-0.6,0-1,0.1c-0.3,0.1-0.6,0.1-0.8,0.3c-0.3,0.1-0.5,0.3-0.6,0.5c-0.2,0.2-0.2,0.5-0.2,0.7
            c0,0.4,0.1,0.7,0.3,1s0.5,0.6,0.8,0.8c0.3,0.3,0.7,0.5,1.1,0.8c0.4,0.3,0.8,0.5,1.2,0.8s0.9,0.6,1.3,0.9c0.5,0.3,1,0.7,1.4,1.2
            c0.5,0.4,0.8,0.9,1.1,1.4s0.5,1.1,0.5,1.7c0,0.9-0.2,1.6-0.6,2.2c-0.4,0.6-0.9,1.2-1.4,1.6c-0.6,0.5-1.3,0.8-2.1,1.1
            c-0.8,0.2-1.6,0.4-2.5,0.4c-0.8,0-1.3,0-1.9-0.1s-1.1-0.2-1.5-0.3c-0.4-0.1-0.8-0.3-1.1-0.4s-0.5-0.3-0.7-0.4l1.1-2.4
            C2571,1177.6,2571.2,1177.7,2571.4,1177.8z"/>
          <path className="st18" d="M2599.2,1180.8h-11.1v-17.3h10.8v2.7h-7.1v4.3h5.2v2.7h-5.2v4.8h7.5L2599.2,1180.8L2599.2,1180.8z"/>
          <path className="st18" d="M2619.7,1174.9c0,0.9-0.2,1.7-0.5,2.5s-0.8,1.4-1.4,2c-0.6,0.6-1.3,1-2.3,1.3c-0.9,0.3-1.9,0.5-3.1,0.5
            c-1.2,0-2.2-0.2-3.1-0.5c-0.9-0.3-1.6-0.8-2.3-1.2c-0.6-0.6-1.1-1.2-1.4-2c-0.3-0.8-0.5-1.6-0.5-2.5v-11.3h3.6v10.9
            c0,1.2,0.3,2.3,0.9,3c0.6,0.7,1.5,1.1,2.9,1.1c0.7,0,1.2-0.1,1.7-0.4c0.5-0.2,0.9-0.6,1.2-1s0.5-0.8,0.7-1.2
            c0.2-0.5,0.2-1,0.2-1.4v-10.9h3.4L2619.7,1174.9L2619.7,1174.9z"/>
          <path className="st18" d="M2644.3,1180.8h-3.6l-0.2-10.5l-4.4,10.6h-2.3l-4.2-10.6l-0.4,10.5h-2.9l0.8-17.3h3.5l5,12.7l5.3-12.7h3.2
            L2644.3,1180.8z"/>
          <path className="st18" d="M2505.7,1195.9c-0.3,0.2-0.7,0.3-1.2,0.5s-1.2,0.2-1.9,0.2s-1.2-0.1-1.8-0.3c-0.6-0.2-1.1-0.5-1.4-1
            c-0.4-0.4-0.8-0.9-1-1.4c-0.2-0.6-0.4-1.2-0.4-1.8c0-0.7,0.1-1.2,0.4-1.8c0.2-0.6,0.6-1.1,1-1.4c0.4-0.4,0.9-0.7,1.4-1
            c0.6-0.2,1.2-0.3,1.8-0.3c0.5,0,0.9,0,1.2,0.1c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.1,0.4,0.2l-0.5,1.2
            c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.4-0.1-0.7-0.1s-0.6-0.1-0.9-0.1c-0.4,0-0.7,0.1-1.1,0.2
            c-0.3,0.2-0.7,0.4-0.9,0.7c-0.2,0.3-0.5,0.6-0.7,1s-0.3,0.8-0.3,1.2c0,0.5,0.1,0.9,0.3,1.2c0.2,0.4,0.4,0.7,0.7,1s0.6,0.5,0.9,0.7
            c0.3,0.2,0.7,0.2,1.1,0.2c0.7,0,1.2-0.1,1.4-0.2c0.4-0.1,0.7-0.2,0.9-0.3L2505.7,1195.9z"/>
          <path className="st18" d="M2515.3,1187.5c0.7,0,1.2,0.1,1.8,0.3c0.6,0.2,1.1,0.6,1.4,1c0.4,0.4,0.7,0.9,1,1.4c0.2,0.6,0.3,1.2,0.3,1.8
            c0,0.7-0.1,1.3-0.4,1.8c-0.3,0.6-0.6,1.1-1,1.4c-0.4,0.4-0.9,0.7-1.4,0.9c-0.6,0.2-1.2,0.3-1.7,0.3c-0.7,0-1.2-0.1-1.7-0.3
            c-0.6-0.2-1.1-0.5-1.4-0.9c-0.4-0.4-0.8-0.9-1-1.4c-0.2-0.6-0.4-1.2-0.4-1.8s0.1-1.2,0.4-1.8c0.2-0.6,0.6-1.1,1-1.4
            c0.4-0.4,0.9-0.7,1.4-1C2514.1,1187.7,2514.7,1187.5,2515.3,1187.5z M2515.3,1188.8c-0.4,0-0.8,0.1-1.1,0.2
            c-0.3,0.2-0.6,0.4-0.9,0.7c-0.2,0.3-0.4,0.6-0.5,1s-0.2,0.8-0.2,1.2c0,0.4,0.1,0.9,0.2,1.2c0.1,0.4,0.3,0.7,0.5,1s0.5,0.5,0.9,0.7
            c0.3,0.2,0.7,0.3,1.1,0.3c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.4,0.9-0.7c0.2-0.3,0.4-0.6,0.5-1s0.2-0.8,0.2-1.2
            c0-0.4-0.1-0.9-0.2-1.2c-0.1-0.4-0.3-0.7-0.5-1s-0.5-0.5-0.9-0.7C2516.1,1188.9,2515.8,1188.8,2515.3,1188.8z"/>
          <path className="st18" d="M2532.8,1195.9c-0.3,0.2-0.7,0.3-1.2,0.5s-1.2,0.2-1.9,0.2s-1.2-0.1-1.8-0.3c-0.6-0.2-1.1-0.5-1.4-1
            c-0.4-0.4-0.8-0.9-1-1.4c-0.2-0.6-0.4-1.2-0.4-1.8c0-0.7,0.1-1.2,0.4-1.8c0.2-0.6,0.6-1.1,1-1.4c0.4-0.4,0.9-0.7,1.4-1
            c0.6-0.2,1.2-0.3,1.8-0.3c0.5,0,0.9,0,1.2,0.1c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.1,0.4,0.2l-0.5,1.2
            c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.4-0.1-0.7-0.1s-0.6-0.1-0.9-0.1c-0.4,0-0.7,0.1-1.1,0.2
            c-0.4,0.2-0.7,0.4-0.9,0.7c-0.3,0.3-0.5,0.6-0.7,1s-0.3,0.8-0.3,1.2c0,0.5,0.1,0.9,0.3,1.2c0.2,0.4,0.4,0.7,0.7,1s0.6,0.5,0.9,0.7
            c0.3,0.2,0.7,0.2,1.1,0.2c0.7,0,1.2-0.1,1.4-0.2c0.4-0.1,0.7-0.2,0.9-0.3L2532.8,1195.9z"/>
          <path className="st18" d="M2543.5,1196.4l-3.6-3.9v3.8h-1.8v-8.6h1.8v3.8l3.4-3.8h1.9l-3.6,3.9l4.2,4.6L2543.5,1196.4z"/>
          <path className="st18" d="M2550.3,1187.7h7.3v1.3h-2.8v7.3h-1.8v-7.3h-2.8L2550.3,1187.7L2550.3,1187.7z"/>
          <path className="st18" d="M2562,1196.3h-1.6l3.6-8.6h1.2l3.2,8.6h-1.9l-0.7-1.9h-3L2562,1196.3z M2564.2,1190.4l-1.1,2.9h2.1
            L2564.2,1190.4z"/>
          <path className="st18" d="M2573.4,1196.3v-8.6h1.8v8.6H2573.4z"/>
          <path className="st18" d="M2582.9,1195h3.2v1.3h-5v-8.6h1.8V1195z"/>
          <path className="st18" d="M2591.7,1194.8c0.1,0.1,0.3,0.1,0.5,0.2s0.4,0.1,0.6,0.1s0.5,0.1,0.8,0.1c0.2,0,0.4,0,0.5-0.1
            c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.4-0.4
            s-0.4-0.3-0.6-0.4c-0.2-0.1-0.4-0.3-0.7-0.4c-0.2-0.1-0.4-0.3-0.7-0.4c-0.2-0.2-0.5-0.3-0.7-0.6c-0.2-0.2-0.4-0.5-0.5-0.8
            s-0.2-0.6-0.2-1c0-0.3,0.1-0.7,0.2-1c0.2-0.3,0.4-0.6,0.6-0.8c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.1,0.7-0.2,1.1-0.2c0.4,0,0.7,0,1,0.1
            c0.3,0,0.5,0.1,0.7,0.1c0.2,0.1,0.4,0.1,0.5,0.2s0.3,0.1,0.4,0.2l-0.5,1.2c-0.2-0.1-0.4-0.2-0.7-0.3c-0.3-0.1-0.6-0.1-0.9-0.1
            c-0.2,0-0.3,0-0.5,0s-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.1-0.3,0.2s-0.1,0.2-0.1,0.4c0,0.2,0,0.4,0.1,0.5s0.2,0.3,0.4,0.4
            s0.3,0.3,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.3,0.7,0.5s0.5,0.4,0.7,0.6s0.4,0.5,0.6,0.7c0.2,0.3,0.2,0.6,0.2,0.9
            c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.3-0.4,0.6-0.8,0.9c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.2-1.2,0.2s-0.7,0-1-0.1
            c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.1-0.4-0.2l0.5-1.2C2591.5,1194.7,2591.6,1194.8,2591.7,1194.8z"/>
        </g>
        <g id="YwgztN_1_">
          <g>
            <path d="M2466.2,1186.3c0,1.4,0,2.7,0,4.1c1.7,0,3.4,0,5.1,0c0,0.7,0,1.3,0,2c-4.1,0-8.1,0-12.2,0c0-0.7,0-1.2,0-2
              c1.6,0,3.4,0,5.1,0c0-1.4,0-2.8,0-4.1c-0.8-0.2-1.5-0.4-2.3-0.7c-3.9-1.4-6.3-5.5-5.9-9.6c0.3-2.2,0.7-4.4,1.1-6.7
              c0.1-0.5,0.3-0.6,0.7-0.6c5,0,9.9,0,14.9,0c0.4,0,0.6,0.1,0.7,0.6c0.4,2.3,0.8,4.6,1.1,7c0.5,4.5-2.8,8.8-7.3,9.7
              C2466.8,1186.1,2466.5,1186.2,2466.2,1186.3z M2472.1,1174c-0.2-1.2-0.4-2.1-0.6-3.1c-4.3,0-8.5,0-12.8,0
              c-0.3,1.8-0.6,3.6-0.8,5.4c-0.4,3.5,1.9,6.8,5.2,7.8c3.5,1,7.1-0.7,8.5-4c0.6-1.2,0.8-2.6,0.6-4c-4,0-8.1,0-12.2,0
              c0-0.7,0-1.3,0-2C2464.1,1174,2468.1,1174,2472.1,1174z"/>
          </g>
        </g>
      </g>
      <g id="botanic_beverages">
        
          <rect x="1343.3" y="1746.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -848.3994 1477.5739)" className="st26" width="32.2" height="32.2"/>
        
          <rect x="1346.7" y="1746.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -847.3554 1479.9589)" className="st27" width="32.2" height="32.2"/>
        <g>
          <path className="st18" d="M1391.2,1748.4h5c0.9,0,1.6,0.1,2.2,0.3c0.6,0.2,1.2,0.4,1.6,0.8s0.7,0.7,1,1.2c0.2,0.4,0.4,0.9,0.4,1.3
            c0,0.5-0.1,0.9-0.3,1.2c-0.2,0.4-0.4,0.6-0.6,0.9c-0.3,0.3-0.5,0.4-0.8,0.6s-0.5,0.3-0.8,0.4c0.4,0,0.9,0.2,1.3,0.4
            c0.4,0.2,0.8,0.4,1.2,0.8c0.4,0.4,0.6,0.7,0.9,1.2c0.3,0.4,0.4,1,0.4,1.5s-0.2,1.2-0.4,1.7c-0.3,0.5-0.7,1.1-1.2,1.4
            c-0.5,0.4-1.1,0.8-1.7,1c-0.6,0.3-1.3,0.4-2.1,0.4h-6.1V1748.4L1391.2,1748.4L1391.2,1748.4z M1395.6,1754.8c0.4,0,0.7,0,1-0.1
            s0.6-0.2,0.8-0.4c0.3-0.2,0.4-0.4,0.5-0.7c0.2-0.3,0.2-0.6,0.2-1s-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.5-0.5-0.6
            c-0.3-0.2-0.5-0.3-0.8-0.4s-0.6-0.1-1-0.1h-1.2v4.3L1395.6,1754.8L1395.6,1754.8z M1398.6,1760.9c0.5-0.4,0.8-0.9,0.8-1.7
            c0-0.8-0.3-1.3-0.8-1.7c-0.5-0.4-1.2-0.5-2-0.5h-2.1v4.5h2.1C1397.3,1761.4,1398,1761.2,1398.6,1760.9z"/>
          <path className="st18" d="M1415.3,1748c1.2,0,2.2,0.2,3.3,0.6c1,0.4,1.9,0.9,2.6,1.6s1.2,1.5,1.6,2.5s0.6,2,0.6,3.2s-0.2,2.3-0.6,3.3
            s-1.1,1.8-1.8,2.5c-0.7,0.7-1.6,1.2-2.6,1.6s-2,0.5-3,0.5c-1.1,0-2.1-0.2-3.1-0.5c-1-0.4-1.8-0.9-2.6-1.6
            c-0.7-0.7-1.3-1.5-1.7-2.5c-0.4-1-0.6-2-0.6-3.3c0-1.2,0.2-2.1,0.6-3.1s1-1.8,1.7-2.5s1.6-1.2,2.6-1.7
            C1413.2,1748.3,1414.1,1748,1415.3,1748z M1415.3,1750.4c-0.7,0-1.3,0.2-2,0.4c-0.5,0.3-1.1,0.7-1.4,1.2c-0.4,0.5-0.7,1.1-0.9,1.8
            c-0.2,0.7-0.4,1.4-0.4,2.2c0,0.7,0.1,1.4,0.4,2.1c0.2,0.6,0.5,1.2,0.9,1.8c0.4,0.5,0.9,0.9,1.4,1.2c0.5,0.3,1.2,0.4,2,0.4
            c0.7,0,1.3-0.2,2-0.4c0.5-0.3,1.1-0.7,1.4-1.2c0.4-0.5,0.7-1.1,0.9-1.8c0.2-0.6,0.4-1.3,0.4-2.1c0-0.7-0.1-1.4-0.4-2.1
            c-0.2-0.7-0.5-1.2-0.9-1.8c-0.4-0.5-0.9-0.9-1.4-1.2C1416.6,1750.6,1416,1750.4,1415.3,1750.4z"/>
          <path className="st18" d="M1426.3,1748.4h12.9v2.4h-4.8v12.8h-3.2v-12.8h-4.8L1426.3,1748.4L1426.3,1748.4z"/>
          <path className="st18" d="M1442.7,1763.5h-2.9l6.2-15.2h2.2l5.6,15.2h-3.3l-1.2-3.4h-5.3L1442.7,1763.5z M1446.7,1753l-1.9,5h3.7
            L1446.7,1753z"/>
          <path className="st18" d="M1472.4,1748.4v15.2h-3.1l-7.8-10.2v10.2h-2.8v-15.2h2.9l8.1,10.3v-10.3H1472.4z"/>
          <path className="st18" d="M1478.6,1763.5v-15.2h3.2v15.2H1478.6z"/>
          <path className="st18" d="M1500.8,1762.7c-0.5,0.3-1.2,0.5-2,0.8s-2,0.4-3.4,0.4c-1.2,0-2.1-0.2-3.1-0.6s-1.9-0.9-2.6-1.6
            s-1.2-1.5-1.7-2.5c-0.4-1-0.6-2-0.6-3.2s0.2-2.2,0.6-3.2s1-1.8,1.7-2.5s1.6-1.2,2.6-1.6s2-0.6,3.1-0.6c0.8,0,1.5,0,2,0.1
            c0.6,0.1,1.1,0.2,1.5,0.3c0.4,0.1,0.8,0.3,1.1,0.4c0.3,0.1,0.5,0.3,0.6,0.4l-0.9,2.2c-0.1-0.1-0.3-0.2-0.5-0.3
            c-0.2-0.1-0.5-0.2-0.8-0.3c-0.4-0.1-0.7-0.2-1.2-0.3c-0.4-0.1-1-0.1-1.5-0.1c-0.6,0-1.2,0.1-1.8,0.4c-0.6,0.3-1.2,0.6-1.6,1.2
            c-0.4,0.4-0.9,1.1-1.2,1.7c-0.3,0.7-0.4,1.4-0.4,2.2c0,0.8,0.2,1.5,0.4,2.1c0.3,0.6,0.6,1.2,1.2,1.7c0.4,0.4,1,0.9,1.6,1.2
            c0.6,0.3,1.2,0.4,1.8,0.4c1.1,0,2-0.1,2.6-0.3c0.6-0.2,1.2-0.4,1.5-0.5L1500.8,1762.7z"/>
          <path className="st18" d="M1390.4,1769.6h2.5c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.6,0.3,0.8,0.4c0.2,0.2,0.4,0.4,0.4,0.6
            c0.1,0.2,0.2,0.4,0.2,0.7s0,0.4-0.1,0.6s-0.2,0.4-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.3,0.2-0.4,0.2
            c0.2,0,0.4,0.1,0.6,0.2s0.4,0.3,0.6,0.4c0.2,0.2,0.4,0.4,0.4,0.6s0.2,0.4,0.2,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.3-0.4,0.5-0.6,0.7
            c-0.3,0.2-0.5,0.4-0.9,0.5s-0.7,0.2-1.1,0.2h-3L1390.4,1769.6L1390.4,1769.6z M1392.6,1772.8c0.2,0,0.4,0,0.5-0.1
            c0.2,0,0.3-0.1,0.4-0.2s0.2-0.2,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5s0-0.4-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.4
            c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.4-0.1-0.5-0.1h-0.6v2.1L1392.6,1772.8L1392.6,1772.8z M1394.1,1775.9c0.3-0.2,0.4-0.4,0.4-0.9
            s-0.1-0.7-0.4-0.9c-0.3-0.2-0.6-0.3-1-0.3h-1.1v2.2h1.1C1393.5,1776.2,1393.8,1776.1,1394.1,1775.9z"/>
          <path className="st18" d="M1405.7,1777.2h-4.9v-7.6h4.7v1.2h-3.1v1.9h2.3v1.2h-2.3v2.1h3.3V1777.2z"/>
          <path className="st18" d="M1413.2,1777.3h-1l-2.5-7.7h1.6l1.6,5l1.7-5h1.4L1413.2,1777.3z"/>
          <path className="st18" d="M1425.3,1777.2h-4.9v-7.6h4.7v1.2h-3.1v1.9h2.3v1.2h-2.3v2.1h3.3V1777.2z"/>
          <path className="st18" d="M1434.4,1777.3l-2-3.4h-0.7v3.3h-1.6v-7.6h2.2c0.5,0,1,0.1,1.3,0.2c0.4,0.1,0.7,0.3,1,0.4
            c0.3,0.2,0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.5,0.2,0.8s-0.1,0.5-0.2,0.7s-0.3,0.4-0.4,0.5c-0.2,0.2-0.3,0.3-0.4,0.4
            c-0.2,0.1-0.3,0.2-0.4,0.2l2.3,3.7L1434.4,1777.3z M1432.2,1772.9c0.4,0,0.8-0.1,1.1-0.4c0.3-0.2,0.4-0.4,0.4-0.7
            c0-0.3-0.1-0.5-0.4-0.8c-0.2-0.2-0.5-0.4-1.1-0.4h-0.6v2.1L1432.2,1772.9L1432.2,1772.9z"/>
          <path className="st18" d="M1441.1,1777.2h-1.4l3.1-7.7h1.1l2.8,7.7h-1.7l-0.6-1.7h-2.7L1441.1,1777.2z M1443.1,1771.9l-1,2.5h1.9
            L1443.1,1771.9z"/>
          <path className="st18" d="M1457.2,1777c-0.2,0.1-0.4,0.1-0.6,0.2s-0.5,0.1-0.8,0.2c-0.3,0-0.6,0.1-1,0.1c-0.5,0-1.2-0.1-1.6-0.3
            c-0.5-0.2-1-0.4-1.3-0.8s-0.6-0.7-0.9-1.2c-0.2-0.4-0.4-1.1-0.4-1.6c0-0.6,0.1-1.2,0.4-1.6c0.2-0.4,0.5-0.9,0.9-1.2
            s0.8-0.6,1.3-0.8c0.5-0.2,1.1-0.3,1.6-0.3c0.4,0,0.7,0,1,0.1c0.3,0,0.5,0.1,0.7,0.2s0.4,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.4,0.2
            l-0.4,1.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4-0.1-0.7-0.1s-0.6,0.1-0.9,0.2
            s-0.5,0.3-0.8,0.5s-0.4,0.5-0.5,0.9c-0.2,0.4-0.2,0.7-0.2,1.2s0.1,0.8,0.2,1.2c0.2,0.4,0.4,0.6,0.5,0.9c0.3,0.3,0.5,0.4,0.8,0.5
            s0.6,0.2,1,0.2c0.2,0,0.3,0,0.4,0s0.2,0,0.3,0s0.2,0,0.3-0.1c0.1,0,0.2,0,0.2-0.1v-2h1.5v2.9
            C1457.4,1776.9,1457.3,1776.9,1457.2,1777z"/>
          <path className="st18" d="M1467.2,1777.2h-4.9v-7.6h4.7v1.2h-3.1v1.9h2.3v1.2h-2.3v2.1h3.3V1777.2z"/>
          <path className="st18" d="M1472.4,1775.8c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.5,0.1c0.2,0,0.4,0.1,0.7,0.1c0.2,0,0.3,0,0.4-0.1
            c0.2,0,0.3-0.1,0.4-0.2s0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.4s-0.2-0.3-0.4-0.4s-0.3-0.3-0.4-0.4
            c-0.2-0.1-0.4-0.3-0.6-0.4s-0.4-0.3-0.6-0.4s-0.4-0.3-0.5-0.4c-0.2-0.2-0.4-0.4-0.4-0.6c-0.1-0.3-0.2-0.5-0.2-0.9
            c0-0.3,0.1-0.5,0.2-0.8s0.3-0.4,0.5-0.6s0.4-0.4,0.8-0.4c0.3-0.1,0.6-0.2,0.9-0.2s0.5,0,0.8,0.1c0.3,0,0.4,0.1,0.6,0.1
            c0.2,0.1,0.4,0.1,0.4,0.2s0.3,0.1,0.4,0.2l-0.4,1.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.2,0-0.3,0-0.4,0
            s-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.4s0,0.3,0.1,0.4s0.2,0.3,0.4,0.4c0.2,0.1,0.3,0.2,0.4,0.4
            c0.2,0.1,0.4,0.2,0.5,0.4c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.2,0.4,0.4,0.4,0.6c0.1,0.3,0.2,0.4,0.2,0.7
            c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.5,0.4-0.9,0.4c-0.4,0.1-0.7,0.2-1.1,0.2s-0.6,0-0.9-0.1
            c-0.3,0-0.4-0.1-0.6-0.2s-0.4-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.3-0.2l0.4-1.1C1472.1,1775.7,1472.2,1775.8,1472.4,1775.8z"/>
        </g>
        <g id="vkBOy4_1_">
          <g>
            <path className="st28" d="M1357.5,1773c-1-0.4-1.2-0.8-1-1.9c0.4-2.6,0.3-5.2-0.7-7.6c-0.8-2-1.1-4-0.9-6.1c0.1-1.3,0.3-2.7,0.4-4
              c0.1-0.7,0.4-1.2,1.1-1.4c3.1,0,6.3,0,9.4,0c0.6,0.2,1,0.6,1.1,1.3c0.5,3,0.8,6.1-0.1,9.1c-0.3,1.1-0.7,2-1,3.1
              c-0.4,1.9-0.4,3.7-0.1,5.6c0.2,1.1,0,1.4-1,1.9C1362.5,1773,1360,1773,1357.5,1773z M1361.2,1756.6c-1.6,0-3.2,0-4.8,0
              c-0.3,0-0.4,0-0.4,0.4c-0.1,2,0.1,4.1,0.8,6.1c1.1,2.7,1.2,5.4,0.7,8.3c-0.1,0.5,0,0.6,0.5,0.6c2,0,4.2,0,6.2,0
              c0.5,0,0.6-0.1,0.5-0.6c-0.5-2.8-0.4-5.5,0.7-8.1c0.8-2,1-4,0.9-6.1c0-0.4-0.1-0.4-0.4-0.4
              C1364.4,1756.7,1362.8,1756.6,1361.2,1756.6z M1366.4,1755.6c-0.2-0.8-0.3-1.6-0.4-2.3c0-0.2-0.3-0.3-0.4-0.4
              c-0.4-0.1-0.8,0-1.2,0c-2.4,0-4.9,0-7.3,0c-0.2,0-0.4,0.1-0.5,0.3c-0.2,0.8-0.3,1.6-0.4,2.4
              C1359.5,1755.6,1362.9,1755.6,1366.4,1755.6z"/>
          </g>
        </g>
      </g>
      <g id="camp_bowie_q">
        
          <rect x="1163.6" y="361.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 79.1536 942.6227)" className="st4" width="27.7" height="27.7"/>
        
          <rect x="1160.4" y="360.2" transform="matrix(0.7216 -0.6923 0.6923 0.7216 67.338 918.5117)" className="st29" width="30.7" height="30.7"/>
        
          <rect x="1163.7" y="360.3" transform="matrix(0.7216 -0.6923 0.6923 0.7216 68.1782 920.8167)" className="st29" width="30.7" height="30.7"/>
        <g>
          <g>
            <path className="st18" d="M1215,367.4c-0.3,0.1-0.6,0.3-1,0.4s-0.9,0.2-1.6,0.2c-0.5,0-1-0.1-1.5-0.3c-0.5-0.2-0.9-0.4-1.2-0.8
              c-0.3-0.3-0.6-0.7-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.5s0.1-1.1,0.3-1.5c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.3,0.7-0.6,1.2-0.8
              c0.5-0.2,1-0.3,1.5-0.3c0.4,0,0.7,0,1,0.1c0.3,0,0.5,0.1,0.7,0.1c0.2,0.1,0.4,0.1,0.5,0.2s0.2,0.1,0.3,0.2l-0.4,1
              c-0.1,0-0.1-0.1-0.2-0.1s-0.2-0.1-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1s-0.5,0-0.7,0c-0.3,0-0.6,0.1-0.9,0.2s-0.5,0.3-0.8,0.5
              c-0.2,0.2-0.4,0.5-0.5,0.8s-0.2,0.7-0.2,1.1s0.1,0.7,0.2,1s0.3,0.6,0.5,0.8c0.2,0.2,0.5,0.4,0.8,0.5s0.6,0.2,0.9,0.2
              c0.5,0,0.9,0,1.2-0.1c0.3-0.1,0.5-0.2,0.7-0.3L1215,367.4z"/>
            <path className="st18" d="M1220.3,367.8h-1.4l3-7.3h1.1l2.7,7.3h-1.6l-0.6-1.6h-2.5L1220.3,367.8z M1222.2,362.8l-0.9,2.4h1.8
              L1222.2,362.8z"/>
            <path className="st18" d="M1237.4,367.8h-1.5l-0.1-4.4l-1.9,4.4h-1l-1.8-4.4l-0.2,4.4h-1.2l0.3-7.2h1.4l2.1,5.3l2.2-5.3h1.3
              L1237.4,367.8z"/>
            <path className="st18" d="M1242.2,367.8v-7.2h2.3c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.8,0.4c0.2,0.2,0.4,0.4,0.5,0.7
              c0.1,0.3,0.2,0.5,0.2,0.8s-0.1,0.6-0.2,0.9s-0.3,0.5-0.6,0.7c-0.2,0.2-0.5,0.3-0.9,0.4c-0.3,0.1-0.7,0.2-1,0.2h-0.7v3h-1.6
              L1242.2,367.8L1242.2,367.8z M1244.2,363.8c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.4-0.2s0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.5
              s0-0.3-0.1-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.1h-0.5v2.2H1244.2z"/>
            <path className="st18" d="M1209.6,373.4h4.8c0.8,0,1.5,0.1,2.2,0.3c0.6,0.2,1.1,0.5,1.5,0.8s0.7,0.7,0.9,1.1c0.2,0.4,0.3,0.8,0.3,1.3
              s-0.1,0.9-0.2,1.2c-0.2,0.3-0.3,0.6-0.6,0.8c-0.2,0.2-0.5,0.4-0.8,0.6s-0.5,0.3-0.8,0.4c0.4,0,0.8,0.1,1.3,0.3
              c0.4,0.2,0.8,0.4,1.1,0.8c0.3,0.3,0.6,0.7,0.8,1.1c0.2,0.4,0.3,0.9,0.3,1.5s-0.1,1.1-0.4,1.6s-0.6,1-1.1,1.4s-1,0.7-1.6,1
              c-0.6,0.2-1.3,0.4-2,0.4h-5.8L1209.6,373.4L1209.6,373.4z M1213.8,379.5c0.3,0,0.7,0,1-0.1s0.6-0.2,0.8-0.4
              c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.3,0.2-0.6,0.2-1s-0.1-0.7-0.2-0.9c-0.1-0.3-0.3-0.5-0.5-0.6c-0.2-0.2-0.5-0.3-0.8-0.3
              c-0.3-0.1-0.6-0.1-1-0.1h-1.1v4.1h1.1V379.5z M1216.6,385.3c0.5-0.3,0.8-0.9,0.8-1.6s-0.3-1.3-0.8-1.6c-0.5-0.4-1.2-0.5-1.9-0.5
              h-2v4.3h2C1215.5,385.8,1216.1,385.7,1216.6,385.3z"/>
            <path className="st18" d="M1232.6,373.1c1.1,0,2.2,0.2,3.1,0.6s1.7,0.9,2.4,1.6c0.7,0.7,1.2,1.5,1.6,2.4s0.6,1.9,0.6,3
              s-0.2,2.2-0.6,3.1s-1,1.7-1.7,2.4c-0.7,0.7-1.5,1.2-2.4,1.5c-0.9,0.4-1.9,0.5-2.9,0.5c-1.1,0-2-0.2-3-0.5
              c-0.9-0.3-1.7-0.8-2.4-1.5c-0.7-0.7-1.2-1.4-1.6-2.4c-0.4-0.9-0.6-2-0.6-3.1s0.2-2.1,0.6-3s0.9-1.7,1.6-2.4
              c0.7-0.7,1.5-1.2,2.4-1.6C1230.5,373.3,1231.5,373.1,1232.6,373.1z M1232.6,375.3c-0.7,0-1.3,0.1-1.8,0.4c-0.6,0.3-1,0.6-1.4,1.1
              s-0.7,1-0.9,1.7c-0.2,0.6-0.3,1.3-0.3,2.1c0,0.7,0.1,1.4,0.3,2c0.2,0.6,0.5,1.2,0.9,1.7s0.8,0.9,1.4,1.1c0.5,0.3,1.2,0.4,1.8,0.4
              c0.7,0,1.3-0.1,1.8-0.4s1-0.7,1.4-1.1c0.4-0.5,0.7-1,0.9-1.7c0.2-0.6,0.3-1.3,0.3-2s-0.1-1.4-0.3-2c-0.2-0.6-0.5-1.2-0.9-1.7
              s-0.8-0.9-1.4-1.1C1233.9,375.5,1233.3,375.3,1232.6,375.3z"/>
            <path className="st18" d="M1250.3,388h-1.9l-4.8-14.6h3l3.1,9.9l2.1-5.7l-1.3-4.2h3.1l3,9.6l3.3-9.6h2.7l-5.3,14.6h-1.9l-2.4-7.3
              L1250.3,388z"/>
            <path className="st18" d="M1267,387.8v-14.5h3.1v14.5H1267z"/>
            <path className="st18" d="M1285.3,387.8h-9.3v-14.5h9.1v2.3h-6v3.6h4.4v2.3h-4.4v4.1h6.3L1285.3,387.8L1285.3,387.8z"/>
            <path className="st18" d="M1290.1,382.5v-2.6h6.4v2.6H1290.1z"/>
            <path className="st18" d="M1316.7,388.7l-1,2.4l-9.1-3.3c-1.6-0.7-2.9-1.5-3.7-2.7c-0.9-1.1-1.3-2.6-1.3-4.4c0-1.1,0.2-2.1,0.6-3
              s0.9-1.7,1.6-2.4c0.7-0.7,1.5-1.2,2.4-1.6s1.9-0.6,3-0.6s2.2,0.2,3.1,0.6s1.7,0.9,2.4,1.6c0.7,0.7,1.2,1.5,1.6,2.4s0.6,1.9,0.6,3
              c0,0.9-0.1,1.7-0.4,2.4c-0.3,0.7-0.6,1.4-1,2s-0.9,1.1-1.5,1.5s-1.2,0.7-1.8,0.9L1316.7,388.7z M1309.3,375.3
              c-0.7,0-1.3,0.1-1.8,0.4s-1,0.7-1.4,1.1c-0.4,0.5-0.7,1-0.9,1.7c-0.2,0.6-0.3,1.3-0.3,2s0.1,1.4,0.3,2c0.2,0.6,0.5,1.2,0.9,1.7
              s0.8,0.9,1.4,1.1c0.5,0.3,1.2,0.4,1.8,0.4c0.7,0,1.3-0.1,1.8-0.4s1-0.7,1.4-1.1c0.4-0.5,0.7-1,0.9-1.7c0.2-0.6,0.3-1.3,0.3-2
              s-0.1-1.4-0.3-2c-0.2-0.6-0.5-1.2-0.9-1.7s-0.8-0.9-1.4-1.1C1310.6,375.5,1310,375.3,1309.3,375.3z"/>
          </g>
        </g>
        <g id="CVsIrR_1_">
          <g>
            <path className="st30" d="M1178.5,376.7c-0.1,2.6-1.1,4.8-2.8,6.7c0.1-1.3,0.6-2.5,0.2-3.9c-0.8,1.2-1.5,2.5-1.6,3.9
              c-0.1,1.5,0.6,2.8,1.1,4.1c-0.3-0.2-0.7-0.4-1-0.7c-1.3-0.8-2.4-1.7-3.4-2.9c-1.5-1.8-1.9-3.8-1.6-6c0.2-1.5,0.8-2.9,1.4-4.3
              c0.6-1.3,0.9-2.7,0.9-4.1c0-0.3,0-0.6,0.1-0.9c0.6,1.3,1.3,2.5,1.5,4c0.8-1.4,1.5-2.9,1.9-4.4c0.4-1.6,0.7-3.1,0.8-4.8
              c0.4,0.2,0.7,0.3,1.1,0.5c1.4,0.8,2.4,2,3.2,3.3c0.6,1,1,2,1.4,3.1c0,0.1,0.1,0.2,0.2,0.4c0.3-1.5-0.5-2.8-0.9-4.1
              c0.3,0.3,0.7,0.6,1,0.9c1.5,1.3,2.5,3,3.3,4.8c0.7,1.6,1.2,3.3,1.2,5.1c0,2.7-0.9,4.9-2.6,6.9c-1.1,1.2-2.3,2.1-3.6,3
              c-0.2,0.1-0.3,0.2-0.5,0.3c0.2-0.4,0.3-0.8,0.5-1.1c0.5-1,0.8-2.1,1-3.2c0.2-2.1-0.4-4-1.7-5.7
              C1179.1,377.3,1178.8,377,1178.5,376.7L1178.5,376.7z M1176.7,364.8c-0.5,4-2,7.6-4.4,10.8c-0.1-1-0.1-2-0.2-3h-0.1
              c-0.5,1.3-1,2.6-1.5,3.9c-0.4,1.2-0.5,2.4-0.4,3.6c0.2,1.7,1.1,2.9,2.3,4c0.2,0.2,0.5,0.4,0.7,0.6c0-0.4-0.1-0.7-0.1-1
              c0-1.5,0.5-2.9,1.3-4.2c0.5-0.8,1-1.6,1.2-2.5c0,0,0-0.1,0.1-0.1c0.5,0.7,1,1.5,1.2,2.4c0.3-0.9,0.5-1.7,0.6-2.6
              s-0.1-1.8-0.3-2.7c0.1,0,0.1,0.1,0.1,0.1c1.1,0.9,2.2,1.9,3.1,3.1c1.2,1.7,1.8,3.6,1.6,5.6c0,0.6-0.2,1.2-0.2,1.8
              c0.3-0.2,0.5-0.4,0.7-0.7c2.3-2.3,3.2-5,2.7-8.2c-0.3-1.9-1-3.6-2-5.2c-0.1-0.2-0.3-0.5-0.5-0.7c0,0,0,0-0.1,0
              c0,1.3-0.4,2.3-1.5,3.1c-0.2-0.7-0.3-1.3-0.5-2c-0.5-1.8-1.3-3.5-2.5-5C1177.8,365.7,1177.3,365.2,1176.7,364.8z"/>
          </g>
        </g>
      </g>
      <g id="cultural_district_creations">
        
          <rect x="1555.6" y="368.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 189.6827 1220.6055)" className="st4" width="25.3" height="25.3"/>
        
          <rect x="1552.5" y="366.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 189.6228 1219.2841)" className="st31" width="28.3" height="28.3"/>
        
          <rect x="1555.6" y="366.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 190.5308 1221.4867)" className="st31" width="28.3" height="28.3"/>
        <g>
          <path className="st18" d="M1607.1,370c-0.5,0.3-1.1,0.5-1.8,0.7c-0.7,0.2-1.7,0.3-3,0.3c-1,0-1.9-0.2-2.8-0.5s-1.6-0.8-2.2-1.4
            s-1.1-1.3-1.5-2.2c-0.4-0.8-0.5-1.8-0.5-2.8s0.2-2,0.5-2.8c0.4-0.9,0.9-1.6,1.5-2.2s1.4-1.1,2.2-1.4c0.9-0.3,1.8-0.5,2.8-0.5
            c0.7,0,1.3,0,1.8,0.1s1,0.2,1.3,0.3c0.4,0.1,0.7,0.2,0.9,0.3c0.2,0.1,0.4,0.2,0.6,0.3l-0.8,1.9c-0.1-0.1-0.3-0.1-0.5-0.2
            s-0.4-0.2-0.7-0.3c-0.3-0.1-0.6-0.2-1-0.2c-0.4-0.1-0.8-0.1-1.4-0.1c-0.5,0-1.1,0.1-1.6,0.4c-0.5,0.2-1,0.6-1.4,1s-0.7,0.9-1,1.5
            s-0.4,1.2-0.4,2c0,0.7,0.1,1.3,0.4,1.9s0.6,1.1,1,1.5s0.9,0.8,1.4,1s1,0.4,1.6,0.4c1,0,1.7-0.1,2.3-0.3c0.5-0.2,1-0.3,1.3-0.5
            L1607.1,370z"/>
          <path className="st18" d="M1623.1,366.1c0,0.7-0.1,1.3-0.4,1.9c-0.2,0.6-0.6,1.1-1.1,1.5s-1.1,0.8-1.8,1c-0.7,0.2-1.5,0.4-2.4,0.4
            s-1.7-0.1-2.4-0.4c-0.7-0.2-1.3-0.6-1.8-1s-0.8-0.9-1.1-1.5c-0.2-0.6-0.4-1.3-0.4-2v-8.8h2.8v8.4c0,1,0.2,1.8,0.7,2.3
            s1.2,0.8,2.3,0.8c0.5,0,1-0.1,1.3-0.3c0.4-0.2,0.7-0.4,0.9-0.7c0.2-0.3,0.4-0.6,0.5-1s0.2-0.7,0.2-1.1v-8.4h2.6v8.9L1623.1,366.1
            L1623.1,366.1z"/>
          <path className="st18" d="M1631.3,368.6h5v2.1h-7.8v-13.4h2.8V368.6z"/>
          <path className="st18" d="M1636.6,357.3h11.4v2.1h-4.3v11.3h-2.8v-11.3h-4.3L1636.6,357.3L1636.6,357.3z"/>
          <path className="st18" d="M1663.6,366.1c0,0.7-0.1,1.3-0.4,1.9c-0.2,0.6-0.6,1.1-1.1,1.5s-1.1,0.8-1.8,1c-0.7,0.2-1.5,0.4-2.4,0.4
            s-1.7-0.1-2.4-0.4c-0.7-0.2-1.3-0.6-1.8-1s-0.8-0.9-1.1-1.5c-0.2-0.6-0.4-1.3-0.4-2v-8.8h2.8v8.4c0,1,0.2,1.8,0.7,2.3
            s1.2,0.8,2.3,0.8c0.5,0,1-0.1,1.3-0.3c0.4-0.2,0.7-0.4,0.9-0.7c0.2-0.3,0.4-0.6,0.5-1s0.2-0.7,0.2-1.1v-8.4h2.6v8.9L1663.6,366.1
            L1663.6,366.1z"/>
          <path className="st18" d="M1676.7,370.9l-3.6-6h-1.3v5.8h-2.8v-13.4h3.9c0.9,0,1.7,0.1,2.4,0.3c0.7,0.2,1.2,0.4,1.7,0.8
            c0.4,0.3,0.8,0.7,1,1.2c0.2,0.4,0.3,0.9,0.3,1.5c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.5,0.5-0.8,0.7
            s-0.5,0.3-0.7,0.4l4.1,6.4L1676.7,370.9z M1673,363c0.8,0,1.4-0.2,1.8-0.6s0.6-0.8,0.6-1.3s-0.2-1-0.6-1.3c-0.4-0.4-1-0.6-1.9-0.6
            h-1.2v3.8H1673z"/>
          <path className="st18" d="M1685,370.7h-2.5l5.5-13.4h1.9l4.9,13.4h-2.9l-1.1-3h-4.6L1685,370.7z M1688.5,361.4l-1.7,4.4h3.3
            L1688.5,361.4z"/>
          <path className="st18" d="M1702,368.6h5v2.1h-7.8v-13.4h2.8V368.6z"/>
          <path className="st18" d="M1595.8,375.8h4.3c1,0,1.9,0.1,2.9,0.4c0.9,0.3,1.7,0.7,2.4,1.3c0.7,0.6,1.3,1.2,1.7,2.1
            c0.4,0.8,0.6,1.8,0.6,2.9c0,1-0.2,1.9-0.7,2.7c-0.4,0.8-1,1.5-1.7,2.1s-1.5,1.1-2.4,1.4s-1.8,0.5-2.8,0.5h-4.3V375.8z
            M1600.1,387.1c0.5,0,1-0.1,1.6-0.3c0.5-0.2,1-0.5,1.5-0.9s0.8-0.9,1.1-1.5c0.3-0.6,0.4-1.2,0.4-1.9c0-0.8-0.1-1.5-0.4-2.1
            s-0.6-1-1.1-1.4c-0.4-0.4-0.9-0.6-1.5-0.8c-0.5-0.2-1.1-0.3-1.6-0.3h-1.5v9.2L1600.1,387.1L1600.1,387.1z"/>
          <path className="st18" d="M1612.7,389.2v-13.4h2.8v13.4H1612.7z"/>
          <path className="st18" d="M1621.8,386.8c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0.1,0.6,0.1,0.9,0.2c0.4,0.1,0.8,0.1,1.2,0.1
            c0.3,0,0.5,0,0.8-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.3-0.3,0.5-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.5-0.2-0.7
            s-0.3-0.4-0.6-0.6c-0.2-0.2-0.5-0.4-0.9-0.6c-0.3-0.2-0.7-0.4-1-0.6s-0.7-0.4-1-0.7c-0.4-0.2-0.7-0.5-1-0.8s-0.6-0.7-0.8-1.1
            c-0.2-0.4-0.3-1-0.3-1.6c0-0.5,0.1-1,0.3-1.4c0.2-0.4,0.5-0.8,0.9-1.1c0.4-0.3,0.8-0.6,1.3-0.8c0.5-0.2,1-0.3,1.6-0.3
            c0.5,0,1,0,1.4,0.1s0.8,0.1,1.1,0.2c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0.1,0.4,0.2,0.6,0.2l-0.8,1.9c-0.2-0.1-0.6-0.3-1-0.4
            s-0.9-0.2-1.4-0.2c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.4,0.1-0.6,0.2s-0.3,0.2-0.5,0.4c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.3,0.1,0.5,0.2,0.8
            c0.1,0.2,0.3,0.4,0.6,0.6c0.2,0.2,0.5,0.4,0.8,0.6s0.6,0.4,1,0.6c0.3,0.2,0.7,0.4,1,0.7c0.4,0.3,0.7,0.6,1.1,0.9
            c0.3,0.3,0.6,0.7,0.9,1.1s0.3,0.8,0.3,1.3c0,0.7-0.1,1.2-0.4,1.7s-0.7,0.9-1.1,1.3c-0.5,0.3-1,0.6-1.6,0.8
            c-0.6,0.2-1.2,0.3-1.9,0.3c-0.6,0-1.1,0-1.5-0.1s-0.8-0.2-1.2-0.3c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.4-0.2-0.5-0.3l0.8-1.9
            C1621.5,386.7,1621.6,386.7,1621.8,386.8z"/>
          <path className="st18" d="M1633.3,375.8h11.4v2.1h-4.3v11.3h-2.8v-11.3h-4.3L1633.3,375.8L1633.3,375.8z"/>
          <path className="st18" d="M1656.8,389.4l-3.6-6h-1.3v5.8h-2.8v-13.4h3.9c0.9,0,1.7,0.1,2.4,0.3c0.7,0.2,1.2,0.4,1.7,0.8
            c0.4,0.3,0.8,0.7,1,1.2c0.2,0.4,0.3,0.9,0.3,1.5c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.5,0.5-0.8,0.7
            s-0.5,0.3-0.7,0.4l4.1,6.4L1656.8,389.4z M1653.1,381.5c0.8,0,1.4-0.2,1.8-0.6s0.6-0.8,0.6-1.3s-0.2-1-0.6-1.3
            c-0.4-0.4-1-0.6-1.9-0.6h-1.2v3.8H1653.1z"/>
          <path className="st18" d="M1664.1,389.2v-13.4h2.8v13.4H1664.1z"/>
          <path className="st18" d="M1683.8,388.5c-0.5,0.3-1.1,0.5-1.8,0.7c-0.7,0.2-1.7,0.3-3,0.3c-1,0-1.9-0.2-2.8-0.5s-1.6-0.8-2.2-1.4
            s-1.1-1.3-1.5-2.2c-0.4-0.8-0.5-1.8-0.5-2.8s0.2-2,0.5-2.8c0.4-0.9,0.9-1.6,1.5-2.2s1.4-1.1,2.2-1.4c0.9-0.3,1.8-0.5,2.8-0.5
            c0.7,0,1.3,0,1.8,0.1s1,0.2,1.3,0.3c0.4,0.1,0.7,0.2,0.9,0.3c0.2,0.1,0.4,0.2,0.6,0.3l-0.8,1.9c-0.1-0.1-0.3-0.1-0.5-0.2
            s-0.4-0.2-0.7-0.3c-0.3-0.1-0.6-0.2-1-0.2c-0.4-0.1-0.8-0.1-1.4-0.1c-0.5,0-1.1,0.1-1.6,0.4c-0.5,0.2-1,0.6-1.4,1s-0.7,0.9-1,1.5
            s-0.4,1.2-0.4,2c0,0.7,0.1,1.3,0.4,1.9s0.6,1.1,1,1.5s0.9,0.8,1.4,1s1,0.4,1.6,0.4c1,0,1.7-0.1,2.3-0.3c0.5-0.2,1-0.3,1.3-0.5
            L1683.8,388.5z"/>
          <path className="st18" d="M1687.4,375.8h11.4v2.1h-4.3v11.3h-2.8v-11.3h-4.3L1687.4,375.8L1687.4,375.8z"/>
          <path className="st18" d="M1600.8,400.8c-0.2,0.1-0.5,0.2-0.9,0.3c-0.4,0.1-0.9,0.2-1.5,0.2c-0.5,0-1-0.1-1.4-0.3
            c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.3-0.6-0.7-0.7-1.1c-0.2-0.4-0.3-0.9-0.3-1.4s0.1-1,0.3-1.4c0.2-0.4,0.4-0.8,0.7-1.1
            c0.3-0.3,0.7-0.5,1.1-0.7s0.9-0.3,1.4-0.3c0.3,0,0.6,0,0.9,0.1c0.3,0,0.5,0.1,0.7,0.1s0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.1,0.3,0.1
            l-0.4,1c-0.1,0-0.1-0.1-0.2-0.1s-0.2-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.5-0.1s-0.4,0-0.7,0c-0.3,0-0.5,0.1-0.8,0.2s-0.5,0.3-0.7,0.5
            s-0.4,0.5-0.5,0.8s-0.2,0.6-0.2,1c0,0.3,0.1,0.7,0.2,1s0.3,0.5,0.5,0.8c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.5,0.2,0.8,0.2
            c0.5,0,0.9,0,1.1-0.1c0.3-0.1,0.5-0.2,0.7-0.2L1600.8,400.8z"/>
          <path className="st18" d="M1608.8,401.3l-1.8-3h-0.6v2.9h-1.4v-6.7h1.9c0.5,0,0.9,0,1.2,0.1c0.3,0.1,0.6,0.2,0.8,0.4
            c0.2,0.2,0.4,0.4,0.5,0.6s0.2,0.5,0.2,0.7s0,0.5-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.4,0.3
            c-0.1,0.1-0.2,0.1-0.3,0.2l2,3.2L1608.8,401.3z M1606.9,397.4c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.4,0.3-0.7
            c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.9-0.3h-0.6v1.9h0.6V397.4z"/>
          <path className="st18" d="M1618.6,401.2h-4.3v-6.7h4.2v1h-2.8v1.7h2v1h-2v1.9h2.9L1618.6,401.2L1618.6,401.2z"/>
          <path className="st18" d="M1623.4,401.2h-1.3l2.7-6.7h1l2.5,6.7h-1.5l-0.5-1.5h-2.3L1623.4,401.2z M1625.1,396.6l-0.8,2.2h1.6
            L1625.1,396.6z"/>
          <path className="st18" d="M1630.9,394.5h5.7v1h-2.1v5.6h-1.4v-5.6h-2.1L1630.9,394.5L1630.9,394.5z"/>
          <path className="st18" d="M1640.6,401.2v-6.7h1.4v6.7H1640.6z"/>
          <path className="st18" d="M1649.8,394.4c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.4,1.1,0.7c0.3,0.3,0.6,0.7,0.7,1.1
            c0.2,0.4,0.3,0.9,0.3,1.4s-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.8-0.8,1.1s-0.7,0.5-1.1,0.7s-0.9,0.2-1.3,0.2c-0.5,0-0.9-0.1-1.4-0.2
            c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.3-0.6-0.7-0.8-1.1c-0.2-0.4-0.3-0.9-0.3-1.4s0.1-1,0.3-1.4c0.2-0.4,0.4-0.8,0.8-1.1
            s0.7-0.6,1.1-0.7C1648.8,394.5,1649.3,394.4,1649.8,394.4z M1649.8,395.4c-0.3,0-0.6,0.1-0.9,0.2s-0.5,0.3-0.6,0.5
            c-0.2,0.2-0.3,0.5-0.4,0.8s-0.1,0.6-0.1,1c0,0.3,0,0.6,0.1,0.9s0.2,0.5,0.4,0.8c0.2,0.2,0.4,0.4,0.6,0.5c0.3,0.1,0.5,0.2,0.8,0.2
            s0.6-0.1,0.8-0.2c0.3-0.1,0.5-0.3,0.6-0.5c0.2-0.2,0.3-0.5,0.4-0.8s0.1-0.6,0.1-0.9s0-0.6-0.1-0.9s-0.2-0.6-0.4-0.8
            c-0.2-0.2-0.4-0.4-0.6-0.5C1650.4,395.5,1650.1,395.4,1649.8,395.4z"/>
          <path className="st18" d="M1663.6,394.5v6.7h-1.4l-3.5-4.4v4.4h-1.2v-6.7h1.3l3.6,4.5v-4.5H1663.6z"/>
          <path className="st18" d="M1668.5,400c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.5,0.1s0.4,0,0.6,0c0.1,0,0.3,0,0.4-0.1
            c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3s-0.3-0.2-0.4-0.3
            c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.3-0.3-0.4-0.6c-0.1-0.2-0.2-0.5-0.2-0.8
            s0.1-0.5,0.2-0.7s0.3-0.4,0.5-0.6s0.4-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.8-0.1s0.5,0,0.7,0s0.4,0.1,0.5,0.1c0.2,0,0.3,0.1,0.4,0.1
            s0.2,0.1,0.3,0.1l-0.4,1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.4-0.1-0.7-0.1c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0.1-0.2,0.2
            c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.4s0.2,0.2,0.3,0.3s0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3
            c0.2,0.1,0.4,0.3,0.5,0.4s0.3,0.4,0.4,0.6s0.2,0.4,0.2,0.7c0,0.3-0.1,0.6-0.2,0.9s-0.3,0.5-0.6,0.6c-0.2,0.2-0.5,0.3-0.8,0.4
            s-0.6,0.1-1,0.1c-0.3,0-0.5,0-0.8-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.3-0.1-0.4-0.2s-0.2-0.1-0.3-0.1l0.4-0.9
            C1668.4,399.9,1668.4,400,1668.5,400z"/>
        </g>
        <g id="VkHjnu_1_">
          <g>
            <path d="M1581.2,381.8c0,0.4-0.1,0.7-0.3,1c-0.1,0.2-0.3,0.3-0.4,0.4l-0.1,0.1c-0.3,0.7-1,0.9-1.6,1.2c-1.2,0.4-2.4,0.6-3.6,0.7
              c-4.6,0.4-9.1,0.3-13.6-0.2c-1-0.1-1.9-0.3-2.9-0.5c-0.5-0.1-1-0.4-1.5-0.6c-0.3-0.2-0.6-0.5-0.7-0.8c0-0.1-0.1-0.1-0.1-0.1
              c-0.4-0.1-0.7-0.4-0.9-0.8c0,0,0-0.1-0.1-0.1c0-0.2-0.1-0.4-0.1-0.5c0.1-0.6,0.3-1.2,0.8-1.6c0,0,0.1-0.1,0.1-0.2
              c0-1.1,0.5-2,1.3-2.8c1.8-1.9,3.9-2.6,6.5-1.9c0.1,0,0.3,0,0.4-0.1c0.7-0.3,1.4-0.6,2.2-0.8c1.5-0.4,3-0.3,4.3,0.5
              c0.1,0.1,0.2,0.1,0.3,0.1c1.2-0.1,2.5-0.1,3.7,0.1c2,0.4,3.4,1.5,4.4,3.2c0.4,0.6,0.6,1.3,0.9,2c0.1,0.1,0.2,0.2,0.3,0.3
              c0.4,0.2,0.6,0.5,0.9,0.9C1581.1,381.5,1581.1,381.6,1581.2,381.8z M1565.2,375.7c0.4,0.3,0.9,0.6,1.3,0.9
              c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0,0.5-0.1,0.6c-0.2,0.2-0.4,0.2-0.7,0c-0.4-0.3-0.9-0.6-1.3-0.9c-1.5-0.8-3-1-4.6-0.3
              c-1.3,0.5-2.2,1.4-2.8,2.7c-0.1,0.2-0.1,0.5-0.2,0.8c7.3,0.1,14.5,0.2,21.8,0.3c-0.6-1.4-1.2-2.6-2.5-3.4s-2.7-0.9-4.3-0.9
              c0.2,0.2,0.3,0.3,0.4,0.4c0.3,0.3,0.3,0.6,0.1,0.8c-0.2,0.2-0.5,0.2-0.8-0.1c-0.2-0.2-0.5-0.5-0.8-0.7c-1.5-1.3-3.1-1.5-4.9-0.8
              C1565.8,375.5,1565.5,375.6,1565.2,375.7z M1579,383.2c-1-0.3-1.9-0.4-2.8-0.1c-0.6,0.2-1.2,0.4-1.8,0.6c-0.7,0.2-1.4,0.2-2-0.2
              c-0.4-0.3-0.9-0.5-1.4-0.8s-1.1-0.3-1.7,0c-0.5,0.2-1.1,0.4-1.6,0.7c-0.9,0.4-1.7,0.3-2.5-0.2c-0.3-0.2-0.6-0.4-0.9-0.6
              c-0.4-0.2-0.7-0.2-1.1-0.1c-0.3,0.2-0.7,0.3-1,0.5c-0.6,0.3-1.2,0.3-1.7-0.1c-0.2-0.1-0.3-0.3-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.1
              c-0.7,0.1-1.4,0.3-2,0.4v0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.5,0.2,0.8,0.3c1.1,0.4,2.3,0.6,3.5,0.7c3.3,0.4,6.7,0.4,10.1,0.3
              c1.7,0,3.4-0.2,5.1-0.5C1577.7,383.7,1578.4,383.6,1579,383.2z M1561.5,382c0.4-0.2,0.7-0.3,1.1-0.5c0.9-0.4,1.7-0.4,2.5,0.2
              c0.3,0.2,0.6,0.4,0.8,0.6c0.4,0.3,0.9,0.3,1.4,0.1c0.5-0.2,0.9-0.4,1.4-0.6c1.1-0.5,2.1-0.5,3.2,0.1c0.4,0.2,0.8,0.4,1.1,0.6
              c0.3,0.2,0.6,0.2,0.9,0.1c0.6-0.2,1.1-0.3,1.7-0.5c1-0.3,2.1-0.4,3.1-0.2c0.3,0.1,0.6,0.1,0.9,0.2c0.2,0,0.4,0,0.5-0.2
              s0-0.4-0.2-0.5c-0.4-0.1-0.8-0.3-1.2-0.3c-2.1-0.1-4.2-0.1-6.2-0.1c-5.2-0.1-10.5-0.2-15.7-0.2c-0.4,0-0.7,0.3-0.6,0.7
              c0,0.3,0.2,0.4,0.4,0.3c0.2,0,0.3-0.1,0.5-0.1c0.7-0.2,1.4-0.3,2.1-0.5c0.5-0.1,0.9,0,1.3,0.3c0.2,0.1,0.4,0.3,0.6,0.4
              C1561.2,382,1561.3,382,1561.5,382z"/>
          </g>
        </g>
      </g>
      <g id="tarrant_tex_mex">
        
          <rect x="1928.6" y="365.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 300.2155 1485.4639)" className="st4" width="29.3" height="29.3"/>
        
          <rect x="1925.1" y="363.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 299.7176 1484.274)" className="st32" width="32.9" height="32.9"/>
        
          <rect x="1928.6" y="363.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 300.7427 1486.7257)" className="st32" width="32.9" height="32.9"/>
        <g>
          <path className="st18" d="M1974.1,365.5h13.2v2.4h-5V381h-3.3v-13.1h-5v-2.4H1974.1z"/>
          <path className="st18" d="M1990.9,381.1h-3l6.3-15.6h2.3l5.7,15.6h-3.4l-1.3-3.5h-5.4L1990.9,381.1z M1995,370.3l-2,5.1h3.8
            L1995,370.3z"/>
          <path className="st18" d="M2016.3,381.3l-4.2-6.9h-1.5v6.7h-3.3v-15.5h4.5c1.1,0,2,0.1,2.8,0.3c0.8,0.2,1.4,0.5,1.9,0.9
            s0.9,0.8,1.1,1.4c0.2,0.5,0.4,1.1,0.4,1.7s-0.1,1.1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1.1c-0.3,0.3-0.6,0.6-0.9,0.8
            c-0.3,0.2-0.6,0.3-0.8,0.4l4.7,7.5L2016.3,381.3z M2012,372.1c1,0,1.7-0.2,2.1-0.7c0.5-0.4,0.7-1,0.7-1.5c0-0.6-0.2-1.1-0.7-1.6
            c-0.5-0.4-1.2-0.7-2.2-0.7h-1.4v4.4h1.5V372.1z"/>
          <path className="st18" d="M2033.8,381.3l-4.2-6.9h-1.5v6.7h-3.3v-15.5h4.5c1.1,0,2,0.1,2.8,0.3c0.8,0.2,1.4,0.5,1.9,0.9
            s0.9,0.8,1.1,1.4c0.2,0.5,0.4,1.1,0.4,1.7s-0.1,1.1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1.1c-0.3,0.3-0.6,0.6-0.9,0.8
            c-0.3,0.2-0.6,0.3-0.8,0.4l4.7,7.5L2033.8,381.3z M2029.5,372.1c1,0,1.7-0.2,2.1-0.7c0.5-0.4,0.7-1,0.7-1.5c0-0.6-0.2-1.1-0.7-1.6
            c-0.5-0.4-1.2-0.7-2.2-0.7h-1.4v4.4h1.5V372.1z"/>
          <path className="st18" d="M2043.4,381.1h-3l6.3-15.6h2.3l5.7,15.6h-3.4l-1.3-3.5h-5.4L2043.4,381.1z M2047.5,370.3l-2,5.1h3.8
            L2047.5,370.3z"/>
          <path className="st18" d="M2073.9,365.5V381h-3.1l-8.1-10.3V381h-2.8v-15.5h2.9l8.3,10.6v-10.6H2073.9z"/>
          <path className="st18" d="M2078.9,365.5h13.2v2.4h-5V381h-3.3v-13.1h-5v-2.4H2078.9z"/>
          <path className="st18" d="M1973.9,387.3h6.6v1.2h-2.5v6.6h-1.6v-6.6h-2.5V387.3z"/>
          <path className="st18" d="M1990.2,395h-5v-7.8h4.9v1.2h-3.2v2h2.4v1.2h-2.4v2.2h3.4v1.2L1990.2,395L1990.2,395z"/>
          <path className="st18" d="M1995.9,395h-1.5l2.7-3.9l-2.4-3.8h1.8l1.6,2.5l1.6-2.5h1.5l-2.4,3.6l2.7,4.2h-1.8l-1.8-2.8L1995.9,395z"/>
          <path className="st18" d="M2020.7,395h-1.6l-0.1-4.7l-2,4.7h-1l-1.9-4.7l-0.2,4.7h-1.3l0.3-7.8h1.5l2.2,5.7l2.4-5.7h1.4L2020.7,395z"
            />
          <path className="st18" d="M2030.9,395h-5v-7.8h4.9v1.2h-3.2v2h2.4v1.2h-2.4v2.2h3.4v1.2L2030.9,395L2030.9,395z"/>
          <path className="st18" d="M2036.6,395h-1.5l2.7-3.9l-2.4-3.8h1.8l1.6,2.5l1.6-2.5h1.5l-2.4,3.6l2.7,4.2h-1.8l-1.8-2.8L2036.6,395z"/>
        </g>
        <g id="Xrr4fa_2_">
          <g>
            <path d="M1943.6,386.7c-2.8,0-5.6,0-8.4,0c-2.2,0-3.4-1.3-3.5-3.5c0-1.5,0-3,0.6-4.4c0.1-0.1,0-0.4-0.1-0.5
              c-1-2.2-0.3-4.3,1.9-5.4c0.2-0.1,0.3-0.3,0.4-0.5c0.8-1.4,2-2.2,3.6-2.2c0.3,0,0.7-0.1,1-0.3c1.4-0.8,3.1-0.8,4.4,0.2
              c0.3,0.2,0.5,0.2,0.9,0.2c1.6-0.3,3,0.1,3.9,1.5c0.6,0.9,1.3,1.4,2.2,1.8c2.6,1.5,4.3,3.7,4.8,6.7c0.2,1.1,0.2,2.3,0.1,3.5
              c-0.1,1.7-1.5,2.9-3.3,2.9C1949.3,386.7,1946.5,386.7,1943.6,386.7z M1949.4,385.2c-0.1-0.8-0.1-1.6-0.2-2.4c0-0.6,0-1.2-0.1-1.8
              c-0.5-3.7-3.3-6.5-7.1-6.9c-3.5-0.4-6.9,1.8-8.2,5.2c-0.5,1.4-0.5,2.8-0.5,4.2c0,1.1,0.7,1.7,1.8,1.7c2,0,4,0,6,0
              C1943.9,385.2,1946.7,385.2,1949.4,385.2z M1950.8,381.3c1.1-1,1-2.8-0.2-3.6c-0.4-0.3-0.6-0.6-0.6-1.1c0-1.2-0.6-2-1.7-2.4
              c-0.3-0.1-0.6-0.4-0.7-0.7c-0.6-1.5-1.8-2.1-3.3-1.5c-0.5,0.2-0.8,0.1-1.1-0.3c-1-1.1-2.3-1.2-3.5-0.2c-0.3,0.2-0.6,0.3-1,0.2
              c-1.2-0.3-2.3,0.3-2.8,1.5c-0.2,0.5-0.4,0.8-0.9,0.9c-1.1,0.4-1.7,1.3-1.5,2.3c2.7-3.4,6.1-4.8,10.3-3.7
              C1947.9,374,1950.2,377,1950.8,381.3z M1953,378.2c0,0.2,0,0.2,0,0.3c0.5,2-0.2,3.5-1.9,4.6c-0.1,0.1-0.3,0.3-0.3,0.5
              c0,0.9,0.7,1.6,1.6,1.6c0.8,0,1.6-0.7,1.6-1.5c0-0.7,0-1.5,0-2.2C1953.8,380.3,1953.6,379.3,1953,378.2z"/>
            <path d="M1934.9,384.3c-0.4,0-0.8-0.4-0.8-0.8s0.3-0.7,0.7-0.8c0.4,0,0.8,0.3,0.8,0.7C1935.7,384,1935.4,384.3,1934.9,384.3z"/>
            <path d="M1947.6,382.8c0.4,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.8C1946.8,383.1,1947.2,382.8,1947.6,382.8z"
              />
            <path d="M1938.1,378.8c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.7,0.7-0.8c0.5,0,0.8,0.3,0.8,0.7
              C1938.9,378.5,1938.6,378.8,1938.1,378.8z"/>
            <path d="M1936.5,380.4c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8s0.3-0.7,0.8-0.7C1936.2,379.6,1936.5,380,1936.5,380.4z"/>
            <path d="M1938.9,382.8c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8s0.3-0.7,0.7-0.8C1938.5,382,1938.9,382.3,1938.9,382.8z"/>
            <path d="M1947.6,380.5c0,0.4-0.4,0.7-0.9,0.7c-0.5-0.1-0.7-0.4-0.7-0.8s0.4-0.7,0.8-0.7C1947.3,379.7,1947.6,380,1947.6,380.5z"
              />
            <path d="M1940.5,381.2c-0.4,0-0.8-0.4-0.7-0.8c0-0.5,0.3-0.8,0.8-0.7c0.5,0,0.7,0.3,0.7,0.8
              C1941.2,380.9,1940.9,381.2,1940.5,381.2z"/>
            <path d="M1942,376.4c0,0.4-0.3,0.8-0.8,0.8s-0.7-0.3-0.7-0.7c0-0.4,0.3-0.8,0.7-0.8C1941.7,375.7,1942,376,1942,376.4z"/>
            <path d="M1943.6,383.5c-0.4,0-0.8-0.4-0.7-0.8c0-0.4,0.4-0.8,0.8-0.7c0.5,0,0.7,0.3,0.7,0.8
              C1944.4,383.2,1944.1,383.6,1943.6,383.5z"/>
            <path d="M1944.4,378.8c0,0.5-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.3-0.7-0.8s0.3-0.8,0.8-0.8C1944.1,378.1,1944.4,378.4,1944.4,378.8z"
              />
          </g>
        </g>
      </g>
      <g id="texiyaki">
        
          <rect x="977.9" y="1748.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -955.7375 1217.2722)" className="st4" width="27.2" height="27.2"/>
        
          <rect x="974.2" y="1746.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -956.1598 1216.0667)" className="st33" width="31.3" height="31.3"/>
        
          <rect x="977.6" y="1746.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -955.1639 1218.4734)" className="st33" width="31.3" height="31.3"/>
        <g>
          <path className="st18" d="M1019.6,1753h12.6v2.3h-4.7v12.5h-3.1v-12.5h-4.7v-2.3H1019.6z"/>
          <path className="st18" d="M1046.5,1767.8h-9.5V1753h9.2v2.3h-6.1v3.7h4.5v2.3h-4.5v4.1h6.4V1767.8z"/>
          <path className="st18" d="M1053.4,1767.8h-2.9l5.1-7.5l-4.7-7.3h3.4l3.1,4.7l3-4.7h2.9l-4.6,6.8l5.2,8h-3.5l-3.4-5.3L1053.4,1767.8z"
            />
          <path className="st18" d="M1068.8,1767.8V1753h3.1v14.8H1068.8z"/>
          <path className="st18" d="M1089.2,1753l-4.8,7.6v7.2h-3.1v-7.2l-4.7-7.6h3.4l3.1,5.4l3.2-5.4H1089.2z"/>
          <path className="st18" d="M1093.3,1767.8h-2.8l6-14.8h2.1l5.4,14.8h-3.2l-1.2-3.3h-5.1L1093.3,1767.8z M1097.2,1757.6l-1.9,4.9h3.6
            L1097.2,1757.6z"/>
          <path className="st18" d="M1118.1,1768l-6-6.8v6.6h-3.1V1753h3.1v6.5l5.7-6.5h3.3l-6.3,6.8l7.3,8L1118.1,1768z"/>
          <path className="st18" d="M1126.9,1767.8V1753h3.1v14.8H1126.9z"/>
        </g>
        <g id="PqbpJD_2_">
          <g>
            <g>
              <path d="M987.5,1774.9c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.2v-0.2c0-0.1,0-0.3,0-0.4c1-3.7,2-7.4,3-11.1c0-0.2,0-0.3,0-0.4
                c0,0-3.6-7.5-4.3-9.1c-0.4-0.8-0.3-1.5,0.1-2.2l0.3-0.3l0.2-0.2l0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.7-0.3,1.1-0.3
                c0.6,0,1.2,0.2,1.6,0.7c0.2,0.2,0.4,0.5,0.5,0.8c0.5,1.4,0.9,2.8,1.3,4.2l0.4,1.3l0.5-1.8c0.3-1.2,0.7-2.4,1-3.7
                c0.3-1,1.1-1.6,2.1-1.6c1.1,0,2.1,0.7,2.3,1.7c0.1,0.5,0.1,0.9-0.1,1.4l-3.5,8.1c-0.2,0.4-0.4,0.8-0.5,1.3c0,0.1-0.1,0.3,0,0.4
                l3.4,11.1c0,0.1,0,0.3,0,0.4s0,0.2,0,0.2l-0.2,0.2c-0.2,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.5-0.2-0.6-0.5l-3.9-8.1l-3.6,8.2
                C988,1774.7,987.8,1774.9,987.5,1774.9z M995.2,1751.1c-0.5,0-0.8,0.3-0.9,0.8c0,0.1,0,0.1,0,0.2l-3.9,14.1c0,0,1.5-3.5,2.1-4.8
                l1.3-3c0.8-1.9,1.7-3.9,2.5-5.8c0.2-0.6-0.1-1-0.4-1.2C995.6,1751.2,995.4,1751.1,995.2,1751.1z M992.5,1764.3
                c-0.1,0.1-0.1,0.3,0,0.5c0.2,0.4,0.4,0.8,0.6,1.2L992.5,1764.3L992.5,1764.3z M987.7,1751.2c-0.2,0-0.4,0.1-0.6,0.2
                c-0.5,0.3-0.7,0.7-0.5,1.2c0.8,1.8,1.7,3.5,2.5,5.3l1.3,2.8v-0.2c0.1-0.4,0.2-0.8,0.3-1.3c0,0,0-0.1,0-0.2
                c-0.8-2.4-1.5-4.8-2.3-7.2c0-0.1-0.1-0.2-0.1-0.3C988.3,1751.4,988.1,1751.2,987.7,1751.2z"/>
              <path className="st18" d="M995.2,1750L995.2,1750c1,0,2,0.7,2.2,1.6c0.1,0.5,0.1,0.9-0.1,1.3c-1.1,2.5-2.2,5-3.2,7.5
                c-0.3,0.6-0.5,1.2-0.8,1.8c-0.1,0.1-0.1,0.3,0,0.4c1.1,3.7,2.3,7.4,3.4,11.1c0.1,0.2,0,0.4,0,0.6c-0.1,0.1-0.1,0.1-0.2,0.2
                c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.5-0.4c-1.2-2.4-2.3-4.8-3.5-7.2c-0.2-0.4-0.3-0.7-0.5-1.1c-0.1,0.1-0.1,0.2-0.1,0.3
                c-1.2,2.7-2.4,5.4-3.5,8.1c-0.2,0.4-0.3,0.5-0.5,0.5c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.2,0-0.4,0-0.5
                c1-3.7,2-7.4,3-11.1c0.1-0.2,0-0.4,0-0.5c-1.4-3-2.9-6.1-4.3-9.1c-0.3-0.7-0.3-1.4,0.1-2c0,0,0,0,0-0.1c0.2-0.2,0.3-0.3,0.5-0.5
                c0.1-0.1,0.3-0.2,0.4-0.2c0.3-0.2,0.7-0.2,1.1-0.2c0.6,0,1.1,0.2,1.5,0.6c0.2,0.2,0.4,0.5,0.5,0.8c0.6,1.8,1.2,3.7,1.8,5.5
                c0,0.1,0.1,0.2,0.1,0.3c0.1-0.2,0.1-0.4,0.2-0.6c0.5-1.7,1-3.4,1.4-5.2C993.4,1750.5,994.3,1750,995.2,1750 M989.9,1767.4
                c0-0.1,0.1-0.1,0.1-0.2c0.8-1.9,1.7-3.9,2.5-5.8c1.3-2.9,2.5-5.9,3.8-8.8c0.3-0.6,0-1.1-0.4-1.4c-0.2-0.1-0.5-0.2-0.7-0.2
                c-0.5,0-0.9,0.2-1.1,0.9c0,0.1,0,0.1-0.1,0.2c-1.4,5.1-2.8,10.1-4.2,15.2C989.9,1767.3,989.9,1767.3,989.9,1767.4L989.9,1767.4
                M990.5,1761L990.5,1761c0.2-0.6,0.4-1.2,0.5-1.7c0-0.1,0-0.2,0-0.2c-0.8-2.4-1.5-4.8-2.3-7.2c0-0.1-0.1-0.2-0.1-0.3
                c-0.2-0.3-0.5-0.4-0.8-0.4c-0.2,0-0.5,0.1-0.7,0.2c-0.6,0.3-0.8,0.8-0.5,1.4c1,2.2,2.1,4.3,3.1,6.5
                C989.9,1759.8,990.2,1760.4,990.5,1761 M993.6,1767.3L993.6,1767.3c-0.3-1.1-0.7-2.3-1-3.4c-0.1,0.2-0.1,0.3-0.2,0.4
                c-0.1,0.2-0.1,0.4,0,0.6c0.4,0.7,0.7,1.5,1.1,2.3C993.5,1767.2,993.5,1767.3,993.6,1767.3 M995.2,1749.7c-1.1,0-2,0.7-2.2,1.7
                c-0.3,1.2-0.7,2.4-1,3.6l-0.4,1.4l-0.3-0.9c-0.4-1.4-0.9-2.8-1.3-4.1c-0.1-0.3-0.3-0.7-0.5-0.9c-0.4-0.4-1-0.7-1.7-0.7
                c-0.4,0-0.8,0.1-1.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.2l-0.1,0.1l0,0l0,0l-0.5,0.5v0.1v0.1l0,0l0,0c-0.4,0.7-0.5,1.5-0.1,2.2
                c0.8,1.6,1.6,3.3,2.3,4.9c0.7,1.4,1.3,2.8,2,4.2c0.1,0.1,0.1,0.2,0,0.4c-1,3.7-2,7.4-3,11.1c0,0.2,0,0.3,0,0.4s0,0.1,0,0.2v0.1
                l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1h0.1c0.1,0,0.2,0,0.2,0c0.5,0,0.6-0.3,0.8-0.6l2.4-5.5l1.1-2.5l0.3,0.6l1.5,3.1l2,4.1
                c0.1,0.3,0.3,0.6,0.7,0.6c0.1,0,0.2,0,0.4-0.1h0.1l0,0l0.2-0.2l0.1-0.1v-0.1c0-0.1,0-0.1,0-0.2s0-0.3-0.1-0.5
                c-0.5-1.8-1.1-3.5-1.7-5.3c-0.6-1.9-1.2-3.8-1.8-5.7c0-0.1,0-0.2,0-0.3c0.2-0.4,0.4-0.8,0.5-1.3l0.3-0.6l1.1-2.5
                c0.7-1.7,1.4-3.3,2.2-5c0.2-0.5,0.3-1,0.1-1.5C997.3,1750.5,996.3,1749.7,995.2,1749.7L995.2,1749.7L995.2,1749.7z
                M990.9,1764.5l3.4-12.3v-0.1c0-0.1,0-0.1,0-0.2c0.1-0.6,0.6-0.7,0.8-0.7s0.4,0.1,0.6,0.2c0.3,0.2,0.6,0.6,0.3,1.1
                c-0.8,1.9-1.7,3.8-2.5,5.8l-1.3,3.1C991.8,1762.3,991.4,1763.4,990.9,1764.5L990.9,1764.5z M990.5,1760.3L990.5,1760.3
                c-0.2-0.4-0.4-0.9-0.6-1.3l-0.6-1.2c-0.8-1.8-1.7-3.5-2.5-5.3c-0.2-0.4,0-0.8,0.4-1.1c0.2-0.1,0.4-0.1,0.6-0.1
                c0.3,0,0.5,0.1,0.6,0.3c0,0.1,0.1,0.1,0.1,0.2c0.8,2.4,1.5,4.8,2.3,7.2c0,0.1,0,0.1,0,0.1C990.7,1759.6,990.6,1760,990.5,1760.3
                L990.5,1760.3z"/>
            </g>
          </g>
        </g>
      </g>
      <g id="clearfork_cones">
        
          <rect x="1541.4" y="1747.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -790.1151 1615.4686)" className="st4" width="27.2" height="27.2"/>
        
          <rect x="1538.1" y="1746.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -790.6423 1614.1726)" className="st34" width="30.2" height="30.2"/>
        
          <rect x="1541.4" y="1746.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -789.6757 1616.5157)" className="st34" width="30.2" height="30.2"/>
        <g>
          <path className="st18" d="M1599.4,1760.1c-0.5,0.3-1.1,0.5-1.9,0.7s-1.8,0.3-3.2,0.3c-1.1,0-2-0.2-3-0.5c-0.9-0.4-1.7-0.9-2.4-1.5
            c-0.7-0.6-1.2-1.4-1.6-2.3s-0.6-1.9-0.6-3s0.2-2.1,0.6-3s0.9-1.7,1.6-2.4c0.7-0.7,1.5-1.2,2.4-1.5c0.9-0.4,1.9-0.5,3-0.5
            c0.7,0,1.4,0,1.9,0.1s1,0.2,1.4,0.3c0.4,0.1,0.7,0.2,1,0.3c0.3,0.1,0.5,0.2,0.6,0.3l-0.9,2.1c-0.1-0.1-0.3-0.1-0.5-0.2
            s-0.5-0.2-0.8-0.3c-0.3-0.1-0.7-0.2-1.1-0.2c-0.4-0.1-0.9-0.1-1.5-0.1s-1.1,0.1-1.7,0.4c-0.6,0.2-1.1,0.6-1.5,1s-0.8,1-1.1,1.6
            c-0.3,0.6-0.4,1.3-0.4,2.1c0,0.7,0.1,1.4,0.4,2s0.6,1.2,1.1,1.6c0.4,0.5,0.9,0.8,1.5,1.1c0.6,0.3,1.1,0.4,1.7,0.4
            c1,0,1.9-0.1,2.4-0.3c0.6-0.2,1.1-0.4,1.4-0.5L1599.4,1760.1z"/>
          <path className="st18" d="M1607.5,1758.6h5.3v2.2h-8.3v-14.3h3V1758.6z"/>
          <path className="st18" d="M1626.5,1760.8h-9.2v-14.3h8.9v2.2h-5.9v3.6h4.3v2.2h-4.3v4h6.2V1760.8z"/>
          <path className="st18" d="M1632.9,1760.8h-2.7l5.8-14.3h2.1l5.3,14.3h-3.1l-1.2-3.2h-4.9L1632.9,1760.8z M1636.7,1750.9l-1.8,4.7h3.5
            L1636.7,1750.9z"/>
          <path className="st18" d="M1656.2,1761l-3.8-6.3h-1.4v6.1h-3v-14.3h4.1c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.3,0.5,1.8,0.8s0.8,0.8,1,1.2
            c0.2,0.5,0.3,1,0.3,1.5s-0.1,1-0.3,1.4c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.6,0.5-0.8,0.7c-0.3,0.2-0.5,0.3-0.7,0.4l4.3,6.8
            L1656.2,1761z M1652.3,1752.7c0.9,0,1.5-0.2,2-0.6c0.4-0.4,0.6-0.9,0.6-1.4s-0.2-1-0.6-1.4s-1.1-0.6-2-0.6h-1.2v4.1L1652.3,1752.7
            L1652.3,1752.7z"/>
          <path className="st18" d="M1664.1,1760.8v-14.3h8.5v2.2h-5.5v3.6h3.8v2.2h-3.8v6.2h-3V1760.8z"/>
          <path className="st18" d="M1684,1746.3c1.1,0,2.1,0.2,3.1,0.5c0.9,0.4,1.7,0.9,2.4,1.5c0.7,0.7,1.2,1.4,1.5,2.4c0.4,0.9,0.5,1.9,0.5,3
            s-0.2,2.1-0.6,3.1c-0.4,0.9-1,1.7-1.7,2.3c-0.7,0.6-1.5,1.1-2.4,1.5c-0.9,0.3-1.9,0.5-2.9,0.5s-2-0.2-2.9-0.5s-1.7-0.8-2.4-1.5
            c-0.7-0.6-1.2-1.4-1.6-2.3s-0.6-1.9-0.6-3.1c0-1.1,0.2-2,0.6-2.9s0.9-1.7,1.6-2.4c0.7-0.7,1.5-1.2,2.4-1.6
            C1682,1746.5,1683,1746.3,1684,1746.3z M1684,1748.5c-0.7,0-1.3,0.1-1.8,0.4s-1,0.6-1.4,1.1s-0.7,1-0.9,1.6
            c-0.2,0.6-0.3,1.3-0.3,2.1c0,0.7,0.1,1.4,0.3,2c0.2,0.6,0.5,1.2,0.9,1.6c0.4,0.5,0.8,0.8,1.4,1.1c0.5,0.3,1.1,0.4,1.8,0.4
            c0.7,0,1.3-0.1,1.8-0.4s1-0.6,1.4-1.1s0.7-1,0.9-1.6c0.2-0.6,0.3-1.3,0.3-2s-0.1-1.4-0.3-2c-0.2-0.6-0.5-1.2-0.9-1.7
            s-0.8-0.9-1.4-1.1C1685.3,1748.7,1684.7,1748.5,1684,1748.5z"/>
          <path className="st18" d="M1705.1,1761l-3.8-6.3h-1.4v6.1h-3v-14.3h4.1c1,0,1.8,0.1,2.5,0.3c0.7,0.2,1.3,0.5,1.8,0.8s0.8,0.8,1,1.2
            c0.2,0.5,0.3,1,0.3,1.5s-0.1,1-0.3,1.4c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.6,0.5-0.8,0.7c-0.3,0.2-0.5,0.3-0.7,0.4l4.3,6.8
            L1705.1,1761z M1701.2,1752.7c0.9,0,1.5-0.2,2-0.6c0.4-0.4,0.6-0.9,0.6-1.4s-0.2-1-0.6-1.4s-1.1-0.6-2-0.6h-1.2v4.1L1701.2,1752.7
            L1701.2,1752.7z"/>
          <path className="st18" d="M1721.8,1761l-5.8-6.6v6.3h-3v-14.3h3v6.3l5.5-6.3h3.1l-6,6.6l7,7.7L1721.8,1761z"/>
          <path className="st18" d="M1592.6,1773.3c-0.2,0.1-0.6,0.3-1,0.4s-0.9,0.2-1.6,0.2c-0.5,0-1-0.1-1.5-0.3c-0.5-0.2-0.9-0.4-1.2-0.8
            c-0.3-0.3-0.6-0.7-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.5s0.1-1,0.3-1.5c0.2-0.5,0.5-0.8,0.8-1.2c0.3-0.3,0.7-0.6,1.2-0.8
            c0.5-0.2,1-0.3,1.5-0.3c0.4,0,0.7,0,1,0.1s0.5,0.1,0.7,0.1c0.2,0.1,0.4,0.1,0.5,0.2s0.2,0.1,0.3,0.2l-0.4,1
            c-0.1,0-0.1-0.1-0.2-0.1s-0.2-0.1-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1s-0.4,0-0.7,0c-0.3,0-0.6,0.1-0.8,0.2c-0.3,0.1-0.5,0.3-0.7,0.5
            s-0.4,0.5-0.5,0.8s-0.2,0.7-0.2,1c0,0.4,0.1,0.7,0.2,1s0.3,0.6,0.5,0.8c0.2,0.2,0.5,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.8,0.2
            c0.5,0,0.9,0,1.2-0.1c0.3-0.1,0.5-0.2,0.7-0.3L1592.6,1773.3z"/>
          <path className="st18" d="M1600.6,1766.4c0.6,0,1.1,0.1,1.5,0.3c0.5,0.2,0.9,0.4,1.2,0.8c0.3,0.3,0.6,0.7,0.8,1.2
            c0.2,0.5,0.3,1,0.3,1.5c0,0.6-0.1,1.1-0.3,1.5c-0.2,0.5-0.5,0.8-0.8,1.2s-0.7,0.6-1.2,0.7c-0.5,0.2-0.9,0.3-1.4,0.3
            s-1-0.1-1.5-0.3c-0.5-0.2-0.9-0.4-1.2-0.7c-0.3-0.3-0.6-0.7-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.5s0.1-1,0.3-1.5s0.5-0.8,0.8-1.2
            c0.3-0.3,0.7-0.6,1.2-0.8C1599.6,1766.5,1600.1,1766.4,1600.6,1766.4z M1600.6,1767.5c-0.3,0-0.6,0.1-0.9,0.2s-0.5,0.3-0.7,0.5
            s-0.3,0.5-0.4,0.8s-0.1,0.7-0.1,1c0,0.4,0,0.7,0.1,1s0.2,0.6,0.4,0.8c0.2,0.2,0.4,0.4,0.7,0.6c0.3,0.1,0.6,0.2,0.9,0.2
            s0.6-0.1,0.9-0.2s0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.8s0.1-0.6,0.1-1c0-0.3-0.1-0.7-0.1-1c-0.1-0.3-0.2-0.6-0.4-0.8
            c-0.2-0.2-0.4-0.4-0.7-0.6C1601.2,1767.6,1600.9,1767.5,1600.6,1767.5z"/>
          <path className="st18" d="M1615.3,1766.5v7.1h-1.4l-3.7-4.7v4.7h-1.3v-7.1h1.3l3.8,4.9v-4.9H1615.3z"/>
          <path className="st18" d="M1624.7,1773.6h-4.6v-7.1h4.5v1.1h-2.9v1.8h2.2v1.1h-2.2v2h3.1L1624.7,1773.6L1624.7,1773.6z"/>
          <path className="st18" d="M1629.6,1772.4c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.5,0.1s0.4,0,0.6,0c0.1,0,0.3,0,0.4-0.1
            c0.1,0,0.2-0.1,0.4-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3
            c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.2-0.3-0.4-0.4-0.6
            s-0.2-0.5-0.2-0.8s0.1-0.5,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6s0.4-0.3,0.7-0.4c0.3-0.1,0.6-0.1,0.9-0.1s0.5,0,0.8,0
            c0.2,0,0.4,0.1,0.6,0.1s0.3,0.1,0.4,0.1s0.2,0.1,0.3,0.1l-0.4,1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.5-0.1-0.7-0.1c-0.1,0-0.3,0-0.4,0
            s-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.4s0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3
            c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.5s0.3,0.4,0.5,0.6c0.1,0.2,0.2,0.4,0.2,0.7
            c0,0.3-0.1,0.7-0.2,0.9c-0.1,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.5,0.3-0.9,0.4c-0.3,0.1-0.7,0.1-1,0.1s-0.6,0-0.8-0.1
            c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.3-0.1-0.4-0.2s-0.2-0.1-0.3-0.1l0.4-1C1629.4,1772.3,1629.5,1772.3,1629.6,1772.4z"/>
        </g>
        <path className="st35" d="M1562.9,1755c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.1-0.1c0-0.4-0.1-0.8-0.3-1.2c-0.2-0.4-0.4-0.8-0.7-1
          c-0.5-0.4-1-0.6-1.7-0.5h-0.1c-0.2-0.2-0.4-0.4-0.7-0.6c-0.5-0.4-1.1-0.8-1.6-1.3c-0.6-0.5-1.2-0.9-1.8-1.3c-0.1-0.1-0.2-0.1-0.4,0
          c-0.5,0.4-1,0.8-1.4,1.3c-0.3,0.4-0.6,0.8-0.9,1.2c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.4,0c-0.8,0.1-1.4,0.4-2,0.9
          c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.1,0.5-0.2,0.8
          s-0.2,0.5-0.5,0.6c-0.2,0.1-0.4,0.1-0.6,0.2c-0.6,0.2-0.9,0.6-1.1,1.1c-0.2,0.6-0.2,1.1,0,1.7v0.1l0,0c0,0,0,0-0.1,0
          c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,0.6,0.3,1.3,0.5,1.9c0.3,1.1,0.6,2.3,1,3.4c0.3,1,0.7,2,1.1,3
          c0.2,0.6,0.5,1.3,0.8,1.9c0.4,0.9,0.8,1.8,1.3,2.7c0.6,1.2,1.3,2.4,1.9,3.6c0.1,0.2,0.3,0.2,0.4,0c0.3-0.5,0.6-0.9,0.8-1.4
          c0.4-0.8,0.9-1.6,1.3-2.4s0.9-1.6,1.3-2.4c0.5-0.9,0.9-1.8,1.4-2.7c0.3-0.6,0.6-1.3,0.9-1.9c0.2-0.4,0.3-0.8,0.5-1.1
          c0.2-0.4,0.4-0.9,0.6-1.3c0,0,0.1-0.3,0.2-0.4c0.2-0.4,0.3-0.8,0.5-1.1c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.1,0.1-0.2,0.1-0.3
          c0.1-0.4,0.3-0.9,0.5-1.3c0.2-0.5,0.4-1.1,0.6-1.7C1563.1,1755.8,1563.1,1755.4,1562.9,1755z M1553.9,1751c0.5-0.7,1-1.3,1.7-1.8
          c0.1,0,0.1,0,0.1,0c0.8,0.6,1.7,1.2,2.5,1.9c0.4,0.3,0.8,0.7,1.3,1c0,0,0,0,0.1,0.1c-0.2,0.2-0.5,0.3-0.7,0.5
          c-0.3,0.2-0.6,0.1-0.8-0.2c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.3-0.3-0.5-0.6-0.7c-0.3-0.2-0.6-0.1-0.9,0.1c-0.2,0.1-0.3,0.3-0.5,0.4
          c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.3,0.1-0.5,0c-0.3-0.1-0.7-0.3-1-0.4c-0.1,0-0.1,0-0.2-0.1
          C1553.7,1751.3,1553.8,1751.1,1553.9,1751z M1548.1,1756.5c0.2-0.2,0.4-0.3,0.6-0.4s0.4-0.1,0.6-0.2c0.3-0.1,0.5-0.3,0.6-0.6
          c0.1-0.3,0.2-0.5,0.2-0.8c0.1-0.7,0.4-1.3,0.8-1.8c0.5-0.5,1.1-0.8,1.8-0.9c0.5-0.1,0.9,0.1,1.3,0.2c0.2,0.1,0.5,0.2,0.7,0.3
          c0.3,0.1,0.7,0.1,0.9-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.3-0.2,0.5-0.2,0.8,0.1c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.2,0.4,0.4,0.5
          c0.3,0.4,0.8,0.5,1.3,0.2c0.2-0.1,0.4-0.3,0.6-0.4c0.5-0.4,1-0.3,1.5-0.1c0.5,0.3,0.7,0.7,0.9,1.1c0.2,0.4,0.2,0.9,0.3,1.3v0.1
          c-0.6,0.6-1.1,1.1-1.8,1.6c-0.4,0.3-0.8,0.6-1.2,0.9c0,0,0,0-0.1,0l0,0c0-0.1,0.1-0.3,0.1-0.4c0.3-0.8,0.6-1.6,1-2.3
          c0.2-0.4,0.5-0.8,0.8-1.2c0.1-0.2,0.1-0.3,0-0.4c-0.3-0.3-0.7-0.3-1.1-0.3c-0.5,0.1-0.9,0.4-1.2,0.8c-0.1,0.2-0.2,0.3-0.3,0.5
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5-0.4-1.1-0.3-1.6,0.1c-0.4,0.3-0.6,0.7-0.8,1.1l0,0l0,0c0,0-0.1-0.1-0.1-0.2
          c-0.4-0.8-1.1-1.4-1.8-1.9c-0.1-0.1-0.2,0-0.3,0c-0.3,0.3-0.6,0.6-0.9,0.8h-0.1c-0.1,0.1-0.2,0.2-0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.2
          h-0.4c0-0.1,0-0.2,0-0.3v-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.6-0.5,1.2-0.5,1.9v0.1
          c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.1-0.8-0.1-1.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.2-0.1,0.3-0.2,0.5
          s0,0.3,0.2,0.3c0.5,0,0.9,0,1.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2v0.1c-0.2,0.3-0.2,0.7-0.1,1s0.2,0.6,0.2,0.9v0.1c-0.1,0-0.1,0-0.1,0
          c-0.7-0.3-1.3-0.7-1.9-1.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.4-0.7-0.5-1.1C1547.6,1757.4,1547.7,1756.9,1548.1,1756.5z
          M1557.6,1758.7c0,0.1,0,0.1-0.1,0.2c-0.4,0.2-0.7,0.4-1.1,0.7h-0.1c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.5-0.2-1-0.1-1.5
          c0-0.7,0.2-1.4,0.6-2.1c0.1-0.3,0.3-0.5,0.6-0.7c0.4-0.3,0.8-0.2,1.1,0.2c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0.1,0.1,0.1,0.2
          c0.1,0.3,0,0.5-0.2,0.8c-0.2,0.4-0.4,0.7-0.6,1.1C1557.5,1757.7,1557.5,1758.2,1557.6,1758.7z M1558,1758.1c0-0.5,0.2-0.8,0.4-1.2
          c0.1-0.3,0.3-0.5,0.4-0.8s0.1-0.5,0.1-0.8c0-0.1,0-0.2,0-0.4c0.2-0.3,0.3-0.7,0.6-0.9c0.3-0.2,0.6-0.4,0.9-0.3c0.1,0,0.2,0,0.3,0.1
          c-0.1,0.1-0.1,0.1-0.1,0.2c-0.7,0.9-1.2,1.8-1.5,2.9c-0.2,0.4-0.3,0.9-0.4,1.3c0,0,0,0.1-0.1,0.1c-0.2,0.1-0.4,0.3-0.6,0.4
          c0,0,0,0-0.1,0C1558,1758.4,1557.9,1758.2,1558,1758.1z M1555.6,1758.6L1555.6,1758.6c-0.1-0.3-0.2-0.6-0.3-0.9
          c-0.3-0.8-0.7-1.5-1.3-2.1c-0.1-0.1-0.1-0.1-0.2-0.2s-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0,0,0-0.1c0,0,0,0,0.1-0.1
          c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1,0.1-0.1,0.2,0c0.8,0.5,1.3,1.2,1.7,2c0,0.1,0,0.2,0,0.2c-0.1,0.4-0.2,0.8-0.2,1.2
          C1555.5,1757.9,1555.6,1758.2,1555.6,1758.6z M1553.7,1758.5c-0.2-0.3-0.6-0.6-0.9-0.8c-0.3-0.2-0.5-0.3-0.7-0.5
          c-0.3-0.3-0.4-0.6-0.3-1.1s0.6-1,1.3-0.7c0.4,0.2,0.6,0.4,0.9,0.7c0.5,0.6,0.9,1.3,1.1,2c0.2,0.5,0.3,0.9,0.3,1.4
          c0,0.1,0,0.3,0,0.4s0,0.1-0.1,0.2c-0.3,0.1-0.5,0.2-0.8,0.4h-0.1v-0.1C1554.4,1759.8,1554.1,1759.1,1553.7,1758.5z M1552.3,1759.7
          c-0.1-0.4-0.2-0.8-0.5-1.1c-0.1-0.1-0.2-0.3-0.4-0.4l-0.1-0.1c-0.2-0.3-0.3-0.7-0.3-1.1c-0.1-0.4,0-0.8,0-1.2
          c0.1-0.3,0.2-0.7,0.3-1l0.1-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c0,0,0,0.1-0.1,0.1c-0.3,0.3-0.5,0.6-0.5,1
          c0,0.7,0.3,1.2,0.8,1.5c0.3,0.2,0.7,0.4,1,0.7c0.4,0.4,0.7,0.8,0.9,1.4c0.1,0.3,0.1,0.6,0,0.8c0,0,0,0.1-0.1,0.1
          c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.3,0-0.4,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1,0-0.1-0.1
          C1552.4,1760.5,1552.4,1760.1,1552.3,1759.7z M1550.7,1758.9C1550.7,1758.9,1550.7,1758.8,1550.7,1758.9c0.2-0.3,0.5-0.4,0.7-0.2
          c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.2,0.6,0.2,1c0,0.2,0,0.4-0.1,0.6c0,0.1,0,0.1-0.1,0.1c-0.2,0-0.4-0.1-0.5-0.1s-0.1,0-0.1-0.1
          c-0.1-0.3-0.3-0.6-0.4-1C1550.7,1759.5,1550.7,1759.2,1550.7,1758.9z M1550.3,1758c-0.4-0.2-0.9-0.3-1.3-0.3c0,0,0,0-0.1,0
          c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.3,0.2-0.5l0.1-0.1c0.4-0.1,0.8,0,1.2,0.2l0.1,0.1c0.1,0.3,0.1,0.7,0.3,1v0.1
          c-0.1,0-0.1,0-0.2,0C1550.6,1758.1,1550.4,1758.1,1550.3,1758z M1555.7,1759.2c0.1,0.2,0.1,0.3,0.2,0.5c0,0.1,0,0.1-0.1,0.2
          c0,0,0,0-0.1,0c0,0,0,0,0-0.1C1555.7,1759.6,1555.7,1759.4,1555.7,1759.2C1555.7,1759.1,1555.7,1759.2,1555.7,1759.2z
          M1549.8,1766.3c-0.2-0.4-0.3-0.8-0.5-1.2c0,0-1.3-3.9-1.8-5.8c0.5,0.4,1,0.7,1.5,1s1.8,0.9,1.8,0.8c0.1,0,0.8,0.2,0.9,0.2l0.1,0.1
          c-0.2,0.1-0.4,0.1-0.5,0.2c-0.3,0.1-0.7,0.2-1,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c0-0.1,0.1,1.1,0.1,1.2c0.2,0.8,0.3,1.6,0.5,2.4
          c0.2,0.6,0.3,1.1,0.5,1.7c0.2,0.8,0.5,1.7,0.7,2.5c-0.1,0-1-2.2-1.1-2.4C1550.1,1767.2,1549.9,1766.8,1549.8,1766.3z
          M1562.6,1755.5c0,0.3-1.5,4.6-1.5,4.6s-2,4.6-2.1,4.7c0,0-0.7,1.6-1.1,2.3c-0.4,0.8-0.8,1.5-1.1,2.2c-0.2,0.3-0.4,0.7-0.5,1
          c0,0-0.3,0.4-0.3,0.5c0,0-0.9,1.7-1.1,1.9c0,0-1,1.7-1.1,1.9c0,0,0,0.1-0.1,0.1c-0.4-0.9-0.9-1.7-1.3-2.6c-0.3-0.6-0.5-1.3-0.7-2
          c-0.5-1.7-1-3.5-1.4-5.2c-0.2-0.8-0.4-1.6-0.4-2.4c0-0.1,0.4-0.2,0.5-0.2c0.5-0.1,1-0.3,1.6-0.5c0.4-0.1,0.8-0.3,1.3-0.5
          c0.4-0.2,0.8-0.4,1.2-0.5c0.4-0.2,0.9-0.4,1.3-0.6c0.3-0.1,0.5-0.2,0.8-0.4c0.3-0.1,0.6-0.3,0.8-0.5c1.2-0.7,2.5-1.6,3.6-2.5
          C1561.2,1756.6,1562.3,1755.6,1562.6,1755.5c0-0.2,0-0.2,0.1-0.3C1562.6,1755.4,1562.6,1755.5,1562.6,1755.5z"/>
      </g>
      <g id="sundance_sunrise">
        
          <rect x="1928.3" y="1748" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -676.8608 1889.0869)" className="st4" width="27.2" height="27.2"/>
        
          <rect x="1925.6" y="1746.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -677.1623 1888.244)" className="st36" width="30.3" height="30.3"/>
        
          <rect x="1928.9" y="1746.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -676.1957 1890.5835)" className="st36" width="30.3" height="30.3"/>
        <g>
          <path className="st18" d="M1972.7,1759.7c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0.1,0.6,0.2,1,0.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.6,0,0.8-0.1
            c0.3-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.8c-0.2-0.2-0.4-0.5-0.6-0.7
            c-0.3-0.2-0.6-0.4-0.9-0.6s-0.7-0.4-1.1-0.7c-0.3-0.2-0.7-0.4-1.1-0.7c-0.4-0.2-0.7-0.5-1.1-0.9c-0.3-0.3-0.6-0.7-0.8-1.2
            c-0.2-0.5-0.3-1-0.3-1.7c0-0.6,0.1-1.1,0.4-1.5c0.2-0.5,0.6-0.9,1-1.2c0.4-0.3,0.9-0.6,1.4-0.8c0.5-0.2,1.1-0.3,1.7-0.3
            s1.1,0,1.5,0.1s0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0.1,0.4,0.2,0.6,0.3l-0.9,2c-0.3-0.1-0.6-0.3-1.1-0.4
            c-0.5-0.2-0.9-0.2-1.5-0.2c-0.3,0-0.5,0-0.8,0.1c-0.3,0-0.5,0.1-0.7,0.2s-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.4-0.2,0.6
            c0,0.3,0.1,0.6,0.2,0.8c0.2,0.2,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.4,0.9,0.6s0.7,0.4,1,0.6s0.7,0.5,1.1,0.7c0.4,0.3,0.8,0.6,1.2,1
            c0.4,0.4,0.7,0.8,0.9,1.2c0.2,0.4,0.4,0.9,0.4,1.4c0,0.7-0.2,1.3-0.5,1.9c-0.3,0.5-0.7,1-1.2,1.4s-1.1,0.7-1.7,0.8
            c-0.7,0.2-1.3,0.3-2,0.3c-0.6,0-1.1,0-1.6-0.1s-0.9-0.2-1.2-0.3c-0.4-0.1-0.7-0.2-0.9-0.3c-0.2-0.1-0.4-0.2-0.6-0.3l0.9-2
            C1972.4,1759.5,1972.5,1759.6,1972.7,1759.7z"/>
          <path className="st18" d="M1998.6,1757.3c0,0.7-0.1,1.4-0.4,2s-0.7,1.2-1.2,1.7s-1.1,0.8-1.9,1.1c-0.8,0.3-1.6,0.4-2.6,0.4
            s-1.8-0.1-2.6-0.4c-0.8-0.3-1.4-0.6-1.9-1.1s-0.9-1-1.2-1.6c-0.3-0.6-0.4-1.3-0.4-2.1v-9.4h3v9c0,1.1,0.2,1.9,0.7,2.5
            s1.3,0.9,2.4,0.9c0.6,0,1-0.1,1.4-0.3c0.4-0.2,0.7-0.4,1-0.8c0.3-0.3,0.4-0.7,0.6-1.1c0.1-0.4,0.2-0.8,0.2-1.2v-9h2.8
            L1998.6,1757.3L1998.6,1757.3z"/>
          <path className="st18" d="M2017.1,1747.9v14.3h-2.9l-7.4-9.5v9.5h-2.6v-14.3h2.7l7.6,9.7v-9.7H2017.1z"/>
          <path className="st18" d="M2022.9,1747.9h4.6c1.1,0,2.1,0.2,3.1,0.5s1.8,0.7,2.6,1.3c0.8,0.6,1.4,1.3,1.8,2.2c0.5,0.9,0.7,1.9,0.7,3.1
            c0,1.1-0.2,2-0.7,2.9s-1.1,1.6-1.8,2.3c-0.8,0.6-1.6,1.1-2.6,1.5c-1,0.3-2,0.5-3,0.5h-4.6L2022.9,1747.9L2022.9,1747.9z
            M2027.6,1760c0.5,0,1.1-0.1,1.7-0.4c0.6-0.2,1.1-0.6,1.6-1s0.9-1,1.2-1.6c0.3-0.6,0.5-1.3,0.5-2c0-0.9-0.1-1.6-0.4-2.2
            s-0.7-1.1-1.1-1.5s-1-0.7-1.6-0.9c-0.6-0.2-1.2-0.3-1.7-0.3h-1.6v9.8L2027.6,1760L2027.6,1760z"/>
          <path className="st18" d="M2041.4,1762.2h-2.7l5.8-14.3h2.1l5.3,14.3h-3.1l-1.2-3.2h-5L2041.4,1762.2z M2045.2,1752.3l-1.8,4.7h3.5
            L2045.2,1752.3z"/>
          <path className="st18" d="M2069.4,1747.9v14.3h-2.9l-7.4-9.5v9.5h-2.6v-14.3h2.7l7.6,9.7v-9.7H2069.4z"/>
          <path className="st18" d="M2087.3,1761.4c-0.5,0.3-1.1,0.5-1.9,0.7c-0.8,0.2-1.8,0.3-3.2,0.3c-1.1,0-2-0.2-3-0.5
            c-0.9-0.4-1.7-0.9-2.4-1.5c-0.7-0.7-1.2-1.4-1.6-2.3c-0.4-0.9-0.6-1.9-0.6-3s0.2-2.1,0.6-3c0.4-0.9,0.9-1.7,1.6-2.4
            c0.7-0.7,1.5-1.2,2.4-1.5c0.9-0.4,1.9-0.5,3-0.5c0.7,0,1.4,0,1.9,0.1c0.6,0.1,1,0.2,1.4,0.3c0.4,0.1,0.7,0.2,1,0.3
            c0.3,0.1,0.5,0.2,0.6,0.3l-0.9,2.1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.5-0.2-0.8-0.3c-0.3-0.1-0.7-0.2-1.1-0.2c-0.4-0.1-0.9-0.1-1.5-0.1
            s-1.1,0.1-1.7,0.4c-0.6,0.3-1.1,0.6-1.5,1.1c-0.4,0.4-0.8,1-1.1,1.6s-0.4,1.3-0.4,2.1c0,0.7,0.1,1.4,0.4,2
            c0.3,0.6,0.6,1.2,1.1,1.6c0.4,0.5,0.9,0.8,1.5,1.1c0.6,0.3,1.1,0.4,1.7,0.4c1,0,1.9-0.1,2.4-0.3c0.6-0.2,1.1-0.4,1.4-0.5
            L2087.3,1761.4z"/>
          <path className="st18" d="M2101.6,1762.2h-9.2v-14.3h8.9v2.2h-5.9v3.6h4.3v2.2h-4.3v4h6.2V1762.2z"/>
          <path className="st18" d="M1971.6,1773.8c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.5,0.1s0.4,0,0.6,0c0.1,0,0.3,0,0.4-0.1
            c0.1,0,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3
            c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.2-0.3-0.4-0.4-0.6
            s-0.2-0.5-0.2-0.8s0.1-0.5,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6s0.4-0.3,0.7-0.4c0.3-0.1,0.6-0.1,0.9-0.1s0.5,0,0.8,0
            c0.2,0,0.4,0.1,0.6,0.1s0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.1l-0.4,1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.5-0.1-0.7-0.1
            c-0.1,0-0.3,0-0.4,0s-0.2,0.1-0.3,0.1s-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0.1,0.4s0.2,0.2,0.3,0.3
            c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.5s0.3,0.4,0.5,0.6
            c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.5,0.3-0.9,0.4c-0.3,0.1-0.7,0.1-1,0.1
            s-0.6,0-0.8-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l0.4-1
            C1971.4,1773.7,1971.5,1773.7,1971.6,1773.8z"/>
          <path className="st18" d="M1986.4,1772.6c0,0.4-0.1,0.7-0.2,1s-0.3,0.6-0.6,0.8c-0.3,0.2-0.6,0.4-0.9,0.5c-0.4,0.1-0.8,0.2-1.3,0.2
            s-0.9-0.1-1.3-0.2s-0.7-0.3-0.9-0.5c-0.3-0.2-0.5-0.5-0.6-0.8s-0.2-0.7-0.2-1.1v-4.7h1.5v4.5c0,0.5,0.1,0.9,0.4,1.2
            c0.2,0.3,0.7,0.4,1.2,0.4c0.3,0,0.5,0,0.7-0.1s0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6v-4.5h1.4
            L1986.4,1772.6L1986.4,1772.6z"/>
          <path className="st18" d="M1997.6,1767.9v7.1h-1.4l-3.7-4.8v4.8h-1.3v-7.1h1.4l3.8,4.9v-4.9H1997.6z"/>
          <path className="st18" d="M2006.5,1775.2l-1.9-3.2h-0.7v3.1h-1.5v-7.1h2.1c0.5,0,0.9,0,1.3,0.1s0.7,0.2,0.9,0.4
            c0.2,0.2,0.4,0.4,0.5,0.6s0.2,0.5,0.2,0.8s0,0.5-0.1,0.7s-0.2,0.4-0.3,0.5c-0.1,0.1-0.3,0.3-0.4,0.4s-0.3,0.2-0.4,0.2l2.2,3.4
            L2006.5,1775.2z M2004.6,1771c0.4,0,0.8-0.1,1-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-1-0.3h-0.6v2
            H2004.6z"/>
          <path className="st18" d="M2012.4,1775v-7.1h1.5v7.1H2012.4z"/>
          <path className="st18" d="M2019.2,1773.8c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.5,0.1s0.4,0,0.6,0c0.1,0,0.3,0,0.4-0.1
            c0.1,0,0.3-0.1,0.4-0.2s0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
            s-0.4-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.2-0.3-0.4-0.4-0.6s-0.2-0.5-0.2-0.8s0.1-0.5,0.2-0.8
            c0.1-0.2,0.3-0.4,0.5-0.6s0.4-0.3,0.7-0.4c0.3-0.1,0.6-0.1,0.9-0.1s0.5,0,0.8,0c0.2,0,0.4,0.1,0.6,0.1s0.3,0.1,0.4,0.1
            c0.1,0.1,0.2,0.1,0.3,0.1l-0.4,1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.5-0.1-0.7-0.1c-0.1,0-0.3,0-0.4,0s-0.2,0.1-0.3,0.1
            s-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0.1,0.4s0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.3
            c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.5s0.3,0.4,0.5,0.6c0.1,0.2,0.2,0.4,0.2,0.7
            c0,0.3-0.1,0.7-0.2,0.9c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.5,0.3-0.9,0.4c-0.3,0.1-0.7,0.1-1,0.1s-0.6,0-0.8-0.1
            c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l0.4-1C2019,1773.7,2019.1,1773.7,2019.2,1773.8z"/>
          <path className="st18" d="M2032.6,1775h-4.6v-7.1h4.5v1.1h-3v1.8h2.2v1.1h-2.2v2h3.1V1775z"/>
        </g>
        <g id="qhPSMY_2_">
          <g>
            <path className="st37" d="M1948.5,1757.8L1948.5,1757.8c0.1-1.3,0.1-2.6,0.1-4c0-0.4-0.3-0.5-0.7-0.2c-0.2,0.1-0.4,0.2-0.5,0.2
              c-0.2,0-0.4-0.1-0.6-0.2c-0.9-0.3-1.7-0.3-2.6,0.2c-0.3,0.2-0.7,0.2-1,0.2c-0.6-0.1-1.2-0.2-1.9-0.3c-1.1-0.2-2.2-0.5-3.3,0
              c-1,0.5-2,0.5-2.9-0.3c-0.5-0.4-0.9-0.1-0.9,0.5c0,1.2,0,2.4,0,3.7c0,0.9,0.4,1.3,1.3,1.3c0.3,0,0.7,0,0.8-0.1
              c0.7-0.7,1.6-1.1,2.6-1.4c0.6-0.2,1.3-0.3,1.9-0.5c-0.8-0.2-1.6-0.4-2.3-0.1c-0.3,0.1-0.5,0.2-0.8,0.3c-0.9,0.3-1.7,0.2-2.5-0.2
              c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.4,0-0.9,0-1.4c0.2,0.1,0.3,0.1,0.4,0.1c0.9,0.6,1.7,0.5,2.6,0.1c0.7-0.3,1.5-0.4,2.3-0.3
              c0.6,0.1,1.2,0.2,1.8,0.3c0.1,0,0.4,0,0.5,0c0.7-0.5,1.4-0.7,2.2-0.8c0.2,0,0.4-0.1,0.5-0.3c0.3-0.3,0.7-0.5,1.1-0.4
              c0.6,0.1,0.8,0.5,1.1,1c0.1,0.3,0.4,0.5,0.5,0.7C1948,1756.4,1948.3,1757.1,1948.5,1757.8z M1938.4,1765.1
              c-0.2,0.2-0.6,0.1-0.6,0.6c0.3,2.7,1.5,4.8,3.9,6.1c2,1.1,4,0.8,5.7-0.7c0.5-0.5,0.9-1,1.4-1.4c0.4-0.4,0.8-0.8,1.2-1.1
              c2.3-1.5,3.1-5,1.3-7.6c-0.6-0.9-1.4-1.6-2.4-2.1c0,0.3-0.1,0.5-0.1,0.7c0,0,0,0,0.1-0.1c0.6,0.6,1.3,1.1,1.8,1.8
              c1.5,2,1.2,4.9-0.7,6.4c-0.7,0.6-1.4,1.3-2.1,1.9c-0.4,0.4-0.7,0.8-1.2,1.1c-1.3,1.1-2.8,1.3-4.4,0.6c-1.7-0.7-2.8-2.1-3.4-3.8
              C1938.7,1766.7,1938.6,1765.9,1938.4,1765.1z"/>
            <path className="st38" d="M1948.2,1758.7c0,0.7-0.4,1.1-0.9,1.2c-0.4,0.1-0.8,0.1-1.3,0.2s-0.8,0.3-1.1,0.7c-0.4,0.7-1,1.1-1.8,1.3
              c-0.8,0.2-1.7,0.5-2.5,0.7c-0.6,0.1-1.1,0.4-1.5,0.8c-0.7,0.6-1.4,1.2-2.2,1.5c-1.1,0.4-1.6,0.2-2.1-0.8s-1-2-1.4-3
              c-0.1-0.2-0.1-0.6,0-0.7c0.1-0.2,0.4-0.2,0.7-0.2c0.2,0,0.4,0,0.5,0.1c0.7,0.1,1.3-0.1,1.7-0.6c0.9-1.1,2.1-1.6,3.5-1.9
              c0.6-0.1,1.2-0.3,1.8-0.5c0.3-0.1,0.6-0.3,0.7-0.5c0.6-1.1,1.5-1.4,2.7-1.4c0.4,0,0.6-0.1,0.8-0.5c0.1-0.3,0.5-0.3,0.7,0
              c0.1,0.1,0.2,0.3,0.2,0.4c0.5,1,0.9,2,1.4,2.9C1948.1,1758.4,1948.1,1758.6,1948.2,1758.7z M1946.5,1756.9
              c-0.4,0.1-0.8,0.1-1.2,0.1c-0.8,0.1-1.6,0.3-2.1,1c-0.4,0.7-1,1-1.7,1.1c-0.3,0.1-0.6,0.2-0.8,0.2c-1.6,0.3-3,0.8-4,2.2
              c-0.3,0.4-0.7,0.5-1.2,0.5c-0.3,0-0.7,0-1.1,0c0.2,0.5,0.4,0.9,0.6,1.2c0.1,0.1,0.2,0.2,0.3,0.2c0.9,0.1,1.7-0.2,2.4-0.9
              c0.2-0.2,0.4-0.5,0.6-0.6c0.4-0.3,0.8-0.6,1.2-0.7c1-0.3,2.1-0.6,3.1-0.9c0.6-0.2,1.1-0.5,1.4-1.1c0.3-0.5,0.7-0.7,1.3-0.8
              c0.5,0,1.1-0.2,1.6-0.3C1946.9,1757.7,1946.7,1757.3,1946.5,1756.9z"/>
            <path className="st38" d="M1948.5,1757.8c-0.2-0.7-0.5-1.4-0.7-2.1c-0.2-0.2-0.4-0.5-0.5-0.7c-0.2-0.5-0.5-0.9-1.1-1
              c-0.4-0.1-0.8,0.1-1.1,0.4c-0.1,0.1-0.3,0.3-0.5,0.3c-0.8,0.1-1.6,0.3-2.2,0.8c-0.1,0.1-0.3,0.1-0.5,0c-0.6-0.1-1.2-0.2-1.8-0.3
              c-0.8-0.2-1.5-0.1-2.3,0.3c-0.9,0.4-1.8,0.5-2.6-0.1c-0.1-0.1-0.2-0.1-0.4-0.1c0,0.5,0,0.9,0,1.4c0,0.1,0.1,0.2,0.2,0.3
              c0.8,0.5,1.7,0.5,2.5,0.2c0.3-0.1,0.5-0.2,0.8-0.3c0.8-0.3,1.6-0.2,2.3,0.1c-0.6,0.2-1.3,0.3-1.9,0.5c-1,0.2-1.9,0.6-2.6,1.4
              c-0.2,0.2-0.6,0.2-0.8,0.1c-0.9,0-1.2-0.4-1.3-1.3c0-1.2,0-2.4,0-3.7c0-0.6,0.5-0.9,0.9-0.5c0.9,0.7,1.9,0.8,2.9,0.3
              c1.1-0.6,2.2-0.2,3.3,0c0.6,0.1,1.2,0.3,1.9,0.3c0.3,0,0.7,0,1-0.2c0.8-0.6,1.7-0.6,2.6-0.2c0.2,0.1,0.4,0.2,0.6,0.2
              s0.4-0.1,0.5-0.2c0.3-0.2,0.7-0.1,0.7,0.2c0,1.3,0,2.6,0,4C1948.6,1757.8,1948.5,1757.8,1948.5,1757.8z"/>
            <path className="st38" d="M1938.4,1765.1c0.2,0.8,0.3,1.6,0.5,2.3c0.6,1.7,1.7,3.1,3.4,3.8c1.6,0.7,3,0.5,4.4-0.6
              c0.4-0.3,0.8-0.7,1.2-1.1c0.7-0.6,1.4-1.3,2.1-1.9c1.9-1.5,2.2-4.4,0.7-6.4c-0.5-0.7-1.2-1.2-1.8-1.8c0,0,0,0-0.1,0.1
              c0-0.2,0.1-0.5,0.1-0.7c1,0.5,1.8,1.2,2.4,2.1c1.8,2.6,1.1,6.1-1.3,7.6c-0.5,0.3-0.8,0.7-1.2,1.1c-0.5,0.5-0.9,1-1.4,1.4
              c-1.6,1.5-3.7,1.8-5.7,0.7c-2.4-1.3-3.6-3.4-3.9-6.1C1937.7,1765.2,1938.2,1765.3,1938.4,1765.1z"/>
            <path className="st38" d="M1947.6,1766.5c0,2-1.6,3.6-3.6,3.6s-3.7-1.6-3.7-3.6s1.6-3.7,3.7-3.7
              C1946,1762.9,1947.6,1764.5,1947.6,1766.5z M1943.9,1769.4c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9s-2.9,1.3-2.9,2.9
              S1942.3,1769.4,1943.9,1769.4z"/>
            <path className="st38" d="M1944,1768.7c0-0.2,0-0.4,0-0.7c0.9-0.1,1.3-0.6,1.5-1.5c0.2,0,0.4,0,0.7,0
              C1946.2,1767.6,1945.2,1768.7,1944,1768.7z"/>
          </g>
        </g>
      </g>
      <g>
        <g>
          <text transform="matrix(1 0 0 1 2656.0005 1121.5443)" className="st39 st12 st40">Bud Light</text>
          <text transform="matrix(1 0 0 1 2671.2004 1157.5443)" className="st39 st12 st40">Gallery</text>
        </g>
        <g>
          <rect x="2675.3" y="997.9" className="st41" width="85.8" height="85.8"/>
          <g>
            <path className="st42" d="M2749.7,1022.1h-62.9c-1.1,0-1.9,0.8-1.9,1.9v33.6c0,1.1,0.7,1.9,1.9,1.9h62.9c1.1,0,1.9-0.7,1.9-1.9v-33.7
              C2751.6,1022.8,2750.8,1022.1,2749.7,1022.1 M2749.7,1023.2c0.5,0,0.7,0.3,0.7,0.7v33.6c0,0.5-0.3,0.7-0.7,0.7h-62.9
              c-0.5,0-0.7-0.3-0.7-0.7V1024c0-0.5,0.3-0.7,0.7-0.7H2749.7z"/>
            <path className="st4" d="M2746.1,1054.8c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8S2746.5,1054.8,2746.1,1054.8
              M2746.1,1053.1c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1C2747,1053.5,2746.6,1053.1,2746.1,1053.1 M2745.9,1054v-0.3
              h0.3c0.1,0,0.2,0,0.2,0.1c0,0.1-0.1,0.2-0.2,0.2H2745.9z M2746.6,1053.9c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1h-0.5v1h0.2
              v-0.4h0.2l0.2,0.4h0.2l-0.3-0.4C2746.5,1054.1,2746.6,1054.1,2746.6,1053.9 M2704.1,1034.3c0.6,0,1.3,0.3,1.3,1.4
              c0,1.2-0.6,1.5-1.3,1.5h-1.6v-2.9H2704.1L2704.1,1034.3z M2704,1029.3c0.7,0,1.2,0.4,1.2,1.3c0,1.1-0.5,1.4-1.2,1.4h-1.4v-2.7
              H2704z M2705.8,1040c2.4,0,4.5-1.2,4.5-4c0-1.7-1.1-2.8-2.3-3.2c1.2-0.6,1.9-1.5,1.9-2.8c0-2.2-1.6-3.4-4.1-3.4h-8.2v13.4H2705.8
              z M2731,1029.5c1,0,2.6,0.1,2.6,3.6c0,3.6-1.5,3.8-2.6,3.8h-0.5v-7.5H2731z M2732.1,1040c3.5,0,6.8-2,6.8-6.8
              c0-4.7-3-6.6-6.6-6.6h-6.9v13.4H2732.1z M2698.9,1055v-3.3h-4.3v-10.1h-5.2v13.4H2698.9z M2700,1055h5.3v-13.4h-5.3V1055z
              M2714.2,1055.4c2.1,0,4-0.4,5.6-1.1v-7h-6.2v2.7h1.6v2.3c-0.2,0-0.5,0.1-0.8,0.1c-2,0-2.7-1.6-2.7-4c0-2.6,1.1-3.7,2.7-3.7
              c1.3,0,2.4,0.6,3.2,1.3l2.3-2.7c-1.5-1.3-3.3-2-5.8-2c-4.5,0-7.6,2.6-7.6,7.1C2706.6,1053.2,2709.4,1055.4,2714.2,1055.4
              M2734.2,1055v-13.4h-5.1v4.9h-2.8v-4.9h-5.1v13.4h5.1v-5.2h2.8v5.2H2734.2z M2717.6,1040.2c4.2,0,6.2-1.5,6.2-5.1v-8.5h-5v9
              c0,1-0.6,1.4-1.3,1.4s-1.3-0.4-1.3-1.4v-9h-5.1v8.5C2711.2,1038.4,2713.2,1040.2,2717.6,1040.2 M2743.8,1044.7v10.2h-5.3v-10.2
              h-3.2v-3.2h11.7v3.2H2743.8z"/>
          </g>
        </g>
      </g>
      <g id="_x37_th_street_staples_east">
        
          <rect x="1154.7" y="1747.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -903.6224 1342.7467)" className="st4" width="28.7" height="28.7"/>
        
          <rect x="1151" y="1746.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -904.5323 1341.3145)" className="st33" width="31.7" height="31.7"/>
        
          <rect x="1154.4" y="1746.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -903.5366 1343.7058)" className="st33" width="31.7" height="31.7"/>
        <g>
          <path className="st18" d="M1199.9,1748.3h10.5v2.2l-8.5,13.4l-1.9-1.2l6.7-11.6h-6.7L1199.9,1748.3L1199.9,1748.3z"/>
          <path className="st18" d="M1212.6,1747h7.4v1.4h-2.8v7.4h-1.8v-7.4h-2.8L1212.6,1747L1212.6,1747z"/>
          <path className="st18" d="M1228.4,1751.9h-3.6v3.8h-1.8v-8.7h1.8v3.6h3.6v-3.6h1.8v8.7h-1.8V1751.9z"/>
          <path className="st18" d="M1240.6,1760.6c0.2,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.2,1,0.2c0.4,0.1,0.8,0.1,1.4,0.1c0.3,0,0.6,0,0.9-0.1
            s0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.4,0.2-0.7c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.5-0.7-0.7
            c-0.3-0.2-0.6-0.4-1-0.7c-0.4-0.2-0.7-0.5-1.1-0.7s-0.7-0.5-1.1-0.7c-0.4-0.3-0.8-0.6-1.1-0.9c-0.4-0.4-0.6-0.8-0.9-1.3
            c-0.2-0.5-0.3-1.1-0.3-1.7s0.1-1.1,0.4-1.6s0.6-0.9,1-1.3s0.9-0.6,1.5-0.8c0.6-0.2,1.2-0.3,1.8-0.3c0.6,0,1.1,0,1.6,0.1
            s0.9,0.1,1.2,0.2c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0.1,0.4,0.2,0.6,0.3l-0.9,2.1c-0.3-0.1-0.6-0.3-1.1-0.4c-0.5-0.2-1-0.2-1.6-0.2
            c-0.3,0-0.5,0-0.8,0.1c-0.3,0-0.5,0.1-0.7,0.2s-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.3,0.1,0.6,0.2,0.8
            c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.4,0.9,0.6c0.4,0.2,0.7,0.4,1.1,0.7c0.3,0.2,0.7,0.5,1.2,0.8c0.4,0.3,0.8,0.6,1.2,1
            c0.4,0.4,0.7,0.8,1,1.2c0.3,0.4,0.4,0.9,0.4,1.5c0,0.7-0.2,1.4-0.5,2s-0.7,1-1.3,1.4c-0.5,0.4-1.1,0.7-1.8,0.9
            c-0.7,0.2-1.4,0.3-2.1,0.3c-0.6,0-1.2,0-1.7-0.1s-0.9-0.2-1.3-0.3c-0.4-0.1-0.7-0.2-0.9-0.3c-0.3-0.1-0.5-0.2-0.6-0.3l0.9-2.1
            C1240.2,1760.4,1240.4,1760.5,1240.6,1760.6z"/>
          <path className="st18" d="M1253.5,1748.3h12.7v2.3h-4.8v12.6h-3.2v-12.6h-4.8v-2.3H1253.5z"/>
          <path className="st18" d="M1279.8,1763.5l-4-6.7h-1.4v6.4h-3.2v-15h4.4c1,0,1.9,0.1,2.7,0.3c0.7,0.2,1.4,0.5,1.9,0.9s0.8,0.8,1.1,1.3
            c0.2,0.5,0.4,1,0.4,1.6c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.4,0.8-0.7,1.1c-0.3,0.3-0.6,0.6-0.9,0.7c-0.3,0.2-0.6,0.3-0.8,0.4l4.6,7.2
            L1279.8,1763.5z M1275.7,1754.7c0.9,0,1.6-0.2,2.1-0.6s0.7-0.9,0.7-1.5s-0.2-1.1-0.7-1.5c-0.4-0.4-1.1-0.6-2.1-0.6h-1.3v4.3h1.3
            V1754.7z"/>
          <path className="st18" d="M1297.7,1763.2h-9.6v-15h9.4v2.3h-6.2v3.8h4.5v2.3h-4.5v4.2h6.5v2.4L1297.7,1763.2L1297.7,1763.2z"/>
          <path className="st18" d="M1312.5,1763.2h-9.6v-15h9.4v2.3h-6.2v3.8h4.5v2.3h-4.5v4.2h6.5v2.4L1312.5,1763.2L1312.5,1763.2z"/>
          <path className="st18" d="M1316.5,1748.3h12.7v2.3h-4.8v12.6h-3.2v-12.6h-4.8v-2.3H1316.5z"/>
          <path className="st18" d="M1199.1,1775.4c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.5,0.1s0.4,0,0.7,0c0.1,0,0.3,0,0.4-0.1
            c0.1,0,0.3-0.1,0.4-0.2s0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.4c-0.1-0.1-0.3-0.2-0.5-0.3
            s-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.3-0.6-0.5s-0.3-0.4-0.4-0.6s-0.2-0.5-0.2-0.9c0-0.3,0.1-0.6,0.2-0.8
            c0.1-0.2,0.3-0.5,0.5-0.6c0.2-0.2,0.5-0.3,0.7-0.4c0.3-0.1,0.6-0.2,0.9-0.2s0.6,0,0.8,0.1c0.2,0,0.4,0.1,0.6,0.1s0.3,0.1,0.4,0.1
            c0.1,0.1,0.2,0.1,0.3,0.1l-0.4,1.1c-0.1-0.1-0.3-0.1-0.6-0.2c-0.2-0.1-0.5-0.1-0.8-0.1c-0.1,0-0.3,0-0.4,0s-0.2,0.1-0.4,0.1
            s-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0.1,0.4s0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.5,0.3
            c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.5s0.3,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.7
            c0,0.4-0.1,0.7-0.2,1c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-0.9,0.4s-0.7,0.2-1.1,0.2c-0.3,0-0.6,0-0.8-0.1
            c-0.2,0-0.5-0.1-0.7-0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l0.4-1.1C1198.9,1775.3,1199,1775.3,1199.1,1775.4z"/>
          <path className="st18" d="M1207.6,1769.2h6.4v1.2h-2.4v6.3h-1.6v-6.3h-2.4V1769.2z"/>
          <path className="st18" d="M1217.7,1776.7h-1.4l3.1-7.5h1.1l2.8,7.5h-1.6l-0.6-1.7h-2.6L1217.7,1776.7z M1219.7,1771.5l-0.9,2.5h1.8
            L1219.7,1771.5z"/>
          <path className="st18" d="M1227.7,1776.7v-7.5h2.4c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.9,0.5c0.2,0.2,0.4,0.4,0.5,0.7
            c0.1,0.3,0.2,0.5,0.2,0.8s-0.1,0.6-0.2,0.9s-0.4,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-0.9,0.4s-0.7,0.2-1.1,0.2h-0.8v3.1L1227.7,1776.7
            L1227.7,1776.7z M1229.8,1772.5c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.4-0.2s0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.5
            s0-0.4-0.1-0.5s-0.2-0.3-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.1h-0.6v2.3L1229.8,1772.5L1229.8,1772.5z"/>
          <path className="st18" d="M1239.1,1775.5h2.8v1.2h-4.4v-7.5h1.6V1775.5z"/>
          <path className="st18" d="M1251.1,1776.7h-4.8v-7.5h4.7v1.2h-3.1v1.9h2.3v1.2h-2.3v2.1h3.2L1251.1,1776.7L1251.1,1776.7z"/>
          <path className="st18" d="M1256.2,1775.4c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.5,0.1s0.4,0,0.7,0c0.1,0,0.3,0,0.4-0.1
            c0.1,0,0.3-0.1,0.4-0.2s0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.4c-0.1-0.1-0.3-0.2-0.5-0.3
            c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.3-0.6-0.5s-0.3-0.4-0.4-0.6s-0.2-0.5-0.2-0.9
            c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.5,0.5-0.6c0.2-0.2,0.5-0.3,0.7-0.4c0.3-0.1,0.6-0.2,0.9-0.2s0.6,0,0.8,0.1
            c0.2,0,0.4,0.1,0.6,0.1s0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.1l-0.4,1.1c-0.1-0.1-0.3-0.1-0.6-0.2c-0.2-0.1-0.5-0.1-0.8-0.1
            c-0.1,0-0.3,0-0.4,0s-0.2,0.1-0.4,0.1s-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0.1,0.4s0.2,0.2,0.3,0.3
            c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.5s0.3,0.4,0.5,0.6
            c0.1,0.2,0.2,0.5,0.2,0.7c0,0.4-0.1,0.7-0.2,1c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-0.9,0.4s-0.7,0.2-1.1,0.2
            c-0.3,0-0.6,0-0.8-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l0.4-1.1
            C1256,1775.3,1256.1,1775.3,1256.2,1775.4z"/>
        </g>
        <g id="eI18kZ_4_">
          <g>
            <path className="st30" d="M1157.3,1764.3c0-0.1,0-0.1,0.1-0.2c0.6-2.1,3-3,4.8-1.9c0,0,0.1,0,0.1,0.1c0.7-0.8,1.7-1.3,2.8-1.2
              c0.9,0.1,1.6,0.5,2.2,1.3c1.1,1.4,0.8,3.9-1.3,4.8c0.5,1.5,0.3,2.8-1,3.8c-1.1,0.8-2.3,0.9-3.5,0.3c-1.3-0.7-1.8-1.8-1.7-3.2
              c-0.1,0-0.2,0-0.3-0.1c-1.2-0.3-2-1.1-2.3-2.3c0,0,0-0.1-0.1-0.1C1157.3,1765.2,1157.3,1764.7,1157.3,1764.3z M1161.7,1763.2
              c-0.5-0.3-1.1-0.5-1.7-0.4c-1.1,0.2-1.9,1.2-1.9,2.3c0,1.1,0.9,2.1,2,2.2c0.5,0,1,0,1.5-0.3c0.3-0.2,0.5-0.1,0.7,0.2
              c0.1,0.3,0.1,0.5-0.2,0.7c-0.3,0.1-0.6,0.3-1,0.4c-0.2,0-0.2,0.1-0.2,0.3c-0.1,0.9,0.4,1.8,1.2,2.2c0.9,0.4,1.7,0.3,2.5-0.3
              c0.7-0.6,1-1.5,0.7-2.4c-0.1-0.4-0.1-0.4-0.5-0.4c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5c0.1,0,0.2,0,0.3,0
              c1.3-0.1,2.2-1.3,2-2.6c-0.2-1.2-1.5-2.1-2.7-1.8c-1,0.2-1.7,1.1-1.8,2.2c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5
              C1161.6,1763.9,1161.7,1763.6,1161.7,1763.2z"/>
            <path className="st30" d="M1180.2,1765.9c0,0.2-0.1,0.3-0.1,0.5c-0.3,1.2-1.1,1.9-2.3,2.2c-1.2,0.3-2.3,0-3.1-1l-0.1-0.1
              c0,0,0,0-0.1,0c-1,0.6-2,0.8-3.1,0.4c-0.8-0.3-1.4-1-1.7-1.8c-0.6-1.6,0.1-3.7,2.4-4.2c-0.1-1.2,0.3-2.3,1.4-3
              c0.7-0.5,1.5-0.7,2.4-0.5c2.1,0.4,3.1,2.5,2.4,4.3l0,0c1.1,0.5,1.7,1.3,1.9,2.4v0.1C1180.2,1765.4,1180.2,1765.6,1180.2,1765.9z
              M1175.2,1766.8c0,0,0.1,0.1,0.1,0.2c0.6,0.7,1.5,0.9,2.4,0.7c0.9-0.3,1.4-1,1.5-2c0.1-0.9-0.5-1.7-1.2-2.1
              c-0.2-0.1-0.4-0.1-0.6,0.1s-0.5,0.2-0.7,0s-0.2-0.5,0-0.7c0.6-0.7,0.8-1.5,0.4-2.4s-1.1-1.4-2.1-1.4s-1.7,0.5-2,1.3
              c-0.4,0.9-0.2,1.7,0.4,2.5c0.2,0.2,0.2,0.5,0,0.7s-0.5,0.2-0.7,0s-0.4-0.5-0.5-0.7c-0.1-0.1-0.2-0.2-0.3-0.2
              c-1,0.2-1.8,1.2-1.7,2.3c0,1,0.9,2,1.9,2.1c1.2,0.2,2.3-0.8,2.5-1.7c0.1-0.3,0.3-0.5,0.6-0.4c0.3,0.1,0.4,0.3,0.3,0.6
              C1175.5,1766.1,1175.4,1766.4,1175.2,1766.8z"/>
            <path className="st30" d="M1167.4,1751.6c1-1.1,2.2-1.5,3.5-1c1.2,0.4,2,1.4,2.1,2.7c0.1,1.5-0.6,2.5-1.9,3.2c0.5,1.5,0.3,2.8-1,3.8
              c-1.1,0.8-2.3,0.9-3.5,0.2c-1.2-0.7-1.7-1.8-1.6-3.2c-1.3-0.3-2.2-1-2.6-2.3c-0.2-0.8-0.1-1.6,0.3-2.4
              C1163.5,1751.2,1165.6,1750.5,1167.4,1751.6z M1163.3,1754.2c0,1.2,0.9,2.2,2,2.3c0.5,0,1,0,1.4-0.3c0.3-0.2,0.5-0.1,0.7,0.2
              c0.1,0.3,0.1,0.5-0.2,0.7c-0.2,0.1-0.5,0.2-0.7,0.3c-0.5,0.2-0.5,0.2-0.5,0.7c0,0.1,0,0.1,0,0.2c0.2,1.2,1.4,2.1,2.6,1.9
              c1.3-0.3,2.1-1.5,1.8-2.8c-0.1-0.4-0.1-0.4-0.5-0.5c-0.3,0-0.4-0.2-0.4-0.5s0.2-0.4,0.4-0.5c0.1,0,0.2,0,0.4,0
              c0.9-0.1,1.7-0.8,1.9-1.7c0.2-0.9-0.2-1.9-1-2.4s-1.8-0.5-2.5,0.1c-0.6,0.4-0.9,1-0.9,1.8c0,0.4-0.2,0.5-0.5,0.5
              s-0.5-0.2-0.4-0.6c0-0.3,0.1-0.6,0.1-0.9c0-0.1,0-0.2-0.1-0.2c-0.7-0.5-1.5-0.5-2.3-0.1
              C1163.7,1752.7,1163.4,1753.4,1163.3,1754.2z"/>
          </g>
        </g>
      </g>
      <g id="_x37_th_street_staples_west">
        
          <rect x="1733.4" y="364.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 244.028 1346.6045)" className="st4" width="28.3" height="28.3"/>
        
          <rect x="1729.7" y="363.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 243.0742 1345.179)" className="st33" width="31.3" height="31.3"/>
        
          <rect x="1733.1" y="363.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 244.0237 1347.6399)" className="st33" width="31.3" height="31.3"/>
        <g>
          <path className="st18" d="M1778,365.1h10.3v2.2l-8.4,13.2l-1.9-1.2l6.6-11.4h-6.6V365.1z"/>
          <path className="st18" d="M1790.6,363.9h7.3v1.3h-2.7v7.3h-1.8v-7.3h-2.7v-1.3H1790.6z"/>
          <path className="st18" d="M1806.1,368.7h-3.6v3.8h-1.8v-8.6h1.8v3.5h3.6v-3.5h1.8v8.6h-1.8V368.7z"/>
          <path className="st18" d="M1818.2,377.3c0.2,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.2,1,0.2c0.4,0.1,0.8,0.1,1.3,0.1c0.3,0,0.6,0,0.9-0.1
            s0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.4,0.2-0.7c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.5-0.6-0.7
            c-0.3-0.2-0.6-0.4-0.9-0.7c-0.4-0.2-0.7-0.4-1.1-0.7c-0.4-0.2-0.7-0.5-1.1-0.7c-0.4-0.3-0.8-0.6-1.1-0.9c-0.3-0.4-0.6-0.8-0.9-1.3
            c-0.2-0.5-0.3-1.1-0.3-1.7s0.1-1.1,0.4-1.6s0.6-0.9,1-1.3s0.9-0.6,1.5-0.8c0.6-0.2,1.2-0.3,1.8-0.3c0.6,0,1.1,0,1.6,0.1
            s0.9,0.1,1.2,0.2c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0.1,0.4,0.2,0.6,0.3l-0.9,2.1c-0.3-0.1-0.6-0.3-1.1-0.4c-0.5-0.2-1-0.2-1.5-0.2
            c-0.3,0-0.5,0-0.8,0.1c-0.3,0-0.5,0.1-0.7,0.2s-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.3,0.1,0.6,0.2,0.8
            c0.2,0.2,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.4,0.9,0.6s0.7,0.4,1.1,0.7c0.3,0.2,0.7,0.5,1.1,0.8s0.8,0.6,1.2,1
            c0.4,0.4,0.7,0.8,0.9,1.2c0.3,0.4,0.4,0.9,0.4,1.5c0,0.7-0.2,1.4-0.5,1.9c-0.3,0.6-0.7,1-1.3,1.4c-0.5,0.4-1.1,0.7-1.8,0.9
            c-0.7,0.2-1.4,0.3-2.1,0.3c-0.6,0-1.2,0-1.7-0.1s-0.9-0.2-1.3-0.3c-0.4-0.1-0.7-0.2-0.9-0.3c-0.3-0.1-0.5-0.2-0.6-0.3l0.9-2.1
            C1817.8,377.1,1818,377.2,1818.2,377.3z"/>
          <path className="st18" d="M1830.9,365.1h12.6v2.3h-4.7v12.5h-3.1v-12.5h-4.7v-2.3H1830.9z"/>
          <path className="st18" d="M1856.8,380.1l-4-6.6h-1.4v6.4h-3.1v-14.8h4.3c1,0,1.9,0.1,2.6,0.3c0.7,0.2,1.3,0.5,1.8,0.9s0.8,0.8,1.1,1.3
            c0.2,0.5,0.4,1,0.4,1.6c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.4,0.8-0.7,1.1c-0.3,0.3-0.6,0.5-0.9,0.7s-0.6,0.3-0.7,0.4l4.5,7.1
            L1856.8,380.1z M1852.7,371.4c0.9,0,1.6-0.2,2-0.6s0.7-0.9,0.7-1.5s-0.2-1.1-0.6-1.5s-1.1-0.6-2-0.6h-1.3v4.2H1852.7z"/>
          <path className="st18" d="M1874.5,379.9h-9.5v-14.8h9.2v2.3h-6.1v3.7h4.5v2.3h-4.5v4.1h6.4V379.9z"/>
          <path className="st18" d="M1889.1,379.9h-9.5v-14.8h9.2v2.3h-6.1v3.7h4.5v2.3h-4.5v4.1h6.4V379.9z"/>
          <path className="st18" d="M1893,365.1h12.6v2.3h-4.7v12.5h-3.1v-12.5h-4.7v-2.3H1893z"/>
          <path className="st18" d="M1777.3,391.8c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.5,0.1s0.4,0,0.7,0c0.1,0,0.3,0,0.4-0.1
            c0.1,0,0.3-0.1,0.4-0.2s0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
            c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.3-0.6-0.5s-0.3-0.4-0.4-0.6s-0.2-0.5-0.2-0.9
            c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.5,0.5-0.6c0.2-0.2,0.5-0.3,0.7-0.4c0.3-0.1,0.6-0.1,0.9-0.1s0.6,0,0.8,0
            c0.2,0,0.4,0.1,0.6,0.1s0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.1l-0.4,1.1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.5-0.1-0.8-0.1
            c-0.1,0-0.3,0-0.4,0s-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0.1,0.4s0.2,0.2,0.3,0.3
            c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.5s0.3,0.4,0.5,0.6
            c0.1,0.2,0.2,0.5,0.2,0.7c0,0.4-0.1,0.7-0.2,1c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-0.9,0.4s-0.7,0.1-1.1,0.1
            c-0.3,0-0.6,0-0.8-0.1c-0.2,0-0.5-0.1-0.6-0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l0.4-1
            C1777.1,391.8,1777.2,391.8,1777.3,391.8z"/>
          <path className="st18" d="M1785.6,385.8h6.3v1.2h-2.4v6.2h-1.6V387h-2.4L1785.6,385.8L1785.6,385.8z"/>
          <path className="st18" d="M1795.6,393.1h-1.4l3-7.4h1.1l2.7,7.4h-1.6l-0.6-1.7h-2.6L1795.6,393.1z M1797.5,388l-0.9,2.4h1.8
            L1797.5,388z"/>
          <path className="st18" d="M1805.4,393.1v-7.4h2.3c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.9,0.5c0.2,0.2,0.4,0.4,0.5,0.7
            c0.1,0.3,0.2,0.5,0.2,0.8s-0.1,0.6-0.2,0.9s-0.3,0.5-0.6,0.7c-0.3,0.2-0.5,0.3-0.9,0.4c-0.3,0.1-0.7,0.2-1.1,0.2h-0.8v3.1h-1.5
            V393.1z M1807.5,389c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.4-0.2s0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.5s0-0.4-0.1-0.5
            s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.1h-0.6v2.2h0.6V389z"/>
          <path className="st18" d="M1816.7,392h2.7v1.2h-4.3v-7.4h1.6L1816.7,392L1816.7,392z"/>
          <path className="st18" d="M1828.5,393.1h-4.7v-7.4h4.6v1.2h-3.1v1.9h2.2v1.2h-2.2v2.1h3.2V393.1z"/>
          <path className="st18" d="M1833.6,391.8c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.5,0.1s0.4,0,0.7,0c0.1,0,0.3,0,0.4-0.1
            c0.1,0,0.3-0.1,0.4-0.2s0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
            c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.3-0.6-0.5s-0.3-0.4-0.4-0.6s-0.2-0.5-0.2-0.9
            c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.5,0.5-0.6c0.2-0.2,0.5-0.3,0.7-0.4c0.3-0.1,0.6-0.1,0.9-0.1s0.6,0,0.8,0
            c0.2,0,0.4,0.1,0.6,0.1s0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.1l-0.4,1.1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.5-0.1-0.8-0.1
            c-0.1,0-0.3,0-0.4,0s-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0.1,0.4s0.2,0.2,0.3,0.3
            c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.5s0.3,0.4,0.5,0.6
            c0.1,0.2,0.2,0.5,0.2,0.7c0,0.4-0.1,0.7-0.2,1c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-0.9,0.4s-0.7,0.1-1.1,0.1
            c-0.3,0-0.6,0-0.8-0.1c-0.2,0-0.5-0.1-0.6-0.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l0.4-1
            C1833.4,391.8,1833.5,391.8,1833.6,391.8z"/>
        </g>
        <g id="eI18kZ_3_">
          <g>
            <path className="st30" d="M1736,380.9c0-0.1,0-0.1,0.1-0.2c0.6-2.1,2.9-3,4.8-1.8c0,0,0.1,0,0.1,0.1c0.7-0.8,1.7-1.3,2.8-1.1
              c0.9,0.1,1.6,0.5,2.2,1.2c1.1,1.4,0.8,3.8-1.3,4.8c0.5,1.5,0.3,2.8-1,3.7c-1,0.8-2.2,0.9-3.4,0.3c-1.2-0.7-1.8-1.8-1.6-3.2
              c-0.1,0-0.2,0-0.3-0.1c-1.2-0.3-2-1.1-2.3-2.3c0,0,0-0.1-0.1-0.1C1736,381.8,1736,381.3,1736,380.9z M1740.4,379.8
              c-0.5-0.3-1-0.5-1.7-0.4c-1.1,0.2-1.9,1.1-1.8,2.3c0,1.1,0.9,2,2,2.1c0.5,0,1,0,1.4-0.3c0.3-0.2,0.5-0.1,0.7,0.2
              c0.1,0.3,0.1,0.5-0.2,0.7c-0.3,0.1-0.6,0.3-1,0.4c-0.2,0-0.2,0.1-0.2,0.3c-0.1,0.9,0.4,1.8,1.2,2.2c0.9,0.4,1.7,0.3,2.4-0.3
              c0.7-0.5,1-1.5,0.7-2.3c-0.1-0.4-0.1-0.4-0.5-0.4c-0.3,0-0.5-0.2-0.5-0.5s0.1-0.5,0.5-0.5c0.1,0,0.2,0,0.3,0
              c1.3-0.1,2.1-1.2,1.9-2.5c-0.2-1.2-1.4-2.1-2.6-1.8c-1,0.2-1.7,1-1.7,2.1c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.4-0.5
              C1740.3,380.5,1740.3,380.2,1740.4,379.8z"/>
            <path className="st30" d="M1758.6,382.5c0,0.1-0.1,0.3-0.1,0.4c-0.3,1.2-1.1,1.9-2.3,2.2c-1.2,0.3-2.3,0-3.1-1l-0.1-0.1
              c0,0,0,0-0.1,0c-1,0.6-2,0.8-3.1,0.4c-0.8-0.3-1.4-1-1.7-1.8c-0.6-1.6,0.1-3.7,2.4-4.2c-0.1-1.2,0.3-2.2,1.4-2.9
              c0.7-0.5,1.5-0.6,2.4-0.5c2,0.4,3,2.5,2.3,4.2l0,0c1.1,0.5,1.6,1.3,1.9,2.4v0.1C1758.6,382,1758.6,382.2,1758.6,382.5z
              M1753.7,383.4c0,0,0.1,0.1,0.1,0.2c0.6,0.7,1.5,0.9,2.3,0.7c0.9-0.3,1.4-1,1.5-1.9c0.1-0.8-0.4-1.7-1.2-2.1
              c-0.2-0.1-0.4-0.1-0.5,0.1c-0.2,0.2-0.5,0.2-0.7,0s-0.2-0.5,0-0.7c0.6-0.7,0.8-1.5,0.4-2.3c-0.4-0.9-1.1-1.3-2-1.3
              s-1.6,0.5-2,1.3c-0.4,0.9-0.2,1.7,0.4,2.4c0.2,0.2,0.2,0.5,0,0.7s-0.5,0.2-0.7,0s-0.4-0.5-0.5-0.7c-0.1-0.1-0.2-0.2-0.3-0.1
              c-1,0.2-1.8,1.2-1.7,2.2c0,1,0.9,2,1.9,2.1c1.2,0.2,2.3-0.8,2.5-1.7c0.1-0.3,0.3-0.4,0.6-0.4c0.3,0.1,0.4,0.3,0.3,0.6
              C1754,382.7,1753.8,383,1753.7,383.4z"/>
            <path className="st30" d="M1745.9,368.4c1-1,2.1-1.4,3.5-1c1.2,0.4,2,1.4,2.1,2.7c0.1,1.5-0.6,2.5-1.8,3.2c0.5,1.5,0.3,2.8-1,3.8
              c-1.1,0.8-2.3,0.9-3.5,0.2c-1.2-0.7-1.7-1.8-1.6-3.2c-1.3-0.3-2.2-1-2.5-2.3c-0.2-0.8-0.1-1.6,0.3-2.4
              C1742.2,368,1744.2,367.3,1745.9,368.4z M1741.9,370.9c0,1.2,0.8,2.2,2,2.3c0.5,0,1,0,1.4-0.3c0.3-0.2,0.5-0.1,0.7,0.2
              c0.1,0.3,0.1,0.5-0.2,0.7c-0.2,0.1-0.5,0.2-0.7,0.3c-0.5,0.2-0.5,0.2-0.4,0.7c0,0.1,0,0.1,0,0.2c0.2,1.2,1.4,2.1,2.6,1.8
              c1.2-0.3,2-1.5,1.7-2.7c-0.1-0.4-0.1-0.4-0.5-0.5c-0.3,0-0.4-0.2-0.4-0.5s0.2-0.4,0.4-0.5c0.1,0,0.2,0,0.4,0
              c0.9-0.1,1.7-0.8,1.9-1.7c0.2-0.9-0.2-1.8-0.9-2.3c-0.8-0.5-1.8-0.5-2.5,0.1c-0.6,0.4-0.9,1-0.9,1.7c0,0.4-0.2,0.5-0.5,0.5
              s-0.5-0.2-0.4-0.6c0-0.3,0.1-0.6,0.1-0.9c0-0.1,0-0.2-0.1-0.2c-0.7-0.5-1.5-0.5-2.3-0.1C1742.4,369.5,1742,370.1,1741.9,370.9z"
              />
          </g>
        </g>
      </g>
      <g id="cowtown_fresh_fare">
        
          <rect x="1745.8" y="1747.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -730.1125 1760.5027)" className="st4" width="28.5" height="28.5"/>
        
          <rect x="1743.3" y="1746.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -730.7126 1759.5664)" className="st43" width="30.7" height="30.7"/>
        
          <rect x="1746.6" y="1746.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -729.7462 1761.8905)" className="st43" width="30.7" height="30.7"/>
        <g>
          <path className="st18" d="M1802.1,1753.6c-0.4,0.2-1,0.5-1.7,0.7s-1.6,0.3-2.8,0.3c-0.9,0-1.8-0.2-2.7-0.5c-0.8-0.3-1.5-0.8-2.1-1.4
            s-1.1-1.3-1.4-2.1c-0.3-0.8-0.5-1.7-0.5-2.7s0.2-1.9,0.5-2.7c0.3-0.8,0.8-1.5,1.4-2.1s1.3-1,2.1-1.4c0.8-0.3,1.7-0.5,2.7-0.5
            c0.7,0,1.2,0,1.7,0.1s0.9,0.2,1.3,0.2c0.4,0.1,0.7,0.2,0.9,0.3c0.2,0.1,0.4,0.2,0.6,0.3l-0.8,1.8c-0.1-0.1-0.2-0.1-0.4-0.2
            c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3-0.1-0.6-0.1-1-0.2s-0.8-0.1-1.3-0.1s-1,0.1-1.5,0.3c-0.5,0.2-0.9,0.5-1.3,0.9s-0.7,0.9-1,1.4
            c-0.2,0.6-0.4,1.2-0.4,1.9c0,0.7,0.1,1.3,0.4,1.8c0.2,0.6,0.6,1,1,1.4s0.8,0.7,1.3,1c0.5,0.2,1,0.3,1.5,0.3c0.9,0,1.7-0.1,2.2-0.2
            c0.5-0.2,1-0.3,1.3-0.5L1802.1,1753.6z"/>
          <path className="st18" d="M1812.9,1741.3c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4s1.1,1.3,1.4,2.1c0.3,0.8,0.5,1.7,0.5,2.7
            s-0.2,1.9-0.6,2.7c-0.4,0.8-0.9,1.5-1.5,2.1s-1.3,1-2.1,1.3c-0.8,0.3-1.7,0.5-2.6,0.5s-1.8-0.2-2.6-0.5c-0.8-0.3-1.5-0.7-2.1-1.3
            c-0.6-0.6-1.1-1.3-1.4-2.1c-0.4-0.8-0.5-1.7-0.5-2.8c0-0.9,0.2-1.8,0.5-2.6c0.4-0.8,0.8-1.5,1.4-2.1s1.3-1.1,2.1-1.4
            C1811.1,1741.4,1812,1741.3,1812.9,1741.3z M1812.9,1743.3c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.2-0.9,0.6-1.2,1s-0.6,0.9-0.8,1.5
            c-0.2,0.6-0.3,1.2-0.3,1.8c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.4,1,0.8,1.5c0.3,0.4,0.7,0.8,1.2,1s1,0.4,1.6,0.4s1.1-0.1,1.6-0.4
            c0.5-0.2,0.9-0.6,1.2-1s0.6-0.9,0.8-1.5c0.2-0.6,0.3-1.2,0.3-1.8c0-0.6-0.1-1.2-0.3-1.8c-0.2-0.6-0.4-1.1-0.8-1.5
            c-0.3-0.4-0.7-0.8-1.2-1C1814.1,1743.4,1813.5,1743.3,1812.9,1743.3z"/>
          <path className="st18" d="M1828.5,1754.4h-1.7l-4.2-12.9h2.7l2.8,8.7l1.8-5.1l-1.2-3.7h2.7l2.6,8.5l2.9-8.5h2.4l-4.7,12.9h-1.7
            l-2.1-6.4L1828.5,1754.4z"/>
          <path className="st18" d="M1842.2,1741.5h10.9v2h-4.1v10.8h-2.7v-10.8h-4.1L1842.2,1741.5L1842.2,1741.5z"/>
          <path className="st18" d="M1862.2,1741.3c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2.1,1.4s1.1,1.3,1.4,2.1c0.3,0.8,0.5,1.7,0.5,2.7
            s-0.2,1.9-0.6,2.7c-0.4,0.8-0.9,1.5-1.5,2.1s-1.3,1-2.1,1.3c-0.8,0.3-1.7,0.5-2.6,0.5s-1.8-0.2-2.6-0.5c-0.8-0.3-1.5-0.7-2.1-1.3
            c-0.6-0.6-1.1-1.3-1.4-2.1c-0.4-0.8-0.5-1.7-0.5-2.8c0-0.9,0.2-1.8,0.5-2.6c0.4-0.8,0.8-1.5,1.4-2.1s1.3-1.1,2.1-1.4
            C1860.3,1741.4,1861.2,1741.3,1862.2,1741.3z M1862.2,1743.3c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.2-0.9,0.6-1.2,1s-0.6,0.9-0.8,1.5
            c-0.2,0.6-0.3,1.2-0.3,1.8c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.4,1,0.8,1.5c0.3,0.4,0.7,0.8,1.2,1s1,0.4,1.6,0.4s1.1-0.1,1.6-0.4
            c0.5-0.2,0.9-0.6,1.2-1c0.3-0.4,0.6-0.9,0.8-1.5c0.2-0.6,0.3-1.2,0.3-1.8c0-0.6-0.1-1.2-0.3-1.8c-0.2-0.6-0.4-1.1-0.8-1.5
            c-0.3-0.4-0.7-0.8-1.2-1C1863.3,1743.4,1862.8,1743.3,1862.2,1743.3z"/>
          <path className="st18" d="M1877.8,1754.4h-1.7l-4.2-12.9h2.7l2.8,8.7l1.8-5.1l-1.2-3.7h2.7l2.6,8.5l2.9-8.5h2.4l-4.7,12.9h-1.7
            l-2.1-6.4L1877.8,1754.4z"/>
          <path className="st18" d="M1904,1741.5v12.8h-2.6l-6.6-8.5v8.5h-2.3v-12.8h2.4l6.8,8.7v-8.7H1904z"/>
          <path className="st18" d="M1791.2,1770.5v-11.7h7v1.8h-4.5v2.9h3.2v1.8h-3.2v5.1L1791.2,1770.5L1791.2,1770.5z"/>
          <path className="st18" d="M1808.8,1770.7l-3.2-5.2h-1.1v5h-2.5v-11.7h3.4c0.8,0,1.5,0.1,2.1,0.2c0.6,0.2,1.1,0.4,1.4,0.7
            c0.4,0.3,0.7,0.6,0.9,1c0.2,0.4,0.3,0.8,0.3,1.3c0,0.4-0.1,0.8-0.2,1.1c-0.2,0.3-0.3,0.6-0.6,0.9c-0.2,0.2-0.5,0.4-0.7,0.6
            c-0.2,0.1-0.4,0.3-0.6,0.3l3.6,5.6L1808.8,1770.7z M1805.5,1763.8c0.7,0,1.3-0.2,1.6-0.5c0.4-0.3,0.5-0.7,0.5-1.2
            c0-0.4-0.2-0.8-0.5-1.2c-0.3-0.3-0.9-0.5-1.6-0.5h-1v3.3L1805.5,1763.8L1805.5,1763.8z"/>
          <path className="st18" d="M1822.8,1770.5h-7.5v-11.7h7.3v1.8h-4.8v2.9h3.5v1.8h-3.5v3.3h5.1L1822.8,1770.5L1822.8,1770.5z"/>
          <path className="st18" d="M1827.5,1768.4c0.2,0.1,0.4,0.1,0.6,0.2s0.5,0.1,0.8,0.2c0.3,0,0.7,0.1,1.1,0.1c0.2,0,0.5,0,0.7-0.1
            s0.4-0.1,0.6-0.2s0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.4-0.2-0.6s-0.3-0.4-0.5-0.6s-0.5-0.3-0.7-0.5
            c-0.3-0.2-0.6-0.4-0.9-0.6s-0.6-0.4-0.9-0.6s-0.6-0.4-0.9-0.7c-0.3-0.3-0.5-0.6-0.7-1s-0.3-0.8-0.3-1.4c0-0.5,0.1-0.9,0.3-1.3
            c0.2-0.4,0.5-0.7,0.8-1s0.7-0.5,1.2-0.7c0.4-0.2,0.9-0.2,1.4-0.2s0.9,0,1.2,0.1c0.4,0.1,0.7,0.1,0.9,0.2c0.3,0.1,0.5,0.1,0.7,0.2
            s0.4,0.2,0.5,0.2l-0.7,1.7c-0.2-0.1-0.5-0.2-0.9-0.4c-0.4-0.1-0.8-0.2-1.2-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2
            s-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.5s0.1,0.5,0.2,0.7s0.3,0.4,0.5,0.5c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.2,0.6,0.3,0.8,0.5
            c0.3,0.2,0.6,0.4,0.9,0.6s0.6,0.5,0.9,0.8s0.5,0.6,0.7,1s0.3,0.7,0.3,1.2c0,0.6-0.1,1.1-0.4,1.5c-0.2,0.4-0.6,0.8-1,1.1
            c-0.4,0.3-0.9,0.5-1.4,0.7s-1.1,0.2-1.7,0.2c-0.5,0-0.9,0-1.3-0.1s-0.7-0.1-1-0.2s-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.2-0.5-0.2
            l0.7-1.6C1827.2,1768.3,1827.4,1768.3,1827.5,1768.4z"/>
          <path className="st18" d="M1846.2,1765.4h-4.9v5.1h-2.5v-11.7h2.5v4.8h4.9v-4.8h2.5v11.7h-2.5V1765.4z"/>
          <path className="st18" d="M1860.4,1770.5v-11.7h7v1.8h-4.5v2.9h3.2v1.8h-3.2v5.1L1860.4,1770.5L1860.4,1770.5z"/>
          <path className="st18" d="M1870.9,1770.5h-2.2l4.8-11.7h1.7l4.3,11.7h-2.6l-0.9-2.6h-4.1L1870.9,1770.5z M1874,1762.4l-1.5,3.9h2.9
            L1874,1762.4z"/>
          <path className="st18" d="M1890,1770.7l-3.2-5.2h-1.1v5h-2.5v-11.7h3.4c0.8,0,1.5,0.1,2.1,0.2c0.6,0.2,1.1,0.4,1.4,0.7
            c0.4,0.3,0.7,0.6,0.9,1c0.2,0.4,0.3,0.8,0.3,1.3c0,0.4-0.1,0.8-0.2,1.1c-0.2,0.3-0.3,0.6-0.6,0.9c-0.2,0.2-0.5,0.4-0.7,0.6
            c-0.2,0.1-0.4,0.3-0.6,0.3l3.6,5.6L1890,1770.7z M1886.8,1763.8c0.7,0,1.3-0.2,1.6-0.5c0.4-0.3,0.5-0.7,0.5-1.2
            c0-0.4-0.2-0.8-0.5-1.2c-0.3-0.3-0.9-0.5-1.6-0.5h-1v3.3L1886.8,1763.8L1886.8,1763.8z"/>
          <path className="st18" d="M1904,1770.5h-7.5v-11.7h7.3v1.8h-4.8v2.9h3.5v1.8h-3.5v3.3h5.1L1904,1770.5L1904,1770.5z"/>
        </g>
        <g>
          <g>
            <rect x="1754.4" y="1753" className="st44" width="0.2" height="0.7"/>
            <rect x="1755.8" y="1753" className="st44" width="0.2" height="0.7"/>
            <rect x="1757.1" y="1753" className="st44" width="0.2" height="0.7"/>
            <rect x="1758.5" y="1753" className="st44" width="0.2" height="0.7"/>
          </g>
          <g>
            <path className="st44" d="M1759.3,1767l-0.5,1.9c0.1,0,0.2,0,0.3,0l0.5-1.9H1759.3z"/>
            <path className="st44" d="M1760.6,1767.3l0.4-1.7c-0.1,0-0.2-0.1-0.2-0.1l-0.4,1.7L1760.6,1767.3z"/>
            <path className="st44" d="M1761.7,1767.6l-0.3,1.4c0.1,0,0.2,0,0.3,0l0.3-1.3L1761.7,1767.6z"/>
            <path className="st44" d="M1764,1768.2l-0.2,0.8c0.1,0,0.2,0,0.3,0l0.2-0.8H1764z"/>
          </g>
          <path className="st45" d="M1769.3,1762.8c-0.8-0.3-1.7-0.2-2.4,0.2c0-0.2,0-0.4-0.1-0.6c0.7,0,1.2-0.2,1.7-0.6
            c0.7-0.7,0.7-1.6,0.7-1.7v-0.3h-0.3c-1.6,0.1-2.2,1-2.5,1.6c-0.5-0.8-1.3-1-1.3-1l-0.2,0.6c0.1,0,1.2,0.4,1.3,2
            c-0.6-0.4-1.3-0.4-2-0.2c-1.1,0.4-1.7,1.5-1.7,3.1c-0.2-0.1-0.3-0.2-0.5-0.3v-8l-1.5-2.6v-2.5h-7.9v2.4l-1.6,2.7v11.9h6.3
            c0.2,0.1,0.5,0.1,0.8,0.1l0,0c0,0,2.5,0.1,4.9,0.1c0.5,0,1,0,1.5,0c3.1,0,3.6-0.1,3.7-0.3c0.1,0,0.2-0.1,0.2-0.1
            c1-0.4,1.7-1.5,2.1-2.8C1771.2,1763.9,1769.9,1763.1,1769.3,1762.8z M1768.6,1760.5c-0.1,0.3-0.2,0.6-0.5,0.9s-0.7,0.4-1.2,0.4
            C1767.1,1761.4,1767.4,1760.7,1768.6,1760.5z M1761.3,1757.3h-2.4l1.1-2L1761.3,1757.3z M1761.5,1757.9v7.4c-1.2-0.6-2-1-2.1-1
            l0,0c-0.2-0.1-0.5-0.2-0.7-0.2v-6.1h2.8L1761.5,1757.9L1761.5,1757.9z M1753.3,1753h6.7v1.6h-6.7V1753z M1753.1,1755.2h6.3
            l-1.2,2.1h-6.3L1753.1,1755.2z M1756.2,1764.1c-0.5-0.8-1.4-1-1.5-1.1l-0.3-0.1v0.3c-0.2,1,0.1,1.7,0.5,2.2l-1.6-0.5l-0.2,0.6
            l2.2,0.7c-0.7-0.1-1.7,0.1-2.6,1.4l-0.2,0.3l0.3,0.1c0,0,0.5,0.3,1.1,0.3c0.2,0,0.4,0,0.7-0.1c0.6-0.2,1.1-0.7,1.4-1.4
            c-0.1,0.5,0,1,0.2,1.5c0.1,0.2,0.2,0.4,0.3,0.5h-4.8v-10.9h6.4v6.2c-0.4,0.1-0.8,0.3-1.1,0.6c-0.2,0.2-0.4,0.4-0.5,0.7
            C1756.5,1764.9,1756.5,1764.5,1756.2,1764.1z M1754.9,1763.7c0.2,0.1,0.6,0.3,0.8,0.7c0.2,0.3,0.3,0.7,0.2,1.2
            C1755.5,1765.4,1754.9,1764.9,1754.9,1763.7z M1755.4,1766.8c-0.2,0.4-0.6,0.7-0.9,0.8c-0.4,0.1-0.8,0.1-1,0
            C1754.1,1766.8,1754.9,1766.8,1755.4,1766.8z M1767.3,1769c-0.1,0-0.2,0-0.2,0s0,0-0.1,0s-0.2,0-0.3,0l0,0c-0.1,0-0.2,0-0.3,0l0,0
            c-0.1,0-0.2,0-0.3,0l0,0c-0.2,0-0.5,0-0.8,0l0,0c-0.3,0-0.6,0-0.9,0l0,0c-0.7,0-1.4,0-2.2,0c-0.7,0-1.4,0-2,0l0,0
            c-0.2,0-0.3,0-0.5,0l0,0c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3,0-0.5,0l0,0c-0.1,0-0.2,0-0.2,0h-0.1h-0.1h-0.1h-0.1h-0.1
            c-0.3-0.1-0.6-0.4-0.7-0.7c-0.4-0.9,0-2.2,0.7-3c0.2-0.2,0.4-0.3,0.7-0.4c0.2-0.1,0.4-0.1,0.6-0.1s0.3,0.1,0.5,0.1
            c0.7,0.4,1.6,0.8,2.4,1.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.5,0.3,0.7,0.4s0.3,0.2,0.5,0.2c1.5,0.8,2.8,1.5,3.7,1.9h0.1
            C1767.1,1768.9,1767.2,1768.9,1767.3,1769L1767.3,1769z M1770,1766.3c-0.3,1.1-0.9,2-1.7,2.4c-0.1,0-0.1,0.1-0.2,0.1
            c-0.4-0.3-1.5-0.9-4.2-2.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-2.1,0.8-2.7,1.2-2.8c0.7-0.3,1.6,0,1.9,0.5l0.3,0.5l0.3-0.5
            c0.3-0.5,1.3-0.8,2.2-0.4C1770,1763.8,1770.4,1764.8,1770,1766.3z"/>
        </g>
        <g>
          <g>
            <path className="st18" d="M1822.2,1776.9v1.1h-1.6v5h-1.4v-5h-1.6v-1.1H1822.2z"/>
            <path className="st18" d="M1825.5,1782v1h-0.1l-0.3,0.1c-0.2,0.1-0.6,0.1-1,0.1c-1.6,0-2.5-0.8-2.5-2.4c0-1.4,0.8-2.2,2.2-2.2
              c0.6,0,1.1,0.2,1.4,0.6c0.4,0.4,0.5,0.8,0.5,1.6v0.4h-2.8c0,0.6,0.5,0.9,1.4,0.9C1824.8,1782.2,1825,1782.2,1825.5,1782z
              M1822.8,1780.5h1.5v-0.2c0-0.4-0.3-0.7-0.7-0.7c-0.5,0-0.8,0.3-0.8,0.7V1780.5z"/>
            <path className="st18" d="M1825.8,1778.7h1.5l0.8,1.3l0.8-1.3h1.5l-1.6,2.2l1.6,2.2h-1.5l-0.8-1.3l-0.8,1.3h-1.5l1.6-2.2
              L1825.8,1778.7z"/>
            <path className="st18" d="M1831,1779c0.5-0.2,1.1-0.4,1.6-0.4s0.9,0.1,1.2,0.2c0.4,0.2,0.6,0.5,0.6,1v2.1c0,0.3,0.1,0.4,0.3,0.7
              l-0.8,0.7c0,0-0.1,0-0.1-0.1c-0.2-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1c-0.5,0.2-0.6,0.2-1,0.2c-0.8,0-1.3-0.5-1.3-1.3
              c0-0.9,0.7-1.4,1.8-1.4h0.4c0.3,0,0.5-0.1,0.5-0.4c0-0.4-0.2-0.5-0.7-0.5s-1.2,0.1-1.5,0.3L1831,1779L1831,1779z M1833.2,1781.3
              h-0.6c-0.4,0-0.6,0.2-0.6,0.5s0.2,0.4,0.5,0.4h0.2c0.3,0,0.5-0.2,0.5-0.5V1781.3z"/>
            <path className="st18" d="M1835.2,1781.9c0.5,0.2,1,0.4,1.5,0.4c0.4,0,0.6-0.1,0.6-0.4c0-0.2-0.1-0.3-0.4-0.5l-0.6-0.3
              c-0.8-0.3-1-0.7-1-1.3c0-0.9,0.7-1.3,1.9-1.3c0.6,0,0.9,0.1,1.2,0.2v0.9c-0.3-0.2-0.7-0.3-1.1-0.3s-0.6,0.1-0.6,0.4
              c0,0.2,0.1,0.3,0.4,0.4l0.8,0.3c0.6,0.2,0.8,0.6,0.8,1.2c0,0.9-0.7,1.4-1.8,1.4c-0.6,0-1.2-0.1-1.6-0.3v-0.8L1835.2,1781.9
              L1835.2,1781.9z"/>
            <path className="st18" d="M1841.4,1776.9h1.4v2.4h2.6v-2.4h1.5v6.2h-1.4v-2.7h-2.6v2.7h-1.4L1841.4,1776.9L1841.4,1776.9z"/>
            <path className="st18" d="M1851.3,1782v1h-0.1l-0.3,0.1c-0.2,0.1-0.6,0.1-1,0.1c-1.6,0-2.5-0.8-2.5-2.4c0-1.4,0.8-2.2,2.2-2.2
              c0.6,0,1.1,0.2,1.4,0.6c0.4,0.4,0.5,0.8,0.5,1.6v0.4h-2.8c0,0.6,0.5,0.9,1.4,0.9C1850.6,1782.2,1850.8,1782.2,1851.3,1782z
              M1848.7,1780.5h1.5v-0.2c0-0.4-0.3-0.7-0.7-0.7c-0.5,0-0.8,0.3-0.8,0.7V1780.5z"/>
            <path className="st18" d="M1852.3,1779c0.5-0.2,1.1-0.4,1.6-0.4s0.9,0.1,1.2,0.2c0.4,0.2,0.6,0.5,0.6,1v2.1c0,0.3,0.1,0.4,0.3,0.7
              l-0.8,0.7c0,0-0.1,0-0.1-0.1c-0.2-0.2-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1c-0.5,0.2-0.6,0.2-1,0.2c-0.8,0-1.3-0.5-1.3-1.3
              c0-0.9,0.7-1.4,1.8-1.4h0.4c0.3,0,0.5-0.1,0.5-0.4c0-0.4-0.2-0.5-0.7-0.5s-1.2,0.1-1.5,0.3L1852.3,1779L1852.3,1779z
              M1854.5,1781.3h-0.6c-0.4,0-0.6,0.2-0.6,0.5s0.2,0.4,0.5,0.4h0.2c0.3,0,0.5-0.2,0.5-0.5V1781.3z"/>
            <path className="st18" d="M1857.8,1776.9v6.2h-1.3v-6.2H1857.8z"/>
            <path className="st18" d="M1858.3,1778.7h0.6v-1.4h1.3v1.4h1v0.8h-1v2.2c0,0.4,0.1,0.5,0.5,0.5h0.5v1h-1.1c-0.8,0-1.2-0.4-1.2-1.1
              v-2.5h-0.6L1858.3,1778.7L1858.3,1778.7z"/>
            <path className="st18" d="M1863,1779.2c0.5-0.4,0.8-0.6,1.4-0.6c0.9,0,1.4,0.5,1.4,1.3v3.2h-1.3v-2.9c0-0.4-0.2-0.6-0.6-0.6
              c-0.5,0-0.8,0.3-0.8,0.8v2.6h-1.3v-6.2h1.3L1863,1779.2L1863,1779.2z"/>
          </g>
          <path className="st18" d="M1808.2,1784.4L1808.2,1784.4L1808.2,1784.4c-0.6-0.3-1.1-0.6-1.5-1c-1-1-1.4-2.5-1.1-3.5
            c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.2,0.6-0.4,1-0.4h0.8h0.1v-0.9h-0.9l0,0l0,0c-1.1,0-1.8,0.6-2.2,1.5c-0.5,1.3,0,3.2,1.3,4.5
            c0.8,0.8,1.9,1.3,2.9,1.4h0.4l-0.3-0.2C1808.7,1785.2,1808.4,1784.8,1808.2,1784.4z M1805.8,1778.1L1805.8,1778.1L1805.8,1778.1
            c0.3-0.6,0.6-1.1,1-1.5c1-1,2.5-1.4,3.5-1.1c0.5,0.2,0.8,0.7,0.9,1.3v0.8v0.1h0.9v-0.9c-0.1-1.1-0.6-1.8-1.5-2.2
            c-1.3-0.5-3.2,0-4.5,1.3c-0.8,0.8-1.3,1.9-1.5,2.9v0.4l0.2-0.3C1805.1,1778.6,1805.4,1778.3,1805.8,1778.1z M1815.5,1781.2
            c-0.2,0.3-0.6,0.6-1,0.8l0,0l0,0c-0.2,0.5-0.5,1-0.9,1.4c-1,1-2.5,1.4-3.5,1.1c-0.5-0.2-0.8-0.7-0.9-1.3v-0.8v-0.1h-0.9v0.1
            c0,0.9,0,0.9,0,0.9c0,1,0.6,1.8,1.5,2.2c1.3,0.5,3.2,0,4.5-1.3c0.8-0.8,1.3-1.9,1.5-2.9v-0.4L1815.5,1781.2z M1812.1,1775.6
            L1812.1,1775.6L1812.1,1775.6c0.6,0.3,1.1,0.6,1.5,1c1,1,1.4,2.5,1.1,3.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.2-0.6,0.4-1,0.4l0,0
            h-0.8h-0.1v0.1v0.8v0.1h0.1h0.9c1.1,0,1.8-0.6,2.2-1.5c0.5-1.3,0-3.2-1.3-4.5c-0.8-0.8-1.9-1.3-2.9-1.5h-0.4l0.3,0.2
            C1811.7,1774.9,1811.9,1775.3,1812.1,1775.6z"/>
          <path className="st18" d="M1809.6,1778.2v0.9h3.7c0.4,0,0.7,0.1,1,0.3l0.1,0.1v-0.2c0-0.3-0.1-0.6-0.2-1l0,0l0,0
            c-0.3-0.1-0.6-0.2-0.9-0.2C1813.3,1778.2,1809.7,1778.2,1809.6,1778.2L1809.6,1778.2z"/>
          <path className="st18" d="M1811.1,1783.5c0,0.2-0.1,0.3-0.2,0.5c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.1h0.2c0.3,0,0.6-0.1,1-0.2l0,0l0,0
            c0.1-0.3,0.2-0.6,0.2-0.9v-3.7v-0.1h-0.9v0.1v1.5v0.3l0,0v1.8C1811.1,1783.3,1811.1,1783.4,1811.1,1783.5L1811.1,1783.5z"/>
          <path className="st18" d="M1809.2,1780.5c0-2.9,0-3.6,0-3.6c0-0.4,0.1-0.7,0.3-1l0.1-0.1h-0.2c-0.3,0-0.6,0-1,0.2l0,0l0,0
            c-0.1,0.3-0.2,0.6-0.2,0.9v3.7v0.1L1809.2,1780.5L1809.2,1780.5z"/>
          <path className="st18" d="M1806.1,1781.7L1806.1,1781.7c0.3,0.1,0.6,0.2,0.9,0.2h3.7h0.1v-0.1v-0.7v-0.1h-0.1h-3.6
            c-0.4,0-0.7-0.1-1-0.3l-0.1-0.1v0.2C1805.9,1781,1805.9,1781.4,1806.1,1781.7L1806.1,1781.7z"/>
          <path className="st18" d="M1866.4,1778.4c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7
            S1866.8,1778.4,1866.4,1778.4L1866.4,1778.4z M1866.4,1777.1c-0.3,0-0.5,0.2-0.5,0.6c0,0.3,0.2,0.6,0.5,0.6s0.5-0.2,0.5-0.6
            C1866.9,1777.3,1866.7,1777.1,1866.4,1777.1L1866.4,1777.1z M1866.3,1778.1L1866.3,1778.1l-0.1-0.8h0.3c0.2,0,0.3,0.1,0.3,0.2
            s-0.1,0.2-0.2,0.2l0.2,0.3h-0.1l-0.2-0.3h-0.1v0.4L1866.3,1778.1L1866.3,1778.1z M1866.4,1777.6c0.1,0,0.2,0,0.2-0.1
            s-0.1-0.1-0.2-0.1h-0.2v0.2H1866.4L1866.4,1777.6z"/>
        </g>
        <path className="st18" d="M1791.6,1778h1.7c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.3,0.4
          c0.1,0.1,0.1,0.3,0.1,0.5s0,0.3-0.1,0.4s-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c0.1,0,0.3,0,0.4,0.1
          s0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.6s-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.3-0.6,0.3
          c-0.2,0.1-0.5,0.1-0.7,0.1h-2.1v-5.1L1791.6,1778L1791.6,1778z M1793.1,1780.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.1
          c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1
          c-0.1,0-0.2,0-0.3,0h-0.4v1.5h0.4V1780.2z M1794.1,1782.2c0.2-0.1,0.3-0.3,0.3-0.6c0-0.3-0.1-0.5-0.3-0.6s-0.4-0.2-0.7-0.2h-0.7
          v1.5h0.7C1793.7,1782.4,1793.9,1782.4,1794.1,1782.2z"/>
        <path className="st18" d="M1801.1,1778l-1.7,2.7v2.5h-1.1v-2.5l-1.6-2.7h1.2l1.1,1.9l1.1-1.9H1801.1z"/>
      </g>
      <g id="panther_city_prime_1_">
        
          <rect x="355" y="1007.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -615.9447 563.9492)" className="st4" width="35.6" height="35.6"/>
        
          <rect x="351.2" y="1005.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -616.3471 562.6301)" className="st46" width="39.6" height="39.6"/>
        
          <rect x="355.5" y="1005.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -615.0876 565.6761)" className="st46" width="39.6" height="39.6"/>
        <g>
          <path className="st18" d="M415.6,1022.8v-18.7h5.9c1.1,0,2.1,0.1,3,0.4c0.9,0.3,1.6,0.7,2.2,1.1c0.6,0.5,1,1.1,1.3,1.7
            c0.3,0.7,0.4,1.4,0.4,2.1c0,0.8-0.2,1.6-0.6,2.3s-0.9,1.3-1.5,1.7c-0.6,0.5-1.4,0.9-2.2,1.1c-0.8,0.3-1.7,0.4-2.7,0.4h-1.9v7.8
            h-3.9L415.6,1022.8L415.6,1022.8z M420.9,1012.4c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.3,1.1-0.5c0.3-0.2,0.6-0.5,0.8-0.9
            c0.2-0.4,0.3-0.8,0.3-1.3s-0.1-0.9-0.3-1.2c-0.2-0.3-0.5-0.6-0.8-0.9c-0.3-0.2-0.7-0.4-1.1-0.5s-0.8-0.2-1.2-0.2h-1.4v5.7
            L420.9,1012.4L420.9,1012.4z"/>
          <path className="st18" d="M434.3,1022.8h-3.6l7.6-18.7h2.7l6.9,18.7h-4.1l-1.5-4.2h-6.5L434.3,1022.8z M439.2,1009.9l-2.4,6.2h4.6
            L439.2,1009.9z"/>
          <path className="st18" d="M470.8,1004.2v18.7H467l-9.7-12.4v12.4H454v-18.7h3.5l9.9,12.7v-12.7H470.8z"/>
          <path className="st18" d="M476.8,1004.2h15.9v2.9h-6v15.8h-3.9v-15.8h-6V1004.2z"/>
          <path className="st18" d="M510.6,1014.7h-7.8v8.1h-3.9v-18.7h3.9v7.6h7.8v-7.6h3.9v18.7h-3.9V1014.7z"/>
          <path className="st18" d="M534.2,1022.8h-12v-18.7h11.7v2.9h-7.7v4.7h5.7v2.9h-5.7v5.2h8.1L534.2,1022.8L534.2,1022.8z"/>
          <path className="st18" d="M551.4,1023.1l-5-8.3h-1.8v8h-3.9v-18.7h5.4c1.3,0,2.4,0.1,3.3,0.4c0.9,0.3,1.7,0.6,2.3,1.1s1.1,1,1.4,1.6
            c0.3,0.6,0.4,1.3,0.4,2s-0.1,1.3-0.4,1.8c-0.3,0.5-0.6,1-0.9,1.4c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.7,0.4-0.9,0.5l5.7,9
            L551.4,1023.1z M546.3,1012.1c1.1,0,2-0.3,2.6-0.8s0.8-1.2,0.8-1.9s-0.3-1.3-0.8-1.9c-0.5-0.5-1.4-0.8-2.6-0.8h-1.6v5.3h1.6
            V1012.1z"/>
          <path className="st18" d="M431.4,1047.6c-0.7,0.4-1.5,0.7-2.5,1s-2.4,0.4-4.2,0.4c-1.4,0-2.7-0.2-3.9-0.7c-1.2-0.5-2.2-1.1-3.1-2
            c-0.9-0.8-1.6-1.9-2.1-3c-0.5-1.2-0.8-2.5-0.8-3.9s0.3-2.7,0.8-3.9c0.5-1.2,1.2-2.2,2.1-3.1s1.9-1.5,3.1-2s2.5-0.7,3.9-0.7
            c1,0,1.8,0.1,2.5,0.2s1.3,0.2,1.9,0.4c0.5,0.1,1,0.3,1.3,0.4c0.3,0.2,0.6,0.3,0.8,0.4l-1.1,2.7c-0.1-0.1-0.4-0.2-0.6-0.3
            c-0.3-0.1-0.6-0.2-1-0.3c-0.4-0.1-0.9-0.2-1.4-0.3c-0.5-0.1-1.2-0.1-1.9-0.1c-0.7,0-1.5,0.2-2.2,0.5s-1.4,0.8-2,1.4
            s-1,1.3-1.4,2.1c-0.4,0.8-0.5,1.7-0.5,2.7s0.2,1.9,0.5,2.7c0.4,0.8,0.8,1.5,1.4,2.1s1.2,1.1,2,1.4c0.7,0.3,1.5,0.5,2.2,0.5
            c1.4,0,2.4-0.1,3.2-0.4c0.8-0.2,1.4-0.5,1.9-0.7L431.4,1047.6z"/>
          <path className="st18" d="M438.1,1048.6V1030h3.9v18.7L438.1,1048.6L438.1,1048.6z"/>
          <path className="st18" d="M448.1,1030H464v2.9h-6v15.8h-3.9v-15.8h-6V1030z"/>
          <path className="st18" d="M484.3,1030l-6,9.6v9h-3.9v-9l-5.9-9.6h4.3l4,6.8l4-6.8H484.3z"/>
        </g>
        <g>
          <path className="st18" d="M495.6,1045.3v-9.3h3c0.6,0,1.1,0.1,1.5,0.2s0.8,0.3,1.1,0.6c0.3,0.2,0.5,0.5,0.7,0.9c0.1,0.3,0.2,0.7,0.2,1
            c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.4,0.6-0.8,0.9c-0.3,0.2-0.7,0.4-1.1,0.6c-0.4,0.1-0.9,0.2-1.3,0.2h-1v3.9L495.6,1045.3
            L495.6,1045.3z M498.3,1040.1c0.2,0,0.4,0,0.6-0.1s0.4-0.1,0.5-0.3c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.7
            c0-0.2,0-0.5-0.1-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.6-0.1h-0.7v2.8L498.3,1040.1
            L498.3,1040.1z"/>
          <path className="st18" d="M513.3,1045.4l-2.5-4.2h-0.9v4h-2v-9.3h2.7c0.6,0,1.2,0.1,1.7,0.2c0.5,0.1,0.9,0.3,1.2,0.5s0.5,0.5,0.7,0.8
            c0.1,0.3,0.2,0.7,0.2,1s-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.7s-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.2-0.5,0.3l2.8,4.5L513.3,1045.4z
            M510.7,1040c0.6,0,1-0.1,1.3-0.4s0.4-0.6,0.4-0.9c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.3-0.7-0.4-1.3-0.4h-0.8v2.7L510.7,1040
            L510.7,1040z"/>
          <path className="st18" d="M520.9,1045.3v-9.3h2v9.3H520.9z"/>
          <path className="st18" d="M538.8,1045.3h-1.9l-0.1-5.7l-2.4,5.7h-1.3l-2.3-5.7l-0.2,5.7H529l0.4-9.3h1.8l2.7,6.8l2.9-6.8h1.7
            L538.8,1045.3z"/>
          <path className="st18" d="M551,1045.3h-6v-9.3h5.8v1.5H547v2.3h2.8v1.5H547v2.6h4V1045.3z"/>
        </g>
        <g id="lplcE0_2_">
          <g>
            <path className="st47" d="M387.5,1017.7c0.3,2.2-1,4.9-2.1,6.4c-1.4,1.9-3.4,2.7-5.7,2.8c-0.7,0-1.4,0.1-1.6,0.9
              c-0.3,0.8,0.1,1.4,0.8,1.8c0.6,0.3,0.4,0.8,0.2,1.2c-0.8,1.9-1.5,3.8-2.2,5.7c-0.3,0.9-0.2,1.8-0.3,2.7c-0.1,1.9-1.3,2.7-3.1,2.1
              c-0.3-0.1-0.6,0-0.9,0.1c-1.4,0.5-2.9-0.3-2.8-1.8c0.1-3.2-1.2-6-2.5-8.9c-0.3-0.7-0.2-1,0.4-1.5c0.4-0.3,0.6-1,0.6-1.5
              c0-0.3-0.7-0.9-1.1-0.9c-5.6,0.1-8-4.3-8.2-9c0-0.6-0.4-1.5,0.5-1.9c0.9-0.3,0.9,0.7,1.4,1.2c1.1,1.1,2.1,2.3,3.2,3.4
              c0.4,0.4,1.1,0.5,1.7,0.7c0.8,0.2,1.4,0.1,1.9-0.7c0.7-1.2,2.1-1.6,3.4-1.7c1.8-0.1,3.7,0.1,5.5,0.3c1,0.1,1.8,0.8,2.4,1.7
              c0.1,0.2,0.5,0.4,0.8,0.4c1.4,0,2.5-0.5,3.4-1.5c0.5-0.5,2.9-4.4,3.7-3.5C387.3,1016.7,387.5,1017.3,387.5,1017.7z M369.1,1026.2
              c1.1,1.6,1.1,2.4,0,4.1c-0.1,0.2-0.1,0.6,0.1,0.9c1.1,2.7,2.3,5.3,2.2,8.3c0,0.2,0.3,0.5,0.5,0.8c0.2-0.2,0.5-0.3,0.7-0.5
              c0.3-0.2,0.5-0.5,0.7-0.7c0.2,0.2,0.5,0.5,0.7,0.7s0.4,0.4,0.7,0.5c0.2-0.3,0.5-0.5,0.5-0.8c0.1-0.6-0.1-1.3,0.1-1.9
              c0.6-2.1,1.4-4.2,2.1-6.4c0.1-0.3,0.2-0.8,0.1-0.9c-1.2-1.3-1.1-2.7-0.2-3.9c-0.2-0.9-0.7-1.7-0.6-2.5c0.1-0.8,0.7-1.5,1.1-2.3
              c-1.4-2-7.8-2-9,0.2C370.2,1023.4,370.3,1024.3,369.1,1026.2z M385.7,1019.5c-0.5,0.6-0.9,1-1.3,1.4c-1.4,1.3-3,2-4.9,2
              c-1,0-1.5,0.5-1.5,1.3c0,0.9,0.6,1.3,1.7,1.3C383,1025.3,385.3,1023.1,385.7,1019.5z M361,1019.6c0.1,3.4,2.7,5.9,6,5.9
              c0.9,0,1.5-0.5,1.5-1.3c0-0.8-0.5-1.4-1.5-1.4c-2.2,0-3.9-0.9-5.4-2.4C361.4,1020.2,361.2,1019.9,361,1019.6z"/>
          </g>
        </g>
      </g>
      </svg>

    </div>
  )
}

export default UpperLevel;