import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

import PageTitle from '../components/PageTitle';
import Footer from '../components/Footer';

import InfoModal from './concourse-maps/InfoModal';
import MessageModal from '../components/MessageModal';
import MainLevel from './concourse-maps/MainLevel';
import SuiteLevel from './concourse-maps/SuiteLevel';
import UpperLevel from './concourse-maps/UpperLevel';
import SectionSearch from './concourse-maps/SectionSearch';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    ':hover': {
      backgroundColor: '#e0c889',
      color: '#fff',
    },
    backgroundColor: state.isSelected ? '#bfab75' : '#fff',
  }),
  control: (provided) => ({
    ...provided,
    border: '1px solid #c5c5c9',
    ':hover': {
      border: '1px solid #c5c5c9',
    }
  }),
  menu: (provided) => ({
    ...provided,
    border: '1px solid #c5c5c9',
    borderTop: '0',
    padding: '0',
    margin: 0
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const options = [
  { value: 'main', label: 'Main Concourse' },
  { value: 'suite', label: 'Suite Level' },
  { value: 'upper', label: 'Upper Concourse' }
];

function ConcourseMap(props) {
  // const { fields } = props;
  const [ showModalMessage, setShowModalMessage ] = useState(false);
  const [ showInfoModal, setShowInfoModal ] = useState(false);
  const [ selectedLayout, setSelectedLayout ] = useState('upper');
  const [ layoutSections, setLayoutSections ] = useState();
  const [ focusedSection, setFocusedSection ] = useState();

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;

    axios.get('http://35.153.188.133/wp-json/wp/v2/concession?per_page=100')
      .then(res => {
        let sections = {
          main: [],
          upper: [],
          suite: []
        };

        res.data.forEach(concession => {
          switch (concession.acf.level) {
            case 'Main Concourse':
              sections.main.push(concession.acf);
              break;
            case 'Upper Concourse':
              sections.upper.push(concession.acf);
              break;
            case 'Suite Level':
              sections.suite.push(concession.acf);
              break;
            default:
              break;
          }
        });

        sections.main.sort((a, b) => (a.name > b.name) ? 1 : -1);
        sections.upper.sort((a, b) => (a.name > b.name) ? 1 : -1);
        sections.suite.sort((a, b) => (a.name > b.name) ? 1 : -1);

        console.log('sections ', sections.upper);
        setLayoutSections(sections);
      })
      .catch(err => console.log(err));
  }, []);

  function handleShowInfo(section) {
    setShowInfoModal(true);
    setFocusedSection(section);
  }

  return (
    <div className='concourse-map'>
      <PageTitle
        title='Concourse Map'
        withInfo={ true }
        handleWithInfo={ e => setShowModalMessage(showModalMessage => !showModalMessage) } />
      
      <div className="selectConcourseMap">
        <ul>
          <li 
            className={ selectedLayout === 'main' ? 'active' : '' }
            onClick={ e => setSelectedLayout('main') }>
            Main Concourse
          </li>
          <li 
            className={ selectedLayout === 'suite' ? 'active' : '' }
            onClick={ e => setSelectedLayout('suite' )}>
            Suite Level
          </li>
          <li 
            className={ selectedLayout === 'upper' ? 'active' : '' }
            onClick={ e => setSelectedLayout('upper') }>
            Upper Concourse
          </li>
        </ul>
      </div>
      <div className='selectWrapper'>
        <Select 
          id='selector'
          styles={ customStyles }
          options={ options }
          onChange={ e => setSelectedLayout(e.value) }
          defaultValue={ options[0] }
          selectedValue={ selectedLayout } />
      </div>

      <div className='seating-chart__body'>
        { selectedLayout === 'main' && <MainLevel focusedSection={ focusedSection } /> }
        { selectedLayout === 'upper' && <UpperLevel focusedSection={ focusedSection } /> }
        { selectedLayout === 'suite' && <SuiteLevel focusedSection={ focusedSection } /> }
      </div>

      {layoutSections && (
        <SectionSearch
          searchPlaceholder='Search Concourse'
          sections={ layoutSections[ selectedLayout ] }
          setFocusedSection={ setFocusedSection }
          focusedSection={ focusedSection }
          handleShowInfo={ handleShowInfo } />
      ) }

      <Footer />

      { showInfoModal && (
        <InfoModal
          focusedSection={ focusedSection }
          handleClose={ e => { setShowInfoModal(false); setFocusedSection(focusedSection.identifier) } }
          details={ focusedSection } />
      ) }

      { showModalMessage && (
        <MessageModal
          handleClose={ e => setShowModalMessage(false) } 
          message='Concession Menus are subject to change at any time.' /> 
      )}
    </div>
  )
}

export default ConcourseMap;