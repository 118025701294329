import React, { useState } from 'react';

function Player(props) {
  const { player } = props;
  const playerInfo = player.playerinfo[ 0 ].$;
  const thumbnail = player.photos[ 0 ].fullsize;
  const [ expand, setExpand ] = useState(false);

  return (
    <div className='sport__rosters__player'>
      <div className='sport__rosters__player__intro'>
        <span className='rosters__player__number'>{ playerInfo.uni }</span>
        <span className='rosters__player__name'>{ player.name }</span>
        <span className='rosters__player__pos'>{ playerInfo.pos_short[0] }</span>
        
        <span 
          className={ expand ? 'sport__rosters__player__chevron' : 'sport__rosters__player__chevron down' }
          onClick={ e => setExpand(expand => !expand) }></span>
      </div>

      { expand && (
        <div className='sport__rosters__player__extra animated fadeIn'>
          <div className='sport__rosters__player__image--container'>
            { thumbnail && <img className='sport__rosters__player__image' src={ thumbnail } alt='player' /> }
          </div>

          <div className='sport__rosters__player__extended'>
            <strong>Height</strong> : { playerInfo.height.toString() } <br />
            <strong>Weight</strong> : { playerInfo.weight } <br />
            <strong>Year</strong> : { playerInfo.year_long } <br />
            <strong>Home Town</strong> : { playerInfo.hometown } <br />
            <strong>High School</strong> : { playerInfo.highschool } <br />
          </div>
        </div>
      ) }
    </div>
  )
}

export default Player;
