import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

function EventCard(props) {
  const { event } = props;

  return (
    <div className='event-card'>
      <Link to={ `/event/${ event.id }`}>
      
        <div className='event-card__left'>
          <img src={ event.acf.event_image.url } alt={ event.acf.event_title } />
        </div>

        <div className='event-card__right'>
          <p>{ moment(`${ event.acf.event_date } ${ event.acf.event_time }`, 'D/M/YYYY HH:mm:ss').format('dddd, MMM D - h:mm A') }</p>
          <h3>{ event.acf.event_title }</h3>
        </div>
      </Link>
    </div> 
  )
}

export default EventCard;