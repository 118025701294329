import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import FilterModal from './FilterModal';

function SectionSearch(props) {
  const {
    sections,
    focusedSection,
    setFocusedSection,
    handleShowInfo,
    searchPlaceholder
  } = props;
  const [ filteredSections, setFilteredSections ] = useState(sections);
  const [ search, setSearch ] = useState('');
  const [ showFilterModal, setShowFilterModal ] = useState(false);
  const [ scrollContainer, setScrollContainer ] = useState();
  const [ filters, setFilters ] = useState();

  useEffect(() => {
    if (document.getElementById('sections-container')) {
      setScrollContainer(document.getElementById('sections-container'));
    }
  }, []);

  useEffect(() => {
    if (search) {
      setFilteredSections(() => {
        return sections.filter(section => {
          return section.name.toUpperCase().includes(search.toString().toUpperCase());
        })
      });
    } else {
      setFilteredSections(sections);
    }
  }, [ search, sections ]);

  useEffect(() => {
    if (filteredSections.length) {
      setFocusedSection(filteredSections[ 0 ].identifier);
    }
  }, [ filteredSections, setFocusedSection ]);

  function handleApply(modalFilters) {
    if (modalFilters.concessions || modalFilters.restaurants || modalFilters.restrooms || modalFilters.services) {
      setFilteredSections(() => {
        return sections.filter(section => {
          if (section.type === 'Concessions' && modalFilters.concessions) {
            return true;
          }

          if (section.type === 'Restaurants & Bars' && modalFilters.restaurants) {
            return true;
          }

          if (section.type === 'Restrooms' && modalFilters.restrooms) {
            return true;
          }

          if (section.type === 'Services' && modalFilters.services) {
            return true;
          }

          return false;
        })
      })
    }
    setFilters(modalFilters);
    setShowFilterModal(false);
  }

  function handleScroll(event) {
    let children = event.target.children;
    let visibleChildren = [];

    Array.from(children).forEach(child => {
      if (child.dataset.visible === 'true') {
        visibleChildren.push(child);
      }
    });

    if (visibleChildren.length && (visibleChildren[ 0 ].getAttribute('data-section') !== focusedSection)) {
      setFocusedSection(visibleChildren[ 0 ].getAttribute('data-section'));
    }

    event.persist()
  }

  return (
    <div>
      <div className='seating-chart__search-box'>
        <input
          type='text'
          className='seating-chart__search'
          name='search'
          placeholder={ searchPlaceholder }
          value={ search }
          onChange={ e => setSearch(e.target.value) } />
        <span style={{ display: 'none' }} onClick={ e => setShowFilterModal(true) }>Filters <img src='/img/icon-filter.png' alt='filter' /></span>
        <span className='clearSearch' onClick={ e => setSearch('') }><img src='/img/x-icon.png' alt='close' /></span>

      </div>

      <div className='seating-chart__sections' id='sections-container' onScroll={ handleScroll }>
        { filteredSections.map(section => {
          if (focusedSection === section.identifier) {
            return (
              <VisibilitySensor key={ section.identifier } delayedCall={ true } containment={ scrollContainer }>
                { ({ isVisible }) => (
                  <div
                    key={ section.identifier }
                    data-visible={ isVisible }
                    data-section={ section.identifier }
                    className='seating-chart__section active'
                    onClick={ e => handleShowInfo(section) }>
                    { section.name }
                  </div>
                ) }
              </VisibilitySensor>
            )
          } else {
            return (
              <VisibilitySensor key={ section.identifier } delayedCall={ true } containment={ scrollContainer }>
                { ({ isVisible }) => (
                  <div
                    key={ section.identifier }
                    data-visible={ isVisible }
                    data-section={ section.identifier }
                    className='seating-chart__section'
                    onClick={ e => handleShowInfo(section) }>
                    { section.name }
                  </div>
                ) }
              </VisibilitySensor>
            )
          }
        }) }
      </div>

      { showFilterModal && (
        <FilterModal
          handleApply={ handleApply }
          existingFilters={ filters }
          handleClose={ e => setShowFilterModal(false) } />
      ) }
    </div>
  )
}

export default SectionSearch;
