import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { NavLink, Link } from 'react-router-dom';

function Header(props) {
  const [ menuOpen, setMenuOpen ] = useState(false);

  useEffect(() => {
    if (menuOpen) {
      $('.app').removeClass('fixedHeader');
    }
  }, [ menuOpen ]);

  return (
    <header className='header'>
      <div className='header__top'>
        <div className='header__logo'>
          <Link to='/'>
            <img src='/img/a-rena-logo.png' alt='Dickies Arena' />
          </Link>
        </div>

        <div
          id='nav-burger'
          className={ menuOpen ? 'open' : 'closed' } 
          onClick={ e => setMenuOpen(menuOpen => !menuOpen) }>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      {menuOpen && (
        <div className='header__nav'>
          <NavLink 
            activeClassName='active'
            onClick={ e=> setMenuOpen(false) }
            exact
            to='/'>
            Home
          </NavLink>

          <NavLink
            activeClassName='active'
            onClick={ e => setMenuOpen(false) }
            exact
            to='/directions'>
            Directions
          </NavLink>

          <NavLink 
            activeClassName='active'
            onClick={ e => setMenuOpen(false) }
            exact
            to='/seating-chart'>
            Seating Chart
          </NavLink>

          <NavLink 
            activeClassName='active' 
            onClick={ e => setMenuOpen(false) }
            exact
            to='/concourse-map'>
            Concourse Map
          </NavLink>

          <NavLink 
            activeClassName='active' 
            onClick={ e => setMenuOpen(false) }
            to='/about'>
            About
          </NavLink>
          <NavLink 
            activeClassName='active' 
            onClick={ e => setMenuOpen(false) }
            to='/guest-services'>
            Guest Services
          </NavLink>
          <NavLink 
            activeClassName='active' 
            onClick={ e => setMenuOpen(false) }
            to='/upcoming-events'>
            Upcoming Events
          </NavLink>

          <div className='header__nav__social'>
            <a href='https://www.facebook.com/dickiesarena/'>
              <img src='/img/icon-facebook.png' alt='Facebook' />
            </a>

            <a href='https://twitter.com/DickiesArena'>
              <img src='/img/icon-twitter.png' alt='Twitter' />
            </a>

            <a href='https://www.instagram.com/dickiesarena/?hl=en'>
              <img src='/img/icon-insta.png' alt='Instagram' />
            </a>
          </div>
        </div>
      )}
      
    </header>
  )
}

export default Header;