import React, { useState, useEffect } from 'react';

function InfoExpander(props) {
  const { title, copy } = props;
  const [ expand, setExpand ] = useState(false);
  const [ chevronClass, setChevronClass ] = useState('info-expander__title__chevron');

  useEffect(() => {
    if (expand) {
      setChevronClass('info-expander__title__chevron down')
    } else {
      setChevronClass('info-expander__title__chevron');
    }
  }, [ expand ]);

  return (
    <div className='info-expander'>
      <div className='info-expander__title' onClick={ e => setExpand(expand => !expand) }>
        { title }

        <span className={ chevronClass }></span>
      </div>

      {expand && (
        <div 
          className='info-expander__copy'
          dangerouslySetInnerHTML={ { __html: copy } }></div>
      )}
    </div>
  )
}

export default InfoExpander;
