import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Roster from './rosters/Roster';
const parseString = require('react-native-xml2js').parseString;

function Rosters(props) {
  const { eventDetails } = props;
  const [ selectHome, setSelectHome ] = useState(true);
  const [ homeRoster, setHomeRoster ] = useState();
  const [ awayRoster, setAwayRoster ] = useState();

  useEffect(() => {
    function getHomeRoster(home) {
      if (!homeRoster) {
        axios(`https://protected-shore-15426.herokuapp.com/${ home }`)
          .then(res => {
            parseString(res.data, (err, result) => {
              if (result) {
                setHomeRoster(result.roster.player);
              } else {
                console.log('parse err ', err);
              }
            });
          })
          .catch(err => {
            getHomeRoster(home);
            console.log('xhr err ', err);
          })
      }
    }

    function getAwayRoster(away) {
      if (!awayRoster) {
        axios(`https://protected-shore-15426.herokuapp.com/${ away }`)
          .then(res => {
            parseString(res.data, (err, result) => {
              if (result) {
                setAwayRoster(result.roster.player);
              } else {
                console.log('parse err ', err);
              }
            });
          })
          .catch(err => {
            setAwayRoster([]);
            console.log('xhr error ', err);
          })
      }
    }

    selectHome ? getHomeRoster(eventDetails.home_roster) : getAwayRoster(eventDetails.away_roster);
  }, [ selectHome, eventDetails, homeRoster, awayRoster ]);
  
  return (
    <div className='sport__rosters'>
      <div className='sport__stats__nav'>
        <div
          className={ selectHome ? 'active' : '' }
          onClick={ e => setSelectHome(true) }>
          { eventDetails.home_team_letters || 'HOME' }
        </div>

        <div
          className={ !selectHome ? 'active' : '' }
          onClick={ e => setSelectHome(false) }>
          { eventDetails.away_team_letters || 'AWAY' }
        </div>
      </div>

      <div className='sport__rosters__body'>
        <div className='sport__rosters__player' style={{ background: 'none' }}>
          <div className='sport__rosters__player__intro'>
            <span className='sport__rosters__player__number'>#</span>
            <span className='sport__rosters__player__name'>NAME</span>
            <span className='sport__rosters__player__pos'>POS</span>
          </div>
        </div>

        { selectHome ? 
          ( homeRoster ? <Roster players={ homeRoster } /> : <div style={{ marginTop: '10px' }}>Loading...</div> ) 
          :
          ( awayRoster ? <Roster players={ awayRoster } /> : <div style={{ marginTop: '10px' }}>Loading...</div>)  
        }
      </div>
    </div>
  )
}

export default Rosters;