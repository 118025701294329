import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../components/Footer';
import AboutFullPage from './about/AboutFullPage';

function About(props) {
  const { fields } = props;

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
  }, []);
  
  return (
    <div className='about'>
      
      <AboutFullPage fields={ fields } />
      
      <div className='aboutPagination'>
        <div className="aboutProgressContainer">
          <span id='slideNumber'>1</span>/7
          <div className="aboutProgressBar">
            <div className="progress"></div>
          </div>
        </div>
        <Link to='/'>Back to DickiesArena.Live Home <img src='/img/arrow-right-deco.png' alt='Back' /></Link>
      </div>

      <Footer />
    </div>
  )
}

export default About;