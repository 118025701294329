import React, { useState, useEffect } from 'react';
import $ from 'jquery';

function InfoModal(props) {
  const { details, handleClose } = props;
  const [ menu, setMenu ] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    let tempObj = {};

    if (details.beverage) {
      tempObj.beverage = details.beverage.split('; ');
    }

    if (details.entrees) {
      tempObj.entrees = details.entrees.split('; ');
    }

    if (details.kids) {
      tempObj.kids = details.kids.split('; ');
    }

    if (details.mains) {
      tempObj.mains = details.mains.split('; ');
    }

    if (details.snacks) {
      tempObj.snacks = details.snacks.split('; ');
    }

    if (details.spirits) {
      tempObj.spirits = details.spirits.split('; ');
    }

    setMenu(tempObj);
  }, [ details ]);

  return (
    <div className='modal__info' style={ { height: $('body').height() } }>
      <img className='modal__info__close' src='/img/icon-close.png' alt='Close' onClick={ e => handleClose() } />

      <div className='modal__concourse'>
        <h2>{ details.name }</h2>

        <div className='modal__seating__level' style={{ paddingBottom: '20px', marginBottom: '20px' }}>
          <img src='/img/icon-marker.png' alt='point' />
          { details.location }
        </div>

        { details.description && (
          <div className='modal__seating__proximity--section'>
            { details.description }
          </div>
        ) }

        { menu && menu.hasOwnProperty('mains') && (
          <div className='modal__seating__proximity--section'>
            Mains
            { menu.mains.map(entry => (
              <span className='modal__seating__proximity--specific' key={ entry }>{ entry }</span>
            )) }
          </div>
        ) }

        { menu && menu.hasOwnProperty('entrees') && (
          <div className='modal__seating__proximity--section'>
            Entrees
            { menu.entrees.map(entry => (
              <span className='modal__seating__proximity--specific' key={ entry }>{ entry }</span>
            )) }
          </div>
        ) }

        { menu && menu.hasOwnProperty('snacks') && (
          <div className='modal__seating__proximity--section'>
            Snacks
            { menu.snacks.map(entry => (
              <span className='modal__seating__proximity--specific' key={ entry }>{ entry }</span>
            )) }
          </div>
        ) }

        { menu && menu.hasOwnProperty('kids') && (
          <div className='modal__seating__proximity--section'>
            Kid's Menu
            { menu.kids.map(entry => (
              <span className='modal__seating__proximity--specific' key={ entry }>{ entry }</span>
            )) }
          </div>
        ) }

        { menu && menu.hasOwnProperty('beverage') && (
          <div className='modal__seating__proximity--section'>
            Beverage
            { menu.beverage.map(entry => (
              <span className='modal__seating__proximity--specific' key={ entry }>{ entry }</span>
            )) }
          </div>
        ) }

        { menu && menu.hasOwnProperty('spirits') && (
          <div className='modal__seating__proximity--section'>
            Spirits
            { menu.spirits.map(entry => (
              <span className='modal__seating__proximity--specific' key={ entry }>{ entry }</span>
            )) }
          </div>
        ) }
        
      </div>
    </div>
  )
}

export default InfoModal;
