import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import PageTitle from '../components/PageTitle';
import Footer from '../components/Footer';

import Basketball from './seating-charts/Basketball';
import ConcertCenter from './seating-charts/ConcertCenter';
import ConcertEnd from './seating-charts/ConcertEnd';
import Hockey from './seating-charts/Hockey';
import Rodeo from './seating-charts/Rodeo';
import SectionSearch from './seating-charts/SectionSearch';
import InfoModal from './seating-charts/InfoModal';
import MessageModal from '../components/MessageModal';

const allSections = {
  endSections: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '14',
    '15',
    '16',
    '104',
    '105',
    '106',
    '107',
    '108',
    '109',
    '110',
    '111',
    '113',
    '114',
    '115',
    '116',
    '117',
    '119',
    '120',
    '121',
    '122',
    '123',
    '124',
    '125',
    '126',
    '204',
    '205',
    '206',
    '207',
    '208',
    '209',
    '210',
    '211',
    '212',
    '213',
    '214',
    '215',
    '216',
    '217',
    '218',
    '219',
    '220',
    '221',
    '222',
    '223',
    '224',
    '225',
    '226',
  ],
  centerSections: [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    '14',
    '15',
    '16',
    '30',
    '31',
    '32',
    '101',
    '103',
    '104',
    '105',
    '106',
    '107',
    '108',
    '109',
    '110',
    '111',
    '113',
    '114',
    '115',
    '116',
    '117',
    '119',
    '120',
    '121',
    '122',
    '123',
    '124',
    '125',
    '126',
    '127',
    '129',
    '130',
    '131',
    '132',
    '201',
    '202',
    '203',
    '204',
    '205',
    '206',
    '207',
    '208',
    '209',
    '210',
    '211',
    '212',
    '213',
    '214',
    '215',
    '216',
    '217',
    '218',
    '219',
    '220',
    '221',
    '222',
    '223',
    '224',
    '225',
    '226',
    '227',
    '228',
    '229',
    '230',
    '231',
    '232',

  ],
  basketballSections: [
    '14',
    '15',
    '16',
    '30',
    '31',
    '32',
    '101',
    '103',
    '104',
    '105',
    '106',
    '107',
    '108',
    '109',
    '110',
    '111',
    '113',
    '114',
    '115',
    '116',
    '117',
    '119',
    '120',
    '121',
    '122',
    '123',
    '124',
    '125',
    '126',
    '127',
    '129',
    '130',
    '131',
    '132',
    '201',
    '202',
    '203',
    '204',
    '205',
    '206',
    '207',
    '208',
    '209',
    '210',
    '211',
    '212',
    '213',
    '214',
    '215',
    '216',
    '217',
    '218',
    '219',
    '220',
    '221',
    '222',
    '223',
    '224',
    '225',
    '226',
    '227',
    '228',
    '229',
    '230',
    '231',
    '232'
  ],
  hockeySections: [
    '101',
    '103',
    '104',
    '105',
    '106',
    '107',
    '108',
    '109',
    '110',
    '111',
    '113',
    '114',
    '115',
    '116',
    '117',
    '119',
    '120',
    '121',
    '122',
    '123',
    '124',
    '125',
    '126',
    '127',
    '129',
    '130',
    '131',
    '132',
    '201',
    '202',
    '203',
    '204',
    '205',
    '206',
    '207',
    '208',
    '209',
    '210',
    '211',
    '212',
    '213',
    '214',
    '215',
    '216',
    '217',
    '218',
    '219',
    '220',
    '221',
    '222',
    '223',
    '224',
    '225',
    '226',
    '227',
    '228',
    '229',
    '230',
    '231',
    '232'
  ],
  rodeoSections: [
    '5',
    '7',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19B',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '70',
    '71',
    '72B',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '101',
    '103',
    '104',
    '105',
    '106',
    '107',
    '108',
    '109',
    '110',
    '111',
    '113',
    '114',
    '115',
    '116',
    '117',
    '119',
    '120',
    '121',
    '122',
    '123',
    '124',
    '125',
    '126',
    '127',
    '129',
    '201',
    '202',
    '203',
    '204',
    '205',
    '206',
    '207',
    '208',
    '209',
    '210',
    '211',
    '212',
    '213',
    '214',
    '215',
    '216',
    '217',
    '218',
    '219',
    '220',
    '221',
    '222',
    '223',
    '224',
    '225',
    '226',
    '227',
    '228',
    '229',
    '230',
    '231',
    '232',
  ]
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    ':hover': {
      backgroundColor: '#e0c889',
      color: '#fff',
    },
    backgroundColor: state.isSelected ? '#bfab75' : '#fff',
  }),
  control: (provided) => ({
    ...provided,
    border: '1px solid #c5c5c9',
    ':hover': {
      border: '1px solid #c5c5c9',
    }
  }),
  menu: (provided) => ({
    ...provided,
    border: '1px solid #c5c5c9',
    borderTop: '0',
    padding: '0',
    margin: 0
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const options = [
  { value: 'end', label: 'Concert - End Stage' },
  { value: 'center', label: 'Concert - Center Stage' },
  { value: 'basketball', label: 'Basketball' },
  { value: 'hockey', label: 'Hockey' },
  { value: 'rodeo', label: 'Rodeo' }
];

function SeatingChart(props) {
  // const { fields } = props;
  const [ showModalMessage, setShowModalMessage ] = useState(false);
  const [ showInfoModal, setShowInfoModal ] = useState(false);
  const [ selectedLayout, setSelectedLayout ] = useState('end');
  const [ layoutSections, setLayoutSections ] = useState(allSections.endSections);
  const [ focusedSection, setFocusedSection ] = useState(layoutSections[0]);

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
    
    setLayoutSections(allSections[ `${ selectedLayout }Sections` ]);
  }, [ selectedLayout ]);

  function handleShowInfo(section) {
    setShowInfoModal(true);
    setFocusedSection(section);
  }

  return (
    <div className='seating-chart'>
      <PageTitle 
        title='Seating Chart' 
        withInfo={ true }  
        handleWithInfo={ e => setShowModalMessage(showModalMessage => !showModalMessage) } />
      <div className="selectWrapper">
        <Select
          id='selector'
          styles={ customStyles }
          options={ options }
          onChange={ e => setSelectedLayout(e.value) }
          defaultValue={ options[0] }
          selectedValue={ selectedLayout } />
      </div>

      <div className='seating-chart__body'>
        { selectedLayout === 'basketball' && <Basketball focusedSection={ focusedSection } /> }
        { selectedLayout === 'center' && <ConcertCenter focusedSection={ focusedSection } /> }
        { selectedLayout === 'end' && <ConcertEnd focusedSection={ focusedSection } /> }
        { selectedLayout === 'hockey' && <Hockey focusedSection={ focusedSection } /> }
        { selectedLayout === 'rodeo' && <Rodeo focusedSection={ focusedSection } /> }
      </div>

      <SectionSearch 
        searchPlaceholder='Search for your section'
        sections={ layoutSections } 
        setFocusedSection={ setFocusedSection } 
        focusedSection={ focusedSection }
        handleShowInfo={ handleShowInfo } />

      <Footer />

      { showInfoModal && (
        <InfoModal 
          focusedSection={ focusedSection }
          selectedLayout={ selectedLayout }
          handleClose={ e => setShowInfoModal(false) }
          section={ focusedSection } />
      ) }

      { showModalMessage && (
        <MessageModal 
          handleClose={ e => setShowModalMessage(false) }
          message='Each event will have a unique seating chart. The following are a few examples of potential seating layouts by event.' /> 
      )}
    </div>
  )
}

export default SeatingChart;