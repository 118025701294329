import React, { useEffect } from 'react';

function Hockey(props) {
  const { focusedSection } = props;

  useEffect(() => {
    const elementToFocus = document.getElementById(`section_${ focusedSection }`);
    const focusedRect = document.getElementsByClassName('focused');

    if (focusedRect.length) {
      focusedRect[ 0 ].classList.remove('focused');
    }

    elementToFocus && elementToFocus.classList.add('focused');
  }, [ focusedSection ]);

  return (
    <div>
      <svg 
        easypz='{ "applyTransformTo":"svg > *", "modes": ["SIMPLE_PAN", "HOLD_ZOOM_IN", "CLICK_HOLD_ZOOM_OUT", "PINCH_ZOOM", "DBLCLICK_ZOOM_IN", "DBLRIGHTCLICK_ZOOM_OUT"], "options": { "minScale": 1, "maxScale": 2, "bounds": { "top": -50, "right": 50, "bottom": 50, "left": -50 }} }'
        version="1.1" 
        id="SeatingChartHockey" 
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px" 
        viewBox="55 70 680 470">
      
      <g id="section_226">
        <rect x="220.1" y="482" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 232.9674 501.5828)">226</text>
      </g>

      <g id="section_227">
        <polygon points="172.1,464.1 148.1,488.1 176.2,516.2 211.9,516.2 211.9,481.7 189.3,481.3 	"/>
        <text transform="matrix(1 0 0 1 173.0738 501.5828)">227</text>
      </g>

      <g id="section_225">
        <rect x="270.9" y="482" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 284.7148 501.5838)">225</text>
      </g>

      <g id="section_224">
        <rect x="321.8" y="482" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 334.6611 501.5828)">224</text>
      </g>

      <g id="section_222">
        <rect x="423.6" y="482" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 435.2162 501.5828)">222</text>
      </g>

      <g id="section_223">
        <rect x="372.7" y="482" width="44.3" height="27"/>
        <text transform="matrix(1 0 0 1 384.3676 499.9161)">223</text>
      </g>

      <g id="section_207">
        <rect x="373" y="81.9" width="44.3" height="29.6"/>
        <text transform="matrix(1 0 0 1 384.3676 101.1403)">207</text>
      </g>

      <g id="section_221">
        <rect x="474.5" y="482.1" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 486.7305 501.5828)">221</text>
      </g>

      <g id="section_220">
        <rect x="525.4" y="482.1" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 536.6125 501.5828)">220</text>
      </g>

      <g id="section_218">
        <rect x="624.8" y="437.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -130.1241 594.7978)" width="56.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 643.2523 457.5687)">218</text>
      </g>

      <g id="section_216">
        <rect x="678.5" y="320.7" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 685.6465 347.9002)">216</text>
      </g>

      <g id="section_215">
        <rect x="678.6" y="277.5" width="29" height="36.9"/>
        <text transform="matrix(1 0 0 1 683.6465 300.1811)">215</text>
      </g>

      <g id="section_214">
        <rect x="678.5" y="226.2" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 685.6279 253.294)">214</text>
      </g>

      <g id="section_219_1_">
        <polygon points="576,482 576,515.9 615.7,515.9 640.9,490.7 616.6,466.3 600.3,482 	"/>
        <text transform="matrix(1 0 0 1 595.6715 498.9849)">219</text>
      </g>

      <g id="section_204">
        <rect x="220.5" y="77.6" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 235.4526 97.1399)">204</text>
      </g>

      <g id="section_203">
        <polygon points="213.8,111.5 213.8,77.6 174.1,77.6 148.8,102.9 173.2,127.2 189.4,111.5 	"/>
        <text transform="matrix(1 0 0 1 174.0738 99.1399)">203</text>
      </g>

      <g id="section_202">
        <rect x="109.6" y="121.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -57.703 137.6184)" width="55.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 127.5483 141.6404)">202</text>
      </g>

      <g id="section_201">
        <polygon points="123.9,173.4 100.2,149.8 76.4,173.6 76.4,219.1 110.1,219.1 110.1,186.9 	"/>
        <text transform="matrix(1 0 0 1 83.6298 191.3426)">201</text>
      </g>

      <g id="section_232">
        <rect x="76.4" y="226.2" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 83.6298 252.2942)">232</text>
      </g>

      <g id="section_231">
        <rect x="76.4" y="277.3" width="33.9" height="37.9"/>
        <text transform="matrix(1 0 0 1 83.6298 299.6478)">231</text>
      </g>

      <g id="section_230">
        <rect x="76.4" y="321.2" width="33.9" height="45.2"/>
        <text transform="matrix(1 0 0 1 83.6298 346.5667)">230</text>
      </g>

      <g id="section_219">
        <polygon points="124.3,417.7 100.2,441.8 76.4,418 76.4,372.5 110.1,372.5 110.9,403.9 	"/>
        <text transform="matrix(1 0 0 1 83.6298 410.7451)">219</text>
      </g>

      <g id="section_228">
        <rect x="119.7" y="425.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -280.2093 229.2583)" width="33.9" height="55.3"/>
        <text transform="matrix(1 0 0 1 126.3052 458.0751)">228</text>
      </g>

      <g id="section_205">
        <rect x="271.4" y="77.6" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 283.1556 97.1399)">205</text>
      </g>

      <g id="section_206">
        <rect x="322.2" y="77.6" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 334.3164 97.1399)">206</text>
      </g>

      <g id="section_208">
        <rect x="423.8" y="77.6" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 435.63 97.1399)">208</text>
      </g>

      <g id="section_209">
        <rect x="474.6" y="77.6" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 485.9932 97.1399)">209</text>
      </g>

      <g id="section_210">
        <rect x="525.5" y="77.7" width="44.3" height="33.9"/>
        <text transform="matrix(1 0 0 1 536.9342 97.1399)">210</text>
      </g>

      <g id="section_211">
        <polygon points="576.1,111 576.1,77.1 615.8,77.1 641,102.3 616.7,126.7 600.4,111 	"/>
        <text transform="matrix(1 0 0 1 597.1486 99.1403)">211</text>
      </g>

      <g id="section_212">
        <rect x="635.2" y="111.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 93.1079 501.7051)" width="33.9" height="54.8"/>
        <text transform="matrix(1 0 0 1 642.4253 141.6402)">212</text>
      </g>

      <g id="section_213">
        <polygon points="664.8,174.1 688.5,150.4 712.3,174.2 712.3,219.8 678.6,219.8 678.6,187.6 	"/>
        <text transform="matrix(1 0 0 1 685.6278 191.6402)">213</text>
      </g>

      <g id="section_217">
        <polygon points="664.8,418.7 688.5,442.4 712.3,418.6 712.3,373.1 678.6,373.1 678.6,405.2 	"/>
        <text transform="matrix(1 0 0 1 684.6273 410.7449)">217</text>
      </g>

      <g id="section_125">
        <rect x="271.2" y="365.4" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 284.1556 418.2495)">125</text>
      </g>

      <g id="section_124">
        <rect x="322.1" y="365.4" width="44.3" height="95.5"/>
        <text transform="matrix(1 0 0 1 333.7278 418.2495)">124</text>
      </g>

      <g id="section_123">
        <rect x="372.8" y="376.3" width="44.3" height="88.9"/>
        <text transform="matrix(1 0 0 1 385.3674 418.2495)">123</text>
      </g>

      <g id="section_122">
        <rect x="423.6" y="365.4" width="44.3" height="95.5"/>
        <text transform="matrix(1 0 0 1 435.1071 418.2495)">122</text>
      </g>

      <g id="section_121">
        <rect x="474.3" y="365.4" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 486.9934 418.2495)">121</text>
      </g>

      <g id="section_109">
        <rect x="474.3" y="127.2" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 486.9934 177.0638)">109</text>
      </g>

      <g id="section_106">
        <polygon points="322.1,131.8 322.1,217.1 322.1,229.5 339.7,229.5 339.7,217.1 366.4,217.1 366.4,131.8 	"/>
        <text transform="matrix(1 0 0 1 334.3165 177.0638)">106</text>
      </g>

      <g id="section_105">
        <rect x="271.2" y="127.2" width="44.3" height="99.7"/>
        <text transform="matrix(1 0 0 1 283.1567 177.0638)">105</text>
      </g>

      <g id="section_120">
        <polygon points="525.4,365.4 547.6,365.4 588.7,465.2 588.7,470.6 525.4,470.6 	"/>
        <text transform="matrix(1 0 0 1 535.934 418.2495)">120</text>
      </g>

      <g id="section_116">
        <polygon points="662.5,390.8 662.5,324.3 562.8,324.3 562.8,349.8 	"/>
        <text transform="matrix(1 0 0 1 604.6299 350.9995)">116</text>
      </g>

      <g id="section_115">
        <rect x="562.8" y="274" width="95.2" height="44.3"/>
        <text transform="matrix(1 0 0 1 604.6299 300.1817)">115</text>
      </g>

      <g id="section_114">
        <polygon points="662.5,201.7 662.5,268.2 562.8,268.2 562.8,242.7 	"/>
        <text transform="matrix(1 0 0 1 604.6299 247.1817)">114</text>
      </g>

      <g id="section_126">
        <polygon points="265.2,465.1 265.2,368.6 243.2,368.7 202,465.1 202,470.1 265.2,470.1 	"/>
        <text transform="matrix(1 0 0 1 235.4528 418.2495)">126</text>
      </g>

      <g id="section_104">
        <polygon points="202,126.7 264.8,126.7 264.8,215.3 237.3,215.3 	"/>
        <text transform="matrix(1 0 0 1 232.9672 177.0638)">104</text>
      </g>

      <g id="section_108">
        <polygon points="423.6,132 423.6,216.3 450.5,216.3 450.5,229.5 468.5,229.5 468.5,216.3 468.5,215.3 468.5,132 	"/>
        <text transform="matrix(1 0 0 1 435.216 177.0638)">108</text>
      </g>

      <g id="section_107">
        <polygon points="372.8,127.2 372.8,191.6 372.8,200.3 372.8,216.3 385.9,216.3 385.9,200.3 405.5,200.3 405.5,216.3 
          417.1,216.3 417.1,200.3 417.1,191.6 417.1,127.2 	"/>
        <text transform="matrix(1 0 0 1 385.3671 177.0638)">107</text>
      </g>

      <g id="section_110">
        <polygon points="527.4,125.4 592.9,125.4 554.6,217.8 545.3,217.8 545.3,227.6 527.4,227.6 	"/>
        <text transform="matrix(1 0 0 1 535.2062 177.0638)">110</text>
      </g>

      <g id="section_111">
        <polygon points="598.3,127.2 629,157 576,208.9 566.3,201.7 	"/>
        <text transform="matrix(1 0 0 1 591.1542 165.5859)">111</text>
      </g>

      <g id="section_113">
        <polygon points="630,165.2 662.5,196.3 591.3,224.9 581.3,215.3 	"/>
        <text transform="matrix(1 0 0 1 625.3403 193.3891)">113</text>
      </g>

      <g id="section_130">
        <polygon points="225.3,324.3 225.3,351 166.3,376.3 166.3,324.3 	"/>
        <text transform="matrix(1 0 0 1 184.5949 346.5665)">130</text>
      </g>

      <g id="section_131">
        <rect x="166.3" y="274" width="58.9" height="44.3"/>
        <text transform="matrix(1 0 0 1 184.5949 299.6476)">131</text>
      </g>

      <g id="section_132">
        <polygon points="226.7,240.9 226.7,268.2 166.3,268.2 166.3,217.1 	"/>
        <text transform="matrix(1 0 0 1 184.5949 253.2941)">132</text>
      </g>

      <g id="section_101">
        <path d="M199.8,205.9c-2.4-1.2-38.8-40.3-38.8-40.3l-18.5,18.8l6.3,6l-7.5,6.7l48,19.9L199.8,205.9z"/>
        <text transform="matrix(1 0 0 1 156.6231 195.0447)">101</text>
      </g>

      <g id="section_103">
        <path d="M193.2,126.7l2.5,3l34.7,84.5l-6.8,7.7c0,0-13.6-5.4-13.9-5.9c-0.3-0.5-7.7-7-7.7-7l5.5-5.5L162,158.1
          L193.2,126.7z"/>
        <text transform="matrix(1 0 0 1 178.4528 163.7256)">103</text>
      </g>

      <g id="section_119">
        <polygon points="579.1,385.1 581.2,382.8 559.5,361.1 555.1,365.4 595.8,463.9 626.3,433.6 	"/>
        <text transform="matrix(1 0 0 1 585.8716 429.3136)">119</text>
      </g>

      <g id="section_117">
        <polygon points="563.4,357.5 559.5,361.1 581.2,382.8 583.1,380.6 628,426.4 658.6,395.9 	"/>
        <text transform="matrix(1 0 0 1 611.9783 398.8057)">117</text>
      </g>

      <g id="section_127">
        <polygon points="229.1,364.4 211.8,379.7 213.9,381.6 161.7,435 193.2,464.6 233.6,368.6 	"/>
        <text transform="matrix(1.0525 0 0 1 177.9155 438.3389)">127</text>
      </g>

      <g id="section_129">
        <polygon points="225.3,360.9 132.5,397.9 161.7,426.9 209.9,377.9 211.8,379.7 229.1,364.4 	"/>
        <text transform="matrix(1.0525 0 0 1 148.4155 406.8848)">129</text>
      </g>

      <g id="hockey_rink">
        <rect x="342.3" y="219.8" width="50" height="10.2"/>
        <rect x="398.1" y="219.8" width="50" height="10.2"/>
        <rect x="372.8" y="365.4" width="44.3" height="9.1"/>
        <text transform="matrix(.5 0 0 .5 352.1885 225.9189)">HOME BOX</text>
        <text transform="matrix(.5 0 0 .5 401.7734 225.9189)">VISITORS BOX</text>
        <text transform="matrix(.5 0 0 .5 382.46 372.0229)">PENALTY</text>
        <path d="M508.4,365.4H280.1c-24.6,0-44.8-20.2-44.8-44.8v-45.9c0-24.6,20.2-44.8,44.8-44.8h228.3
          c24.6,0,44.8,20.2,44.8,44.8v45.9C553.2,345.3,533,365.4,508.4,365.4z"/>
      </g>
      <g id="handicapped">
        <g>
          <g>
            <circle cx="344.4" cy="461.6" r="6"/>
            <path d="M344.4,468c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S347.9,468,344.4,468z M344.4,455.9
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S347.5,455.9,344.4,455.9z"/>
          </g>
          <g>
            <g>
              <path d="M344.6,460.7L344.6,460.7c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C344.6,460.2,344.6,460.4,344.6,460.7C344.6,460.6,344.6,460.6,344.6,460.7z"/>
            </g>
            <g>
              <path d="M343.5,460.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C342.6,461,343,460.7,343.5,460.6
                C343.4,460.6,343.4,460.6,343.5,460.6z"/>
            </g>
            <g>
              <path d="M345,458.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C344.6,457.6,345,458,345,458.4z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="344.4" cy="130.8" r="6"/>
            <path d="M344.4,137.1c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C350.7,134.3,347.9,137.1,344.4,137.1z
              M344.4,125.1c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C350.1,127.7,347.5,125.1,344.4,125.1z"/>
          </g>
          <g>
            <g>
              <path d="M344.6,129.8L344.6,129.8c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C344.6,129.4,344.6,129.6,344.6,129.8L344.6,129.8z"/>
            </g>
            <g>
              <path d="M343.5,129.8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C342.6,130.1,343,129.9,343.5,129.8
                C343.4,129.8,343.4,129.8,343.5,129.8z"/>
            </g>
            <g>
              <path d="M345,127.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9S345,127.1,345,127.6z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="446" cy="130.8" r="6"/>
            <path d="M446,137.1c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S449.5,137.1,446,137.1z M446,125.1
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C451.7,127.7,449.1,125.1,446,125.1z"/>
          </g>
          <g>
            <g>
              <path d="M446.2,129.8L446.2,129.8c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C446.2,129.4,446.2,129.6,446.2,129.8L446.2,129.8z"/>
            </g>
            <g>
              <path d="M445.1,129.8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C444.2,130.1,444.6,129.9,445.1,129.8
                C445,129.8,445,129.8,445.1,129.8z"/>
            </g>
            <g>
              <path d="M446.6,127.6c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9S446.6,127.1,446.6,127.6z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="513.2" r="6"/>
            <path d="M394.4,519.5c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C400.7,516.7,397.9,519.5,394.4,519.5z
              M394.4,507.4c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,507.4,394.4,507.4z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,512.2L394.6,512.2c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,511.8,394.6,511.9,394.6,512.2C394.6,512.1,394.6,512.2,394.6,512.2z"/>
            </g>
            <g>
              <path d="M393.5,512.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,512.5,393,512.3,393.5,512.1
                C393.4,512.1,393.4,512.1,393.5,512.1z"/>
            </g>
            <g>
              <path d="M395,510c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,509.5,395,510z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="678.8" cy="452.3" r="6"/>
            <path d="M678.8,458.7c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S682.3,458.7,678.8,458.7z
              M678.8,446.6c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S681.9,446.6,678.8,446.6z"/>
          </g>
          <g>
            <g>
              <path d="M679,451.3C679,451.3,679.1,451.3,679,451.3c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C679,450.9,679,451.1,679,451.3L679,451.3z"/>
            </g>
            <g>
              <path d="M677.9,451.3c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C677,451.6,677.4,451.4,677.9,451.3
                C677.8,451.3,677.9,451.3,677.9,451.3z"/>
            </g>
            <g>
              <path d="M679.4,449.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C679,448.3,679.4,448.6,679.4,449.1z"
                />
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="711.3" cy="296" r="6"/>
            <path d="M711.3,302.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S714.8,302.3,711.3,302.3z
              M711.3,290.3c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C717,292.8,714.4,290.3,711.3,290.3z"/>
          </g>
          <g>
            <g>
              <path d="M711.5,295C711.5,295,711.6,295,711.5,295c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V297c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C711.5,294.6,711.5,294.8,711.5,295L711.5,295z"/>
            </g>
            <g>
              <path d="M710.4,294.9c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C709.5,295.3,709.9,295.1,710.4,294.9
                C710.3,295,710.4,295,710.4,294.9z"/>
            </g>
            <g>
              <path d="M711.9,292.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S711.9,292.3,711.9,292.8z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="676.5" cy="141.5" r="6"/>
            <path d="M676.5,147.8c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S680,147.8,676.5,147.8z M676.5,135.7
              c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S679.7,135.7,676.5,135.7z"/>
          </g>
          <g>
            <g>
              <path d="M676.7,140.5C676.8,140.5,676.8,140.5,676.7,140.5c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C676.8,140.1,676.7,140.2,676.7,140.5C676.7,140.4,676.7,140.5,676.7,140.5z"/>
            </g>
            <g>
              <path d="M675.6,140.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C674.8,140.8,675.1,140.6,675.6,140.4L675.6,140.4z"/>
            </g>
            <g>
              <path d="M677.1,138.3c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C676.8,137.4,677.1,137.8,677.1,138.3
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="641.4" cy="412.2" r="6"/>
            <path d="M641.4,418.6c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S644.9,418.6,641.4,418.6z
              M641.4,406.5c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C647.1,409.1,644.5,406.5,641.4,406.5z"/>
          </g>
          <g>
            <g>
              <path d="M641.6,411.3C641.6,411.3,641.7,411.3,641.6,411.3c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4
                c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0
                c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4
                c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5
                c0.2,0,0.4,0.1,0.5,0.4v0.1C641.6,410.8,641.6,411,641.6,411.3C641.6,411.2,641.6,411.2,641.6,411.3z"/>
            </g>
            <g>
              <path d="M640.5,411.2c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C639.6,411.6,640,411.3,640.5,411.2L640.5,411.2z"/>
            </g>
            <g>
              <path d="M642,409c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C641.6,408.2,642,408.6,642,409z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="482" r="6"/>
            <path d="M394.4,488.4c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S397.9,488.4,394.4,488.4z
              M394.4,476.3c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,476.3,394.4,476.3z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,481L394.6,481c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V483c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,480.6,394.6,480.8,394.6,481L394.6,481z"/>
            </g>
            <g>
              <path d="M393.5,481c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,481.3,393,481.1,393.5,481
                C393.4,481,393.4,481,393.5,481z"/>
            </g>
            <g>
              <path d="M395,478.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C394.6,478,395,478.3,395,478.8z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="112.6" r="6"/>
            <path d="M394.4,119c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C400.7,116.1,397.9,119,394.4,119z
              M394.4,106.9c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,106.9,394.4,106.9z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,111.6L394.6,111.6c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,111.2,394.6,111.4,394.6,111.6L394.6,111.6z"/>
            </g>
            <g>
              <path d="M393.5,111.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,111.9,393,111.7,393.5,111.6
                C393.4,111.6,393.4,111.6,393.5,111.6z"/>
            </g>
            <g>
              <path d="M395,109.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S395,108.9,395,109.4z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="394.4" cy="81.9" r="6"/>
            <path d="M394.4,88.2c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C400.7,85.4,397.9,88.2,394.4,88.2z
              M394.4,76.1c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S397.5,76.1,394.4,76.1z"/>
          </g>
          <g>
            <g>
              <path d="M394.6,80.9L394.6,80.9c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C394.6,80.5,394.6,80.6,394.6,80.9C394.6,80.8,394.6,80.9,394.6,80.9z"/>
            </g>
            <g>
              <path d="M393.5,80.8c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C392.6,81.2,393,81,393.5,80.8
                C393.4,80.8,393.4,80.8,393.5,80.8z"/>
            </g>
            <g>
              <path d="M395,78.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C394.6,77.8,395,78.2,395,78.7z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="658" cy="296" r="6"/>
            <path d="M658,302.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S661.5,302.3,658,302.3z M658,290.3
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C663.7,292.8,661.2,290.3,658,290.3z"/>
          </g>
          <g>
            <g>
              <path d="M658.2,295C658.3,295,658.3,295,658.2,295c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V297c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C658.2,294.6,658.2,294.8,658.2,295L658.2,295z"/>
            </g>
            <g>
              <path d="M657.1,294.9c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C656.2,295.3,656.6,295.1,657.1,294.9
                C657.1,295,657.1,295,657.1,294.9z"/>
            </g>
            <g>
              <path d="M658.6,292.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C658.2,291.9,658.6,292.3,658.6,292.8
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="446" cy="461.6" r="6"/>
            <path d="M446,468c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S449.5,468,446,468z M446,455.9
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S449.1,455.9,446,455.9z"/>
          </g>
          <g>
            <g>
              <path d="M446.2,460.7L446.2,460.7c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C446.2,460.2,446.2,460.4,446.2,460.7C446.2,460.6,446.2,460.6,446.2,460.7z"/>
            </g>
            <g>
              <path d="M445.1,460.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C444.2,461,444.6,460.7,445.1,460.6
                C445,460.6,445,460.6,445.1,460.6z"/>
            </g>
            <g>
              <path d="M446.6,458.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C446.2,457.6,446.6,458,446.6,458.4z"
                />
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="153.5" cy="421.6" r="6"/>
            <path d="M153.5,428c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S157,428,153.5,428z M153.5,415.9
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S156.7,415.9,153.5,415.9z"/>
          </g>
          <g>
            <g>
              <path d="M153.8,420.7L153.8,420.7c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C153.8,420.2,153.8,420.4,153.8,420.7C153.8,420.6,153.8,420.6,153.8,420.7z"/>
            </g>
            <g>
              <path d="M152.6,420.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C151.8,421,152.1,420.7,152.6,420.6L152.6,420.6z"/>
            </g>
            <g>
              <path d="M154.2,418.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C153.8,417.6,154.2,418,154.2,418.4z"
                />
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="230.7" cy="126.7" r="6"/>
            <path d="M230.7,133c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C237.1,130.2,234.2,133,230.7,133z
              M230.7,121c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C236.5,123.5,233.9,121,230.7,121z"/>
          </g>
          <g>
            <g>
              <path d="M231,125.7L231,125.7c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3,0-0.5,0-0.8,0
                c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7
                c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0
                c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C231,125.3,231,125.5,231,125.7L231,125.7z"/>
            </g>
            <g>
              <path d="M229.8,125.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C229,126,229.3,125.8,229.8,125.6L229.8,125.6z"/>
            </g>
            <g>
              <path d="M231.4,123.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S231.4,123,231.4,123.5z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="152.8" cy="175.5" r="6"/>
            <path d="M152.8,181.9c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S156.3,181.9,152.8,181.9z
              M152.8,169.8c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S156,169.8,152.8,169.8z"/>
          </g>
          <g>
            <g>
              <path d="M153.1,174.5L153.1,174.5c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5H154c-0.3,0-0.5,0-0.8,0
                c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.5,1.1,0.8,1.7
                c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1c-0.6,0-1.1,0-1.7,0
                c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C153.1,174.1,153.1,174.3,153.1,174.5L153.1,174.5z"/>
            </g>
            <g>
              <path d="M151.9,174.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C151.1,174.9,151.4,174.6,151.9,174.5L151.9,174.5z"/>
            </g>
            <g>
              <path d="M153.5,172.3c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C153.1,171.5,153.5,171.9,153.5,172.3
                z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="76.9" cy="259.6" r="6"/>
            <path d="M76.9,266c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C83.3,263.1,80.4,266,76.9,266z
              M76.9,253.9c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S80.1,253.9,76.9,253.9z"/>
          </g>
          <g>
            <g>
              <path d="M77.2,258.6L77.2,258.6c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C77.2,258.2,77.2,258.4,77.2,258.6L77.2,258.6z"/>
            </g>
            <g>
              <path d="M76,258.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4c-0.2,0.9,0.2,1.8,1.1,2.2
                c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1c-0.4,0.6-1,1-1.8,1.1
                c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C75.2,259,75.5,258.7,76,258.6L76,258.6z"/>
            </g>
            <g>
              <path d="M77.5,256.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C77.2,255.6,77.6,256,77.5,256.4z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="76.9" cy="333" r="6"/>
            <path d="M76.9,339.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S80.4,339.3,76.9,339.3z M76.9,327.3
              c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C82.6,329.8,80.1,327.3,76.9,327.3z"/>
          </g>
          <g>
            <g>
              <path d="M77.2,332L77.2,332c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4V334c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C77.2,331.6,77.2,331.8,77.2,332C77.2,331.9,77.2,332,77.2,332z"/>
            </g>
            <g>
              <path d="M76,331.9c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4c-0.2,0.9,0.2,1.8,1.1,2.2
                c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1c-0.4,0.6-1,1-1.8,1.1
                c-1.1,0.2-2.1-0.3-2.7-1.3s-0.4-2.3,0.4-3.1C75.2,332.3,75.5,332.1,76,331.9L76,331.9z"/>
            </g>
            <g>
              <path d="M77.5,329.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C77.2,328.9,77.6,329.3,77.5,329.8z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="557.1" cy="126.7" r="6"/>
            <path d="M557.1,133c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C563.4,130.2,560.5,133,557.1,133z
              M557.1,121c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C562.8,123.5,560.2,121,557.1,121z"/>
          </g>
          <g>
            <g>
              <path d="M557.3,125.7L557.3,125.7c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C557.3,125.3,557.3,125.5,557.3,125.7L557.3,125.7z"/>
            </g>
            <g>
              <path d="M556.1,125.6c0,0.1,0,0.1,0,0.2s0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C555.3,126,555.7,125.8,556.1,125.6L556.1,125.6z"/>
            </g>
            <g>
              <path d="M557.7,123.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S557.7,123,557.7,123.5z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <circle cx="650.2" cy="184.4" r="6"/>
            <path d="M650.2,190.8c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C656.5,187.9,653.6,190.8,650.2,190.8z
              M650.2,178.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7S653.3,178.7,650.2,178.7z"/>
          </g>
          <g>
            <g>
              <path d="M650.4,183.4L650.4,183.4c0.3,0,0.6,0,0.8,0s0.4,0.2,0.5,0.4c0,0.2-0.1,0.4-0.3,0.5h-0.1
                c-0.3,0-0.5,0-0.8,0c0,0,0,0-0.1,0c0,0.2,0,0.5,0,0.7c0,0,0,0,0.1,0c0.5,0,1,0,1.5,0c0.2,0,0.4,0.1,0.5,0.3
                c0.3,0.6,0.5,1.1,0.8,1.7c0.2,0.3-0.1,0.7-0.4,0.7c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.5-0.5-0.9-0.7-1.4c0-0.1-0.1-0.1-0.1-0.1
                c-0.6,0-1.1,0-1.7,0c-0.2,0-0.4-0.1-0.5-0.4v-0.1c0-0.9,0-1.8,0-2.7c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.1,0.5,0.4v0.1
                C650.4,183,650.4,183.2,650.4,183.4L650.4,183.4z"/>
            </g>
            <g>
              <path d="M649.2,183.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c-0.6,0.3-1.1,0.7-1.2,1.4
                c-0.2,0.9,0.2,1.8,1.1,2.2c1,0.5,2.2,0.1,2.7-1c0,0,0,0,0-0.1c0,0,0,0,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0,0,0,0.1
                c-0.4,0.6-1,1-1.8,1.1c-1.1,0.2-2.1-0.3-2.7-1.3c-0.6-1-0.4-2.3,0.4-3.1C648.4,183.7,648.8,183.5,649.2,183.4L649.2,183.4z"/>
            </g>
            <g>
              <path d="M650.8,181.2c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C650.4,180.4,650.8,180.8,650.8,181.2
                z"/>
            </g>
          </g>
        </g>
      </g>
      </svg>

    </div>
  )
}

export default Hockey;
