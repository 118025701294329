import React, { useState } from 'react';
import moment from 'moment';
// import { Link } from 'react-router-dom';

import Details from './sport/Details';
import Stats from './sport/Stats';
import Rosters from './sport/Rosters';

function Basketball(props) {
  const { eventDetails } = props;
  const [ navSelection, setNavSelection ] = useState(0);

  return (
    <div className='wrapper events'>
      <div className='sport__header'>
        <div className='sport__header__third'>
          { eventDetails.home_logo && <img src={ eventDetails.home_logo.url } alt='home logo' /> }
          <div>{ eventDetails.home_team_letters || 'HOME' }</div>
        </div>

        <div className='sport__header__third'>
          <div className='sport__header__date'>
            { eventDetails.event_date && moment(`${ eventDetails.event_date }`, 'D/M/YYYY').format('ddd - MMM D') }
          </div>
          <div className='sport__header__time'>
            { eventDetails.event_time && moment(`${ eventDetails.event_time }`, 'HH:mm:ss').format('h:mm A') }
          </div>
        </div>

        <div className='sport__header__third'>
          { eventDetails.away_logo && <img src={ eventDetails.away_logo.url } alt='away logo' /> }
          <div>{ eventDetails.away_team_letters || 'AWAY' }</div>
        </div>
      </div>

      <div className='sport__nav'>
        <div 
          className={ navSelection === 0 ? 'sport__nav__option active' : 'sport__nav__option' } 
          onClick={ e => setNavSelection(0) }>
          Details
        </div>

        <div 
          className={ navSelection === 1 ? 'sport__nav__option active' : 'sport__nav__option' } 
          onClick={ e => setNavSelection(1) }>
          Player Stats
        </div>

        <div 
          className={ navSelection === 2 ? 'sport__nav__option active' : 'sport__nav__option' } 
          onClick={ e => setNavSelection(2) }>
          Rosters
        </div>
      </div>


      <div className='sport__body'>
        { navSelection === 0 && <Details eventDetails={ eventDetails } /> }
        { navSelection === 1 && <Stats eventDetails={ eventDetails } /> }
        { navSelection === 2 && <Rosters eventDetails={ eventDetails } /> }
      </div>
      
    </div>
  )
}

export default Basketball;