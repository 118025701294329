import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
// import Blur from 'react-blur';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SlickCarousel(props) {
  const { fields } = props;
  const [ featuredEvents, setFeaturedEvents ] = useState();

  useEffect(() => {
    function getFeaturedEvents() {
      let eventsArray = [];
      fields.featured_events.forEach(event => {
        axios.get(`http://35.153.188.133/wp-json/wp/v2/event/${ event.ID }`)
          .then(res => eventsArray.push(res.data))
          .then(() => {
            if (eventsArray.length === fields.featured_events.length) {
              setFeaturedEvents(eventsArray);
            }
          })
          .catch(err => console.log(err));
      })
    }

    if (fields.featured_events.length) {
      getFeaturedEvents();
    }
  }, [ fields ]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Slider { ...settings }>
      {featuredEvents && featuredEvents.map(event => (
        <div className='homeSlide' key={ event.id }>
          <div className='content' style={ { backgroundImage: `url(${ event.acf.event_background_image.url })` } }>
            <h1 className='carousel__top'>
              Dickies Arena <span className='white'>Live!</span> Presents
            </h1>
            <div className='slideImage slideMobile'>
              <Link to={ `/event/${ event.id }` }>
                <img src={ event.acf.event_big_image.url } alt={ event.acf.event_title } />
              </Link>
            </div>
            <div className='slideImage slideTablet'>
              <Link to={ `/event/${ event.id }` }>
                <img src={ event.acf.event_image.url } alt={ event.acf.event_title } />
              </Link>
            </div>
            <div className='slideFooter'>
              <div className='carousel__copy'>
                { event.acf.event_title }
              </div>

              <div className='carousel__date'>
                { moment(`${ event.acf.event_date } ${ event.acf.event_time }`, 'D/M/YYYY HH:mm:ss').format('dddd, MMM D - h:mm A') }
              </div>

            </div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

export default SlickCarousel;