import React from 'react';
// import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Details(props) {
  const { eventDetails } = props;
  // const [ readFull, setReadFull ] = useState(false);

  return (
    <div className='sport__details'>
      <div className='eventContainer'>
        <div className='eventContent'>
          <a href={ eventDetails.ticket_link } target='_blank' rel='noopener noreferrer' className='btn btn-primary btn-blockEvent '>Purchase Tickets</a>
          <div dangerouslySetInnerHTML={ { __html: eventDetails.event_description } }></div>
        </div>
        <div className='seperator'></div>
        <div className='eventsNav'>
          <Link to='/seating-chart' className='nav-item'><img src='/img/icon-section.png' alt='section' />Find Your Section</Link>
          <Link to='/concourse-map' className='nav-item'><img src='/img/dining-room.png' alt='dining' />View Concessions</Link>
          <Link to='/directions' className='nav-item'><img src='/img/icons-waze.png' alt='waze' />Directions</Link>
        </div>
      </div>
    </div>
  )
}

export default Details;