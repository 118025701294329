import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import PageTitle from '../components/PageTitle';

function GuestServices(props) {
  const { fields } = props;

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
  }, []);

  return (
    <div className='guest-services'>
      <div className="masthead">
        <PageTitle title='Guest Services' />
        
        <div 
          className='guest-services__copy' 
          dangerouslySetInnerHTML={ { __html: fields.guest_services_top_copy } }></div>

        <a href="sms:96050;&body=DICKIES" className='guest-services__text'>
          <div className='guest-services__text__left'>
            <h3>TEXT 96050</h3>
            <h3>for real-time assistance</h3>
          </div>

          <img className='guest-services__text__right' src='/img/icon-sms.png' alt='sms' />
        </a>
      </div>

      <div className='guest-services__nav'>
        <Link to='/guest-services/ada-services'>
          <div className='landing-card minimal'>
            <div className='landing-card__left'>
              <h2>ADA Services</h2>
            </div>

            <div className='landing-card__right'>
              <img src='/img/arrow-right-deco.png' alt='Go' />
            </div>
          </div>
        </Link>

        <Link to='/guest-services/parking-map'>
          <div className='landing-card minimal'>
            <div className='landing-card__left'>
              <h2>Parking Map</h2>
            </div>

            <div className='landing-card__right'>
              <img src='/img/arrow-right-deco.png' alt='Go' />
            </div>
          </div>
        </Link>

        <Link to='/directions'>
          <div className='landing-card minimal'>
            <div className='landing-card__left'>
              <h2>Waze</h2>
            </div>

            <div className='landing-card__right'>
              <img src='/img/arrow-right-deco.png' alt='Go' />
            </div>
          </div>
        </Link>
      </div>

      <Footer />
    </div>
  )
}

export default GuestServices;