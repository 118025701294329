import React, { useEffect } from 'react';

import PageTitle from '../../components/PageTitle';
import Footer from '../../components/Footer';

function ParkingMap(props) {
  const { fields } = props;

  useEffect(() => {
    document.getElementsByTagName('body')[ 0 ].scrollTop = 0;
  }, []);
  
  return (
    <div className='parking-map'>
      <PageTitle title='Parking Map' />

      <div
        className='parking-map__copy'
        dangerouslySetInnerHTML={ { __html: fields.parking_map_copy } }></div>

      <a 
        className='parking-map__button' 
        href='http://35.153.188.133/wp-content/uploads/2019/09/screen-shot-2019-09-20-at-3-25-49-am@3x.jpg' 
        download='DickiesArenaParkingMap.jpg'>
        <span>Download/View Parking Map</span>
        <img src='/img/icon-parking-filled.png' alt='parking-icon' />
      </a>

      <div className='parking-map__image'>
        <img src={ fields.parking_map_image.url } alt={ fields.parking_map_image.alt } />
      </div>
      <Footer />
    </div>
  )
}

export default ParkingMap;